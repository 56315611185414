import { AxiosError } from 'axios'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { $enum } from 'ts-enum-util'

import ActionsColumn from './ActionsColumn/ActionsColumn'
import CostColumn from './CostColumn/CostColumn'
import { FilterOption } from './OrderFilters/OrderFilters'
import { Orders } from './Orders'
import PatientColumn from './PatientColumn/PatientColumn'
import ProductColumn from './ProductColumn/ProductColumn'
import PurchaseOrderColumn from './PurchaseOrderColumn/PurchaseOrderColumn'
import StatusColumn from './StatusColumn/StatusColumn'
import { ClinTheme } from '../../../ClinTheme'
import { PageNames } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import {
  getParamValueFor,
  IPagination,
  useUpdateQueryParam
} from '../../../components/ClinPagination/ClinPagination.model'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinText } from '../../../components/ClinText'
import { UserRoleRecord, UserRole, isAusGaUser } from '../../../constants'
import { useAppContext } from '../../../context/app'
import useChangeBackgroundColor from '../../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { useEffectOnlyOnce } from '../../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { useNewFeaturesList } from '../../../hooks/useNewFeaturesList/useNewFeaturesList'
import { AnalyticsEvent } from '../../../services/Analytics/AnalyticsEvent'
import { AnalyticsPageEvent } from '../../../services/Analytics/AnalyticsPageEvent'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import {
  getOrders,
  cancelGetOrders,
  getOrderById
} from '../../../services/ApiService'
import config from '../../../services/ConfigProvider'
import { IBanner } from '../../../types/IBanner'
import { IOrderSummary } from '../../../types/IOrder'
import { IRestEndpointState } from '../../../types/IRestEndpointState'
import { OrderStatus } from '../../../types/OrderStatus'
import {
  InstituteDto,
  OrderDto,
  OrderSearchDto,
  OrderSummaryDto
} from '../../../types/swaggerTypes'
import { useOnMount } from '../../../utils/useOnMount'

interface IDashboardContainerProps extends RouteComponentProps {}

const handleTrackOrder = async (orderNumber: string) => {
  try {
    const response = await getOrderById(orderNumber)
    const orderDto: OrderDto = response.data as OrderDto

    const orderLineWithTrackingLink = orderDto.lines.find(
      (line) => line.trackingInfo?.aftershipTrackingLink !== null
    )
    const trackingLink =
      orderLineWithTrackingLink?.trackingInfo?.aftershipTrackingLink
    if (trackingLink) {
      window.open(trackingLink, '_blank')
    }
  } catch (error: any) {
    analyticsServiceSingleton.trackError(
      error,
      JSON.parse(localStorage.getItem('current_user') || '{}')
    )
  }
}

export interface OrderColumn {
  name: string
  viewName: string
  translationKey: string
  toolTipKey?: string
  width: string
  minWidth?: string
  maxWidth?: string
  hide?: boolean
  isSortable?: boolean
  sortProperty?: string
  renderValue?: (order: any) => JSX.Element | string
}

export const orderColumns: OrderColumn[] = [
  {
    name: 'orderNumber',
    viewName: 'Order number',
    translationKey: 'orders:column_titles.order_number',
    toolTipKey: 'orders:order_number_tooltip',
    width: '100%',
    isSortable: true,
    sortProperty: 'orderNumber',
    renderValue: (order: OrderSummaryDto) => (
      <ClinText
        fontSize={ClinTheme.fontSizes[1]}
        lineHeight={ClinTheme.lineHeights.heading[0]}
      >
        {order.orderNumber}
      </ClinText>
    )
  },
  {
    name: 'purchaseOrderNumber',
    viewName: 'P.O. number',
    translationKey: 'orders:column_titles.purchase_order_number',
    toolTipKey: 'orders:po_number_orders_tooltip',
    width: '100%',
    isSortable: true,
    sortProperty: 'poNumber',
    renderValue: (order: OrderSummaryDto) => (
      <PurchaseOrderColumn purchaseOrderNumber={order.customerPoNumber} />
    )
  },
  {
    name: 'patient',
    viewName: 'Patient',
    translationKey: 'orders:column_titles.patient',
    toolTipKey: 'orders:patient_orders_tooltip',
    width: '100%',
    maxWidth: '119px',
    isSortable: true,
    sortProperty: 'patient',
    renderValue: (order: OrderSummaryDto) => (
      <PatientColumn patientNumber={order.patientNumber} />
    )
  },
  {
    name: 'products',
    viewName: 'Products',
    translationKey: 'orders:column_titles.products',
    toolTipKey: 'orders:products_orders_tooltip',
    width: '100%',
    maxWidth: '180px',
    isSortable: true,
    sortProperty: 'product',
    renderValue: (order: OrderSummaryDto | any) => (
      <ProductColumn product={order.medications[0]} />
    )
  },
  {
    name: 'cost',
    viewName: 'Cost',
    translationKey: 'orders:column_titles.cost',
    toolTipKey: 'orders:cost_orders_tooltip',
    width: '100%',
    maxWidth: '90px',
    isSortable: false,
    renderValue: (order: OrderSummaryDto) => (
      <CostColumn cost={order.orderTotal} />
    )
  },
  {
    name: 'dateSubmitted',
    viewName: 'Date submitted',
    translationKey: 'orders:column_titles.date_submitted',
    toolTipKey: 'orders:date_submitted_orders_tooltip',
    width: '100%',
    isSortable: true,
    sortProperty: 'orderedDate',
    renderValue: (order: OrderSummaryDto) => (
      <ClinText
        fontSize={ClinTheme.fontSizes[1]}
        lineHeight={ClinTheme.lineHeights.heading[0]}
      >
        {DateTime.fromISO(order.orderedDate).toFormat('dd/MMM/yyyy')}
      </ClinText>
    )
  },
  {
    name: 'status',
    viewName: 'Order status',
    translationKey: 'orders:column_titles.status',
    toolTipKey: 'orders:status_orders_tooltip',
    sortProperty: 'orderStatus',
    renderValue: function column(order: OrderSummaryDto) {
      return (
        <StatusColumn
          orderStatus={order.orderStatus}
          handleTrackOrder={() => {
            order.orderNumber && handleTrackOrder(order.orderNumber)
          }}
        />
      )
    },
    width: '100%',
    isSortable: true
  },
  {
    name: 'actions',
    viewName: 'Actions',
    translationKey: 'orders:column_titles.actions',
    toolTipKey: 'orders:actions_orders_tooltip',
    width: '100%',
    isSortable: false,
    renderValue: (order: OrderSummaryDto) =>
      order.isDummyOrder ? (
        <ClinText
          fontSize={ClinTheme.fontSizes[1]}
          lineHeight={ClinTheme.lineHeights.heading[0]}
          color={ClinTheme.colors.deepGrey}
        >
          •••
        </ClinText>
      ) : (
        <ActionsColumn
          order={order}
          handleTrackOrder={() => {
            order.orderNumber && handleTrackOrder(order.orderNumber)
          }}
        />
      )
  }
]

/**
 * Utility function to get a sort column back
 * @param searchParams
 * @param defaultColumn
 */
export const getSortColumnFromURLParams = (
  searchParams: URLSearchParams,
  defaultColumn: OrderColumn
): OrderColumn => {
  const sortColumn = searchParams.get('sortColumn')
  const matchSortColumn = orderColumns.find(
    (oc) => oc.name.toString() === sortColumn
  )
  return matchSortColumn ? matchSortColumn : defaultColumn
}

const defaultRowsPerPage = 5

const defaultSearchParams: OrderSearchDto = {
  query: null,
  filter: {
    start: null,
    end: null,
    orderStatuses: [
      OrderStatus.Processing,
      OrderStatus.Submitted,
      OrderStatus.OnHold,
      OrderStatus.OutForDelivery,
      OrderStatus.InTransit,
      OrderStatus.FailedAttempt,
      OrderStatus.Shipped,
      OrderStatus.Cancelled,
      OrderStatus.Delivered,
      OrderStatus.MedicalReviewDeclined,
      OrderStatus.HealthAuthorityDeclined
    ],
    orderNumberStart: 0,
    orderNumberEnd: 2147483647, // max value of a 32 bit integer
    purchaseNumberStart: null,
    purchaseNumberEnd: null,
    itemGenerics: [],
    patients: [],
    showMyOrders: false
  },
  pagination: {
    skip: 0,
    take: defaultRowsPerPage
  },
  sorting: {
    sortBy: 'orderedDate',
    order: SortDirectionType.Descending
  }
}

const defaultPagination: IPagination = {
  count: 0,
  skip: 0,
  take: defaultRowsPerPage,
  total: 0
}

const orderStatuses = {
  currentOrders: [
    OrderStatus.Processing,
    OrderStatus.Submitted,
    OrderStatus.OnHold,
    OrderStatus.OutForDelivery,
    OrderStatus.InTransit,
    OrderStatus.FailedAttempt
  ],
  previousOrders: [
    OrderStatus.Shipped,
    OrderStatus.Cancelled,
    OrderStatus.Delivered,
    OrderStatus.MedicalReviewDeclined,
    OrderStatus.HealthAuthorityDeclined
  ]
}

export const OrdersContainer: React.FC<IDashboardContainerProps> = ({
  history,
  location
}) => {
  const { portfolioCountryCode } = useAppContext()
  const { i18n } = useTranslation()
  const { institute, userDetails, userRole, supportContact } = useAppContext()
  const isMaUser = !!(userRole && UserRoleRecord[userRole as UserRole].isMaUser)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [orders, setOrders] = React.useState<IOrderSummary[]>([])
  const [selectedFilter, setSelectedFilter] = useState<FilterOption[]>([])

  // Restore page state from URL params
  const urlSearchParams = new URLSearchParams(location.search)

  //Background
  useChangeBackgroundColor(ClinTheme.colors.lightGrey)

  // Query
  const [initialQuery] = React.useState<string>(() => {
    const orderQuery = urlSearchParams.get('q')
    return !orderQuery || orderQuery === '' ? '' : orderQuery
  })
  // Restore pagination state
  // PageSize
  defaultSearchParams.pagination.take = getParamValueFor(
    'pageSize',
    urlSearchParams,
    defaultRowsPerPage
  )
  // PageIndex
  defaultPagination.take = defaultSearchParams.pagination.take
  defaultSearchParams.pagination.skip =
    defaultSearchParams.pagination.take *
    getParamValueFor('pageIndex', urlSearchParams)

  const [pagination, setPagination] =
    React.useState<IPagination>(defaultPagination)

  // TabIndex
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(() => {
    const tabIndex = urlSearchParams.get('tabIndex')
    let defaultTabIndex: number = 0
    if (typeof tabIndex === 'string') {
      defaultTabIndex = parseInt(tabIndex)
      defaultSearchParams.filter.orderStatuses =
        defaultTabIndex === 0
          ? [OrderStatus.Processing, OrderStatus.Submitted, OrderStatus.OnHold]
          : [
              OrderStatus.Shipped,
              OrderStatus.Cancelled,
              OrderStatus.Delivered,
              OrderStatus.OutForDelivery,
              OrderStatus.InTransit,
              OrderStatus.FailedAttempt
            ]
    }
    return defaultTabIndex
  })

  // Sorting column
  const [sortColumn, setSortColumn] = React.useState<OrderColumn>(() => {
    const defaultSortColumn = orderColumns[0] // Default is date submitted
    const restoredSortByColumn = getSortColumnFromURLParams(
      urlSearchParams,
      defaultSortColumn
    )
    defaultSearchParams.sorting.sortBy = restoredSortByColumn
      ? restoredSortByColumn.name.toString()
      : defaultSortColumn.name.toString()
    return restoredSortByColumn
  })

  // Sort direction
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType>(
    () => {
      const sortDirection = urlSearchParams.get('sortDirection')
      const restoredSortDirection = $enum(SortDirectionType).asValueOrDefault(
        sortDirection,
        SortDirectionType.Descending
      )
      defaultSearchParams.sorting.order = restoredSortDirection.toString()
      return restoredSortDirection
    }
  )

  const [searchParams, setSearchParams] = React.useState<OrderSearchDto>({
    ...defaultSearchParams,
    query: initialQuery,
    sorting: {
      sortBy: orderColumns[0].name,
      order: SortDirectionType.Descending
    }
  })

  const [searchQuery, setSearchQuery] = React.useState<string>(initialQuery)

  // Custom filters (Filter by)

  const handleFilterChange = useCallback(
    (filter: FilterOption[]) => {
      setSelectedFilter(filter)
      setIsLoading(true)
      cancelGetOrders()
      setOrders([])
      setPagination(defaultPagination)

      if (filter) {
        const newSearchParams = { ...searchParams }
        newSearchParams.pagination = {
          skip: 0,
          take: searchParams.pagination.take
        }

        newSearchParams.filter.orderStatuses = [
          ...(filter.includes('currentOrders')
            ? orderStatuses.currentOrders
            : []),
          ...(filter.includes('previousOrders')
            ? orderStatuses.previousOrders
            : [])
        ]

        newSearchParams.filter.showMyOrders = filter.includes('myOrders')

        setSearchParams(newSearchParams)
      }
    },
    [searchParams]
  )

  const { dispatch } = useAppContext()

  const handlePageClicked = (selectedPageIndex: number) => {
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: (selectedPageIndex - 1) * searchParams.pagination.take,
        take: searchParams.pagination.take
      }
    })
  }

  const handleTabSelected = (tabIndex: number) => {
    setIsLoading(true)
    cancelGetOrders()
    setOrders([])
    setPagination(defaultPagination)

    // Reset pagination to the first page when switching tabs
    const newPagination = {
      skip: 0,
      take: searchParams.pagination.take
    }

    setSearchParams({
      ...searchParams,
      filter: {
        ...searchParams.filter,
        orderStatuses:
          tabIndex === 0
            ? [
                OrderStatus.Processing,
                OrderStatus.Submitted,
                OrderStatus.OnHold,
                OrderStatus.OutForDelivery,
                OrderStatus.InTransit,
                OrderStatus.FailedAttempt
              ]
            : [
                OrderStatus.Shipped,
                OrderStatus.Cancelled,
                OrderStatus.Delivered
              ]
      },
      pagination: newPagination
    })
    setSelectedTabIndex(tabIndex)
  }

  const toggleSortDirection = (
    current: SortDirectionType
  ): SortDirectionType => {
    if (current === SortDirectionType.None) {
      return SortDirectionType.Descending
    }
    if (current === SortDirectionType.Descending) {
      return SortDirectionType.Ascending
    }
    return SortDirectionType.None
  }

  const handleToggleSort = (columnName: any) => {
    setIsLoading(true)
    let newSortDirection: SortDirectionType
    let newSortColumn: OrderColumn

    if (typeof columnName === 'string') {
      newSortColumn =
        orderColumns.find((col) => col.name === columnName) || sortColumn
    } else {
      newSortColumn = columnName
    }

    if (sortColumn.name === newSortColumn.name) {
      newSortDirection = toggleSortDirection(sortDirection)
    } else {
      newSortDirection = SortDirectionType.Descending
    }

    setSortDirection(newSortDirection)
    setSortColumn(newSortColumn)

    setSearchParams({
      ...searchParams,
      sorting: {
        sortBy: newSortColumn.sortProperty || newSortColumn.name,
        order: newSortDirection
      }
    })
  }

  const handleRowClicked = (selectedOrderId: string) => {
    const selectedOrder = orders.find(
      (order) => order.orderNumber === selectedOrderId
    )

    if (selectedOrder && !selectedOrder.isDummyOrder) {
      history.push(`/order/${selectedOrderId}`, {
        from: window.location.pathname
      })
    }
  }

  useEffect(() => {
    if (isAusGaUser(portfolioCountryCode, userRole)) {
      window.location.href = '/products/catalogue'
    }
    institute &&
      institute.data &&
      !institute.isLoading &&
      getOrders(searchParams)
        .then((response) => {
          if (searchParams && searchParams.query !== '') {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.SubmitSearchQuery,
              {
                query: searchParams.query,
                searchLocation: 'order table',
                searchAPI: 'search'
              }
            )
          }
          setIsLoading(false)
          if (response) {
            const ordersFromAPI: IOrderSummary[] = response.data.result
            if (
              ordersFromAPI.length === 0 &&
              !newFeaturesList?.['FirstOrderNewUser']
            ) {
              const yesterday = new Date()
              yesterday.setDate(yesterday.getDate() - 1)
              const formattedYesterday =
                yesterday.toISOString().split('T')[0] + 'T00:00:00Z'

              const dummyOrder: Partial<IOrderSummary> = {
                orderNumber: '12345678',
                customerPoNumber: '--',
                patientNumber: 'N/A',
                medications: [{ name: 'Example product' }],
                orderTotal: 0,
                orderedDate: formattedYesterday,
                orderStatus: OrderStatus.Delivered,
                isOpen: false,
                isDummyOrder: true
              }
              if (searchParams.query && ordersFromAPI.length === 0) {
                setOrders([])
                return
              }
              setOrders([dummyOrder as any])
              return
            } else if (ordersFromAPI.length === 0) {
              setOrders([])
              return
            } else {
              const sortedOrders =
                searchParams.sorting.sortBy === 'patient'
                  ? [
                      ...ordersFromAPI.filter((order) => order.patientNumber),
                      ...ordersFromAPI.filter((order) => !order.patientNumber)
                    ]
                  : ordersFromAPI

              setOrders(sortedOrders)
            }
            const paginationDto = response.data.pagination
            if (paginationDto) {
              if (paginationDto.total === 0) {
                // If no orders are found, reset to the first page
                handlePageClicked(1)
              } else {
                setPagination({
                  count: paginationDto.count,
                  skip: paginationDto.skip,
                  take: paginationDto.take,
                  total: paginationDto.total
                })
              }
            }
          }
        })
        .catch((error: AxiosError) => {
          // ... (keep the existing error handling logic)
        })
    return () => {
      cancelGetOrders()
    }
  }, [searchParams, dispatch, institute.data, institute.isLoading, institute])

  const handleToggleOrderRow = (orderNumber: string) => {
    setOrders(
      orders.map((order) => {
        if (order.orderNumber !== orderNumber) {
          return order
        }
        return { ...order, isOpen: !order.isOpen }
      })
    )
  }

  const handlePageSizeChange = (pageSize: number) => {
    cancelGetOrders()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: 0,
        take: pageSize
      }
    })
  }

  const handleOnSearch = (query: string) => {
    setSearchQuery(query)
  }

  const handleOnSubmit = () => {
    cancelGetOrders()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        ...searchParams.pagination,
        skip: 0
      },
      query: searchQuery
    })
  }

  const handleShowMyOrders = (event: React.ChangeEvent<HTMLInputElement>) => {
    cancelGetOrders()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      filter: {
        ...searchParams.filter,
        showMyOrders: event.target.checked
      }
    })
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.MyOrdersToggle, {
      selected: event.target.checked
    })

    // Update the filter state to reflect the My Orders toggle
    setSelectedFilter(event.target.checked ? ['myOrders'] : ['currentOrders'])
  }

  const preferredName = userDetails?.contactCard.preferredName.length
    ? userDetails.contactCard.preferredName
    : userDetails?.contactCard.name.split(' ')[0]

  // Track changes to pagination and tabs as they update and update query string if it changes
  useUpdateQueryParam({ q: searchParams.query })
  useUpdateQueryParam({ tabIndex: selectedTabIndex })
  useUpdateQueryParam({
    pageIndex: searchParams.pagination.skip / searchParams.pagination.take
  })
  useUpdateQueryParam({ pageSize: searchParams.pagination.take })
  useUpdateQueryParam({ sortColumn: sortColumn.name.toString() })
  useUpdateQueryParam({ sortDirection: sortDirection.toString() })

  type Deps = IRestEndpointState<InstituteDto>[]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      analyticsServiceSingleton.trackPageView(
        AnalyticsPageEvent.ViewDashboard,
        {
          Institution: dependencies[0].data?.instituteName
        }
      )
    },
    [institute],
    (dependencies: Deps) => dependencies[0]
  )

  const { newFeaturesList, displayNewFeature, displayNewFeatureGlowEffect } =
    useNewFeaturesList(PageNames.Orders)

  const handleOnChange = (query: string) => {
    setIsLoading(true)
    setSearchParams((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        skip: 0
      },
      query
    }))
  }

  return (
    <Orders
      selectedFilter={selectedFilter}
      handleFilterChange={handleFilterChange}
      handleShowMyOrders={handleShowMyOrders}
      userPreferredName={preferredName}
      isLoading={isLoading}
      isMaUser={isMaUser}
      orders={orders}
      newFeaturesList={newFeaturesList}
      pagination={pagination}
      selectedSortColumn={sortColumn}
      selectedSortDirection={sortDirection}
      selectedTabIndex={selectedTabIndex}
      initialQuery={searchQuery}
      isEmptySearch={!!(searchParams.query && orders.length === 0)}
      supportContact={supportContact}
      showMyOrders={defaultSearchParams.filter.showMyOrders || false}
      handleTabSelected={handleTabSelected}
      handlePageClicked={handlePageClicked}
      handleRowClicked={handleRowClicked}
      handleToggleSort={handleToggleSort}
      handleToggleOrderRow={handleToggleOrderRow}
      handlePageSizeChange={handlePageSizeChange}
      handleOnSearch={handleOnSearch}
      handleOnSubmit={handleOnSubmit}
      handleMyOrders={handleShowMyOrders}
      handleOnChange={handleOnChange}
      displayNewFeature={displayNewFeature}
      displayNewFeatureGlowEffect={displayNewFeatureGlowEffect}
    />
  )
}
