import { DateTime } from 'luxon'
import React, { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'

import { ClinTheme } from '../../ClinTheme'
import i18n from '../../i18n/config'
import {
  StyledEstimatedTimeRow,
  StyledEstimatedTimeToolTip
} from '../../pages/Products/ProductDetail/ProductDetail.styles'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinText } from '../ClinText'

interface IClinEddProps {
  expectedDeliveryDate: string | undefined
  cutOffTime: string | undefined
}

export const EstimatedDeliveryDate: FunctionComponent<IClinEddProps> = ({
  expectedDeliveryDate,
  cutOffTime
}) => {
  const { t } = useTranslation()

  return (
    <StyledEstimatedTimeRow>
      <ClinIcon
        iconName={ClinIconPathName.CustomDelivery}
        iconSize={ClinTheme.space[4]}
        iconFill={ClinTheme.colors.primary}
      />

      <div
        style={{
          marginLeft: ClinTheme.space[3],
          width: '100%'
        }}
      >
        <ClinText
          marginBottom={ClinTheme.space[2]}
          as="div"
          color={ClinTheme.colors.primary}
          fontWeight={ClinTheme.fontWeights.medium}
          lineHeight={ClinTheme.lineHeights.heading[0]}
        >
          {t('product_detail:estimated_delivery_title')}
        </ClinText>

        <ClinText
          as="div"
          className={'unit-price'}
          color={ClinTheme.colors.primary}
          lineHeight={ClinTheme.lineHeights.heading[0]}
          whiteSpace="normal"
        >
          <>
            <Trans
              i18nKey="product_detail:estimated_delivery_time"
              components={{
                bold: <strong />,
                br: <br />
              }}
              values={{
                ExpectedDate: DateTime.fromISO(expectedDeliveryDate ?? '', {
                  zone: 'utc'
                })
                  .setLocale(i18n.language)
                  .toFormat('cccc, MMMM d'),
                CutOffTime: DateTime.fromISO(cutOffTime ?? '', {
                  zone: 'utc'
                })
                  .setLocale(i18n.language)
                  .toFormat('HH:mm')
              }}
            >
              <></>
            </Trans>

            <StyledEstimatedTimeToolTip>
              <Tooltip
                style={{
                  position: 'absolute',
                  top: '1px',
                  left: ClinTheme.space[2]
                }}
                title={t('product_detail:estimated_delivery_time_tooltip')}
                position="top"
                trigger="mouseenter"
                arrow={true}
              >
                <ClinIcon
                  iconSize={ClinTheme.fontSizes[3]}
                  iconName={ClinIconPathName.Warning}
                />
              </Tooltip>
            </StyledEstimatedTimeToolTip>
          </>
        </ClinText>
      </div>
    </StyledEstimatedTimeRow>
  )
}
