import { string } from 'yup'

import { getInputValidation, ValidationType } from './getInputValidation'

/**
 * Utility function to require a minumum number of string characters and return the required text if the test fails
 * @param minChars - the minumum characters that are required
 * @param requiredText - the text returned if the test fails
 */

export const requiredMinStringLength = (
  minChars: number = 20,
  requiredText: string = getInputValidation(ValidationType.RequiredField)
) =>
  string()
    .required(requiredText)
    .test(
      'len',
      getInputValidation(ValidationType.MaxiumumCharacters, minChars),
      (val) => {
        return !!(val === '' || (val && val.length < minChars))
      }
    )
