import styled from 'styled-components'

import { StyledClinTableOrderToggle } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle.styles'

export const StyledUkShortages = styled.div({
  [`${StyledClinTableOrderToggle}`]: {
    whiteSpace: 'nowrap'
  },
  [`.shortages__alternative-name`]: {
    textDecoration: 'underline'
  }
})

export const StyledNoShortagesMessage = styled.div({
  textAlign: 'center',
  svg: {
    width: 150,
    height: 150
  },
  p: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  [`.no-shortages-message__instructions`]: {
    maxWidth: 380
  }
})
