import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledDebugContainer } from './BootstrapSplash.styles'
import { QueryParam } from './BootstrapSplashConstants'
import { ErrorSplash } from './ErrorSplash'
import { ClinTheme } from '../../ClinTheme'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinText } from '../../components/ClinText'
import {
  UserRole,
  UserRoleRecord,
  defaultBasketDetails,
  defaultBasketMADetails,
  isAusGaUser
} from '../../constants'
import { CountryAlphaCodes } from '../../constants/countryAlpha2Codes'
import { useAppContext } from '../../context/app'
import {
  ActionType,
  getBasketAsync,
  useBasketDispatch
} from '../../context/basket'
import { useNewFeaturesDispatch } from '../../context/newFeatures'
import { getNewFeaturesAsync } from '../../context/newFeatures/NewFeaturesContext.async'
import { useIDSRoleCheck } from '../../context/useIDSRoleCheck'
import { useOnboardingCheck } from '../../context/useOnboardingCheck'
import { AnnounceEventType } from '../../events/AnnounceEvent'
import {
  createGetUserAuthEvent,
  createUserRoleEvent
} from '../../events/AuthEvent'
import { createBootstrappedEvent } from '../../events/BootstrapEvent'
import {
  createGetInstituteEvent,
  createGetInstitutesEvent,
  createSwitchInstituteEvent
} from '../../events/InstituteEvent'
import {
  createSetFirstAccessEvent,
  createShowInstituteModalEvent
} from '../../events/InstituteModalEvent'
import { createUpdateSupportContactAddressEvent } from '../../events/SupportContact'
import { AppLoader } from '../../features/AppLoader'
import { defaultLanguageEnglish, defaultLanguages } from '../../i18n/config'
import { i18nRef } from '../../i18n/i18nRef'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  cancelGetUserInstitutes,
  getUserInstitutes
} from '../../services/ApiService'
import authService from '../../services/AuthService'
import {
  clearLocallyStoredInstitute,
  getLocallyStoredInstitute
} from '../../services/UserInstituteService'
import { localStorageRecipientName } from '../../types/localStorageConstants'
import { getPathValueFromUrl } from '../../utils/getPathValue'
import { getUserPhone } from '../../utils/getUserPhone'
import { noop } from '../../utils/noop'
import { useErrorMessage } from '../../utils/useErrorMessage'

interface ISplashProps {
  /** Show debug information */
  showDebug?: boolean
}

const Tick = () => (
  <span
    role="img"
    aria-label="tick"
    style={{ fontSize: ClinTheme.fontSizes[5] }}
  >
    ☑
  </span>
)

export const BootstrapSplashContainer: FunctionComponent<ISplashProps> = ({
  showDebug = false,
  children
}) => {
  const { t, i18n } = useTranslation()
  const [loadingText, setLoadingText] = useState<string>('...')
  const {
    dispatch,
    user,
    userDetails,
    institute,
    portfolioCountryCode,
    institutes,
    supportContact,
    isBootstrapped,
    bootstrapError,
    userRole
  } = useAppContext()

  useOnboardingCheck(userDetails)
  const isUserWithRolesLoaded = useIDSRoleCheck(user)
  const [preferredLanguageId, setPreferredLanguageId] = useState<string>(
    defaultLanguageEnglish
  )

  const handleFetchInstitutesError = useErrorMessage(
    t('app_loader:error_fetching_institutes'),
    true
  )
  const handleFetchInstituteError = useErrorMessage(
    t('app_loader:error_fetching_institute'),
    true
  )

  useEffect(() => {
    setLoadingText(t('app_loader:loading_user'))
    dispatch(createGetUserAuthEvent())
  }, [dispatch])

  const [userInstituteId, setUserInstituteId] = useState<
    string | null | undefined
  >(undefined)

  // Helper function to handle institute loading
  const handleInstituteLoading = async (
    response: any,
    storedInstitute: any
  ) => {
    const foundInstitute = response.data.find(
      (institute: any) =>
        institute.instituteId.toString() ===
        storedInstitute?.instituteId.toString()
    )

    const storedJsonString = localStorage.getItem(QueryParam.ReturnUrl)
    if (storedJsonString) {
      const organizationId = JSON.parse(storedJsonString).organization
      return organizationId
    }

    return foundInstitute ? foundInstitute.instituteId.toString() : null
  }

  // Helper function to handle form URL parameters
  const handleFormUrlParams = () => {
    const formId = getPathValueFromUrl(
      window.location.pathname,
      QueryParam.Form
    )
    if (!formId) return null

    const organizationId = getPathValueFromUrl(
      window.location.pathname,
      QueryParam.Organization
    )
    const accessProgramId = getPathValueFromUrl(
      window.location.pathname,
      QueryParam.AccessProgram
    )
    const physicianId = getPathValueFromUrl(
      window.location.pathname,
      QueryParam.PhysicianId
    )

    if (organizationId && accessProgramId && physicianId) {
      return {
        organizationId,
        formId,
        redirectUrl: `${window.location.protocol}//${window.location.host}/programs/access-programs/${accessProgramId}/patient-access-form/${physicianId}/form/${formId}`
      }
    }
    return null
  }

  // Helper function to handle institute initialization
  const initializeInstitute = (
    institutes: any[],
    userInstituteId: string | null | undefined
  ) => {
    if (institutes.length === 1) {
      return {
        currentInstitute: institutes[0],
        shouldSwitchInstitute: true
      }
    }

    if (userInstituteId) {
      const resupplyInstOPA = window.location.pathname.split('/')[7]
      return {
        instituteId: resupplyInstOPA || userInstituteId,
        shouldGetInstitute: true
      }
    }

    return { shouldShowModal: true }
  }

  // Helper function to update analytics
  const updateAnalytics = (
    userDetails: any,
    institute: any,
    portfolioCountryCode: string,
    userRole: string
  ) => {
    analyticsServiceSingleton.language =
      userDetails.contactCard.languagePreference
    analyticsServiceSingleton.instituteId = String(institute.data?.instituteId)
    analyticsServiceSingleton.platformVersion = String(
      portfolioCountryCode === CountryAlphaCodes.Australia
        ? isAusGaUser(portfolioCountryCode, userRole)
          ? 'Aus GA'
          : 'Aus MA'
        : 'global'
    )
  }

  // Modified useEffect hooks
  useEffect(() => {
    if (isUserWithRolesLoaded && userDetails) {
      getUserInstitutes()
        .then(async (response) => {
          dispatch(createGetInstitutesEvent(response.data))
          const storedInstitute = await getLocallyStoredInstitute()
          const instituteId = await handleInstituteLoading(
            response,
            storedInstitute
          )
          setUserInstituteId(instituteId)
        })
        .catch(handleFetchInstitutesError)

      const preferredLanguage = defaultLanguages.find(
        (l) =>
          l.id.toLowerCase() ===
          userDetails?.contactCard.languagePreference.toLowerCase()
      )
      if (preferredLanguage) {
        setPreferredLanguageId(preferredLanguage.id)
      }
    }
  }, [dispatch, handleFetchInstitutesError, isUserWithRolesLoaded, userDetails])

  useEffect(() => {
    const formParams = handleFormUrlParams()
    if (formParams) {
      setUserInstituteId(formParams.organizationId)
      localStorage.setItem(
        QueryParam.ReturnUrl,
        JSON.stringify({
          organization: formParams.organizationId,
          form: formParams.formId
        })
      )
      window.location.href = formParams.redirectUrl
    }
  }, [])

  useEffect(() => {
    if (!institutes || !isUserWithRolesLoaded) return

    getLocallyStoredInstitute()
      .then((response) => {
        const result = initializeInstitute(institutes, userInstituteId)

        if (result.shouldSwitchInstitute) {
          dispatch(
            createSwitchInstituteEvent(result.currentInstitute.instituteId)
          )
          dispatch(createSetFirstAccessEvent(false))
          return
        }

        if (result.shouldGetInstitute) {
          dispatch(createGetInstituteEvent(result.instituteId))
        }

        if (result.shouldShowModal) {
          dispatch(createShowInstituteModalEvent())
        }

        if (response === null && !localStorage.getItem(QueryParam.ReturnUrl)) {
          const instituteOPA = window.location.pathname.split('/')[7]
          const currentInstitute = instituteOPA
            ? { instituteId: instituteOPA }
            : institutes[0]

          if (currentInstitute) {
            dispatch(
              createSwitchInstituteEvent(currentInstitute.instituteId, false)
            )
          }
          dispatch(createSetFirstAccessEvent(true))
          dispatch(createShowInstituteModalEvent())
        }
      })
      .catch(handleFetchInstituteError)

    return () => {
      cancelGetUserInstitutes()
    }
  }, [
    dispatch,
    handleFetchInstituteError,
    institutes,
    isUserWithRolesLoaded,
    userInstituteId
  ])

  // Wait for institute and user role to be available and save in shared app context
  useEffect(() => {
    if (institute.data && userDetails && isUserWithRolesLoaded) {
      const matchingRelationship =
        userDetails.contactCard.clientRelationships.find(
          (item) => item.instituteId === institute.data?.instituteId
        )

      if (matchingRelationship) {
        const role = matchingRelationship.roleType as UserRole
        dispatch(createUserRoleEvent(role))
      } else {
        console.error(
          `No matching institute relationship found for institute ID: ${institute.data.instituteId}`
        )
        dispatch({
          type: AnnounceEventType.SHOW,
          mode: AnnounceMode.Error,
          description: `No matching institute found. Please contact support.`
        })
      }
    }
  }, [dispatch, isUserWithRolesLoaded, institute, userDetails])

  // Watch the portfolioCountryCode and if it changes update the support details
  useEffect(() => {
    if (portfolioCountryCode && isUserWithRolesLoaded && userDetails) {
      dispatch(createUpdateSupportContactAddressEvent(portfolioCountryCode))
    }
  }, [portfolioCountryCode, dispatch, isUserWithRolesLoaded, userDetails])

  // Update loader text
  useEffect(() => {
    if (user && userDetails) {
      setLoadingText(t('app_loader:user_details_loaded'))
    }
    if (supportContact && supportContact.countryName) {
      setLoadingText(t('app_loader:support_info_loaded'))
    }
    if (institutes) {
      setLoadingText(t('app_loader:institutes_loaded'))
    }
    if (institute && institute.data) {
      setLoadingText(t('app_loader:current_institute_loaded'))
    }
  }, [
    user,
    userDetails,
    supportContact,
    institutes,
    institute,
    bootstrapError,
    isUserWithRolesLoaded,
    t
  ])

  // Get the user's current basket once institute is loaded
  const basketDispatch = useBasketDispatch()

  useEffect(() => {
    if (institute?.data?.instituteId && isUserWithRolesLoaded) {
      getBasketAsync(basketDispatch).then(() =>
        setLoadingText(t('app_loader:basket_loaded'))
      )
    }
  }, [basketDispatch, isUserWithRolesLoaded, institute, t])

  useEffect(() => {
    const isMaUser = !!(
      userRole && UserRoleRecord[userRole as UserRole].isMaUser
    )
    const defaultRoleBasketDetails = isMaUser
      ? defaultBasketMADetails
      : defaultBasketDetails
    basketDispatch({
      type: ActionType.UpdateBasketDetails,
      basketDetails: {
        ...defaultRoleBasketDetails,
        deliverToContact: localStorage.getItem(localStorageRecipientName) ?? '',
        recipientEmail: userDetails?.contactCard.email,
        recipientPhoneNumber: getUserPhone(userDetails?.contactCard)
      }
    })
  }, [basketDispatch, userRole, userDetails])

  // If not bootstrapped already, check if all loaded!
  useEffect(() => {
    if (
      !isBootstrapped &&
      user &&
      userDetails &&
      userRole &&
      institutes &&
      institute.data &&
      supportContact &&
      bootstrapError === undefined &&
      isUserWithRolesLoaded &&
      portfolioCountryCode &&
      portfolioCountryCode !== 'unknown' &&
      i18nRef.isLoaded
    ) {
      setLoadingText(t('app_loader:ready'))
      // Update the analytics singleton with the users preferred language before submitting tracked events
      updateAnalytics(userDetails, institute, portfolioCountryCode, userRole)
      // Dispatch analytics event to show we have logged in with userId and instituteId
      analyticsServiceSingleton.trackEvent(AnalyticsEvent.UserLoggedIn, {
        instituteId: institute.data?.instituteId,
        userId: userDetails?.contactCard.contactId
      })
      // Tell the app we are ready to go
      dispatch(createBootstrappedEvent(true))
    }
  }, [
    dispatch,
    user,
    userDetails,
    userRole,
    supportContact,
    institutes,
    institute,
    bootstrapError,
    isUserWithRolesLoaded,
    isBootstrapped,
    portfolioCountryCode,
    t
  ])

  const newFeaturesDispatch = useNewFeaturesDispatch()

  useEffect(() => {
    if (user) {
      getNewFeaturesAsync(newFeaturesDispatch)
    }
  }, [newFeaturesDispatch, user])

  /**
   * Button handlers
   */
  const handleTryAgain = () => {
    // Clear cookie for institute
    clearLocallyStoredInstitute()
    window.location.href = '/'
  }

  const handleLogOut = () => {
    authService.logout().then(() => noop())
  }

  const getContactEmail = (
    portfolioCountryCode: string,
    userRole: UserRole
  ) => {
    if (
      portfolioCountryCode === CountryAlphaCodes.Australia &&
      isAusGaUser(portfolioCountryCode, userRole)
    ) {
      return 'customerservice@linkhealthcare.com.au'
    }
    return 'medicineaccess@clinigengroup.com'
  }

  return (
    <>
      {isBootstrapped ? (
        <>{children}</>
      ) : !bootstrapError ? (
        <>
          <AppLoader loadingText={loadingText} />
          {showDebug ? (
            <StyledDebugContainer>
              <ClinText>User: {user ? <Tick /> : <ClinSpinner />}</ClinText>

              <ClinText>
                {`${t('app_loader:institutes')} `}
                {institutes ? <Tick /> : <ClinSpinner />}
              </ClinText>

              <ClinText>
                {`${t('app_loader:user_institute')} `}
                {institute.data ? <Tick /> : <ClinSpinner />}
              </ClinText>

              <ClinText>
                {`${t('app_loader:support_contact')} `}
                {supportContact && supportContact.countryName ? (
                  <Tick />
                ) : (
                  <ClinSpinner />
                )}
              </ClinText>
            </StyledDebugContainer>
          ) : null}
        </>
      ) : (
        <ErrorSplash
          bootstrapError={bootstrapError}
          supportContact={{
            csEmailAddress: getContactEmail(
              portfolioCountryCode,
              userRole as UserRole
            ),
            csPhoneNumber: '+44 (0) 1283 494340',
            rawPhoneNumber: '+441283494340'
          }}
          handleTryAgain={handleTryAgain}
          handleLogOut={handleLogOut}
        />
      )}
    </>
  )
}
