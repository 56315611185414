import React, { FunctionComponent } from 'react'

import {
  ISectionTitleBarStyles,
  StyledSectionTitleBar
} from './SectionTitleBar.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'

interface ISectionTitleBarProps extends ISectionTitleBarStyles {
  /** Title text */
  title: string
}

export const SectionTitleBar: FunctionComponent<ISectionTitleBarProps> = ({
  title
}) => (
  <StyledSectionTitleBar>
    <ClinText
      color={ClinTheme.colors.white}
      textTransform="uppercase"
      fontWeight={ClinTheme.fontWeights.bold}
      fontSize={ClinTheme.fontSizes[1]}
    >
      {title}
    </ClinText>
  </StyledSectionTitleBar>
)
