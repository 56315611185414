import React, { FunctionComponent } from 'react'

import {
  StyledLabel,
  StyledCheckbox,
  StyledCheckMark,
  ICheckboxStyles
} from './ClinCheckbox.styles'
import { ClinTheme } from '../../ClinTheme'
import { IDefaultStyleProps } from '../../types'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinText } from '../ClinText'

export const CHECKED = 1
export const UNCHECKED = 2
export const INDETERMINATE = -1

interface ICheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    IDefaultStyleProps,
    ICheckboxStyles {
  /** HTML id attribute */
  id: string
  /** HTML name attribute */
  name?: string
  /** Sets the value of the checkbox */
  value?: string
  /** HTML label attribute */
  label?: string
  /** React checked attribute for default state */
  defaultChecked?: boolean
  /** checked attribute */
  checked?: boolean
  /** checkbox width */
  checkBoxWidth?: number
  /** checkbox height */
  checkBoxHeight?: number
  /** custom border radious */
  borderRadius?: string
  /** custom margin right */
  marginRight?: string
  shadow?: string
  /** custom z index */
  zIndex?: string
  labelFontSize?: number
  labelStyle?: object
  checkboxLabelSpacing?: string
  whitespace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-line'
    | 'pre-wrap'
    | 'initial'
    | 'inherit'
  /** Function that takes an event and returns nothing */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** Function that takes an event and returns nothing */
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

export const ClinCheckbox: FunctionComponent<ICheckboxProps> = ({
  className = '',
  children,
  name,
  label,
  id,
  checkBoxWidth,
  checkBoxHeight,
  borderRadius,
  marginRight,
  value,
  disabled,
  checked,
  indeterminate,
  defaultChecked,
  zIndex,
  shadow,
  labelFontSize,
  labelStyle,
  checkboxLabelSpacing,
  whitespace,
  onChange,
  onKeyPress,
  ...rest
}) => {
  const checkRef = React.useRef<any>()

  React.useEffect(() => {
    if (checkRef.current) {
      checkRef.current.indeterminate = indeterminate === true
    }
  }, [checkRef, indeterminate])

  return (
    <StyledLabel
      style={labelStyle ?? {}}
      zIndex={zIndex}
      title={id}
      htmlFor={id}
      disabled={disabled}
      className={`clin-checkbox ${className}`}
      aria-label={label ?? 'checkbox'}
    >
      <StyledCheckbox
        {...rest}
        ref={checkRef}
        type="checkbox"
        id={id}
        name={name}
        value={value}
        indeterminate={indeterminate}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          onKeyPress?.(event)
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange?.(event)
        }}
      />
      <StyledCheckMark
        checkBoxWidth={checkBoxWidth}
        checkBoxHeight={checkBoxHeight}
        borderRadius={borderRadius}
        marginRight={marginRight}
        shadow={shadow}
      >
        <ClinIcon
          iconName={ClinIconPathName.Tick}
          className="clin-checkbox-checked"
          iconFill={ClinTheme.colors.white}
          iconSize={ClinTheme.fontSizes[3]}
        />
        <ClinIcon
          iconName={ClinIconPathName.Indeterminate}
          className="clin-checkbox-indeterminate"
          iconFill={ClinTheme.colors.white}
          iconSize={ClinTheme.fontSizes[3]}
        />
      </StyledCheckMark>
      {label && (
        <ClinText
          marginLeft={checkboxLabelSpacing ?? ''}
          as="div"
          fontSize={labelFontSize ?? ClinTheme.fontSizes[1]}
          color={disabled ? ClinTheme.colors.darkGrey : 'initial'}
          whiteSpace={whitespace ? 'nowrap' : 'normal'}
        >
          {label}
        </ClinText>
      )}
    </StyledLabel>
  )
}
