import * as React from 'react'

import {
  StyledHiddenSelect,
  StyledSelectLabel,
  StyledSelectSpan,
  StyledIcon
} from './ClinSelect.styles'
import { ClinTheme } from '../../ClinTheme'
import { IDefaultStyleProps } from '../../types'
import {
  PlaceholderType,
  getInputPlaceholder
} from '../../utils/Forms/getInputPlaceholder'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinText } from '../ClinText'

export enum ClinSelectVariant {
  primary = 'primary',
  secondary = 'secondary'
}

export interface ISelectStyles {
  /** Sets field styles to error */
  hasError?: boolean
  /** Sets field styles to valid */
  isValid?: boolean
  /** Disables the select */
  disabled?: boolean
  /** Css width value */
  width?: string | number
  /** Variant - primary is big with a border, secondary is more subtle */
  variant?: ClinSelectVariant
  lineHeight?: number
  padding?: string
  background?: string
}

interface ISelectProps
  extends IDefaultStyleProps,
    ISelectStyles,
    React.SelectHTMLAttributes<HTMLSelectElement> {
  /** HTML id attribute */
  id?: string
  /** The label to display above the select */
  label?: string
  /** The label to display below the input */
  prompt?: string
  /** React selected attribute */
  defaultValue?: string
  spanHeight?: string
  lineHeight?: number
  padding?: string
  iconTop?: number
  background?: string
  placeholderColor?: string
  /** onChange event that takes an event and returns nothing */
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export const ClinSelect: React.FC<ISelectProps> = ({
  className = '',
  id,
  label,
  prompt,
  children,
  defaultValue,
  disabled = false,
  isValid = false,
  hasError = false,
  width = 267,
  variant = ClinSelectVariant.primary,
  title,
  spanHeight,
  lineHeight,
  padding,
  iconTop,
  color,
  background,
  placeholderColor,
  onChange,
  ...rest
}) => {
  const { value } = rest
  const [selectedValue, setSelectedValue] = React.useState<string>()

  const firstChildValue = (
    React.Children.toArray(children)[0] as React.ReactElement
  ).props.value

  React.useEffect(() => {
    setSelectedValue(defaultValue || value || firstChildValue)
  }, [defaultValue, value, firstChildValue])

  const selectedOption = React.Children.toArray(children).find(
    (option) => (option as React.ReactElement).props.value === selectedValue
  ) as React.ReactElement

  const selectedText =
    selectedOption?.props.children &&
    typeof selectedOption.props.children === 'string'
      ? selectedOption.props.children
      : selectedValue

  return (
    <>
      <StyledSelectLabel
        tabIndex={-1}
        className={`clin-select ${className}`}
        disabled={disabled}
        isValid={isValid}
        hasError={hasError}
        width={width}
        htmlFor={id}
      >
        {label && (
          <ClinText
            as="div"
            fontSize={ClinTheme.fontSizes[1]}
            color="inherit"
            marginBottom={ClinTheme.space[2]}
          >
            {label}
          </ClinText>
        )}

        <StyledSelectSpan
          style={spanHeight ? { height: spanHeight } : { height: 'inherit' }}
          disabled={disabled}
          isValid={isValid}
          hasError={hasError}
          variant={variant}
          lineHeight={lineHeight}
          padding={padding}
          color={
            selectedText === getInputPlaceholder(PlaceholderType.SelectInput)
              ? placeholderColor
                ? placeholderColor
                : color
              : color
          }
          background={background}
        >
          {selectedText}
          <StyledIcon
            top={iconTop}
            iconName={
              variant === ClinSelectVariant.secondary
                ? ClinIconPathName.ChevronSmallUpDown
                : ClinIconPathName.ChevronSmallDown
            }
            iconFill={
              disabled
                ? ClinTheme.colors.grey
                : variant === ClinSelectVariant.secondary
                ? ClinTheme.colors.primary
                : ClinTheme.colors.black
            }
          />
        </StyledSelectSpan>
        <StyledHiddenSelect
          title={title}
          id={id}
          value={selectedValue}
          onChange={(event) => {
            setSelectedValue(event.target.value)
            onChange && onChange(event)
          }}
          disabled={disabled}
        >
          {children}
        </StyledHiddenSelect>
        {prompt && !disabled && (
          <ClinText
            as="div"
            fontSize={ClinTheme.fontSizes[1]}
            color={hasError ? ClinTheme.colors.redInvalid : 'inherit'}
            marginTop={ClinTheme.space[2]}
          >
            {prompt}
          </ClinText>
        )}
      </StyledSelectLabel>
    </>
  )
}
