/**
 * AnalyticsEvent
 *
 * Provide as many custom events as you wish to pass for your project
 */

export enum AnalyticsEvent {
  ApiResponceSuccess = 'api_response_success',
  ApiResponceFailure = 'api_response_failure',
  UserLoggedIn = 'user_logged_in', // {userId, instituteId}
  PasswordChange = 'password_change',
  RequestRepeatOrderSuccess = 'request_repeat_order-success',
  RequestRepeatOrderFailed = 'request_repeat_order-failed',
  OpenRepeatOrderModal = 'repeat_order_modal-open',
  CloseRepeatOrderModal = 'repeat_order_modal-close',
  BasketAddItem = 'basket_add_item',
  BasketRemoveItem = 'basket_remove_item',
  BasketStaleCheckoutError = 'basket_stale_checkout_error',
  OrderCompleted = 'order_completed', // triggered when a GA or MA order is completed { order, order_id, division, institute_id, has_hold, order_value }
  SubmitSearchQuery = 'submit_search_query',
  ResultsForSearchQuery = 'result_for_search_query',
  SourcingRequestSubmit = 'sourcing_request_submit', // { incidentNumber }
  ShippingAddressSubmit = 'shipping_address_submit',
  InviteUserInstitute = 'invite_user_institute',
  RemoveUserFromInstitute = 'remove_user_from_institute',
  SwitchInstitute = 'switch_institute', // { new institute }
  CancelOrderedItem = 'cancel_ordered_item',
  RequestAccessToInstitute = 'request_access_to_institute',
  DocumentDownload = 'document_download', // R2 Events from here down
  ContactClicked = 'contact_clicked', // When a contact link is clicked {type: phone or email, pageURL}
  BookmarkAddEvent = 'bookmark_add', // When a bookmark is added { bookmarkId, itemType }
  BookmarkRemoveEvent = 'bookmark_remove', // When a bookmark is added { bookmarkId }
  ProgramRegisterInterest = 'program_register_interest', // {programId, programName}
  ReportShortage = 'report_shortage', // {genericName}
  EnrolUserOntoProgram = 'enrol_user_onto_program', // {programId/ programName / userId}
  EnrolPhysicianOnToProgram = 'enrol_physician_onto_program', // {physicianIds, programName / programId}
  RemovePhysician = 'remove_physician', // {physicianId, instituteId}
  DisassociatePhysician = 'disassociate_physician', // {physicianId, instituteId}
  PatientTransferPhysician = 'patient_transfer_physician',
  PatientTransferInstitute = 'patient_transfer_institute', // {patientId, physicianId, instituteName}
  PatientDiscontinue = 'patient_discontinue', // {physicianId, patientId, instituteId}
  ReconcileVial = 'reconcile_vial', // {patientId, programId, programName}
  CancelOrderMA = 'cancel_order_ma', // {orderId}
  PlaceOrderMAAgain = 'place_order_again_ma',
  OrderUpdatedMA = 'order_updated_ma', // {orderId} trigger when an MA order is completed
  ProductView = 'product_view', // { sku, catalogueItemName, formulationPackSize, price, availableQuantity, portfolioJourney, defaultWarehouseCode }
  ProductReferralFromPublicSite = 'product_referral', // Referral from public site { productId }
  ProductReferralAccessProgram = 'product_referral_access_program', // Referral from public site { productId }
  ProductReferralRoleError = 'product_referral_role_error', // Referral from public site as MaUser and shown error { productId }
  LanguageSwitched = 'language_switched', // Language switch in prefs {language}
  Error = 'error', // A generic error
  MyOrdersToggle = 'my_orders_toggle', // {selected: [boolean]}
  DeliveryTracking = 'click_delivery_tracking', //User clicked on delivery tracking link on the order details page,
  NewFeatureDisplayed = 'feature_highlight_shown', //User sees new feture for the first time
  NewFeatureClosed = 'feature_highlight_closed', //User sees new feture for the first time
  DocumentUpload = 'document_upload', //User uploaded product details and basket page | sku & documentType
  TrackCopyLink = 'copy_link_click', //AUS "MA user" being able to see GA products sees a specific component <ProductDetailContact>, tracking that with a SKU property
  CreateDeliveryAddress = 'create_delivery_address', // new unvalidated address
  AddDeliveryNote = 'add_deliveryu_note',
  PurchaseOrderNumber = 'purchase_order_number', //Added when PO added on MA checkout, for UK and France
  CreatePatient = 'create_new_patient',
  ReadyForResupply = 'ready_for_resupply',
  RequestResupply = 'request_resupply',
  ContinueOrder = 'continue_order',
  SaveAndClose = 'save_and_close',
  EnrolPatientIntoRWD = 'enrol_patient_into_rwd', // Triggered on checkbox on CaP form
  RWDInfoPage = 'rwd_inf_page', // Triggered whena a user clicks 'Learn more about RWD ...'
  ExpandVariantView = 'expand_varient_view', // Triggered when user clicks the down arrow on the accordion
  CheckoutCartModal = 'checkout_cart_modal', // Triggered when a user clicks 'Checkout' on the pop-up cart modal
  CloseCartModal = 'close_cart_modal' // Triggered when a user closes the pop-up cart modal
}
