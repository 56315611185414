import React from 'react'

import { ClinTheme } from '../../../../ClinTheme'
import { ClinText } from '../../../../components/ClinText'
import ColumnTooltip from '../ColumnTooltip/ColumnTooltip'

interface ProductInfo {
  name: string
}

const ProductColumn = ({ product }: { product: ProductInfo }) => {
  const cleanProductInfo = (info: ProductInfo): string => {
    return info.name
      .split(' - ')
      .filter((item) => item && item !== 'undefined')
      .join(' - ')
  }

  const cleanedFullDescription = cleanProductInfo(product)

  // Create a short description with brand name, generic name, and dosage
  const parts = cleanedFullDescription.split(' - ')
  const shortDescription = parts.slice(0, 3).join(' - ')

  return (
    <ColumnTooltip tooltipText={cleanedFullDescription}>
      <ClinText
        fontSize={ClinTheme.fontSizes[1]}
        lineHeight={ClinTheme.lineHeights.heading[0]}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {shortDescription}
      </ClinText>
    </ColumnTooltip>
  )
}

export default ProductColumn
