import React, { FunctionComponent } from 'react'

import { StyledClinMobileLogoSvg } from './ClinMobileLogo.styles'
import { ClinTheme } from '../../ClinTheme'
import { IDefaultStyleProps } from '../../types'
import { IClinLogoSvgStyleProps } from '../ClinLogo/ClinLogo.styles'

interface IClinLogoProps
  extends IClinLogoSvgStyleProps,
    IDefaultStyleProps,
    React.HTMLAttributes<HTMLOrSVGElement> {}

export const ClinMobileLogo: FunctionComponent<IClinLogoProps> = ({
  style,
  className
}) => (
  <StyledClinMobileLogoSvg
    style={{ ...style }}
    className={`clin-logo ${className}`}
    x="0"
    y="0"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={ClinTheme.colors.white}
        d="M12.9979 28.4807L12.9903 28.5525V28.4807H12.9979ZM12.9979 28.4807H16.0672C19.6911 28.4807 21.674 27.7634 22.9732 25.6113C24.2039 23.531 24.7509 20.0877 25.2979 14.6359C25.8449 9.18403 26.1184 5.74075 25.2979 3.5887C24.4774 1.50838 22.6313 0.719299 19.0074 0.719299H15.9305L12.9979 28.4807ZM20.033 22.9571C19.486 24.3201 18.7339 24.4636 17.3664 24.4636L19.486 4.80818C20.7851 4.80818 21.5373 5.02338 21.8108 6.31461C21.9475 6.96023 21.9475 7.96452 21.8792 9.25575C21.8346 9.70007 21.7827 10.1825 21.726 10.7105C21.6087 11.8022 21.4704 13.0887 21.3322 14.6359C21.2194 15.582 21.1299 16.4428 21.0493 17.2183C20.9343 18.3244 20.8374 19.257 20.7168 20.016C20.5116 21.3072 20.3065 22.3115 20.033 22.9571Z"
      />
      <path
        fill="#8BDB1F"
        d="M11.3798 19.1232L10.9696 22.9252C10.7644 24.0729 10.4226 25.0772 10.0123 25.938C9.53367 26.7271 8.98667 27.4445 8.23453 27.9466C7.41402 28.5922 6.38839 28.8792 5.15763 28.8792C3.85849 28.8792 2.90123 28.5922 2.21747 27.9466C1.53371 27.3727 1.12346 26.6554 0.918333 25.938C0.576454 25.0772 0.508079 24.0729 0.576454 22.9252L2.28585 6.56959C2.4226 5.42183 2.76448 4.48928 3.24311 3.55672C3.72174 2.76764 4.20037 2.05029 5.02087 1.47641C5.77301 0.902529 6.86702 0.543854 8.16616 0.543854C9.4653 0.543854 10.4226 0.902529 11.1063 1.47641C11.7901 2.05029 12.2003 2.76764 12.4055 3.55672C12.7473 4.41754 12.8157 5.3501 12.7473 6.56959L12.3371 10.3715H8.64479L9.05504 6.56959C9.12342 5.85224 8.98667 5.27836 8.64479 4.84795C8.43966 4.63275 8.16616 4.48928 7.7559 4.48928C7.07215 4.48928 6.66189 4.99142 6.45677 5.49357C6.38839 5.78051 6.25164 6.06745 6.18326 6.49786L4.47387 22.9252C4.40549 23.6425 4.54225 24.2164 4.88412 24.6468C5.02087 24.862 5.29438 24.9337 5.63626 24.9337C6.45677 24.9337 6.86702 24.5033 7.07215 23.9295C7.14052 23.7143 7.27727 23.3556 7.34565 22.9252L7.7559 19.1232H11.3798Z"
      />
    </g>
  </StyledClinMobileLogoSvg>
)
