import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'

interface IProductNotAvailableProps {
  /** Show loading */
  isLoading?: boolean
  /** The name of the product */
  productName?: string
}

export const ProductNotAvailable: FunctionComponent<
  IProductNotAvailableProps
> = ({ isLoading, productName }) => {
  const { t } = useTranslation()
  return (
    <ClinPageContentFrame isLoading={isLoading}>
      <Row>
        <Col sm={12} md={8} lg={8}>
          <ClinText
            as="h2"
            fontWeight={ClinTheme.fontWeights.bold}
            variant={TypographyVariant.H2}
          >
            {t('product_not_available:title', {
              productName: productName
                ? productName
                : t('product_not_available:this_product')
            })}
          </ClinText>
          <ClinText>{t('product_not_available:description')}</ClinText>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col sm={12} md={10} lg={8}>
          <ClinGroup justifyContent={'flex-start'}>
            <Link to="/orders">
              <ClinButton variant="secondary">
                {t('product_not_available:back_to_dashboard_btn')}
              </ClinButton>
            </Link>
            <Link to="/sourcing-enquiry">
              <ClinButton variant="primary">
                {t('product_not_available:sourcing_enquiry_btn')}
              </ClinButton>
            </Link>
          </ClinGroup>
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
