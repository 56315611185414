import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { IConfirmation } from '../../types/IConfirmation'
import { mediaQuery } from '../../utils/mediaQuery'

export interface IClinConfirmationStyles {
  confirmation: IConfirmation
}

export const StyledClinConfirmation = styled.div({
  // These styles mostly match the ClinNavBar - confirmations should
  // show below the nav on the right hand side
  margin: 'auto',
  padding: 0,
  position: 'sticky',
  top: 0,
  height: 0,
  zIndex: ClinTheme.zIndices.dropdown,
  [mediaQuery(ClinTheme.breakpoints[2])]: {
    padding: 0,
    paddingLeft: ClinTheme.space[7]
  }
})

export const StyledClinConfirmationBox = styled.div<IClinConfirmationStyles>(
  {
    marginLeft: 'auto',
    boxShadow: ClinTheme.shadows[0],
    right: 0
  },
  ({ confirmation }) => ({
    width: confirmation.boxWidth || 220,
    borderRadius: confirmation.borderRadius || 0,
    background: confirmation.background || ClinTheme.colors.white,
    padding: confirmation.removePadding ? 0 : ClinTheme.space[3],
    marginRight: confirmation.marginRight
      ? confirmation.marginRight
      : ClinTheme.space[2],
    position: confirmation.position as any,
    top: confirmation.topPosition || ClinTheme.space[2]
  })
)
