import { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import React, { ReactNode } from 'react'

import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinText } from '../../components/ClinText'
import { DocumentSearchResultDto } from '../../types/swaggerTypes'
import { StyleDocumentationDetailButton } from '../Products/ProductDetail/ProductDetail.styles'

export enum DocumentationColumn {
  Category = 'Category name',
  DocumentationName = 'Documentation name',
  Language = 'Language',
  LastUpdated = 'Last updated',
  Download = 'Download'
}

export const getTableColumnWidth = (column: DocumentationColumn) => {
  switch (column) {
    case DocumentationColumn.Category:
      return '20%'
    case DocumentationColumn.DocumentationName:
      return '30%'
    case DocumentationColumn.Language:
      return '15%'
    case DocumentationColumn.LastUpdated:
      return '20%'
    case DocumentationColumn.Download:
      return '5%'
    default:
      return 'auto'
  }
}

/**
 * Helper function to get API sort field name from UI column name
 * @param columnName
 */
export const getSortFieldForColumn = (columnName: DocumentationColumn) => {
  switch (columnName) {
    case DocumentationColumn.Category:
      return `documentTypeName`
    case DocumentationColumn.DocumentationName:
      return 'documentName'
    case DocumentationColumn.Download:
      return 'download'
    case DocumentationColumn.Language:
      return 'documentLanguage'
    case DocumentationColumn.LastUpdated:
      return 'revisionDate'
    default:
      return ''
  }
}

/**
 * Get the column name for a sort field
 * @param sortField
 */
export const getColumnForSortField = (
  sortField: string
): DocumentationColumn => {
  switch (sortField) {
    case `documentTypeName`:
      return DocumentationColumn.Category
    case 'documentName':
      return DocumentationColumn.DocumentationName
    case 'documentLanguage':
      return DocumentationColumn.Language
    case 'revisionDate':
      return DocumentationColumn.LastUpdated
    default:
      return DocumentationColumn.Download
  }
}

export const getTableCellContent = (
  transFn: TFunction,
  column: DocumentationColumn,
  document: DocumentSearchResultDto,
  isDownloading?: boolean
): ReactNode | string => {
  const { documentName, documentLanguage, revisionDate, documentType } =
    document
  switch (column) {
    case DocumentationColumn.Category:
      return <ClinText as="span">{documentType}</ClinText>
    case DocumentationColumn.DocumentationName:
      return <ClinText as="span">{documentName}</ClinText>
    case DocumentationColumn.Language:
      return <ClinText as="span">{documentLanguage}</ClinText>
    case DocumentationColumn.LastUpdated:
      return (
        <ClinText as="span">
          {revisionDate && DateTime.fromISO(revisionDate).toLocaleString()}
        </ClinText>
      )
    case DocumentationColumn.Download:
      return (
        <>
          <StyleDocumentationDetailButton>
            <ClinButton variant="ghost" disabled={isDownloading}>
              {isDownloading
                ? transFn('common:downloading')
                : transFn('common:buttons.download')}
              &nbsp;
              {isDownloading ? (
                <ClinSpinner size={ClinTheme.space[3]} />
              ) : (
                <ClinIcon iconName={ClinIconPathName.Download} />
              )}
            </ClinButton>
          </StyleDocumentationDetailButton>
        </>
      )
    default:
      return 'Unknown type'
  }
}
