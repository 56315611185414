import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { StyledFlexSpacer, StyledNoOrders } from './NoOrders.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { CountryDto } from '../../types/swaggerTypes'

interface INoOrdersProps {
  /** If the search result is called and comes back empty */
  isEmptySearch?: boolean
  /** Supplied contact data */
  supportContact: CountryDto
  /** If user role is MA only */
  isMaUser?: boolean
}

export const NoOrders: FunctionComponent<INoOrdersProps> = ({
  isEmptySearch,
  supportContact,
  isMaUser
}) => {
  const { t } = useTranslation()

  return (
    <StyledNoOrders>
      <ClinSpacer height={ClinTheme.space[8]} />
      <ClinIcon
        iconName={ClinIconPathName.OrderBox}
        iconFill={ClinTheme.colors.grey}
        iconSize={170}
      />
      {!isEmptySearch ? (
        <>
          <StyledFlexSpacer />
          <ClinText
            variant={TypographyVariant.H4}
            fontWeight={ClinTheme.fontWeights.bold}
            marginBottom={'0'}
          >
            {t('orders:no_orders.title')}
          </ClinText>
          <StyledFlexSpacer />
          <ClinText
            variant={TypographyVariant.H4}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('orders:no_orders.sub_title')}
          </ClinText>
          <StyledFlexSpacer />
          <ClinText
            className={'msg-copy'}
            textAlign={'center'}
            variant={TypographyVariant.LargeParagraph}
          >
            {isMaUser ? (
              <>
                {`${t('orders:no_orders.ma_description')} `}
                <Link to="/results">
                  {t('orders:no_orders.ma_description_link')}
                </Link>
                .
              </>
            ) : (
              <>
                {`${t('orders:no_orders.description')} `}
                <Link to="/products/catalogue">
                  {t('orders:no_orders.description_link')}
                </Link>
                .
              </>
            )}
            <br />
            <br />
            {`${t('orders:no_orders.description_part_two')} `}
            <br />
            {`${t('orders:no_orders.description_part_three')} `}
            <Link to="/about/contact-us">
              {t('orders:no_orders.description_part_three_link')}
            </Link>
            .
          </ClinText>
        </>
      ) : (
        <>
          <StyledFlexSpacer />
          <ClinText
            variant={TypographyVariant.H4}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('orders:no_match.title')}
          </ClinText>
          <StyledFlexSpacer />
          <ClinText
            className={'msg-copy'}
            textAlign={'center'}
            variant={TypographyVariant.LargeParagraph}
            marginBottom={ClinTheme.space[4]}
          >
            {`${t('orders:no_match.description')} `}
            <strong>{t('orders:no_match.get_in_touch')}:</strong>
          </ClinText>
          <ClinGroup alignItems="center" justifyContent="center">
            <ClinIcon
              iconName={ClinIconPathName.Phone}
              style={{ marginRight: 0 }}
              iconFill={ClinTheme.colors.primary}
            />
            <a
              className="display-telephone"
              href={`tel:${supportContact?.rawPhoneNumber}`}
              style={{ margin: 0 }}
              onClick={(event) => {
                analyticsServiceSingleton.trackEvent(
                  AnalyticsEvent.ContactClicked,
                  { contactType: 'telephone' }
                )
              }}
            >
              <ClinText
                className={'telephone'}
                as="span"
                color={ClinTheme.colors.primaryLight}
                marginLeft={ClinTheme.space[2]}
                variant={TypographyVariant.LargeParagraph}
              >
                {supportContact?.csPhoneNumber}
              </ClinText>
            </a>
          </ClinGroup>
          <ClinSpacer />
          <ClinGroup alignItems="center" justifyContent="center">
            <ClinIcon
              iconName={ClinIconPathName.Mail}
              style={{ marginRight: 0 }}
              iconFill={ClinTheme.colors.primary}
            />
            <a
              className="raw-email"
              href={`mailto:${supportContact?.csEmailAddress}`}
              style={{ margin: 0 }}
              onClick={(event) => {
                analyticsServiceSingleton.trackEvent(
                  AnalyticsEvent.ContactClicked,
                  { contactType: 'email' }
                )
              }}
            >
              <ClinText
                className="email"
                as="span"
                color={ClinTheme.colors.primaryLight}
                marginLeft={ClinTheme.space[2]}
                variant={TypographyVariant.LargeParagraph}
              >
                {supportContact?.csEmailAddress}
              </ClinText>
            </a>
          </ClinGroup>
        </>
      )}
      <ClinSpacer height={ClinTheme.space[4]} />
    </StyledNoOrders>
  )
}
