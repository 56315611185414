import { t } from 'i18next'
import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from 'react-router'

import { ConfirmationScreenContainer } from '../../../components/AusConfirmationScreen/ConfirmationScreenContainer'
import { CustomizedState } from '../SourcingEnquiry.models'

export type TSourcingEnquiryParams = {
  generic: string
  brand: string
}

interface ISourcingEnquiryProps extends RouteComponentProps {}

export const SourcingEnquirySubmitContainer: FunctionComponent<
  ISourcingEnquiryProps
> = ({ location }) => {
  const state = location.state as CustomizedState

  return (
    <ConfirmationScreenContainer
      confirmationProductDetail={state.confirmationProductDetail}
      title={t('sourcing_enquiry:sourcing_enquiry_submitted_details.title')}
      detailsDescription={t(
        'sourcing_enquiry:sourcing_enquiry_submitted_details.request_details'
      )}
    />
  )
}
