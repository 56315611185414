import React, { FunctionComponent, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useRouteMatch } from 'react-router'

import {
  IPhysicianColumn,
  IProgramDocumentColumn,
  ProgramUiState,
  getShowRWDCard,
  TabModel
} from './ProgramDetail.models'
import {
  StyledAddPhysicianButton,
  StyledAddPhysicianTextWrapper,
  StyledTabSection,
  StyledProgramAttributes,
  StyledProgramAttributesCard,
  StyledProgramAttributesCardWrapper,
  StyledClinTableOrderToggleWrapper,
  StyledTabsRow
} from './ProgramDetail.styles'
import { ProgramDetailHeader } from './ProgramDetailHeader'
import { ProgramDetailTabLink } from './ProgramDetailTabs/ProgramDetailTabLink'
import { ProgramDocumentsSearch } from './ProgramDocumentsSearch'
import { StyledVerticalLine } from './StyledVerticalLine'
import { UnenrolledProgramsInfo } from './UnenrolledPrograms/UnenrolledProgramsInfo'
import { ClinTheme } from '../../../ClinTheme'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ClinPagination } from '../../../components/ClinPagination'
import {
  IPagination,
  getCurrentPage,
  getTotalPages
} from '../../../components/ClinPagination/ClinPagination.model'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinTable } from '../../../components/ClinTable'
import {
  ClinPatientTableHeaderCell,
  ClinTableBodyCell,
  ClinTableRow
} from '../../../components/ClinTable/ClinTable'
import {
  ClinTableOrderToggle,
  SortDirectionType
} from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinText } from '../../../components/ClinText'
import { ProgramCountryStatus } from '../../../constants'
import { NoProgramDocuments } from '../../../features/NoProgramDocuments/NoProgramDocuments'
import RealWorldDataCardProgramDetails from '../../../features/RealWorldDataCardProgramDetails/RealWorldDataCardProgramDetails'
import {
  DocumentSearchResultDto,
  PhysiciansEnrolledSummaryDto,
  ProgramCatalogDto,
  ProgramDocumentDto
} from '../../../types/swaggerTypes'
import { StyledStatusWrapper } from '../../Patients/PatientDashboard/PatientDashboard.styles'
import { PatientDashboardContainer } from '../../Patients/PatientDashboard/PatientDashboardContainer'

interface IProgramDetailProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** Whether there is an error loading */
  hasError: boolean
  /** Selected program */
  program?: ProgramCatalogDto
  /** Selected program status */
  programUiState?: ProgramUiState
  /** Available physicians */
  physicians?: PhysiciansEnrolledSummaryDto[]
  physiciansColumns: IPhysicianColumn[]
  /** Display link to documents */
  documentCount?: number
  documentColumns: IProgramDocumentColumn[]
  programDocuments?: DocumentSearchResultDto[]
  tabData: TabModel[]
  selectedTabIndex: number
  documentPagination: IPagination
  documentsQuery: string
  isProgramEnrolled?: boolean
  handleChangeTab: (tabIndex: number) => void
  handleDocumentPageSizeChanged: (pageSize: number) => void
  handleDocumentPageClicked: (pageIndex: number) => void
  handlePhysicianRowClicked: (index: number) => void
  handleDocumentQueryChanged: (query: string) => void
  handlePatientCountChanged: (countr: number) => void
  handleDocumentRowClicked: (
    document?: ProgramDocumentDto,
    documentId?: string
  ) => void
  handleDocumentToggleSort: (columnName: string | undefined) => void
  selectedDocumentSortDirection: SortDirectionType | string
  selectedDocumentSortColumn: string
}

export const ProgramDetail: FunctionComponent<IProgramDetailProps> = ({
  isLoading,
  hasError,
  program,
  programUiState,
  tabData,
  isProgramEnrolled,
  selectedTabIndex,
  physicians,
  physiciansColumns,
  programDocuments,
  documentPagination,
  documentColumns,
  documentCount,
  documentsQuery,
  selectedDocumentSortColumn,
  selectedDocumentSortDirection,
  handleChangeTab,
  handleDocumentPageClicked,
  handleDocumentPageSizeChanged,
  handlePhysicianRowClicked,
  handleDocumentQueryChanged,
  handlePatientCountChanged,
  handleDocumentRowClicked,
  handleDocumentToggleSort
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const isPhysicianExists = physicians && physicians.length > 0
  const averageMedicalReviewTimeExists =
    program?.program?.averageMedicalReviewTime

  const [physicianSortConfig, setPhysicianSortConfig] = useState({
    sortColumn: physiciansColumns[0].name,
    sortOrder: SortDirectionType.None
  })

  const sortPhysicians = (
    physicians: PhysiciansEnrolledSummaryDto[],
    sortKey: string,
    sortOrder: SortDirectionType = SortDirectionType.None
  ) => {
    if (!physicians || !sortKey || sortOrder === SortDirectionType.None) {
      return physicians
    }

    const sortedPhysicians = physicians.sort(
      (a: PhysiciansEnrolledSummaryDto, b: PhysiciansEnrolledSummaryDto) => {
        let valA = (a as any)[sortKey].trim()
        let valB = (b as any)[sortKey].trim()

        // Handling different data types
        if (typeof valA === 'string') {
          valA = valA.toLowerCase() // Case-insensitive sorting for strings
          valB = valB.toLowerCase()
          return sortOrder === SortDirectionType.Ascending
            ? valA.localeCompare(valB)
            : valB.localeCompare(valA)
        } else if (typeof valA === 'number') {
          return sortOrder === SortDirectionType.Ascending
            ? valA - valB
            : valB - valA
        }

        return 0 // In case of data type mismatch or other unforeseen cases
      }
    )

    return sortedPhysicians
  }

  const handlePhysicianSortChange = (sortKey: string | undefined) => {
    if (sortKey === undefined) return

    setPhysicianSortConfig((currentConfig) => {
      const isNewColumn = currentConfig.sortColumn !== sortKey
      const isAscending = isNewColumn
        ? true
        : currentConfig.sortOrder !== SortDirectionType.Ascending
      return {
        sortColumn: sortKey,
        sortOrder: isAscending
          ? SortDirectionType.Ascending
          : SortDirectionType.Descending
      }
    })
  }

  const sortedPhysicians = sortPhysicians(
    physicians || [],
    physicianSortConfig.sortColumn as string,
    physicianSortConfig.sortOrder
  )

  const physicianTableHeader = () =>
    physiciansColumns.map((column, index) => (
      <ClinPatientTableHeaderCell
        key={column.name}
        width={column.width}
        background={ClinTheme.colors.lightGrey}
        color={ClinTheme.colors.darkGrey}
      >
        <StyledClinTableOrderToggleWrapper>
          <ClinTableOrderToggle
            sortDirection={
              physicianSortConfig.sortColumn === column.name
                ? physicianSortConfig.sortOrder
                : SortDirectionType.None
            }
            onClick={() => handlePhysicianSortChange(column.name)}
          >
            {column.title}
          </ClinTableOrderToggle>
        </StyledClinTableOrderToggleWrapper>
      </ClinPatientTableHeaderCell>
    ))

  const documentTableHeader = () =>
    documentColumns.map((column: IProgramDocumentColumn, index) => {
      return (
        <ClinPatientTableHeaderCell
          key={column.name}
          width={column.width}
          background={ClinTheme.colors.lightGrey}
          color={ClinTheme.colors.darkGrey}
        >
          {column.isSortable ? (
            <StyledClinTableOrderToggleWrapper>
              <ClinTableOrderToggle
                sortDirection={
                  selectedDocumentSortColumn === column.name
                    ? (selectedDocumentSortDirection as SortDirectionType)
                    : SortDirectionType.None
                }
                onClick={() =>
                  handleDocumentToggleSort &&
                  handleDocumentToggleSort(column.name)
                }
              >
                {column.title}
              </ClinTableOrderToggle>
            </StyledClinTableOrderToggleWrapper>
          ) : (
            <StyledClinTableOrderToggleWrapper>
              <ClinTableOrderToggle hideSortIcon={true}>
                {column.title.toUpperCase()}
              </ClinTableOrderToggle>
            </StyledClinTableOrderToggleWrapper>
          )}
        </ClinPatientTableHeaderCell>
      )
    })
  const showRWDCard = getShowRWDCard(program)

  const getStatusColor = (status: string | undefined) => {
    switch (status) {
      case ProgramCountryStatus.AcceptingNewPatients:
        return {
          backgroundColor: 'rgba(0, 162, 35, 0.1)',
          textColor: ClinTheme.colors.greenValid
        }
      case ProgramCountryStatus.OpenForResupply:
        return {
          backgroundColor: 'rgba(215, 0, 0, 0.05)',
          textColor: ClinTheme.colors.orangeWarn
        }
      default:
        return {
          backgroundColor: 'rgba(83, 83, 83, 0.1)',
          textColor: ClinTheme.colors.darkGrey
        }
    }
  }

  const statusAttributesCard = () => {
    const { backgroundColor, textColor } = getStatusColor(
      program?.programCountry?.countryProgramStatus
    )

    return (
      <StyledStatusWrapper color={backgroundColor}>
        <ClinText
          fontWeight={ClinTheme.fontWeights.medium}
          color={textColor}
          fontSize={ClinTheme.fontSizes[0]}
          lineHeight={'2'}
        >
          {program?.programCountry?.countryProgramStatus}
        </ClinText>
      </StyledStatusWrapper>
    )
  }

  return isLoading || !program || !programUiState ? (
    <>
      {!hasError && (
        <ClinPageContentFrame className="program-detail">
          <Row align="center">
            <Col>
              <StyledSpinnerContainer>
                <ClinSpinner size={ClinTheme.space[7]} />
              </StyledSpinnerContainer>
            </Col>
          </Row>
        </ClinPageContentFrame>
      )}
    </>
  ) : (
    <>
      {program?.projectId && programUiState && (
        <ProgramDetailHeader
          programId={program.projectId}
          showStockOrderButton={program.programCountry?.stock === 'Y'}
          programName={program.programName}
          sponsor={program.sponsor}
          programCountryStatus={program.programCountry?.countryProgramStatus}
          isProgramEnrolled={isProgramEnrolled}
          programInfoVariant={programUiState.sideBarMode}
        />
      )}
      <ClinPageContentFrame
        className="program-detail"
        hideBreadcrumbs={true}
        crumbs={[
          { path: '/orders', name: t('orders:page_name') },
          {
            name: t('access_programs:title'),
            path: '/programs/access-programs'
          },
          {
            name: program?.programName ?? '',
            path: `/programs/access-programs/${program?.programDocumentId}`
          }
        ]}
      >
        <ClinSpacer height={24} />
        <Row>
          <Col md={12} lg={10}>
            <StyledProgramAttributes>
              {program.programName && (
                <StyledProgramAttributesCardWrapper>
                  <StyledProgramAttributesCard>
                    <ClinText
                      marginBottom={16}
                      fontSize={ClinTheme.fontSizes[0]}
                      color={ClinTheme.colors.darkGrey}
                    >
                      {t('program_detail:product')}
                    </ClinText>
                    <ClinText fontWeight={ClinTheme.fontWeights.medium}>
                      {program.programName}
                    </ClinText>
                  </StyledProgramAttributesCard>
                </StyledProgramAttributesCardWrapper>
              )}
              {program.programCountry?.countryProgramStatus && (
                <StyledProgramAttributesCardWrapper
                  width={averageMedicalReviewTimeExists ? '25%' : '35%'}
                >
                  <StyledVerticalLine
                    height="60%"
                    width="1px"
                    backgroundColor={ClinTheme.colors.midGrey}
                  />
                  <StyledProgramAttributesCard>
                    <ClinText
                      marginBottom={10}
                      fontSize={ClinTheme.fontSizes[0]}
                      color={ClinTheme.colors.darkGrey}
                    >
                      {t('program_detail:status')}
                    </ClinText>
                    {statusAttributesCard()}
                  </StyledProgramAttributesCard>
                </StyledProgramAttributesCardWrapper>
              )}
              {averageMedicalReviewTimeExists && (
                <StyledProgramAttributesCardWrapper width={'25%'}>
                  <StyledVerticalLine
                    height="60%"
                    width="1px"
                    backgroundColor={ClinTheme.colors.midGrey}
                  />
                  <StyledProgramAttributesCard>
                    <ClinText
                      marginBottom={16}
                      fontSize={ClinTheme.fontSizes[0]}
                      color={ClinTheme.colors.darkGrey}
                    >
                      {t('program_detail:average_medical_review_time')}
                    </ClinText>
                    <ClinText fontWeight={ClinTheme.fontWeights.medium}>
                      {program.program?.averageMedicalReviewTime ??
                        t('program_detail:varies')}
                    </ClinText>
                  </StyledProgramAttributesCard>
                </StyledProgramAttributesCardWrapper>
              )}
              {
                <StyledProgramAttributesCardWrapper
                  width={averageMedicalReviewTimeExists ? '25%' : '40%'}
                >
                  <StyledVerticalLine
                    height="60%"
                    width="1px"
                    backgroundColor={ClinTheme.colors.midGrey}
                  />
                  <StyledProgramAttributesCard>
                    <ClinText
                      marginBottom={16}
                      fontSize={ClinTheme.fontSizes[0]}
                      color={ClinTheme.colors.darkGrey}
                    >
                      {t('program_detail:estimated_delivery')}
                    </ClinText>
                    <ClinText fontWeight={ClinTheme.fontWeights.medium}>
                      {t('product_detail:estimated_delivery_at_checkout')}
                    </ClinText>
                  </StyledProgramAttributesCard>
                </StyledProgramAttributesCardWrapper>
              }
            </StyledProgramAttributes>
          </Col>
        </Row>
        <ClinSpacer height={16} />
        <StyledTabsRow>
          <Col md={12} lg={10}>
            <StyledTabSection
              selectedTabIndex={selectedTabIndex}
              style={{ minWidth: '508px' }}
            >
              {tabData.map((tab) => (
                <ProgramDetailTabLink
                  key={tab.tabIndex}
                  linkText={tab.linkText}
                  showCountNumber={tab.showCountNumber}
                  countNumber={tab.countNumber}
                  tabIndex={tab.tabIndex}
                  active={selectedTabIndex === tab.tabIndex}
                  isProgramEnrolled={isProgramEnrolled}
                  onClick={() => handleChangeTab(tab.tabIndex)}
                />
              ))}
            </StyledTabSection>
          </Col>
        </StyledTabsRow>
        {selectedTabIndex === 0 && (
          <>
            {isProgramEnrolled ? (
              <Row>
                <Col md={12} lg={10}>
                  <PatientDashboardContainer
                    history={history}
                    location={location}
                    match={match}
                    showTableInTab={true}
                    programName={program.programName}
                    isProgramOpenForResupply={
                      program.programCountry?.countryProgramStatus ===
                      ProgramCountryStatus.OpenForResupply
                    }
                    handleResultCountChange={handlePatientCountChanged}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={12} lg={10}>
                  <UnenrolledProgramsInfo
                    programId={program.projectId}
                    programInfoVariant={programUiState.sideBarMode}
                  ></UnenrolledProgramsInfo>
                </Col>
              </Row>
            )}
          </>
        )}

        {selectedTabIndex === 1 && (
          <>
            <ClinSpacer height={5} />
            <Row>
              <Col md={12} lg={10}>
                {isLoading ? (
                  <StyledSpinnerContainer>
                    <ClinSpinner size={ClinTheme.space[7]} />
                  </StyledSpinnerContainer>
                ) : isPhysicianExists ? (
                  <ClinTable
                    className="TableOverflowVisible"
                    borderColapseSeparate={true}
                    borderSpacing="0 8px"
                    padding="0px 2px 0px 2px"
                    tableHeader={physicianTableHeader}
                  >
                    {sortedPhysicians.map((physician, rowIndex) => (
                      <ClinTableRow
                        background={ClinTheme.colors.white}
                        key={physician.physicianId}
                        onRowClick={() =>
                          handlePhysicianRowClicked(physician.physicianId)
                        }
                      >
                        {physiciansColumns.map((column) => (
                          <ClinTableBodyCell
                            key={column.name}
                            style={{ padding: '15px 24px 15px 16px' }}
                          >
                            {column.renderValue(physician, rowIndex)}
                          </ClinTableBodyCell>
                        ))}
                      </ClinTableRow>
                    ))}
                  </ClinTable>
                ) : (
                  <></>
                )}
                {
                  <StyledAddPhysicianTextWrapper>
                    <ClinText
                      fontSize={ClinTheme.fontSizes[0]}
                      fontWeight={ClinTheme.fontWeights.medium}
                      lineHeight={ClinTheme.lineHeights.largeParagraph}
                    >
                      {t('program_detail:add_physician_text')}
                    </ClinText>
                    <StyledAddPhysicianButton
                      variant="purpleSecondaryNarrow"
                      onClick={() =>
                        history.push({
                          pathname: `/programs/access-programs/${program?.projectId}/physician-enrolment-form`
                        })
                      }
                    >
                      {t('program_detail:add_physician_button')}
                    </StyledAddPhysicianButton>
                  </StyledAddPhysicianTextWrapper>
                }
              </Col>
            </Row>
          </>
        )}

        {selectedTabIndex === 2 && (
          <>
            <Row>
              <Col md={12} lg={10}>
                <ProgramDocumentsSearch
                  handleOnChange={(query) => handleDocumentQueryChanged(query)}
                  initialQuery={documentsQuery}
                ></ProgramDocumentsSearch>
              </Col>
              <ClinSpacer height={5} />
              <Col md={12} lg={10}>
                {isLoading ? (
                  <StyledSpinnerContainer>
                    <ClinSpinner size={ClinTheme.space[7]} />
                  </StyledSpinnerContainer>
                ) : documentCount ? (
                  <ClinTable
                    className="TableOverflowVisible"
                    borderColapseSeparate={true}
                    borderSpacing="0 8px"
                    padding="0px 2px 0px 2px"
                    tableHeader={documentTableHeader}
                  >
                    {programDocuments?.map(
                      (document: DocumentSearchResultDto, rowIndex) => {
                        return (
                          <ClinTableRow
                            background={ClinTheme.colors.white}
                            key={document.generatedId}
                            onRowClick={() => {
                              handleDocumentRowClicked &&
                                handleDocumentRowClicked(
                                  document.programDocument,
                                  document.generatedId
                                )
                            }}
                            onRowEnter={() => {
                              handleDocumentRowClicked &&
                                handleDocumentRowClicked(
                                  document.programDocument,
                                  document.generatedId
                                )
                            }}
                          >
                            {documentColumns.map(
                              (
                                column: IProgramDocumentColumn,
                                index: number
                              ) => (
                                <ClinTableBodyCell
                                  key={column.name}
                                  style={{
                                    padding: '11px 24px 11px 16px',
                                    boxSizing: 'border-box',
                                    height: '56px'
                                  }}
                                >
                                  {column.renderValue(document, rowIndex)}
                                </ClinTableBodyCell>
                              )
                            )}
                          </ClinTableRow>
                        )
                      }
                    )}
                  </ClinTable>
                ) : (
                  <NoProgramDocuments />
                )}
              </Col>
            </Row>
            <ClinSpacer />
            <Row>
              <Col md={12} lg={10}>
                {!isLoading &&
                programDocuments?.length &&
                documentPagination &&
                getTotalPages(documentPagination) ? (
                  <ClinPagination
                    showTabsPagination={true}
                    showLeftSpacer={true}
                    currentPage={getCurrentPage(documentPagination)}
                    totalPages={getTotalPages(documentPagination)}
                    pageSize={documentPagination.take}
                    handlePageSelected={(pageIndex) =>
                      handleDocumentPageClicked &&
                      handleDocumentPageClicked(pageIndex)
                    }
                    handlePageSizeChange={handleDocumentPageSizeChanged}
                  />
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </>
        )}
        {showRWDCard && <RealWorldDataCardProgramDetails />}
      </ClinPageContentFrame>
    </>
  )
}
