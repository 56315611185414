import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton/ClinButton'
import { mediaQuery } from '../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]
const tabletUpBreakpoint = ClinTheme.breakpoints[2]

export const StyledRealWorldDataCard = styled.aside({
  position: 'relative',
  top: '0',
  right: '0',
  width: 'auto',
  borderRadius: ClinTheme.radii[3],
  background: ClinTheme.colors.white,
  textAlign: 'center',
  border: `1px solid ${ClinTheme.colors.grey}`,
  overflow: 'hidden',
  [mediaQuery(mobileUpBreakpoint)]: {
    position: 'relative',
    top: '0',
    right: '0',
    width: 'auto'
  },
  [mediaQuery(tabletUpBreakpoint)]: {
    position: 'relative',
    width: '266px',
    img: {
      width: '100%'
    }
  }
})

export const StyledRealWorldDataCardHeader = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '5px 10px',
  backgroundColor: ClinTheme.colors.primaryLightest,
  color: ClinTheme.colors.white,
  cursor: 'pointer',
  borderRadius: '5px'
})

export const StyledRealWorldDataCardContent = styled.div`
  padding: ${ClinTheme.space[3]}px ${ClinTheme.space[3]}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${mediaQuery(mobileUpBreakpoint)} {
    padding: ${ClinTheme.space[4]}px ${ClinTheme.space[3]}px;
  }

  p {
    line-height: 24px;
  }
`

export const StyledLearnMoreLink = styled.button`
  font-family: ${ClinTheme.fonts.body};
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  color: ${ClinTheme.colors.tealishBlue};
  background: none;
  padding: 0;
`

// Forwarding ref to StyledLearnMoreButton
export const StyledLearnMoreButton = styled(ClinButton)({
  padding: `7px ${ClinTheme.space[6]}px`,
  textTransform: 'initial',
  fontSize: '14px',
  lineHeight: `${ClinTheme.space[3]}px`,
  border: `1px solid ${ClinTheme.colors.primaryLight}`,
  borderWidth: ClinTheme.borderWidths[1],
  color: ClinTheme.colors.primaryLight,
  height: '48px',
  ':hover': {
    backgroundColor: ClinTheme.colors.primaryLight,
    color: ClinTheme.colors.white,
    borderColor: ClinTheme.colors.primaryLight
  }
})

export const StyledOverlayText = styled.div<{ isClickable: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  vertical-align: middle;
  width: 186px;
  height: 61px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/images/button-background.svg');
  color: ${ClinTheme.colors.white};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
  border-radius: ${ClinTheme.radii[1]}px;
  p {
    padding-top: 14px;
    color: ${ClinTheme.colors.white};
    text-align: center;
  }
`

export const StyledEnrolledIndicator = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: ${ClinTheme.space[2]}px;
  gap: ${ClinTheme.space[2]}px;
  background-color: ${ClinTheme.colors.lightenGreen};
  color: ${ClinTheme.colors.greenValid};
  border-radius: ${ClinTheme.radii[3]}px;
`
export const StyledNomineeDetails = styled.div`
  width: 100%;
`

export const StyledRealWorldDataCardImage = styled.div<{ isEnrolled: boolean }>`
  position: relative;
  width: 100%;
  background-image: url('/images/rwd-bg.jpg');
  height: ${(props) => (props.isEnrolled ? '90px' : '160px')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
