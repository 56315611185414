import React, { FunctionComponent } from 'react'
import Select from 'react-select'

import {
  StyledClinMultiSelect,
  StyledDropdownIndicator,
  StyledClearIndicator,
  StyledMultiValueRemove,
  getReactSelectStyles
} from './ClinMultiSelect.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinText } from '../ClinText'

export type ClinMultiSelectOption = {
  label: string
  value: string
}

interface IClinMultiSelectProps {
  /** Label for the form field */
  label?: string
  /** Placeholder if no value is selected */
  placeholder?: string
  /** The label to display below the input */
  prompt?: string
  /**  Sets field styles to error */
  hasError?: boolean
  /** List of possible select options */
  options: ClinMultiSelectOption[]
  /** List of selected options*/
  value: ClinMultiSelectOption[]
  /** Function that's called when the selection changes */
  onChange: (value: ClinMultiSelectOption[]) => void
}

const DropdownIndicator = ({ innerProps, isFocused }: any) => {
  return (
    <StyledDropdownIndicator {...innerProps}>
      <ClinIcon
        iconName={ClinIconPathName.ChevronSmallUpDown}
        iconFill={
          isFocused ? ClinTheme.colors.black : ClinTheme.colors.darkGrey
        }
      />
    </StyledDropdownIndicator>
  )
}

const ClearIndicator = ({ innerProps }: any) => {
  return (
    <StyledClearIndicator {...innerProps}>
      <ClinIcon
        iconName={ClinIconPathName.Cross}
        iconFill={ClinTheme.colors.darkGrey}
      />
    </StyledClearIndicator>
  )
}

const MultiValueRemove = ({ innerProps }: any) => {
  return (
    <StyledMultiValueRemove {...innerProps}>
      <ClinIcon
        iconName={ClinIconPathName.Cross}
        iconFill={ClinTheme.colors.primaryMid}
      />
    </StyledMultiValueRemove>
  )
}

export const ClinMultiSelect: FunctionComponent<IClinMultiSelectProps> = ({
  options,
  value,
  onChange,
  label,
  prompt,
  hasError,
  placeholder = 'Please select',
  ...props
}) => {
  return (
    <StyledClinMultiSelect {...props}>
      {label && (
        <ClinText
          as="div"
          fontSize={ClinTheme.fontSizes[1]}
          color="inherit"
          marginBottom={ClinTheme.space[2]}
        >
          {label}
        </ClinText>
      )}
      <Select
        options={options}
        value={value}
        placeholder={placeholder}
        isMulti={true}
        onChange={(value: any) =>
          // cast to ClinMultiSelectOption[] because we know that this is a
          // multiselect, but TypeScript doesn't
          onChange((value || []) as ClinMultiSelectOption[])
        }
        components={{
          DropdownIndicator,
          ClearIndicator,
          MultiValueRemove
        }}
        styles={getReactSelectStyles(hasError)}
      />
      {prompt && (
        <ClinText
          as="div"
          fontSize={ClinTheme.fontSizes[1]}
          color={hasError ? ClinTheme.colors.redInvalid : 'inherit'}
          marginTop={ClinTheme.space[2]}
        >
          {prompt}
        </ClinText>
      )}
    </StyledClinMultiSelect>
  )
}
