import React, { FunctionComponent } from 'react'
import { useFeatureFlags } from '../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../context/featureFlags/FeatureFlagKeys'
import { AddedToBasketConfirmationOld } from './old/AddedToBasketConfirmationOld'
import { AddedToBasketConfirmationNew } from './new/AddedToBasketConfirmationNew'

export interface IBasketItemDetails {
  title: string
  sku: string
  quantity: number
  amount: number
  currencyCode: string
  requiresDocumentation: boolean
}

interface IAddedToBasketConfirmationProps {
  /** The basket item details */
  basketItemDetails: IBasketItemDetails
  /**Flag when the item is removed */
  wasItemRemoved?: boolean
  /** Conditional check for AUS users */
  countryCode?: string
  /** Handle the callback for going to the basket */
  handleGoToBasket?: () => void
  /** Handle the callback for going to the Checkout */
  handleProceedToCheckout?: () => void
  /** Handle closing the confirmation */
  handleClose?: () => void
}

export const AddedToBasketConfirmation: FunctionComponent<
  IAddedToBasketConfirmationProps
> = (props) => {
  const { useFeatureFlag } = useFeatureFlags()
  const enableNewBasketPopup = useFeatureFlag(FeatureFlagKeys.NewBasketPopup)

  return (
    <>
      {enableNewBasketPopup ? (
        <AddedToBasketConfirmationNew {...props} />
      ) : (
        <AddedToBasketConfirmationOld {...props} />
      )}
    </>
  )
}
