import { DateTime } from 'luxon'

import {
  FeatureFlag,
  FeatureFlagVariant,
  FeatureFlagWithTargetingFilter,
  FeatureFlagWithTimeWindow,
  TimeWindowFilter
} from './FeatureFlagModels'
import { RelationshipDto, UserDto } from '../../types/swaggerTypes'

// AudienceParameters interface
export interface AudienceParameters {
  IncludedUsers: string[]
  ExcludedUsers: string[]
  Groups: string[]
  ExcludedCountries: string[]
  IncludedCountries: string[]
  IncludedRoles: string[]
  ExcludedRoles: string[]
  DefaultRolloutPercentage: number
}

// Type guards
export function isFeatureFlagWithTargetingFilter(
  flag: FeatureFlag
): flag is FeatureFlagWithTargetingFilter {
  return (
    (flag as FeatureFlagWithTargetingFilter).conditions.client_filters[0]
      ?.name === FeatureFlagVariant.Targeting
  )
}

export function isFeatureFlagWithTimeWindow(
  flag: FeatureFlag
): flag is FeatureFlagWithTimeWindow {
  return (
    (flag as FeatureFlagWithTimeWindow).conditions.client_filters[0]?.name ===
    FeatureFlagVariant.TimeWindow
  )
}

export const parseFeatureFlagData = (raw: any) => {
  const parsedData: Record<string, FeatureFlag> = {}
  try {
    for (const key in raw) {
      if (Object.prototype.hasOwnProperty.call(raw, key)) {
        const flag: FeatureFlag = JSON.parse(raw[key])
        parsedData[flag.id] = flag
      }
    }
  } catch (error) {
    console.error(
      'Error parsing feature flag data, please raise an issue with our customer services: ',
      error
    )
    return {} // Abort if json is malformed, means a breaking change in Azure App Config structure
  }
  return parsedData
}

export const evaluateFeatureFlags = (
  parsedFeatureFlagData: Record<string, FeatureFlag>,
  userDetails: UserDto | undefined
) => {
  const evaluatedFlags: Record<string, boolean> = {}
  for (const key in parsedFeatureFlagData) {
    if (parsedFeatureFlagData.hasOwnProperty(key)) {
      evaluatedFlags[key] = evaluateFeatureFlag(
        parsedFeatureFlagData[key],
        userDetails
      )
    }
  }
  return evaluatedFlags
}

// Check if the flag is always enabled based on its configuration
const isBaseFeatureFlag = (featureFlag: FeatureFlag): boolean => {
  return featureFlag.conditions.client_filters.length === 0
}

// Check user's inclusion or exclusion
const checkUserInclusion = (
  email: string,
  audience: AudienceParameters
): boolean | null => {
  const { IncludedUsers = [], ExcludedUsers = [] } = audience || {}

  // User explicitly excluded
  if (Array.isArray(ExcludedUsers) && ExcludedUsers.includes(email)) {
    return false
  }

  // User explicitly included
  if (Array.isArray(IncludedUsers) && IncludedUsers.includes(email)) {
    return true
  }

  // If there are included users but current user is not in the list
  if (Array.isArray(IncludedUsers) && IncludedUsers.length > 0) {
    return false
  }

  // If there are excluded users and the user is not in that list
  if (Array.isArray(ExcludedUsers) && ExcludedUsers.length > 0) {
    return true
  }

  // No specific inclusion/exclusion rules for this user
  return null
}

// Check role inclusion or exclusion
const checkRoleInclusion = (
  userRoles: string[],
  audience: AudienceParameters
): boolean | null => {
  const { IncludedRoles = [], ExcludedRoles = [] } = audience || {}

  // Check if user's role is included
  const isRoleIncluded =
    Array.isArray(IncludedRoles) &&
    IncludedRoles.some((role) => userRoles.includes(role))

  // Check if user's role is excluded
  const isRoleExcluded =
    Array.isArray(ExcludedRoles) &&
    ExcludedRoles.some((role) => userRoles.includes(role))

  // If user's role is excluded but not included
  if (isRoleExcluded && !isRoleIncluded) {
    return false
  }

  // If user's role is included
  if (isRoleIncluded) {
    return true
  }

  // No specific role rules match
  return null
}

// Check country inclusion or exclusion
const checkCountryInclusion = (
  userCountryCodes: string[],
  audience: AudienceParameters
): boolean | null => {
  const { IncludedCountries = [], ExcludedCountries = [] } = audience || {}

  const isCountryIncluded =
    Array.isArray(IncludedCountries) &&
    IncludedCountries.some((country) => userCountryCodes.includes(country))

  const isCountryExcluded =
    Array.isArray(ExcludedCountries) &&
    ExcludedCountries.some((country) => userCountryCodes.includes(country))

  // If there are included countries and not excluded
  if (
    Array.isArray(IncludedCountries) &&
    IncludedCountries.length > 0 &&
    !isCountryExcluded
  ) {
    return isCountryIncluded
  }

  // If there are excluded countries and not included
  if (
    Array.isArray(ExcludedCountries) &&
    ExcludedCountries.length > 0 &&
    !isCountryIncluded
  ) {
    return !isCountryExcluded
  }

  // No specific country rules match
  return null
}

// Check if user passes rollout percentage
const checkRolloutPercentage = (percentage: number): boolean => {
  if (percentage <= 0) return false
  if (percentage >= 100) return true

  const randomPercentage = Math.random() * 100
  return randomPercentage <= percentage
}

// Evaluate targeting filter
const evaluateTargetingFilter = (
  featureFlag: FeatureFlagWithTargetingFilter,
  userDetails: UserDto | undefined
): boolean => {
  const targetingFilter = featureFlag.conditions.client_filters.find(
    (filter) => filter.name === FeatureFlagVariant.Targeting
  )

  if (!targetingFilter) return false

  const audience: AudienceParameters = targetingFilter.parameters?.Audience || {
    IncludedUsers: [],
    ExcludedUsers: [],
    Groups: [],
    ExcludedCountries: [],
    IncludedCountries: [],
    IncludedRoles: [],
    ExcludedRoles: [],
    DefaultRolloutPercentage: 0
  }
  const currentUser = userDetails?.contactCard.email

  if (!currentUser) return false

  const userCountryCodes = getShipToCountryCodes()
  const userRoles = getUserRoles()

  // Check role rules first
  const roleResult = checkRoleInclusion(userRoles, audience)
  if (roleResult !== null) return roleResult

  // Then check user rules
  const userResult = checkUserInclusion(currentUser, audience)
  if (userResult !== null) return userResult

  // Then check country rules
  const countryResult = checkCountryInclusion(userCountryCodes, audience)
  if (countryResult !== null) return countryResult

  // Last resort - use default rollout percentage
  return checkRolloutPercentage(audience.DefaultRolloutPercentage)
}

// Evaluate time window filter
const evaluateTimeWindowFilter = (
  featureFlag: FeatureFlagWithTimeWindow
): boolean => {
  const timeWindowFilter: TimeWindowFilter | undefined =
    featureFlag.conditions.client_filters.find(
      (filter) => filter.name === FeatureFlagVariant.TimeWindow
    )

  if (!timeWindowFilter) return false

  const currentTime: DateTime = DateTime.now()
  const endTime: DateTime = DateTime.fromFormat(
    timeWindowFilter.parameters.End,
    "EEE, dd MMM yyyy HH:mm:ss 'GMT'"
  )

  return currentTime < endTime
}

// Main feature flag evaluation function - now simplified
export const evaluateFeatureFlag = (
  featureFlag: FeatureFlag,
  userDetails: UserDto | undefined
): boolean => {
  // Base checks
  if (!featureFlag.enabled) {
    return false
  }

  if (isBaseFeatureFlag(featureFlag)) {
    return featureFlag.enabled
  }

  if (isAlwaysEnabledFeatureFlag(featureFlag)) {
    console.log('Always enabled feature flag:', featureFlag.id)
    return true
  }

  // Type-specific evaluations
  if (isFeatureFlagWithTargetingFilter(featureFlag)) {
    return evaluateTargetingFilter(featureFlag, userDetails)
  }

  if (isFeatureFlagWithTimeWindow(featureFlag)) {
    return evaluateTimeWindowFilter(featureFlag)
  }

  // Default fallback
  return false
}

const getCurrentUser = () => {
  const currentUserJson = localStorage.getItem('current_user')
  if (!currentUserJson) {
    console.error('No current user found in local storage')
    return null
  }

  try {
    return JSON.parse(currentUserJson)
  } catch (error) {
    console.error('Error parsing current user data from local storage:', error)
    return null
  }
}

const getShipToCountryCodes = () => {
  const currentUser = getCurrentUser()
  if (!currentUser) {
    return []
  }

  const contactCard = currentUser.contactCard
  if (!contactCard || !contactCard.clientRelationships) {
    console.error('Invalid contact card data')
    return []
  }

  return contactCard.clientRelationships.map(
    (relationship: RelationshipDto) => relationship.shipToCountryCode
  )
}

const getUserRoles = () => {
  const currentUser = getCurrentUser()
  if (!currentUser) {
    return []
  }

  const contactCard = currentUser.contactCard
  if (!contactCard || !contactCard.clientRelationships) {
    console.error('Invalid contact card data')
    return []
  }

  return contactCard.clientRelationships.map(
    (relationship: RelationshipDto) => relationship.roleType
  )
}

const isAlwaysEnabledFeatureFlag = (featureFlag: FeatureFlag): boolean => {
  if (
    featureFlag.enabled &&
    featureFlag.conditions.client_filters.length === 1 &&
    featureFlag.conditions.client_filters[0].name ===
      FeatureFlagVariant.Targeting
  ) {
    const audience = (
      featureFlag.conditions.client_filters[0].parameters as any
    )?.Audience
    if (audience) {
      const isArrayEmptyOrUndefined = (arr: any[] | undefined): boolean =>
        !arr || arr.length === 0

      return (
        isArrayEmptyOrUndefined(audience.IncludedUsers) &&
        isArrayEmptyOrUndefined(audience.ExcludedUsers) &&
        isArrayEmptyOrUndefined(audience.IncludedCountries) &&
        isArrayEmptyOrUndefined(audience.ExcludedCountries) &&
        isArrayEmptyOrUndefined(audience.IncludedRoles) &&
        isArrayEmptyOrUndefined(audience.ExcludedRoles) &&
        isArrayEmptyOrUndefined(audience.Groups) &&
        audience.DefaultRolloutPercentage === 0
      )
    }
  }
  return false
}
