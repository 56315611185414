import styled from 'styled-components'

import { IButtonIconStyles } from './ClinButtonIcon'
import { ClinTheme } from '../../ClinTheme'

export const StyledButtonIcon = styled.button<IButtonIconStyles>(
  {
    alignItems: 'center',
    borderRadius: '50%',
    border: 'none',
    fontFamily: ClinTheme.fonts.body,
    fontSize: ClinTheme.fontSizes[2],
    padding: ClinTheme.space[0],
    lineHeight: ClinTheme.lineHeights.body,
    justifyContent: 'center',
    textDecoration: 'none',
    outline: 'none',
    WebkitTapHighlightColor: ClinTheme.colors.transparent,
    transition: '.2s linear background-color, .2s linear box-shadow',
    whiteSpace: 'nowrap',
    backgroundColor: ClinTheme.colors.transparent,
    a: {
      textDecoration: 'none',
      color: 'inherit',
      ':focus': {
        outline: 'none'
      }
    }
  },
  ({ display, disabled, hoverColor }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    color: disabled ? ClinTheme.colors.midGrey : ClinTheme.colors.black,
    display,
    ':hover: enabled': {
      color: hoverColor || ClinTheme.colors.secondary
    },
    ':focus': {
      boxShadow: `0 0 0 2px ${ClinTheme.colors.midGrey}`
    }
  })
)
