import {
  UserRole,
  UserRoleWithCountry,
  getUserRoleWithCountry
} from '../constants'

export const redirectUser = (
  portfolioCountryCode: string,
  userRole: UserRole | undefined,
  previousPathname?: string,
  redirectUrl?: string
) => {
  // Get the user role with country (still needed in case we want to extend functionality later)
  const userRoleWithCountry = getUserRoleWithCountry(
    portfolioCountryCode,
    userRole
  )

  // Set the default redirect URL
  let locationHref = redirectUrl || ''

  // If we have a previous pathname, use that instead
  if (previousPathname) {
    locationHref = previousPathname
  }

  window.location.href = locationHref
}
