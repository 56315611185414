import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { StyledClinText } from '../../components/ClinText/ClinText.styles'
import { mediaQuery } from '../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledInstitutes = styled.div({
  marginTop: ClinTheme.space[3],
  marginBottom: ClinTheme.space[5],
  borderTop: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.midGrey}`,
  [mediaQuery(mobileUpBreakpoint)]: {
    marginTop: ClinTheme.space[4]
  }
})

export const StyledInstitute = styled.div({
  paddingTop: ClinTheme.space[4],
  paddingBottom: ClinTheme.space[4],
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.midGrey}`
})

export const StyledSubmit = styled.div({
  paddingTop: ClinTheme.space[4],
  paddingBottom: ClinTheme.space[4],
  [mediaQuery(mobileUpBreakpoint)]: {
    marginTop: ClinTheme.space[3]
  }
})

export const StyledLoaderContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '300px'
})

export const StyledActions = styled.div({
  display: 'inline-flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',

  'button:last-of-type': {
    marginBottom: ClinTheme.space[3]
  },

  [mediaQuery(mobileUpBreakpoint)]: {
    flexDirection: 'row',
    flexWrap: 'nowrap',

    button: {
      marginBottom: 0,
      marginRight: ClinTheme.space[3]
    },

    'button:last-of-type': {
      marginBottom: 0
    }
  }
})

export const StyleRequestAccessCta = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginTop: ClinTheme.space[4],
  marginBottom: ClinTheme.space[3]
})

export const StyleRequestAccessCtaMessage = styled(StyledClinText)({
  marginLeft: ClinTheme.space[2],
  [mediaQuery(mobileUpBreakpoint)]: {
    maxWidth: ClinTheme.space[8] * 5
  }
})

export const StyledAccessCta = styled(StyledClinText)({
  color: ClinTheme.colors.primaryMid,
  textDecoration: 'underline',
  display: 'none',
  marginLeft: ClinTheme.space[3],
  cursor: 'pointer',

  [mediaQuery(mobileUpBreakpoint)]: {
    display: 'block'
  }
})

export const StyledAccessCtaInline = styled(StyledClinText)({
  display: 'inline-block',
  color: ClinTheme.colors.primaryMid,
  textDecoration: 'underline',
  margin: 0,

  [mediaQuery(mobileUpBreakpoint)]: {
    display: 'none'
  }
})
