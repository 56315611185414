export enum PatientsColumn {
  Patient = 'Patient',
  Program = 'Program',
  TreatingPhysician = 'TreatingPhysician',
  LastUpdated = 'LastUpdated',
  PatientStatus = 'PatientStatus',
  Actions = 'Actions'
}

export enum OPAOrderTypes {
  Resupply = 'Resupply',
  InitialOrder = 'Initial Order',
  StockOrder = 'Stock Order'
}

export enum PatientOrderStatus {
  UnderReview = 'Under Review',
  UnderMedicalReview = 'Under Medical Review',
  HealthAuthorityReview = 'Health authority review',
  MedicalReviewDeclined = 'Medical review declined',
  HealthAuthorityDeclined = 'Health authority declined',
  Processing = 'Processing',
  InTransit = 'In Transit',
  OutForDelivery = 'Out for delivery',
  FailedAttempt = 'Failed attempt',
  Delivered = 'Delivered',
  Available_for_Pickup = 'Available for Pickup',
  Expired = 'Expired',
  Pending = 'Pending',
  Shipped = 'Shipped',
  FailedDelivery = 'Failed Delivery',
  ReadyForResupply = 'Ready for resupply',
  PatientDiscontinued = 'This patient has been discontinued',
  ProgramClosed = 'Program closed',
  Delayed = 'Delayed',
  BeginOrder = 'Begin your order',
  IncompletePAF = 'Continue with your order',
  IncompleteCheckout = 'One more step to go',
  Submitted = 'Submitted',
  Cancelled = 'Cancelled'
}

export const getRandomColor = (value: number) => {
  const colors = [
    '#FFBDA2',
    '#80D091',
    '#9BE7E4',
    '#8C88FF',
    '#9DCDE8',
    '#E8AEFF'
  ]

  const colorArray: string[] = []

  for (let i = 0; i < value; i++) {
    const randomIndex = Math.floor(Math.random() * colors.length)
    colorArray.push(colors[randomIndex])
  }

  return colorArray
}

export enum ActionDropdownConstants {
  BeginOrder = 'BeginOrder',
  DiscontinuePatient = 'DiscontinuePatient',
  ContinueOrder = 'ContinueOrder',
  OrderResuply = 'OrderResuply',
  TrackYourOrder = 'TrackYourOrder',
  ReconcileVials = 'ReconcileVials',
  TransferPatient = 'TransferPatient',
  OneMoreStepToGo = 'OneMoreStepToGo',
  DeletePatient = 'DeletePatinet',
  NoActions = 'NoActions'
}

export enum CreatePatientsViewMode {
  ShowForm = 'ShowForm',
  Submitting = 'Submitting',
  Loading = 'Loading',
  DuplicatePatient = 'Duplicate'
}

export const getGenders = () => {
  return [
    'patient_detail:create_patients.gender_male',
    'patient_detail:create_patients.gender_female'
  ]
}

export const patientOrderStatusTranslation = {
  [PatientOrderStatus.UnderReview]:
    'patient_detail:patient_order_statuses.under_review',
  [PatientOrderStatus.UnderMedicalReview]:
    'patient_detail:patient_order_statuses.under_medical_review',
  [PatientOrderStatus.HealthAuthorityReview]:
    'patient_detail:patient_order_statuses.health_authority_review',
  [PatientOrderStatus.MedicalReviewDeclined]:
    'patient_detail:patient_order_statuses.medical_review_declined',
  [PatientOrderStatus.HealthAuthorityDeclined]:
    'patient_detail:patient_order_statuses.health_authority_declined',
  [PatientOrderStatus.Processing]:
    'patient_detail:patient_order_statuses.processing',
  [PatientOrderStatus.InTransit]:
    'patient_detail:patient_order_statuses.in_transit',
  [PatientOrderStatus.OutForDelivery]:
    'patient_detail:patient_order_statuses.out_for_delivery',
  [PatientOrderStatus.FailedAttempt]:
    'patient_detail:patient_order_statuses.failed_attempt',
  [PatientOrderStatus.Delivered]:
    'patient_detail:patient_order_statuses.delivered',
  [PatientOrderStatus.Available_for_Pickup]:
    'patient_detail:patient_order_statuses.available_for_pickup',
  [PatientOrderStatus.Expired]: 'patient_detail:patient_order_statuses.expired',
  [PatientOrderStatus.Pending]: 'patient_detail:patient_order_statuses.pending',
  [PatientOrderStatus.Shipped]: 'patient_detail:patient_order_statuses.shipped',
  [PatientOrderStatus.Delayed]: '',
  [PatientOrderStatus.FailedDelivery]:
    'patient_detail:patient_order_statuses.failed_delivery',
  [PatientOrderStatus.ReadyForResupply]:
    'patient_detail:patient_order_statuses.ready_for_resupply',
  [PatientOrderStatus.PatientDiscontinued]:
    'patient_detail:patient_order_statuses.discontinued',
  [PatientOrderStatus.ProgramClosed]:
    'patient_detail:patient_order_statuses.program_closed',
  [PatientOrderStatus.BeginOrder]:
    'patient_detail:patient_order_statuses.new_patient',
  [PatientOrderStatus.IncompletePAF]:
    'patient_detail:patient_order_statuses.incomplete_paf',
  [PatientOrderStatus.IncompleteCheckout]:
    'patient_detail:patient_order_statuses.incomplete_checkout',
  [PatientOrderStatus.Submitted]:
    'patient_detail:patient_order_statuses.submitted',
  [PatientOrderStatus.Cancelled]:
    'patient_detail:patient_order_statuses.cancelled'
}

export const patientOrderStatusTooltipTranslation = {
  [PatientOrderStatus.UnderReview]:
    'patient_detail:patient_status_tooltip.under_review',
  [PatientOrderStatus.UnderMedicalReview]:
    'patient_detail:patient_status_tooltip.under_medical_review',
  [PatientOrderStatus.HealthAuthorityReview]:
    'patient_detail:patient_status_tooltip.health_authority_review',
  [PatientOrderStatus.MedicalReviewDeclined]:
    'patient_detail:patient_status_tooltip.declined_medical_review',
  [PatientOrderStatus.HealthAuthorityDeclined]:
    'patient_detail:patient_status_tooltip.health_authority_declined',
  [PatientOrderStatus.Processing]:
    'patient_detail:patient_status_tooltip.processing',
  [PatientOrderStatus.InTransit]:
    'patient_detail:patient_status_tooltip.in_transit',
  [PatientOrderStatus.OutForDelivery]:
    'patient_detail:patient_status_tooltip.out_for_delivery',
  [PatientOrderStatus.FailedAttempt]:
    'patient_detail:patient_status_tooltip.failed_attempt',
  [PatientOrderStatus.Delivered]:
    'patient_detail:patient_status_tooltip.delivered',
  [PatientOrderStatus.Available_for_Pickup]:
    'patient_detail:patient_status_tooltip.available_for_pickup',
  [PatientOrderStatus.Expired]: 'patient_detail:patient_status_tooltip.expired',
  [PatientOrderStatus.Pending]: 'patient_detail:patient_status_tooltip.pending',
  [PatientOrderStatus.Shipped]: 'patient_detail:patient_status_tooltip.shipped',
  [PatientOrderStatus.FailedDelivery]:
    'patient_detail:patient_status_tooltip.failed_delivery',
  [PatientOrderStatus.ReadyForResupply]:
    'patient_detail:patient_status_tooltip.ready_for_resupply',
  [PatientOrderStatus.PatientDiscontinued]:
    'patient_detail:patient_status_tooltip.discontinued',
  [PatientOrderStatus.ProgramClosed]:
    'patient_detail:patient_status_tooltip.program_closed',
  [PatientOrderStatus.BeginOrder]:
    'patient_detail:patient_status_tooltip.new_patient',
  [PatientOrderStatus.IncompletePAF]:
    'patient_detail:patient_status_tooltip.incomplete_paf',
  [PatientOrderStatus.IncompleteCheckout]:
    'patient_detail:patient_status_tooltip.incomplete_checkout',
  [PatientOrderStatus.Submitted]:
    'patient_detail:patient_status_tooltip.submitted',
  [PatientOrderStatus.Cancelled]:
    'patient_detail:patient_status_tooltip.cancelled'
}

/**
 * Maps non-standard status strings to their corresponding PatientOrderStatus values
 */
const specialStatusMappings: { [key: string]: PatientOrderStatus } = {
  Failed_Delivery: PatientOrderStatus.FailedDelivery,
  'In Transit': PatientOrderStatus.InTransit,
  'Under medical review': PatientOrderStatus.UnderMedicalReview,
  'Failed Attempt': PatientOrderStatus.FailedAttempt
}

/**
 * Normalizes order status strings to PatientOrderStatus values
 * Handles both special cases and standard mappings
 *
 * @param status - The original status string from API or other sources
 * @returns The normalized PatientOrderStatus value
 */
export function mapToPatientOrderStatus(status: string): string {
  // Check special mappings first
  if (status in specialStatusMappings) {
    return specialStatusMappings[status].toString()
  }

  // For statuses that don't need special mapping, return as is
  return status
}
