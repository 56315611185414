import { AxiosError } from 'axios'
import download from 'downloadjs'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { $enum } from 'ts-enum-util'

import { ProgramDocumentation } from './ProgramDocumentation'
import {
  DocumentationColumn,
  getColumnForSortField,
  getSortFieldForColumn
} from './ProgramDocumentation.models'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  decodeObjToUrlParams,
  getParamValueFor,
  IPagination,
  useUpdateQueryParam
} from '../../components/ClinPagination/ClinPagination.model'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  getProgramDocumentSearchFacets,
  cancelProgramDocumentSearch,
  programDocumentSearch,
  downloadDocumentById,
  AuthError
} from '../../services/ApiService'
import { IProgramDocument } from '../../types/IProgramDocument'
import {
  FacetDto,
  FieldFacetsDto,
  ProgramDocumentsSearchDto
} from '../../types/swaggerTypes'
import { getFilenameFromUrl } from '../../utils/getFilenameFromUrl'
import { useOnMount } from '../../utils/useOnMount'
import { getUpdatedFiltersForTag } from '../Products/ProductSearch/ProductSearch.models'
import {
  SideBarMode,
  useProgramStatus
} from '../Programs/ProgramDetail/ProgramDetail.models'

interface IProgramDocumentationRouteParams {
  programId: string
}
interface IProgramDocumentationProps
  extends RouteComponentProps<IProgramDocumentationRouteParams> {}

const rowsPerPage: number = 25

const defaultPagination: IPagination = {
  count: 0,
  skip: 0,
  take: rowsPerPage,
  total: 0
}

export const ProgramDocumentationContainer: FunctionComponent<
  IProgramDocumentationProps
> = ({ match, location, history }) => {
  const { dispatch, portfolioCountryCode, supportContact } = useAppContext()
  const { programId } = match.params
  const initialSearchParams: ProgramDocumentsSearchDto = {
    query: '*',
    filter: {
      programId: parseInt(programId),
      types: [],
      languages: [],
      countryCode: portfolioCountryCode
    },
    pagination: {
      skip: 0,
      take: rowsPerPage
    },
    sorting: {
      sortBy: 'documentTypeName',
      order: 'desc'
    }
  }
  const {
    isProgramLoading,
    isEnrolledProgramsLoading,
    program,
    programUiState
  } = useProgramStatus(programId)

  const isEnrolled =
    !isProgramLoading && programUiState?.sideBarMode === SideBarMode.Enrolled

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [documents, setDocuments] = useState<IProgramDocument[]>()
  const [documentTypeNameFacets, setDocumentTypeNameFacets] =
    useState<FacetDto[]>()
  const [documentLanguageFacets, setDocumentLanguageFacets] =
    useState<FacetDto[]>()
  const [pagination, setPagination] =
    React.useState<IPagination>(defaultPagination)

  // Restore page state from URL params
  const urlSearchParams = new URLSearchParams(location.search)

  // Query
  const [initialQuery] = React.useState<string>(() => {
    const documentQuery = urlSearchParams.get('q')
    return documentQuery === '*' ? '' : documentQuery || ''
  })

  // Restore pagination from URL params
  const [selectedDocumentTypeNameFacets, setSelectedDocumentTypeNameFacets] =
    React.useState<FacetDto[]>(() => {
      let previousSelectedFilters: FacetDto[] = []
      const appliedFilters = urlSearchParams.get(
        'selectedDocumentTypeNameFacets'
      )
      if (appliedFilters) {
        previousSelectedFilters = decodeObjToUrlParams(appliedFilters)
        initialSearchParams.filter.types = [
          ...previousSelectedFilters.map((f) => f.value)
        ]
      }
      return previousSelectedFilters
    })

  // Restore pagination from URL params
  const [selectedDocumentLanguageFacets, setSelectedDocumentLanguageFacets] =
    React.useState<FacetDto[]>(() => {
      let previousSelectedFilters: FacetDto[] = []
      const appliedFilters = urlSearchParams.get(
        'selectedDocumentLanguageFacets'
      )
      if (appliedFilters) {
        previousSelectedFilters = decodeObjToUrlParams(appliedFilters)
        initialSearchParams.filter.languages = [
          ...previousSelectedFilters.map((f) => f.value)
        ]
      }
      return previousSelectedFilters
    })

  // Restore page Size
  const [documentsPerPage, setDocumentsPerPage] = React.useState<number>(() => {
    const previousPageSize = urlSearchParams.get('pageSize')
    return previousPageSize ? parseInt(previousPageSize) : rowsPerPage
  })

  // Restore pagination from URL params
  initialSearchParams.pagination.take = getParamValueFor(
    'pageSize',
    urlSearchParams,
    rowsPerPage
  )

  // PageIndex
  defaultPagination.take = initialSearchParams.pagination.take
  initialSearchParams.pagination.skip =
    getParamValueFor('pageIndex', urlSearchParams) *
    initialSearchParams.pagination.take

  // Restore sort by column from URL params and update query
  const [sortColumn, setSortColumn] = React.useState<DocumentationColumn>(
    () => {
      const sortByColumn = urlSearchParams.get('sortBy')
      const previousSortBy = sortByColumn && decodeURIComponent(sortByColumn)

      const defaultSortByColumn = getColumnForSortField(
        initialSearchParams.sorting.sortBy
      )
      const restoredSortByColumn = $enum(DocumentationColumn).asValueOrDefault(
        previousSortBy,
        defaultSortByColumn
      )
      initialSearchParams.sorting.sortBy =
        getSortFieldForColumn(restoredSortByColumn)
      return restoredSortByColumn
    }
  )

  // Restore sort direction from URL params
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType>(
    () => {
      const previousSortDirection = urlSearchParams.get('sortDirection')
      const restoredSortDirection = $enum(SortDirectionType).asValueOrDefault(
        previousSortDirection,
        SortDirectionType.Ascending
      )
      initialSearchParams.sorting.order = restoredSortDirection.toString()
      return restoredSortDirection
    }
  )

  // Construct the search query
  initialSearchParams.filter.countryCode = portfolioCountryCode
  const [searchParams, setSearchParams] =
    React.useState<ProgramDocumentsSearchDto>({
      ...initialSearchParams,
      query: initialQuery,
      filter: initialSearchParams.filter
    })

  const handlePageClicked = (selectedPageIndex: number) => {
    cancelProgramDocumentSearch()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: (selectedPageIndex - 1) * documentsPerPage,
        take: documentsPerPage
      }
    })
  }

  const handlePageSizeChange = (pageSize: number) => {
    cancelProgramDocumentSearch()
    setIsLoading(true)
    setDocumentsPerPage(pageSize)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: 0,
        take: pageSize
      }
    })
  }

  const handleFilterToggle = (tag: FacetDto, filterGroupTitle: string) => {
    cancelProgramDocumentSearch()
    setIsLoading(true)
    const isCategoryFilter = filterGroupTitle === 'Category'
    const updatedFilters = getUpdatedFiltersForTag(
      tag,
      isCategoryFilter
        ? [...(selectedDocumentTypeNameFacets || [])]
        : [...(selectedDocumentLanguageFacets || [])]
    )

    let updateParams: ProgramDocumentsSearchDto

    if (isCategoryFilter) {
      updateParams = {
        ...searchParams,
        pagination: {
          ...searchParams.pagination,
          skip: 0
        },
        filter: {
          ...searchParams.filter,
          types: [...updatedFilters.map((f) => f.value)]
        }
      }
      setSelectedDocumentTypeNameFacets(updatedFilters)
    } else {
      updateParams = {
        ...searchParams,
        pagination: {
          ...searchParams.pagination,
          skip: 0
        },
        filter: {
          ...searchParams.filter,
          languages: [...updatedFilters.map((f) => f.value)]
        }
      }
      setSelectedDocumentLanguageFacets(updatedFilters)
    }

    setSearchParams(updateParams)
  }

  const handleClearFilters = () => {
    cancelProgramDocumentSearch()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        ...searchParams.pagination,
        skip: 0
      },
      filter: {
        programId: parseInt(programId),
        types: [],
        countryCode: portfolioCountryCode,
        languages: []
      }
    })
    setSelectedDocumentTypeNameFacets([])
    setSelectedDocumentLanguageFacets([])
  }

  const toggleSortDirection = (
    current: SortDirectionType
  ): SortDirectionType => {
    if (current === SortDirectionType.None) {
      return SortDirectionType.Descending
    }
    if (current === SortDirectionType.Descending) {
      return SortDirectionType.Ascending
    }
    return SortDirectionType.None
  }

  const handleToggleSort = (columnName: DocumentationColumn) => {
    cancelProgramDocumentSearch()
    setIsLoading(true)
    const newSortDirection =
      sortColumn === columnName
        ? toggleSortDirection(sortDirection)
        : SortDirectionType.Descending
    setSortDirection(newSortDirection)
    setSortColumn(columnName)
    const sortField = getSortFieldForColumn(columnName)
    setSearchParams({
      ...searchParams,
      sorting: {
        sortBy:
          newSortDirection === SortDirectionType.None
            ? `documentTypeName`
            : sortField,
        order: newSortDirection
      }
    })
  }

  const handleOnChange = (query: string) => {
    cancelProgramDocumentSearch()
    setIsLoading(true)
    setSearchParams((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        skip: 0
      },
      query
    }))
  }

  // This is the inital api call to get the facets for the program
  useOnMount(() => {
    setIsLoading(true)
    portfolioCountryCode &&
      programId &&
      getProgramDocumentSearchFacets(parseInt(programId), portfolioCountryCode)
        .then((response) => {
          const documentTypeName: FieldFacetsDto | undefined =
            response.data.facets &&
            response.data.facets.find((f) => f.field === 'documentTypeName')
          setDocumentTypeNameFacets(documentTypeName?.values)

          const documentLanguage: FieldFacetsDto | undefined =
            response.data.facets &&
            response.data.facets.find((f) => f.field === 'documentLanguage')

          setDocumentLanguageFacets(documentLanguage?.values)
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the document facets. ${error}`
            )
          )
        })
        .finally(() => setIsLoading(false))
  })

  // This collects the program documents
  useEffect(() => {
    // searchParams and pagination changes then make call
    portfolioCountryCode &&
      programId &&
      programDocumentSearch(searchParams)
        .then((response) => {
          if (response) {
            if (response.data.result) {
              const updatedDocuments: IProgramDocument[] =
                response.data.result.map((item) => item as IProgramDocument)
              setDocuments(updatedDocuments)
              const paginationDto = response.data.pagination
              paginationDto &&
                setPagination({
                  count: paginationDto.count,
                  skip: paginationDto.skip,
                  take: paginationDto.take,
                  total: paginationDto.total
                })
            }
          }
        })
        .catch((error: AxiosError) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching program documents. ${error.message}`
            )
          )
        })
        .finally(() => setIsLoading(false))
    return () => {
      cancelProgramDocumentSearch()
    }
  }, [dispatch, portfolioCountryCode, programId, searchParams])

  const handleDownload = (documentItem: IProgramDocument) => {
    const updatedDocuments =
      documents &&
      documents.map((document: IProgramDocument) => {
        if (document.generatedId === documentItem.generatedId) {
          document.isDownloading = true
        }
        return document
      })
    setDocuments(updatedDocuments)
    documentItem.generatedId &&
      downloadDocumentById(documentItem.generatedId.toString())
        .then((response) => {
          documentItem.documentTypeName &&
            documentItem.documentUrl &&
            download(
              response.data,
              getFilenameFromUrl(documentItem.documentUrl),
              documentItem.documentTypeName
            )
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.DocumentDownload,
            {
              ...documentItem,
              programId
            }
          )
        })
        .catch((error: AxiosError) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          window.scrollTo(0, 0)
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error downloading your document. ${error}`
            )
          )
        })
        .finally(() => {
          const updatedDocuments =
            documents &&
            documents.map((document: IProgramDocument) => {
              if (document.itemId === documentItem.itemId) {
                document.isDownloading = false
              }
              return document
            })
          setDocuments(updatedDocuments)
        })
  }

  // This redirects any user who attempts to access this page when not enrolled in the program
  useEffect(() => {
    // TODO:// Refactor into a navigation guard
    if (
      !isProgramLoading &&
      !isEnrolledProgramsLoading &&
      !isEnrolled &&
      programUiState
    ) {
      history.push(`/programs/access-programs/${programId}`)
    }
  }, [
    history,
    isProgramLoading,
    isEnrolledProgramsLoading,
    isEnrolled,
    programId,
    programUiState
  ])

  // Track changes to pagination and tabs as they update and update query string if it changes
  useUpdateQueryParam({ q: searchParams.query })
  useUpdateQueryParam({
    selectedDocumentTypeNameFacets
  })
  useUpdateQueryParam({
    selectedDocumentLanguageFacets
  })
  useUpdateQueryParam({ sortBy: sortColumn.toString() })
  useUpdateQueryParam({ sortDirection: sortDirection.toString() })
  useUpdateQueryParam({ pageSize: searchParams.pagination.take })
  useUpdateQueryParam({
    pageIndex: searchParams.pagination.skip / searchParams.pagination.take
  })

  return (
    <ProgramDocumentation
      isLoading={isProgramLoading || isEnrolledProgramsLoading || isLoading}
      program={program}
      documents={documents}
      categoryFilters={documentTypeNameFacets}
      selectedCategoryFilters={selectedDocumentTypeNameFacets}
      languageFilters={documentLanguageFacets}
      selectedLanguageFilters={selectedDocumentLanguageFacets}
      initialQuery={initialQuery}
      pagination={pagination}
      selectedSortColumn={sortColumn}
      selectedSortDirection={sortDirection}
      supportContact={supportContact}
      userCountry={portfolioCountryCode}
      handleFilterToggle={handleFilterToggle}
      handlePageClicked={handlePageClicked}
      handlePageSizeChange={handlePageSizeChange}
      handleClearFilters={handleClearFilters}
      handleToggleSort={handleToggleSort}
      handleOnChange={handleOnChange}
      handleDownload={handleDownload}
    />
  )
}
