import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../../components/ClinIcon'
import { mediaQueryDown } from '../../utils/mediaQuery'

const mobileBreakpoint = ClinTheme.breakpoints[1]

export interface IDocumentUploadStyleProps {
  /** Variant for SKU selector & Basket pages */
  isPreOrderVariant?: boolean
  /** Custom style for AUS */
  styleForAUS?: boolean
}

export const StyledDocumentIcon = styled(ClinIcon)({
  marginRight: ClinTheme.space[2]
})

export const StyledFileRowWrap = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '8px',
  padding: '16px 24px',
  backgroundColor: ClinTheme.colors.white
})

export const StyledFileRow = styled.div<IDocumentUploadStyleProps>(
  {
    display: 'flex',
    alignItems: 'center',
    [`.sku-information`]: {
      width: '100%'
    }
  },
  ({ isPreOrderVariant, styleForAUS }: IDocumentUploadStyleProps) => ({
    flexWrap: isPreOrderVariant ? (styleForAUS ? 'initial' : 'wrap') : 'initial'
  })
)

export const StyledFileRowAus = styled.div<IDocumentUploadStyleProps>(
  {
    display: 'flex',
    alignItems: 'center',
    [`.sku-information`]: {
      width: '100%'
    }
  },
  ({ isPreOrderVariant, styleForAUS }: IDocumentUploadStyleProps) => ({
    flexWrap: isPreOrderVariant ? (styleForAUS ? 'initial' : 'wrap') : 'initial'
  })
)

export const StyledButtonArea = styled.div(
  {
    marginLeft: 'auto',
    textAlign: 'right'
  },
  ({ styleForAUS }: IDocumentUploadStyleProps) => ({
    [`span, span:hover`]: {
      textDecoration: styleForAUS ? 'none ' : 'underline'
    }
  })
)

export const StyledFileAndIcon = styled.div({
  display: 'flex',
  width: '100%'
})

export const StyledFileName = styled.div({
  flexGrow: 1,
  width: 1,
  marginRight: ClinTheme.space[2]
})

export const FileInputLabel = styled.label({
  marginBottom: 0
})

export const StyledDocumentUpload = styled.div<IDocumentUploadStyleProps>(
  {
    [` button`]: {
      textDecoration: 'underline'
    },
    [`.clin-document-upload__prompt`]: {
      fontWeight: ClinTheme.fontWeights.medium,
      color: ClinTheme.colors.primary
    },
    [mediaQueryDown(mobileBreakpoint)]: {
      [` > ${StyledFileRow}`]: {
        display: 'block'
      },
      [` > ${StyledButtonArea}`]: {
        marginTop: ClinTheme.space[2]
      }
    }
  },
  ({ isPreOrderVariant }: IDocumentUploadStyleProps) => ({
    background: isPreOrderVariant
      ? ClinTheme.colors.transparent
      : ClinTheme.colors.lightGrey,
    padding: isPreOrderVariant
      ? `${ClinTheme.space[4]}px 0`
      : `${ClinTheme.space[4]}px ${ClinTheme.space[3]}px`,
    borderTop: isPreOrderVariant
      ? 'none'
      : '1px solid ' + ClinTheme.colors.grey,
    [`:last-child`]: {
      borderBottom: isPreOrderVariant
        ? 'none'
        : '1px solid ' + ClinTheme.colors.grey
    }
  })
)

export const StyledUploadDetails = styled.div({
  display: 'flex',
  alignItems: 'center',
  [mediaQueryDown(mobileBreakpoint)]: {
    flexDirection: 'column'
  }
})

export const StyledUploadDetailsDot = styled.span({
  display: 'inline-block',
  margin: '0 5px',
  marginTop: '-8px',
  [mediaQueryDown(mobileBreakpoint)]: {
    display: 'none'
  }
})

export const StyledUploadDetailsDate = styled.span({
  [mediaQueryDown(mobileBreakpoint)]: {
    marginLeft: '-20px'
  }
})
