import styled, { keyframes } from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import {
  ClinButtonIcon,
  IButtonIconProps
} from '../../../components/ClinButtonIcon'
import { IClinAnnounceBarProps } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'

interface ColumnProps {
  width?: string
}

interface IStyledIconProps {
  height?: number
  width?: number
}

const StyledSlideIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(-${ClinTheme.space[3]}px);
}
100% {
   opacity: 1;
    transform: translateY(0px);
  }
`

export const StyledAddedToBasketConfirmation = styled.div({
  border: '2px solid black',
  borderRadius: '8px',
  padding: `${ClinTheme.space[5]}px`,
  position: 'relative',
  [`.clin-announce`]: {
    marginBottom: ClinTheme.space[3]
  }
})

export const StyledCrossButton = styled(ClinButtonIcon)<IButtonIconProps>({
  position: 'absolute',
  top: '12px',
  right: '12px'
})

export const StyledHeader = styled.div({
  marginBottom: ClinTheme.space[3]
})

export const StyledBody = styled.div({})

export const Container = styled.div({
  width: '100%',
  overflow: 'hidden',
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '1fr 2fr 1fr',
  gap: '16px 8px'
})

export const RowBase = styled.div({
  display: 'contents',
  boxSizing: 'border-box',
  width: '100%'
})

export const Header = styled(RowBase)({
  marginBottom: '16px',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    height: '48px'
  }
})

export const Row = styled(RowBase)``

export const Column = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-child {
    min-width: 88px;
    text-align: right;
  }
`

export const StyledClinAnnounceBarSuccess = styled.div<IClinAnnounceBarProps>`
  display: flex;
  align-items: center;
  animation: ${StyledSlideIn} 0.15s ease-out;
  padding: 14px;
  border-radius: ${ClinTheme.radii[3]}px;
  background-color: ${ClinTheme.colors.lightenGreen};
  color: ${ClinTheme.colors.black};
  border: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 0}px;
  word-break: break-word;
`

export const StyledIcon = styled.img<IStyledIconProps>(
  {
    objectFit: 'contain',
    objectPosition: 'left'
  },
  ({ height, width }: IStyledIconProps) => ({
    marginRight: '12px',
    height: height ? `${height}px` : '20px',
    width: width ? `${width}px` : '27px'
  })
)
