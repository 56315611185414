import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { ClinTheme } from '../../../ClinTheme'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { UserRole, isAusGaUser } from '../../../constants'
import { DrugRequestForm } from '../../../features/DrugRequestForm'
import { useCheckUnableHomePage } from '../../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'
import { CreateShortageRequestDto } from '../../../types/swaggerTypes'

export interface IReportShortageProps {
  /** Form submission handler */
  handleFormSubmission: (data: CreateShortageRequestDto) => void
  /** Show an error if the submission API request failed */
  errorMessage?: string | null
  /** Whether the submission API request is in progress */
  isSubmitting?: boolean
  /** User country*/
  userCountry: string
  /** User country*/
  userRole?: UserRole
}

export const ReportShortage: FunctionComponent<IReportShortageProps> = ({
  handleFormSubmission,
  isSubmitting,
  errorMessage,
  userCountry,
  userRole
}) => {
  const { t } = useTranslation()
  const { enableNewHomePage } = useCheckUnableHomePage()
  return (
    <ClinPageContentFrame
      crumbs={[
        isAusGaUser(userCountry, userRole) && enableNewHomePage
          ? {
              path: '/home',
              name: t('navigation:home')
            }
          : { path: '/orders', name: t('orders:page_name') },
        {
          path: '/shortages/report',
          name: t('report_shortage:title')
        }
      ]}
    >
      <Row>
        <Col xs={12} lg={9}>
          <Row>
            <Col xs={12}>
              <ClinText
                as="h1"
                variant={TypographyVariant.H2}
                fontWeight={ClinTheme.fontWeights.bold}
              >
                {t('report_shortage:title')}
              </ClinText>
              <ClinText variant={TypographyVariant.LargeParagraph}>
                {t('report_shortage:description')}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[4]} />
          <DrugRequestForm
            handleFormSubmission={handleFormSubmission}
            variant="reportShortage"
            isSubmitting={isSubmitting}
            errorMessage={errorMessage}
          />
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
