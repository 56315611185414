import React, { FunctionComponent } from 'react'

import {
  StyledClinCenterTruncatedText,
  StyledTextStart,
  StyledTextEnd
} from './ClinCenterTruncatedText.styles'

interface IClinCenterTruncatedTextProps {
  /** Text to show in the ClinText */
  text: string
  /** Minimum number of characters to show from the end of the text */
  showLastNCharacters?: number
}

export const ClinCenterTruncatedText: FunctionComponent<
  IClinCenterTruncatedTextProps
> = ({ text, showLastNCharacters = 20 }) => {
  return (
    <StyledClinCenterTruncatedText>
      <StyledTextStart>{text.slice(0, -showLastNCharacters)}</StyledTextStart>
      <StyledTextEnd>{text.slice(-showLastNCharacters)}</StyledTextEnd>
    </StyledClinCenterTruncatedText>
  )
}
