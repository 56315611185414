import React, { FunctionComponent } from 'react'

import { ClinTheme } from '../../ClinTheme'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinSingleBox } from '../ClinSingleBox'
import { ClinText } from '../ClinText'
import {
  StyledClinThreeBoxes,
  IClinThreeBoxesStyles
} from './ClinThreeBoxes.styles'
import { TypographyVariant } from '../ClinText/ClinText.styles'

interface IClinThreeBoxesProps extends IClinThreeBoxesStyles {
  moduleTitle?: string
  firstBoxTitle: string
  secondBoxTitle: string
  thirdBoxTitle: string
  firstBoxEstimate?: string
  secondBoxEstimate?: string
  thirdBoxEstimate?: string
  firstBoxContent: string
  secondBoxContent: string
  thirdBoxContent: string
  firstBoxIcon: ClinIconPathName
  secondBoxIcon: ClinIconPathName
  thirdBoxIcon: ClinIconPathName
  isMaOrder?: boolean
  viewBox?: string
  optionalURL?: string
}

export const ClinThreeBoxes: FunctionComponent<IClinThreeBoxesProps> = (
  props
) => (
  <StyledClinThreeBoxes {...props}>
    <ClinText
      fontWeight={ClinTheme.fontWeights.bold}
      variant={props.isMaOrder ? TypographyVariant.H3 : TypographyVariant.H4}
      fontSize={
        props.isMaOrder ? ClinTheme.fontSizes[5] : ClinTheme.fontSizes[3]
      }
      width="100%"
    >
      {props.moduleTitle}
    </ClinText>
    <ClinSingleBox
      title={props.firstBoxTitle}
      isMaOrder={props.isMaOrder}
      estimate={props.firstBoxEstimate}
      description={props.firstBoxContent}
      icon={props.firstBoxIcon}
      backgroundColor={ClinTheme.colors.green}
      optionalURL={props.optionalURL}
    ></ClinSingleBox>
    <ClinSingleBox
      title={props.secondBoxTitle}
      isMaOrder={props.isMaOrder}
      viewBox={props.viewBox}
      estimate={props.secondBoxEstimate}
      description={props.secondBoxContent}
      icon={props.secondBoxIcon}
      backgroundColor={ClinTheme.colors.orange}
    ></ClinSingleBox>
    <ClinSingleBox
      title={props.thirdBoxTitle}
      isMaOrder={props.isMaOrder}
      estimate={props.thirdBoxEstimate}
      description={props.thirdBoxContent}
      icon={props.thirdBoxIcon}
      backgroundColor={ClinTheme.colors.primary}
      optionalURL={props.optionalURL}
    ></ClinSingleBox>
  </StyledClinThreeBoxes>
)
