import { t } from 'i18next'
import React, { FunctionComponent } from 'react'

import { StyledFlexSpacer, StyledNoPatients } from './NoPatients.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'

export const NoPatients: FunctionComponent = () => {
  return (
    <StyledNoPatients>
      <ClinSpacer height={ClinTheme.space[8]} />
      <ClinIcon
        iconName={ClinIconPathName.ProductCode}
        iconFill={ClinTheme.colors.grey}
        iconSize={170}
      />
      <StyledFlexSpacer />
      <ClinText
        textAlign={'center'}
        variant={TypographyVariant.H4}
        fontWeight={ClinTheme.fontWeights.bold}
        marginTop={ClinTheme.space[2]}
      >
        {t('physician_details:no_match')}
      </ClinText>
      <StyledFlexSpacer />
      <StyledFlexSpacer />
      <ClinSpacer height={ClinTheme.space[8]} />
    </StyledNoPatients>
  )
}
