import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'
import { ClinTableRow } from '../../../components/ClinTable/ClinTable'

export const StyledClinTableRow = styled(ClinTableRow)({
  background: ClinTheme.colors.white
})

/**
 * AutoSuggest CSS className selectors
  container:                'react-autosuggest__container',
  containerOpen:            'react-autosuggest__container--open',
  input:                    'react-autosuggest__input',
  inputOpen:                'react-autosuggest__input--open',
  inputFocused:             'react-autosuggest__input--focused',
  suggestionsContainer:     'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList:          'react-autosuggest__suggestions-list',
  suggestion:               'react-autosuggest__suggestion',
  suggestionFirst:          'react-autosuggest__suggestion--first',
  suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
  sectionContainer:         'react-autosuggest__section-container',
  sectionContainerFirst:    'react-autosuggest__section-container--first',
  sectionTitle:             'react-autosuggest__section-title'
 */
export const StyledAutoSuggestWrapper = styled.div({
  marginLeft: ClinTheme.space[2],
  marginRight: ClinTheme.space[3],
  position: 'relative',
  width: '100%',
  [`.react-autosuggest__container`]: {
    display: 'inline-flex',
    minWidth: '100%'
  },
  [`input`]: {
    width: '100%',
    color: ClinTheme.colors.black,
    fontSize: ClinTheme.fontSizes[2],
    fontWeight: ClinTheme.fontWeights.normal,
    lineHeight: `${ClinTheme.fontSizes[4] - 2}px`, // subtract top and bottom border 1px widths
    letterSpacing: ClinTheme.letterSpacing[0],
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
    borderRadius: ClinTheme.radii[2],
    border: `solid 1px ${ClinTheme.colors.grey}`,
    transition: '.1s linear box-shadow',
    [`:focus`]: {
      outline: 'none',
      border: `solid 1px ${ClinTheme.colors.primaryMid}`,
      boxShadow: `0px 0px 3px 0px ${ClinTheme.colors.primaryMid}`
    },
    '::placeholder': {
      color: 'inherit'
    },
    ':-ms-input-placeholder': {
      // Special case for Text Inputs to have correct height in IE 11
      height: '40px'
    },
    ':disabled': {
      cursor: 'not-allowed'
    }
  },
  [`ul`]: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    backgroundColor: ClinTheme.colors.white,
    overflow: 'hidden'
  },
  [`li`]: {
    padding: `${ClinTheme.space[3]}px ${ClinTheme.space[3]}px`,
    margin: 0,
    whiteSpace: 'nowrap',
    borderBottom: `solid 1px ${ClinTheme.colors.grey}`,
    cursor: 'pointer',
    [`&:hover`]: {
      backgroundColor: ClinTheme.colors.lightGrey
    }
  },
  [`.react-autosuggest__suggestions-container`]: {
    position: 'absolute',
    left: 0,
    top: ClinTheme.space[6],
    right: 0,
    zIndex: ClinTheme.zIndices.dropdown,
    boxShadow: `0px 0px 6px 0px ${ClinTheme.colors.grey}`
  }
})
