import { t } from 'i18next'
import React, { FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'

import { StyledSubmit } from './NewShippingAddressExitModal.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinModal } from '../../../components/ClinModal'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'

interface INewShippingAddressExitModalProps {
  /** Control internal state of modal **/
  isOpen?: boolean
  /** Handle submittion to discontinue patient selection  **/
  handleSubmit: () => void
  /** Handle close   **/
  handleClose: () => void
}

export const NewShippingAddressExitModal: FunctionComponent<
  INewShippingAddressExitModalProps
> = ({ isOpen, handleSubmit, handleClose }) => {
  return (
    <ClinModal
      isDismissible={true}
      onClose={() => {
        handleClose()
      }}
      maxWidth="sm"
      height="auto"
      isOpen={isOpen}
    >
      <Container>
        <Row justify="center">
          <Col sm={12} md={11}>
            <Row>
              <Col sm={12} md={11}>
                <ClinText
                  as="h3"
                  variant={TypographyVariant.H3}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  {t('checkout_options:new_shipping_address_modal_title')}
                </ClinText>

                <ClinText variant={TypographyVariant.LargeParagraph}>
                  {t('checkout_options:new_shipping_address_modal_description')}
                </ClinText>
              </Col>
            </Row>

            <ClinSpacer height={ClinTheme.space[5]} />
            <Row>
              <Col sm={12} md={7}>
                <Row justify="between">
                  <Col md={5} lg={6}>
                    <StyledSubmit>
                      <ClinButton
                        className="cancel"
                        onClick={() => {
                          handleClose()
                        }}
                      >
                        {t('common:buttons.cancel')}
                      </ClinButton>
                      <ClinButton
                        className="submit"
                        variant="primary"
                        onClick={() => {
                          handleSubmit()
                        }}
                      >
                        {t('common:buttons.continue')}
                      </ClinButton>
                    </StyledSubmit>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </ClinModal>
  )
}
