export enum ConfirmationEventType {
  SHOW = 'ShowConfirmationEvent',
  HIDE = 'HideConfirmationEvent'
}

export interface IShowConfirmationEvent {
  type: ConfirmationEventType.SHOW
  children: JSX.Element
  autoHideAfterMs: number
  boxWidth?: number
  removePadding?: boolean
  marginRight?: number
  borderRadius?: number
  background?: string
  position?: string
  topPosition?: number
}

export const createConfirmationEvent = ({
  children,
  autoHideAfterMs = 5000,
  boxWidth,
  removePadding,
  marginRight,
  borderRadius,
  background,
  position,
  topPosition
}: {
  children: JSX.Element
  autoHideAfterMs?: number
  boxWidth?: number
  removePadding?: boolean
  marginRight?: number
  borderRadius?: number
  background?: string
  position?: string
  topPosition?: number
}): IShowConfirmationEvent => ({
  type: ConfirmationEventType.SHOW,
  children,
  autoHideAfterMs,
  boxWidth,
  removePadding,
  marginRight,
  borderRadius,
  background,
  position,
  topPosition
})

export interface IClearConfirmationEvent {
  type: ConfirmationEventType.HIDE
}

export const clearConfirmationEvent = (): IClearConfirmationEvent => ({
  type: ConfirmationEventType.HIDE
})

export type IConfirmationEvent =
  | IShowConfirmationEvent
  | IClearConfirmationEvent
