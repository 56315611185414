import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { ClinTheme } from '../../ClinTheme'
import { ClinCard } from '../../components/ClinCard'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'

interface IMaOrderPriceItemProps {
  title: string
  value: number
  currencyCode: string
  isVisible?: boolean
  paddingBottom?: number
  className?: string
}

export const MaOrderPriceItem: FunctionComponent<IMaOrderPriceItemProps> = ({
  title,
  value,
  currencyCode,
  isVisible = true,
  paddingBottom,
  className
}) => {
  if (!isVisible) return <></>

  return (
    <>
      <ClinGroup justifyContent="space-between" className={className}>
        <ClinText
          fontSize={ClinTheme.fontSizes[3]}
          fontWeight={ClinTheme.fontWeights.normal}
          color={ClinTheme.colors.black}
          marginRight={'20px'}
        >
          {title}
        </ClinText>
        <ClinText
          fontSize={ClinTheme.fontSizes[2]}
          fontWeight={ClinTheme.fontWeights.normal}
          color={ClinTheme.colors.black}
        >
          {numberToCurrencyString(value, getBrowserLocale(), {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </ClinText>
      </ClinGroup>
      {paddingBottom && <ClinSpacer height={paddingBottom} />}
    </>
  )
}

interface IMaOrderPriceProps {
  /** Subtotal */
  subtotal: number
  /** Price of summed charges */
  charges: number
  /** Nominal charge */
  nominalCharge: number
  /** Tax */
  tax: number
  /** Total */
  total: number
  /** Currency code */
  currencyCode: string
}

export const MaOrderPrice: FunctionComponent<IMaOrderPriceProps> = ({
  subtotal,
  charges,
  nominalCharge,
  tax,
  total,
  currencyCode
}) => {
  const { t } = useTranslation()
  return (
    <ClinCard>
      <ClinText
        as="h4"
        variant={TypographyVariant.H4}
        marginTop={'0'}
        fontWeight={ClinTheme.fontWeights.medium}
        color={ClinTheme.colors.black}
      >
        {t('ma_order_detail:order_summary.title')}
      </ClinText>
      <Row>
        <Col>
          <MaOrderPriceItem
            className="charges"
            title={t('ma_order_detail:order_summary.charges')}
            value={charges}
            currencyCode={currencyCode}
            isVisible={charges !== 0 && subtotal !== 0}
            paddingBottom={ClinTheme.space[2]}
          />
          <MaOrderPriceItem
            className="nominal-charge"
            title={t('ma_order_detail:order_summary.nominal_charge')}
            value={nominalCharge}
            currencyCode={currencyCode}
            isVisible={nominalCharge !== 0}
            paddingBottom={ClinTheme.space[2]}
          />
          <MaOrderPriceItem
            className="tax"
            title={t('ma_order_detail:order_summary.tax')}
            value={tax}
            currencyCode={currencyCode}
            isVisible={subtotal !== 0}
            paddingBottom={ClinTheme.space[1]}
          />
          <ClinSpacer hasBorder={true} />
          <ClinSpacer height={ClinTheme.space[2]} />
          <MaOrderPriceItem
            className="total"
            title={t('ma_order_detail:order_summary.total')}
            value={total}
            currencyCode={currencyCode}
          />
        </Col>
      </Row>
    </ClinCard>
  )
}
