import React, { FunctionComponent } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

import {
  StyledClinNavLink,
  StyledClinNavLinkInner,
  StyledClinSecondNavLinkInner,
  StyledClinUpperNavLink,
  StyledClinUpperNavLinkInner,
  StyledNavText
} from './ClinNavItem.styles'

export interface IClinNavItemProps extends NavLinkProps {
  /*** Displays a hyperlink rather than a NavLink so we can just show a button and disable the navigation **/
  disabled?: boolean
  /*** Override the current path active by applying a .active class manually **/
  current?: boolean
  showHighlight?: boolean
  displayInitialSentence?: boolean
}

export interface IStyledNavTextProps {
  showHighlight?: boolean
  textTransformStyle?: string
}

export const ClinNavItem: FunctionComponent<IClinNavItemProps> = (props) => {
  const { disabled, current, children, ...rest } = props
  return (
    <StyledClinNavLink
      as={disabled ? 'div' : NavLink}
      className={current ? 'clin-navitem active' : 'clin-navitem'}
      {...rest}
    >
      <StyledClinNavLinkInner>{children}</StyledClinNavLinkInner>
    </StyledClinNavLink>
  )
}

export const ClinNavUpperItem: FunctionComponent<IClinNavItemProps> = (
  props
) => {
  const { disabled, current, children, ...rest } = props
  return (
    <StyledClinUpperNavLink
      as={disabled ? 'div' : NavLink}
      className={current ? 'clin-navitem active' : 'clin-navitem'}
      {...rest}
    >
      <StyledNavText>
        <StyledClinUpperNavLinkInner>{children}</StyledClinUpperNavLinkInner>
      </StyledNavText>
    </StyledClinUpperNavLink>
  )
}

export const ClinNavSecondItem: FunctionComponent<IClinNavItemProps> = (
  props
) => {
  const {
    disabled,
    current,
    showHighlight,
    children,
    displayInitialSentence,
    ...rest
  } = props
  return (
    <StyledClinUpperNavLink
      as={disabled ? 'div' : NavLink}
      className={current ? 'clin-navitem active' : 'clin-navitem'}
      {...rest}
    >
      <StyledClinSecondNavLinkInner>
        <StyledNavText
          textTransformStyle={displayInitialSentence ? 'initial' : ''}
          showHighlight={showHighlight}
        >
          {children}
        </StyledNavText>
      </StyledClinSecondNavLinkInner>
    </StyledClinUpperNavLink>
  )
}
