import React, { FunctionComponent } from 'react'

import { StyledClinTableToggleRowButton } from './ClinTableToggleRowButton.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'

interface IClinTableToggleRowButton {
  /** Click event handler */
  onClick: () => void
  /** Whether the row is expanded */
  isToggled: boolean | undefined
  /** Use custom background based on isToggled */
  useCustomBackground?: boolean
}

export const ClinTableToggleRowButton: FunctionComponent<
  IClinTableToggleRowButton
> = ({ isToggled, useCustomBackground, onClick }) => (
  <StyledClinTableToggleRowButton
    className={`icon-toggle ${isToggled ? 'icon-toggle--open' : ''}`}
    iconName={ClinIconPathName.ChevronDown}
    iconFill={
      useCustomBackground ? ClinTheme.colors.white : ClinTheme.colors.primary
    }
    iconSize={ClinTheme.space[3]}
    isToggled={isToggled}
    useCustomBackground={useCustomBackground}
    onClick={(event) => {
      event.preventDefault()
      event.stopPropagation()
      onClick()
    }}
  />
)
