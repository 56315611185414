import React, { FunctionComponent } from 'react'

import {
  IStyledClinSpinnerProps,
  StyledClinSpinnerSvg
} from './ClinSpinner.styles'
import { ClinTheme } from '../../ClinTheme'

interface IClinSpinnerProps extends IStyledClinSpinnerProps {
  /** The time in seconds to make a complete rotation - default 1 second */
  spinDuration?: number
  /** The fillColor of the spinner */
  fillColor?: string
}

export const ClinSpinner: FunctionComponent<IClinSpinnerProps> = ({
  spinDuration = 1,
  size = ClinTheme.space[4],
  fillColor = ClinTheme.colors.primaryLight
}) => (
  <StyledClinSpinnerSvg
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 0 0"
    xmlSpace="preserve"
    width={size}
    height={size}
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      fill={fillColor}
      d="M12,0C5.4,0,0,5.4,0,12h2C2,6.5,6.5,2,12,2s10,4.5,10,10h2C24,5.4,18.6,0,12,0z"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur={`${spinDuration}s`}
        from="0 12 12"
        to="360 12 12"
        repeatCount="indefinite"
      />
    </path>
    <path d="M0 0h24v24H0z" fill="none" />
  </StyledClinSpinnerSvg>
)
