import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinButtonIcon } from '../ClinButtonIcon'

interface StyledProps {
  isToggled?: boolean
  useCustomBackground?: boolean
}

const getBackgroundColor = (
  isToggled?: boolean,
  useCustomBackground?: boolean
) => {
  if (useCustomBackground) {
    return isToggled
      ? ClinTheme.colors.redOrange
      : ClinTheme.colors.primaryLight
  }
  return ClinTheme.colors.white
}

export const StyledClinTableToggleRowButton = styled(
  ClinButtonIcon
)<StyledProps>(({ isToggled, useCustomBackground }) => ({
  padding: 6,
  marginTop: -3,
  transition: 'transform 0.3s',
  background: getBackgroundColor(isToggled, useCustomBackground),
  [`&.icon-toggle--open`]: {
    transform: 'rotate(180deg)'
  },
  ':hover': useCustomBackground
    ? {}
    : {
        boxShadow: `0 0 0 2px ${ClinTheme.colors.midGrey}`
      },
  [`:hover svg path:first-child`]: {
    fill: useCustomBackground
      ? ClinTheme.colors.white
      : ClinTheme.colors.magenta
  }
}))
