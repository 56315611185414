import styled from 'styled-components'

import { ClinTheme } from '../../../../ClinTheme'
import { ClinButton } from '../../../../components/ClinButton'
import { IStatusWrapperProps } from '../../../Patients/PatientDetail/PatientDetailCard.styles'

export const StyledStatusWrapper = styled.div<IStatusWrapperProps>(
  {},
  ({ color }: IStatusWrapperProps) => ({
    width: '70%',
    display: 'block',
    background: color,
    padding: '4px 10px',
    borderRadius: `${ClinTheme.space[2]}px`
  })
)
export const StyledBasketContainer = styled.div`
  min-width: 243px;
  width: 243px;
  height: 276px;
  background-color: ${ClinTheme.colors.lightGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 16px;
  border-radius: 10px;
  border: 2px solid ${ClinTheme.colors.black};
  padding: 25px;
  box-sizing: border-box;
  cursor: default;
`

export const StyledLicenseBox = styled.div`
  height: 26px;
  width: 100%;
`

export const StyledLabel = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
  padding: 5px;
  border-radius: 8px;
  background-color: #ede7f3;
`

export const StyledQuantityBox = styled.div`
  width: 100%;
  height: 95px;

  .quantity-label > .clin-text {
    font-size: ${ClinTheme.fontSizes[0]}px;
    line-height: ${ClinTheme.lineHeights.largeParagraph};
  }
`

export const StyledTotalBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`

export const StyledButtonLabel = styled.div`
  display: inline-block;
  margin: 0 5px;
`

export const StyledInlineBasketContainer = styled.div`
  min-width: 192px;
  width: 192px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: default;
  gap: 16px;

  .quantity-label > .clin-text {
    font-size: ${ClinTheme.fontSizes[0]}px;
    line-height: ${ClinTheme.lineHeights.small};
  }

  input {
    padding: 8px;
    text-align: right;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    margin-left: 8px;
    display: inline-block !important;
    opacity: 1 !important;
  }

  &.clin-mobile-view {
    min-width: 100%;
    width: 100%;
    align-items: start;

    input {
      height: 48px;
    }

    button {
      width: 70%;
      height: 48px;
    }
  }
`

export const StyledClinButton = styled(ClinButton)({
  padding: '8px 7px',
  fontSize: ClinTheme.fontSizes[2],
  height: '40px'
})

export const StyledClinButtonBlock = styled(ClinButton)({
  width: '100%',
  padding: '8px 10px',
  fontSize: ClinTheme.fontSizes[2]
})
