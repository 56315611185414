import { rgba } from 'polished'
import styled, { keyframes } from 'styled-components'

import { AnnounceMode, IClinAnnounceBarProps } from './ClinAnnounceBar'
import { ClinTheme } from '../../ClinTheme'

const getColorForMode = (mode: AnnounceMode): string => {
  switch (mode) {
    case AnnounceMode.Information:
      return ClinTheme.colors.primaryMid
    case AnnounceMode.Success:
      return ClinTheme.colors.greenValid
    case AnnounceMode.Error:
      return ClinTheme.colors.redInvalid
    case AnnounceMode.Warning:
      return ClinTheme.colors.orangeWarn
  }
}

const StyledSlideIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(-${ClinTheme.space[3]}px);
}
100% {
   opacity: 1;
    transform: translateY(0px);
  }
`

export const StyledClinAnnounceBar = styled.div<IClinAnnounceBarProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: ${StyledSlideIn} 0.15s ease-out;
  padding: ${ClinTheme.space[3]}px;
  border-radius: ${ClinTheme.radii[2]}px;
  background-color: ${({ mode }) => mode && rgba(getColorForMode(mode), 0.15)};
  color: ${({ mode }) => mode && getColorForMode(mode)};
  border: solid 1px ${({ mode }) => mode && getColorForMode(mode)};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 0}px;
  margin-top: 20px;
  word-break: break-word;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
`

export const StyledMessage = styled.div({
  marginRight: 'auto',
  marginLeft: ClinTheme.space[4],
  flex: '1 0 0'
})

export const StyledCloseIcon = styled.a({
  cursor: 'pointer'
})
