import React, { FunctionComponent } from 'react'
import {
  StyledAddedToBasketConfirmation,
  StyledBody,
  StyledHeader
} from './AddedToBasketConfirmationOld.styles'
import { ClinText } from '../../../components/ClinText'
import { ClinButtonIcon } from '../../../components/ClinButtonIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { numberToCurrencyString } from '../../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../../utils/getBrowserLocale'
import { useTranslation } from 'react-i18next'
import { CountryAlphaCodes } from '../../../constants/countryAlpha2Codes'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics/AnalyticsEvent'

export interface IBasketItemDetails {
  title: string
  sku: string
  quantity: number
  amount: number
  currencyCode: string
  requiresDocumentation: boolean
}

interface IAddedToBasketConfirmationOldProps {
  /** The basket item details */
  basketItemDetails: IBasketItemDetails
  /**Flag when the item is removed */
  wasItemRemoved?: boolean
  /** Conditional check for AUS users */
  countryCode?: string
  /** Handle the callback for going to the basket */
  handleGoToBasket?: () => void
  /** Handle closing the confirmation */
  handleClose?: () => void
}

export const AddedToBasketConfirmationOld: FunctionComponent<
  IAddedToBasketConfirmationOldProps
> = ({
  basketItemDetails,
  wasItemRemoved,
  handleGoToBasket,
  handleClose,
  countryCode
}) => {
  const { t } = useTranslation()
  const { title, sku, quantity, amount, currencyCode, requiresDocumentation } =
    basketItemDetails
  return (
    <StyledAddedToBasketConfirmation>
      <StyledHeader>
        <ClinGroup justifyContent={'space-between'}>
          <ClinText
            as={'div'}
            textTransform={'uppercase'}
            fontWeight={ClinTheme.fontWeights.medium}
            fontSize={ClinTheme.fontSizes[2]}
            color={ClinTheme.colors.primary}
          >
            {t('add_to_basket_confirmation:recently_added')}
          </ClinText>
          <ClinButtonIcon
            iconName={ClinIconPathName.Cross}
            iconFill={ClinTheme.colors.primary}
            onClick={() => {
              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.CloseCartModal
              )

              handleClose && handleClose()
            }}
          />
        </ClinGroup>
      </StyledHeader>
      <StyledBody>
        {wasItemRemoved ? (
          <ClinAnnounceBar mode={AnnounceMode.Information} title={' '}>
            <ClinText as={'div'} color={ClinTheme.colors.primaryMid}>
              {t('add_to_basket_confirmation:medicine_removed_accounce')}
            </ClinText>
          </ClinAnnounceBar>
        ) : (
          <ClinAnnounceBar mode={AnnounceMode.Success} title={' '}>
            <ClinText as={'div'} color={ClinTheme.colors.greenValid}>
              {t('add_to_basket_confirmation:medicine_add_to_basket')}
            </ClinText>
          </ClinAnnounceBar>
        )}
        {/* Order details */}
        {!wasItemRemoved && (
          <>
            <ClinGroup justifyContent={'space-between'}>
              <ClinText
                as={'div'}
                fontSize={ClinTheme.fontSizes[3]}
                fontWeight={ClinTheme.fontWeights.bold}
                color={ClinTheme.colors.primary}
              >
                {title}
              </ClinText>
              {/*<ClinButtonIcon*/}
              {/*  iconName={ClinIconPathName.Trashcan}*/}
              {/*  iconFill={ClinTheme.colors.primary}*/}
              {/*  onClick={handleRemoveItem}*/}
              {/*/>*/}
            </ClinGroup>
            <ClinSpacer />
            <ClinGroup justifyContent={'space-between'}>
              <ClinText
                as={'div'}
                variant={TypographyVariant.SmallUI}
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
              >
                {t('add_to_basket_confirmation:product_code')}
              </ClinText>
              <ClinText as={'div'}>{sku}</ClinText>
            </ClinGroup>
            <ClinSpacer />
            <ClinGroup justifyContent={'space-between'}>
              <ClinText
                as={'div'}
                variant={TypographyVariant.SmallUI}
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
              >
                {t('add_to_basket_confirmation:quantity')}
              </ClinText>
              <ClinText as={'div'}>{quantity}</ClinText>
            </ClinGroup>
            <ClinSpacer hasBorder={true} height={ClinTheme.space[6]} />
            <ClinGroup justifyContent={'space-between'} alignItems={'center'}>
              <ClinText
                as={'div'}
                variant={TypographyVariant.SmallUI}
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
              >
                {t('add_to_basket_confirmation:amount')}
              </ClinText>
              <ClinText
                as={'div'}
                variant={TypographyVariant.H4}
                className={'amount'}
              >
                {numberToCurrencyString(amount, getBrowserLocale(), {
                  style: 'currency',
                  currency: currencyCode,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </ClinText>
            </ClinGroup>
            <ClinSpacer height={ClinTheme.space[4]} />
            {/* Supporting documentation */}
            {countryCode !== CountryAlphaCodes.Australia &&
              requiresDocumentation && (
                <>
                  <ClinAnnounceBar mode={AnnounceMode.Information} title={' '}>
                    <ClinText as={'div'} color={ClinTheme.colors.primaryMid}>
                      {t(
                        'add_to_basket_confirmation:supporting_document_required'
                      )}
                    </ClinText>
                  </ClinAnnounceBar>
                  <ClinSpacer height={ClinTheme.space[4]} />
                </>
              )}
          </>
        )}
        <ClinButton
          display={'block'}
          variant={'primary'}
          onClick={handleGoToBasket}
        >
          {t('add_to_basket_confirmation:view_basket_checkout')}
        </ClinButton>
        <ClinSpacer hasBorder={true} height={ClinTheme.space[6]} />
        <ClinText as={'div'}>
          <strong>
            {t('add_to_basket_confirmation:note_label')} <br />
          </strong>
          {countryCode === CountryAlphaCodes.Australia
            ? t('add_to_basket_confirmation:aus_note')
            : t('add_to_basket_confirmation:note')}
        </ClinText>
      </StyledBody>
    </StyledAddedToBasketConfirmation>
  )
}
