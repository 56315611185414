import { t } from 'i18next'
import React, { FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'

import { ClinTheme } from '../../../../ClinTheme'
import { ClinAnnounceBar } from '../../../../components/ClinAnnounceBar'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinGroup } from '../../../../components/ClinGroup'
import { ClinLoader } from '../../../../components/ClinLoader'
import { ClinModal } from '../../../../components/ClinModal'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import {
  StyledOverlayLoader,
  StyledSubmit
} from '../../PatientDetail/DiscontinueConfirmationModal/DiscontinueConfirmationModal.styles'

interface IPatientDashboardDiscontinueModal {
  /** Control internal state of modal **/
  isOpen?: boolean
  /** displays error notification **/
  hasError?: boolean
  /** displays error notification **/
  errorMessage?: string
  /** Whether the submission is still processing **/
  isSubmitting: boolean
  /** Handle submittion to discontinue patient selection  **/
  selectedPatientHasVials: boolean
  handleSubmit: () => void
  /** Handle close   **/
  handleClose: () => void
}

export const PatientDashboardDiscontinueModal: FunctionComponent<
  IPatientDashboardDiscontinueModal
> = ({
  isOpen,
  hasError,
  errorMessage,
  isSubmitting,
  selectedPatientHasVials,
  handleSubmit,
  handleClose
}) => {
  return (
    <ClinModal
      onClose={() => {
        handleClose()
      }}
      maxWidth="sm"
      height="auto"
      isOpen={isOpen}
    >
      {selectedPatientHasVials && (
        <Container>
          <Row justify="center">
            <Col sm={12} md={11}>
              <Row>
                <Col sm={12} md={11}>
                  <ClinText
                    as="h3"
                    variant={TypographyVariant.H3}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {t('patient_detail:discontinue_warning_modal.title')}
                  </ClinText>

                  <ClinText variant={TypographyVariant.LargeParagraph}>
                    {t('patient_detail:discontinue_warning_modal.description')}
                  </ClinText>
                </Col>
              </Row>
              <ClinSpacer height={ClinTheme.space[5]} />
              <Row>
                <Col sm={12} md={7}>
                  <Row justify="between">
                    <Col md={5} lg={6}>
                      <ClinGroup>
                        <ClinButton
                          style={{ width: '100%' }}
                          className="close-warning"
                          variant="primary"
                          onClick={() => {
                            handleClose()
                          }}
                        >
                          {t('common:buttons.ok')}
                        </ClinButton>
                      </ClinGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <ClinSpacer />
            </Col>
          </Row>
        </Container>
      )}
      {!selectedPatientHasVials && (
        <Container>
          <Row justify="center">
            <Col sm={12} md={11}>
              {errorMessage && hasError && (
                <Row>
                  <Col>
                    <ClinAnnounceBar title={'Error'}>
                      {errorMessage}
                    </ClinAnnounceBar>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={12} md={10}>
                  <ClinText
                    as="h3"
                    variant={TypographyVariant.H3}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {t('patient_detail:discontinue_confirmation_modal.title')}
                  </ClinText>

                  <ClinText variant={TypographyVariant.LargeParagraph}>
                    {t(
                      'patient_detail:discontinue_confirmation_modal.description'
                    )}
                  </ClinText>
                </Col>
              </Row>

              <ClinSpacer height={ClinTheme.space[5]} />
              <Row>
                <Col sm={12} md={7}>
                  <Row justify="between">
                    <Col md={5} lg={6}>
                      <StyledSubmit>
                        <ClinButton
                          className="cancel"
                          onClick={() => {
                            handleClose()
                          }}
                        >
                          {t('common:buttons.cancel')}
                        </ClinButton>
                        <ClinButton
                          className="submit"
                          variant="primary"
                          onClick={() => {
                            handleSubmit()
                          }}
                        >
                          {t(
                            'patient_detail:discontinue_confirmation_modal.discontinue_btn'
                          )}
                        </ClinButton>
                      </StyledSubmit>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
      {isSubmitting && (
        <StyledOverlayLoader>
          <Container>
            <Row justify="center" align="center">
              <Col sm={12}>
                <ClinText
                  marginLeft="auto"
                  marginRight="auto"
                  marginBottom={ClinTheme.space[5]}
                  textAlign="center"
                  variant={TypographyVariant.LargeParagraph}
                >
                  {t('common:please_wait')}
                </ClinText>
                <ClinGroup justifyContent="center">
                  <div
                    style={{
                      height: ClinTheme.space[9] * 2,
                      width: ClinTheme.space[9] * 2
                    }}
                  >
                    <ClinLoader minHandDuration={'1s'} />
                  </div>
                </ClinGroup>
              </Col>
            </Row>
          </Container>
        </StyledOverlayLoader>
      )}
    </ClinModal>
  )
}
