import { AxiosError, AxiosResponse } from 'axios'
import download from 'downloadjs'
import { DateTime } from 'luxon'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, RouteComponentProps } from 'react-router'

import { ProductDetail } from './ProductDetail'
import {
  getDefaultWarehouseCodeForInstitute,
  getProductDocsInformation,
  getSourcingEnquiryLink,
  getVisibleProductDocuments
} from './ProductDetail.models'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  ProductItemAvailabilityStatus,
  isAusGaUser,
  isAusUser
} from '../../../constants'
import { CountryAlphaCodes } from '../../../constants/countryAlpha2Codes'
import { useAppContext } from '../../../context/app'
import { addBasketItemAsync, useBasket } from '../../../context/basket'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import {
  clearConfirmationEvent,
  createConfirmationEvent
} from '../../../events/ConfirmationEvent'
import { AddedToBasketConfirmation } from '../../../features/AddedToBasketConfirmation'
import { IBasketItemDetails } from '../../../features/AddedToBasketConfirmation/AddedToBasketConfirmation'
import { BookmarkToggleButtonContainer } from '../../../features/BookmarkToggleButton/BookmarkToggleButtonContainer'
import {
  shouldDisplayEstimatedDeliveryDateForAus,
  shouldDisplayEstimatedDeliveryDateProductPage
} from '../../../features/VariantCard/VariantCard.model'
import { useEffectOnlyOnce } from '../../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import i18n from '../../../i18n/config'
import { AnalyticsEvent } from '../../../services/Analytics'
import { AnalyticsPageEvent } from '../../../services/Analytics/AnalyticsPageEvent'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import {
  AuthError,
  cancelGetDistributor,
  cancelGetPriceAndAvailabilityForSku,
  cancelGetProductById,
  cancelGetProductBySku,
  cancelSkinnySourcing,
  cancelGetWarehouseForCode,
  downloadDocumentById,
  getDistributor,
  getPriceAndAvailabilityForSku,
  getProductById,
  getProductBySku,
  getWarehouseForCode,
  registerInterest,
  skinnySourcing,
  touchSkuForRecentlyViewed
} from '../../../services/ApiService'
import {
  getRequiresDocumentation,
  useAllHoldsInformation
} from '../../../services/Holds/holds'
import { HoldType } from '../../../services/Holds/holds.constants'
import { IHoldDetails } from '../../../services/Holds/holds.types'
import {
  getAvailableStockForTSEItem,
  getCanReOrder,
  getVariantForSkuGivenCountry,
  getVariantInformationForCatalogItem,
  IPortfolioState,
  isCMDrug,
  isGADrug,
  getRestrictedProduct
} from '../../../services/PortfolioJourneys/PortfolioJourney.model'
import { IAugmentedCatalogItemDto } from '../../../services/PortfolioJourneys/PortfolioJourney.types'
import {
  CatalogDto,
  DistributorDto,
  HoldDto,
  ItemDocumentDto,
  PriceAndAvailabilityDto,
  UploadedDocumentDto,
  WarehouseDto
} from '../../../types/swaggerTypes'
import { handleBasketError } from '../../../utils/basketUtils'
import {
  getBasketCurrency,
  hasMultipleCurrencies
} from '../../../utils/currencyUtils'
import { getFilenameFromUrl } from '../../../utils/getFilenameFromUrl'
import { noop } from '../../../utils/noop'
import { useErrorMessage } from '../../../utils/useErrorMessage'
import { validateQuantity } from '../../../utils/validateQuantity'
import { ProductSourceSystem } from '../Product.models'
import { getDefaultWarehouseCode } from '../ProductVariant/ProductVariant.models'
import { useFeatureFlags } from '../../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../../context/featureFlags/FeatureFlagKeys'

//region Imports
export interface IDocumentForBasketItem {
  sku: string
  holdDetails: IHoldDetails
  document: UploadedDocumentDto
}

export enum RequestSubmissionState {
  NotSubmitted,
  Submitting,
  Submitted
}

interface IProductDetailRouteParams {
  productId: string
  sku: string
}

export interface ProductDocumentUiState {
  isDownloading: boolean
  document: ItemDocumentDto
}

interface IProductDetailProps
  extends RouteComponentProps<IProductDetailRouteParams> {}

export interface IEddArray {
  skuAndEddArray: IEddItemForLocalStorage[]
}

export interface IEddItemForLocalStorage {
  itemSku?: string
  itemDisplayedStock?: number | undefined
}

//endregion

export const ProductDetailContainer: FunctionComponent<IProductDetailProps> = ({
  match,
  history
}) => {
  const { t } = useTranslation()
  const { useFeatureFlag } = useFeatureFlags()
  const isNewCheckoutPage = useFeatureFlag(
    FeatureFlagKeys.NewOneStepCheckoutPage
  )

  //region hook initialisers
  const { productId, sku } = match.params
  const [catalogId, setCatalogId] = React.useState<string | undefined>(
    productId
  )
  const isSkuOnly = !!matchPath(history.location.pathname, {
    path: '/product/sku/:sku'
  })
  const {
    dispatch,
    defaultShippingAddress,
    portfolioCountryCode,
    institute,
    userRole
  } = useAppContext()
  const [{ viewBasket }, basketDispatch] = useBasket()
  const [skinnySourcingButtonDisabled, setSkinnySourcingButtonDisabled] =
    React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isLoadingPrice, setIsLoadingPrice] = React.useState<boolean>(true)
  const [registerInterestSubmissionState, setRegisterInterestSubmissionState] =
    React.useState<RequestSubmissionState>(RequestSubmissionState.NotSubmitted)
  const [isAddingToBasket, setIsAddingToBasket] = React.useState<boolean>(false)
  const [unitPrice, setUnitPrice] = React.useState<number>(0)
  const [priceAndAvailability, setPriceAndAvailability] =
    React.useState<PriceAndAvailabilityDto>()
  const [holds, setHolds] = React.useState<HoldDto[]>([])
  const [minQuantity, setMinQuantity] = React.useState<number>(1)
  const [maxQuantity, setMaxQuantity] = React.useState<number | null>(null)
  const [stepQuantity, setStepQuantity] = React.useState<number | null>(null)
  const [quantity, setQuantity] = React.useState<number>(0)
  const [totalCost, setTotalCost] = React.useState<number>(0)
  const [hasCurrencyError, setHasCurrencyError] = React.useState<boolean>()
  const [catalog, setCatalog] = React.useState<CatalogDto>()
  const [catalogueItem, setCatalogueItem] =
    React.useState<IAugmentedCatalogItemDto>()
  const [quantityError, setQuantityError] = React.useState<string | undefined>()
  // Maintain all uploaded documents so we can submit them after add to basket
  const [basketItemDocuments, setBasketItemDocuments] = React.useState<
    IDocumentForBasketItem[]
  >([])
  const [distributor, setDistributor] = React.useState<DistributorDto>()
  const handleWarehouseError = useErrorMessage(
    'There was an error fetching warehouse details'
  )
  const [productDocuments, setProductDocuments] = useState<
    ProductDocumentUiState[] | undefined
  >()

  // Get all holds information
  const holdsInformation = useAllHoldsInformation(holds)
  //endregion

  //region Handlers
  const handleGoBack = () => {
    history.goBack()
  }

  const updateDocumentUiState = (
    documentItem: ItemDocumentDto,
    isLoading: boolean
  ) => {
    const updateLoadingDocument =
      productDocuments &&
      productDocuments?.map((productDocuments: ProductDocumentUiState) => {
        if (
          productDocuments.document.generatedId === documentItem.generatedId
        ) {
          productDocuments.isDownloading = isLoading
        }
        return productDocuments
      })
    updateLoadingDocument && setProductDocuments(updateLoadingDocument)
  }

  const handleDownload = (documentItem: ItemDocumentDto) => {
    updateDocumentUiState(documentItem, true)

    documentItem.generatedId &&
      downloadDocumentById(documentItem.generatedId)
        .then((response) => {
          documentItem.documentType &&
            documentItem.documentUrl &&
            download(
              response.data,
              getFilenameFromUrl(documentItem.documentUrl),
              documentItem.documentType
            )
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.DocumentDownload,
            {
              ...documentItem,
              catalogId
            }
          )
        })
        .catch((error: AxiosError) => {
          window.scrollTo(0, 0)
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error downloading your document. ${error}`
            )
          )
        })
        .finally(() => {
          updateDocumentUiState(documentItem, false)
        })
  }

  const handleSkinnySourcingRequest = () => {
    const errMsg = validateQuantity(
      quantity,
      minQuantity,
      maxQuantity,
      stepQuantity,
      getRestrictedProduct(catalogueItem)
    )
    if (errMsg) {
      setQuantityError(errMsg)
      return
    }
    setQuantityError(undefined)

    setSkinnySourcingButtonDisabled(true)
    catalogueItem?.item.sku &&
      skinnySourcing({
        itemNumber: catalogueItem.item.sku,
        requiredQty: quantity
      })
        .then((response) => {
          if (response.data.incidentNumber) {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.SourcingRequestSubmit,
              {
                sku: catalogueItem.item.sku
              }
            )
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Success,
                portfolioCountryCode === CountryAlphaCodes.Australia
                  ? `${t(
                      'product_detail:successful_skinny_sourcing_request_aus'
                    )}`
                  : `${t('product_detail:successful_skinny_sourcing_request')}`
              )
            )
          }
        })
        .catch((error: AxiosError) => {
          // If request is cancelled continue
          setSkinnySourcingButtonDisabled(false)
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error registering your interest in ${catalog?.catalogItemName}. ${error}`
            )
          )
          setIsLoading(false)
        })
        .finally(() =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        )
  }

  const handleAddToBasket = () => {
    const errMsg = validateQuantity(
      quantity,
      minQuantity,
      maxQuantity,
      stepQuantity,
      getRestrictedProduct(catalogueItem)
    )
    if (errMsg) {
      setQuantityError(errMsg)
      return
    }
    setQuantityError(undefined)
    if (catalogueItem && priceAndAvailability) {
      setIsAddingToBasket(true)
      addBasketItemAsync(
        basketDispatch,
        catalogueItem.sku,
        quantity,
        priceAndAvailability,
        basketItemDocuments
      )
        .then(() => {
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
          analyticsServiceSingleton.trackEvent(AnalyticsEvent.BasketAddItem, {
            sku: catalogueItem.sku,
            quantity: quantity,
            price: priceAndAvailability.listPrice,
            currency: priceAndAvailability.currencyCode
          })

          const localStorageData = localStorage.getItem('EDDs')
          const StoredEDDs: IEddArray = localStorageData
            ? JSON.parse(localStorageData)
            : { skuAndEddArray: [] }
          const newSkuEddItem: IEddItemForLocalStorage = {
            itemSku: catalogueItem.sku,
            itemDisplayedStock: catalogueItem.reservableStock ?? 0
          }
          const i = StoredEDDs.skuAndEddArray.findIndex(
            //check if already exist in local storage
            (e) => e.itemSku === newSkuEddItem.itemSku
          )
          if (i !== -1) {
            StoredEDDs.skuAndEddArray[i] = newSkuEddItem // if exist edit stock level
          } else {
            StoredEDDs.skuAndEddArray.push(newSkuEddItem) // not exist add it
          }

          localStorage.setItem('EDDs', JSON.stringify(StoredEDDs))
        })
        .catch((error) => {
          setIsAddingToBasket(false)
          handleBasketError(error, undefined, handleAddToBasket)
        })
    }
  }

  const handleUpdateQuantity = (quantity: number) => {
    setQuantity(quantity)
    const errMsg = validateQuantity(
      quantity,
      minQuantity,
      maxQuantity,
      stepQuantity,
      getRestrictedProduct(catalogueItem)
    )
    if (errMsg) {
      setQuantityError(errMsg)
      return
    }
    setQuantityError(undefined)
    setTotalCost(isNaN(quantity) ? 0 : quantity * unitPrice)
  }

  const determineEddForAnalytics = () => {
    let retVal: string = ''
    if (catalogueItem) {
      if (isAusGaUser(portfolioCountryCode, userRole)) {
        shouldDisplayEstimatedDeliveryDateForAus(catalogueItem)
          ? catalogueItem.reservableStock && catalogueItem?.reservableStock > 0
            ? (retVal = 'Delivered in 1-2 business days')
            : (retVal = 'Delivered in 7-14 business days')
          : (retVal = '')
      } else if (!isAusUser(portfolioCountryCode)) {
        shouldDisplayEstimatedDeliveryDateProductPage(
          catalogueItem.expectedDate,
          catalogueItem,
          catalogueItem.cutOffTime
        )
          ? (retVal = DateTime.fromISO(catalogueItem.expectedDate || '', {
              zone: 'utc'
            })
              .setLocale(i18n.language)
              .toFormat('MMM dd,yyyy'))
          : (retVal = '')
      }
    }
    return retVal
  }

  const handleGoToBasket = () => {
    history.push('/basket')
  }

  const handleProceedToCheckout = () => {
    history.push('/checkout')
  }

  const handleProceedToCheckoutOld = () => {
    history.push('/basket')
  }

  /**
   * Associate a sku with a hold document that has been uploaded
   * @param sku
   * @param holdDetails
   * @param document
   */
  const handleFileUploadedForBasketItem = (
    sku: string,
    holdDetails: IHoldDetails,
    document: UploadedDocumentDto
  ) => {
    const documentForBasketItem: IDocumentForBasketItem = {
      sku: sku,
      holdDetails: holdDetails,
      document: document
    }
    setBasketItemDocuments([...basketItemDocuments, documentForBasketItem])
  }

  const handleRegisterInterestForProduct = () => {
    setRegisterInterestSubmissionState(RequestSubmissionState.Submitting)
    catalogueItem?.item.sku &&
      registerInterest({
        itemNumber: catalogueItem.item.sku,
        getExclusiveFlag: catalogueItem.item.gaExclusive === 'Y' ? 'Y' : 'N',
        anyOtherInformation: ' ',
        launchPoint: catalogueItem.endpoint
          ? catalogueItem.endpoint.toString()
          : '0' // if endpoint isn't passed for some reason
      })
        .then((response) => {
          if (response.data.incidentNumber) {
            setRegisterInterestSubmissionState(RequestSubmissionState.Submitted)
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Success,
                `${t('product_detail:successful_registed_interest_part_one')}`,
                `${t('product_detail:successful_registed_interest_part_two', {
                  productName: catalog?.catalogItemName
                })}`
              )
            )
          }
        })
        .catch((error: AxiosError) => {
          // If request is cancelled continue
          setRegisterInterestSubmissionState(
            RequestSubmissionState.NotSubmitted
          )
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error registering your interest in ${catalog?.catalogItemName}. ${error}`
            )
          )
          setIsLoading(false)
        })
        .finally(() =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        )
  }

  //endregion
  //region useEffects

  // Update the SKU/CatalogItem with the portfolio journey information
  const augmentCatalogItem = useCallback(
    (
      warehouse?: WarehouseDto,
      pricingAndAvailability?: PriceAndAvailabilityDto
    ) => {
      // Find the sku
      const catItem = catalog?.items.find((cat) => cat.sku === sku)
      if (catItem) {
        // Augment catalogue item
        const augmentedCatalogueItem = getVariantInformationForCatalogItem(
          catItem,
          portfolioCountryCode,
          warehouse,
          [],
          pricingAndAvailability?.availableQuantity,
          pricingAndAvailability
        )
        // Get Distributor Warehouse information if required for this portfolio journey
        if (
          augmentedCatalogueItem.showDistributorInfo &&
          augmentedCatalogueItem.distributorName
        ) {
          getDistributor(augmentedCatalogueItem.distributorName)
            .then((response) => setDistributor(response.data))
            .catch((error: AxiosError) => {
              dispatch(
                createAnnounceEvent(
                  AnnounceMode.Error,
                  `There was an error fetching the distributor. ${error}`
                )
              )
            })
        }

        setCatalogueItem(augmentedCatalogueItem)

        // Get documents required
        const visibleProductDocuments = getVisibleProductDocuments(
          augmentedCatalogueItem
        )
        setProductDocuments(visibleProductDocuments)

        // Set min and max quantities
        setMinQuantity(
          catItem.item.minimumOrderQuantity
            ? catItem.item.minimumOrderQuantity
            : 1
        )
        // Add in TSE max order logic
        const isRestricted = getRestrictedProduct(catItem)
        const defaultWarehouse = getDefaultWarehouseCodeForInstitute(
          institute.data
        )
        const maximumOrderQuantity = isRestricted
          ? getAvailableStockForTSEItem(catItem, defaultWarehouse) // Calculate maximum quantity for TSE
          : catItem.item.maximumOrderQuantity
        setMaxQuantity(maximumOrderQuantity)
        setStepQuantity(catItem.item.incrementalOrderQuantity)
      }
    },
    [catalog, dispatch, institute.data, portfolioCountryCode, sku]
  )

  // Do price and availability call (if journey allows)
  useEffect(() => {
    // Only call Price and Availability if the journey allows
    // Get portfolio journey for this item and institute
    if (catalog && defaultShippingAddress && sku) {
      const catalogueItem = catalog.items.find((c) => c.sku === sku)
      if (catalogueItem) {
        const portfolioRules = catalogueItem?.portfolioRules
        const portfolioState: IPortfolioState | undefined =
          getVariantForSkuGivenCountry(
            portfolioRules,
            defaultShippingAddress?.country
          )
        const canReOrder = portfolioState
          ? getCanReOrder(portfolioState?.uiVariationState)
          : false

        // We can order it so get the price
        if (canReOrder) {
          setIsLoadingPrice(true)
          getPriceAndAvailabilityForSku(sku, defaultShippingAddress.addressId)
            .then((response) => {
              setPriceAndAvailability(response.data)
              setUnitPrice(parseFloat(response.data.listPrice))
              // Remove any Safety Letter holds
              const holdsMinusSafetyLetter = response.data.holds.filter(
                (h) => h.holdName !== HoldType.SafetyLetter.toString()
              )
              setHolds(holdsMinusSafetyLetter)
            })
            .catch((error) => {
              setIsLoadingPrice(false)
              if (error.message === AuthError.RequestCancelled) return
              dispatch(
                createAnnounceEvent(
                  AnnounceMode.Error,
                  `There was an error fetching the pricing. ${error}`
                )
              )
            })
        }

        if (!canReOrder && catalogueItem) {
          // If the product is not available on this portfolio journey then don't load price
          augmentCatalogItem()
          setIsLoadingPrice(false)
          setIsLoading(false)
        }
      }
    }
  }, [augmentCatalogItem, catalog, defaultShippingAddress, dispatch, sku])

  // Get product / sku information
  useEffect(() => {
    // Product & variant - Hit the page including variant (i.e. product/ef386707-lidocaine-hydrochloride/variant/1001257)
    if (catalogId) {
      getProductById(catalogId)
        .then((response: AxiosResponse<CatalogDto>) => {
          setCatalog(response.data)
        })
        .catch((error: AxiosError) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the product. ${error}`
            )
          )
          setIsLoading(false)
        })
    }
  }, [dispatch, catalogId])

  // Get product/catalog ID using sku information (When hitting the sku page directly (i.e. product/sku/1001257))
  useEffect(() => {
    if (isSkuOnly) {
      getProductBySku(sku)
        .then(({ data }) => {
          setCatalogId(data.parentCatalogDocumentId)
        })
        .catch((error: AxiosError) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the product. ${error}`
            )
          )
          setIsLoading(false)
        })
    }
  }, [sku, dispatch, isSkuOnly])

  // Get warehouse and augment the product/catalog
  useEffect(() => {
    if (catalog && priceAndAvailability) {
      // Get Warehouse
      setIsLoadingPrice(true)
      augmentCatalogItem()
      getWarehouseForCode(
        priceAndAvailability.defaultWarehouse ||
          getDefaultWarehouseCode(portfolioCountryCode)
      )
        .then((response) => {
          augmentCatalogItem(response.data, priceAndAvailability)
          setIsLoadingPrice(false)
        })
        .catch((error) => {
          console.warn(error)
          handleWarehouseError(error)
        })
    }
  }, [
    catalog,
    priceAndAvailability,
    handleWarehouseError,
    augmentCatalogItem,
    portfolioCountryCode
  ])

  // When basket updated show confirmation but not on initial mount
  const isInitialMount = useRef(true)
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else if (quantity && !isLoadingPrice && catalog && priceAndAvailability) {
      const basketItemDetails: IBasketItemDetails = {
        title: catalog.catalogItemName,
        sku: sku,
        quantity: quantity,
        amount: quantity * unitPrice,
        currencyCode:
          viewBasket?.currencyCode || priceAndAvailability?.currencyCode,
        requiresDocumentation: getRequiresDocumentation(holds)
      }
      setIsAddingToBasket(false)
      dispatch(
        createConfirmationEvent({
          children: (
            <AddedToBasketConfirmation
              basketItemDetails={basketItemDetails}
              handleGoToBasket={handleGoToBasket}
              handleProceedToCheckout={
                isNewCheckoutPage
                  ? handleProceedToCheckout
                  : handleProceedToCheckoutOld
              }
              wasItemRemoved={false}
              countryCode={portfolioCountryCode}
              handleClose={() => dispatch(clearConfirmationEvent())}
            />
          ),
          autoHideAfterMs: 10 * 1000,
          boxWidth: 360,
          position: 'absolute',
          topPosition: -75,
          removePadding: true
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewBasket, priceAndAvailability])

  // Determine if multiple currencies exists and show warning
  useEffect(() => {
    if (viewBasket && priceAndAvailability) {
      // Determine if main currency of basket is different
      const multipleCurrenciesExist = hasMultipleCurrencies(viewBasket)
      // Check main currency of the basket is different
      const isBasketCurrencyDifferent =
        viewBasket.currencyCode !== getBasketCurrency(viewBasket)
      const showCurrencyError =
        multipleCurrenciesExist || isBasketCurrencyDifferent
      if (showCurrencyError) {
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Warning,
            t('product_detail:please_clear_basket')
          )
        )
      }
      setHasCurrencyError(showCurrencyError)
    }
  }, [viewBasket, priceAndAvailability, dispatch, t])

  // Recently view items update
  useEffect(() => {
    touchSkuForRecentlyViewed(sku).then(() => noop())
  }, [sku])

  // Analytics tracking for product
  useEffect(() => {
    if (
      !isLoadingPrice &&
      !isLoading &&
      catalogueItem &&
      catalog &&
      portfolioCountryCode
    ) {
      const productDetails = {
        sku,
        journey: priceAndAvailability
          ? ProductItemAvailabilityStatus.AVAILABLE
          : ProductItemAvailabilityStatus.AVAILABLE_ON_REQUEST,
        catalogueItemName: catalog?.catalogItemName,
        formulationPackSize: catalogueItem?.formulationPackSize,
        price: `${priceAndAvailability?.currencyCode} ${priceAndAvailability?.listPrice}`,
        availableQuantity: priceAndAvailability?.availableQuantity,
        portfolioJourney: getVariantInformationForCatalogItem(
          catalogueItem,
          portfolioCountryCode
        ).uiVariantState,
        productType:
          catalogueItem && isGADrug(catalogueItem)
            ? 'GA'
            : catalogueItem && isCMDrug(catalogueItem)
            ? 'MA'
            : 'Unknown',
        defaultWarehouseCode: priceAndAvailability?.defaultWarehouse,
        isTSE: getRestrictedProduct(catalogueItem),
        'Required documents': getProductDocsInformation(
          catalogueItem.documents
        ),
        productOrigin:
          catalogueItem.item?.sourceSystem === ProductSourceSystem.EBS
            ? 'EBS'
            : 'SageX3',
        edd: determineEddForAnalytics()
      }
      analyticsServiceSingleton.trackEvent(
        AnalyticsEvent.ProductView,
        productDetails
      )
    }
    // N.B. The dependency array warning is disabled as in this case we don't want to trigger multiple firing of events
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPrice, isLoading])

  // onComponentDidUnmount - cancel all API calls
  useEffect(() => {
    return () => {
      cancelGetProductBySku()
      cancelSkinnySourcing()
      cancelGetProductById()
      cancelGetPriceAndAvailabilityForSku()
      cancelGetDistributor()
      cancelGetWarehouseForCode()
    }
  }, [])

  //endregion

  type Deps = [CatalogDto, boolean, ProductDocumentUiState[], boolean]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      if (dependencies[1]) {
        analyticsServiceSingleton.trackPageView(
          AnalyticsPageEvent.ViewProduct,
          {
            catalogueItemName: dependencies[0].catalogItemName
          }
        )
      } else {
        analyticsServiceSingleton.trackPageView(
          AnalyticsPageEvent.ViewProductDetails,
          dependencies[2] && dependencies[2].length > 0
            ? {
                'Required documents': getProductDocsInformation(
                  dependencies[2].map((item) => item.document)
                )
              }
            : {}
        )
      }
    },
    [catalog, isSkuOnly, productDocuments, isLoading],
    (dependencies: Deps) =>
      dependencies[0] && dependencies[2] && !dependencies[3]
  )

  return (
    <ProductDetail
      isLoading={isLoading}
      isLoadingPrice={isLoadingPrice}
      hasCurrencyError={hasCurrencyError}
      registerInterestSubmissionState={registerInterestSubmissionState}
      isAddingToBasket={isAddingToBasket}
      product={catalog}
      isSkuOnly={isSkuOnly}
      stepSize={stepQuantity}
      userCountry={portfolioCountryCode}
      userRole={userRole}
      minQuantity={minQuantity}
      maxQuantity={maxQuantity}
      quantityError={quantityError}
      currencyCode={priceAndAvailability?.currencyCode}
      totalCost={totalCost}
      unitPrice={unitPrice}
      holdsInformation={holdsInformation}
      catalogueItem={catalogueItem}
      sourcingRequestURL={
        catalogueItem && getSourcingEnquiryLink(catalogueItem)
      }
      distributor={distributor}
      productDocuments={productDocuments}
      isRestricted={getRestrictedProduct(catalogueItem)}
      handleDownload={handleDownload}
      handleGoBack={handleGoBack}
      handleAddToBasket={handleAddToBasket}
      handleChangeQuantity={handleUpdateQuantity}
      handlePrint={() => window.print()}
      handleFileUploadForSku={handleFileUploadedForBasketItem}
      handleRegisterInterestForProduct={handleRegisterInterestForProduct}
      skinnySourcingButtonDisabled={skinnySourcingButtonDisabled}
      renderBookmarkToggle={(props) => (
        <BookmarkToggleButtonContainer {...props} />
      )}
      handleSkinnySourcingRequest={handleSkinnySourcingRequest}
    />
  )
}
