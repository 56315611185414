import React, { Fragment, useState } from 'react'
import { ClinTheme } from '../../../ClinTheme'
import { Col, Row } from 'react-grid-system'
import { CatalogDto } from '../../../types/swaggerTypes'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { BookmarkStatus } from '../../Bookmarks/Bookmarks.model'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import {
  CatalogueItemConstants,
  catalogueItemStatusMapper,
  getCatalogueItemStatus,
  ICatalogItemDto
} from './ProductVariant.models'
import { useTranslation } from 'react-i18next'
import { INewFeature } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import {
  UserRole,
  isAusGaUser,
  ProductItemAvailabilityStatus
} from '../../../constants'
import { useCheckUnableHomePage } from '../../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'
import { ClinTableBodyCell } from '../../../components/ClinTable/ClinTable'
import { ClinTableOrderToggle } from '../../../components/ClinTableOrderToggle'
import { StyledClinTableOrderToggleWrapper } from '../../Patients/PatientDashboard/PatientDashboard.styles'
import { StyledGrayWrapper } from '../../OrderPages/Orders/Orders.styles'
import { IAugmentedCatalogItemDto } from '../../../services/PortfolioJourneys/PortfolioJourney.types'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import StatusColumn from './StatusColumn/StatusColumn'
import { DateTime } from 'luxon'
import i18n from '../../../i18n/config'
import { Basket } from './Basket/Basket'
import ProductInformation from './ProductInformation/ProductInformation'
import {
  StyledClinTableRow,
  StyledClinTableExpandedRow,
  StyledProductInformationBox,
  StyledClinPatientTableHeaderCell,
  StyledClinTableBodyCell,
  StyledDesktopWrapper,
  StyledClinTable,
  FixedWidthCell,
  ProductInformationBox,
  StyledClinTableToggleRowButton,
  StyledStrengthCell,
  StyledMobileWrapper
} from './ProductVariant.styles'
import { numberToCurrencyString } from '../../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../../utils/getBrowserLocale'
import MobileItem from './MobileItem/MobileItem'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { ClinDrawer } from '../../../components/ClinDrawer'
import { BasketDrawer } from './BasketDrawer/BasketDrawer'
import { useFeatureFlags } from '../../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../../context/featureFlags/FeatureFlagKeys'

interface IProductDetailProps {
  /** Selected product */
  product: CatalogDto
  /** Selected product variants */
  catalogItems?: ICatalogItemDto[]
  /** Whether we are loading or not */
  isLoading: boolean
  /** Country code for ship to */
  countryCode: string
  /** Whether this product or not or loading */
  bookmarkedStatus?: BookmarkStatus
  /** User country*/
  userCountry: string
  /** User role*/
  userRole?: UserRole
  /** List with user unseen features from context */
  newFeaturesList?: INewFeature[]
  /** Handle download button click */
  handleSelectVariant?: (sku: string) => void
  /** Handle back to top request */
  handleBackToTop: () => void
  /** Handle go to the basket */
  handleGoToBasket: () => void
  /** Handle go to the checkout */
  handleProceedToCheckout: () => void
  /** TODO: Remove this once we have the new checkout page */
  /** Handle go to the checkout OLD  */
  handleProceedToCheckoutOld: () => void
}

export interface IBasketItemDetails {
  title: string
  sku: string
  quantity: number
  amount: number
  currencyCode: string
  requiresDocumentation: boolean
}

export const ProductVariant: React.FC<IProductDetailProps> = ({
  product,
  catalogItems,
  isLoading,
  userCountry,
  userRole,
  handleSelectVariant,
  handleGoToBasket,
  handleProceedToCheckout,
  handleProceedToCheckoutOld
}) => {
  const { t } = useTranslation()
  const { enableNewHomePage } = useCheckUnableHomePage()

  const { useFeatureFlag } = useFeatureFlags()
  const isNewCheckoutPage = useFeatureFlag(
    FeatureFlagKeys.NewOneStepCheckoutPage
  )
  const enableNewBasketDrawer = useFeatureFlag(FeatureFlagKeys.NewBasketDrawer)

  const [expandedRows, setExpandedRows] = useState<string[]>([])
  const [hoveredRow, setHoveredRow] = useState<string | null>(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  const getCatalogueItemDeliveryDate = (
    catalogueItem: IAugmentedCatalogItemDto,
    portfolioCountryCode: string
  ) => {
    const catalogueItemStatus = getCatalogueItemStatus(
      catalogueItem,
      portfolioCountryCode
    ) as CatalogueItemConstants

    const eDate = catalogueItem.expectedDeliveryDates?.find(
      (e: any) => e.shipToCountry === portfolioCountryCode
    )

    if (
      [
        CatalogueItemConstants.RequestQuote,
        CatalogueItemConstants.BackOrder,
        CatalogueItemConstants.OutOfStock
      ].includes(catalogueItemStatus)
    ) {
      return '--'
    }

    return eDate?.expectedDeliveryDate
      ? DateTime.fromISO(eDate?.expectedDeliveryDate, { zone: 'utc' })
          .setLocale(i18n.language)
          .toFormat('cccc MMM d')
      : '--'
  }

  const getCatalogueItemColumns = (portfolioCountryCode: string) => [
    {
      name: 'strength',
      viewName: 'Strength',
      translationKey: 'product_variants:column_titles.strength',
      width: '100%',
      minWidth: '150px',
      maxWidth: '165px',
      isSortable: false,
      renderValue: (catalogueItem: IAugmentedCatalogItemDto) => (
        <>
          {catalogueItem.products.map((product: any) => (
            <StyledStrengthCell key={`catalogue-item-${product.productId}`}>
              <ClinText
                variant={TypographyVariant.SmallUI}
                lineHeight={ClinTheme.lineHeights.heading[0]}
                fontSize={ClinTheme.fontSizes[1]}
              >
                {product.genericName}
              </ClinText>
              <ClinText
                lineHeight={ClinTheme.lineHeights.heading[0]}
                fontSize={ClinTheme.fontSizes[1]}
              >
                {product.strength}
              </ClinText>
            </StyledStrengthCell>
          ))}
        </>
      )
    },
    {
      name: 'brand',
      viewName: 'Brand',
      translationKey: 'product_variants:column_titles.brand',
      width: '100%',
      isSortable: false,
      renderValue: (catalogueItem: IAugmentedCatalogItemDto) => (
        <ClinText
          fontSize={ClinTheme.fontSizes[1]}
          lineHeight={ClinTheme.lineHeights.heading[0]}
        >
          {catalogueItem.item.brand ?? '--'}
        </ClinText>
      )
    },
    {
      name: 'dosageFormAndPackSize',
      viewName: 'Dosage form and pack size',
      translationKey:
        'product_variants:column_titles.dosage_form_and_pack_size',
      width: '100%',
      minWidth: '115px',
      maxWidth: '180px',
      isSortable: false,
      renderValue: (catalogueItem: IAugmentedCatalogItemDto) => (
        <>
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
          >
            {catalogueItem.item.formulation}
          </ClinText>
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
          >
            {catalogueItem.item.packSize}
          </ClinText>
        </>
      )
    },
    {
      name: 'availability',
      viewName: 'Availability',
      translationKey: 'product_variants:column_titles.availability',
      width: '100%',
      renderValue: function column(catalogueItem: IAugmentedCatalogItemDto) {
        const catalogueItemStatus = getCatalogueItemStatus(
          catalogueItem,
          portfolioCountryCode
        )

        return <StatusColumn catalogueItemStatus={catalogueItemStatus} />
      }
    },
    {
      name: 'price',
      viewName: 'Pack price',
      translationKey: 'product_variants:column_titles.pack_price',
      width: '100%',
      isSortable: false,
      renderValue: (catalogueItem: IAugmentedCatalogItemDto) => {
        const isAvailable =
          catalogueItem.availableStatus ===
          ProductItemAvailabilityStatus.AVAILABLE
        const isPriceLoading =
          isAvailable && catalogueItem.unitPrice === undefined
        return (
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            color={ClinTheme.colors.graphiteBlue}
            textAlign="center"
          >
            {isPriceLoading ? (
              <ClinSpinner size={ClinTheme.space[3]} />
            ) : isAvailable &&
              catalogueItem.unitPrice !== undefined &&
              catalogueItem.currencyCode ? (
              numberToCurrencyString(
                catalogueItem.unitPrice,
                getBrowserLocale(),
                {
                  style: 'currency',
                  currency: catalogueItem.currencyCode,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }
              )
            ) : (
              '--'
            )}
          </ClinText>
        )
      }
    },
    {
      name: 'estimatedDelivery',
      viewName: (
        <>
          <div style={{ fontWeight: ClinTheme.fontWeights.medium }}>
            {t('product_variants:column_titles.estimated_delivery')}
          </div>
          <span style={{ fontWeight: ClinTheme.fontWeights.normal }}>
            {t('product_variants:column_titles.order_time')}
          </span>
        </>
      ),
      translationKey: 'product_variants:column_titles.estimated_delivery',
      width: '100%',
      isSortable: false,
      renderValue: (catalogueItem: IAugmentedCatalogItemDto) => {
        const catalogueItemDeliveryDate = getCatalogueItemDeliveryDate(
          catalogueItem,
          portfolioCountryCode
        )

        return (
          <ClinText
            variant={TypographyVariant.SmallUI}
            lineHeight={ClinTheme.lineHeights.small}
            fontSize={ClinTheme.fontSizes[0]}
          >
            {catalogueItemDeliveryDate}
          </ClinText>
        )
      }
    },
    {
      name: 'Quantity',
      viewName: 'Quantity',
      translationKey: 'product_variants:column_titles.estimated_delivery',
      width: '100%',
      minWidth: '184px',
      maxWidth: '184px',
      isSortable: false,
      renderValue: (catalogItem: IAugmentedCatalogItemDto) => {
        const catalogueItemStatus = getCatalogueItemStatus(
          catalogItem,
          userCountry
        )
        const productAvailabilityStatus =
          catalogueItemStatusMapper[catalogueItemStatus]

        return (
          <Basket
            basketType="inline"
            userCountry={userCountry}
            userRole={userRole}
            product={product}
            catalogItem={{
              ...catalogItem,
              availableStatus: productAvailabilityStatus
            }}
            setIsDrawerOpen={setIsDrawerOpen}
            handleGoToBasket={handleGoToBasket}
            handleProceedToCheckout={
              isNewCheckoutPage
                ? handleProceedToCheckout
                : handleProceedToCheckoutOld
            }
          />
        )
      }
    },
    {
      name: 'toggle',
      viewName: 'Actions',
      translationKey: 'product_variants:column_titles.estimated_delivery',
      width: '100%',
      minWidth: '50px',
      maxWidth: '50px',
      isSortable: false,
      renderValue: (catalogueItem: IAugmentedCatalogItemDto) => (
        <StyledClinTableToggleRowButton
          isToggled={expandedRows.includes(
            `${catalogueItem.itemId}-${catalogueItem.item.sku}`
          )}
          onClick={() => {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.ExpandVariantView
            )

            handleToggleRow &&
              handleToggleRow(
                `${catalogueItem.itemId}-${catalogueItem.item.sku}`
              )
          }}
        />
      )
    }
  ]

  const handleToggleRow = (itemId: string) => {
    setExpandedRows((prev: string[]) =>
      prev.includes(itemId)
        ? prev.filter((id: string) => id !== itemId)
        : [...prev, itemId]
    )
  }

  const handleMouseEnter = (id: string) => {
    setHoveredRow(id)
  }

  const handleMouseLeave = () => {
    setHoveredRow(null)
  }

  const getCrumbs = () => {
    return [
      isAusGaUser(userCountry, userRole) && enableNewHomePage
        ? {
            name: t('navigation:home'),
            path: '/home'
          }
        : { path: '/orders', name: t('orders:page_name') },
      {
        name: t('product_catalogue:non_ma_title'),
        path: '/products/catalogue'
      },
      {
        name: product ? product.catalogItemName : '',
        path: `/product/${product && product.catalogDocumentId}`
      }
    ]
  }

  const columns = getCatalogueItemColumns(userCountry)

  return isLoading || !product ? (
    <ClinPageContentFrame>
      <Row align="center">
        <Col>
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </ClinPageContentFrame>
  ) : (
    <StyledGrayWrapper>
      <ClinPageContentFrame crumbs={getCrumbs()}>
        <StyledDesktopWrapper>
          <StyledClinTable
            borderColapseSeparate={true}
            className="TableOverflowVisible"
            tableHeader={() =>
              columns.map((column) => (
                <StyledClinPatientTableHeaderCell
                  key={`th-${column.name}`}
                  background={ClinTheme.colors.lightGrey}
                >
                  <FixedWidthCell
                    width={column.width}
                    minWidth={column.minWidth}
                    maxWidth={column.maxWidth}
                  >
                    {column.isSortable ? (
                      <StyledClinTableOrderToggleWrapper>
                        <ClinTableOrderToggle isUppercase={false}>
                          {column.viewName}
                        </ClinTableOrderToggle>
                      </StyledClinTableOrderToggleWrapper>
                    ) : (
                      <StyledClinTableOrderToggleWrapper>
                        <ClinTableOrderToggle hideSortIcon={true}>
                          {column.viewName}
                        </ClinTableOrderToggle>
                      </StyledClinTableOrderToggleWrapper>
                    )}
                  </FixedWidthCell>
                </StyledClinPatientTableHeaderCell>
              ))
            }
          >
            {catalogItems?.map(
              (catalogItem: ICatalogItemDto, index: number) => (
                <Fragment
                  key={`variant-row-${catalogItem.itemId}-${catalogItem.item.sku}-${index}`}
                >
                  {/* Main Row */}
                  <StyledClinTableRow
                    background={ClinTheme.colors.white}
                    className={`main-row ${
                      hoveredRow ===
                      `${catalogItem.itemId}-${catalogItem.item.sku}`
                        ? 'highlighted'
                        : ''
                    } ${
                      expandedRows.includes(
                        `${catalogItem.itemId}-${catalogItem.item.sku}`
                      )
                        ? 'expanded'
                        : ''
                    }`}
                    onMouseEnter={() =>
                      handleMouseEnter(
                        `${catalogItem.itemId}-${catalogItem.item.sku}`
                      )
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    {columns.map((column) => (
                      <StyledClinTableBodyCell
                        key={`${column.name}-${catalogItem.itemId}-${catalogItem.item.sku}`}
                        onClick={() =>
                          handleSelectVariant &&
                          handleSelectVariant(catalogItem.item.sku)
                        }
                      >
                        <FixedWidthCell
                          width={column.width}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                        >
                          {column.renderValue
                            ? column.renderValue(catalogItem)
                            : null}
                        </FixedWidthCell>
                      </StyledClinTableBodyCell>
                    ))}
                  </StyledClinTableRow>

                  {/* Expanded Row */}
                  <StyledClinTableExpandedRow
                    background={ClinTheme.colors.white}
                    isOpen={expandedRows.includes(
                      `${catalogItem.itemId}-${catalogItem.item.sku}`
                    )}
                    className={`expanded-row ${
                      hoveredRow ===
                      `${catalogItem.itemId}-${catalogItem.item.sku}`
                        ? 'highlighted'
                        : ''
                    }`}
                    onMouseEnter={() =>
                      handleMouseEnter(
                        `${catalogItem.itemId}-${catalogItem.item.sku}`
                      )
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    {/* Image container */}
                    {/* <StyledClinTableBodyCell
                    colSpan={2}
                    style={{ padding: '30px 10px 30px 16px' }}
                    onClick={() =>
                      handleSelectVariant && handleSelectVariant(catalogItem)
                    }
                  >
                    <StyledImageContainer>
                      <StyledImage
                        src={PlaceholderImage}
                        alt={`${catalogItem.itemId}-image`}
                      />
                      <StyledImageText>
                        Last updated: 24 Jan 2024
                      </StyledImageText>
                    </StyledImageContainer>
                  </StyledClinTableBodyCell> */}
                    <StyledClinTableBodyCell
                      colSpan={10}
                      style={{ padding: '32px 24px 24px' }}
                      onClick={() =>
                        handleSelectVariant &&
                        handleSelectVariant(catalogItem.item.sku)
                      }
                    >
                      <StyledProductInformationBox>
                        <ProductInformationBox>
                          <ProductInformation
                            catalogItem={catalogItem}
                            handleSelectVariant={handleSelectVariant}
                          />
                        </ProductInformationBox>
                        {catalogItem &&
                          catalogItem.cannotOrder &&
                          (() => {
                            const catalogueItemStatus = getCatalogueItemStatus(
                              catalogItem,
                              userCountry
                            )
                            const productAvailabilityStatus =
                              catalogueItemStatusMapper[catalogueItemStatus]

                            return (
                              <Basket
                                basketType="block"
                                userCountry={userCountry}
                                userRole={userRole}
                                product={product}
                                catalogItem={{
                                  ...catalogItem,
                                  availableStatus: productAvailabilityStatus
                                }}
                                handleGoToBasket={handleGoToBasket}
                                handleProceedToCheckout={
                                  isNewCheckoutPage
                                    ? handleProceedToCheckout
                                    : handleProceedToCheckoutOld
                                }
                              />
                            )
                          })()}
                      </StyledProductInformationBox>
                    </StyledClinTableBodyCell>
                  </StyledClinTableExpandedRow>

                  {/* Empty Row */}
                  <StyledClinTableRow>
                    <ClinTableBodyCell
                      colSpan={10}
                      style={{ padding: '4px' }}
                    ></ClinTableBodyCell>
                  </StyledClinTableRow>
                </Fragment>
              )
            )}
          </StyledClinTable>
        </StyledDesktopWrapper>
        <StyledMobileWrapper>
          {catalogItems?.map((catalogItem, index) => (
            <MobileItem
              key={`variant-row-${catalogItem.itemId}-${catalogItem.item.sku}-${index}`}
              catalogItem={catalogItem}
              userCountry={userCountry}
              userRole={userRole}
              product={product}
              catalogueItemStatus={getCatalogueItemStatus(
                catalogItem,
                userCountry
              )}
              catalogueItemDeliveryDate={getCatalogueItemDeliveryDate(
                catalogItem,
                userCountry
              )}
              setIsDrawerOpen={setIsDrawerOpen}
              handleGoToBasket={handleGoToBasket}
              handleProceedToCheckout={
                isNewCheckoutPage
                  ? handleProceedToCheckout
                  : handleProceedToCheckoutOld
              }
              handleSelectVariant={handleSelectVariant}
            />
          ))}
        </StyledMobileWrapper>
      </ClinPageContentFrame>
      {enableNewBasketDrawer && (
        <ClinDrawer
          open={isDrawerOpen}
          width={512}
          onClose={() => setIsDrawerOpen(false)}
        >
          <BasketDrawer />
        </ClinDrawer>
      )}
    </StyledGrayWrapper>
  )
}
