import { AxiosError } from 'axios'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledBasketContainer,
  StyledLicenseBox,
  StyledQuantityBox,
  StyledTotalBox,
  StyledLabel,
  StyledButtonLabel,
  StyledInlineBasketContainer,
  StyledClinButton,
  StyledClinButtonBlock
} from './Basket.styles'
import { ClinTheme } from '../../../../ClinTheme'
import { AnnounceMode } from '../../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import { ClinSpinner } from '../../../../components/ClinSpinner'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import { ClinTextInput } from '../../../../components/ClinTextInput'
import {
  isAusMaUser,
  ProductItemAvailabilityStatus,
  UserRole
} from '../../../../constants'
import { CountryAlphaCodes } from '../../../../constants/countryAlpha2Codes'
import { useAppContext } from '../../../../context/app'
import { addBasketItemAsync, useBasket } from '../../../../context/basket'
import { createAnnounceEvent } from '../../../../events/AnnounceEvent'
import {
  clearConfirmationEvent,
  createConfirmationEvent
} from '../../../../events/ConfirmationEvent'
import {
  AddedToBasketConfirmation,
  IBasketItemDetails
} from '../../../../features/AddedToBasketConfirmation/AddedToBasketConfirmation'
import { AnalyticsEvent } from '../../../../services/Analytics'
import analyticsServiceSingleton from '../../../../services/Analytics/initAnalytics'
import { AuthError, skinnySourcing } from '../../../../services/ApiService'
import { getRequiresDocumentation } from '../../../../services/Holds/holds'
import {
  getAvailableStockForTSEItem,
  getRestrictedProduct
} from '../../../../services/PortfolioJourneys/PortfolioJourney.model'
import { IAugmentedCatalogItemDto } from '../../../../services/PortfolioJourneys/PortfolioJourney.types'
import { CatalogDto } from '../../../../types/swaggerTypes'
import { handleBasketError } from '../../../../utils/basketUtils'
import { getBrowserLocale } from '../../../../utils/getBrowserLocale'
import { numberToCurrencyString } from '../../../../utils/numberToCurrencyString'
import { validateQuantity } from '../../../../utils/validateQuantity'
import { getDefaultWarehouseCodeForInstitute } from '../../ProductDetail/ProductDetail.models'
import {
  IDocumentForBasketItem,
  IEddArray,
  IEddItemForLocalStorage
} from '../../ProductDetail/ProductDetailContainer'
import { useFeatureFlags } from '../../../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../../../context/featureFlags/FeatureFlagKeys'
import {
  CatalogueItemConstants,
  getCatalogueItemStatus
} from '../ProductVariant.models'

interface IBasketProps {
  /** User country*/
  userCountry: string
  /** User role*/
  userRole?: UserRole
  /** Basket type */
  basketType: 'block' | 'inline' | 'mobile'
  /** Selected product */
  product: CatalogDto
  /** Selected product variant */
  catalogItem: IAugmentedCatalogItemDto
  /** Open Basket drawer */
  setIsDrawerOpen?: (isOpen: boolean) => void
  /** Handle go to the basket */
  handleGoToBasket: () => void
  /** Handle go to the checkout */
  handleProceedToCheckout: () => void
}

export const Basket = (props: IBasketProps): React.ReactElement => {
  const {
    basketType,
    userCountry,
    userRole,
    product,
    catalogItem,
    setIsDrawerOpen,
    handleGoToBasket,
    handleProceedToCheckout
  } = props

  const { t } = useTranslation()
  const { institute } = useAppContext()
  const [{ viewBasket }, basketDispatch] = useBasket()
  const { dispatch, portfolioCountryCode } = useAppContext()

  const { useFeatureFlag } = useFeatureFlags()
  const enableNewBasketDrawer = useFeatureFlag(FeatureFlagKeys.NewBasketDrawer)

  const [quantity, setQuantity] = React.useState<number>(0)
  const [minQuantity, setMinQuantity] = React.useState<number>(1)
  const [maxQuantity, setMaxQuantity] = React.useState<number>(10)
  const [stepQuantity, setStepQuantity] = React.useState<number>(1)
  const [quantityError, setQuantityError] = React.useState<string | undefined>()
  const [isAddingToBasket, setIsAddingToBasket] = React.useState<boolean>(false)
  const [totalCost, setTotalCost] = React.useState<number>(0)
  const [skinnySourcingButtonDisabled, setSkinnySourcingButtonDisabled] =
    React.useState<boolean>(false)
  const [basketItemDocuments] = React.useState<IDocumentForBasketItem[]>([])

  const { availableStatus, unitPrice, currencyCode } = catalogItem
  const isAvailable =
    availableStatus === ProductItemAvailabilityStatus.AVAILABLE
  const isAvailableOnRequest =
    availableStatus === ProductItemAvailabilityStatus.AVAILABLE_ON_REQUEST
  const isUnavailable =
    availableStatus === ProductItemAvailabilityStatus.UNAVAILABLE
  const isPriceLoading = isAvailable && unitPrice === undefined

  const distributor = catalogItem.distributorName

  // Temporarily hide 'Import Licence Required' label until CLOS-9515 is implemented.
  const showImportLicense = false
  // const isStock = stockLevels
  //   ? stockLevels.some(
  //       (stockLevel: any) =>
  //         stockLevel.reservableStock > 0 || stockLevel.safetyStockLevel > 0
  //     )
  //   : false

  // (reservableStock > 0 || safetyStockLevel > 0) && status === 'Avaliable' -> primary
  // (reservableStock = 0 && safetyStockLevel = 0) && status === 'Avaliable' -> secondary
  // const isPrimaryAddBtn = isStock && isAvailable

  const handleChangeQuantity = (
    quantityValue: number,
    minQuantity: number,
    maxQuantity: number,
    stepQuantity: number,
    catalogueItem: IAugmentedCatalogItemDto
  ) => {
    setQuantity(quantityValue)

    const showMinErrorMessage =
      (basketType === 'inline' || basketType === 'mobile') &&
      !isNaN(Number(quantityValue))
    const errMsg = validateQuantity(
      quantityValue,
      minQuantity,
      maxQuantity,
      stepQuantity,
      getRestrictedProduct(catalogueItem),
      true
    )

    if (errMsg && showMinErrorMessage) {
      setQuantityError(errMsg)
      return
    }

    setQuantityError(undefined)
    if (catalogueItem?.unitPrice !== null) {
      setTotalCost(
        isNaN(quantityValue) ||
          catalogueItem?.unitPrice === undefined ||
          isNaN(catalogueItem.unitPrice)
          ? 0
          : quantityValue * catalogueItem.unitPrice
      )
    }
  }

  const handleAddToBasket = (
    minQuantity: number,
    maxQuantity: number,
    stepQuantity: number,
    catalogueItem: IAugmentedCatalogItemDto
  ) => {
    const selectedQuantity =
      (basketType === 'inline' || basketType === 'mobile') && !quantity
        ? 1
        : quantity
    setQuantity(selectedQuantity)
    const errMsg = validateQuantity(
      selectedQuantity,
      minQuantity,
      maxQuantity,
      stepQuantity,
      getRestrictedProduct(catalogueItem)
    )
    if (errMsg) {
      setQuantityError(errMsg)
      return
    }
    setQuantityError(undefined)
    if (catalogueItem?.unitPrice != null) {
      setTotalCost(
        isNaN(selectedQuantity) || isNaN(catalogueItem?.unitPrice)
          ? 0
          : selectedQuantity * catalogueItem.unitPrice
      )
    }

    if (catalogueItem?.priceAvailability) {
      setIsAddingToBasket(true)
      addBasketItemAsync(
        basketDispatch,
        catalogueItem.sku,
        selectedQuantity,
        catalogueItem.priceAvailability,
        basketItemDocuments
      )
        .then(() => {
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
          analyticsServiceSingleton.trackEvent(AnalyticsEvent.BasketAddItem, {
            sku: catalogueItem.sku,
            quantity: selectedQuantity,
            price: catalogueItem?.priceAvailability?.listPrice,
            currency: catalogueItem?.priceAvailability?.currencyCode
          })

          const localStorageData = localStorage.getItem('EDDs')
          const StoredEDDs: IEddArray = localStorageData
            ? JSON.parse(localStorageData)
            : { skuAndEddArray: [] }
          const newSkuEddItem: IEddItemForLocalStorage = {
            itemSku: catalogueItem.sku,
            itemDisplayedStock: catalogueItem.reservableStock ?? 0
          }
          const i = StoredEDDs.skuAndEddArray.findIndex(
            //check if already exist in local storage
            (e) => e.itemSku === newSkuEddItem.itemSku
          )
          if (i !== -1) {
            StoredEDDs.skuAndEddArray[i] = newSkuEddItem // if exist edit stock level
          } else {
            StoredEDDs.skuAndEddArray.push(newSkuEddItem) // not exist add it
          }

          localStorage.setItem('EDDs', JSON.stringify(StoredEDDs))
        })
        .catch((error) => {
          setIsAddingToBasket(false)
          handleBasketError(error, undefined, handleAddToBasket)
        })
    }
  }

  const handleSkinnySourcingRequest = (
    minQuantity: number,
    maxQuantity: number,
    stepQuantity: number,
    catalogueItem: IAugmentedCatalogItemDto
  ) => {
    const errMsg = validateQuantity(
      quantity,
      minQuantity,
      maxQuantity,
      stepQuantity,
      getRestrictedProduct(catalogueItem)
    )
    if (errMsg) {
      setQuantityError(errMsg)
      return
    }
    setQuantityError(undefined)

    setSkinnySourcingButtonDisabled(true)
    catalogueItem?.item.sku &&
      skinnySourcing({
        itemNumber: catalogueItem.item.sku,
        requiredQty: quantity
      })
        .then((response) => {
          if (response.data.incidentNumber) {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.SourcingRequestSubmit,
              {
                sku: catalogueItem.item.sku
              }
            )
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Success,
                portfolioCountryCode === CountryAlphaCodes.Australia
                  ? `${t(
                      'product_detail:successful_skinny_sourcing_request_aus'
                    )}`
                  : `${t('product_detail:successful_skinny_sourcing_request')}`
              )
            )
          }
        })
        .catch((error: AxiosError) => {
          // If request is cancelled continue
          setSkinnySourcingButtonDisabled(false)
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error registering your interest in ${product?.catalogItemName}. ${error}`
            )
          )
        })
        .finally(() =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        )
  }

  useEffect(() => {
    if (!catalogItem) {
      return
    }

    const isRestricted = getRestrictedProduct(catalogItem)
    const defaultWarehouse = getDefaultWarehouseCodeForInstitute(institute.data)
    const maximumOrderQuantity = isRestricted
      ? getAvailableStockForTSEItem(catalogItem, defaultWarehouse)
      : catalogItem.item.maximumOrderQuantity

    setMaxQuantity(maximumOrderQuantity || 10)
    setStepQuantity(catalogItem.item.incrementalOrderQuantity || 1)
    setMinQuantity(catalogItem.item.minimumOrderQuantity || 1)
  }, [catalogItem, institute.data])

  // When basket updated show confirmation but not on initial mount
  const isInitialMount = useRef(true)
  useEffect(() => {
    if (!catalogItem || !isAddingToBasket) {
      return
    }

    const { availableStatus, unitPrice } = catalogItem
    const isAvailable =
      availableStatus === ProductItemAvailabilityStatus.AVAILABLE
    const isPriceLoading = isAvailable && unitPrice === undefined

    if (isInitialMount.current) {
      isInitialMount.current = false
    } else if (
      quantity &&
      product &&
      !isPriceLoading &&
      catalogItem?.priceAvailability
    ) {
      const price =
        unitPrice || parseFloat(catalogItem?.priceAvailability?.listPrice)
      const basketItemDetails: IBasketItemDetails = {
        title: product.catalogItemName,
        sku: catalogItem.sku,
        quantity: quantity,
        amount: quantity * price,
        currencyCode:
          viewBasket?.currencyCode ||
          catalogItem?.priceAvailability?.currencyCode,
        requiresDocumentation: getRequiresDocumentation(
          catalogItem?.priceAvailability?.holds
        )
      }

      setIsAddingToBasket(false)

      if (enableNewBasketDrawer) {
        setIsDrawerOpen && setIsDrawerOpen(true)
      } else {
        dispatch(
          createConfirmationEvent({
            children: (
              <AddedToBasketConfirmation
                basketItemDetails={basketItemDetails}
                handleGoToBasket={handleGoToBasket}
                handleProceedToCheckout={handleProceedToCheckout}
                wasItemRemoved={false}
                countryCode={portfolioCountryCode}
                handleClose={() => dispatch(clearConfirmationEvent())}
              />
            ),
            autoHideAfterMs: 10 * 1000,
            boxWidth: 360,
            borderRadius: 8,
            position: 'absolute',
            topPosition: basketType !== 'mobile' ? -75 : 0,
            removePadding: true
          })
        )
      }
    }
  }, [
    viewBasket,
    catalogItem,
    isAddingToBasket,
    dispatch,
    handleGoToBasket,
    setIsDrawerOpen,
    portfolioCountryCode,
    product,
    quantity
  ])

  // Helper functions for disabling controls
  const shouldDisableQuantitySelector = () => {
    if (!catalogItem) return true

    // Get the catalogueItemStatus from the catalogItem
    const catalogueItemStatus = getCatalogueItemStatus(catalogItem, userCountry)

    // Check if the product is out of stock
    if (catalogueItemStatus === CatalogueItemConstants.OutOfStock) {
      return true
    }

    return (
      skinnySourcingButtonDisabled ||
      isUnavailable ||
      isPriceLoading ||
      catalogItem.cannotOrder ||
      catalogItem.showDistributorInfo
    )
  }

  const shouldDisableAddToBasket = () => {
    // Disable the Add button when catalogueItemStatus is "Out of stock" in the inline mode
    if (basketType === 'inline') {
      // Get the catalogueItemStatus from the catalogItem
      const catalogueItemStatus = getCatalogueItemStatus(
        catalogItem,
        userCountry
      )
      if (catalogueItemStatus === CatalogueItemConstants.OutOfStock) {
        return true
      }
    }
    return isPriceLoading || !!quantityError || isAddingToBasket
  }

  // Check if the product is out of stock
  const isOutOfStock = () => {
    const catalogueItemStatus = getCatalogueItemStatus(catalogItem, userCountry)
    return catalogueItemStatus === CatalogueItemConstants.OutOfStock
  }

  return (
    <>
      {basketType === 'block' && (
        <StyledBasketContainer
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            event.stopPropagation()
          }
        >
          <StyledLicenseBox>
            {showImportLicense && (
              <StyledLabel>
                <ClinText
                  whiteSpace="nowrap"
                  color={ClinTheme.colors.primaryLight}
                  fontWeight={ClinTheme.fontWeights.medium}
                  variant={TypographyVariant.SmallUI}
                  lineHeight={ClinTheme.lineHeights.largeParagraph}
                  textAlign="center"
                  display="flex"
                >
                  {t('product_variants:import_license_required')}
                </ClinText>
              </StyledLabel>
            )}
          </StyledLicenseBox>
          <ClinSpacer hasBorder={false} height="20px" />
          <StyledQuantityBox>
            <ClinTextInput
              name="quantity"
              id="quantity"
              label={t('product_variants:enter_quantity')}
              type="number"
              className="quantity-label"
              disabled={
                skinnySourcingButtonDisabled ||
                isUnavailable ||
                isPriceLoading ||
                (catalogItem &&
                  catalogItem.showDistributorInfo &&
                  !!distributor)
              }
              hasError={!!quantityError}
              prompt={quantityError ? quantityError : ''}
              step={stepQuantity || undefined}
              min={minQuantity || undefined}
              max={maxQuantity || 10000}
              width="100%"
              onChange={(event) => {
                event.stopPropagation()

                !isPriceLoading &&
                  handleChangeQuantity &&
                  handleChangeQuantity(
                    parseInt(event.currentTarget.value),
                    minQuantity,
                    maxQuantity,
                    stepQuantity,
                    catalogItem
                  )
              }}
            />
            {catalogItem && !catalogItem.cannotOrder && (
              <StyledTotalBox>
                <ClinText
                  as="div"
                  variant={TypographyVariant.SmallUI}
                  fontWeight={ClinTheme.fontWeights.medium}
                  color={ClinTheme.colors.black}
                >
                  {t('product_variants:subtotal')}
                </ClinText>
                <ClinText
                  as="div"
                  variant={TypographyVariant.SmallUI}
                  fontWeight={ClinTheme.fontWeights.normal}
                  color={ClinTheme.colors.black}
                >
                  {currencyCode &&
                    numberToCurrencyString(totalCost, getBrowserLocale(), {
                      style: 'currency',
                      currency: currencyCode,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                </ClinText>
              </StyledTotalBox>
            )}
          </StyledQuantityBox>
          <ClinSpacer hasBorder={false} height="36px" />
          {catalogItem &&
            catalogItem.canAccessSourcingRequestForm &&
            isAvailableOnRequest && (
              <StyledClinButtonBlock
                variant="primary"
                display="block"
                disabled={skinnySourcingButtonDisabled || !!quantityError}
                onClick={() =>
                  handleSkinnySourcingRequest(
                    minQuantity,
                    maxQuantity,
                    stepQuantity,
                    catalogItem
                  )
                }
              >
                {skinnySourcingButtonDisabled
                  ? t('product_variants:requested_quote')
                  : t('product_variants:request_quote')}
              </StyledClinButtonBlock>
            )}
          {isUnavailable && (
            <StyledClinButtonBlock variant="primary" disabled={isUnavailable}>
              {t('product_variants:unavailable')}
            </StyledClinButtonBlock>
          )}
        </StyledBasketContainer>
      )}
      {(basketType === 'inline' || basketType === 'mobile') &&
        catalogItem &&
        !catalogItem.cannotOrder &&
        !isAusMaUser(userCountry, userRole) &&
        !catalogItem.showDistributorInfo &&
        !isOutOfStock() && (
          <StyledInlineBasketContainer
            className={basketType === 'mobile' ? 'clin-mobile-view' : ''}
            onClick={(event: React.MouseEvent<HTMLDivElement>) =>
              event.stopPropagation()
            }
          >
            <ClinTextInput
              name="quantity"
              id="quantity"
              label=""
              type="number"
              className="quantity-label"
              disabled={shouldDisableQuantitySelector()}
              hasError={!!quantityError}
              prompt={quantityError ? quantityError : ''}
              step={stepQuantity || undefined}
              min={minQuantity || undefined}
              max={maxQuantity || 10000}
              width="100%"
              onChange={(event) => {
                event.stopPropagation()

                !isPriceLoading &&
                  handleChangeQuantity &&
                  handleChangeQuantity(
                    parseInt(event.currentTarget.value),
                    minQuantity,
                    maxQuantity,
                    stepQuantity,
                    catalogItem
                  )
              }}
            />
            <StyledClinButton
              variant="primary"
              disabled={shouldDisableAddToBasket()}
              onClick={() => {
                analyticsServiceSingleton.trackEvent(
                  AnalyticsEvent.BasketAddItem,
                  {
                    currency: currencyCode,
                    location: 'varient page',
                    sku: catalogItem.sku,
                    quantity: quantity,
                    price: totalCost
                  }
                )

                handleAddToBasket &&
                  handleAddToBasket(
                    minQuantity,
                    maxQuantity,
                    stepQuantity,
                    catalogItem
                  )
              }}
            >
              {isPriceLoading && <ClinSpinner size={ClinTheme.space[3]} />}
              <StyledButtonLabel>
                {t('product_variants:add_btn')}
              </StyledButtonLabel>
            </StyledClinButton>
          </StyledInlineBasketContainer>
        )}
    </>
  )
}
