import React, { Fragment, FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { UserRole, isAusGaUser } from '../../constants'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'
import {
  AboutClinigenTemplate,
  BasicContentBlock
} from '../../types/ContentTypes'

const StyledContent = styled.div`
  a {
    color: ${ClinTheme.colors.tealishBlue} !important;
    font-family: ${ClinTheme.fonts.body};
    letter-spacing: ${ClinTheme.letterSpacing[2]};
    text-decoration: underline;
    &:hover {
      color: ${ClinTheme.colors.redOrange} !important;
    }
  }
`

interface IAboutUsProps {
  content: AboutClinigenTemplate
  userCountry: string
  userRole?: UserRole
}

export const AboutUs: FunctionComponent<IAboutUsProps> = ({
  content,
  userCountry,
  userRole
}) => {
  const { t } = useTranslation()
  const { enableNewHomePage } = useCheckUnableHomePage()
  return (
    <ClinPageContentFrame
      className="cms"
      crumbs={[
        isAusGaUser(userCountry, userRole) && enableNewHomePage
          ? {
              path: '/home',
              name: t('navigation:home')
            }
          : { path: '/orders', name: t('orders:page_name') },
        {
          path: '/about',
          name: content?.flatData?.shortTitle
            ? content?.flatData?.shortTitle
            : ''
        }
      ]}
    >
      <Row>
        <Col>
          <ClinText
            as="h1"
            fontWeight={ClinTheme.fontWeights.bold}
            variant={TypographyVariant.H1}
          >
            {content?.flatData?.title}
          </ClinText>
        </Col>
      </Row>
      {content?.flatData?.content?.map(
        (item: BasicContentBlock, index: number) => {
          return (
            <Fragment key={`about-content-${item.id}`}>
              {item.flatData.headline && (
                <Row>
                  <Col sm={12}>
                    <ClinText
                      as="h2"
                      fontWeight={ClinTheme.fontWeights.bold}
                      variant={TypographyVariant.H2}
                    >
                      {item.flatData.headline}
                    </ClinText>
                  </Col>
                </Row>
              )}
              <Row key={`about-us-${item.id}`}>
                <Col sm={12} md={10} lg={8}>
                  <StyledContent
                    dangerouslySetInnerHTML={{
                      __html: `${item.flatData.paragraph}`
                    }}
                  />
                </Col>
              </Row>
              <ClinSpacer />
            </Fragment>
          )
        }
      )}
    </ClinPageContentFrame>
  )
}
