/**
 * Utility to dynamically load a script
 * @param fileUrl
 * @param async
 * @usage:
 * const isHubspotJSLoaded = await dynamicScriptLoader(
 *       `//js-eu1.hs-scripts.com/${config.hubspotAppId}.js`
 *     )
 */
export const dynamicScriptLoader = async (
  fileUrl: string
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const scriptEle = document.createElement('script')
    scriptEle.setAttribute('src', fileUrl)
    scriptEle.setAttribute('type', 'text/javascript')
    scriptEle.setAttribute('async', 'true')
    document.body.appendChild(scriptEle)
    scriptEle.addEventListener('load', () => {
      resolve(true)
    })
    scriptEle.addEventListener('error', (error) => {
      reject(`Error dynamically loading file ${error.message}`)
    })
  })
}
