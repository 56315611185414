import React from 'react'

import { ClinTheme } from '../../../../ClinTheme'
import { ClinText } from '../../../../components/ClinText'
import ColumnTooltip from '../ColumnTooltip/ColumnTooltip'

interface PatientColumnProps {
  patientNumber: string | undefined
}

const MAX_VISIBLE_LENGTH = 11

const PatientColumn: React.FC<PatientColumnProps> = ({ patientNumber }) => {
  const displayPatientNumber = patientNumber || '--'
  const isTruncated = displayPatientNumber.length > MAX_VISIBLE_LENGTH

  const shouldShowTooltip = displayPatientNumber !== '--' && isTruncated

  const renderContent = () => (
    <ClinText
      fontSize={ClinTheme.fontSizes[1]}
      lineHeight={ClinTheme.lineHeights.heading[0]}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {isTruncated
        ? `${displayPatientNumber.slice(0, MAX_VISIBLE_LENGTH)}...`
        : displayPatientNumber}
    </ClinText>
  )

  if (shouldShowTooltip) {
    return (
      <ColumnTooltip tooltipText={displayPatientNumber}>
        {renderContent()}
      </ColumnTooltip>
    )
  }

  return renderContent()
}

export default PatientColumn
