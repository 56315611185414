import styled, { keyframes } from 'styled-components'

import { IDropdownStyles } from './ClinDropdown'
import { ClinTheme } from '../../ClinTheme'

const StyledMenuSlide = keyframes`
0% {
  opacity: 0;
  transform: translateY(-${ClinTheme.space[2]}px);
}
100% {
   opacity: 1;
    transform: translateY(0px);
  }
`
export const StyledClinDropdownContainer = styled.div<IDropdownStyles>`
  animation: ${StyledMenuSlide} 0.15s ease-out;
  display: none;
  outline: none;
  position: absolute;
  top: 100%;
  left: ${(props) => (props.alignment === 'left' ? '0px' : 'auto')};
  right: ${(props) => (props.alignment === 'right' ? '0px' : 'auto')};
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`

export const StyledClinDropdown = styled.div<IDropdownStyles>(
  ({ isMyAccount }) => ({
    display: 'inline-flex',
    position: 'relative',
    zIndex: isMyAccount
      ? ClinTheme.zIndices.drawer
      : ClinTheme.zIndices.dropdown
  })
)
