import React from 'react'

import { ClinTheme } from '../../../../ClinTheme'
import { ClinText } from '../../../../components/ClinText'
import ColumnTooltip from '../ColumnTooltip/ColumnTooltip'

interface CostColumnProps {
  cost: number
}

const MAX_VISIBLE_LENGTH = 9

const CostColumn: React.FC<CostColumnProps> = ({ cost }) => {
  const displayCostNumber = cost ? `£${cost.toFixed(2)}` : '--'
  const isTruncated = displayCostNumber.length > MAX_VISIBLE_LENGTH

  const renderContent = () => (
    <ClinText
      fontSize={ClinTheme.fontSizes[1]}
      lineHeight={ClinTheme.lineHeights.heading[0]}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {isTruncated
        ? `${displayCostNumber.slice(0, MAX_VISIBLE_LENGTH)}...`
        : displayCostNumber}
    </ClinText>
  )

  if (isTruncated) {
    return (
      <ColumnTooltip tooltipText={displayCostNumber}>
        {renderContent()}
      </ColumnTooltip>
    )
  }

  return renderContent()
}

export default CostColumn
