import { yupResolver } from '@hookform/resolvers/yup'
import React, { ChangeEvent, FunctionComponent, useEffect } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { number, object, string } from 'yup'

import { StyledSpinner, StyledSubmit } from './CreateAddressModal.style'
import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinModal } from '../../../components/ClinModal'
import { ClinSelect } from '../../../components/ClinSelect'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinTextInput } from '../../../components/ClinTextInput'
import {
  CountryDto,
  CreateShippingAddressDto
} from '../../../types/swaggerTypes'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../../utils/Forms/getInputPlaceholder'
import {
  getInputValidation,
  ValidationType
} from '../../../utils/Forms/getInputValidation'

interface ICreateAddressModalProps {
  /** Control internal state of modal **/
  isOpen?: boolean
  /** Displays an error notification **/
  hasError?: boolean
  /** Displays error message **/
  errorMessage?: string
  /** Whether the submission is still processing **/
  isSubmitting: boolean
  /** List of countries **/
  countries: CountryDto[]
  resetOnSubmit?: boolean
  selectedCountry?: string
  addressModalError?: string
  //reset form manualy - for be validation
  resetForm?: boolean
  /** Handle confirmation of dissociation  **/
  handleFormSubmit: (data: CreateShippingAddressDto) => void
  /** Handle close modal **/
  handleClose: () => void
}

const defaultState: CreateShippingAddressDto = {
  address: {
    addressId: 0,
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    city: '',
    postalCode: '',
    state: '',
    country: ''
  }
}

export const CreateAddressModal: FunctionComponent<
  ICreateAddressModalProps
> = ({
  isOpen,
  countries,
  isSubmitting,
  hasError,
  selectedCountry,
  addressModalError,
  resetForm,
  resetOnSubmit,
  handleFormSubmit,
  handleClose
}) => {
  const requiredText = getInputValidation(ValidationType.RequiredField)

  const NewShippingAddressSchema = object().shape({
    address: object().shape({
      addressId: number(),
      address1: string().required(requiredText),
      address2: string().required(requiredText),
      address3: string(),
      address4: string(),
      city: string().required(requiredText),
      postalCode: string().required(requiredText),
      state: string().required(requiredText),
      country: string().required(requiredText)
    })
  })

  const {
    register,
    control,
    reset,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = useForm<CreateShippingAddressDto>({
    defaultValues: defaultState,
    resolver: yupResolver(NewShippingAddressSchema)
  })
  const { t } = useTranslation()

  const onSubmit = handleSubmit((data: CreateShippingAddressDto) => {
    handleFormSubmit(data)
    clearErrors()
    resetOnSubmit && reset({})
  })

  const onCancel = () => {
    handleClose()
    reset({})
  }
  useEffect(() => {
    if (selectedCountry) {
      setValue('address.country', selectedCountry)
    }
  }, [selectedCountry, setValue, isOpen])

  useEffect(() => {
    if (resetForm) {
      reset(defaultState)
    }

    return () => {}
  }, [resetForm])

  return (
    <>
      <ClinModal
        onClose={handleClose}
        maxWidth="sm"
        height="auto"
        isOpen={isOpen}
        padding={'64px 80px'}
        remmoveSmallPadding={true}
      >
        <Container>
          <Row justify="center">
            <Col sm={12}>
              <form onSubmit={onSubmit}>
                <Row>
                  <Col xs={12}>
                    <ClinText
                      as="h1"
                      variant={TypographyVariant.H3}
                      fontWeight={ClinTheme.fontWeights.bold}
                    >
                      {t('request_new_shipping_address:modal_title')}
                    </ClinText>
                  </Col>
                </Row>
                <ClinSpacer height={ClinTheme.space[2]} />
                <Row>
                  <Col xs={12}>
                    <ClinText
                      variant={TypographyVariant.LargeParagraph}
                      fontWeight={ClinTheme.fontWeights.medium}
                    >
                      {t('request_new_shipping_address:mandatory_message')}
                    </ClinText>
                  </Col>
                </Row>
                <ClinSpacer height={ClinTheme.space[5]} />
                <Row>
                  <Col xs={12} md={8}>
                    <div style={{ display: 'none' }}>
                      <ClinTextInput
                        id="address-id"
                        {...register(`address.addressId`)}
                        value={0}
                      />
                    </div>
                    <ClinTextInput
                      id="address"
                      {...register(`address.address1`)}
                      label={t(
                        'request_new_shipping_address:form_label_hospital'
                      )}
                      width="100%"
                      hasError={!!(errors && errors.address?.address1)}
                      prompt={errors.address?.address1?.message}
                    />
                    <ClinSpacer height={ClinTheme.space[3]} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <ClinTextInput
                      id="address-one"
                      {...register(`address.address2`)}
                      label={t(
                        'request_new_shipping_address:form_label_address_line_1'
                      )}
                      width="100%"
                      hasError={!!(errors && errors.address?.address2)}
                      prompt={errors.address?.address2?.message}
                    />
                    <ClinSpacer height={ClinTheme.space[3]} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <ClinTextInput
                      id="address-two"
                      {...register(`address.address3`)}
                      label={t(
                        'request_new_shipping_address:form_label_address_line_2'
                      )}
                      width="100%"
                      hasError={!!(errors && errors.address?.address3)}
                      prompt={errors.address?.address3?.message}
                    />
                    <ClinSpacer height={ClinTheme.space[3]} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <ClinTextInput
                      id="address-three"
                      {...register(`address.address4`)}
                      label={t(
                        'request_new_shipping_address:form_label_address_line_3'
                      )}
                      width="100%"
                      hasError={!!(errors && errors.address?.address4)}
                      prompt={errors.address?.address4?.message}
                    />
                    <ClinSpacer height={ClinTheme.space[3]} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <ClinTextInput
                      id="city"
                      {...register(`address.city`)}
                      label={t('request_new_shipping_address:form_label_city')}
                      width="100%"
                      hasError={!!(errors && errors.address?.city)}
                      prompt={errors.address?.city?.message}
                    />
                    <ClinSpacer height={ClinTheme.space[3]} />
                  </Col>
                  <Col xs={12} md={8}>
                    <ClinTextInput
                      id="city"
                      {...register(`address.state`)}
                      label={t('request_new_shipping_address:form_label_state')}
                      width="100%"
                      hasError={!!(errors && errors.address?.state)}
                      prompt={errors.address?.state?.message}
                    />
                    <ClinSpacer height={ClinTheme.space[3]} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <Controller
                      name={'address.country'}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ClinSelect
                          id="country"
                          label={t(
                            'request_new_shipping_address:form_label_country'
                          )}
                          width="100%"
                          value={value}
                          defaultValue={selectedCountry}
                          disabled={selectedCountry ? true : false}
                          onChange={(
                            changeValue: ChangeEvent<HTMLSelectElement>
                          ) => onChange(changeValue.currentTarget.value)}
                          hasError={!!(errors && errors.address?.country)}
                          prompt={errors.address?.country?.message}
                        >
                          <option value="" disabled={true}>
                            {getInputPlaceholder(PlaceholderType.SelectInput)}
                          </option>
                          {countries
                            .sort((a, b) =>
                              a.countryName && b.countryName
                                ? a.countryName.localeCompare(b.countryName)
                                : -1
                            )
                            .map((country: CountryDto, index: number) => {
                              return (
                                <option
                                  key={`country-codes-${index}`}
                                  value={country.countryCode}
                                >
                                  {country.countryName}
                                </option>
                              )
                            })}
                        </ClinSelect>
                      )}
                    />
                    {selectedCountry && (
                      <ClinText
                        marginTop={'5px'}
                        variant={TypographyVariant.SmallUI}
                      >
                        {t(
                          'request_new_shipping_address:disabled_country_warning_text'
                        )}
                      </ClinText>
                    )}
                    <ClinSpacer height={ClinTheme.space[3]} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <ClinTextInput
                      id="postal-code"
                      {...register(`address.postalCode`)}
                      label={t(
                        'request_new_shipping_address:form_label_postcode'
                      )}
                      width="100%"
                      hasError={!!(errors && errors.address?.postalCode)}
                      prompt={errors.address?.postalCode?.message}
                    />
                    <ClinSpacer height={ClinTheme.space[7]} />
                  </Col>
                </Row>
                <StyledSubmit>
                  <Row style={{ width: '100%' }}>
                    <Col xs={12} md={6}>
                      <ClinButton
                        onClick={() => onCancel()}
                        style={{ width: '100%' }}
                      >
                        {t('common:buttons.cancel')}
                      </ClinButton>
                    </Col>
                    <Col xs={12} md={6}>
                      {isSubmitting ? (
                        <StyledSpinner>
                          <ClinSpinner size={ClinTheme.space[4]} />
                        </StyledSpinner>
                      ) : (
                        <ClinButton
                          style={{ width: '100%' }}
                          type="submit"
                          variant="primary"
                          disabled={isSubmitting}
                        >
                          {t(
                            'request_new_shipping_address:modal_submit_request'
                          )}
                        </ClinButton>
                      )}
                    </Col>
                    {addressModalError && (
                      <ClinText
                        marginTop={'20px'}
                        color="red"
                        variant={TypographyVariant.SmallUI}
                      >
                        {addressModalError}
                      </ClinText>
                    )}
                  </Row>
                </StyledSubmit>
                <ClinSpacer height={ClinTheme.space[3]} />
              </form>
            </Col>
          </Row>
        </Container>
      </ClinModal>
    </>
  )
}
