import { AxiosError } from 'axios'
import React, { FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router'

import { ChangePassword, FormSubmitState } from './ChangePassword'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../../../context/app'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { updatePassword } from '../../../services/ApiService'

export const ChangePasswordContainer: FunctionComponent = () => {
  const history = useHistory()
  const [formState, setFormState] = useState<FormSubmitState>(
    FormSubmitState.Form
  )

  const { dispatch } = useAppContext()

  const handleSubmit = (
    password: string,
    newPassword: string,
    newPasswordConfirmation: string
  ) => {
    setFormState(FormSubmitState.Loading)
    updatePassword(password, newPassword, newPasswordConfirmation)
      .then((response) =>
        history.push(
          {
            pathname: '/account/details'
          },
          {
            title: ' ',
            message: 'Your password has been updated'
          }
        )
      )
      .catch((error: AxiosError) =>
        setTimeout(() => {
          setFormState(FormSubmitState.Error)
          const { code, message } = error
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `Error posting new password. ${message} [${code ? code : '?'}]`
            )
          )
          analyticsServiceSingleton.trackError(
            error,
            JSON.parse(localStorage.getItem('current_user') || '{}')
          )
        }, 500)
      )
  }

  const handleReturnToAccounts = () => {
    history.push('/account/details')
  }

  return (
    <ChangePassword
      formState={formState}
      handleSubmitClick={handleSubmit}
      handleReturnToAccounts={handleReturnToAccounts}
      handleTryAgain={() => setFormState(FormSubmitState.Form)}
    />
  )
}
