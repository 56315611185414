import {
  ApolloClient,
  InMemoryCache,
  gql,
  DocumentNode,
  NormalizedCacheObject,
  createHttpLink,
  ApolloLink
} from '@apollo/client'

import i18n from '../../i18n/config'
import config from '../ConfigProvider'

const httpLink = createHttpLink({
  uri: `${config.cmsRoot}/api/content/${config.squidexRestfulInstanceName}/graphql`
})

const isAusGAFromStorage = localStorage.getItem('user_GA_AUS')
const determineLanguageOption = () => {
  if (isAusGAFromStorage && isAusGAFromStorage === 'true') {
    return 'en-AU'
  }
  return i18n.language
}
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'X-Languages': determineLanguageOption(),
      'X-NoResolveLanguages': 1
    }
  })
  return forward(operation)
})

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    }
  }
})

const getAboutContent: DocumentNode = gql`
  query {
    queryAboutClinigenTemplateContents {
      id
      flatData {
        content {
          flatData {
            headline
            paragraph
          }
        }
        slug
        shortTitle
        title
      }
    }
  }
`

const getContactContent: DocumentNode = gql`
  query {
    queryContactUsTemplateContents {
      id
      flatData {
        content {
          ... on BasicContentBlock {
            flatData {
              headline
              paragraph
            }
          }
        }
        locations {
          ... on LocationContactBlock {
            flatData {
              locationName
              contactNumber
              contactFax
              contactEmail
            }
          }
        }
        sendEmailBtnText
        slug
        title
      }
    }
  }
`

const getSupportContent: DocumentNode = gql`
  query {
    queryTopicTemplateContents {
      flatData {
        title
        icon
        slug
        sortOrderValue
      }
    }
    querySupportMainPageContents {
      id
      flatData {
        contentBlocks {
          ... on Accordion {
            flatData {
              title
              accordionItems {
                flatData {
                  title
                  content
                }
              }
            }
          }
          ... on VideoGuides {
            flatData {
              title
              videoRows {
                flatData {
                  title
                  videos {
                    flatData {
                      vimeoThumbnail {
                        id
                        fileName
                      }
                      vimeoId
                      shortDescription
                    }
                  }
                }
              }
            }
          }
        }
        slug
        topics
        title
      }
    }
  }
`

const getVideoContentById: DocumentNode = gql`
  query ($vimeoId: String!) {
    queryVideoContents(filter: $vimeoId) {
      flatData {
        title
        vimeoId
        description
        transcriptDownload {
          id
          fileName
          fileType
        }
        relatedVideos {
          flatData {
            vimeoThumbnail {
              id
              fileName
            }
            vimeoId
            shortDescription
          }
        }
      }
    }
    querySupportMainPageContents {
      id
      flatData {
        title
      }
    }
  }
`

const getTopicLinks: DocumentNode = gql`
  query {
    queryTopicTemplateContents {
      flatData {
        title
        icon
        slug
      }
    }
  }
`

const getTopicContentBySlug: DocumentNode = gql`
  query ($slug: String!) {
    queryTopicTemplateContents(filter: $slug) {
      flatData {
        title
        icon
        slug
        sortOrderValue
        content {
          ... on Accordion {
            flatData {
              title
              accordionItems {
                flatData {
                  title
                  content
                  video {
                    flatData {
                      vimeoId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    querySupportMainPageContents {
      id
      flatData {
        title
      }
    }
  }
`

const getPageContent: DocumentNode = gql`
  query ($contentName: String!) {
    queryContentBlocksContents(filter: $contentName) {
      id
      flatData {
        content
      }
    }
  }
`

export {
  client,
  getAboutContent,
  getContactContent,
  getSupportContent,
  getVideoContentById,
  getTopicLinks,
  getPageContent,
  getTopicContentBySlug
}
