import { AxiosError } from 'axios'
import React, {
  FunctionComponent,
  useEffect,
  useCallback,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  matchPath,
  RouteComponentProps,
  StaticContext,
  useLocation
} from 'react-router'

import { PatientAccessForm } from './PatientAccessForm'
import {
  IOPAPostMessage,
  OPAErrorCode,
  OPAMessagingConstants
} from './PatientAccessForm.models'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { NewFeatureElements } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNotification } from '../../components/ClinNotification'
import { useAppContext } from '../../context/app'
import { useFeatureFlags } from '../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../context/featureFlags/FeatureFlagKeys'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { useEffectOnlyOnce } from '../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { AnalyticsEvent } from '../../services/Analytics'
import { AnalyticsPageEvent } from '../../services/Analytics/AnalyticsPageEvent'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  cancelGetOpaUrl,
  deleteOpa,
  getOpaUrl,
  updatePatient,
  getPhysicianPatientsByIds,
  getPhysicianPatients,
  getPhysicianPatientsForOpaForm
} from '../../services/ApiService'
import {
  ProgramCatalogDto,
  PatientSummaryAssociatedToPhysiciansSearchDto,
  PatientSummaryAssociatedToPhysiciansSearchDtoForOpaForm
} from '../../types/swaggerTypes'
import { getPathValueFromUrl } from '../../utils/getPathValue'
import { useOnMount } from '../../utils/useOnMount'
import { QueryParam } from '../BootstrapSplash/BootstrapSplashConstants'
import { DeletePAFModal } from '../OpaCheckout/DeletePAFModal'
import {
  OPAOrderTypes,
  PatientOrderStatus
} from '../Patients/PatientDashboard/Patient.model'
import { PatientOPAUrlErrorModal } from '../Patients/PatientDashboard/PatientOPAUrlErrorModal/PatientOPAUrlErrorModal'
import { ModalMode } from '../PhysicianDetails/PhysicianDetailsContainer'
import { useProgramStatus } from '../Programs/ProgramDetail/ProgramDetail.models'

interface IProgramDetailRouteParams {
  programId: string
  physicianId: string
  endpoint?: string
}
interface IPatientAccessFormProps
  extends RouteComponentProps<
    IProgramDetailRouteParams,
    StaticContext,
    LocationState
  > {}

interface LocationState {
  orderType?: string
  programId?: string
  patientNumber?: string
  newFeature?: NewFeatureElements
  patientId?: number
  patientStatus?: string
  patientCreationFlag?: boolean
  stockOrder?: boolean
  from?: string
}

export interface RouteStateType {
  endpoint: string
  from: string
  patientNumber?: string
  orderType?: string
  patientId?: number
}

export const PatientAccessFormContainer: FunctionComponent<
  IPatientAccessFormProps
> = ({ match, history, location }) => {
  const { t } = useTranslation()
  const { dispatch, defaultShippingAddress, institute, portfolioCountryCode } =
    useAppContext()
  const { programId, physicianId } = match.params
  const { program, isProgramLoading } = useProgramStatus(programId)
  const [iFrameHeight, setIFrameHeight] = useState<number>(600)
  const [opaUrl, setOpaUrl] = useState<string | undefined>()
  const [isIFrameLoaded, setIsIFrameLoaded] = useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false)
  const [deletePAFModalMode, setDeletePAFModalMode] = React.useState<
    ModalMode | undefined
  >()
  const [deletePAFError, setDeletePAFError] = React.useState<string>()
  const [patientId, setPatientId] = useState<number | undefined>()
  const [patientNumber, setPatientNumber] = useState<string | undefined>()
  const [patientStatus, setPatientStatus] = useState<string | undefined>()
  const [orderType, setOrderType] = useState<string | undefined>()
  const [isOPAUrlError, setIsOPAUrlError] = useState<boolean>(false)

  //Passed down to OPAHeaderStepper to disable other button
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isCancelling, setIsCancelling] = useState<boolean>(false)
  const [canNavigateAway, setCanNavigateAway] = useState<boolean>(true)

  const { useFeatureFlag } = useFeatureFlags()
  const patientCentricFeatureFlag = useFeatureFlag(
    FeatureFlagKeys.PatientCentricJourneyPerUser
  )

  const routeState = useLocation<RouteStateType>()
  const endpoint = routeState?.state?.endpoint ?? null

  const matchOrderDetails =
    routeState.state &&
    matchPath(routeState.state.from, {
      path: '/order/:orderId'
    })

  const matchOpaOrderSummary =
    routeState.state &&
    matchPath(routeState.state.from, {
      path: '/opa-order-summary/:orderId'
    })

  const matchPatientDetails =
    routeState.state &&
    matchPath(routeState.state.from, {
      path: '/programs/my-physicians/:physicianId/:patientId'
    })

  const matchProgramDetails =
    routeState.state &&
    matchPath(routeState.state.from, {
      path: '/programs/access-programs/:programId'
    })

  const matchOPAInProgress = matchPath(location.pathname, {
    path: '/programs/access-programs/:programId/patient-access-form/:physicianId'
  })?.isExact

  let backButtonTitle

  if (matchOrderDetails) {
    backButtonTitle = t('patient_access_form:back_to_order_details')
  } else if (matchOpaOrderSummary) {
    backButtonTitle = t('patient_access_form:back_to_checkout_confirmation')
  } else if (matchPatientDetails) {
    backButtonTitle = t('patient_access_form:back_to_patient_details')
  } else {
    backButtonTitle = t('patient_access_form:back_to_program_details')
  }

  const handleGoBack = () => {
    if (
      matchOpaOrderSummary ||
      matchPatientDetails ||
      matchProgramDetails ||
      matchOrderDetails
    ) {
      history.goBack()
    }
    if (matchOPAInProgress) {
      history.replace(`/programs/access-programs/${programId}`)
    } else {
      history.push(`/programs/access-programs/${programId}`)
    }
  }

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const opaMessage: IOPAPostMessage = event.data
      // Only receive OPA events
      if (
        opaMessage.type === OPAMessagingConstants.OpaOnlineServices ||
        opaMessage.event === OPAMessagingConstants.OpaOnlineServices
      ) {
        console.warn(JSON.stringify(opaMessage, null, 2))
        // Handle Save & Exit
        if (opaMessage.message && opaMessage.message === 'Save & Exit') {
          history.push(`/programs/access-programs/${programId}`)
        }
        // Handle OPA Timeout
        if (
          opaMessage.errorCode ===
          OPAErrorCode.XXCL_OPA_LAUNCH_TIMEOUT.toString()
        ) {
          history.push(`/programs/access-programs/${programId}`)
          return
        }
        // Handle scroll top message
        if (
          opaMessage.message === 'Pop-Up Modal Triggered' &&
          opaMessage.scroll === 'TOP'
        ) {
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
        }
        // Handle change of height message
        if (opaMessage.height && opaMessage.height !== iFrameHeight) {
          setIFrameHeight(opaMessage.height)
        }
        // Handle successful order
        if (opaMessage.status && opaMessage.order) {
          setCanNavigateAway(true)
          history.push(`/opa-checkout/${opaMessage.order}`, {
            programId: programId,
            orderType: routeState.state?.orderType,
            patientNumber: routeState.state?.patientNumber,
            patientId: routeState.state?.patientId,
            stockOrder: history.location.state.stockOrder,
            from: location?.state?.from
          })
        }
        // Handle error
        if (opaMessage.errorCode && !opaMessage.status) {
          setCanNavigateAway(true)
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              opaMessage.errorCode.toString(),
              'Error'
            )
          )
        }
      } else if (
        opaMessage.system &&
        opaMessage.system === OPAMessagingConstants.ServiceCloudCPOPA
      ) {
        if (
          opaMessage.message &&
          opaMessage.message === OPAMessagingConstants.CheckoutCreated
        ) {
          handleSavedCheckpoint()
        }
      }
    },
    [dispatch, history, iFrameHeight, programId]
  )

  const onLoad = () => {
    setIsIFrameLoaded(true)
  }

  const handleSavedCheckpoint = async () => {
    const newStatus = PatientOrderStatus.IncompletePAF

    let currentPatientId = patientId

    if (currentPatientId === undefined && patientNumber) {
      currentPatientId = await fetchPatientId(patientNumber, physicianId)
    }

    if (physicianId && currentPatientId && patientNumber) {
      updatePatient(physicianId, currentPatientId, {
        orderStatus: newStatus
      })
        .then((response) => {
          setIsSaving(false)
          setCanNavigateAway(true)
          let redirect = '/patients'
          if (
            location?.state?.from &&
            location?.state?.from !== window.location.pathname
          ) {
            redirect = location.state.from
          }
          history.push(redirect, {
            programId: '',
            patientNumber: patientNumber,
            newFeature: NewFeatureElements.ContinueOPA
          })
        })
        .catch((error: AxiosError) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred updating patient status. ${error}`
            )
          )
        })
    }
  }

  const handleUpdatePatientStatus = () => {
    const newStatus = PatientOrderStatus.IncompletePAF
    if (
      physicianId &&
      patientId &&
      patientNumber &&
      patientStatus !== newStatus
    ) {
      updatePatient(physicianId, patientId, {
        orderStatus: newStatus
      })
        .then((response) => {})
        .catch((error: AxiosError) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred updating patient status. ${error}`
            )
          )
        })
    }
  }

  useOnMount(() => {
    const patientId = history.location?.state?.patientId
    patientId && setPatientId(patientId)
    const orderType = history.location?.state?.orderType
    orderType && setOrderType(orderType)
    const patientNumber = history.location?.state?.patientNumber
    patientNumber && setPatientNumber(patientNumber)
    const patientStatus = history.location?.state?.patientStatus
    patientStatus && setPatientStatus(patientStatus)

    const redirect = location?.state?.from
      ? location.state.from
      : '/patients?pageSize=10'

    const hasFlag = patientCentricFeatureFlag && !endpoint && !matchOrderDetails

    const shouldRedirect = !(patientNumber && orderType)

    if (hasFlag && shouldRedirect) {
      history.push(redirect)
    }
  })

  useEffect(() => {
    const runGetOpa: boolean = true
    let orderType: string | undefined = ''
    let patientNumber: string | undefined = ''
    orderType = history.location.state?.orderType
    patientNumber = history.location.state?.patientNumber
    const shouldRedirect = !(patientNumber && orderType)

    const fetchAccessProgramData = async () => {
      if (hasFlag) {
        let runGetOpa = false

        if (shouldRedirect) {
          if (isAccessProgramPath) {
            let contactId

            try {
              const user = JSON.parse(
                localStorage.getItem('current_user') ?? '{}'
              )
              contactId = user?.contactCard?.contactId?.toString()
            } catch (e) {
              console.error('Invalid JSON in localStorage:', e)
            }

            if (contactId && contactId !== physicianId) {
              history.push(getRedirectPath())
              return
            }

            const {
              runGetOpa: updatedRunGetOpa,
              orderType: updatedOrderType,
              patientNumber: updatedPatientNumber
            } = await handleAccessProgramRedirect()

            runGetOpa = updatedRunGetOpa
            orderType = updatedOrderType
            patientNumber = updatedPatientNumber
          } else {
            history.push(getRedirectPath())
          }
        }
      }

      if (
        programId &&
        physicianId &&
        defaultShippingAddress &&
        !endpoint &&
        runGetOpa
      ) {
        getOpaUrl(
          programId,
          physicianId,
          defaultShippingAddress.addressId,
          orderType,
          patientNumber,
          patientCentricFeatureFlag
        )
          .then((response) => {
            setCanNavigateAway(false)
            let opaUrl = response.data.endPoint
            const formId = getFormId()
            if (formId) {
              opaUrl = `${opaUrl}/form/${formId}`
              localStorage.removeItem(QueryParam.ReturnUrl)
            }
            setOpaUrl(opaUrl)
          })
          .catch((error: AxiosError) => {
            if (parseOPAUrlErrorMessage(error)) {
              setIsOPAUrlError(true)
              setCanNavigateAway(true)
              return
            }
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error fetching OPA URL. ${error}`
              )
            )
          })
        return () => {
          cancelGetOpaUrl()
        }
      }
      if (endpoint) {
        setIsReadOnly(true)
        setOpaUrl(endpoint)
      }
    }

    fetchAccessProgramData()
  }, [defaultShippingAddress, dispatch, endpoint, physicianId, programId])

  const hasFlag = patientCentricFeatureFlag && !endpoint && !matchOrderDetails

  const redirectToAccessForm = (
    program: string,
    physician: string,
    patientNumber: string | undefined
  ) => {
    history.push({
      pathname: `/programs/access-programs/${program}/patient-access-form/${physician}`,
      state: {
        orderType: OPAOrderTypes.Resupply,
        patientNumber,
        from: window.location.pathname
      }
    })
  }

  const handleAccessProgramRedirect = async () => {
    const pathParts = location.pathname.split('/')
    const program = pathParts[3]
    const physician = pathParts[5]
    const patientNumber = pathParts[9]

    setPatientNumber(patientNumber)

    redirectToAccessForm(program, physician, patientNumber)
    return {
      runGetOpa: true,
      orderType: OPAOrderTypes.Resupply,
      patientNumber
    }
  }

  const fetchPatientId = async (patientNum: string, physId: string) => {
    try {
      const searchQuery: PatientSummaryAssociatedToPhysiciansSearchDtoForOpaForm =
        {
          query: patientNum,
          filter: {
            programs: [],
            patientOrderStatuses: [],
            physicianId: Number(physId)
          },
          pagination: {
            skip: 0,
            take: 5
          },
          sorting: {
            sortBy: 'lastUpdateDate',
            order: 'ASC'
          }
        }

      const response = await getPhysicianPatientsForOpaForm(
        Number(physId),
        searchQuery
      )

      if (response?.data?.result?.length) {
        const patient = response.data.result.find(
          (p) => p.patientNumber === patientNum
        )
        return patient?.patientId
      }

      return undefined
    } catch (error) {
      console.error('Error fetching patient:', error)
      return undefined
    }
  }

  const getRedirectPath = () => {
    return location?.state?.from || '/patients?pageSize=10'
  }

  const isAccessProgramPath =
    location.pathname.startsWith('/programs/access-programs/') &&
    location.pathname.includes('/patient-access-form/')

  useEffect(() => {
    isIFrameLoaded && window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage, isIFrameLoaded])

  useEffect(() => {
    isIFrameLoaded && handleUpdatePatientStatus()
  }, [isIFrameLoaded])

  const getFormId = () => {
    const matchPathWithFormId = matchPath(location.pathname, {
      path: '/programs/access-programs/:programId/patient-access-form/:physicianId/form/:formId'
    })?.isExact

    if (matchPathWithFormId) {
      //get form id
      return getPathValueFromUrl(window.location.pathname, QueryParam.Form)
    }
  }

  type Deps = [boolean, ProgramCatalogDto]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      analyticsServiceSingleton.trackPageView(AnalyticsPageEvent.ViewPAF, {
        'Program name': dependencies[1].programName,
        'Program ID': dependencies[1].projectId,
        institute_id: institute.data?.instituteId
      })
    },
    [isProgramLoading, program],
    (dependencies: Deps) => !dependencies[0] && dependencies[1]
  )

  const redirectToPreviousPage = () => {
    //check from witch page user gets here redirect to patients page in case we don't have routeState state from
    let redirect = '/patients'
    if (
      routeState?.state?.from &&
      routeState?.state?.from !== window.location.pathname
    ) {
      redirect = routeState.state.from
    }
    history.push({
      pathname: redirect,
      state: {
        ...history.location?.state,
        patientNumber: patientNumber,
        newFeature: NewFeatureElements.CancelOPA
      }
    })
  }

  const handleCancelButton = () => {
    setIsCancelling(true)
    setDeletePAFModalMode(ModalMode.active)
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.CancelOrderMA, {
      location: 'opa',
      programName: program?.programName,
      programId: programId
    })
  }

  const handleSaveAndCloseButton = (programName?: string) => {
    setIsSaving(true)
    setTimeout(() => {
      setIsSaving(false)
    }, 5000)
    const iframe = document.getElementById('opa-Iframe') as HTMLIFrameElement
    iframe?.contentWindow &&
      iframe.contentWindow.postMessage(
        {
          system: 'CLINIGEN_DIRECT_SERVICE',
          message: 'CREATE_CHECKPOINT'
        },
        '*'
      )
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.SaveAndClose, {
      programName: programName,
      programId: programId,
      location: 'opa'
    })
  }

  // handlers of delete  modal window
  const handleDeletePAFConfirmation = async () => {
    let currentPatientId = patientId

    if (currentPatientId === undefined && patientNumber) {
      currentPatientId = await fetchPatientId(patientNumber, physicianId)
    }

    if (patientNumber && currentPatientId) {
      setDeletePAFModalMode(ModalMode.submitting)
      deleteOpa(
        programId,
        physicianId,
        defaultShippingAddress?.addressId ?? '',
        patientNumber
      )
        .then((response) => {
          const newStatus =
            orderType === OPAOrderTypes.Resupply
              ? PatientOrderStatus.ReadyForResupply
              : PatientOrderStatus.BeginOrder
          if (currentPatientId !== undefined)
            updatePatient(physicianId, currentPatientId, {
              orderStatus: newStatus
            })
              .then((response) => {
                setCanNavigateAway(true)
                setIsCancelling(false)
                redirectToPreviousPage()
              })
              .catch((error: AxiosError) => {
                setDeletePAFModalMode(ModalMode.hasError)
                setDeletePAFError(error.message)
              })
        })
        .catch((error: AxiosError) => {
          setDeletePAFModalMode(ModalMode.hasError)
          setDeletePAFError(error.message)
        })
    }
  }

  const handleDeletePAFClose = () => {
    setIsCancelling(false)
    setDeletePAFModalMode(undefined)
  }

  const parseOPAUrlErrorMessage = (error: AxiosError) => {
    if (error?.response?.data) {
      const responseContent = JSON.parse(
        error.response.data.dependency.response
      )
      if (
        responseContent['o:errorDetails'] &&
        Array.isArray(responseContent['o:errorDetails'])
      ) {
        const detail = responseContent['o:errorDetails'].find(
          (detail) =>
            detail['o:errorCode'] === OPAErrorCode.XXCL_OPA_INIT_PATIENT_LOCKED
        )
        return !!detail
      }
    }
    return false
  }

  const isVisibleNotification = (): boolean => {
    return (
      !!patientCentricFeatureFlag &&
      !!history?.location?.state?.patientCreationFlag &&
      !history?.location?.state?.stockOrder
    )
  }

  const handleOpaUrlErrorClose = () => {
    history.push(`/patients`)
    setCanNavigateAway(true)
  }

  return (
    <>
      {isVisibleNotification() && (
        <ClinNotification
          title={t('ma_order_detail:notification_create_new_patient_title')}
          onClose={() => {}}
        />
      )}
      <DeletePAFModal
        isOpen={deletePAFModalMode !== undefined}
        isSubmitting={deletePAFModalMode === ModalMode.submitting}
        hasError={deletePAFModalMode === ModalMode.hasError}
        errorMessage={deletePAFError}
        handleConfirmation={handleDeletePAFConfirmation}
        handleClose={handleDeletePAFClose}
      />
      <PatientAccessForm
        program={program}
        isLoading={isProgramLoading}
        opaUrl={opaUrl}
        height={iFrameHeight}
        isReadOnly={isReadOnly}
        backButtonTitle={backButtonTitle}
        userCountry={portfolioCountryCode}
        patientCentricFeatureFlag={patientCentricFeatureFlag}
        isSaving={isSaving}
        isCancelling={isCancelling}
        handleIFrameLoaded={onLoad}
        handleGoBack={handleGoBack}
        handleCancelButton={handleCancelButton}
        handleSaveAndCloseButton={handleSaveAndCloseButton}
      />
      <PatientOPAUrlErrorModal
        isOpen={isOPAUrlError}
        onClose={handleOpaUrlErrorClose}
      />
    </>
  )
}
