import React, { forwardRef } from 'react'

import { StyledResponsiveVideoEmbed } from './ClinResponsiveVideoEmbed.styles'

interface IResponsiveVideoEmbedProps {
  /** A required Video id from vimeo however this could be extended for other platforms */
  videoId: string
  /** A required title attribute to be used on the iframe  */
  title: string
}

export const ClinResponsiveVideoEmbed = forwardRef<
  HTMLIFrameElement,
  IResponsiveVideoEmbedProps
>(({ videoId, title }, ref) => {
  return (
    <StyledResponsiveVideoEmbed>
      <iframe
        ref={ref}
        title={title}
        src={`https://player.vimeo.com/video/${videoId}`}
        frameBorder="0"
      />
    </StyledResponsiveVideoEmbed>
  )
})
ClinResponsiveVideoEmbed.displayName = 'ClinResponsiveVideoEmbed'
