import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { useLocation } from 'react-router-dom'

import { AddressModal } from './AddressModal'
import { useAddressModal } from './AddressModal/AddressModal.models'
import {
  AssociateModal,
  AssociateMode,
  useAssociateModal
} from './AssociateModal'
import { SideBarMode, useProgramStatus } from './ProgramDetail.models'
import { ProgramDetailOld } from './ProgramDetailOld'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../../../context/app'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { ICustomSelectOption } from '../../../features/CustomSelect/CustomSelect'
import { useEffectOnlyOnce } from '../../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { useGetEnrolledPhysicians } from '../../../hooks/useGetEnrolledPhysicians/useGetEnrolledPhysicians'
import { AnalyticsPageEvent } from '../../../services/Analytics/AnalyticsPageEvent'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { programDocumentSearch } from '../../../services/ApiService'
import {
  PhysiciansEnrolledSummaryDto,
  ProgramCatalogDto
} from '../../../types/swaggerTypes'
import { useErrorMessage } from '../../../utils/useErrorMessage'
import { useOnMount } from '../../../utils/useOnMount'

interface RouteStateType {
  hasRegisteredInterest: boolean
}

interface IProgramDetailRouteParams {
  programId: string
}
interface IProductDetailProps
  extends RouteComponentProps<IProgramDetailRouteParams> {}

export const ProgramDetailContainerOld: FunctionComponent<
  IProductDetailProps
> = ({ match, history }) => {
  const { t } = useTranslation()
  const { dispatch, supportContact, portfolioCountryCode } = useAppContext()
  const { programId } = match.params
  const [selectedPhysician, setSelectedPhysician] = useState<
    PhysiciansEnrolledSummaryDto | undefined
  >()
  const handleCountError = useErrorMessage(
    'There was an error fetching program document count'
  )

  const location = useLocation<{ successMessage: string | undefined }>()

  // Get document count
  const [documentCount, setDocumentCount] = React.useState<number>(0)
  useEffect(() => {
    programDocumentSearch({
      query: '',
      filter: {
        programId: parseInt(programId),
        types: [],
        languages: [],
        countryCode: portfolioCountryCode
      },
      pagination: {
        skip: 0,
        take: 0 // set to 0 to just get count
      },
      sorting: {
        sortBy: 'documentTypeName',
        order: 'desc'
      }
    })
      .then((response) => {
        setDocumentCount(response.data.pagination?.total ?? 0)
      })
      .catch((error) => {
        console.warn(error)
        handleCountError(error)
      })
  }, [handleCountError, portfolioCountryCode, programId])

  const {
    isProgramLoading,
    programError,
    program,
    programUiState,
    defaultShippingAddress
  } = useProgramStatus(programId)

  const isEnrolled = programUiState?.sideBarMode === SideBarMode.Enrolled

  const { physiciansLoading, physiciansError, physicians } =
    useGetEnrolledPhysicians(programId, isEnrolled)

  const handlePhysicianSelect = (option: ICustomSelectOption): void => {
    const foundPhysician: PhysiciansEnrolledSummaryDto | undefined =
      physicians?.find((item) => item.physicianId === option.id)
    setSelectedPhysician(
      foundPhysician &&
        physicians &&
        foundPhysician.physicianId !== selectedPhysician?.physicianId
        ? physicians.find((item) => item.physicianId === option.id)
        : undefined
    )
  }

  const handleBeginProductOrder = () => {
    history.push(
      `/programs/access-programs/${programId}/patient-access-form/${selectedPhysician?.physicianId}`,
      {
        from: window.location.pathname
      }
    )
  }

  const {
    associateMode,
    associateConfirmation,
    associateError,
    handleConfirmation,
    handleAssociateOpen,
    handleAssociateClose,
    handleConfirmationSelect
  } = useAssociateModal(selectedPhysician, handleBeginProductOrder)

  const {
    isAddressModalOpen,
    currentAddress,
    selectedAddress,
    addresses,
    handleOpenAddressModal,
    handleCloseAddressModal,
    handleAddressSelected,
    handleSubmitAddress
  } = useAddressModal()

  const handlePhysicianAssociationCheck = () => {
    if (selectedPhysician?.physicianLinkedToUser !== 'Y') {
      handleAssociateOpen()
    } else {
      handleBeginProductOrder()
    }
  }

  const handleRemoveParam = () => {
    const searchParams = new URLSearchParams(location.search)

    searchParams.delete('autoenrolled')

    history.push({
      pathname: location.pathname,

      search: `?${searchParams.toString()}`
    })
  }

  // Check if we have just submitted registered interest form
  const routeState = useLocation<RouteStateType>()
  useOnMount(() => {
    const hasRegisteredInterest = routeState?.state?.hasRegisteredInterest
    if (hasRegisteredInterest) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Success,
          t('program_register_interest:confirmation_message'),
          t('program_register_interest:confirmation_title')
        )
      )
    }
    const searchParams = new URLSearchParams(location.search)

    if (searchParams.has('autoenrolled')) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Success,
          t('enrol_user_on_to_program:auto_enrolment_success_message'),
          t('enrol_user_on_to_program:auto_enrolment_success_title')
        )
      )

      handleRemoveParam()
    }
  })

  const handleRegisterInterestRequest = () =>
    history.push(
      `/programs/access-programs/${program?.projectId}/register-interest`
    )

  const handleEnrolRequest = () =>
    history.push(
      `/programs/access-programs/${program?.projectId}/enrolment-form`
    )

  const handlePhysicianEnrolRequest = () =>
    history.push(
      `/programs/access-programs/${program?.projectId}/physician-enrolment-form`
    )

  type Deps = [boolean, ProgramCatalogDto]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      analyticsServiceSingleton.trackPageView(AnalyticsPageEvent.ViewProgram, {
        'Program name': dependencies[1].programName,
        'Program ID': dependencies[1].projectId
      })
    },
    [isProgramLoading || (isEnrolled && physiciansLoading), program],
    (dependencies: Deps) => dependencies[0] === false && dependencies[1]
  )

  return (
    <>
      {selectedPhysician && (
        <AssociateModal
          isOpen={associateMode !== undefined}
          physician={selectedPhysician}
          associateConfirmation={associateConfirmation}
          errorMessage={associateError}
          isSubmitting={associateMode === AssociateMode.submitting}
          hasError={associateMode === AssociateMode.hasError}
          handleConfirmationSelect={handleConfirmationSelect}
          handleConfirmation={handleConfirmation}
          handleClose={handleAssociateClose}
        />
      )}
      {addresses && (
        <AddressModal
          isOpen={isAddressModalOpen}
          currentAddress={currentAddress}
          selectedAddress={selectedAddress}
          addresses={addresses}
          isSubmitting={false}
          requestShippingAddressLink={'/new-shipping-address'}
          handleAddressSelected={handleAddressSelected}
          handleSubmitAddress={handleSubmitAddress}
          handleClose={handleCloseAddressModal}
        />
      )}
      <ProgramDetailOld
        isLoading={isProgramLoading || (isEnrolled && physiciansLoading)}
        hasError={programError || physiciansError}
        program={program}
        programUiState={programUiState}
        physicians={physicians}
        supportContact={supportContact}
        selectedPhysician={selectedPhysician}
        shippingAddress={defaultShippingAddress}
        documentCount={documentCount}
        handlePhysicianSelect={handlePhysicianSelect}
        handleBeginProductOrder={handlePhysicianAssociationCheck}
        handleOpenAddressModal={handleOpenAddressModal}
        handleRegisterInterestRequest={handleRegisterInterestRequest}
        handleEnrolRequest={handleEnrolRequest}
        handlePhysicianEnrolRequest={handlePhysicianEnrolRequest}
      />
    </>
  )
}
