import styled from 'styled-components'

import { StyledClinLoaderSVG } from '../../components/ClinLoader/ClinLoader.styles'

export const StyledLoadingText = styled.div({
  fontFamily: 'Arial',
  color: '#270072'
})

export const StyledAppLoader = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '48px',
  [StyledClinLoaderSVG]: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
    flex: '0 0 24px'
  }
})
