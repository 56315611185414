import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router'

import {
  StyledClinHeader,
  StyledFeatureHighlightWrapper,
  StyledNavItemInner
} from './NavbarContainer.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinDropdown } from '../../components/ClinDropdown'
import { ClinDropdownMenu } from '../../components/ClinDropdownMenu'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinNavbar } from '../../components/ClinNavbar'
import {
  StyledBasketCounter,
  StyledMainItemsName
} from '../../components/ClinNavbar/ClinNavbar.styles'
import { ClinNavIconButton } from '../../components/ClinNavIconButton'
import { StyledNavTextAndChevron } from '../../components/ClinNavIconButton/ClinNavIconButton.styles'
import {
  ClinNavItem,
  ClinNavSecondItem,
  ClinNavUpperItem
} from '../../components/ClinNavItem/ClinNavItem'
import {
  NewFeatureElements,
  PageNames
} from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipContainer } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import {
  getUserRoleWithCountry,
  isAusGaUser,
  isAusMaUser,
  UserRole,
  UserRoleRecord,
  UserRoleWithCountry
} from '../../constants'
import { useAppContext } from '../../context/app'
import { useBasket } from '../../context/basket'
import { useFeatureFlags } from '../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../context/featureFlags/FeatureFlagKeys'
import { useNewFeatures, removeFeature } from '../../context/newFeatures'
import { createShowInstituteModalEvent } from '../../events/InstituteModalEvent'
import { DropdownNavButton } from '../../features/DropdownNavButton'
import { DropdownUpperNavButton } from '../../features/DropdownNavButton/DropdownUpperNavButton'
import { matchInArray } from '../../features/Search/AutoSuggest/AutoSuggest.model'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'
import { useNewFeaturesList } from '../../hooks/useNewFeaturesList/useNewFeaturesList'
import ColumnTooltip from '../../pages/OrderPages/Orders/ColumnTooltip/ColumnTooltip'
import authService from '../../services/AuthService'
import {
  INavigationItem,
  ITopTierNavigationItem,
  navigationItems
} from '../../services/ContentProvider'
import {
  FLOATING_OVERLAY_VISIBLE,
  eventEmitter
} from '../../utils/eventEmitter'
import { getIsPageAccessibleFromCountry } from '../../utils/getIsPageAccessibleFromCountry'
import { noop } from '../../utils/noop'

interface IMenuState {
  title: string
  isActive: boolean
}
interface ChildNavItemsState {
  [title: string]: INavigationItem[]
}

// Extract the NavItem rendering logic to its own function
const renderNavItem = (
  props: {
    title: string
    path: string
    translationKey: string
    tooltipTextKey?: string
    customTooltip?: string
    disabled?: boolean
    navItems?: INavigationItem[]
    showNewFeatureHighlight?: boolean
    displayInitialSentence?: boolean
    fixStyle?: boolean
  },
  state: {
    menuStates: IMenuState[]
    newFeaturesList: any
    numberOfEnrolledPrograms: number | undefined
    shouldSetClassToMenu: (
      fixStyle: boolean | undefined,
      path: string
    ) => boolean
    isMobile: boolean
    handleDropDownClick: (title: string, path: string) => void
    featuresDispatch: any
    showPatientNavbarNewFeatureHighlight: () => boolean
    showOrdersNavbarNewFeatureHighlight: () => boolean
    isCurrentPath: (path: string) => boolean
    t: (key: string) => string
    removeFeature: Function
    portfolioCountryCode: string
  }
) => {
  const {
    title,
    path,
    translationKey,
    tooltipTextKey,
    customTooltip,
    disabled,
    navItems,
    showNewFeatureHighlight,
    displayInitialSentence,
    fixStyle
  } = props

  const {
    menuStates,
    newFeaturesList,
    numberOfEnrolledPrograms,
    shouldSetClassToMenu,
    isMobile,
    handleDropDownClick,
    featuresDispatch,
    showPatientNavbarNewFeatureHighlight,
    showOrdersNavbarNewFeatureHighlight,
    isCurrentPath,
    t,
    removeFeature,
    portfolioCountryCode
  } = state

  const menuStateItem: IMenuState | undefined = menuStates?.find(
    (navItem) => navItem.title === title
  )

  const handleNavItemClick = () => {
    if (navItems) {
      handleDropDownClick(title, path)
    }

    // Handle feature removal on click
    if (
      path === '/patients' &&
      newFeaturesList[NewFeatureElements.PatientsNavbar]?.id
    ) {
      removeFeature(
        featuresDispatch,
        newFeaturesList[NewFeatureElements.PatientsNavbar].id
      )
    }

    if (
      path === '/orders' &&
      newFeaturesList[NewFeatureElements.OrdersNavbar]?.id
    ) {
      removeFeature(
        featuresDispatch,
        newFeaturesList[NewFeatureElements.OrdersNavbar].id
      )
    }
  }

  const renderPatientsFeatureTooltip = () => {
    if (
      path === '/patients' &&
      showNewFeatureHighlight &&
      numberOfEnrolledPrograms! > 0 &&
      newFeaturesList[NewFeatureElements.PatientsNavbar] &&
      showPatientNavbarNewFeatureHighlight()
    ) {
      return (
        <StyledFeatureHighlightWrapper>
          <ClinNewFeatureTooltipContainer
            feature={newFeaturesList[NewFeatureElements.PatientsNavbar]}
            openTooltipInitially={true}
            disableDismiss={true}
          />
        </StyledFeatureHighlightWrapper>
      )
    }
    return <></>
  }

  const renderOrdersFeatureTooltip = () => {
    if (
      path === '/orders' &&
      showNewFeatureHighlight &&
      newFeaturesList[NewFeatureElements.OrdersNavbar] &&
      showOrdersNavbarNewFeatureHighlight()
    ) {
      return (
        <StyledFeatureHighlightWrapper>
          <ClinNewFeatureTooltipContainer
            feature={newFeaturesList[NewFeatureElements.OrdersNavbar]}
            openTooltipInitially={true}
            disableDismiss={true}
            total={2}
            current={2}
          />
        </StyledFeatureHighlightWrapper>
      )
    }
    return <></>
  }

  const shouldShowHighlight = !!(
    showNewFeatureHighlight &&
    ((path === '/patients' &&
      numberOfEnrolledPrograms! > 0 &&
      showPatientNavbarNewFeatureHighlight()) ||
      (path === '/orders' && showOrdersNavbarNewFeatureHighlight()))
  )

  return (
    <ClinDropdown
      className={
        shouldSetClassToMenu(fixStyle, path) ? 'navbarZIndexTooltipFix' : ''
      }
      showHover={true}
      key={`${title}-${path}`}
      alignment="left"
      trigger={() => (
        <>
          {renderPatientsFeatureTooltip()}
          {renderOrdersFeatureTooltip()}

          <ColumnTooltip
            tooltipText={tooltipTextKey ? t(tooltipTextKey) : ''}
            additionalClass={customTooltip}
            disableOnMobile={true}
          >
            <ClinNavSecondItem
              style={{ whiteSpace: 'nowrap' }}
              onClick={handleNavItemClick}
              to={path}
              displayInitialSentence={displayInitialSentence}
              showHighlight={shouldShowHighlight}
              exact={true}
              strict={true}
              current={isCurrentPath(path)}
              disabled={disabled}
            >
              {t(translationKey)}
              {navItems && navItems.length > 0 && (
                <StyledNavItemInner>
                  {menuStateItem && (
                    <ClinIcon
                      iconName={
                        isMobile
                          ? ClinIconPathName.ChevronRight
                          : menuStateItem.isActive
                          ? ClinIconPathName.ChevronUp
                          : ClinIconPathName.ChevronDown
                      }
                      iconSize={
                        isMobile ? ClinTheme.fontSizes[0] : ClinTheme.space[3]
                      }
                      style={{
                        position: 'relative',
                        top: isMobile ? '2px' : ClinTheme.space[0],
                        marginLeft: isMobile
                          ? ClinTheme.fontSizes[0]
                          : ClinTheme.space[2]
                      }}
                    />
                  )}
                </StyledNavItemInner>
              )}
            </ClinNavSecondItem>
          </ColumnTooltip>
        </>
      )}
    >
      {renderDropdownMenu(
        navItems,
        isMobile,
        title,
        handleDropDownClick,
        portfolioCountryCode,
        t
      )}
    </ClinDropdown>
  )
}

// Extract dropdown menu rendering to its own function
const renderDropdownMenu = (
  navItems: INavigationItem[] | undefined,
  isMobile: boolean,
  title: string,
  handleDropDownClick: (title: string, path: string) => void,
  portfolioCountryCode: string,
  t: (key: string) => string
) => {
  if (!navItems || navItems.length === 0) {
    return <></>
  }

  if (isMobile) {
    return <></>
  }

  return (
    <ClinDropdownMenu style={{ padding: 0 }}>
      {navItems
        .filter((navItem) =>
          getIsPageAccessibleFromCountry(
            portfolioCountryCode,
            navItem.onlyAllowForCountryCodes
          )
        )
        .map((navItem) => (
          <DropdownNavButton
            className={isMobile ? 'dropdown-nav-button-mobile' : ''}
            onClick={() => {
              handleDropDownClick(title, navItem.path)
            }}
            key={navItem.path}
            to={navItem.path}
            disabled={navItem.disabled}
          >
            {t(navItem.translationKey)}
          </DropdownNavButton>
        ))}
    </ClinDropdownMenu>
  )
}

// Add this helper function outside of any component functions
const filterAccessibleNavItems = (
  items: INavigationItem[] | undefined,
  countryCode: string
): INavigationItem[] => {
  if (!items) return []

  return items.filter((item) =>
    getIsPageAccessibleFromCountry(countryCode, item.onlyAllowForCountryCodes)
  )
}

export const NavbarContainer: FunctionComponent = () => {
  const { t } = useTranslation()
  const {
    dispatch,
    userRole,
    institute,
    portfolioCountryCode,
    numberOfEnrolledPrograms
  } = useAppContext()
  const [{ basket }] = useBasket()
  const initialMenuState = navigationItems.map((item) => {
    return {
      title: item.title,
      isActive: false
    }
  })
  const { useFeatureFlag } = useFeatureFlags()
  const patientCentricFeatureFlag = useFeatureFlag(
    FeatureFlagKeys.PatientCentricJourneyPerUser
  )
  const [menuStates, setMenuStates] = useState<IMenuState[]>(initialMenuState)
  const [filteredNavItems, setFilteredNavItems] = useState<
    | React.ReactElement<typeof ClinNavItem>[]
    | React.ReactElement<typeof ClinNavItem>
  >()
  const [childNavItems, setChildNavItems] = useState<ChildNavItemsState>({})
  const [filteredChildNavItems, setFilteredChildNavItems] =
    useState<INavigationItem[]>()
  const [isFeatureVisible, setIsFeatureVisible] = useState(false)

  const { newFeaturesList, displayNewFeature } = useNewFeaturesList(
    PageNames.Navbar
  )

  const { enableNewHomePage } = useCheckUnableHomePage()

  const location = useLocation()
  const [, featuresDispatch] = useNewFeatures() // Get dispatch function

  const [isMobile, setIsMobile] = React.useState<boolean>(false)

  const updateIsMobile = () => {
    setIsMobile(
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) <= ClinTheme.breakpoints[2]
    )
  }

  const listener = () => {
    updateIsMobile()
  }

  useEffect(() => {
    updateIsMobile() // Set initial state
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  const showPatientNavbarNewFeatureHighlight = (): boolean => {
    return !!(
      newFeaturesList[NewFeatureElements.PatientsNavbar] &&
      displayNewFeature &&
      displayNewFeature(NewFeatureElements.PatientsNavbar) &&
      numberOfEnrolledPrograms! > 0
    )
  }

  const showOrdersNavbarNewFeatureHighlight = (): boolean => {
    return !!(
      newFeaturesList[NewFeatureElements.OrdersNavbar] &&
      displayNewFeature &&
      displayNewFeature(NewFeatureElements.OrdersNavbar)
    )
  }

  const userRoleWithCountry = getUserRoleWithCountry(
    portfolioCountryCode,
    userRole
  )

  const excludedRoleWithCountry = [
    UserRoleWithCountry.AusBase,
    UserRoleWithCountry.AusBaseMa,
    UserRoleWithCountry.Base,
    UserRoleWithCountry.BaseMa
  ]

  const isAllowedToDisplay = (navItem: ITopTierNavigationItem) => {
    return (
      !navItem.hideForCountriesCode?.includes(portfolioCountryCode) &&
      !navItem.hideForCountriesCodeWithRole?.includes(userRoleWithCountry!)
    )
  }

  const isMaUser = userRole && UserRoleRecord[userRole as UserRole].isMaUser

  const getBadgeValue = (value: number): string => {
    return value > 99 ? '99+' : value.toString()
  }

  useEffect(() => {
    const handleFeatureSwitch = (isVisible: boolean) => {
      setIsFeatureVisible(isVisible)
    }

    eventEmitter.on(FLOATING_OVERLAY_VISIBLE, handleFeatureSwitch)

    return () => {
      eventEmitter.off(FLOATING_OVERLAY_VISIBLE, handleFeatureSwitch)
    }
  }, [])

  const shouldSetClassToMenu = (
    fixStyle: boolean | undefined,
    path: string
  ) => {
    const shouldShowPatients: boolean = !!showPatientNavbarNewFeatureHighlight()
    const shouldShowOrders: boolean = !!showOrdersNavbarNewFeatureHighlight()
    if (shouldShowPatients && path === '/patients') {
      return false
    }
    if (shouldShowOrders && path === '/orders') {
      return false
    }
    if (shouldShowPatients && shouldShowOrders && fixStyle) {
      return true
    }
    if (isFeatureVisible) {
      return true
    }
    return false
  }

  // Function to filter navigation items and set their state
  const processNavigationItems = () => {
    return navigationItems
      .filter((navItem) => {
        if (navItem.path === '/home') {
          return enableNewHomePage
        }
        if (navItem.path === '/patients') {
          if (!isAusGaUser(portfolioCountryCode, userRole))
            return patientCentricFeatureFlag
        } else {
          return (
            (navItem.isVisibleForMaUser && isMaUser === true) ||
            (!!isMaUser === false && isAllowedToDisplay(navItem)) ||
            (navItem.isVisibleForAusMaUser &&
              isAusMaUser(portfolioCountryCode, userRole))
          )
        }
      })
      .map((navItem) => {
        if (isMobile && navItem.navItems && navItem.navItems.length > 0) {
          const accessibleNavItems = filterAccessibleNavItems(
            navItem.navItems,
            portfolioCountryCode
          )

          setChildNavItems((prevState) => ({
            ...prevState,
            [navItem.title]: accessibleNavItems
          }))
        }

        return renderNavItem(navItem, {
          menuStates,
          newFeaturesList,
          numberOfEnrolledPrograms,
          shouldSetClassToMenu,
          isMobile,
          handleDropDownClick,
          featuresDispatch,
          showPatientNavbarNewFeatureHighlight,
          showOrdersNavbarNewFeatureHighlight,
          isCurrentPath,
          t,
          removeFeature,
          portfolioCountryCode
        })
      })
  }

  useEffect(() => {
    const updatedFilteredNavItems = processNavigationItems()
    setFilteredNavItems(updatedFilteredNavItems)
  }, [
    patientCentricFeatureFlag,
    location,
    numberOfEnrolledPrograms,
    newFeaturesList,
    isFeatureVisible,
    isMobile,
    menuStates
  ])

  const getFilteredChildNavItems = (title: string) => {
    const childItems = childNavItems[title]
    if (!childItems) {
      const navigationItem = navigationItems.find(
        (item) => item.title === title
      )
      return filterAccessibleNavItems(
        navigationItem?.navItems,
        portfolioCountryCode
      )
    }
    return childItems
  }

  const handleDropDownClick = (title: string, path: string) => {
    const updatedMenuStates = menuStates.map((item) => {
      if (item.title === title) {
        return {
          ...item,
          isActive: !item.isActive
        }
      } else {
        return {
          ...item,
          isActive: false
        }
      }
    })

    isMobile && setFilteredChildNavItems(getFilteredChildNavItems(title))
    setMenuStates(updatedMenuStates)
  }

  const isCurrentPath = (path: string): boolean => {
    const excludeRegex = /^\/programs\/my-physicians\/\d+\/\d+$/

    return (
      !excludeRegex.test(location.pathname) &&
      !!matchPath(location.pathname, { path, exact: false })
    )
  }

  if (matchInArray(location.pathname, ['onboarding', 'opa-checkout'])) {
    return null
  }

  const resetOnClose = () => {
    setMenuStates(initialMenuState)
    resetFilteredChildNavItems()
  }

  const resetFilteredChildNavItems = () => {
    setFilteredChildNavItems(undefined)
  }

  return (
    <StyledClinHeader>
      <ClinNavbar
        resetOnClose={resetOnClose}
        resetFilteredChildNavItems={resetFilteredChildNavItems}
        navItems={filteredNavItems}
        childNavItems={filteredChildNavItems}
        navIcons={
          <>
            <ClinNavIconButton
              title={institute?.data?.instituteName ?? ''}
              iconName={ClinIconPathName.Switch}
              tooltip={t('opa_checkout:institute_hover_tooltip')}
              toLink="/institute/details"
              disabled={true}
              iconViewBox="0 0 18 20"
              className="institution-name tooltip block-institute-picker"
              onClick={() => dispatch(createShowInstituteModalEvent())}
            ></ClinNavIconButton>

            {!isMaUser && (
              <ClinNavIconButton
                title={t('navigation:bookmarks')}
                iconName={ClinIconPathName.Bookmark}
                tooltip={t('navigation:bookmarks_tooltip')}
                toLink="/bookmarks"
                id="bookmarks"
              ></ClinNavIconButton>
            )}
            <ClinDropdown
              className={isFeatureVisible ? 'navbarZIndexTooltipFix' : ''}
              showHover={true}
              alignment="right"
              isMyAccount={true}
              trigger={() => (
                <ClinNavIconButton
                  className="my-account"
                  title={t('navigation:my_account')}
                  iconName={ClinIconPathName.Edit}
                  toLink="/account/details"
                  disabled={true}
                  isCurrentPath={isCurrentPath('/account/details')}
                ></ClinNavIconButton>
              )}
            >
              <ClinDropdownMenu
                className="account-menu"
                style={{
                  padding: 0,
                  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
                  borderRadius: '3px'
                }}
              >
                <div style={{ paddingBottom: '8px' }}>
                  {userRole && (
                    <>
                      <DropdownUpperNavButton
                        className="my-account"
                        to={`/account/details`}
                      >
                        {t('navigation:my_account')}
                      </DropdownUpperNavButton>
                      <DropdownUpperNavButton to={`/institute/details`}>
                        {t('navigation:my_institute')}
                      </DropdownUpperNavButton>
                      {userRoleWithCountry !== undefined &&
                        !excludedRoleWithCountry.includes(
                          userRoleWithCountry
                        ) && (
                          <DropdownUpperNavButton to={'/new-user'}>
                            {t('navigation:invite_colleague')}
                          </DropdownUpperNavButton>
                        )}
                      <DropdownUpperNavButton to={`/preferences`}>
                        {t('navigation:preferences')}
                      </DropdownUpperNavButton>
                    </>
                  )}
                  <DropdownUpperNavButton
                    className={'block-log-out'}
                    to={`/logout`}
                    onClick={(event) => {
                      event.preventDefault()
                      authService.logout().then(() => noop())
                    }}
                  >
                    {t('navigation:log_out')}
                  </DropdownUpperNavButton>
                </div>
              </ClinDropdownMenu>
            </ClinDropdown>
            {userRole && !isMaUser && (
              <StyledNavTextAndChevron
                style={{ marginRight: '15px', marginTop: '0' }}
                className="basket-link"
              >
                <ClinNavUpperItem
                  to={'/basket'}
                  exact={true}
                  strict={true}
                  style={{ position: 'relative' }}
                >
                  <ClinIcon
                    style={{ marginRight: '5px' }}
                    iconName={ClinIconPathName.NewBasket}
                    iconSize={'27px'}
                    iconHeight={'19px'}
                    iconFill={ClinTheme.colors.white}
                    viewBox="0 0 27 22"
                  />
                  {basket.data && basket.data.items.length > 0 && (
                    <StyledBasketCounter className="basket basket-items">
                      {getBadgeValue(basket?.data?.items?.length)}
                    </StyledBasketCounter>
                  )}
                  <StyledMainItemsName>
                    {t('navigation:basket')}
                  </StyledMainItemsName>
                </ClinNavUpperItem>
              </StyledNavTextAndChevron>
            )}
          </>
        }
      />
    </StyledClinHeader>
  )
}
