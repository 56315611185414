import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react'
import {
  ActionType,
  BasketStatus,
  getBasketAsync,
  useBasket
} from '../../context/basket'

import { useHistory } from 'react-router'
import { useAppContext } from '../../context/app'
import {
  CreateShippingAddressDto,
  OrderAddressDto,
  PriceAndAvailabilityDto,
  UpdateBasketDetailsDto,
  OrderDto,
  CreateCdhShippingAddressDto
} from '../../types/swaggerTypes'
import { Checkout } from './Checkout'
import { ClinTheme } from '../../ClinTheme'
import {
  getPriceAndAvailabilityForSku,
  placeDraftOrder,
  submitOrder,
  getCurrentBasket,
  updateOrder,
  cancelDraftOrder,
  newUnvalidatedShippingAddress,
  AuthError
} from '../../services/ApiService'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { CreateAddressModal } from '../CheckoutOptions/CreateAddressModal/CreateAddressModal'
import { useCountries } from '../../utils/useCountries'
import { handleBasketError } from '../../utils/basketUtils'
import { showWootricSurvey } from '../../services/Wootric/showWootricSurvey'
import {
  UserRoleRecord,
  defaultBasketDetails,
  OrderDelivery
} from '../../constants'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { BasketViewState, returnDefaultBasketValues } from '../Basket/Basket'
import useChangeBackgroundColor from '../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import {
  ValidationType,
  getInputValidation
} from '../../utils/Forms/getInputValidation'
import { Col, Row } from 'react-grid-system'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { ClinSpinner } from '../../components/ClinSpinner/ClinSpinner'
import { getUserPhone } from '../../utils/getUserPhone'
import { getLocallyStoredInstituteId } from '../../services/UserInstituteService'
import { useBeforeUnload } from '../../utils/useBeforeUnload'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { getDefaultWarehouseCode } from '../Products/ProductVariant/ProductVariant.models'

export enum CheckoutViewMode {
  ShowOrders = 'ShowOrders',
  Submitting = 'Submitting',
  Loading = 'Loading',
  Redirect = 'Redirect'
}

interface FieldErrors {
  nameError?: string
  phoneError?: string
}

interface IAugmentedOrder extends OrderDto {
  useCurrentDetails: boolean
  deliveryOption: OrderDelivery
  availableDeliveryOptions: OrderDelivery[]
}

export const CheckoutContainer: FunctionComponent = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const {
    dispatch,
    userDetails,
    defaultShippingAddress,
    portfolioCountryCode,
    institute,
    userRole,
    user
  } = useAppContext()

  const { countries } = useCountries()

  const [orderWasSubmitted, setOrderWasSubmitted] = useState<boolean>(false)
  const [
    { orders, viewBasket, basketDetails, completedOrder, basket },
    basketDispatch
  ] = useBasket()
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState<
    string | undefined
  >(undefined)
  const [order, setOrder] = useState<OrderDto>()
  const [cutOffTime, setCutOffTime] = useState<string | undefined>(undefined)
  useChangeBackgroundColor(ClinTheme.colors.lightGrey)

  const [newAddress, setNewAddress] = useState<OrderAddressDto>()
  const [isNewShippingAddressOpen, setIsNewShippingAddressOpen] =
    useState<boolean>(false)
  const [hasErrorMessage, setHasErrorMessage] = useState<string>()

  const [viewMode, setViewMode] = useState<CheckoutViewMode>(
    CheckoutViewMode.Loading
  )

  const [deliveryOptions, setDeliveryOptions] =
    useState<UpdateBasketDetailsDto>(basketDetails)

  const [deliveryAddresses, setDeliveryAddresses] = useState<OrderAddressDto[]>(
    []
  )
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  const [isRefreshingBasket, setIsRefreshingBasket] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [draftOrderId, setDraftOrderId] = useState<string | undefined>(
    undefined
  )

  const [isInitialized, setIsInitialized] = useState(false)
  const [orderId, setOrderId] = useState<string | undefined>(undefined)
  const [deliveryNote, setDeliveryNote] = useState<string>('')
  const [isSubmittingAddress, setIsSubmittingAddress] = useState<boolean>(false)
  const [addressModalError, setAddressModalError] = useState<string>(
    'Error creating address'
  )
  const [resetForm, setResetForm] = useState<boolean>(false)
  const localStorageDeliveryNoteKey = 'deliveryNote'
  const localStorageAddressKey = 'new-address'

  const [allOrders, setAllOrders] = useState<OrderDto[]>([])

  const saveAddressToLocalStorage = async (address: OrderAddressDto) => {
    const currentInstituteId = await getLocallyStoredInstituteId()
    const savedInstituteId = localStorage.getItem('institute_id')
    if (!savedInstituteId || savedInstituteId !== currentInstituteId) {
      localStorage.removeItem(localStorageAddressKey)
      localStorage.setItem('institute_id', currentInstituteId || '')
    }
    localStorage.setItem(localStorageAddressKey, JSON.stringify(address))
  }

  useBeforeUnload(cancelDraftOrder, isInitialized)

  const handleDeliveryNote = () => {
    if (deliveryNote) {
      localStorage.setItem(localStorageDeliveryNoteKey, deliveryNote)
      analyticsServiceSingleton.trackEvent(AnalyticsEvent.AddDeliveryNote, {})
    } else {
      localStorage.setItem(localStorageDeliveryNoteKey, '')
    }
  }
  const getAddressResponseErrorMessage = (message: any) => {
    if (message) {
      const messageLine = message.split(',')
      if (messageLine && messageLine[6]) {
        const messageLineText = messageLine[6].split(':')
        if (messageLineText) {
          const errorMessage = messageLineText[2]
          if (errorMessage) {
            const errorMessageWithoutQuotations = errorMessage.split('"')
            if (errorMessageWithoutQuotations) {
              const finalErrorMessage = errorMessageWithoutQuotations[1]
              return finalErrorMessage ?? ''
            }
          }
        }

        return ''
      }
    }
  }

  // Add a ref to track initialization status
  const initializationInProgress = useRef(false)

  // Refresh basket data
  const refreshBasketData = useCallback(async () => {
    try {
      setIsRefreshingBasket(true)
      const response = await getCurrentBasket()

      if (response?.data) {
        response.data.items ??= []
        response.data.orders ??= []

        basketDispatch({
          type: ActionType.UpdateBasket,
          basket: { data: response.data, isLoading: false }
        })
      }
    } catch (error) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Error,
          `Error refreshing basket data: ${error}`
        )
      )
    } finally {
      setTimeout(() => {
        setIsRefreshingBasket(false)
        setIsInitialLoad(false)
      }, 100)
    }
  }, [basketDispatch, dispatch])

  // Initial basket load
  useEffect(() => {
    refreshBasketData()
  }, [refreshBasketData])

  // Price and availability calculation
  useEffect(() => {
    const calculatePriceAndAvailability = async () => {
      if (
        !basket?.data ||
        !defaultShippingAddress?.addressId ||
        isRefreshingBasket
      ) {
        return
      }

      setViewMode(CheckoutViewMode.Loading)

      try {
        const validItems = basket.data.items.every(
          (item) => item && item.sku && item.skuCatalogItem
        )

        if (!validItems) {
          await refreshBasketData()
          return
        }

        const skus: string[] = basket.data.items.map((item) => item.sku)
        let latestExpectedDate: string | undefined
        let latestCutOffTime: string | undefined

        // Get the default warehouse code for the current portfolio country
        const defaultWarehouse = getDefaultWarehouseCode(portfolioCountryCode)

        // Flag to track if any item is back-ordered (0 stock in default warehouse)
        let hasBackOrderedItem = false

        // Check if any item in the basket has 0 reservable stock in the default warehouse
        basket.data.items.forEach((item) => {
          if (item.skuCatalogItem?.stockLevels) {
            const defaultWarehouseStock = item.skuCatalogItem.stockLevels.find(
              (stock) => stock.warehouse === defaultWarehouse
            )

            // If no stock found for default warehouse or reservable stock is 0, mark as back-ordered
            if (
              !defaultWarehouseStock ||
              defaultWarehouseStock.reservableStock <= 0
            ) {
              hasBackOrderedItem = true
            }
          }
        })

        const pricingCalls: Promise<PriceAndAvailabilityDto>[] = skus.map(
          (sku) =>
            getPriceAndAvailabilityForSku(
              sku,
              defaultShippingAddress?.addressId
            ).then((r) => {
              if (
                !latestExpectedDate ||
                (r.data.expectedDate &&
                  r.data.expectedDate > latestExpectedDate)
              ) {
                latestExpectedDate = r.data.expectedDate
                latestCutOffTime = r.data.cutOffTime
              }
              return r.data
            })
        )

        const results = await Promise.all(pricingCalls)

        // Only set the estimatedDeliveryDate when ALL items have stock > 0 in the default warehouse
        if (!hasBackOrderedItem) {
          setEstimatedDeliveryDate(latestExpectedDate)
          setCutOffTime(latestCutOffTime)
        } else {
          // Clear EDD display when ANY item is back-ordered
          setEstimatedDeliveryDate(undefined)
          setCutOffTime(undefined)
        }

        if (basket.data) {
          basketDispatch({
            type: ActionType.SetCurrentBasket,
            basket: basket.data,
            priceAndAvailability: results,
            countryCode: portfolioCountryCode
          })
        }

        setViewMode(CheckoutViewMode.ShowOrders)
      } catch (error) {
        setHasErrorMessage(`An error occurred fetching your basket. ${error}`)
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `An error occurred fetching your basket. ${error}`
          )
        )
      }
    }
    calculatePriceAndAvailability()
  }, [
    basket?.data,
    defaultShippingAddress?.addressId,
    portfolioCountryCode,
    isRefreshingBasket
  ])

  const handleBackToBasket = () => {
    history.push('/basket')
  }

  const defaultCountry = countries?.find(
    (c) => c.countryCode === 'GB' || c.countryCode === 'IE'
  )

  useEffect(() => {
    if (
      completedOrder &&
      orderWasSubmitted &&
      viewBasket?.state === BasketStatus.Submitted.toString()
    ) {
      // Get the new basket
      getBasketAsync(basketDispatch).then(() => {
        // Empty basket and refresh
        // This is commented releted to ticket clos-2075
        // basketDispatch({
        //   type: ActionType.EmptyBasket
        // })

        history.push(`/checkout/summary`)
      })
    }
  }, [basketDispatch, history, orderWasSubmitted, viewBasket, completedOrder])

  useEffect(() => {
    if (orders && orders.length > 0 && !orderWasSubmitted) {
      setViewMode(CheckoutViewMode.ShowOrders)
    }
  }, [orderWasSubmitted, orders])

  useEffect(() => {
    if (
      !basket.isLoading &&
      basket?.data?.items?.length === 0 &&
      countries?.length !== 0 &&
      !completedOrder &&
      !orderWasSubmitted &&
      viewBasket?.state !== BasketStatus.Submitted.toString()
    ) {
      history.push('/basket')
      return
    } else {
      setViewMode(CheckoutViewMode.ShowOrders)
    }
  }, [basket.isLoading, basket?.data?.items?.length, history, countries])

  // If we navigate away remove orders - no way of knowing what
  useEffect(
    () => () => {
      cancelDraftOrder()
        .then(() => console.log('Cancelled order'))
        .catch((error) =>
          console.warn(`Attempt to cancel order failed: ${error}`)
        )
    },
    []
  )
  useEffect(() => {
    if (institute?.data?.instituteId) {
      const storedInstituteId = localStorage.getItem('institute_id')
      if (
        storedInstituteId &&
        storedInstituteId !== institute.data.instituteId.toString()
      ) {
        localStorage.removeItem(localStorageAddressKey)
        localStorage.setItem(
          'institute_id',
          institute.data.instituteId.toString()
        )
      }
    }
  }, [institute?.data?.instituteId])

  const compareAddresses = (
    address1: OrderAddressDto,
    address2: OrderAddressDto
  ): boolean => {
    return (
      address1.address1 === address2.address1 &&
      address1.city === address2.city &&
      address1.postalCode === address2.postalCode &&
      address1.state === address2.state
    )
  }
  const isSelectedAddressSameAsStored = (): boolean => {
    const savedAddressStr = localStorage.getItem(localStorageAddressKey)
    if (!savedAddressStr) return false

    try {
      const savedAddress = JSON.parse(savedAddressStr) as OrderAddressDto
      const selectedAddress = deliveryAddresses.find(
        (addr) => addr.addressId === deliveryOptions.shippingAddressId
      )

      if (!selectedAddress) return false

      return compareAddresses(selectedAddress, savedAddress)
    } catch (e) {
      console.error('Error parsing saved address:', e)
      return false
    }
  }
  /**
   * Order is actually booked at this step
   */
  const handlePlaceOrderSubmit = () => {
    if (!hasErrorMessage) {
      setViewMode(CheckoutViewMode.Submitting)
      window.scrollTo({
        behavior: 'smooth',
        top: 0
      })

      // First update the order with shipping hold if needed
      const isDefaultPoNumber = deliveryOptions.poNumber === 'PO'
      const isNewShippingAddress = isSelectedAddressSameAsStored()
      updateOrder(orderId!, orderId!, {
        bookedFlag: 'N',
        shippingHold: isNewShippingAddress ? 'Y' : 'N',
        deliverToContact: deliveryOptions.deliverToContact,
        custPONumber: isDefaultPoNumber ? orderId : deliveryOptions.poNumber,
        recipientEmail: deliveryOptions.recipientEmail,
        recipientPhoneNumber: deliveryOptions.recipientPhoneNumber,
        deliveryNote: deliveryNote,
        shipToOrgId: !isNaN(parseInt(deliveryOptions.shippingAddressId!))
          ? parseInt(deliveryOptions.shippingAddressId!)
          : null
      })
        .then(() => {
          handleDeliveryNote()
          return submitOrder(draftOrderId!)
        })
        .then((response) => {
          response.data.checkedOutOrders.map((order) =>
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.OrderCompleted,
              {
                order: order.orderNumber,
                order_number: order.orderNumber,
                division: 'GA',
                institute_id: user?.institute?.instituteId,
                has_hold: order.holds.length > 0,
                order_value: order.totals.total
              }
            )
          )
          setOrderWasSubmitted(true)
          basketDispatch({
            type: ActionType.OrderSubmitted,
            completedOrder: response.data
          })
          //remove expected delivery date from basket context after submit order
          basketDispatch({
            type: ActionType.SetExpectedDeliveryDate
          })

          let basketDetails: UpdateBasketDetailsDto = defaultBasketDetails
          if (userRole && userDetails)
            basketDetails = returnDefaultBasketValues(userRole, userDetails)
          basketDispatch({
            type: ActionType.UpdateBasketDetails,
            basketDetails: basketDetails
          })

          // Trigger survey and pass through user and order numbers
          showWootricSurvey(
            user,
            response.data.checkedOutOrders.map((o) => o.orderNumber).join(','),
            'GA'
          )
        })
        .catch((error) => {
          const basketError = handleBasketError(error, dispatch)
          !basketError &&
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `An error occurred submitting your order. ${error}`
              )
            )
        })
    }
  }

  const handleSendEmail = () => {
    window.location.href = 'mailto:pv@linkhealthcare.co'
  }

  /**
   * When addresses are loaded set them and select the default shipping address
   */
  const isOptionsLoaded = useRef(false) // Make sure this only happens once

  useEffect(() => {
    if (
      !isOptionsLoaded.current &&
      !institute.isLoading &&
      institute?.data &&
      defaultShippingAddress
    ) {
      const addresses = institute.data.shipTo.map((address) => address)
      const savedAddress = localStorage.getItem(localStorageAddressKey)

      if (savedAddress) {
        try {
          const parsedAddress = JSON.parse(savedAddress)
          const addressExists = addresses.some(
            (addr) =>
              addr.address1 === parsedAddress.address1 &&
              addr.city === parsedAddress.city &&
              addr.state === parsedAddress.state &&
              addr.postalCode === parsedAddress.postalCode
          )

          if (!addressExists) {
            addresses.unshift(parsedAddress)
          }
        } catch (e) {
          console.error('Error parsing saved address:', e)
        }
      }

      setDeliveryAddresses(addresses)
      setDeliveryOptions({
        ...deliveryOptions,
        shippingAddressId: savedAddress
          ? JSON.parse(savedAddress).addressId
          : defaultShippingAddress.addressId
      })

      isOptionsLoaded.current = true
    }
  }, [institute, defaultShippingAddress])

  const initOptions = async () => {
    await getCurrentBasket().then((response) => {
      if (response?.data.state === BasketStatus.CheckingOut.toString()) {
        cancelDraftOrder()
      }
    })

    const defaultOptions: UpdateBasketDetailsDto = {
      ...defaultBasketDetails,
      shippingAddressId: defaultShippingAddress?.addressId,
      currencyCode: portfolioCountryCode || 'GBP',
      deliverToContact: userDetails?.contactCard?.name || '',
      recipientEmail: userDetails?.contactCard?.email || '',
      recipientPhoneNumber: getUserPhone(userDetails?.contactCard) || '',
      poNumber: 'PO'
    }

    setDeliveryOptions(defaultOptions)

    await placeDraftOrder(
      defaultOptions.currencyCode,
      defaultOptions.deliverToContact.trim(),
      defaultOptions.poNumber?.trim() || '',
      defaultOptions.shippingAddressId?.toString(),
      undefined,
      defaultOptions.recipientEmail?.trim() ?? '',
      defaultOptions.recipientPhoneNumber?.trim() ?? ''
    ).then((response) => {
      setDraftOrderId(response.data.basket.checkoutStamp)
      setOrderId(response.data.createdOrders[0].orderNumber)
      setOrder(response.data.createdOrders[0])
      setAllOrders(response.data.createdOrders)
      setIsInitialized(true)
      return response.data.basket.checkoutStamp
    })
  }
  const errorOrderMessage = 'An error occurred creating your order'
  const initializeDeliveryOptions = useCallback(async () => {
    if (isInitialized || initializationInProgress.current) {
      dispatch(createAnnounceEvent(AnnounceMode.Error, errorOrderMessage))
      return
    }

    if (!userDetails || !defaultShippingAddress) {
      dispatch(createAnnounceEvent(AnnounceMode.Error, errorOrderMessage))
      return
    }

    try {
      initializationInProgress.current = true
      if (!basket.isLoading && basket.data?.items?.length) {
        await initOptions()
      }
    } catch (error) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Error,
          `An error occurred creating your order. ${error}`
        )
      )
    } finally {
      initializationInProgress.current = false
    }
  }, [
    isInitialized,
    userDetails,
    defaultShippingAddress,
    portfolioCountryCode,
    basket.isLoading,
    basket?.data?.items,
    basket?.data?.state
  ])

  useEffect(() => {
    if (!isInitialized && !initializationInProgress.current) {
      initializeDeliveryOptions()
    }
  }, [isInitialized, initializeDeliveryOptions])

  const handleNewShippingAddress = (data: CreateShippingAddressDto) => {
    if (data && data.address) {
      setIsSubmittingAddress(true)
      const newAddress: CreateCdhShippingAddressDto = {
        address: {
          ...data.address,
          countryCode: data.address.country
        }
      }
      newUnvalidatedShippingAddress(newAddress)
        .then((response) => {
          if (response?.data) {
            const newAddress = response?.data
            if (newAddress) {
              const newDeliveryAddresses: OrderAddressDto[] =
                deliveryAddresses.slice()
              newDeliveryAddresses.unshift(newAddress)
              setDeliveryAddresses(newDeliveryAddresses)
              setNewAddress(newAddress)
              setDeliveryOptions({
                ...deliveryOptions,
                shippingAddressId: newAddress.addressId
              })
              saveAddressToLocalStorage(newAddress)
            }
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.CreateDeliveryAddress,
              {}
            )
            setIsNewShippingAddressOpen(false)
            setAddressModalError('')
            setResetForm(true)

            const addressScroll = document.getElementById('scrollView')
            addressScroll?.scrollTo({
              behavior: 'smooth',
              top: 0
            })
          }
        })
        .catch((error: AxiosError) => {
          const { code, message } = error
          setResetForm(false)
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          // If we have a full error show it
          if (error.response && error.response.data) {
            setAddressModalError(
              getAddressResponseErrorMessage(
                error.response.data.dependency.response
              )
            )
          } else {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `${t(
                  'request_new_shipping_address:submitted_fail_message'
                )} ${message} ${code ?? ''}`
              )
            )
          }
        })
        .finally(() => {
          setIsSubmittingAddress(false)
        })
    }
  }

  const validateDeliveryDetails = (details: {
    recipientName: string
    recipientPhone: string
    deliveryNote?: string
  }): FieldErrors => {
    const errors: FieldErrors = {}

    // Validate name
    if (!details.recipientName || details.recipientName.trim() === '') {
      errors.nameError = getInputValidation(ValidationType.RequiredField)
    }

    // Validate phone
    if (!details.recipientPhone || details.recipientPhone.trim() === '') {
      errors.phoneError = getInputValidation(ValidationType.RequiredField)
    } else {
      // Validate phone format
      const phoneRegex = /^\+?[\d\s-()]{10,}$/
      if (!phoneRegex.test(details.recipientPhone.trim())) {
        errors.phoneError = 'Please enter a valid phone number'
      }
    }

    return errors
  }

  const handleSaveDeliveryDetails = (details: {
    recipientName: string
    recipientEmail: string
    recipientPhone: string
    deliveryNote?: string
  }) => {
    const validationErrors = validateDeliveryDetails(details)
    setFieldErrors(validationErrors)

    if (Object.keys(validationErrors).length === 0) {
      setDeliveryOptions({
        ...deliveryOptions,
        deliverToContact: details.recipientName,
        recipientEmail: details.recipientEmail,
        recipientPhoneNumber: details.recipientPhone
      })
      return true
    }
    return false
  }

  return (
    <>
      {isRefreshingBasket ||
      viewMode === CheckoutViewMode.Loading ||
      isInitialLoad ||
      !isInitialized ? (
        <Row align="center">
          <Col>
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      ) : (
        <>
          <CreateAddressModal
            isOpen={isNewShippingAddressOpen}
            handleFormSubmit={handleNewShippingAddress}
            handleClose={() => {
              setIsNewShippingAddressOpen(false)
            }}
            isSubmitting={isSubmittingAddress}
            resetOnSubmit={resetForm}
            countries={countries}
            selectedCountry={portfolioCountryCode}
            errorMessage={addressModalError}
          />

          <Checkout
            viewMode={viewMode}
            basket={viewBasket}
            order={order}
            allOrders={allOrders}
            customerPoNumber={
              deliveryOptions.poNumber ? deliveryOptions.poNumber : ''
            }
            deliverToContact={
              deliveryOptions.deliverToContact
                ? deliveryOptions.deliverToContact
                : ''
            }
            errorMessage={hasErrorMessage}
            selectedAddressId={
              deliveryOptions.shippingAddressId
                ? deliveryOptions.shippingAddressId
                : ''
            }
            estimatedDeliveryDate={estimatedDeliveryDate}
            cutOffTime={cutOffTime}
            deliveryAddresses={deliveryAddresses}
            recipientPhoneNumber={deliveryOptions.recipientPhoneNumber ?? ''}
            recipientEmail={deliveryOptions.recipientEmail ?? ''}
            handleChangeAddress={(selectedAddressId) =>
              setDeliveryOptions({
                ...deliveryOptions,
                shippingAddressId: selectedAddressId
              })
            }
            handleBackToBasket={handleBackToBasket}
            handlePoChange={(value) => {
              setDeliveryOptions({ ...deliveryOptions, poNumber: value })
            }}
            handleRecipientsNameChange={(value) =>
              setDeliveryOptions({
                ...deliveryOptions,
                deliverToContact: value
              })
            }
            handleRecipientEmailChange={(value) => {
              setDeliveryOptions({ ...deliveryOptions, recipientEmail: value })
            }}
            handleRecipientPhoneChange={(value) => {
              setDeliveryOptions({
                ...deliveryOptions,
                recipientPhoneNumber: value
              })
            }}
            handleNewShippingAddress={() => setIsNewShippingAddressOpen(true)}
            handleOrderSubmit={handlePlaceOrderSubmit}
            handleSendEmail={handleSendEmail}
            handleSaveDeliveryDetails={handleSaveDeliveryDetails}
            fieldErrors={fieldErrors}
            deliveryNote={deliveryNote}
            handleDeliveryNotesChange={setDeliveryNote}
          />
        </>
      )}
    </>
  )
}
