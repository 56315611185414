import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import {
  StyledModalLightbox,
  StyledModal,
  StyledModalWrapper,
  StyledModalContent,
  StyledModalHeader,
  StyledModalBody
} from './ClinModal.styles'
import { ClinTheme } from '../../ClinTheme'
import { IDefaultStyleProps } from '../../types'

export interface IModalStyles {
  /** Css max-width accepts specific values that relate to the themes breakpoints */
  maxWidth?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'none'
  /** Css height value */
  height?: string | number
  /** z-index css value */
  zIndex?: number
  /** remove padding for full width */
  removePadding?: boolean
  boxShadow?: string
  borderRadius?: string
}

interface THandleOpen {
  /** function to open modal */
  handleOpen(): void
}

interface THandleClose {
  /** function to close modal */
  handleClose?(): void
}

interface IModalProps extends IDefaultStyleProps, IModalStyles {
  /** Control internal state of modal */
  isOpen?: boolean
  /** Determines if the modal can be manually closed (whether it is blocking or not) */
  isDismissible?: boolean
  /** Optional parameter for padding */
  padding?: string
  /** Remove padding on small screens*/
  remmoveSmallPadding?: boolean
  boxShadow?: string
  borderRadius?: string
  /** Callback function that takes nothing and returns nothing */
  onClose?: () => void
  /** handleOpen arg & ReactNode to act as trigger */
  trigger?(props: THandleOpen): React.ReactNode
  /** handleClose arg & ReactNode to display in header */
  header?(props: THandleClose): React.ReactNode
}

export const ClinModal: React.FC<IModalProps> = ({
  className = '',
  children,
  height,
  maxWidth = 'xs',
  zIndex = ClinTheme.zIndices.modal,
  isOpen,
  isDismissible = true,
  trigger,
  header,
  onClose,
  removePadding,
  remmoveSmallPadding,
  padding,
  boxShadow,
  borderRadius
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [prevIsModalOpen, setPrevIsModalOpen] = useState(null)

  if (isOpen !== prevIsModalOpen) {
    setIsModalOpen(isOpen as any)
    setPrevIsModalOpen(isOpen as any)
  }

  const handleOpen = () => {
    setIsModalOpen(true)
  }

  const handleClose = () => {
    setIsModalOpen(false)
    onClose && onClose()
  }

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : 'auto'
  }, [isModalOpen])

  // Clean up modal body styles when unmounted
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <>
      {trigger &&
        trigger({
          handleOpen: () => handleOpen()
        })}

      {isModalOpen &&
        createPortal(
          <StyledModal className={`clin-modal ${className}`} zIndex={zIndex}>
            <StyledModalLightbox
              onClick={() => {
                isDismissible && handleClose()
              }}
            />
            <StyledModalWrapper>
              <StyledModalContent
                className={'clin-modal--modal-content'}
                maxWidth={maxWidth}
                height={height}
                boxShadow={boxShadow}
                borderRadius={borderRadius}
              >
                {header && (
                  <StyledModalHeader removePadding={Boolean(removePadding)}>
                    {header({
                      handleClose: () => isDismissible && handleClose()
                    })}
                  </StyledModalHeader>
                )}
                <StyledModalBody
                  padding={padding}
                  hasHeader={Boolean(header)}
                  removePadding={Boolean(removePadding)}
                  removeSmallPadding={remmoveSmallPadding}
                >
                  {children}
                </StyledModalBody>
              </StyledModalContent>
            </StyledModalWrapper>
          </StyledModal>,
          document.body
        )}
    </>
  )
}
