import styled from 'styled-components'

import { ITextInputStyles } from './ClinTextInput'
import { ClinTheme } from '../../ClinTheme'
import { mediaQueryDown } from '../../utils/mediaQuery'

export const StyledInputWrapper = styled.div({
  position: 'relative',
  display: 'inline-block',
  width: '100%'
})

const getBoxShadow = (
  isValid?: boolean,
  hasError?: boolean,
  hasFocus?: boolean
) => {
  let boxShadowColor

  if (hasError) {
    boxShadowColor = ClinTheme.colors.redInvalid
  } else if (isValid) {
    boxShadowColor = ClinTheme.colors.greenValid
  } else if (hasFocus) {
    boxShadowColor = ClinTheme.colors.primaryMid
  } else {
    boxShadowColor = 'none'
  }

  return hasFocus ? `0px 0px 3px 0px ${boxShadowColor}` : boxShadowColor
}

export const StyledClinTextInput = styled.input<ITextInputStyles>(
  {
    fontFamily: ClinTheme.fonts.body,
    fontSize: ClinTheme.fontSizes[2],
    fontWeight: ClinTheme.fontWeights.normal,
    lineHeight: `${ClinTheme.fontSizes[4] - 2}px`, // subtract top and bottom border 1px widths
    letterSpacing: ClinTheme.letterSpacing[0],
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
    borderRadius: ClinTheme.radii[2],
    border: `solid 1px ${ClinTheme.colors.grey}`,
    transition: '.1s linear box-shadow',
    boxSizing: 'border-box',
    [`:focus`]: {
      outline: 'none',
      border: `solid 1px ${ClinTheme.colors.primaryMid}`,
      boxShadow: getBoxShadow(false, false, true)
    },
    ':-ms-input-placeholder': {
      // Special case for Text Inputs to have correct height in IE 11
      height: '40px'
    },
    ':disabled': {
      cursor: 'not-allowed'
    }
  },
  ({
    isValid,
    hasError,
    disabled,
    placeholderColor,
    width = '100%',
    color = ClinTheme.colors.black
  }: ITextInputStyles) => ({
    color: !disabled ? color : ClinTheme.colors.darkGrey,
    borderColor: hasError
      ? ClinTheme.colors.redInvalid
      : isValid
      ? ClinTheme.colors.greenValid
      : ClinTheme.colors.grey,
    boxShadow: getBoxShadow(isValid, hasError),
    width,
    backgroundColor: disabled
      ? ClinTheme.colors.lightGrey
      : ClinTheme.colors.white,
    '::placeholder': {
      color: placeholderColor ?? 'inherit'
    }
  })
)

export const StyledInputContainer = styled.div`
  display: block;
  width: 100%;
`

export const StyledPrompt = styled.div`
  color: ${ClinTheme.colors.redInvalid};
  font-size: ${ClinTheme.fontSizes[1]}px;
  margin-top: ${ClinTheme.space[2]}px;
`

export const PhoneInputWrapper = styled.div<{
  hasError?: boolean
  isValid?: boolean
  disabled?: boolean
}>`
  .react-tel-input {
    position: relative;
    /* &:focus {
      .flag-dropdown {
        border-right: none;
        border-color: ${ClinTheme.colors.primaryMid};
        box-shadow: -7px -2px 12px -5px ${ClinTheme.colors.primaryMid};
      }
    } */
    .special-label {
      font-family: ${ClinTheme.fonts.body};
      font-size: ${ClinTheme.fontSizes[2]}px;
      margin-bottom: ${ClinTheme.space[2]}px;
      color: ${ClinTheme.colors.black};
    }

    .form-control {
      width: 100%;
      height: 40px;
      border-radius: ${ClinTheme.radii[2]}px;
      font-family: ${ClinTheme.fonts.body};
      font-size: ${ClinTheme.fontSizes[2]}px;
      font-weight: ${ClinTheme.fontWeights.normal};
      line-height: ${ClinTheme.fontSizes[4] - 2}px;
      letter-spacing: ${ClinTheme.letterSpacing[0]};
      padding: ${ClinTheme.space[2]}px ${ClinTheme.space[3]}px;
      padding-left: 48px;
      color: ${({ disabled }) =>
        !disabled ? ClinTheme.colors.black : ClinTheme.colors.darkGrey};
      background-color: ${({ disabled }) =>
        disabled ? ClinTheme.colors.lightGrey : ClinTheme.colors.white};
      border: solid 1px
        ${({ hasError, isValid }) =>
          hasError
            ? ClinTheme.colors.redInvalid
            : isValid
            ? ClinTheme.colors.greenValid
            : ClinTheme.colors.grey};
      box-shadow: ${({ hasError, isValid }) =>
        hasError
          ? `0px 0px 3px 0px ${ClinTheme.colors.redInvalid}`
          : isValid
          ? `0px 0px 3px 0px ${ClinTheme.colors.greenValid}`
          : 'none'};
      transition: 0.1s linear box-shadow;

      &:focus {
        box-shadow: 0px 0px 3px 0px ${ClinTheme.colors.primaryMid};
        border-color: ${ClinTheme.colors.primaryMid};
        outline: none;
      }

      &::placeholder {
        color: ${ClinTheme.colors.darkGrey};
      }

      &:disabled {
        cursor: not-allowed;
      }

      &:-ms-input-placeholder {
        height: 40px;
      }
    }
    .form-control:focus ~ .flag-dropdown {
      border-right: none;
      border-color: ${ClinTheme.colors.primaryMid};
      box-shadow: -7px -2px 12px -12px ${ClinTheme.colors.primaryMid};
    }

    .flag-dropdown {
      outline: none;
      position: absolute;
      top: 0;
      bottom: 0;
      padding: 0;
      background-color: ${({ disabled }) =>
        disabled ? ClinTheme.colors.lightGrey : ClinTheme.colors.white};
      border: solid 1px
        ${({ hasError, isValid }) =>
          hasError
            ? ClinTheme.colors.redInvalid
            : isValid
            ? ClinTheme.colors.greenValid
            : ClinTheme.colors.grey};
      border-right: none;
      border-radius: ${ClinTheme.radii[2]}px 0 0 ${ClinTheme.radii[2]}px;
      transition: 0.1s linear border-color;

      .selected-flag {
        position: relative;
        width: 38px;
        height: 100%;
        padding: 0 0 0 8px;
        display: flex;
        align-items: center;
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

        .flag {
          display: flex;
          align-items: center;
          opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
        }

        .arrow {
          position: relative;
          width: 0;
          top: 20%;
          height: 0;
          left: 25px;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-top: 4px solid ${ClinTheme.colors.darkGrey};
          margin-left: 4px;
          opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
        }
        .arrow.up {
          border-top: none;
        }
      }
    }
  }
`

const mobileUpBreakpoint = ClinTheme.breakpoints[1]
export const StyledLabel = styled.label<ITextInputStyles>(
  {
    display: 'block',
    [`:focus`]: {
      outline: 'none'
    }
  },
  ({
    width,
    labelFontSize = ClinTheme.fontSizes[2],
    labelLineHeight = ClinTheme.lineHeights.body,
    labelFontWeight = ClinTheme.fontWeights.normal
  }: ITextInputStyles) => ({
    width,
    fontSize: labelFontSize,
    lineHeight: labelLineHeight,
    fontWeight: labelFontWeight
  }),
  ({ marginBottom }: ITextInputStyles) => ({
    [mediaQueryDown(mobileUpBreakpoint)]: {
      marginBottom: marginBottom ?? 0
    }
  })
)
