import { IHoldDetails } from '../../services/Holds/holds.types'
import { UIVariationState } from '../../services/PortfolioJourneys/PortfolioJourney.types'
import {
  BasketDto,
  BasketItemDto,
  PriceAndAvailabilityDto
} from '../../types/swaggerTypes'

export enum BasketStatus {
  Default = 'Default', // for FE only
  Open = 'Open', // read / write
  CheckingOut = 'CheckingOut', // read only - created orders but haven't hit purchase (order exists in EBS but hasn't been booked yet  - API call to abandon checkout)
  Abandoned = 'Abandoned', // read only
  Placing = 'Placing', // for FE only
  Submitted = 'Submitted', // for FE only
  Closed = 'Closed' // for FE only
}

export interface IViewBasketItem extends BasketItemDto {
  amount: number
  priceAndAvailability: PriceAndAvailabilityDto | undefined
  /** Defined by Portfolio Journey if this SKU is Globally Unavailable */
  unavailable?: boolean
  /** UI variation state as defined by Portfolio Journey */
  uiVariantState?: UIVariationState
  /** Basket item hold details */
  holds?: IHoldDetails[]
}

export interface IViewBasket extends BasketDto {
  total: number
  items: IViewBasketItem[]
  state: BasketStatus
  currencyCode?: string // TODO: Work out the requirements for this?
}
