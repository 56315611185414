import { useCallback, useEffect, useState } from 'react'

import { ClinTheme } from '../../ClinTheme'
import { useWindowSize } from '../../utils/useWindowSize'

export const useDropdown = (
  ddRef: React.RefObject<HTMLDivElement | undefined>,
  isOpen: boolean = false,
  showHover?: boolean,
  onOpen?: (isDropdownOpen: boolean) => void,
  isDisabled?: boolean
) => {
  const dropdownRef = ddRef.current
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(isOpen)
  const { width } = useWindowSize()
  const isDesktop = width && width > ClinTheme.breakpoints[3]

  const handleClickedOutside = useCallback(
    (event) => {
      const targetDropdownEl =
        dropdownRef &&
        (event.target === dropdownRef || dropdownRef.contains(event.target))

      if (!targetDropdownEl && isDropdownOpen) {
        setIsDropdownOpen(false)
      }
    },
    [dropdownRef, isDropdownOpen]
  )

  const handleEscKey = useCallback(
    (event) => {
      if (event.keyCode === 27 && isDropdownOpen) {
        setIsDropdownOpen(false)
      }
      if (event.keyCode === 13 && isDropdownOpen) {
        setIsDropdownOpen(false)
      }
    },
    [isDropdownOpen]
  )

  useEffect(() => {
    if (dropdownRef && !isDisabled) {
      const onMouseOver = () => setIsDropdownOpen(true)
      const onMouseOut = () => setIsDropdownOpen(false)
      const onClick = () => setIsDropdownOpen(!isDropdownOpen)
      const onkeydown = () => setIsDropdownOpen(!isDropdownOpen)
      isDesktop &&
        showHover &&
        dropdownRef.addEventListener('mouseover', onMouseOver)
      isDesktop &&
        showHover &&
        dropdownRef.addEventListener('mouseout', onMouseOut)
      dropdownRef.addEventListener('click', onClick)
      dropdownRef.addEventListener('keydown', handleEscKey)
      document.addEventListener('click', handleClickedOutside)
      return () => {
        isDesktop &&
          showHover &&
          dropdownRef.removeEventListener('mouseover', onMouseOver)
        isDesktop &&
          showHover &&
          dropdownRef.removeEventListener('mouseout', onMouseOut)
        dropdownRef.removeEventListener('click', onClick)
        dropdownRef.removeEventListener('keydown', onkeydown)
      }
    }
  }, [
    ddRef,
    dropdownRef,
    handleClickedOutside,
    handleEscKey,
    isDesktop,
    isDropdownOpen,
    showHover,
    isOpen,
    onOpen,
    isDisabled
  ])

  useEffect(() => {
    onOpen && onOpen(isDropdownOpen)
  }, [isDropdownOpen, onOpen])

  return [isDropdownOpen as boolean]
}
