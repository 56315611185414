import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../ClinIcon'
import {
  StyledClinSingleBox,
  IClinSingleBoxStyles
} from './ClinSingleBox.styles'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinText } from '../ClinText'
import { TypographyVariant } from '../ClinText/ClinText.styles'

interface IClinSingleBoxProps extends IClinSingleBoxStyles {
  title: string
  estimate?: string
  icon: ClinIconPathName
  description: string
  backgroundColor: string
  isMaOrder?: boolean
  viewBox?: string
  optionalURL?: string
}

export const ClinSingleBox: FunctionComponent<IClinSingleBoxProps> = (
  props
) => {
  let substituteUrl: string = '/orders/'
  if (props.optionalURL) substituteUrl = `/order/${props.optionalURL}`
  return (
    <StyledClinSingleBox {...props}>
      <ClinIcon
        iconName={props.icon}
        iconFill={props.backgroundColor}
        iconSize="65px"
        viewBox={props.viewBox ? props.viewBox : '0 0 24 24'}
      ></ClinIcon>
      <ClinText
        textAlign="center"
        marginBottom="20px"
        marginTop="20px"
        variant={TypographyVariant.H5}
        color={props.backgroundColor}
      >
        {props.title}
      </ClinText>
      <ClinText
        variant={TypographyVariant.Paragraph}
        textAlign="center"
        marginTop="-20px"
        fontWeight={ClinTheme.fontWeights.medium}
      >
        {props.estimate}
      </ClinText>
      <ClinText variant={TypographyVariant.LargeParagraph} textAlign="center">
        <Trans
          i18nKey={props.description}
          components={{
            a: <a aria-label="Text content link" href={substituteUrl}></a>
          }}
          values={{
            link: substituteUrl
          }}
          shouldUnescape={true}
        ></Trans>
      </ClinText>
    </StyledClinSingleBox>
  )
}
