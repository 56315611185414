import * as React from 'react'

import { StyledButtonIcon } from './ClinButtonIcon.styles'
import { IDefaultStyleProps } from '../../types'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'

export interface IButtonIconStyles {
  /** Css display value */
  display?: 'flex' | 'inline-flex'
  /** StyledComponents polymorphic as prop */
  as?: 'div' | 'span'
  /** Button type */
  type?: 'button' | 'submit' | 'reset'
  /** The background color on hover */
  backgroundHover?: string
  /** Button color on hover */
  hoverColor?: string
}

export interface IButtonIconProps
  extends IDefaultStyleProps,
    IButtonIconStyles,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** The name of icon from paths */
  iconName: ClinIconPathName
  /** The icon fill */
  iconFill?: 'initial' | 'inherit' | 'currentcolor' | string
  /**  The icon size */
  iconSize?: string | number
}

export const ClinButtonIcon: React.FC<IButtonIconProps> = ({
  className = '',
  iconName,
  iconFill = 'currentcolor',
  iconSize,
  display = 'inline-flex',
  as,
  type = 'button',
  backgroundHover,
  ...rest
}) => (
  <StyledButtonIcon
    {...rest}
    as={as}
    type={type}
    className={`clin-button-icon ${className}`}
    aria-label={iconName}
    display={display}
    backgroundHover={backgroundHover}
    tabIndex={rest.disabled ? -1 : rest.tabIndex ?? 0}
  >
    <ClinIcon iconName={iconName} iconFill={iconFill} iconSize={iconSize} />
  </StyledButtonIcon>
)
