import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'

import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../ClinIcon'
import {
  StyledClinTwoColumnCard,
  StyledLeftContainer,
  StyledRightContainer,
  StyledSelectContainer,
  StyledCircle
} from './ClinTwoColumnCard.styles'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinText } from '../ClinText'
import { TypographyVariant } from '../ClinText/ClinText.styles'

interface IClinTwoColumnCardProps {
  /** CTA that leads to viewing details */
  cta: string
  /** Called when user clicks on the card */
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  /** Content in the left column */
  left: React.ReactNode
  /** Content in the right column */
  right: React.ReactNode
  /** Whether to show an overlay saying the item has been deleted */
  isDeleted?: boolean
}

export const ClinTwoColumnCard: FunctionComponent<IClinTwoColumnCardProps> = ({
  onClick,
  left,
  right,
  cta,
  isDeleted
}) => {
  return (
    <StyledClinTwoColumnCard
      removePadding={true}
      isSelectable={true}
      marginBottom={ClinTheme.space[3]}
      onClick={(event) => onClick && onClick(event)}
      isDeleted={isDeleted}
    >
      <Row nogutter={true}>
        <Col xs={12} md={9}>
          <StyledLeftContainer>{left}</StyledLeftContainer>
        </Col>
        <Col xs={12} md={3} style={{ display: 'flex' }}>
          <StyledRightContainer>
            {right}
            <StyledSelectContainer>
              <ClinText
                as="div"
                variant={TypographyVariant.Paragraph}
                fontWeight={ClinTheme.fontWeights.medium}
                textTransform="uppercase"
                color={ClinTheme.colors.primaryLight}
              >
                {cta}
                <StyledCircle>
                  <ClinIcon
                    iconName={ClinIconPathName.ArrowRight}
                    iconFill={ClinTheme.colors.white}
                  />
                </StyledCircle>
              </ClinText>
            </StyledSelectContainer>
          </StyledRightContainer>
        </Col>
      </Row>
    </StyledClinTwoColumnCard>
  )
}
