import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledLearnMoreButton,
  StyledRealWorldDataCard,
  StyledRealWorldDataCardContent,
  StyledOverlayText
} from './RealWorldDataCardProgramDetails.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'

const RealWorldDataCardProgramDetails = () => {
  const { t } = useTranslation()
  const buttonId = 'learn-more-rwd-button'

  const handleLearnMoreClick = () => {
    document.getElementById(buttonId)?.blur()
    window.open(`/programs/access-programs/real-world-data`, '_blank')
  }

  return (
    <StyledRealWorldDataCard>
      <div style={{ position: 'relative', width: '100%' }}>
        <img src="/images/rwd-bg.jpg" alt="real world data background" />
        <StyledOverlayText>
          <ClinText>{t('access_programs:rwd_button_titile')}</ClinText>
        </StyledOverlayText>
      </div>
      <StyledRealWorldDataCardContent>
        <ClinText
          fontSize={ClinTheme.fontSizes[2]}
          fontWeight={ClinTheme.fontWeights.bold}
          textAlign="center"
        >
          {t('access_programs:card_title')}
        </ClinText>
        <ClinSpacer height={ClinTheme.space[3]} />
        <ClinText
          color={ClinTheme.colors.darkGrey}
          fontSize={ClinTheme.fontSizes[0]}
          fontWeight={ClinTheme.fontWeights.medium}
          textTransform="uppercase"
        >
          {t('access_programs:time_to_read')}
        </ClinText>
        <ClinSpacer height={ClinTheme.space[3]} />
        <StyledLearnMoreButton id={buttonId} onClick={handleLearnMoreClick}>
          {t('access_programs:learn_more')}
        </StyledLearnMoreButton>
        <ClinSpacer height={ClinTheme.space[3]} />
      </StyledRealWorldDataCardContent>
    </StyledRealWorldDataCard>
  )
}

export default RealWorldDataCardProgramDetails
