import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ITypographyStyles } from '../../types'
import { mediaQuery } from '../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[0]
const tabletUpBreakpoint = ClinTheme.breakpoints[1]

export enum TypographyVariant {
  SmallUI,
  Paragraph,
  LargeParagraph,
  LargerParagraph,
  H5,
  H4,
  H3,
  H2,
  H1
}

export enum AsValues {
  'small',
  'p',
  'div',
  'span',
  'sub',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6'
}
export interface ITextStyles extends ITypographyStyles {
  /** Clinigen specific elements */
  variant?: TypographyVariant
  /** Text inverted on dark background */
  inverted?: boolean
  /** StyledComponents polymorphic as prop */
  as?: keyof typeof AsValues
  width?: string
  overflow?: string
  textOverflow?: string
}

interface IFontStyle {
  fontSize: number
  fontWeight: number
  lineHeight: number
  marginTop?: number
  marginBottom?: number
  color: string
}

const getFontStyleFor = (variant?: TypographyVariant): IFontStyle => {
  const defaultFontStyle: IFontStyle = {
    fontSize: ClinTheme.fontSizes[1],
    fontWeight: ClinTheme.fontWeights.normal,
    lineHeight: ClinTheme.lineHeights.body,
    marginTop: ClinTheme.space[0],
    marginBottom: ClinTheme.space[0],
    color: ClinTheme.colors.black
  }

  // Mobile first
  switch (variant) {
    case TypographyVariant.H1:
      defaultFontStyle.fontSize = ClinTheme.fontSizes[6]
      defaultFontStyle.fontWeight = ClinTheme.fontWeights.normal
      defaultFontStyle.lineHeight = ClinTheme.lineHeights.heading[4]
      defaultFontStyle.marginTop = ClinTheme.space[4]
      defaultFontStyle.marginBottom = ClinTheme.space[4]
      defaultFontStyle.color = ClinTheme.colors.primary
      break
    case TypographyVariant.H2:
      defaultFontStyle.fontSize = ClinTheme.fontSizes[5]
      defaultFontStyle.fontWeight = ClinTheme.fontWeights.normal
      defaultFontStyle.lineHeight = ClinTheme.lineHeights.heading[3]
      defaultFontStyle.marginTop = ClinTheme.space[4]
      defaultFontStyle.marginBottom = ClinTheme.space[4]
      defaultFontStyle.color = ClinTheme.colors.primary
      break
    case TypographyVariant.H3:
      defaultFontStyle.fontSize = ClinTheme.fontSizes[5]
      defaultFontStyle.lineHeight = ClinTheme.lineHeights.heading[2]
      defaultFontStyle.marginTop = ClinTheme.space[3]
      defaultFontStyle.marginBottom = ClinTheme.space[3]
      defaultFontStyle.color = ClinTheme.colors.primary
      break
    case TypographyVariant.H4:
      defaultFontStyle.fontSize = ClinTheme.fontSizes[4]
      defaultFontStyle.lineHeight = ClinTheme.lineHeights.heading[1]
      defaultFontStyle.marginTop = ClinTheme.space[3]
      defaultFontStyle.marginBottom = ClinTheme.space[3]
      defaultFontStyle.color = ClinTheme.colors.primary
      break
    case TypographyVariant.H5:
      defaultFontStyle.fontSize = ClinTheme.fontSizes[1]
      defaultFontStyle.fontWeight = ClinTheme.fontWeights.medium
      defaultFontStyle.lineHeight = ClinTheme.lineHeights.body
      defaultFontStyle.marginTop = ClinTheme.space[2]
      defaultFontStyle.marginBottom = ClinTheme.space[2]
      defaultFontStyle.color = ClinTheme.colors.primary
      break
    case TypographyVariant.LargeParagraph:
      defaultFontStyle.fontSize = ClinTheme.fontSizes[2]
      defaultFontStyle.lineHeight = ClinTheme.lineHeights.largeParagraph
      defaultFontStyle.marginTop = ClinTheme.space[2]
      defaultFontStyle.marginBottom = ClinTheme.space[2]
      break
    case TypographyVariant.LargerParagraph:
      defaultFontStyle.fontSize = ClinTheme.fontSizes[3]
      defaultFontStyle.lineHeight = ClinTheme.lineHeights.largeParagraph
      defaultFontStyle.marginTop = ClinTheme.space[2]
      defaultFontStyle.marginBottom = ClinTheme.space[2]
      break
    case TypographyVariant.Paragraph:
      defaultFontStyle.fontSize = ClinTheme.fontSizes[1]
      defaultFontStyle.lineHeight = ClinTheme.lineHeights.body
      defaultFontStyle.marginTop = ClinTheme.space[2]
      defaultFontStyle.marginBottom = ClinTheme.space[2]
      break
    case TypographyVariant.SmallUI:
      defaultFontStyle.fontSize = ClinTheme.fontSizes[0]
      defaultFontStyle.fontWeight = ClinTheme.fontWeights.medium
      defaultFontStyle.lineHeight = ClinTheme.lineHeights.small
      defaultFontStyle.marginTop = ClinTheme.space[2]
      defaultFontStyle.marginBottom = ClinTheme.space[2]
      break
  }
  return defaultFontStyle
}

const getResponsiveFontSizeFor = (
  breakpoint: 'tablet' | 'desktop',
  variant?: TypographyVariant
): number | string => {
  let fontSize: number | string = 'inherit'
  switch (variant) {
    case TypographyVariant.H1:
      fontSize =
        breakpoint === 'tablet'
          ? ClinTheme.fontSizes[6]
          : ClinTheme.fontSizes[7]
      break
    case TypographyVariant.H2:
      fontSize =
        breakpoint === 'tablet' ? ClinTheme.space[5] : ClinTheme.space[6]
      break
    case TypographyVariant.H3:
      fontSize = ClinTheme.fontSizes[5]
      break
    case TypographyVariant.H4:
      fontSize = ClinTheme.fontSizes[4]
      break
    case TypographyVariant.H5:
      fontSize = ClinTheme.fontSizes[1]
      break
    case TypographyVariant.LargeParagraph:
      fontSize = ClinTheme.fontSizes[2]
      break
    case TypographyVariant.LargerParagraph:
      fontSize = ClinTheme.fontSizes[3]
      break
    case TypographyVariant.Paragraph:
      fontSize = ClinTheme.fontSizes[1]
      break
    case TypographyVariant.SmallUI:
      fontSize = ClinTheme.fontSizes[0]
      break
  }
  return fontSize
}

export const StyledClinText = styled.p<ITextStyles>(
  {
    fontFamily: ClinTheme.fonts.body
  },
  ({
    fontSize,
    fontWeight,
    lineHeight,
    color,
    textAlign,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    textTransform,
    display,
    variant,
    inverted,
    whiteSpace,
    wordBreak,
    width,
    overflow,
    textOverflow,
    letterSpacing
  }) => ({
    fontSize: fontSize ?? getFontStyleFor(variant).fontSize,
    fontWeight: fontWeight ?? getFontStyleFor(variant).fontWeight,
    lineHeight: lineHeight ?? getFontStyleFor(variant).lineHeight,
    color: inverted
      ? ClinTheme.colors.white
      : color ?? getFontStyleFor(variant).color,
    textAlign,
    width,
    overflow,
    textOverflow,
    marginTop: marginTop || (variant ? getFontStyleFor(variant).marginTop : 0),
    marginRight,
    marginBottom:
      marginBottom || (variant ? getFontStyleFor(variant).marginTop : 0),
    marginLeft,
    textTransform: textTransform ?? 'none',
    letterSpacing:
      letterSpacing ??
      (variant === TypographyVariant.H5
        ? 'normal'
        : ClinTheme.letterSpacing[1]),
    display,
    whiteSpace,
    wordBreak,
    [`a`]: {
      color: inverted ? ClinTheme.colors.white : ClinTheme.colors.primaryLight
    },
    [`a:hover, a:visited`]: {
      color: inverted ? ClinTheme.colors.white : ClinTheme.colors.primaryLight
    },
    [`a:active`]: {
      color: ClinTheme.colors.magenta
    },
    [mediaQuery(mobileUpBreakpoint)]: {
      fontSize: variant ? getResponsiveFontSizeFor('tablet', variant) : ''
    },
    [mediaQuery(tabletUpBreakpoint)]: {
      fontSize: variant ? getResponsiveFontSizeFor('desktop', variant) : ''
    }
  })
)
