import { EffectCallback, useEffect, useRef } from 'react'

/**
 * Custom hook that only runs an effect on component mount
 * Uses a ref to track if the component has mounted
 * @param effect Callback function to run on mount
 */
export const useOnMountWithSubsequent = (effect: EffectCallback) => {
  const mounted = useRef(false)

  useEffect(() => {
    // Only run effect if not already mounted
    if (!mounted.current) {
      mounted.current = true
      return effect()
    }
    // Reset mounted ref on unmount
    return () => {
      mounted.current = false
    }
  }, [effect])
}
