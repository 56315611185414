import React, { FunctionComponent } from 'react'
import { Row, Col, Container } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinModal } from '../../../components/ClinModal'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import {
  StyledActions,
  StyledSubmit
} from '../../../features/Prompt/Prompt.styles'

interface IOpaExitPreventionPromptModalProps {
  /** When the prompt should be enabled */
  isOpen?: boolean
  handleClose: () => void
  handleOnConfirm: () => void
}

export const OpaExitPreventionPromptModal: FunctionComponent<
  IOpaExitPreventionPromptModalProps
> = ({ handleClose, isOpen, handleOnConfirm }) => {
  const { t } = useTranslation()

  return (
    <ClinModal maxWidth="xs" height="auto" isOpen={isOpen}>
      <Container>
        <Row justify="center">
          <Col sm={12}>
            <Row>
              <Col sm={12} md={10}>
                <ClinText
                  className="prompt-title"
                  as="h4"
                  variant={TypographyVariant.H4}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  {t('common:prompt.title')}
                </ClinText>
                <ClinText
                  className="prompt-description"
                  variant={TypographyVariant.LargeParagraph}
                  marginBottom="0"
                >
                  {t('common:prompt.description')}
                </ClinText>
              </Col>
            </Row>
            <StyledSubmit>
              <Row justify="between">
                <Col md={5} lg={6}>
                  <StyledActions>
                    <ClinButton
                      className="cancel-page-change"
                      onClick={handleClose}
                    >
                      {t('common:buttons.cancel')}
                    </ClinButton>
                    <ClinButton
                      className="continue-page-change"
                      variant="primary"
                      onClick={handleOnConfirm}
                    >
                      {t('common:buttons.continue')}
                    </ClinButton>
                  </StyledActions>
                </Col>
              </Row>
            </StyledSubmit>
          </Col>
        </Row>
      </Container>
    </ClinModal>
  )
}
