import React, { FunctionComponent } from 'react'

import {
  StyledClinTableOrderToggle,
  StyledClinTableOrderToggleProps
} from './ClinTableOrderToggle.styles'
import { IDefaultStyleProps } from '../../types'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'

export enum SortDirectionType {
  Ascending = 'asc',
  Descending = 'desc',
  None = ''
}

export type TSortDirection =
  | SortDirectionType.Ascending
  | SortDirectionType.Descending
  | SortDirectionType.None
  | undefined

interface IClinTableOrderToggleProps
  extends IDefaultStyleProps,
    StyledClinTableOrderToggleProps {
  /** Determines which arrow icon to display  */
  sortDirection?: TSortDirection
  /** onClick function passes the event returns nothing */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const ClinTableOrderToggle: FunctionComponent<
  IClinTableOrderToggleProps
> = ({
  children,
  sortDirection = SortDirectionType.None,
  hideSortIcon,
  isUppercase,
  onClick
}) => {
  const iconConfig = {
    [SortDirectionType.Ascending]: ClinIconPathName.ChevronSmallUp,
    [SortDirectionType.Descending]: ClinIconPathName.ChevronSmallDown,
    [SortDirectionType.None]: ClinIconPathName.ChevronSmallUpDown
  }

  const getIconName = (
    sortDirectionStr: keyof typeof iconConfig
  ): ClinIconPathName => iconConfig[sortDirectionStr]

  return (
    <StyledClinTableOrderToggle
      hideSortIcon={hideSortIcon}
      onClick={(event) => !hideSortIcon && onClick && onClick(event)}
      isUppercase={isUppercase}
    >
      {children}
      {!hideSortIcon && <ClinIcon iconName={getIconName(sortDirection)} />}
    </StyledClinTableOrderToggle>
  )
}
