import React, { FunctionComponent } from 'react'

import { IDefaultStyleProps } from '../../types'
import { ClinText } from '../ClinText'
import {
  StyledLabel,
  StyledCheckbox,
  StyledTrack,
  StyledSlider,
  IToggleStyles
} from './ClinSwitch.styles'

interface IClinSwitchProps extends IDefaultStyleProps, IToggleStyles {
  /** HTML id attribute */
  id: string
  /** HTML name attribute */
  name?: string
  /** Sets the value of the ClinSwitch */
  value?: string
  /** HTML label attribute */
  label: string
  /** React checked attribute */
  defaultChecked?: boolean
  /**  Pass a ref to the `input` element */
  inputRef?: React.Ref<HTMLInputElement>
  /** Function that takes an event and returns nothing */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ClinSwitch: FunctionComponent<IClinSwitchProps> = ({
  className = '',
  name,
  label,
  id,
  value,
  display = 'flex',
  disabled,
  defaultChecked,
  onChange,
  flexDirection = 'row',
  switchColor,
  inputRef,
  size = 'normal',
  children
}) => {
  return (
    <StyledLabel
      title={label}
      htmlFor={id}
      display={display}
      disabled={disabled}
      flexDirection={flexDirection}
      size={size}
      className={`clin-switch ${className}`}
    >
      <StyledCheckbox
        type="checkbox"
        id={id}
        name={name}
        switchColor={switchColor}
        disabled={disabled}
        value={value}
        defaultChecked={defaultChecked}
        ref={inputRef}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange?.(event)
        }}
      />

      <StyledTrack flexDirection={flexDirection}>
        <StyledSlider />
      </StyledTrack>
      <ClinText as="div" textAlign="right">
        {children}
      </ClinText>
    </StyledLabel>
  )
}
