import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { StyledClinTextInput } from '../../components/ClinTextInput/ClinTextInput.styles'
import { IIndexable } from '../../types'
import { mediaQuery } from '../../utils/mediaQuery'

const tabletUpBreakpoint = ClinTheme.breakpoints[2]
const desktopUpBreakpoint = ClinTheme.breakpoints[3]

enum TableColumnName {
  Vial = 'vial',
  Status = 'status',
  Batch = 'batch',
  BatchNa = 'batchNa',
  Date = 'date'
}

const getColumnStyle = (column: TableColumnName) => {
  const config: IIndexable = {
    vial: {
      marginLeft: `${ClinTheme.space[3]}px`,
      flex: `0 0 14%`
    },
    status: {
      margin: `0 ${ClinTheme.space[2]}px`,
      flex: `0 0 160px`
    },
    batch: {
      margin: `0 ${ClinTheme.space[2]}px`,
      flex: `0 0 160px`,
      marginBottom: 0
    },
    batchNa: {
      margin: `0 ${ClinTheme.space[2]}px`,
      flex: `0 0 80px`,
      marginBottom: 0
    },
    date: {
      margin: `0 ${ClinTheme.space[2]}px`,
      flex: `0 0 160px`,
      marginBottom: 0
    }
  }
  return config[column!]
}

export const StyledVialReconciliationTable = styled.div({
  borderRadius: ClinTheme.space[1],
  border: '1px solid #E7E8E8',
  overflowY: 'hidden',
  overflowX: 'auto',
  [`.vial-reconcile-row:last-child`]: {
    borderBottom: 'none'
  }
})

export const StyledTableHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: `${ClinTheme.space[3]}px 0`,
  background: ClinTheme.colors.white,
  [`.vial`]: getColumnStyle(TableColumnName.Vial),
  [`.status`]: getColumnStyle(TableColumnName.Status),
  [`.batch`]: getColumnStyle(TableColumnName.Batch),
  [`.batch-na`]: getColumnStyle(TableColumnName.BatchNa),
  [`.date`]: getColumnStyle(TableColumnName.Date)
})

export const StyledVialReconcileRow = styled.form({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#EDEDFC',
  borderBottom: `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.grey}`,
  padding: `${ClinTheme.space[3]}px 0`,
  [`.vial`]: getColumnStyle(TableColumnName.Vial),
  [`.status`]: getColumnStyle(TableColumnName.Status),
  [`.batch`]: {
    ...getColumnStyle(TableColumnName.Batch),
    [`input`]: {
      width: '100%'
    }
  },
  [`.batch-na`]: {
    ...getColumnStyle(TableColumnName.BatchNa)
  },
  [`.date`]: {
    ...getColumnStyle(TableColumnName.Date),
    [`input`]: {
      cursor: 'pointer',
      width: '100%'
    },
    [mediaQuery(desktopUpBreakpoint)]: {
      width: 'auto'
    }
  },
  [`.clin-button`]: {
    marginLeft: 'auto',
    marginRight: `${ClinTheme.space[3]}px`,
    width: '160px'
  }
})

export const StyledVialReconcileDate = styled.div({
  position: 'relative',
  flex: `0 0 160px`,

  [mediaQuery(tabletUpBreakpoint)]: {
    flex: `0 0 160px`
  },

  [`.clin-icon`]: {
    position: 'absolute',
    top: '50%',
    right: ClinTheme.space[3],
    zIndex: 100,
    transform: 'translateY(-50%)'
  },
  [`${StyledClinTextInput}`]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: ClinTheme.space[4],
    userSelect: 'none',
    [`:disabled`]: {
      cursor: 'not-allowed'
    }
  }
})

export const StyledVialReconcileHeaderWrapper = styled.div({
  background: ClinTheme.colors.white,
  border: '1px solid #E7E8E8',
  borderRadius: '4px',
  padding: '16px'
})
