import { t } from 'i18next'
import React, { FunctionComponent, useState, useEffect } from 'react'

import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinText } from '../ClinText'
import {
  NotificationContainer,
  VerticalLine,
  DismissButton
} from './ClinNotification.styles'

interface ClinNotificationProps {
  title: string
  onClose: () => void
  autoHideTimer?: number
}

export const ClinNotification: FunctionComponent<ClinNotificationProps> = ({
  title,
  onClose,
  autoHideTimer = 10000
}) => {
  const [isVisible, setIsVisible] = useState(true)

  const handleClose = () => {
    setIsVisible(false)
    onClose && onClose()
  }

  useEffect(() => {
    if (autoHideTimer > 0) {
      const timer = setTimeout(() => {
        handleClose()
      }, autoHideTimer)

      return () => clearTimeout(timer)
    }
  }, [autoHideTimer])

  return (
    <>
      {isVisible && (
        <NotificationContainer>
          <ClinIcon
            style={{
              marginLeft: ClinTheme.space[3],
              marginTop: ClinTheme.space[2]
            }}
            iconSize={ClinTheme.fontSizes[4]}
            iconName={ClinIconPathName.Success}
            iconFill={ClinTheme.colors.aquaGreen}
          />
          <ClinText
            fontWeight={ClinTheme.fontWeights.medium}
            fontSize={ClinTheme.fontSizes[1]}
            color={ClinTheme.colors.white}
            whiteSpace="nowrap"
          >
            {title}
          </ClinText>
          <VerticalLine></VerticalLine>
          <DismissButton onClick={handleClose}>
            {t('common:buttons.dismiss')}
          </DismissButton>
        </NotificationContainer>
      )}
    </>
  )
}

export default ClinNotification
