/**
 * A collection of Currency utils
 */
import { IViewBasket } from '../context/basket'

/**
 * Check whether an item can be added to the basket
 * depending on whether it has a different currency to those already in the basket
 * @param currencyCode - the currency code of the item to add
 * @param viewBasket
 */
export const canAddCurrencyToBasket = (
  currencyCode: string,
  viewBasket: IViewBasket
): boolean => {
  // Look at basket currencyCode and then all individual items
  const basketCurrencyIsDifferent =
    viewBasket.currencyCode?.toLowerCase() !== currencyCode.toLowerCase()
  // Does the basket contain multiple currency items?
  const multipleCurrencies = hasMultipleCurrencies(viewBasket)
  // The items in the basket are different in currencyCode
  const itemHasDifferentCurrency = viewBasket.items.some(
    (item) => item.priceAndAvailability?.currencyCode !== currencyCode
  )
  return !(
    multipleCurrencies ||
    basketCurrencyIsDifferent ||
    itemHasDifferentCurrency
  )
}

/**
 * Check whether the basket contains multiple currencies
 * @param viewBasket
 */
export const hasMultipleCurrencies = (viewBasket: IViewBasket): boolean => {
  const currencies: string[] = []
  viewBasket.items.forEach((item) => {
    const currency = item.priceAndAvailability?.currencyCode
    if (currency && currencies.indexOf(currency) === -1) {
      currencies.push(currency)
    }
  })
  return currencies.length > 1
}

/**
 * Returns the first basket currency or the basket defined currency code if not
 * @param viewBasket
 */
export const getBasketCurrency = (
  viewBasket: IViewBasket
): string | undefined => {
  return viewBasket.items.length > 0
    ? viewBasket.items[0].priceAndAvailability?.currencyCode
    : viewBasket.currencyCode
}
