import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { useAppContext } from '../../context/app'
import {
  BasketDto,
  BasketItemDocumentDto,
  BasketItemDto,
  ItemDocumentDto,
  PriceAndAvailabilityDto,
  UploadedDocumentDto
} from '../../types/swaggerTypes'
import { RouteComponentProps } from 'react-router'
import _ from 'lodash'
import {
  cancelDraftOrder,
  cancelUpdateBasketItemQuantity,
  downloadDocumentById,
  getCurrentBasket,
  getPriceAndAvailabilityForSku,
  removeHoldDocumentToBasketItem,
  removeItemFromBasket,
  setHoldDocumentToBasketItem,
  updateBasketItemQuantity
} from '../../services/ApiService'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { AxiosError, AxiosResponse } from 'axios'
import { validateQuantity } from '../../utils/validateQuantity'
import {
  ActionType,
  ActionUpdateBasketItemQuantity,
  BasketStatus,
  useBasket
} from '../../context/basket'
import { useAllHoldsInformation } from '../../services/Holds/holds'
import { IHoldDetails } from '../../services/Holds/holds.types'
import { useBasketHasAtLeastOneUnavailableItem } from '../../services/PortfolioJourneys/PortfolioJourney.hooks'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { handleBasketError } from '../../utils/basketUtils'
import download from 'downloadjs'
import { getFilenameFromUrl } from '../../utils/getFilenameFromUrl'
import { ClinTheme } from '../../ClinTheme'
import { CountryAlphaCodes } from '../../constants/countryAlpha2Codes'
import { isAusUser } from '../../constants'
import useChangeBackgroundColor from '../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { BasketOld, BasketViewState } from './BasketOld'

export interface IQuantityError {
  basketItemId: string
  message: string
}

export const BasketContainerOld: FunctionComponent<RouteComponentProps> = ({
  history
}) => {
  const { dispatch, defaultShippingAddress, portfolioCountryCode } =
    useAppContext()
  const [{ viewBasket }, basketDispatch] = useBasket()
  const [basket, setBasket] = React.useState<BasketDto | undefined>()
  const [basketViewState, setBasketViewState] = React.useState<BasketViewState>(
    BasketViewState.Loading
  )
  const [agreedTandCs, setAgreedTandCs] = React.useState<boolean>()
  const [hasDownloadedSafetyLetter, setHasDownloadedSafetyLetter] =
    React.useState<boolean>(false)
  const [isUpdatingQuantity, setIsUpdatingQuantity] = React.useState<boolean>()
  const [quantityErrors, setQuantityErrors] = React.useState<IQuantityError[]>(
    []
  )

  useChangeBackgroundColor(ClinTheme.colors.lightGrey)
  const holdInformation = useAllHoldsInformation([], viewBasket)

  const isAnItemUnavailable = useBasketHasAtLeastOneUnavailableItem(viewBasket)

  const updateItemQuantityDebounced = useCallback(
    _.debounce((basketItem: BasketItemDto, quantity: number) => {
      if (basketViewState === BasketViewState.Loading) {
        cancelUpdateBasketItemQuantity()
      }
      setIsUpdatingQuantity(true)
      cancelUpdateBasketItemQuantity()

      updateBasketItemQuantity(
        basketItem.basketItemId,
        quantity,
        basketItem.sku
      )
        .then((response) => {
          const updateBasketItem = response.data
          basketDispatch({
            type: ActionType.UpdateItemQuantity,
            basketItem: updateBasketItem,
            quantity: updateBasketItem.quantity
          } as ActionUpdateBasketItemQuantity)
          setIsUpdatingQuantity(false)
        })
        .catch((error) => {
          const basketError = handleBasketError(error, undefined, () => {
            handleChangeQuantity(basketItem, quantity, true)
          })
          !basketError &&
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `An error occurred updating an item quantity. ${error}`
              )
            )
        })
    }, 500),
    []
  )

  const validateQuantityAndManageError = (
    basketItem: BasketItemDto,
    quantity: number
  ): boolean => {
    const errMsg = validateQuantity(
      quantity,
      basketItem.skuCatalogItem.item.minimumOrderQuantity ?? 1,
      basketItem.skuCatalogItem.item.maximumOrderQuantity,
      basketItem.skuCatalogItem.item.incrementalOrderQuantity
    )
    if (errMsg) {
      setQuantityErrors([
        ...quantityErrors,
        { basketItemId: basketItem.basketItemId, message: errMsg }
      ])
      return false
    }
    setQuantityErrors([
      ...quantityErrors.filter(
        (err) => err.basketItemId !== basketItem.basketItemId
      )
    ])
    return true
  }

  const handleChangeQuantity = (
    basketItem: BasketItemDto,
    quantity: number,
    isRetry?: boolean
  ) => {
    if (isRetry) {
      updateItemQuantityDebounced(basketItem, quantity)
      return
    }
    basketViewState !== BasketViewState.Loading &&
      validateQuantityAndManageError(basketItem, quantity) &&
      updateItemQuantityDebounced(basketItem, quantity)
  }

  const handleRemoveItem = (basketItem: BasketItemDto) => {
    setBasketViewState(BasketViewState.Loading)
    removeItemFromBasket(basketItem.basketItemId)
      .then((response) => {
        if (response.status === 204) {
          basketDispatch({
            type: ActionType.RemoveBasketItem,
            basketItem
          })
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.BasketRemoveItem,
            {
              sku: basketItem.sku,
              quantity: basketItem.quantity
            }
          )
        }
      })
      .catch((error) => {
        const basketError = handleBasketError(error, undefined, () =>
          handleRemoveItem(basketItem)
        )
        !basketError &&
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred removing an item from your basket. ${error}`
            )
          )
      })
      .finally(() => setBasketViewState(BasketViewState.Basket))
  }

  const handleProceedToCheckout = () => {
    //for Australina user redirect to one step checkout page
    if (portfolioCountryCode === CountryAlphaCodes.Australia) {
      history.push({
        pathname: '/one-step-checkout'
      })
    } else {
      // Check for any baskets in progress
      getCurrentBasket().then((response) => {
        // If their is a checkout in progress
        if (response?.data.state === BasketStatus.CheckingOut) {
          // Cancel it
          cancelDraftOrder()
            .then((response) => {
              if (response.status === 200) {
                setBasketViewState(BasketViewState.Declaration)
              }
            })
            .catch((error) => {
              dispatch &&
                dispatch(
                  createAnnounceEvent(
                    AnnounceMode.Error,
                    `An error occurred cancelling checkout. ${error}`
                  )
                )
            })
        } else {
          setBasketViewState(BasketViewState.Declaration)
        }
      })
    }
  }

  const handleDeclarationCancel = () => {
    setAgreedTandCs(false)
    setHasDownloadedSafetyLetter(false)
    setBasketViewState(BasketViewState.Basket)
  }

  const handleDeclarationSelect = (agreed: boolean) => {
    setAgreedTandCs(agreed)
  }

  const handleSafetyLetterDownloadSelect = (agreed: boolean) => {
    setHasDownloadedSafetyLetter(agreed)
  }

  const handleDeclarationSubmit = () => {
    agreedTandCs &&
      defaultShippingAddress &&
      setBasketViewState(BasketViewState.Submitting)
    agreedTandCs && defaultShippingAddress && history.push('/checkout/delivery')
  }

  // Helper function to process holds
  const processHolds = (result: PriceAndAvailabilityDto) => {
    result.holds ??= []
    return {
      ...result,
      holds: result.holds.map((hold) => ({
        ...hold,
        holdDocuments: hold.holdDocuments ?? []
      }))
    }
  }

  // Helper function to handle price and availability error
  const handlePriceAndAvailabilityError = (error: any) => {
    dispatch(
      createAnnounceEvent(
        AnnounceMode.Error,
        `An error occurred fetching your basket. ${error}`
      )
    )
  }

  /**
   * Get pricing information for all skus in the basket
   */
  const basketProceedClicked = localStorage.getItem('basketProceedClicked')

  useEffect(() => {
    if (basketProceedClicked) {
      setBasketViewState(BasketViewState.Declaration)
    }
  }, [basketProceedClicked])

  const getPriceAndAvailability = async () => {
    if (!basket || !defaultShippingAddress?.addressId) return

    try {
      const skus = basket.items.map((item) => item.sku)
      const pricingCalls = skus.map((sku) =>
        getPriceAndAvailabilityForSku(
          sku,
          defaultShippingAddress.addressId
        ).then((r) => r.data)
      )

      const results = await Promise.all(pricingCalls)
      const processedResults = results.map(processHolds)

      basketDispatch({
        type: ActionType.SetCurrentBasket,
        basket: basket,
        priceAndAvailability: processedResults,
        countryCode: portfolioCountryCode
      })
      if (basketProceedClicked) {
        setBasketViewState(BasketViewState.Declaration)
        localStorage.removeItem('basketProceedClicked')
      } else {
        setBasketViewState(BasketViewState.Basket)
      }
    } catch (error) {
      handlePriceAndAvailabilityError(error)
    }
  }

  /**
   * Associate a sku with a hold document that has been uploaded
   * @param basketItemId
   * @param document
   * @param holdDetails
   */
  const handleFileUploadedForBasketItem = (
    basketItemId: string,
    document: UploadedDocumentDto,
    holdDetails: IHoldDetails,
    basketItemDocumentId?: string | null
  ) => {
    if (holdDetails.requiredDocumentName && holdDetails.holdDto?.holdName) {
      const documentForBasketItem: BasketItemDocumentDto = {
        basketItemDocumentId: basketItemDocumentId
          ? basketItemDocumentId
          : null,
        basketItemId,
        uploadedDocumentId: document.uploadedDocumentId,
        uploadedDocument: document,
        holdName: holdDetails.holdDto.holdName,
        documentType: holdDetails.requiredDocumentName
      }
      setHoldDocumentToBasketItem(documentForBasketItem)
        .then((response) => {
          basketDispatch({
            type: ActionType.UpdateBasketItemDocuments,
            basketItemId: basketItemId,
            basketItemDocuments: response.data.documents
          })
          // TODO: dispatch this to update the basket or hang on to it!
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error associating the file with the hold. ${error}`
            )
          )
        })
    } else {
      console.warn(
        'handleFileUploadedForBasketItem - required document or holdName missing!'
      )
    }
  }

  /**
   * Remove file from association with basket item for hold
   * */
  const handleRemoveFileForHoldAsync = async (
    holdDetails: IHoldDetails,
    basketItemId: string
  ) => {
    const targetViewBasketItem = viewBasket?.items.find(
      (d) => d.basketItemId === basketItemId
    )
    targetViewBasketItem?.documents.forEach((targetDocument) => {
      if (targetDocument?.uploadedDocumentId) {
        removeHoldDocumentToBasketItem(
          basketItemId,
          targetDocument?.uploadedDocumentId
        )
          .then((response) => {
            basketDispatch({
              type: ActionType.UpdateBasketItemDocuments,
              basketItemId: basketItemId,
              basketItemDocuments: response.data.documents,
              remove: true
            })
          })
          .catch((error) => {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error removing the file from the hold. ${error}`
              )
            )
          })
      }
    })
  }

  //in case we have multiple documents per hold use this to delete one of them
  const handleRemoveSpecificFileForHoldAsync = (
    item: BasketItemDocumentDto
  ) => {
    if (item.uploadedDocumentId) {
      removeHoldDocumentToBasketItem(item.basketItemId, item.uploadedDocumentId)
        .then((response) => {
          basketDispatch({
            type: ActionType.UpdateBasketItemDocuments,
            basketItemId: item.basketItemId,
            basketItemDocuments: response.data.documents,
            remove: false
          })
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error removing the file from the hold. ${error}`
            )
          )
        })
    }
  }

  const handleDownloadSafetyLetter = () => {
    // Find safety letter from documents
    const safetyLetterDocs: ItemDocumentDto[] = []
    const hasSafetyLetter = (fileName: string): boolean => {
      return (
        fileName.toLowerCase().includes('mhra saftey letter') ||
        fileName.toLowerCase().includes('mhra safety letter')
      )
    }
    basket?.items.forEach((item) =>
      item.skuCatalogItem.documents.forEach(
        (d) =>
          d.documentType &&
          hasSafetyLetter(d.documentType) &&
          safetyLetterDocs.push(d)
      )
    )
    // Download all of them
    if (safetyLetterDocs && safetyLetterDocs.length > 0) {
      safetyLetterDocs.forEach(
        (documentItem) =>
          documentItem.generatedId &&
          downloadDocumentById(documentItem.generatedId)
            .then((response) => {
              documentItem.documentType &&
                documentItem.documentUrl &&
                download(
                  response.data,
                  getFilenameFromUrl(documentItem.documentUrl),
                  documentItem.documentType
                )
              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.DocumentDownload,
                {
                  ...documentItem
                }
              )
            })
            .catch((error: AxiosError) => {
              window.scrollTo(0, 0)
              dispatch(
                createAnnounceEvent(
                  AnnounceMode.Error,
                  `There was an error downloading your document. ${error}`
                )
              )
            })
      )
    }
  }

  // Wait for current basket
  useEffect(() => {
    if (!basket) {
      getCurrentBasket()
        .then((response: AxiosResponse<BasketDto> | null) => {
          // Update badge value
          if (response?.data) {
            response.data.items ??= []
            response.data.orders ??= []
            setBasket(response.data)
            basketDispatch({
              type: ActionType.UpdateBasket,
              basket: { data: response.data, isLoading: false }
            })
          }
        })
        .catch((error: AxiosError) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the basket. ${error}`
            )
          )
        })
    }
  }, [basket, dispatch, basketDispatch])

  // With address and a basket get pricing and availability information
  useEffect(() => {
    // If a basket exists but is in checkout mode go to checkout
    if (viewBasket && viewBasket.state === BasketStatus.CheckingOut) {
      isAusUser(portfolioCountryCode)
        ? history.push('/one-step-checkout')
        : history.push('/checkout')
    }
    if (viewBasket && defaultShippingAddress) {
      // Get pricing
      getPriceAndAvailability()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket, defaultShippingAddress])

  return (
    <BasketOld
      showDebug={false}
      countryCode={portfolioCountryCode}
      basketViewState={basketViewState}
      basket={viewBasket}
      holdInformation={holdInformation}
      quantityErrors={quantityErrors}
      isAnItemUnavailable={isAnItemUnavailable}
      isUpdatingQuantity={isUpdatingQuantity}
      hasAgreedToTerms={agreedTandCs}
      hasDownloadedSafetyLetter={hasDownloadedSafetyLetter}
      handleChangeItemQuantity={handleChangeQuantity}
      handleRemoveItem={handleRemoveItem}
      handleProceedToCheckout={handleProceedToCheckout}
      handleDeclarationCancel={handleDeclarationCancel}
      handleDeclarationSelect={handleDeclarationSelect}
      handleDeclarationSubmit={handleDeclarationSubmit}
      handleFileUploadedForBasketItem={handleFileUploadedForBasketItem}
      handleRemoveFileForHoldAsync={handleRemoveFileForHoldAsync}
      handleRemoveSpecificFileForHoldAsync={
        handleRemoveSpecificFileForHoldAsync
      }
      handleSafetyLetterDownloadSelect={handleSafetyLetterDownloadSelect}
      handleDownloadSafetyLetter={handleDownloadSafetyLetter}
    />
  )
}
