import React, { FunctionComponent, useEffect, useState } from 'react'
import { Tooltip } from 'react-tippy'

import { tooltipWrapperStyles } from './ColumnTooltip.styles'
import { ClinTheme } from '../../../../ClinTheme'

interface IColumnTooltipProps {
  children: React.ReactNode
  tooltipText: string
  additionalClass?: string
  disableOnMobile?: boolean
}

const ColumnTooltip: FunctionComponent<IColumnTooltipProps> = ({
  children,
  tooltipText,
  additionalClass = '',
  disableOnMobile
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  if (!tooltipText) {
    return <>{children}</>
  }

  const updateIsMobile = () => {
    setIsMobile(
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) <= ClinTheme.breakpoints[2]
    )
  }

  const tooltipPosition =
    additionalClass === 'custom-tooltip' ? 'top' : 'bottom'

  const listener = () => {
    updateIsMobile()
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    updateIsMobile()
    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  return (
    <div className={additionalClass}>
      <div style={{ ...tooltipWrapperStyles, overflow: 'hidden' }}>
        <Tooltip
          theme="light"
          title={tooltipText}
          position={tooltipPosition}
          trigger="mouseenter"
          disabled={disableOnMobile && isMobile}
          arrow={true}
        >
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '100%'
            }}
          >
            {children}
          </span>
        </Tooltip>
      </div>
    </div>
  )
}

export default ColumnTooltip
