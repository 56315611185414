import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'

import { EditContactForm } from './EditContactForm'
import { ClinTheme } from '../../../ClinTheme'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import {
  InstituteContactDto,
  LovDto,
  UpdateContactDto
} from '../../../types/swaggerTypes'

interface IEditContactProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** An array of specialism values */
  specialisms?: LovDto[]
  /** An array of lov job title values */
  jobTitles?: LovDto[]
  /** Contact to be edited */
  contact?: InstituteContactDto
  /** Whether the submission API request is in progress */
  isSubmitting?: boolean
  /** Form submission handler */
  handleFormSubmission: (data: UpdateContactDto) => void
  /** Cancel form and return to previous page */
  handleCancel: () => void
}

export const EditContact: FunctionComponent<IEditContactProps> = ({
  isLoading,
  contact,
  specialisms,
  jobTitles,
  isSubmitting,
  handleFormSubmission,
  handleCancel
}) => {
  return (
    <ClinPageContentFrame>
      {isLoading && contact === undefined && (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      )}

      {!isLoading && contact && specialisms && jobTitles && (
        <EditContactForm
          contact={contact}
          jobTitles={jobTitles}
          specialisms={specialisms}
          isSubmitting={isSubmitting}
          handleFormSubmission={handleFormSubmission}
          handleCancel={handleCancel}
        />
      )}
    </ClinPageContentFrame>
  )
}
