import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { Trans, useTranslation } from 'react-i18next'

import {
  StyledMaOrderItem,
  IStyledMaOrderItemProps,
  StyledMaOrderItemDetailMobileLabel,
  StyledMaOrderItemDetailValue,
  StyledMaOrderItemStatus,
  StyledMaOrderItemDocs,
  StyledMaOrderItemName,
  StyledHoldFileItem
} from './MaOrderItem.styles'
import { ClinTheme } from '../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../components/ClinAccordion'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { DrugPropertyItem } from '../../features/OrderItems/OrderItem'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { getOrderHoldsInformation } from '../../services/Holds/holds'
import { HoldType } from '../../services/Holds/holds.constants'
import {
  HoldDto,
  OrderLineDto,
  UploadedDocumentDto
} from '../../types/swaggerTypes'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'
import { DocumentUpload } from '../DocumentUpload'
import { DocumentUploadContainer } from '../DocumentUpload/DocumentUploadContainer'
import { HoldAnnounce } from '../HoldAnnounce'

export enum OrderItemType {
  OrdinaryItem,
  ReassignedItem
}
interface DocsDescription {
  message: string
  status?: string
}
export interface IMaOrderItemProps extends IStyledMaOrderItemProps {
  /** The order id **/
  orderId: string
  /** Order items */
  orderLine: OrderLineDto
  /** Sponsor of program */
  sponsor: string | undefined
  /** Currency code */
  currencyCode: string
  /** Order item type */
  itemType: OrderItemType
  /** Hide/show file upload field */
  isFileUploadEnabled: boolean
  /** Called when a file has been uploaded */
  handleFileUploadedForOrderAndHold?: (
    orderId: string,
    orderLine: OrderLineDto,
    hold: HoldDto,
    document: UploadedDocumentDto
  ) => void
}

export const MaOrderItem: FunctionComponent<IMaOrderItemProps> = ({
  orderId,
  orderLine,
  sponsor,
  currencyCode,
  itemType,
  isFileUploadEnabled,
  isShowingProductDetails = true,
  hasBorder,
  handleFileUploadedForOrderAndHold
}) => {
  const { t } = useTranslation()
  const { skuCatalogItem } = orderLine
  const genericName = skuCatalogItem?.item.genericDosageConcatenation
  const dosageAndPackSize =
    skuCatalogItem?.formulationPackSize || t('glossary:not_applicable')
  const quantity =
    itemType === OrderItemType.OrdinaryItem
      ? orderLine.quantities.ordered
      : orderLine.vialsReassignedQuantity
  const brandName = skuCatalogItem?.item.brand
    ? skuCatalogItem?.item.brand
    : t('glossary:not_applicable')
  const licenseCountries: string[] = []
  skuCatalogItem?.licenses.forEach(
    (license) => license.country && licenseCountries.push(license.country)
  )
  const licenseCountry =
    licenseCountries.join(', ') || t('glossary:not_applicable')
  const packPrice = numberToCurrencyString(
    orderLine.unitSellingPrice,
    getBrowserLocale(),
    {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  )
  // Required for reassigned order items
  const status =
    itemType === OrderItemType.OrdinaryItem
      ? orderLine.status
      : t('glossary:reassigned')
  const docsData: DocsDescription[] = []
  const { holds } = orderLine
  const validationHold = holds.find(
    (hold) => hold.holdName === HoldType.ValidationHold
  )
  const controlledDrugs = holds.find(
    (hold) => hold.holdName === HoldType.ControlledDrug
  )
  if (holds.length === 0 || (!validationHold && !controlledDrugs)) {
    docsData.push({
      message: t(
        'ma_order_detail:order_items.supporting_documents_not_required'
      )
    })
  }
  if (validationHold) {
    docsData.push({
      message: t('holds:import_license'),
      status: t('glossary:not_provided')
    })
  }
  if (controlledDrugs) {
    docsData.push({
      message: t('holds:controlled_drug_import_license'),
      status: t('glossary:not_provided')
    })
  }

  const holdsInformation = getOrderHoldsInformation(orderLine.holds)

  return (
    <StyledMaOrderItem
      hasBorder={hasBorder}
      isShowingProductDetails={isShowingProductDetails}
    >
      <Row className="order-data">
        <Col sm={12} md={6}>
          <StyledMaOrderItemDetailValue>
            <StyledMaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_product')}
              </ClinText>
            </StyledMaOrderItemDetailMobileLabel>
            <StyledMaOrderItemName className="generic-name">
              {genericName}
            </StyledMaOrderItemName>
          </StyledMaOrderItemDetailValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledMaOrderItemDetailValue>
            <StyledMaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_product_code')}
              </ClinText>
            </StyledMaOrderItemDetailMobileLabel>
            <ClinText
              className="product-code"
              fontSize={ClinTheme.fontSizes[2]}
              textAlign="right"
            >
              {orderLine.skuCatalogItem?.sku || t('glossary:not_applicable')}
            </ClinText>
          </StyledMaOrderItemDetailValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledMaOrderItemDetailValue>
            <StyledMaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_quantity')}
              </ClinText>
            </StyledMaOrderItemDetailMobileLabel>
            <ClinText
              className="quantity"
              fontSize={ClinTheme.fontSizes[2]}
              textAlign="right"
            >
              {quantity}
            </ClinText>
          </StyledMaOrderItemDetailValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledMaOrderItemDetailValue>
            <StyledMaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_amount')}
              </ClinText>
            </StyledMaOrderItemDetailMobileLabel>
            <ClinText
              className="amount"
              fontSize={ClinTheme.fontSizes[2]}
              textAlign="right"
            >
              {packPrice}
            </ClinText>
          </StyledMaOrderItemDetailValue>
        </Col>
      </Row>
      <StyledMaOrderItemStatus>
        <ClinText
          fontSize={ClinTheme.fontSizes[1]}
          fontWeight={ClinTheme.fontWeights.medium}
          color={ClinTheme.colors.primary}
        >
          {t('glossary:status')}: &nbsp;
        </ClinText>
        <ClinText className="status" fontSize={ClinTheme.fontSizes[2]}>
          {status}
        </ClinText>
      </StyledMaOrderItemStatus>
      {itemType === OrderItemType.OrdinaryItem && holdsInformation && (
        <>
          {holdsInformation.holdsDetails
            .filter(
              (h) => h.holdDto?.holdName !== HoldType.CustomDelivery.toString()
            )
            .map((hold, index) => (
              <Row key={`${hold.type}-${index}`}>
                <Col sm={12} md={8}>
                  {index > 0 && <ClinSpacer height={ClinTheme.space[3]} />}
                  <HoldAnnounce
                    mode={hold.announceMode}
                    title={hold.title}
                    message={hold?.maMessage?.replace(
                      '{genericName}',
                      orderLine.skuCatalogItem?.item.genericDosageConcatenation
                        ? orderLine.skuCatalogItem.item
                            .genericDosageConcatenation
                        : t('glossary:drug_name')
                    )}
                  />
                </Col>
              </Row>
            ))}
          <ClinSpacer height={ClinTheme.space[4]} />
        </>
      )}
      {isShowingProductDetails && (
        <Row className="program-details-and-docs">
          <Col sm={12} md={8}>
            <ClinAccordion activeItemIndices={[0]}>
              <ClinAccordionItem
                title={t('ma_order_detail:order_items.program_details_title')}
              >
                <Row>
                  <DrugPropertyItem
                    iconName={ClinIconPathName.PackSize}
                    title={t('glossary:dosage_and_packsize')}
                    description={dosageAndPackSize || 'N/A'}
                  />
                  <DrugPropertyItem
                    iconName={ClinIconPathName.Marker}
                    title={t('glossary:country_of_license')}
                    description={licenseCountry}
                  />
                  <DrugPropertyItem
                    iconName={ClinIconPathName.BrandName}
                    title={t('glossary:product')}
                    description={brandName}
                  />
                  <DrugPropertyItem
                    iconName={ClinIconPathName.DocumentSponsor}
                    title={t('glossary:sponsor')}
                    description={sponsor || t('glossary:not_applicable')}
                  />
                  <DrugPropertyItem
                    iconName={ClinIconPathName.UnitPrice}
                    title={t('glossary:unit_price')}
                    description={packPrice}
                  />
                </Row>
                <StyledMaOrderItemDocs>
                  <Row>
                    <Col sm={12}>
                      <ClinText
                        color={ClinTheme.colors.primary}
                        fontSize={ClinTheme.fontSizes[3]}
                        marginTop={ClinTheme.space[4]}
                        marginBottom={ClinTheme.space[3]}
                      >
                        {t(
                          'ma_order_detail:order_items.supporting_documents_title'
                        )}
                      </ClinText>
                      <ClinSpacer height={ClinTheme.space[1]} />
                      {!isFileUploadEnabled ? (
                        docsData.map((data, index) => (
                          <Row key={index}>
                            <Col sm={data.status ? 6 : 12}>
                              <ClinText
                                className={'hold-generic-name'}
                                fontSize={ClinTheme.fontSizes[2]}
                                marginBottom={ClinTheme.space[3]}
                              >
                                {data.message}
                              </ClinText>
                            </Col>
                            {data.status && (
                              <Col sm={6}>
                                <ClinText
                                  className="hold-document-status"
                                  fontSize={ClinTheme.fontSizes[2]}
                                  fontWeight={ClinTheme.fontWeights.bold}
                                  color={ClinTheme.colors.redInvalid}
                                  marginBottom={ClinTheme.space[3]}
                                >
                                  {data.status}
                                </ClinText>
                              </Col>
                            )}
                          </Row>
                        ))
                      ) : (
                        <>
                          {holdsInformation.requiresDocuments ? (
                            <ClinText marginBottom={ClinTheme.space[3]}>
                              <Trans
                                i18nKey="ma_order_detail:order_items.supporting_documents_required"
                                components={{ br: <br /> }}
                              >
                                <></>
                              </Trans>
                            </ClinText>
                          ) : (
                            <ClinText marginBottom={ClinTheme.space[3]}>
                              <Trans
                                i18nKey="ma_order_detail:order_items.supporting_documents_not_required"
                                components={{ br: <br /> }}
                              >
                                <></>
                              </Trans>
                            </ClinText>
                          )}

                          {holdsInformation.holdsDetails.map((hold, index) => {
                            return hold.requiresDocumentUpload &&
                              hold.requiredDocumentName &&
                              !hold.documentUploaded ? (
                              <StyledHoldFileItem key={`doc-upload-${index}`}>
                                <DocumentUploadContainer
                                  isPreOrderVariant={true}
                                  documentPrompt={hold.documentPrompt}
                                  warehouseAddress={hold.wareHouseAddress}
                                  prompt={hold.requiredDocumentName}
                                  handleFileUploaded={(document) => {
                                    analyticsServiceSingleton.trackEvent(
                                      AnalyticsEvent.DocumentUpload,
                                      {
                                        sku: orderLine.skuCatalogItem?.sku,
                                        documentType: hold.requiredDocumentName
                                      }
                                    )
                                    handleFileUploadedForOrderAndHold &&
                                      hold.holdDto?.holdId &&
                                      handleFileUploadedForOrderAndHold(
                                        orderId,
                                        orderLine,
                                        hold.holdDto,
                                        document
                                      )
                                  }}
                                />
                              </StyledHoldFileItem>
                            ) : (
                              hold.documentUploaded && (
                                <DocumentUpload
                                  key={`doc-upload-${index}`}
                                  prompt={hold.documentUploaded.documentType}
                                  fileName={hold.documentUploaded.documentName}
                                  uploadDetails={{
                                    uploadDate:
                                      hold.documentUploaded.uploadedDate,
                                    uploadedBy: hold.documentUploaded.uploadedBy
                                  }}
                                />
                              )
                            )
                          })}
                        </>
                      )}
                    </Col>
                  </Row>
                </StyledMaOrderItemDocs>
              </ClinAccordionItem>
            </ClinAccordion>
          </Col>
        </Row>
      )}
    </StyledMaOrderItem>
  )
}
