import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinStyledButton } from '../../components/ClinButton/ClinButton.styles'
import { StyledClinChip } from '../../components/ClinChip/ClinChip.styles'

export const StyledFilterGroup = styled.div({
  padding: `${ClinTheme.space[3]}px ${ClinTheme.space[3]}px`,
  backgroundColor: ClinTheme.colors.lightGrey,
  [StyledClinChip]: {
    marginLeft: 0
  },
  [ClinStyledButton]: {
    marginLeft: ClinTheme.space[2]
  }
})

export const StyledFilterHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: ClinTheme.space[1]
})
