import React, { FunctionComponent } from 'react'

import {
  StyledTabButtonWrapper,
  StyledTabButton,
  StyledTabBedge
} from '../../../Programs/ProgramDetail/ProgramDetail.styles'

interface IPatientDetailTabLinkProps {
  linkText: string
  countNumber?: number
  showCountNumber?: boolean
  tabIndex: number
  buttonStyles?: {}
  active?: boolean
  onClick: (tabIndex: number) => void
}

export const PatientDetailTabLink: FunctionComponent<
  IPatientDetailTabLinkProps
> = ({
  linkText,
  countNumber,
  showCountNumber,
  buttonStyles,
  tabIndex,
  active,
  onClick
}) => {
  return (
    <StyledTabButtonWrapper active={active}>
      <StyledTabButton
        style={buttonStyles ? buttonStyles : { textDecoration: 'none' }}
        active={active}
        onClick={() => onClick(tabIndex)}
        variant={'linkButton'}
      >
        {linkText}
        {showCountNumber && (countNumber || countNumber === 0) ? (
          <StyledTabBedge active={active}>{countNumber}</StyledTabBedge>
        ) : (
          <></>
        )}
      </StyledTabButton>
    </StyledTabButtonWrapper>
  )
}
