import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { mediaQueryDown } from '../../utils/mediaQuery'

const mediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledOrderSummaryHeading = styled.div({
  display: 'inline-flex',
  alignItems: 'baseline'
})

export const StyledOrderSummaryIcon = styled.div({
  borderRadius: '50%',
  width: ClinTheme.space[5],
  height: ClinTheme.space[5],
  display: 'inline-flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  color: ClinTheme.colors.white,
  backgroundColor: ClinTheme.colors.secondary
})

export const StyledOrderLink = styled(Link)({
  fontSize: ClinTheme.fontSizes[2],
  lineHeight: ClinTheme.lineHeights.largeParagraph,
  marginTop: ClinTheme.space[2],
  marginBottom: ClinTheme.space[2],
  textDecoration: 'underline',
  color: ClinTheme.colors.black,
  [`:hover`]: {
    color: ClinTheme.colors.black
  }
})

export const StyleOpaAction = styled.div({
  display: 'flex',
  cursor: 'pointer'
})

export const StyledOrderSummaryButtonsGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  [`.clin-button`]: {
    marginLeft: ClinTheme.space[4]
  },
  [mediaQueryDown(mediumUpBreakpoint)]: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    [`.clin-button`]: {
      width: '100%',
      marginLeft: ClinTheme.space[0],
      marginTop: ClinTheme.space[3]
    }
  }
})

export const StyledOpaPdfWrapper = styled.div({
  [`@media print`]: {
    pageBreakAfter: 'always'
  }
})
