import mixpanel from 'mixpanel-browser'

import AnalyticsService, { IAnalyticsProvider } from './AnalyticsService'
import authService from '../AuthService'
import { AnalyticsEvent } from './AnalyticsEvent'
import packageJson from '../../../package.json'
import versionJson from '../../version.json'

/**
 * MixPanelProvider
 *
 * @usage
 * Install dependencies
 * Pass through the correct mixpanel token
 * npm install @types/mixpanel-browser -D
 * npm install mixpanel-browser -S
 *
 * @further-information
 * https://developer.mixpanel.com/docs/javascript (SDK integration)
 * https://developer.mixpanel.com/docs/javascript-full-api-reference
 * https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/mixpanel-browser/mixpanel-browser-tests.ts
 * https://help.mixpanel.com/hc/en-us/articles/115004613766
 */
export class MixPanelProvider
  extends AnalyticsService
  implements IAnalyticsProvider
{
  initProvider = () => {
    if (mixpanel) {
      this.logEvent(`MixPanelService initProvider`)
      mixpanel.init(
        this.tokenId,
        {
          api_host: 'https://api-eu.mixpanel.com', // N.B. This requires EU DATA to be enabled in MixPanel Project
          debug: this.enableDebugLogging,
          loaded: () => {
            // Wrap the async function in a void function that doesn't return the Promise
            this.registerUser()
          }
        },
        'mixpanel'
      )
      const hasOptedOut = mixpanel.has_opted_out_tracking()
      if (hasOptedOut) {
        // Browsers can have a DNT enabled - i.e. in Chrome > Settings > Send a ‘Do Not Track’ request with your browsing traffic
        console.warn('Mixpanel - You have opted out of tracking') // https://help.mixpanel.com/hc/en-us/articles/360001113426-Opt-Out-of-Tracking#do-not-track-settings
      }
    } else {
      console.warn('MixPanelService - no mixpanel object found!')
    }
  }

  registerUser = async () => {
    const user = await authService.getUserAsync()

    if (!user?.profile.sub) {
      return
    }
    const userId = user.profile.sub
    const preAuthId = mixpanel.get_distinct_id()

    mixpanel.identify(userId)

    if (preAuthId !== userId) {
      mixpanel.alias(userId, preAuthId)
    }

    mixpanel.register_once({ 'First Login Date': new Date().toISOString() })

    if (user?.profile.email) {
      mixpanel.people.set({
        $email: user.profile.email,
        version: packageJson.version,
        build:
          versionJson.buildNumber !== '#{BuildNumber}#'
            ? versionJson.buildNumber
            : '0'
      })
    }
  }

  trackEvent = (event: AnalyticsEvent, properties?: object) => {
    this.logEvent(`MixPanelService trackEvent: ${event}, ${properties}`)

    if (mixpanel && typeof mixpanel.track === 'function') {
      mixpanel.track(event.toString(), properties, { transport: 'xhr' }, () =>
        this.logEvent(`MixPanelService trackEvent returned OK`)
      )
    }
  }

  trackPageView = (path: string, properties?: object) => {
    this.logEvent(`MixPanelService trackPageView: ${path}`)
    if (mixpanel && typeof mixpanel.track === 'function') {
      mixpanel.track('PageView', { route: path, ...properties }, () =>
        this.logEvent(`MixPanelService trackPageView returned OK`)
      )
    }
  }

  trackError = (error: Error) => {
    this.logEvent(`MixPanelService trackError: ${error.message}`)
    if (mixpanel && typeof mixpanel.track === 'function') {
      mixpanel.track('ExceptionEvent', error, { transport: 'xhr' }, () =>
        this.logEvent(`MixPanelService trackError returned OK`)
      )
    }
  }
}
