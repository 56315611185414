import { defaultAusGaDerivedFromFilter } from './ProductSearchContainer'
import {
  UserRole,
  UserRoleRecord,
  isAusGaUser,
  isAusMaUser
} from '../../../constants'
import { CountryAlphaCodes } from '../../../constants/countryAlpha2Codes'
import { FacetDto } from '../../../types/swaggerTypes'
import { ProductColumn } from '../Product.models'

/**
 * Helper function to get API sort field name from UI column name
 * @param columnName
 * @param countryCode
 */
export const getSortFieldForColumn = (
  columnName: ProductColumn,
  countryCode: string
) => {
  switch (columnName) {
    case ProductColumn.Availability:
      return `countryAvailability/${countryCode}`
    case ProductColumn.BrandName:
      return 'brandsNormalized'
    case ProductColumn.GenericName:
      return 'catalogItemNameNormalized'
    case ProductColumn.TherapeuticArea:
      return 'tagsNormalized'
    case ProductColumn.Sponsor:
      return 'sponsor'
    default:
      return ''
  }
}

/**
 * Get the column name for a sort field
 * @param sortField
 * @param countryCode
 */
export const getColumnForSortField = (
  sortField: string,
  countryCode: string = 'GB'
): ProductColumn => {
  switch (sortField) {
    case `countryAvailability/${countryCode}`:
      return ProductColumn.Availability
    case 'brandsNormalized':
      return ProductColumn.BrandName
    case 'catalogItemNameNormalized':
      return ProductColumn.GenericName
    case 'tagsNormalized':
      return ProductColumn.TherapeuticArea
    default: //Default was generic name but for australia users we want to be availability related to ticket clos-2315s
      return countryCode === CountryAlphaCodes.Australia
        ? ProductColumn.Availability
        : ProductColumn.GenericName
  }
}

/**
 * Utility function to add or remove filters
 * @param tag
 * @param filters
 */
export const getUpdatedFiltersForTag = (
  tag: FacetDto,
  filters: FacetDto[]
): FacetDto[] => {
  const itemExists = filters.find((t) => t.value === tag.value)
  return itemExists
    ? filters.filter((sf) => sf.value !== tag.value)
    : [...filters, tag]
}

/**
 * Utility function to add or remove filters
 * @param tag
 * @param filters
 */
export const getDerivedFilter = (
  countryCode: string,
  userRole?: UserRole
): string => {
  const isMaUser = !!(userRole && UserRoleRecord[userRole as UserRole].isMaUser)
  const filterType = isAusMaUser(countryCode, userRole)
    ? ''
    : isMaUser
    ? 'ManagedAccessProgram'
    : isAusGaUser(countryCode, userRole) // added this related to clos-1610 (ga aus users can not see access program products)
    ? defaultAusGaDerivedFromFilter
    : ''
  return filterType
}
