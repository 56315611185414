import { DateTime } from 'luxon'
import React, { FunctionComponent, ReactNode } from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { ClinTheme } from '../../ClinTheme'
import { ClinPagination } from '../../components/ClinPagination'
import {
  getCurrentPage,
  getTotalPages,
  IPagination
} from '../../components/ClinPagination/ClinPagination.model'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinSpinner } from '../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { ClinTable } from '../../components/ClinTable'
import {
  ClinTableBodyCell,
  ClinTableHeaderCell,
  ClinTableRow
} from '../../components/ClinTable/ClinTable'
import {
  ClinTableOrderToggle,
  SortDirectionType
} from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinText } from '../../components/ClinText'
import { FacetDto, PatientSummaryDto } from '../../types/swaggerTypes'
import { NoPatients } from '../NoPatients'
import { BasicSearch } from '../Search/BasicSearch'
import { TableFilterSearch } from '../TableFilterSearch'
import {
  AssociatedPatientsColumn,
  ColumnRecord,
  getSortFieldForColumn,
  getStatusColor
} from './AssociatedPatients.model'

const minRowsPerPage = 5

interface IAssociatedPatientsProps {
  /** The array of patients search results */
  patients?: PatientSummaryDto[]
  /** The initial query */
  initialQuery?: string
  /** Whether table is loading or not */
  isLoading?: boolean
  /** Columns to show on the physicians table */
  columns: AssociatedPatientsColumn[]
  /** Pagination */
  pagination?: IPagination

  programFilters?: FacetDto[]
  selectedProgramFilters?: FacetDto[]
  patientStatusFilters?: FacetDto[]
  selectedPatientStatusFilters?: FacetDto[]

  /** The selected column */
  selectedSortColumn?: AssociatedPatientsColumn
  /** The selected sort direction */
  selectedSortDirection?: SortDirectionType
  /** Handle the row clicked */
  handleRowClicked: (patientId: number) => void
  /** Select the toggle sort column */
  handleToggleSort: (columnName: AssociatedPatientsColumn) => void
  /** When pagination page is selected */
  handlePageClicked?: (selectedPageIndex: number) => void
  /** Select results per page */
  handlePageSizeChange?: (pageSize: number) => void
  /** When a filter is selected */
  handleFilterToggle?: (tag: FacetDto, filterGroupTitle: string) => void
  /** Clear all filters */
  handleClearFilters?: () => void
  /** When a search query is entered and ENTER pressed */
  handleOnSearch?: (query: string) => void
}

export const AssociatedPatients: FunctionComponent<
  IAssociatedPatientsProps
> = ({
  patients,
  initialQuery,
  isLoading,
  columns,
  pagination,
  selectedSortColumn,
  selectedSortDirection,
  programFilters,
  selectedProgramFilters,
  patientStatusFilters,
  selectedPatientStatusFilters,
  handleRowClicked,
  handleToggleSort,
  handlePageClicked,
  handlePageSizeChange,
  handleFilterToggle,
  handleClearFilters,
  handleOnSearch
}) => {
  const { t } = useTranslation()

  const getCellContent = (
    column: AssociatedPatientsColumn,
    patient: PatientSummaryDto
  ): ReactNode | string => {
    switch (column) {
      case AssociatedPatientsColumn.PatientNumber:
        return (
          <ClinText
            className={'patient-id'}
            color={ClinTheme.colors.black}
            fontSize={ClinTheme.fontSizes[2]}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {patient.patientNumber}
          </ClinText>
        )
      case AssociatedPatientsColumn.Initials:
        return (
          <ClinText
            className={'patient-initials'}
            fontSize={ClinTheme.fontSizes[2]}
          >
            {patient.initials}
          </ClinText>
        )
      case AssociatedPatientsColumn.DOB:
        return (
          <ClinText className={'patient-dob'} fontSize={ClinTheme.fontSizes[2]}>
            {patient.dateOfBirth
              ? DateTime.fromISO(patient.dateOfBirth, {
                  zone: 'utc'
                })?.toLocaleString()
              : 'N/A'}
          </ClinText>
        )
      case AssociatedPatientsColumn.ProgramName:
        return (
          <ClinText
            className={'program-name'}
            fontSize={ClinTheme.fontSizes[2]}
          >
            {patient.programName}
          </ClinText>
        )
      case AssociatedPatientsColumn.HospitalRefNumber:
        return (
          <ClinText
            className={'hospital-ref-number'}
            fontSize={ClinTheme.fontSizes[2]}
          >
            {patient.hospitalRefNo || 'N/A'}
          </ClinText>
        )
      case AssociatedPatientsColumn.Status:
        return (
          <ClinText
            className={'patient-status'}
            fontWeight={ClinTheme.fontWeights.bold}
            color={getStatusColor(patient.patientStatus)}
            fontSize={ClinTheme.fontSizes[2]}
          >
            {patient.patientStatus}
          </ClinText>
        )
    }
  }

  const isPatientsExist = patients && patients.length > 0

  return (
    <>
      <Row>
        <Col>
          <TableFilterSearch
            title={t('glossary:advanced_filtering')}
            filterGroups={[
              {
                title: t('glossary:status'),
                titleSelected: t(
                  'physician_details:status_filter_group_title',
                  {
                    filtersSelectedCounter: selectedPatientStatusFilters?.length
                  }
                ),
                filters: patientStatusFilters,
                selectedFilters: selectedPatientStatusFilters
              },
              {
                title: t('glossary:program_other'),
                titleSelected: t(
                  'physician_details:status_filter_group_title',
                  {
                    filtersSelectedCounter: selectedProgramFilters?.length
                  }
                ),
                filters: programFilters,
                selectedFilters: selectedProgramFilters
              }
            ]}
            handleSelectFilterGroup={(filter, filterGroupTitle) =>
              handleFilterToggle && handleFilterToggle(filter, filterGroupTitle)
            }
            handleClearAllFilters={handleClearFilters}
            searchInput={() => (
              <BasicSearch
                placeholder={t('physician_details:patients_search_placeholder')}
                initialQuery={initialQuery}
                handleOnChange={(value) =>
                  handleOnSearch && handleOnSearch(value)
                }
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {isLoading && (
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          )}
          {!isLoading && patients && (
            <>
              <ClinTable
                tableHeader={() =>
                  columns.map(
                    (column: AssociatedPatientsColumn, index: number) => (
                      <ClinTableHeaderCell key={index}>
                        {getSortFieldForColumn(column) === undefined ? (
                          <ClinTableOrderToggle hideSortIcon={true}>
                            {t(ColumnRecord[column].transKey)}
                          </ClinTableOrderToggle>
                        ) : (
                          <ClinTableOrderToggle
                            sortDirection={
                              selectedSortColumn === column
                                ? selectedSortDirection
                                : SortDirectionType.None
                            }
                            onClick={() =>
                              handleToggleSort && handleToggleSort(column)
                            }
                          >
                            {t(ColumnRecord[column].transKey)}
                          </ClinTableOrderToggle>
                        )}
                      </ClinTableHeaderCell>
                    )
                  )
                }
              >
                {isPatientsExist ? (
                  patients.map((patient: PatientSummaryDto, rowIndex) => (
                    <ClinTableRow key={rowIndex}>
                      {columns.map(
                        (column: AssociatedPatientsColumn, index: number) => (
                          <ClinTableBodyCell
                            key={index}
                            onCellClick={() => {
                              handleRowClicked &&
                                handleRowClicked(patient.patientId)
                            }}
                            onCellEnter={() =>
                              handleRowClicked &&
                              handleRowClicked(patient.patientId)
                            }
                          >
                            {getCellContent(column, patient)}
                          </ClinTableBodyCell>
                        )
                      )}
                    </ClinTableRow>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columns.length}>
                      <NoPatients />
                    </td>
                  </tr>
                )}
              </ClinTable>
            </>
          )}
        </Col>
      </Row>
      <ClinSpacer />
      <Row>
        <Col>
          {!isLoading &&
          isPatientsExist &&
          pagination &&
          pagination.total > minRowsPerPage ? (
            <ClinPagination
              currentPage={getCurrentPage(pagination)}
              totalPages={getTotalPages(pagination)}
              pageSize={pagination.take}
              handlePageSelected={(pageIndex) =>
                handlePageClicked && handlePageClicked(pageIndex)
              }
              handlePageSizeChange={handlePageSizeChange}
            />
          ) : undefined}
        </Col>
      </Row>
    </>
  )
}
