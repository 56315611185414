import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import {
  Overlay,
  DrawerContainer,
  Header,
  StyledClinButtonIcon,
  ANIMATION_DURATION
} from './ClinDrawer.styles'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'

interface IClinDrawerProps {
  open: boolean
  width?: number
  onClose: () => void
  children?: React.ReactNode
}

export const ClinDrawer: React.FC<IClinDrawerProps> = ({
  open,
  width,
  onClose,
  children
}) => {
  const [show, setShow] = useState(open)
  const [internalOpen, setInternalOpen] = useState(open)

  useEffect(() => {
    if (open) {
      setShow(true)
      setTimeout(() => setInternalOpen(true), 20)
    } else {
      setInternalOpen(false)
      const timer = setTimeout(() => setShow(false), ANIMATION_DURATION)
      return () => clearTimeout(timer)
    }
  }, [open])

  if (!show) return null

  return ReactDOM.createPortal(
    <>
      <Overlay isOpen={internalOpen} onClick={onClose} />
      <DrawerContainer isOpen={internalOpen} width={width}>
        <Header>
          <StyledClinButtonIcon
            iconName={ClinIconPathName.Cross}
            iconFill={ClinTheme.colors.darkGrey}
            onClick={onClose}
          />
        </Header>
        {children}
      </DrawerContainer>
    </>,
    document.body
  )
}
