import styled from 'styled-components'

import { ClinTheme } from '../../../../ClinTheme'
import { mediaQuery } from '../../../../utils/mediaQuery'

const tabletUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledProductHeader = styled.div({
  paddingBottom: ClinTheme.space[3],
  marginBottom: ClinTheme.space[5],
  borderBottom: `solid 1px ${ClinTheme.colors.midGrey}`,
  [`.clin-button`]: {
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[2]}px`
  },
  [`&.productHeaderAus`]: {
    paddingBottom: 0
  },
  [mediaQuery(tabletUpBreakpoint)]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

interface IProductCellProps {
  /** Css justify-content value */
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit'
}
export const StyledProductCell = styled.div<IProductCellProps>(
  {
    alignItems: 'center',
    [mediaQuery(tabletUpBreakpoint)]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  ({ justifyContent }: IProductCellProps) => ({
    display: justifyContent ? 'flex' : 'initial',
    justifyContent
  })
)
