import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'

export interface StyledClinTableOrderToggleProps {
  /** Hide the toggle icon altogether */
  hideSortIcon?: boolean
  isUppercase?: boolean
}
export const StyledClinTableOrderToggle =
  styled.div<StyledClinTableOrderToggleProps>(
    {
      display: 'inline-flex',
      alignItems: 'center !important',
      userSelect: 'none',
      height: ClinTheme.space[6],
      color: ClinTheme.colors.primary,
      fontSize: ClinTheme.fontSizes[1],
      fontWeight: ClinTheme.fontWeights.medium,
      fontFamily: ClinTheme.fonts.body,
      letterSpacing: ClinTheme.letterSpacing[1],
      transition: '.2s linear box-shadow',
      ':focus': {
        outline: 'none',
        boxShadow: `0 0 0 2px ${ClinTheme.colors.midGrey}`
      }
    },
    ({ hideSortIcon, isUppercase }) => ({
      cursor: hideSortIcon ? 'default' : 'pointer',
      isUppercase: isUppercase ? 'uppercase' : 'none'
    })
  )
