import config from '../ConfigProvider'
import AnalyticsService from './AnalyticsService'
import { ApplicationInsightsProvider } from './ApplicationInsightsProvider'
import { HubspotProvider } from './HubspotProvider'
import { MixPanelProvider } from './MixPanelProvider'

/**
 * initAnalytics
 * Helper method to instantiate Analytics Service with all in the included providers
 * Be sure to call initProviders()!
 */
const initAnalytics = (): AnalyticsService => {
  const enableDebugLogging = config.environment === 'local' // enable while developing to show analytics console logs
  const sendTrackingData = config.enableAnalytics === 'true'

  const analyticsService = new AnalyticsService(
    enableDebugLogging,
    sendTrackingData
  )

  const mp = new MixPanelProvider(enableDebugLogging, sendTrackingData)
  mp.tokenId = config.mixPanelToken
  analyticsService.registerProvider('MixPanel', mp)

  const aip = new ApplicationInsightsProvider(
    enableDebugLogging,
    sendTrackingData
  )
  aip.tokenId = config.appInsightsInstrumentationKey
  analyticsService.registerProvider('ApplicationInsights', aip)

  const hubspotProvider = new HubspotProvider(
    enableDebugLogging,
    sendTrackingData
  )
  hubspotProvider.tokenId = config.hubspotAppId
  analyticsService.registerProvider('HubspotProvider', hubspotProvider)

  analyticsService.initProviders()
  return analyticsService
}

const analyticsServiceSingleton = initAnalytics()
export default analyticsServiceSingleton
