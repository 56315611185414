import styled from 'styled-components'

import { ClinTheme } from '../../../../ClinTheme'
import { ClinButton } from '../../../../components/ClinButton'

export const StyledProductInformation = styled.div`
  width: 100%;
  text-align: left;
`

export const StyledProductInformationContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px 18px;
  align-items: flex-start;
`

export const StyledItemDetails = styled.div<{ minWidth?: string }>`
  width: ${(props) => props.minWidth || '160px'};
  flex: 0 0 ${(props) => props.minWidth || '160px'};
  min-width: ${(props) => props.minWidth || '160px'};
  text-align: left;
`

export const StyledDocumentsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;

  span {
    font-weight: ${ClinTheme.fontWeights.normal};
    margin-top: 8px;
    display: block;
    height: fit-content;
  }
`

export const StyledClinButton = styled(ClinButton)({
  fontSize: ClinTheme.fontSizes[2],
  padding: '8px 24px'
})
