import { t } from 'i18next'
import React from 'react'

import {
  StyledModalContent,
  StyledCancelButton,
  StyledEnrollButton,
  StyledHeaderText,
  StyledSuggestionText,
  StyledButtonContainer
} from './PatientEnrollModal.styles'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinModal } from '../../../../components/ClinModal'

interface IPatientEnrollModalProps {
  isOpen: boolean
  handleEnroll: () => void
  handleClose: () => void
}

export const PatientEnrollModal: React.FC<IPatientEnrollModalProps> = ({
  isOpen,
  handleEnroll,
  handleClose
}) => {
  return (
    <ClinModal
      isOpen={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      height="auto"
      padding={`${ClinTheme.space[5]}px`}
      borderRadius="4px"
    >
      <StyledModalContent>
        <StyledHeaderText as="div">
          {t('patient_detail:patient_enroll_modal.modal_title')}
        </StyledHeaderText>
        <StyledSuggestionText as="div">
          {t('patient_detail:patient_enroll_modal.modal_description')}
        </StyledSuggestionText>
        <StyledButtonContainer>
          <StyledCancelButton
            variant="purpleSecondaryNarrow"
            onClick={handleClose}
          >
            {t('patient_detail:patient_enroll_modal.cancel_button_text')}
          </StyledCancelButton>
          <StyledEnrollButton variant="purpleNarrow" onClick={handleEnroll}>
            {t('patient_detail:patient_enroll_modal.enroll_button_text')}
          </StyledEnrollButton>
        </StyledButtonContainer>
      </StyledModalContent>
    </ClinModal>
  )
}
