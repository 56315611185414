import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'

export interface IOnboardingLineItemStyles {
  /** Example prop please replace or remove! */
  exampleOnboardingLineItemStyle?: string
}

export const StyledOnboardingLineItem = styled.div<IOnboardingLineItemStyles>(
  {
    margin: 4,
    borderBottom: `1px solid ${ClinTheme.colors.grey}`,
    padding: '14px 0 17px',
    fontSize: `${ClinTheme.fontSizes[2]}px`
  },
  ({ exampleOnboardingLineItemStyle }: IOnboardingLineItemStyles) => ({
    exampleOnboardingLineItemStyle
  })
)
