import { AxiosError, AxiosResponse } from 'axios'
import download from 'downloadjs'
import _ from 'lodash'
import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'

import { Basket, BasketViewState } from './Basket'
import { ClinTheme } from '../../ClinTheme'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { isAusUser } from '../../constants'
import { CountryAlphaCodes } from '../../constants/countryAlpha2Codes'
import { useAppContext } from '../../context/app'
import {
  ActionType,
  ActionUpdateBasketItemQuantity,
  BasketStatus,
  useBasket
} from '../../context/basket'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import useChangeBackgroundColor from '../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  cancelDraftOrder,
  cancelUpdateBasketItemQuantity,
  downloadDocumentById,
  getCurrentBasket,
  getPriceAndAvailabilityForSku,
  removeHoldDocumentToBasketItem,
  removeItemFromBasket,
  setHoldDocumentToBasketItem,
  updateBasketItemQuantity
} from '../../services/ApiService'
import { useAllHoldsInformation } from '../../services/Holds/holds'
import { IHoldDetails } from '../../services/Holds/holds.types'
import { useBasketHasAtLeastOneUnavailableItem } from '../../services/PortfolioJourneys/PortfolioJourney.hooks'
import {
  BasketDto,
  BasketItemDocumentDto,
  BasketItemDto,
  ItemDocumentDto,
  PriceAndAvailabilityDto,
  UploadedDocumentDto
} from '../../types/swaggerTypes'
import { handleBasketError } from '../../utils/basketUtils'
import { getFilenameFromUrl } from '../../utils/getFilenameFromUrl'
import { validateQuantity } from '../../utils/validateQuantity'

export interface IQuantityError {
  basketItemId: string
  message: string
}

// Helper functions to reduce complexity
const createBasketErrorHandler = (
  dispatch: any,
  errorMessage: string,
  retryFn?: () => void
) => {
  return (error: any) => {
    const basketError = handleBasketError(error, undefined, retryFn)
    if (!basketError) {
      dispatch(
        createAnnounceEvent(AnnounceMode.Error, `${errorMessage} ${error}`)
      )
    }
  }
}

export const BasketContainer: FunctionComponent<RouteComponentProps> = ({
  history
}) => {
  const { dispatch, defaultShippingAddress, portfolioCountryCode } =
    useAppContext()
  const [{ viewBasket }, basketDispatch] = useBasket()
  const [basket, setBasket] = React.useState<BasketDto | undefined>()
  const [basketViewState, setBasketViewState] = React.useState<BasketViewState>(
    BasketViewState.Loading
  )
  const [agreedTandCs, setAgreedTandCs] = React.useState<boolean>()
  const [hasDownloadedSafetyLetter, setHasDownloadedSafetyLetter] =
    React.useState<boolean>(false)
  const [isUpdatingQuantity, setIsUpdatingQuantity] = React.useState<boolean>()
  const [quantityErrors, setQuantityErrors] = React.useState<IQuantityError[]>(
    []
  )

  useChangeBackgroundColor(ClinTheme.colors.lightGrey)
  const holdInformation = useAllHoldsInformation([], viewBasket)

  const isAnItemUnavailable = useBasketHasAtLeastOneUnavailableItem(viewBasket)

  // Extracted function for updating basket item quantity
  const performUpdateItemQuantity = (
    basketItem: BasketItemDto,
    quantity: number
  ) => {
    setIsUpdatingQuantity(true)
    cancelUpdateBasketItemQuantity()

    updateBasketItemQuantity(basketItem.basketItemId, quantity, basketItem.sku)
      .then((response) => {
        const updateBasketItem = response.data
        basketDispatch({
          type: ActionType.UpdateItemQuantity,
          basketItem: updateBasketItem,
          quantity: updateBasketItem.quantity
        } as ActionUpdateBasketItemQuantity)
        setIsUpdatingQuantity(false)
      })
      .catch(
        createBasketErrorHandler(
          dispatch,
          'An error occurred updating an item quantity.',
          () => handleChangeQuantity(basketItem, quantity, true)
        )
      )
  }

  const updateItemQuantityDebounced = useCallback(
    _.debounce((basketItem: BasketItemDto, quantity: number) => {
      if (basketViewState === BasketViewState.Loading) {
        cancelUpdateBasketItemQuantity()
      }
      performUpdateItemQuantity(basketItem, quantity)
    }, 500),
    [basketViewState]
  )

  const validateQuantityAndManageError = (
    basketItem: BasketItemDto,
    quantity: number
  ): boolean => {
    const errMsg = validateQuantity(
      quantity,
      basketItem.skuCatalogItem.item.minimumOrderQuantity ?? 1,
      basketItem.skuCatalogItem.item.maximumOrderQuantity,
      basketItem.skuCatalogItem.item.incrementalOrderQuantity
    )
    if (errMsg) {
      setQuantityErrors([
        ...quantityErrors,
        { basketItemId: basketItem.basketItemId, message: errMsg }
      ])
      return false
    }
    setQuantityErrors([
      ...quantityErrors.filter(
        (err) => err.basketItemId !== basketItem.basketItemId
      )
    ])
    return true
  }

  const handleChangeQuantity = (
    basketItem: BasketItemDto,
    quantity: number,
    isRetry?: boolean
  ) => {
    if (isRetry) {
      updateItemQuantityDebounced(basketItem, quantity)
      return
    }

    if (
      basketViewState !== BasketViewState.Loading &&
      validateQuantityAndManageError(basketItem, quantity)
    ) {
      updateItemQuantityDebounced(basketItem, quantity)
    }
  }
  const handleRemoveItem = (basketItem: BasketItemDto) => {
    setBasketViewState(BasketViewState.Loading)
    removeItemFromBasket(basketItem.basketItemId)
      .then((response) => {
        if (response.status === 204) {
          basketDispatch({
            type: ActionType.RemoveBasketItem,
            basketItem
          })
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.BasketRemoveItem,
            {
              sku: basketItem.sku,
              quantity: basketItem.quantity
            }
          )
        }
      })
      .catch(
        createBasketErrorHandler(
          dispatch,
          'An error occurred removing an item from your basket.',
          () => handleRemoveItem(basketItem)
        )
      )
      .finally(() => setBasketViewState(BasketViewState.Basket))
  }

  // Extracted function for checkout navigation
  const navigateToCheckout = () => {
    history.push('/checkout')
  }

  const handleCancelDraftOrder = async () => {
    try {
      const response = await cancelDraftOrder()
      if (response.status === 200) {
        navigateToCheckout()
      }
    } catch (error) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Error,
          `An error occurred cancelling checkout. ${error}`
        )
      )
    }
  }

  const handleProceedToCheckout = useCallback(async () => {
    // For Australian users redirect to one step checkout page
    if (portfolioCountryCode === CountryAlphaCodes.Australia) {
      history.push({
        pathname: '/one-step-checkout'
      })
      return
    }

    try {
      const response = await getCurrentBasket()
      // If there is a checkout in progress
      if (response?.data.state === BasketStatus.CheckingOut.toString()) {
        // Cancel it
        await handleCancelDraftOrder()
      } else {
        navigateToCheckout()
      }
    } catch (error) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Error,
          `An error occurred proceeding to checkout. ${error}`
        )
      )
    }
  }, [history, portfolioCountryCode, dispatch])

  const handleDeclarationCancel = () => {
    setAgreedTandCs(false)
    setHasDownloadedSafetyLetter(false)
    setBasketViewState(BasketViewState.Basket)
  }

  const handleDeclarationSelect = (agreed: boolean) => {
    setAgreedTandCs(agreed)
  }

  const handleSafetyLetterDownloadSelect = (agreed: boolean) => {
    setHasDownloadedSafetyLetter(agreed)
  }

  const handleDeclarationSubmit = () => {
    if (agreedTandCs && defaultShippingAddress) {
      setBasketViewState(BasketViewState.Submitting)
      history.push('/checkout/delivery')
    }
  }

  const handleProceed = () => {
    history.push('/checkout')
  }

  // Helper function to process holds
  const processHolds = (result: PriceAndAvailabilityDto) => {
    result.holds ??= []
    return {
      ...result,
      holds: result.holds.map((hold) => ({
        ...hold,
        holdDocuments: hold.holdDocuments ?? []
      }))
    }
  }

  // Helper function to handle price and availability error
  const handlePriceAndAvailabilityError = (error: any) => {
    dispatch(
      createAnnounceEvent(
        AnnounceMode.Error,
        `An error occurred fetching your basket. ${error}`
      )
    )
  }

  // Extracted function for getting price and availability
  const getPriceAndAvailability = async () => {
    if (!basket || !defaultShippingAddress?.addressId) return

    try {
      const skus = basket.items.map((item) => item.sku)
      const pricingCalls = skus.map((sku) =>
        getPriceAndAvailabilityForSku(
          sku,
          defaultShippingAddress.addressId
        ).then((r) => r.data)
      )

      const results = await Promise.all(pricingCalls)
      const processedResults = results.map(processHolds)

      basketDispatch({
        type: ActionType.SetCurrentBasket,
        basket: basket,
        priceAndAvailability: processedResults,
        countryCode: portfolioCountryCode
      })
      setBasketViewState(BasketViewState.Basket)
    } catch (error) {
      handlePriceAndAvailabilityError(error)
    }
  }

  // Extracted function for file upload handling
  const handleFileUploadedForBasketItem = (
    basketItemId: string,
    document: UploadedDocumentDto,
    holdDetails: IHoldDetails,
    basketItemDocumentId?: string | null
  ) => {
    if (!holdDetails.requiredDocumentName || !holdDetails.holdDto?.holdName) {
      console.warn(
        'handleFileUploadedForBasketItem - required document or holdName missing!'
      )
      return
    }

    const documentForBasketItem: BasketItemDocumentDto = {
      basketItemDocumentId: basketItemDocumentId || null,
      basketItemId,
      uploadedDocumentId: document.uploadedDocumentId,
      uploadedDocument: document,
      holdName: holdDetails.holdDto.holdName,
      documentType: holdDetails.requiredDocumentName
    }

    setHoldDocumentToBasketItem(documentForBasketItem)
      .then((response) => {
        basketDispatch({
          type: ActionType.UpdateBasketItemDocuments,
          basketItemId: basketItemId,
          basketItemDocuments: response.data.documents
        })
      })
      .catch(
        createBasketErrorHandler(
          dispatch,
          'There was an error associating the file with the hold.'
        )
      )
  }

  // Extracted function for removing file from hold
  const handleRemoveFileForHoldAsync = async (
    holdDetails: IHoldDetails,
    basketItemId: string
  ) => {
    const targetViewBasketItem = viewBasket?.items.find(
      (d) => d.basketItemId === basketItemId
    )

    if (!targetViewBasketItem) return

    targetViewBasketItem.documents.forEach((targetDocument) => {
      if (targetDocument?.uploadedDocumentId) {
        removeHoldDocumentToBasketItem(
          basketItemId,
          targetDocument?.uploadedDocumentId
        )
          .then((response) => {
            basketDispatch({
              type: ActionType.UpdateBasketItemDocuments,
              basketItemId: basketItemId,
              basketItemDocuments: response.data.documents,
              remove: true
            })
          })
          .catch(
            createBasketErrorHandler(
              dispatch,
              'There was an error removing the file from the hold.'
            )
          )
      }
    })
  }

  // Function to remove a specific file from hold
  const handleRemoveSpecificFileForHoldAsync = (
    item: BasketItemDocumentDto
  ) => {
    if (!item.uploadedDocumentId) return

    removeHoldDocumentToBasketItem(item.basketItemId, item.uploadedDocumentId)
      .then((response) => {
        basketDispatch({
          type: ActionType.UpdateBasketItemDocuments,
          basketItemId: item.basketItemId,
          basketItemDocuments: response.data.documents,
          remove: false
        })
      })
      .catch(
        createBasketErrorHandler(
          dispatch,
          'There was an error removing the file from the hold.'
        )
      )
  }

  // Function to download safety letter
  const handleDownloadSafetyLetter = () => {
    if (!basket) return

    // Find safety letter from documents
    const safetyLetterDocs: ItemDocumentDto[] = []
    const hasSafetyLetter = (fileName: string): boolean => {
      return (
        fileName.toLowerCase().includes('mhra saftey letter') ||
        fileName.toLowerCase().includes('mhra safety letter')
      )
    }

    // Collect all safety letter documents
    basket.items.forEach((item) =>
      item.skuCatalogItem.documents.forEach((d) => {
        if (d.documentType && hasSafetyLetter(d.documentType)) {
          safetyLetterDocs.push(d)
        }
      })
    )

    // Download all of them
    if (safetyLetterDocs.length > 0) {
      safetyLetterDocs.forEach((documentItem) => {
        if (!documentItem.generatedId) return

        downloadDocumentById(documentItem.generatedId)
          .then((response) => {
            if (documentItem.documentType && documentItem.documentUrl) {
              download(
                response.data,
                getFilenameFromUrl(documentItem.documentUrl),
                documentItem.documentType
              )
              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.DocumentDownload,
                {
                  ...documentItem
                }
              )
            }
          })
          .catch((error: AxiosError) => {
            window.scrollTo(0, 0)
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error downloading your document. ${error}`
              )
            )
          })
      })
    }
  }

  // Effect to fetch current basket
  useEffect(() => {
    if (!basket) {
      getCurrentBasket()
        .then((response: AxiosResponse<BasketDto> | null) => {
          // Update badge value
          if (response?.data) {
            response.data.items ??= []
            response.data.orders ??= []
            setBasket(response.data)
            basketDispatch({
              type: ActionType.UpdateBasket,
              basket: { data: response.data, isLoading: false }
            })
          }
        })
        .catch((error: AxiosError) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the basket. ${error}`
            )
          )
        })
    }
  }, [basket, dispatch, basketDispatch])

  // Effect to handle checkout state and get pricing
  useEffect(() => {
    // If a basket exists but is in checkout mode go to checkout
    if (viewBasket && viewBasket.state === BasketStatus.CheckingOut) {
      if (isAusUser(portfolioCountryCode)) {
        history.push('/one-step-checkout')
      }
    }

    if (viewBasket && defaultShippingAddress) {
      // Get pricing
      getPriceAndAvailability()
    }
  }, [basket, defaultShippingAddress])

  return (
    <Basket
      showDebug={false}
      countryCode={portfolioCountryCode}
      basketViewState={basketViewState}
      basket={viewBasket}
      holdInformation={holdInformation}
      quantityErrors={quantityErrors}
      isAnItemUnavailable={isAnItemUnavailable}
      isUpdatingQuantity={isUpdatingQuantity}
      hasAgreedToTerms={agreedTandCs}
      hasDownloadedSafetyLetter={hasDownloadedSafetyLetter}
      handleChangeItemQuantity={handleChangeQuantity}
      handleRemoveItem={handleRemoveItem}
      handleProceedToCheckout={handleProceedToCheckout}
      handleDeclarationCancel={handleDeclarationCancel}
      handleDeclarationSelect={handleDeclarationSelect}
      handleDeclarationSubmit={handleDeclarationSubmit}
      handleFileUploadedForBasketItem={handleFileUploadedForBasketItem}
      handleRemoveFileForHoldAsync={handleRemoveFileForHoldAsync}
      handleRemoveSpecificFileForHoldAsync={
        handleRemoveSpecificFileForHoldAsync
      }
      handleSafetyLetterDownloadSelect={handleSafetyLetterDownloadSelect}
      handleDownloadSafetyLetter={handleDownloadSafetyLetter}
    />
  )
}
