import React from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { OrderColumn, orderColumns } from './OrdersContainerOld'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinAnnounceBar } from '../../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinButton } from '../../../../components/ClinButton'
import {
  INewFeature,
  NewFeatureElements
} from '../../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipContainer } from '../../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import { ClinPageContentFrame } from '../../../../components/ClinPageContentFrame'
import { ClinPagination } from '../../../../components/ClinPagination'
import {
  IPagination,
  getCurrentPage,
  getTotalPages
} from '../../../../components/ClinPagination/ClinPagination.model'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import { ClinSwitch } from '../../../../components/ClinSwitch'
import { SortDirectionType } from '../../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import { OrdersTableOld } from '../../../../features/OrdersTable/old/OrdersTableOld'
import { RecentSkuCardsContainer } from '../../../../features/RecentSkuCards'
import { IBanner } from '../../../../types/IBanner'
import { OrderSummaryDto, CountryDto } from '../../../../types/swaggerTypes'
import {
  StyledOrdersAnnounce,
  StyledOrdersAnnounceText,
  StyledOrdersAnnounceCta,
  StyledMyOrdersToggle
} from '../Orders.styles'

interface IDashboardProps {
  isLoading: boolean
  isMaUser: boolean
  newFeaturesList?: INewFeature[]
  userPreferredName?: string
  orders: OrderSummaryDto[]
  pagination: IPagination
  selectedSortColumn?: OrderColumn
  selectedSortDirection?: SortDirectionType
  selectedTabIndex?: number
  initialQuery?: string
  isEmptySearch: boolean
  supportContact: CountryDto
  showMyOrders: boolean
  handlePageClicked: (selectedPageIndex: number) => void
  handleTabSelected: (selectedTabIndex: number) => void
  handleToggleSort: (selectedColumn: OrderColumn) => void
  handleRowClicked: (selectedOrderId: string) => void
  handleToggleOrderRow: (orderNumber: string) => void
  handlePageSizeChange: (pageSize: number) => void
  handleOnSearch: (query: string) => void
  handleOnSubmit: () => void
  handleMyOrders?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOnChange: (query: string) => void
}

export const OrdersOld: React.FC<IDashboardProps> = ({
  isLoading,
  isMaUser,
  userPreferredName,
  orders,
  pagination,
  newFeaturesList,
  selectedSortColumn,
  selectedSortDirection,
  selectedTabIndex,
  initialQuery,
  isEmptySearch,
  supportContact,
  showMyOrders,
  handlePageClicked,
  handleTabSelected,
  handleToggleSort,
  handleRowClicked,
  handleToggleOrderRow,
  handlePageSizeChange,
  handleOnSearch,
  handleOnSubmit,
  handleMyOrders,
  handleOnChange
}) => {
  const { t } = useTranslation()

  return (
    <ClinPageContentFrame>
      <Row>
        <Col
          sm={12}
          md={10}
          style={{
            marginBottom: ClinTheme.space[4]
          }}
        >
          <ClinText
            variant={TypographyVariant.H2}
            as="h1"
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('orders:title')}
            {userPreferredName ? `, ${userPreferredName}` : ''}
          </ClinText>
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {t('orders:description')}
          </ClinText>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[1]} />
      <StyledMyOrdersToggle>
        <span
          className="tooltip-span"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start'
          }}
        >
          <span className="tooltip-span" style={{ marginRight: '10px' }}>
            {newFeaturesList?.find(
              (x) => x.id === NewFeatureElements.SwitchButtonFeature
            ) && (
              <ClinNewFeatureTooltipContainer
                feature={
                  newFeaturesList.find(
                    (x) => x.id === NewFeatureElements.SwitchButtonFeature
                  ) as INewFeature
                }
                openTooltipInitially={true}
              ></ClinNewFeatureTooltipContainer>
            )}
          </span>

          <span style={{ display: 'flex' }} className="tooltip-span">
            <ClinText as="div" variant={TypographyVariant.H5} marginRight="0px">
              {t('orders:my_orders_button_title')}
            </ClinText>
            <ClinSwitch
              id="my-orders"
              label={t('orders:my_orders_button_label')}
              defaultChecked={showMyOrders}
              flexDirection="row-reverse"
              onChange={(event) => handleMyOrders && handleMyOrders(event)}
            />
          </span>
        </span>
      </StyledMyOrdersToggle>
      <ClinSpacer height={ClinTheme.space[3]} zIndex="-5" />
      <OrdersTableOld
        isLoading={isLoading}
        orders={orders}
        columns={orderColumns}
        isEmptySearch={isEmptySearch}
        hideSortToggleForColumnIndices={[3, 5]}
        selectedSortColumn={selectedSortColumn}
        selectedSortDirection={selectedSortDirection}
        selectedTabIndex={selectedTabIndex}
        initialQuery={initialQuery}
        supportContact={supportContact}
        isMaUser={isMaUser}
        handleToggleSort={(event) => {
          const column = orderColumns.find((item) => {
            return item.viewName === event.currentTarget.textContent
          })
          column && handleToggleSort(column)
        }}
        handleRowClicked={(orderId) => handleRowClicked(orderId)}
        handleTabSelected={(tabIndex) => handleTabSelected(tabIndex)}
        handleToggleOrderRow={(orderNumber) =>
          handleToggleOrderRow(orderNumber)
        }
        handleOnChange={handleOnChange}
        handleOnSearch={handleOnSearch}
        handleOnSubmit={handleOnSubmit}
      />
      <ClinSpacer height={ClinTheme.space[7]} />
      <Row>
        <Col>
          {!isLoading &&
            orders &&
            orders.length > 0 &&
            pagination &&
            pagination.total > 0 && (
              <ClinPagination
                currentPage={getCurrentPage(pagination)}
                totalPages={getTotalPages(pagination)}
                pageSize={pagination.take}
                handlePageSelected={(pageIndex) => handlePageClicked(pageIndex)}
                handlePageSizeChange={handlePageSizeChange}
              />
            )}
        </Col>
      </Row>
      {!isMaUser && (
        <>
          <ClinSpacer hasBorder={true} height={ClinTheme.space[7]} />
          <RecentSkuCardsContainer />
        </>
      )}
      <ClinSpacer />
    </ClinPageContentFrame>
  )
}
