import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'

export interface ISectionTitleBarStyles {
  /** Example prop please replace or remove! */
  exampleSectionTitleBarStyle?: string
}

export const StyledSectionTitleBar = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: ClinTheme.space[4],
  width: '100%',
  fontSize: ClinTheme.fontSizes[1],
  textTransform: 'uppercase',
  background: ClinTheme.colors.primary,
  color: ClinTheme.colors.white,
  boxSizing: 'border-box'
})
