import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'
const desktopBreakpoint = ClinTheme.breakpoints[3]

/**
 * A basic wrapper around react-router Link component to remove underline
 */
export const StyledDropDownDetail = styled.div({
  padding: `${ClinTheme.space[3]}px ${ClinTheme.space[5]}px`,
  borderBottom: `solid ${ClinTheme.borderWidths[1]}px ${ClinTheme.colors.midGrey}`
})

export const StyledHeader = styled.div({
  borderBottom: `solid 1px ${ClinTheme.colors.lightGrey}`,

  '.user-name': {
    fontSize: ClinTheme.fontSizes[0],
    lineHeight: ClinTheme.lineHeights.body
  },

  '.institution-name': {
    fontSize: ClinTheme.fontSizes[0],
    fontWeight: ClinTheme.fontWeights.medium,
    whiteSpace: 'normal',
    lineHeight: ClinTheme.lineHeights.body,
    marginBottom: ClinTheme.space[1]
  }
})

export const StyledClinHeader = styled.div({
  backgroundColor: ClinTheme.colors.primaryDark,

  '.user-name': {
    fontSize: ClinTheme.fontSizes[0],
    lineHeight: ClinTheme.lineHeights.body
  },

  '.institution-name': {
    fontSize: ClinTheme.fontSizes[0],
    fontWeight: ClinTheme.fontWeights.medium,
    whiteSpace: 'normal',
    lineHeight: ClinTheme.lineHeights.body
  }
})

export const StyledNavItemInner = styled.span({
  display: 'inline',

  [mediaQuery(desktopBreakpoint)]: {
    display: 'none'
  }
})

export const StyledFeatureHighlightWrapper = styled.div({
  position: 'absolute',
  top: '11px',
  left: '-19px'
})
