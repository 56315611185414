import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

const mediumUpBreakpoint = ClinTheme.breakpoints[1]
const largeUpBreakpoint = ClinTheme.breakpoints[2]

export const StyledSupportLinks = styled.div({
  ul: {
    paddingLeft: 0,

    li: {
      marginBottom: ClinTheme.space[2],
      listStyle: 'none',
      paddingLeft: 0
    }
  }
})

export const StyleSupportLinkCards = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginRight: `-${ClinTheme.space[7]}px`,
  width: '100%',

  [mediaQuery(mediumUpBreakpoint)]: {
    marginLeft: `-${ClinTheme.space[2]}px`
  }
})

export const StyleSupportLinkCard = styled.div({
  flex: '0 0 100%',
  boxSizing: 'border-box',
  marginBottom: ClinTheme.space[3],

  [mediaQuery(mediumUpBreakpoint)]: {
    padding: ClinTheme.space[2],
    flex: '0 0 50%',
    marginBottom: 0
  },
  [mediaQuery(largeUpBreakpoint)]: {
    flex: '0 0 33%'
  }
})

export interface IStyledVideoSection {
  backgroundColor?: string
}

export const StyledVideoSection = styled.div<IStyledVideoSection>(
  {
    paddingTop: ClinTheme.space[7],
    paddingBottom: ClinTheme.space[7],
    width: '100%'
  },
  ({ backgroundColor }) => ({
    backgroundColor: backgroundColor ?? 'none'
  })
)
