import styled, { keyframes } from 'styled-components'

import { IModalStyles } from './ClinModal'
import { ClinTheme } from '../../ClinTheme'
import { IIndexable } from '../../types'
import { mediaQuery, mediaQueryDown } from '../../utils/mediaQuery'

const largeBreakpoint = ClinTheme.breakpoints[2]
const mediumBreakpoint = ClinTheme.breakpoints[1]
const smallBreakpoint = ClinTheme.breakpoints[0]

export const StyledModal = styled.div<IModalStyles>(
  {
    position: 'fixed',
    fontFamily: ClinTheme.fonts.body,
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    overflow: 'auto'
  },
  ({ zIndex }) => ({
    zIndex
  })
)

const lighboxAnimIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
`

export const StyledModalLightbox = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  animation: ${lighboxAnimIn} 0.2s linear;
  background-color: ${ClinTheme.colors.black};
  opacity: 0.4;
`
const wrapperAnimIn = keyframes`
  0% {
    transform: scaleX(0.8) scaleY(0.8)
  }
  100% {
    transform: scaleX(1) scaleY(1)
  }
`

export const StyledModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${wrapperAnimIn} 0.13s ease-out;
  margin: 0 auto;
  height: 100vh;
  max-height: 85%; // reducing height to account for mobile browser buttons at the bottom of the screen

  @media (min-width: ${mediumBreakpoint}px) {
    min-height: 100%; // min-height stops over-flow scroll on ios devices
  }
`

const getMaxWidthStyles = (variant: string) => {
  const config: IIndexable = {
    xs: {
      maxWidth: ClinTheme.breakpoints[0]
    },
    sm: {
      maxWidth: ClinTheme.breakpoints[1]
    },
    md: {
      maxWidth: ClinTheme.breakpoints[2]
    },
    lg: {
      maxWidth: ClinTheme.breakpoints[3]
    },
    xxs: {
      maxWidth: ClinTheme.breakpoints[5]
    },
    none: {
      maxWidth: 'none'
    }
  }
  return config[variant]
}

export const StyledModalContent = styled.div<IModalStyles>(
  {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: ClinTheme.colors.white,
    maxHeight: `calc(100% - ${ClinTheme.space[5]}px)`,
    overflow: 'hidden',
    width: `calc(100% - ${ClinTheme.space[5]}px)`,

    [mediaQuery(largeBreakpoint)]: {
      width: `calc(100% - ${ClinTheme.space[6]}px)`,
      maxHeight: `calc(100% - ${ClinTheme.space[6]}px)`
    }
  },
  ({ maxWidth, height, boxShadow, borderRadius }) => ({
    borderRadius: borderRadius ?? ClinTheme.radii[1],
    boxShadow: boxShadow ?? 'none',
    height,
    ...getMaxWidthStyles(maxWidth!)
  })
)

export const StyledModalHeader = styled.div<{
  removePadding: boolean
}>(
  {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'end'
  },
  ({ removePadding }) => ({
    padding: removePadding ? 0 : ClinTheme.space[4]
  })
)

export const StyledModalBody = styled.div<{
  hasHeader: boolean
  removePadding: boolean
  removeSmallPadding?: boolean
  padding?: string
}>(
  {
    position: 'relative',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch'
  },
  ({ hasHeader, removePadding, removeSmallPadding, padding }) => ({
    paddingTop: hasHeader ? 0 : ClinTheme.space[4],
    padding: removePadding ? 0 : padding ?? ClinTheme.space[2],

    [mediaQueryDown(smallBreakpoint)]: {
      padding: removePadding
        ? 0
        : removeSmallPadding
        ? ClinTheme.space[3]
        : ClinTheme.space[2]
    },
    [mediaQuery(largeBreakpoint)]: {
      padding: removePadding ? 0 : padding ?? ClinTheme.space[4]
    }
  })
)
