import { useEffect } from 'react'

import { useFeatureFlags } from './FeatureFlagContext'
import { FeatureFlagKeys } from './FeatureFlagKeys'
import { isAusGaUser } from '../../constants'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'
import { redirectUser } from '../../utils/redirectToHome'
import { useAppContext } from '../app'

/**
 * Redirects the user based on the status of a feature flag.
 * @param {string} featureFlagKey - The key of the feature flag to check.
 */
const useFeatureFlagRedirect = (featureFlagKey: FeatureFlagKeys) => {
  const { useFeatureFlag } = useFeatureFlags()
  const { userRole, portfolioCountryCode } = useAppContext()
  const featureFlagResult = useFeatureFlag(featureFlagKey)
  const { redirectUrl } = useCheckUnableHomePage()

  // Temporary :'(
  useEffect(() => {
    // Reasoning for hijack: Until AUS GA users get merged like all other roles to include GA+MA view
    if (
      featureFlagKey === FeatureFlagKeys.PatientCentricJourneyPerUser &&
      isAusGaUser(portfolioCountryCode, userRole)
    ) {
      redirectUser(portfolioCountryCode, userRole, undefined, redirectUrl)
    }

    // Otherwise regular logic
    if (!featureFlagResult) {
      redirectUser(portfolioCountryCode, userRole, undefined, redirectUrl)
    }
  }, [featureFlagResult, portfolioCountryCode, userRole])
}

export default useFeatureFlagRedirect
