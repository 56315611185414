import i18next from 'i18next'

import { IHoldDetails } from './holds.types'
import { HoldAnnounceMode } from '../../features/HoldAnnounce/HoldAnnounce'
import { IIndexable } from '../../types'
import { OrgAddressDto } from '../../types/swaggerTypes'
import { convertSnakeToCamelCase } from '../../utils/convertSnakeToCamelCase'
import { getSquidExObjectForKeyAndValue } from '../../utils/getSquidExObjectForKeyAndValue'

export enum HoldType {
  Credit = 'Credit Check Failure', // There is an issue with your account
  CommercialSupply = 'Commercial Supply Hold', // Action
  SpecialClinicalNeed = 'Special Clinical Need Hold',
  SafetyLetter = 'Safety Letter Hold',
  InHouseAuthorisation = 'In-House Authorisation Hold',
  ControlledDrug = 'Controlled Drugs Hold',
  HPRADrug = 'HPRA Hold',
  Reservation = 'Reservation Hold',
  ValidationHold = 'Validation Hold',
  CustomDelivery = 'Shipping Method Hold', // when a user selects custom shipping
  NonSpecific = 'Non-specific Hold', // Catch all
  Compliance = 'Compliance Hold', // Special internal hold for order level announces
  MedicalReview = 'Medical review hold',
  ShippedInTarget = 'Order Shipped In Target System Hold',
  SASHold = 'SAS Hold'
}

/**
 * Hard coded ware house addresses for Controlled Drug and HPRA holds
 */
export const warehouseLookup: Record<string, OrgAddressDto> = {
  BYFLEET: {
    addressId: 'C02',
    address1: 'Clinigen',
    address2: 'Unit 3 Canada Road',
    address3: 'Byfleet',
    address4: '',
    city: '',
    country: 'United Kingdom',
    postalCode: 'KT14 7JL',
    primaryFlag: '',
    state: ''
  },
  LISMORE: {
    addressId: 'IE1',
    address1: 'QM Specials Ltd',
    address2: 'Units 2 & 3',
    address3: 'Lismore Business Park',
    address4: 'Co. Waterford',
    city: 'Lismore',
    country: 'Republic of Ireland',
    postalCode: 'P51 HH76',
    primaryFlag: '',
    state: ''
  }
}

/**
 * Schema as defined in SquidEx for holds
 */
interface ISquidExHoldSchema {
  schema_id: string
  key: string
  title: string
  message: string
  ma_message: string
  pre_order_message?: string
  document_prompt: string
  required_document_name: string
}

/**
 * Get localised holds
 */
export const getHoldForType = (holdType: HoldType): IHoldDetails => {
  // Get all holds from i18n (now its instantiated)
  const squidExHolds = i18next.t('holds:holds', {
    returnObjects: true
  }) as IIndexable<IIndexable<ISquidExHoldSchema>>
  // Lookup the specific value for our key
  const localisedHoldContentSquidEx = getSquidExObjectForKeyAndValue(
    'key',
    holdType.toString(),
    squidExHolds
  ) as IIndexable<string>

  /* TODO: At the moment the code below this comment deletes any pre-existing values that haven't been modified such as title and message
    
  Ideally we would replace this code with: 
  _.mapKeys(localisedHoldContentSquidEx, (v, k) => _.snakeCase(k))

  However the app currently requires camalCase in different areas which causes breaking changes in relation to display document uploads
  so for now i've removed the deleting code and allowing the object to provide both formats to retain required functionality and display holds across the app

  */
  // Convert snakeCase keys back to camel and move value over
  Object.keys(localisedHoldContentSquidEx).forEach((oldKey) => {
    const newKey = convertSnakeToCamelCase(oldKey)
    localisedHoldContentSquidEx[newKey] = localisedHoldContentSquidEx[oldKey]
    // delete localisedHoldContentSquidEx[oldKey] // removed this as a temp fix
  })

  const localisedHoldContent: IHoldDetails =
    localisedHoldContentSquidEx as unknown as IHoldDetails

  switch (holdType) {
    case HoldType.Credit:
      return {
        announceMode: HoldAnnounceMode.Information,
        showPostOrder: true,
        ...localisedHoldContent
      }
    case HoldType.CommercialSupply:
      return {
        announceMode: HoldAnnounceMode.Error,
        requiresDocumentUpload: true,
        ...localisedHoldContent
      }
    case HoldType.CustomDelivery:
      return {
        announceMode: HoldAnnounceMode.Information,
        showPostOrder: true,
        ...localisedHoldContent
      }
    case HoldType.Reservation:
      return {
        announceMode: HoldAnnounceMode.Error,
        showPostOrder: true,
        ...localisedHoldContent
      }
    case HoldType.ControlledDrug:
      return {
        announceMode: HoldAnnounceMode.Error,
        requiresDocumentUpload: true,
        ...localisedHoldContent
      }
    case HoldType.HPRADrug:
      return {
        announceMode: HoldAnnounceMode.Error,
        requiresDocumentUpload: true,
        ...localisedHoldContent
      }
    case HoldType.InHouseAuthorisation:
      return {
        announceMode: HoldAnnounceMode.Error,
        requiresDocumentUpload: true,
        ...localisedHoldContent
      }
    case HoldType.SafetyLetter:
      return {
        announceMode: HoldAnnounceMode.Error,
        requiresDocumentUpload: false,
        ...localisedHoldContent
      }
    case HoldType.SpecialClinicalNeed:
      return {
        announceMode: HoldAnnounceMode.Error,
        requiresDocumentUpload: true,
        ...localisedHoldContent
      }
    case HoldType.ValidationHold:
      return {
        announceMode: HoldAnnounceMode.Error,
        requiresDocumentUpload: true,
        ...localisedHoldContent
      }
    case HoldType.NonSpecific:
      return {
        announceMode: HoldAnnounceMode.Error,
        requiresDocumentUpload: false,
        ...localisedHoldContent
      }
    case HoldType.Compliance:
      return {
        announceMode: HoldAnnounceMode.Error,
        showPostOrder: true,
        ...localisedHoldContent
      }
    case HoldType.MedicalReview:
      return {
        announceMode: HoldAnnounceMode.Error,
        showPostOrder: true,
        ...localisedHoldContent
      }
    case HoldType.ShippedInTarget:
      return {
        announceMode: HoldAnnounceMode.Information,
        showPostOrder: true,
        ...localisedHoldContent
      }
    case HoldType.SASHold:
      return {
        announceMode: HoldAnnounceMode.Error,
        requiresDocumentUpload: true,
        ...localisedHoldContent
      }
  }
}
