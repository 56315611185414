import { AxiosResponse } from 'axios'
import { DateTime } from 'luxon'

import { AnalyticsEvent } from './Analytics'
import {
  getPageNewFeatures,
  newFeatureExpiresInMonths,
  newFeatureLookup,
  newFeatureRealeases
} from '../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.config'
import {
  INewFeature,
  INewFeatureItem,
  INewFeaturesEvents,
  NewFeatureElements
} from '../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { TourViewDto } from '../types/swaggerTypes'
import analyticsServiceSingleton from './Analytics/initAnalytics'
import { setNewFeatureToViewed } from './ApiService'

const filterNewFeatures = (features: TourViewDto[]): INewFeatureItem[] => {
  const userFeatures: INewFeatureItem[] = []
  const activeFeatures = newFeatureLookup //features from config
  const featureElements = features.map((x) => ({
    id: x.element,
    isCancelled: x.isCancelled
  })) //features from bff

  //find group features and see if you have not closed one from the same group
  const activeFeatureGroups = activeFeatures.filter((x) => x.groupId)

  //Dictionary for grouped features
  const groupedFeatures: { [groupId: string]: INewFeatureItem[] } = {}
  activeFeatureGroups.forEach((feature) => {
    if (feature.groupId) {
      if (!groupedFeatures[feature.groupId]) {
        groupedFeatures[feature.groupId] = [] // Initialize array if not exists
      }
      feature.isCancelled = featureElements.find(
        (x) => x.id === feature.id
      )?.isCancelled
      groupedFeatures[feature.groupId].push(feature) // Add feature to the corresponding group
    }
  })

  activeFeatures.forEach((element) => {
    const feature = features.find((x) => x.element === element.id)
    if (
      (!featureElements.find((x) => x.id === element.id) &&
        featureNotViewedYet(element.id, element.version) &&
        shouldDisplayFeatureByDate(element.version)) ||
      (featureElements.find((x) => x.id === element.id) &&
        feature?.viewed === '0001-01-01T00:00:00+00:00' &&
        shouldDisplayFeatureByDate(feature.version) &&
        featureNotViewedYet(feature.element, feature.version))
    ) {
      //check if any of group element is cancelled
      const isCancelledFeature = !!(
        element.groupId &&
        groupedFeatures[element.groupId]?.length &&
        groupedFeatures[element.groupId].find((x) => x.isCancelled)
      )
      userFeatures.push({ id: element.id, isCancelled: isCancelledFeature })
    }
  })

  return userFeatures
}

//we should display only features that are not older than 3 months.
const shouldDisplayFeatureByDate = (version: string): boolean => {
  const featureVersionReleaseDate = newFeatureRealeases.find(
    (x) => x.version === version
  )?.releaseDate
  const today = DateTime.now().toUTC()
  if (featureVersionReleaseDate) {
    return (
      today <
      featureVersionReleaseDate.plus({ month: newFeatureExpiresInMonths })
    )
  }
  return false
}

//this is condition is for removing tooltip that has been seen by user but page was refreshed before we can handle seen event
//this is a fix for situation when user refresh page and he has seen the new feature but we haven't had a time to respond to backend
const featureNotViewedYet = (
  element: string,
  version: string,
  isCancelled?: boolean
): boolean => {
  const savedFeature = localStorage.getItem(`feature-${element}`)
  if (savedFeature === element) {
    setFeatureToViewed(element, version, isCancelled)
      .then(() => {
        addNewFeatureClosedEvent(getNewFeatureEventDescription(element))
        localStorage.removeItem(`feature-${element}`)
      })
      .catch((error: any) => {})
    return false
  } else {
    return true
  }
}

const setFeatureToViewed = async (
  element: string,
  version: string,
  isCancelled?: boolean
): Promise<AxiosResponse> => {
  return setNewFeatureToViewed(element, version, isCancelled)
}

//mixpanel event for displaying new feature
const addNewFeatureDisplayedEvent = (description: string) => {
  analyticsServiceSingleton.trackEvent(AnalyticsEvent.NewFeatureDisplayed, {
    description
  })
}

//mixpanel event for closing new feature
const addNewFeatureClosedEvent = (description: string) => {
  analyticsServiceSingleton.trackEvent(AnalyticsEvent.NewFeatureClosed, {
    description
  })
}

const getNewFeatureEventDescription = (id: string): string => {
  switch (id) {
    case NewFeatureElements.SwitchButtonFeature:
      return INewFeaturesEvents.SwitchButtonFeature
    case NewFeatureElements.BookmarkFeature:
      return INewFeaturesEvents.BookmarkFeature
    case NewFeatureElements.GlobalSearchFeature:
      return INewFeaturesEvents.GlobalSearchFeature
    case NewFeatureElements.TrackingLinkFeature:
      return INewFeaturesEvents.TrackingLinkFeature
    case NewFeatureElements.GlobalSearchNewUserFeature:
      return INewFeaturesEvents.GlobalSearchFeature
    default:
      return ''
  }
}

const getNewFeatureList = (
  pageName: string,
  features: INewFeatureItem[]
): { [id: string]: INewFeature } => {
  const featureList: INewFeature[] = []

  const featureIds = getPageNewFeatures(pageName)

  featureIds.forEach((element) => {
    const feature = features.find((x) => x.id === element)
    if (feature) {
      const featureToAdd = newFeatureLookup.find((x) => x.id === element)
      if (featureToAdd) {
        featureToAdd.isCancelled = feature.isCancelled
        featureList.push(featureToAdd)
      }
    }
  })

  return featureList.reduce((acc, curr) => {
    acc[curr.id] = curr
    return acc
  }, {} as { [id: string]: INewFeature })
}

export {
  filterNewFeatures,
  setFeatureToViewed,
  addNewFeatureDisplayedEvent,
  addNewFeatureClosedEvent,
  getNewFeatureEventDescription,
  getNewFeatureList
}
