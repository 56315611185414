import { ItemDto, OrderDto, OrderLineDto } from '../../types/swaggerTypes'
import { PatientOrderStatus } from '../Patients/PatientDashboard/Patient.model'

export enum OrderStatus {
  Processing = 'Processing', // order is booked
  Submitted = 'Submitted', // order is not booked
  OnHold = ' On hold',
  Cancelled = 'Cancelled',
  Shipped = 'Shipped',
  Reassigned = 'Reassigned'
}

export enum OrderItemType {
  DataLoggers = 'Data Loggers', // Data Loggers - Charge
  NOIAndCDLicenseItems = 'NOI and CD License Items', // NOI and CD License Items – N/A (won’t ever appear on order) - Charge
  Shippers = 'Shippers', // Shippers - Charge
  ProductionComponents = 'Production Components', // Production Components– N/A (won’t ever appear on order) - Charge
  ServiceItems = 'Service Items', // Service Items - Charge
  Equipment = 'Equipment', // Equipment – Charge
  AncillaryEquipment = 'Ancillary Equipment', // Ancillary Equipment - Ancillaries
  ProductionLabels = 'Production Labels', // Production Labels – N/A (won’t ever show) - Charge
  Pharmaceuticals = 'Pharmaceuticals', // Pharmaceuticals - Product
  PostProduction = 'Post Production', // Post Production - Product
  RawMaterials = 'Raw Materials' // Raw Materials - Product
}

export enum LocalStorageOPAKeys {
  DeliveryNote = 'deliveryNote'
}

export const isProductOrderItem = (orderLine: OrderLineDto) => {
  const itemCategoryCode = orderLine.skuCatalogItem?.item.itemCategoryCode
  return (
    itemCategoryCode === OrderItemType.Pharmaceuticals ||
    itemCategoryCode === OrderItemType.PostProduction ||
    itemCategoryCode === OrderItemType.RawMaterials
  )
}

// Extract the comparison logic to reduce cognitive complexity
export const hasAncillaryItemCategory = (orderLine: OrderLineDto): boolean =>
  orderLine.skuCatalogItem?.item.itemCategoryCode ===
  OrderItemType.AncillaryEquipment

export const isAncillaryOrderItem = (orderLine: OrderLineDto) =>
  hasAncillaryItemCategory(orderLine)

export const isProductTemperatureTracker = (item: ItemDto | undefined) =>
  item &&
  item.division === 'CTL' &&
  item.itemCategoryCode === OrderItemType.DataLoggers

const nonChargeItemCatCodes = [
  OrderItemType.AncillaryEquipment.toString(),
  OrderItemType.Pharmaceuticals.toString(),
  OrderItemType.PostProduction.toString(),
  OrderItemType.RawMaterials.toString()
]

export const isChargeLineItem = (item: ItemDto | undefined) => {
  return (
    item &&
    item.itemCategoryCode &&
    nonChargeItemCatCodes.indexOf(item.itemCategoryCode) === -1
  )
}

/**
 * Sum up charges from line items
 * @param order
 */
export const getTotalCharges = (order: OrderDto) => {
  let sum = 0
  order.lines.forEach((line) => {
    const item: ItemDto | undefined = line.skuCatalogItem?.item
    if (isChargeLineItem(item)) {
      sum += line.totals.subTotal
    }
  })
  return sum
}

export const isOrderCancellable = (order: OrderDto) =>
  order?.lines.reduce(
    (previousValue, currentValue, currentIndex, array): boolean => {
      return currentValue.status === OrderStatus.Cancelled ||
        currentValue.status === OrderStatus.Shipped
        ? false
        : previousValue
    },
    true
  )

export const getOrderItems = (order: OrderDto | undefined) => {
  const orderedItems: OrderLineDto[] = []
  order?.lines.forEach((orderLine) => {
    if (
      (isProductOrderItem(orderLine) || isAncillaryOrderItem(orderLine)) &&
      (orderLine.status === OrderStatus.Cancelled ||
        orderLine.quantities.ordered > 0 ||
        orderLine.status === PatientOrderStatus.MedicalReviewDeclined ||
        orderLine.status === PatientOrderStatus.HealthAuthorityDeclined)
    ) {
      orderedItems.push(orderLine)
    }
  })
  return orderedItems
}

export const getReassignedOrderItems = (order: OrderDto | undefined) => {
  const reasignedItems: OrderLineDto[] = []
  order?.lines.forEach((orderLine) => {
    if (
      (isProductOrderItem(orderLine) || isAncillaryOrderItem(orderLine)) &&
      ((orderLine.vialsReassignedQuantity &&
        orderLine.vialsReassignedQuantity > 0) ||
        orderLine.status === PatientOrderStatus.MedicalReviewDeclined ||
        orderLine.status === PatientOrderStatus.HealthAuthorityDeclined)
    ) {
      reasignedItems.push(orderLine)
    }
  })
  return reasignedItems
}

export const getOrderAndReassignedItems = (order: OrderDto | undefined) => {
  const orderedItems: OrderLineDto[] = []
  order?.lines.forEach((orderLine) => {
    if (
      (isProductOrderItem(orderLine) || isAncillaryOrderItem(orderLine)) &&
      (orderLine.status === OrderStatus.Cancelled ||
        orderLine.quantities.ordered > 0 ||
        orderLine.status === PatientOrderStatus.MedicalReviewDeclined ||
        orderLine.status === PatientOrderStatus.HealthAuthorityDeclined ||
        (orderLine.vialsReassignedQuantity &&
          orderLine.vialsReassignedQuantity > 0)) &&
      !isProductTemperatureTracker(orderLine.skuCatalogItem?.item)
    ) {
      orderedItems.push(orderLine)
    }
  })

  return orderedItems
}

const canBeParsedAsNumber = (value: string) => {
  return !isNaN(parseInt(value))
}

export const getAdditionalLineDescription = (orderLine: OrderLineDto) => {
  if (
    orderLine &&
    orderLine.skuCatalogItem &&
    orderLine.skuCatalogItem.item &&
    orderLine.skuCatalogItem.item.itemCategoryCode
  ) {
    switch (orderLine.skuCatalogItem?.item.itemCategoryCode) {
      case OrderItemType.AncillaryEquipment:
        return 'opa_checkout:additional_lines.ancillary_equipment'
      case OrderItemType.DataLoggers:
        if (orderLine.skuCatalogItem.item.storageTemperature === '15-25') {
          return 'opa_checkout:additional_lines.data_loger_ambient'
        } else if (
          orderLine.skuCatalogItem.item.storageTemperature === '2-8' ||
          (orderLine.skuCatalogItem.item.storageTemperature &&
            canBeParsedAsNumber(
              orderLine.skuCatalogItem.item.storageTemperature
            ) &&
            (orderLine.skuCatalogItem.item
              .storageTemperature as unknown as number) < 0)
        )
          return 'opa_checkout:additional_lines.data_logger_cold'
        else {
          return 'opa_checkout:additional_lines.data_logger_default'
        }
      case OrderItemType.Shippers:
        if (orderLine.skuCatalogItem.item.storageTemperature === '15-25') {
          return 'opa_checkout:additional_lines.shippers_ambient'
        } else if (orderLine.skuCatalogItem.item.storageTemperature === '2-8')
          return 'opa_checkout:additional_lines.shippers_cool'
        else {
          return 'opa_checkout:additional_lines.shippers_default'
        }
      case OrderItemType.ServiceItems:
        return 'opa_checkout:additional_lines.service_items'
      case OrderItemType.ProductionComponents:
        return 'opa_checkout:additional_lines.production_components'
      case OrderItemType.Equipment:
        return 'opa_checkout:additional_lines.equipment_items'
      case OrderItemType.NOIAndCDLicenseItems:
        return 'opa_checkout:additional_lines.no_iand_cd_license_items'
      case OrderItemType.ProductionLabels:
        return 'opa_checkout:additional_lines.production_label_items'

      default:
        return ''
    }
  } else {
    return ''
  }
}

export const deliveryNoteMaxCharacters = 240
