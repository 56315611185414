import { lighten } from 'polished'
import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'
import { ClinStyledButton } from '../../../components/ClinButton/ClinButton.styles'
import { ClinText } from '../../../components/ClinText'
import { mediaQuery, mediaQueryDown } from '../../../utils/mediaQuery'

const tabletBreakpoint = ClinTheme.breakpoints[1]

export interface IProgramSearchStylesProps {
  /** Show suggestions below search */
  showSuggestions?: boolean
  /** Show suggestions below search */
  showGlowEffect?: boolean
}

export const StyledProgramSearchInput = styled.input<IProgramSearchStylesProps>(
  {
    fontFamily: ClinTheme.fonts.body,
    position: 'relative',
    flex: '1 1 auto',
    marginBottom: '0',
    textIndent: 12,
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[2]}px`,
    fontSize: ClinTheme.fontSizes[2],
    fontWeight: ClinTheme.fontWeights.normal,
    lineHeight: ClinTheme.lineHeights.body,
    border: `1px solid rgb(210, 210, 210)`,
    borderRadius: ClinTheme.space[1],
    transition: '.2s linear background-color, .2s linear color',
    '::placeholder': {
      color: 'inherit'
    },
    ':-ms-input-placeholder': {
      // Special case for Text Inputs to have correct height in IE 11
      height: '40px'
    },
    [`:focus`]: {
      outline: '0',
      border: '1px solid rgb(80, 30, 206)',
      boxShadow: 'rgb(80, 30, 206) 0px 0px 3px 0px'
    }
  }
)

export const StyledProgramSearchWrapper = styled.div({
  width: '100%',
  backgroundColor: ClinTheme.colors.white,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  [`.clin-icon`]: {
    transition: '.2s linear color',
    color: ClinTheme.colors.primary
  },
  [`> input:focus`]: {
    [`~ .clin-icon`]: {
      color: ClinTheme.colors.primary
    }
  }
})

export const StyledProgramAutoSuggestWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '1 0 auto',

  [`${ClinStyledButton}`]: {
    marginTop: ClinTheme.space[4],
    width: '100%'
  },

  [mediaQuery(tabletBreakpoint)]: {
    flexDirection: 'row',

    [`${ClinStyledButton}`]: {
      marginTop: 0,
      marginLeft: ClinTheme.space[4],
      width: 'auto'
    }
  }
})

export interface IProgramAutoSuggestResultProps {
  /** Show highlight styles */
  selected?: boolean
}

export interface IProgramSearchStylesProps {
  /** Show suggestions below search */
  showSuggestions?: boolean
}

export const StyledProgramAutoSuggestResultItem =
  styled.li<IProgramAutoSuggestResultProps>(
    {
      zIndex: '999',
      fontSize: ClinTheme.fontSizes[3],
      fontWeight: ClinTheme.fontWeights.light,
      color: ClinTheme.colors.primary,
      padding: `${ClinTheme.space[2]}px ${ClinTheme.space[4]}px ${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
      backgroundColor: ClinTheme.colors.white,
      cursor: 'pointer',
      [`a`]: {
        display: 'block',
        color: ClinTheme.colors.primary
      },
      [`:hover, :focus`]: {
        backgroundColor: lighten(0.2, ClinTheme.colors.primaryLightest)
      },
      [`:first-child`]: {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px'
      },
      [`:last-child`]: {
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px'
      }
    },
    ({ selected }) => ({
      backgroundColor: selected
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.white
    })
  )

export const StyledProgramAutoSuggestNoResultItem =
  styled.li<IProgramAutoSuggestResultProps>(
    {
      fontSize: ClinTheme.fontSizes[3],
      fontWeight: ClinTheme.fontWeights.light,
      color: ClinTheme.colors.primary,
      padding: `${ClinTheme.space[4]}px ${ClinTheme.space[5]}px`,
      backgroundColor: ClinTheme.colors.white,
      borderBottom: `solid 1px ${ClinTheme.colors.grey}`,
      cursor: 'pointer',
      [`a`]: {
        display: 'block',
        color: ClinTheme.colors.primary
      },
      [`:hover, :focus`]: {
        backgroundColor: lighten(0.2, ClinTheme.colors.primaryLightest)
      },
      [`:last-child`]: {
        borderBottom: 'none'
      }
    },
    ({ selected }) => ({
      backgroundColor: selected
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.white
    })
  )
export const StyledProgramSearchLoaderWrapper = styled.div({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: '21px'
})

export const StyledProgramResults = styled.ul<IProgramSearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    margin: 0,
    zIndex: ClinTheme.zIndices.modal,
    background: ClinTheme.colors.white,
    marginTop: '11px',
    borderRadius: '8px',
    padding: '8px 0',
    boxShadow:
      '0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 4px 8px 0px rgba(0, 0, 0, 0.16)'
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)

export const StyledProgramLink = styled(ClinText)({
  letterSpacing: '0.7px',
  textTransform: 'uppercase',
  fontWeight: ClinTheme.fontWeights.medium,
  lineHeight: ClinTheme.lineHeights.small,
  marginBottom: '0px',
  marginTop: '0px',
  whiteSpace: 'nowrap',

  [mediaQueryDown(tabletBreakpoint)]: {
    whiteSpace: 'unset'
  }
})
