import { useEffect, useState } from 'react'

import {
  INewFeature,
  NewFeatureElements
} from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { useNewFeatures } from '../../context/newFeatures'
import { getNewFeatureList } from '../../services/NewFeaturesService'

export const useNewFeaturesList = (pageName: string) => {
  const [{ features }] = useNewFeatures()

  const [newFeaturesList, setNewFeaturesList] = useState<{
    [id: string]: INewFeature
  }>(getNewFeatureList(pageName, features))

  const displayNewFeature = (featureToDisplay: NewFeatureElements) => {
    const currentFeature = newFeaturesList[featureToDisplay]
    if (!currentFeature) {
      return false
    }
    if (currentFeature.isCancelled) {
      return true
    } else {
      const excludedFeatures = currentFeature.mandatoryViewedFeatures
      return (
        excludedFeatures &&
        excludedFeatures.every((value) => !newFeaturesList[value])
      )
    }
  }

  const displayNewFeatureGlowEffect = (
    featureToDisplay: NewFeatureElements
  ) => {
    // Adding a condition to disable PatientsSearch highlighting
    // if (featureToDisplay === NewFeatureElements.PatientsSearch) {
    //   return false;
    // }

    const currentFeature = newFeaturesList[featureToDisplay]
    if (!currentFeature || currentFeature?.isCancelled) {
      return false
    } else {
      const excludedFeatures = currentFeature.mandatoryViewedFeatures
      return (
        excludedFeatures &&
        excludedFeatures.every((value) => !newFeaturesList[value])
      )
    }
  }

  useEffect(() => {
    setNewFeaturesList(getNewFeatureList(pageName, features))
  }, [features])

  return {
    newFeaturesList,
    displayNewFeature,
    displayNewFeatureGlowEffect
  }
}
