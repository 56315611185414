import styled from 'styled-components'

import { MarketingMode } from './AccountDetailsCountainer'
import { ClinTheme } from '../../../ClinTheme'
import { ClinText } from '../../../components/ClinText'
import { mediaQueryDown, mediaQuery } from '../../../utils/mediaQuery'

export interface IStyledAccountDetailsProps {
  /** The current marketing user state */
  marketingMode: MarketingMode
}

const tabletBreakpoint = ClinTheme.breakpoints[2]

export const StyledRow = styled.div({
  width: '100%',
  paddingTop: ClinTheme.space[4],
  paddingBottom: ClinTheme.space[4],

  boxSizing: 'border-box',
  [`.clin-text`]: {
    fontSize: ClinTheme.fontSizes[3]
  },

  [mediaQuery(tabletBreakpoint)]: {
    paddingRight: ClinTheme.space[2],
    paddingLeft: ClinTheme.space[2]
  }
})

export const StyledRowFilled = styled(StyledRow)({
  backgroundColor: ClinTheme.colors.ultraLightGrey,
  paddingRight: ClinTheme.space[3],
  paddingLeft: ClinTheme.space[3],

  '.clin-text-input, .clin-select': {
    marginBottom: ClinTheme.space[3],
    '.clin-text': {
      fontSize: ClinTheme.fontSizes[1]
    }
  },
  '.phone-fields': {
    label: {
      fontSize: ClinTheme.fontSizes[2]
    }
  }
})

export const StyledRowTitle = styled(ClinText)({
  marginBottom: ClinTheme.space[2]
})

export const StyledAccountDetails = styled.div({
  width: '100%',

  '.inactive': {
    border: 'none',
    outline: 'none'
  },

  '&.not-editing': {
    input: {
      border: 'none',
      fontWeight: ClinTheme.fontWeights.bold,
      fontSize: ClinTheme.fontSizes[3],

      '&:focus': {
        border: 'none',
        outline: 'none',
        boxShadow: 'none'
      }
    },
    [`${StyledRow}`]: {
      borderBottom: `1px solid ${ClinTheme.colors.grey}`
    },

    '.clin-text-input': {
      marginBottom: 0,

      input: {
        paddingLeft: 0
      },

      [mediaQueryDown(tabletBreakpoint)]: {
        marginBottom: 0
      }
    },

    [`${StyledRowTitle}`]: {
      marginBottom: 0
    }
  }
})

export const StyledRows = styled.div<IStyledAccountDetailsProps>(
  ({ marketingMode }) => ({
    opacity: marketingMode === MarketingMode.Submitting ? '0.3' : 1,
    pointerEvents: marketingMode === MarketingMode.Submitting ? 'none' : 'auto'
  })
)

export const StyledPhoneExtension = styled.span({
  display: 'inline-block',
  marginLeft: `${ClinTheme.space[4]}px`,
  marginRight: `6px`
})

export const StyledPhoneExtensionNumber = styled.span({
  fontWeight: `${ClinTheme.fontWeights.bold}`,
  fontSize: `${ClinTheme.fontSizes[3]}`
})
