import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import {
  StyledClinDropdown,
  StyledClinDropdownContainer
} from '../../../components/ClinDropdown/ClinDropdown.styles'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ICustomSelectStyles } from '../../../features/CustomSelect/CustomSelect.styles'
import { mediaQueryDown } from '../../../utils/mediaQuery'

export interface IClinSeparatorProps {
  height: string
}

export const StyledSectionWrapper = styled.div({
  backgroundColor: ClinTheme.colors.white,
  padding: `${ClinTheme.space[5]}px ${ClinTheme.space[6]}px`
})

export const StyledDuplicatePatientWrapper = styled.div({
  backgroundColor: ClinTheme.colors.white,
  padding: `${ClinTheme.space[6]}px ${ClinTheme.space[5]}px`
})

export const StyledSeparator = styled(ClinSpacer)<IClinSeparatorProps>(
  {},
  ({ height }: IClinSeparatorProps) => ({
    height: `${height}px`,
    [mediaQueryDown(ClinTheme.breakpoints[1])]: {
      height: '0px'
    }
  })
)

export const StyledTitleWrapper = styled.div({
  width: '100%',
  backgroundColor: ClinTheme.colors.white,
  paddingTop: `${ClinTheme.space[5]}px`
})

export const StyledTitleButtonWrapper = styled.div({
  boxSizing: 'border-box',
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: `${ClinTheme.fontSizes[2]}px`,
  paddingRight: `${ClinTheme.fontSizes[2]}px`,
  maxWidth: '1140px',
  marginBottom: '0px'
})

export const StyledDisablePatinetButtonWrapper = styled.div({
  display: 'flex',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export const StyledDisablePatinetButton = styled(ClinButton)({
  marginRight: `${ClinTheme.space[3]}px`,
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    marginBottom: `${ClinTheme.space[2]}px`
  }
})

export const StyledEnrollButton = styled(ClinButton)({
  width: '137px',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    marginTop: '12px'
  }
})

export const StyledYearSelect = styled.div<ICustomSelectStyles>({
  fontFamily: ClinTheme.fonts.body,
  fontWeight: ClinTheme.fontWeights.normal,
  boxSizing: 'border-box',
  minHeight: '1px',
  position: 'relative',
  paddingLeft: ' 16px',
  paddingRight: '16px',
  width: '41.6667%',
  flex: '0 0 41.6667%',
  maxWidth: '41.6667%',
  marginLeft: '0%',
  right: 'auto',
  left: 'auto',
  [`${StyledClinDropdown}, ${StyledClinDropdownContainer}`]: {
    width: '100%'
  }
})

export const StyledYearSelectOptions = styled.div<ICustomSelectStyles>({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 4px 8px 0px rgba(0, 0, 0, 0.16)`,
  background: ClinTheme.colors.white,
  height: ' 400px',
  overflow: 'auto',
  '&::-webkit-scrollbar-track': {
    width: '0px',
    display: 'none',
    backgroundColor: 'fuchsia'
  }
})

export const StyledYearSelectTrigger = styled.div<ICustomSelectStyles>({
  position: 'relative',
  width: '100%',
  height: ClinTheme.space[7]
})
