import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpinner } from '../../components/ClinSpinner'
import { BookmarkStatus } from '../../pages/Bookmarks/Bookmarks.model'

interface IBookmarkToggleButtonProps {
  /** Indicates whether the bookmark is set, or if it's being updated */
  bookmarkedStatus?: BookmarkStatus
  /** Called when the user wants to toggle the bookmark */
  toggleBookmark?: (e: React.MouseEvent<HTMLButtonElement>) => void
  /** Only show the status icon, no text like "Bookmarked" */
  iconOnly?: boolean
  /** Show the status icon before text like "Bookmarked" */
  iconFirst?: boolean
  /** Whether to show the hover state */
  isHover?: boolean
  /** Called when mouse leaves the button */
  onMouseEnter?: () => void
  /** Called when mouse enters the button */
  onMouseLeave?: () => void
}

export const StyledLabel = styled.span`
  margin: 0 5px;
  display: inline-block;
`

export const BookmarkToggleButton: FunctionComponent<
  IBookmarkToggleButtonProps
> = ({
  bookmarkedStatus = BookmarkStatus.NotBookmarked,
  toggleBookmark,
  onMouseEnter,
  onMouseLeave,
  isHover,
  iconOnly = false,
  iconFirst = false
}) => {
  const { t } = useTranslation()
  const LabelComponent = (
    <StyledLabel>
      {bookmarkedStatus === BookmarkStatus.Bookmarked
        ? t('common:buttons.bookmarked')
        : t('common:buttons.bookmark')}
    </StyledLabel>
  )

  return (
    <ClinButton
      variant="ghost"
      onClick={(e) => toggleBookmark && toggleBookmark(e)}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {!iconOnly && !iconFirst && LabelComponent}
      {bookmarkedStatus === BookmarkStatus.Loading ? (
        <ClinSpinner size={ClinTheme.space[3]} />
      ) : (
        <ClinIcon
          iconName={
            bookmarkedStatus === BookmarkStatus.Bookmarked || isHover
              ? ClinIconPathName.BookmarkFilled
              : ClinIconPathName.Bookmark
          }
        />
      )}
      {!iconOnly && iconFirst && LabelComponent}
    </ClinButton>
  )
}
