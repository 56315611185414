import React, { useState, useEffect, FunctionComponent } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { SessionTimeOut } from './SessionTimeOut'
import authService from '../../services/AuthService'
import { BasicTimer } from '../../utils/BasicTimer/BasicTimer' // https://github.com/supremetechnopriest/react-idle-timer
import { noop } from '../../utils/noop'

interface ISessionTimeOutContainerProps {
  /** Duration for time out (defaults to 45 minutes) */
  inactivityTimeoutSecs?: number
  /** Duration to show modal */
  dialogueDurationSecs?: number
}

export const SessionTimeOutContainer: FunctionComponent<
  ISessionTimeOutContainerProps
> = ({ inactivityTimeoutSecs = 60 * 60, dialogueDurationSecs = 30 }) => {
  const [isShowing, setIsShowing] = useState<boolean>(false)
  const [timeRemainingSecs, setTimeRemainingSecs] =
    useState<number>(dialogueDurationSecs)
  const [timer, setTimer] = useState<BasicTimer | undefined>()

  useEffect(() => {
    if (!timer) {
      setTimer(new BasicTimer({ intervalMs: 1000 }))
    }
    return () => {
      if (timer) {
        timer.reset()
      }
    }
  }, [timer])

  useEffect(() => {
    if (isShowing && timer) {
      timer.durationSecs = dialogueDurationSecs
      setTimeRemainingSecs(timer.durationSecs)
      timer.onComplete = () => {
        setTimeRemainingSecs(0)
        setIsShowing(false)
        authService.logout().then(() => noop())
      }
      timer.onTick = (remaining: number) => {
        setTimeRemainingSecs(remaining)
      }
      timer.start()
    }
  }, [timer, dialogueDurationSecs, isShowing, inactivityTimeoutSecs])

  const handleOnIdle = () => {
    setIsShowing(true)
  }

  const { reset } = useIdleTimer({
    startOnMount: true,
    timeout: 1000 * inactivityTimeoutSecs, // Idle timeout in milliseconds
    onIdle: handleOnIdle, // function to call when user is now idle
    debounce: 500,
    crossTab: true
  })

  /**
   * Continue session button clicked
   */
  const handleContinue = () => {
    if (timer) {
      timer.reset()
    }
    reset()
    setIsShowing(false)
  }

  /**
   * Log out button clicked
   */
  const handleLogOut = () => {
    authService.logout().then(() => noop())
  }

  return (
    <SessionTimeOut
      isOpen={isShowing}
      timeRemainingSecs={timeRemainingSecs}
      handleLogOut={handleLogOut}
      handleContinue={handleContinue}
    />
  )
}
