import React, { FunctionComponent, useEffect, useState } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { ClinTheme } from '../../ClinTheme'
import { ClinCheckbox } from '../../components/ClinCheckbox'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'

interface IHipaaComplianceProps {
  /** Selected fields */
  preselected?: boolean
  /** This checks if the user is transfering to the same institute or not */
  transferToSameInstitute: boolean
  /** The function is called when all items are checked */
  handleConfirmation: (isConfirmed: boolean) => void
}

const numOfСonfirmations = 4

export const HipaaCompliance: FunctionComponent<IHipaaComplianceProps> = ({
  preselected,
  transferToSameInstitute,
  handleConfirmation
}) => {
  const { t } = useTranslation()
  const [confirmationCounter, setConfirmationCounter] = useState<number>(0)
  const handleConfirmationSelect = (isConfirmed: boolean) => {
    setConfirmationCounter((value) => (isConfirmed ? ++value : --value))
  }
  useEffect(() => {
    if (preselected) {
      setConfirmationCounter(numOfСonfirmations)
      handleConfirmation(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleConfirmation(confirmationCounter === numOfСonfirmations)
  }, [confirmationCounter, handleConfirmation])

  return (
    <>
      {transferToSameInstitute ? (
        <Row>
          <Col>
            <ClinText>
              {t('transfer_patient_to_physician:hipaa_description')}
            </ClinText>
            <ClinSpacer height={ClinTheme.space[4]} />
            <ClinGroup alignItems={'flex-start'}>
              <ClinCheckbox
                id="confirmation-1"
                defaultChecked={preselected}
                onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                  handleConfirmationSelect(event.currentTarget.checked)
                }
              />
              <ClinText>
                {t('transfer_patient_to_physician:hipaa_option_one')}
              </ClinText>
            </ClinGroup>
            <ClinSpacer height={ClinTheme.space[3]} />
            <ClinGroup alignItems={'flex-start'}>
              <ClinCheckbox
                id="confirmation-2"
                defaultChecked={preselected}
                onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                  handleConfirmationSelect(event.currentTarget.checked)
                }
              />
              <ClinText>
                {t('transfer_patient_to_physician:hipaa_option_two')}
              </ClinText>
            </ClinGroup>
            <ClinSpacer height={ClinTheme.space[3]} />
            <ClinGroup alignItems={'flex-start'}>
              <ClinCheckbox
                id="confirmation-3"
                defaultChecked={preselected}
                onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                  handleConfirmationSelect(event.currentTarget.checked)
                }
              />
              <ClinText>
                {t('transfer_patient_to_physician:hipaa_option_three')}
              </ClinText>
            </ClinGroup>
            <ClinSpacer height={ClinTheme.space[3]} />
            <ClinGroup alignItems={'flex-start'}>
              <ClinCheckbox
                id="confirmation-4"
                defaultChecked={preselected}
                onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                  handleConfirmationSelect(event.currentTarget.checked)
                }
              />
              <ClinText>
                {t('transfer_patient_to_physician:hipaa_option_four')}
              </ClinText>
            </ClinGroup>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <ClinText>
              {t('transfer_patient_to_institute:hipaa_description')}
            </ClinText>
            <ClinSpacer height={ClinTheme.space[4]} />
            <ClinGroup alignItems={'flex-start'}>
              <ClinCheckbox
                id="confirmation-1"
                defaultChecked={preselected}
                onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                  handleConfirmationSelect(event.currentTarget.checked)
                }
              />
              <ClinText>
                {t('transfer_patient_to_physician:hipaa_option_one')}
              </ClinText>
            </ClinGroup>
            <ClinSpacer height={ClinTheme.space[3]} />
            <ClinGroup alignItems={'flex-start'}>
              <ClinCheckbox
                id="confirmation-2"
                defaultChecked={preselected}
                onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                  handleConfirmationSelect(event.currentTarget.checked)
                }
              />
              <ClinText>
                {t('transfer_patient_to_physician:hipaa_option_two')}
              </ClinText>
            </ClinGroup>
            <ClinSpacer height={ClinTheme.space[3]} />
            <ClinGroup alignItems={'flex-start'}>
              <ClinCheckbox
                id="confirmation-3"
                defaultChecked={preselected}
                onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                  handleConfirmationSelect(event.currentTarget.checked)
                }
              />
              <ClinText>
                {t('transfer_patient_to_physician:hipaa_option_three')}
              </ClinText>
            </ClinGroup>
            <ClinSpacer height={ClinTheme.space[3]} />
            <ClinGroup alignItems={'flex-start'}>
              <ClinCheckbox
                id="confirmation-4"
                defaultChecked={preselected}
                onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                  handleConfirmationSelect(event.currentTarget.checked)
                }
              />
              <ClinText>
                {t('transfer_patient_to_physician:hipaa_option_four')}
              </ClinText>
            </ClinGroup>
          </Col>
        </Row>
      )}
    </>
  )
}
