import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'

import { RealWorldDataProgram } from './RealWorldDataProgram'
import { ProgramPage } from './RealWorldDataProgram.model'
import useFeatureFlagRedirect from '../../context/featureFlags/FeatureFlagBasedRedirect'
import { FeatureFlagKeys } from '../../context/featureFlags/FeatureFlagKeys'
import config from '../../services/ConfigProvider'

interface IDashboardContainerProps extends RouteComponentProps {}

export const RealWorldDataProgramContainer: React.FC<
  IDashboardContainerProps
> = () => {
  useFeatureFlagRedirect(FeatureFlagKeys.PatientCentricJourneyPerUser)
  const [content, setContent] = useState<ProgramPage>(null as any)

  const pageId = 'bf7940e2-7047-49eb-8029-3beb99bd9845'

  const fetchAccessToken = async () => {
    const tokenResponse = await axios.post(
      'https://cloud.squidex.io/identity-server/connect/token',
      `grant_type=client_credentials&client_id=${config?.squidexClientId}&client_secret=${config?.squidexClientSecret}&scope=squidex-api`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
    return tokenResponse.data.access_token
  }

  const fetchContent = async () => {
    try {
      const accessToken = await fetchAccessToken()
      const contentResponse = await axios.get(
        `https://cloud.squidex.io/api/content/clinigen-direct-program-pages/pgrogram-page/${pageId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      setContent(contentResponse.data)
    } catch (error) {
      console.error('Error fetching content:', error)
    }
  }

  useEffect(() => {
    fetchContent()
  })

  if (
    config.squidexClientId === 'undefined' ||
    config.squidexClientSecret === 'undefined'
  ) {
    return <div>Missing Squidex credentials</div>
  }

  return <RealWorldDataProgram content={content}></RealWorldDataProgram>
}
