import { yupResolver } from '@hookform/resolvers/yup'
import React, { ChangeEvent, FunctionComponent, useEffect } from 'react'
import { Container, Col, Row } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

import { ClinTheme } from '../../../../ClinTheme'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinIcon } from '../../../../components/ClinIcon'
import { ClinIconPathName } from '../../../../components/ClinIcon/ClinIcon.paths'
import { ClinRadio } from '../../../../components/ClinRadio'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import {
  getInputValidation,
  ValidationType
} from '../../../../utils/Forms/getInputValidation'

export interface ITransferForm {
  destinationRadioId: string
}

const defaultState: ITransferForm = {
  destinationRadioId: '1'
}

interface ITransferPatientFormProps {
  /** Disable transfer button */
  disableTransferButton: boolean
  /** Handle confirmation of transfer  **/
  handleFormSubmission: (form: ITransferForm) => void
  /** Handle close form **/
  handleClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  /** Called when for radio is selected **/
  handleDestinationInputChange?: (selectedRadioButtonId: string) => void
}

export const TransferPatientForm: FunctionComponent<
  ITransferPatientFormProps
> = ({
  disableTransferButton,
  handleFormSubmission,
  handleClose,
  handleDestinationInputChange
}) => {
  const { t } = useTranslation()
  const sampleSchema = object().shape({
    destinationRadioId: string().required(
      getInputValidation(ValidationType.RequiredField)
    )
  })

  const { handleSubmit, control, watch } = useForm<ITransferForm>({
    defaultValues: defaultState,
    resolver: yupResolver(sampleSchema)
  })

  const watchDestinationId = watch('destinationRadioId')

  useEffect(() => {
    handleDestinationInputChange &&
      handleDestinationInputChange(watchDestinationId)
  }, [watchDestinationId, handleDestinationInputChange])

  return (
    <Container
      style={{
        marginTop: ClinTheme.space[5],
        marginBottom: ClinTheme.space[4]
      }}
    >
      <Row>
        <Col sm={12} md={9} lg={9}>
          <form onSubmit={handleSubmit(handleFormSubmission)}>
            <ClinText
              as="h2"
              color="#270072"
              fontWeight={700}
              variant={TypographyVariant.H2}
            >
              {t('transfer_patient:title')}
            </ClinText>
            <ClinSpacer height={ClinTheme.space[7]} hasBorder={true} />
            <Row>
              <Col>
                <ClinButton
                  className="back-button"
                  variant="linkWithIcon"
                  onClick={(event) => handleClose(event)}
                >
                  <ClinIcon
                    style={{ marginRight: ClinTheme.space[2] }}
                    iconSize={ClinTheme.fontSizes[3]}
                    iconName={ClinIconPathName.ArrowLeft}
                  />
                  {t('transfer_patient:patient_details_btn')}
                </ClinButton>
              </Col>
            </Row>
            <ClinSpacer height={ClinTheme.space[4]} />
            <ClinText
              as="h5"
              variant={TypographyVariant.H4}
              color={ClinTheme.colors.primary}
              fontWeight={ClinTheme.fontWeights.bold}
              marginBottom={ClinTheme.space[4]}
            >
              {t('transfer_patient:please_choose')}
            </ClinText>
            <ClinSpacer height={ClinTheme.space[4]} />
            <Controller
              name="destinationRadioId"
              control={control}
              render={({ field: { onChange, value } }) => {
                const handleOnChange = (
                  event: ChangeEvent<HTMLInputElement>
                ) => {
                  onChange(event.currentTarget.value)
                }
                return (
                  <>
                    <ClinRadio
                      label={t(
                        'transfer_patient:transfer_to_physician_at_institute_label'
                      )}
                      id="transfer-to-my-institute"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleOnChange(event)
                      }}
                      checked={value === '1'}
                      value="1"
                    >
                      <ClinText
                        fontSize={ClinTheme.fontSizes[2]}
                        fontWeight={ClinTheme.fontWeights.light}
                      >
                        {t(
                          'transfer_patient:transfer_to_physician_at_institute'
                        )}
                      </ClinText>
                    </ClinRadio>
                    <ClinSpacer />
                    <ClinRadio
                      label={t(
                        'transfer_patient:transfer_to_physician_at_other_institute_label'
                      )}
                      id="transfer-to-another-institute"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleOnChange(event)
                      }
                      checked={value === '2'}
                      value="2"
                    >
                      <ClinText
                        fontSize={ClinTheme.fontSizes[2]}
                        fontWeight={ClinTheme.fontWeights.light}
                      >
                        {t(
                          'transfer_patient:transfer_to_physician_at_other_institute'
                        )}
                      </ClinText>
                    </ClinRadio>
                  </>
                )
              }}
            />
            <ClinSpacer height={ClinTheme.space[9]} />
            <ClinButton
              type="submit"
              variant="primary"
              disabled={disableTransferButton}
            >
              {t('transfer_patient:start_transfer_btn')}
            </ClinButton>
            <ClinSpacer height={ClinTheme.space[4]} />
            <ClinButton as="a" onClick={handleClose} variant="linkButton">
              <ClinText
                color={ClinTheme.colors.primaryMid}
                fontSize={ClinTheme.fontSizes[1]}
                fontWeight={ClinTheme.fontWeights.light}
              >
                {t('transfer_patient:close_tranfer_form_btn')}
              </ClinText>
            </ClinButton>
            <ClinSpacer height={ClinTheme.space[4]} />
          </form>
        </Col>
      </Row>
    </Container>
  )
}
