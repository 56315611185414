import { AxiosError } from 'axios'
import React, { FunctionComponent, useState } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { IPreferenceFormValues, Preferences } from './Preferences'
import { ClinTheme } from '../../ClinTheme'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinSpinner } from '../../components/ClinSpinner'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { ICustomSelectOption } from '../../features/CustomSelect/CustomSelect'
import { defaultLanguages, ILanguageOption } from '../../i18n/config'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AuthError, updateContact } from '../../services/ApiService'
import config from '../../services/ConfigProvider'
import { noop } from '../../utils/noop'
import { StyledSpinnerContainer } from '../OpaCheckout/OpaCheckout.styles'
export interface IPreferences {
  /** Users prefered language */
  language?: ILanguageOption
}
export const PreferencesContainer: FunctionComponent = () => {
  const { dispatch, userDetails, portfolioCountryCode, userRole } =
    useAppContext()
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const [isLanguageLoading, setIsLanguageLoading] = useState<boolean>(false)
  /* This enables a comparison to be made with new updates which will help
  to decide which actions are required when a user submits their preferences */
  const [originalPreferences, setOriginalPreferences] = useState<IPreferences>({
    language: defaultLanguages.find((l) => l.id === i18n.language)
  })
  /* An object that can be used to store new preferences (this will eventually contain more in the future) */
  const [preferences, setPreferences] = useState<IPreferences>({
    language: defaultLanguages.find((l) => l.id === i18n.language)
  })

  const handleSwitchLanguage = (selectedLanguage: ICustomSelectOption) => {
    const foundLanguage = defaultLanguages.find(
      (l) => l.id === selectedLanguage.id
    )
    foundLanguage && setPreferences({ ...preferences, language: foundLanguage })
  }
  const handleFormSubmit = (data: IPreferenceFormValues) => {
    const updatedPreferences: IPreferences = {
      language: defaultLanguages.find((l) => l.id === data.language)
    }
    if (
      updatedPreferences.language &&
      originalPreferences?.language?.id !== updatedPreferences.language?.id
    ) {
      setIsLanguageLoading(true)
      if (updatedPreferences.language.id !== 'cimode') {
        userDetails &&
          updateContact(userDetails.contactCard.contactId.toString(), {
            preferredName: userDetails.contactCard.preferredName,
            licenseNumber: userDetails.contactCard.licenseNumber,
            specialism: userDetails.contactCard.specialism,
            classification: userDetails.contactCard.classification,
            phones: userDetails.contactCard.customerPhones.map((phone) => {
              return {
                phoneId: phone.phoneId.toString(),
                requestType: 'UPDATE',
                phoneType: phone.phoneType,
                countryCode: phone.countryCode,
                areaCode: phone.areaCode,
                phoneNumber: phone.phoneNumber,
                phoneExtension: phone.phoneExtension
              }
            }),
            languagePreference: updatedPreferences.language.id
          })
            .then((response) => {
              if (response.status === 200) {
                i18n.changeLanguage(data.language).then(() => noop())
                setPreferences(updatedPreferences)
                setOriginalPreferences(updatedPreferences)
                dispatch(
                  createAnnounceEvent(
                    AnnounceMode.Success,
                    t('preferences:preferences_updated'),
                    ' '
                  )
                )
                analyticsServiceSingleton.trackEvent(
                  AnalyticsEvent.LanguageSwitched,
                  { language: data.language }
                )
              }
            })
            .catch((error: AxiosError) => {
              // If request is cancelled continue
              if (error.message === AuthError.RequestCancelled) {
                return
              }
              if (error.response) {
                const { code, message } = error
                dispatch(
                  createAnnounceEvent(
                    AnnounceMode.Error,
                    `${t(
                      'preferences:error_updating_preferences'
                    )} ${message} ${code ? code : ''}`
                  )
                )
              }
            })
            .finally(() => {
              setIsLanguageLoading(false)
            })
      } else {
        i18n
          .changeLanguage(updatedPreferences.language?.id)
          .then(() => {
            setPreferences(updatedPreferences)
            setOriginalPreferences(updatedPreferences)
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Success,
                t('preferences:preferences_updated'),
                ' '
              )
            )
          })
          .finally(() => setIsLanguageLoading(false))
      }
    }
  }

  const handleCancel = () => {
    history.push('/orders')
  }
  const handleMarketingSubscriptionRequest = () => {
    config.communicationPreferences &&
      window.open(config.communicationPreferences, '_blank')
  }
  return (
    <>
      {isLanguageLoading ? (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      ) : (
        <Preferences
          preferences={preferences}
          languageOptions={defaultLanguages}
          userCountry={portfolioCountryCode}
          userRole={userRole}
          handleSwitchLanguage={handleSwitchLanguage}
          handleFormSubmit={handleFormSubmit}
          handleMarketingSubscriptionRequest={
            handleMarketingSubscriptionRequest
          }
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}
