import { yupResolver } from '@hookform/resolvers/yup'
import { DateTime } from 'luxon'
import React, { FunctionComponent, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

import {
  StyledVialReconcileRow,
  StyledVialReconcileDate
} from './VialReconciliationTable.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSelect } from '../../components/ClinSelect'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinText } from '../../components/ClinText'
import { ClinTextInput } from '../../components/ClinTextInput'
import {
  IPatientVial,
  VialRecordState,
  VialReconciliationType
} from '../../pages/Patients/PatientDetail/PatientDetail.types'
import { getBrowserLocale } from '../../utils/getBrowserLocale'

/**
 * All possible statuses for Vials - we only need to deal with first three
 */
export enum VialStatus {
  Administered = 'Administered',
  Unused = 'Unused',
  Destroyed = 'Destroyed',
  ToBeAdministered = 'To be administered',
  PendingReassignment = 'Pending Reassignment',
  VialReassignedFrom = 'Vial Reassigned From',
  VialReassignedTo = 'Vial Reassigned To'
}

interface IVialStatus {
  value: VialStatus
  transKey: string
}

// Only display these in the vial select drop down (in this order)
export const selectVialStatuses: IVialStatus[] = [
  {
    value: VialStatus.Administered,
    transKey: 'patient_detail:vial_status_administered'
  },
  {
    value: VialStatus.Destroyed,
    transKey: 'patient_detail:vial_status_destroyed'
  }
]

/**
 * Get the correct validation rules for the reconciliation
 * @param vialReconciliationType
 */
const getSchemaForReconciliationType = (
  vialReconciliationType: VialReconciliationType
): any => {
  // FIXME: Update this type?!
  switch (vialReconciliationType) {
    // Can only select ‘Administered’ or ‘Destroyed’ from Disposition
    case VialReconciliationType.DispositionOnly:
      return object().shape({
        vialStatus: string()
          .required()
          .notOneOf([VialStatus.ToBeAdministered.toString()]),
        batchNumber: string(),
        dateAdministered: string(),
        vialNumber: string()
      })
    // Can select ‘Administered’ or ‘Destroyed’ from Disposition and must complete a Batch Number
    case VialReconciliationType.DispositionBatch:
      return object().shape({
        vialStatus: string()
          .required()
          .notOneOf([VialStatus.ToBeAdministered.toString()]),
        batchNumber: string().required(),
        dateAdministered: string(),
        vialNumber: string()
      })
    //  Can select ‘Administered’ or ‘Destroyed’ from Disposition and must complete a Date
    case VialReconciliationType.DispositionDate:
      return object().shape({
        vialStatus: string()
          .required()
          .notOneOf([VialStatus.ToBeAdministered.toString()]),
        batchNumber: string(),
        dateAdministered: string().required(),
        vialNumber: string()
      })

    // Can select ‘Administered’ or ‘Destroyed’ from Disposition and must complete a Date AND Batch Number
    case VialReconciliationType.DispositionBatchAndDate:
    default:
      return object().shape({
        vialStatus: string()
          .required()
          .notOneOf([VialStatus.ToBeAdministered.toString()]),
        batchNumber: string().required(),
        dateAdministered: string().required(),
        vialNumber: string()
      })
  }
}

/**
 * Work out which fields are required
 * @param vialReconciliationType
 * @param fieldName
 */
const getFieldIsRequiredForVialReconciliationType = (
  vialReconciliationType: VialReconciliationType,
  fieldName: string
): boolean => {
  switch (vialReconciliationType) {
    // Can select ‘Administered’ or ‘Destroyed’ from Disposition and must complete a Batch Number
    case VialReconciliationType.DispositionBatch:
      return fieldName === 'vialStatus' || fieldName === 'batchNumber'
    //  Can select ‘Administered’ or ‘Destroyed’ from Disposition and must complete a Date
    case VialReconciliationType.DispositionDate:
      return fieldName === 'vialStatus' || fieldName === 'dateAdministered'
    // Can only select ‘Administered’ or ‘Destroyed’ from Disposition
    case VialReconciliationType.DispositionOnly:
      return fieldName === 'vialStatus'
    // Can select ‘Administered’ or ‘Destroyed’ from Disposition and must complete a Date AND Batch Number
    case VialReconciliationType.DispositionBatchAndDate:
    default:
      return (
        fieldName === 'vialStatus' ||
        fieldName === 'dateAdministered' ||
        fieldName === 'batchNumber'
      )
  }
}

interface IVialReconcileRowProps {
  /** String to use for as React key */
  rowIndex: string
  /** PatientVial */
  patientVial: IPatientVial
  /** Show debug form data */
  showDebug?: boolean
  /** Disabled or not */
  isDisabled?: boolean
  /** Saving or not */
  isSaving?: boolean
  /** The type of vial reconciliation */
  vialReconciliationType?: VialReconciliationType
  vialRecordState?: VialRecordState
  handleVialRecordState: (vial: IPatientVial) => void
  /** Triggered when the date picker is selected */
  handleVialDateInputSelected?: (vial: IPatientVial) => void
  /** Save the row  */
  handleSaveChanges?: (updatedVial: IPatientVial) => void
}

export const VialReconcileRow: FunctionComponent<IVialReconcileRowProps> = ({
  rowIndex,
  showDebug,
  patientVial,
  isDisabled,
  isSaving,
  vialReconciliationType = VialReconciliationType.DispositionBatchAndDate,
  vialRecordState,
  handleVialRecordState,
  handleVialDateInputSelected,
  handleSaveChanges
}) => {
  const { t } = useTranslation()

  const { vialNumber, batchNumber } = patientVial

  /**
   * Helper method to get the copy for the date formatted field
   */
  const getDateFieldText = (): string => {
    return getFieldIsRequiredForVialReconciliationType(
      vialReconciliationType,
      'dateAdministered'
    )
      ? patientVial.dateAdministeredFormatted && patientVial.wasReconciled
        ? patientVial.dateAdministeredFormatted
        : t('common:labels.select_placeholder')
      : t('glossary:not_applicable')
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState,
    setValue,
    reset,
    formState: { errors }
  } = useForm<IPatientVial>({
    defaultValues: {
      ...patientVial,
      batchNumber: getFieldIsRequiredForVialReconciliationType(
        vialReconciliationType,
        'batchNumber'
      )
        ? patientVial.batchNumber
        : t('glossary:not_applicable'),
      dateAdministeredFormatted: getDateFieldText()
    },
    mode: 'onChange',
    resolver: yupResolver(
      getSchemaForReconciliationType(vialReconciliationType)
    )
  })

  useEffect(() => {
    setValue(
      'batchNumber',
      vialRecordState?.batchNumber ?? patientVial.batchNumber
    )
    setValue(
      'vialStatus',
      vialRecordState?.vialStatus ?? patientVial.vialStatus
    )
    setValue(
      'dateAdministered',
      vialRecordState?.dateAdministered ?? patientVial.dateAdministered
    )
  }, [])

  // Watch the date administered field and transform it to show a formatted version
  useEffect(() => {
    patientVial.wasReconciled =
      patientVial.dateAdministered === vialRecordState?.dateAdministered &&
      patientVial.batchNumber === vialRecordState?.batchNumber &&
      patientVial.vialStatus === vialRecordState?.vialStatus

    if (
      getFieldIsRequiredForVialReconciliationType(
        vialReconciliationType,
        'dateAdministered'
      )
    ) {
      // Update the real ISO value
      setValue('dateAdministered', patientVial.dateAdministered, {
        shouldValidate: true,
        shouldDirty: false
      })
      // Transform date for display here (https://moment.github.io/luxon/docs/manual/formatting.html)
      let dateAdministeredFormatted = t('common:labels.select_placeholder')
      if (
        patientVial.dateAdministered !== undefined &&
        patientVial.dateAdministered !== ''
      ) {
        dateAdministeredFormatted = DateTime.fromISO(
          patientVial.dateAdministered
        )
          .setLocale(getBrowserLocale())
          .toLocaleString(DateTime.DATE_SHORT)
      }
      setValue('dateAdministeredFormatted', dateAdministeredFormatted)
    } else {
      setValue('dateAdministeredFormatted', t('glossary:not_applicable'))
    }
  }, [setValue, patientVial, vialReconciliationType, t])

  useEffect(() => {
    const vialRecordSubscription = watch(
      (updatedVial: Partial<IPatientVial>) => {
        handleVialRecordState({
          ...patientVial,
          ...updatedVial
        })
      }
    )

    return () => vialRecordSubscription.unsubscribe()
  }, [watch, patientVial, handleVialRecordState])

  const onSubmit = handleSubmit(
    (vial: IPatientVial) => {
      // Transform into format sever can handle
      !isDisabled && handleSaveChanges && handleSaveChanges(vial)
      // Reset the form values and reset dirty so user can change it again
      reset(
        { ...vial },
        {
          keepDefaultValues: false,
          keepValues: false,
          keepDirty: false
        }
      )
    },
    (errors) => {
      console.warn(errors)
    }
  )

  const getSubmitButtonDisabled = (): boolean => {
    return (
      !!isDisabled ||
      !formState.isValid ||
      !!isSaving ||
      (patientVial.wasReconciled! && !formState.isDirty)
    )
  }

  const watchAllFields = watch()

  return (
    <>
      <StyledVialReconcileRow
        noValidate={true}
        onSubmit={onSubmit}
        className={'vial-reconcile-row'}
      >
        <ClinText className={'vial'}>{vialNumber}</ClinText>
        {/* Batch number (optional) */}
        {getFieldIsRequiredForVialReconciliationType(
          vialReconciliationType,
          'batchNumber'
        ) ? (
          <ClinTextInput
            id={`${rowIndex}-batch-${vialNumber}`}
            className={'batch'}
            {...register('batchNumber')}
            maxLength={25}
            disabled={
              !getFieldIsRequiredForVialReconciliationType(
                vialReconciliationType,
                'batchNumber'
              ) || isSaving
            }
            readOnly={
              !getFieldIsRequiredForVialReconciliationType(
                vialReconciliationType,
                'batchNumber'
              )
            }
          />
        ) : (
          <ClinText className={'batch-na'}>
            {batchNumber ? batchNumber : t('glossary:not_applicable')}
          </ClinText>
        )}
        {/* These three hidden inputs keep the original values */}
        <input
          id={`${rowIndex}-batch-number-${vialNumber}`}
          {...register('vialNumber')}
          type="hidden"
        />

        <input
          id={`${rowIndex}-date-administered-${vialNumber}`}
          {...register('dateAdministered')}
          type="hidden"
          readOnly={true}
        />

        <input
          id={`${rowIndex}-order-number-${vialNumber}`}
          {...register('orderNumber')}
          type="hidden"
          readOnly={true}
        />

        <Controller
          name={'vialStatus'}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ClinSelect
                title="vial status"
                className={'status'}
                value={value}
                hasError={
                  !!(errors && errors.vialStatus && patientVial.wasReconciled)
                }
                // prompt={errors.vialStatus?.message}
                disabled={isDisabled || isSaving}
                onChange={onChange}
              >
                <option value={VialStatus.ToBeAdministered.toString()}>
                  {t('common:labels.select_placeholder')}
                </option>
                {selectVialStatuses.map(
                  (vialStatus) =>
                    vialStatus.value.toString() !==
                      VialStatus.ToBeAdministered.toString() && (
                      <option
                        key={vialStatus.value.toString()}
                        value={vialStatus.value}
                      >
                        {t(vialStatus.transKey)}
                      </option>
                    )
                )}
              </ClinSelect>
            )
          }}
        />

        {/* (Optional) Display only to show date using locale and formatted */}
        <StyledVialReconcileDate>
          <ClinTextInput
            id={`${rowIndex}-action-date-${vialNumber}`}
            title={'date administered'}
            type="text"
            className={'date'}
            {...register('dateAdministeredFormatted')}
            defaultValue={getDateFieldText()}
            readOnly={true}
            hasError={!!(errors && errors.dateAdministeredFormatted)}
            // prompt={errors.dateAdministered?.message}
            disabled={
              isDisabled ||
              isSaving ||
              watchAllFields.vialStatus === VialStatus.Unused.toString() ||
              watchAllFields.vialStatus ===
                VialStatus.ToBeAdministered.toString() ||
              !getFieldIsRequiredForVialReconciliationType(
                vialReconciliationType,
                'dateAdministered'
              )
            }
            onClick={() => {
              patientVial.wasReconciled = false
              if (handleVialDateInputSelected) {
                handleVialDateInputSelected(patientVial)
              }
            }}
          />
          <ClinIcon
            iconName={ClinIconPathName.ChevronSmallUpDown}
            iconFill={
              isDisabled ||
              isSaving ||
              watchAllFields.vialStatus === VialStatus.Unused.toString() ||
              watchAllFields.vialStatus ===
                VialStatus.ToBeAdministered.toString()
                ? ClinTheme.colors.darkGrey
                : 'auto'
            }
          />
        </StyledVialReconcileDate>

        <ClinButton
          variant={'purpleLightSecondary'}
          type="submit"
          disabled={getSubmitButtonDisabled()}
        >
          {isSaving ? (
            <ClinGroup alignItems="center">
              {t('patient_detail:vial_reconciliation_table.button_saving')}
              <span style={{ marginLeft: ClinTheme.space[2] }}>
                <ClinSpinner size={ClinTheme.space[3]} />
              </span>
            </ClinGroup>
          ) : patientVial.wasReconciled && !formState.isDirty ? (
            t('patient_detail:vial_reconciliation_table.button_saved')
          ) : (
            t('patient_detail:vial_reconciliation_table.button_save_changes')
          )}
        </ClinButton>
      </StyledVialReconcileRow>
      {showDebug && (
        <pre>
          patientVial: {JSON.stringify(patientVial, null, 2)}
          <br />
          watchAllFields: {JSON.stringify(watchAllFields, null, 2)}
          <br />
          formState: {JSON.stringify(formState, null, 2)}
          <br />
          errors: {JSON.stringify(errors, null, 2)}
          <br />
          vialReconciliationType: {vialReconciliationType.toString()}
          <br />
          <br />
          <button onClick={() => reset()}>Reset form</button>
        </pre>
      )}
    </>
  )
}
