import { AxiosError } from 'axios'
import _ from 'lodash'
import React, {
  ChangeEvent,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { IAutoSuggestProps, matchInArray } from './AutoSuggest.model'
import {
  StyledAutoSuggestResultItem,
  StyledAutoSuggestWrapper,
  StyledNoResults
} from './AutoSuggest.styles'
import { ClinTheme } from '../../../ClinTheme'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  NewFeatureElements,
  PageNames
} from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinText } from '../../../components/ClinText'
import {
  UserRoleRecord,
  UserRole,
  isAusGaUser,
  isAusMaUser
} from '../../../constants'
import { useAppContext } from '../../../context/app'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { useNewFeaturesList } from '../../../hooks/useNewFeaturesList/useNewFeaturesList'
import { AnalyticsEvent } from '../../../services/Analytics'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import {
  AuthError,
  cancelGetProductsSuggestion,
  getProductsSuggestion
} from '../../../services/ApiService'
import { CatalogDtoSuggestionDto } from '../../../types/swaggerTypes'
export interface ISearchStylesProps {
  /** Show suggestions below search */
  showSuggestions?: boolean
  /** Show suggestions below search */
  showGlowEffect?: boolean
}

interface IAutoSuggestContainerProps {
  /** App path/locations to hide this component on */
  pathsToHide?: string[]
  /** Amount of debounce time in milliseconds */
  debounceTimeMs?: number
  /** Initial query string */
  initialQuery?: string
  /** Number of results to display */
  maxResultsToDisplay?: number
  /** No results text */
  noResultsText?: string
  /** No results text for ma users */
  maUserNoResultsText?: string
  /** No results path */
  noResultsPath?: string
  /** MA user path */
  maUserPath?: string
  /** Suggestion text */
  suggestionText?: string
  /** Suggestion path */
  suggestionPath?: string
  /** Place holder text */
  placeholderText?: string
  /** Optional border radious for last li element in suggestions*/
  borderRadiusLastLiElement?: string
  /** Optional parametar color for suggestion text*/
  suggestionTextColor?: string
  /** Optional parametar padding for suggestion text*/
  suggestionTextPadding?: string
  /** Search component to pass through */
  searchComponent(props: IAutoSuggestProps): ReactNode
  /** Call back to reveal query */
  handleOnSearch?: (query: string) => void
  /** Call back to broadcast onChange */
  handleOnChange?: (query: string) => void
}

export const AutoSuggest: FunctionComponent<IAutoSuggestContainerProps> = ({
  pathsToHide,
  searchComponent,
  debounceTimeMs = 500,
  handleOnSearch,
  handleOnChange,
  initialQuery = '',
  borderRadiusLastLiElement,
  maxResultsToDisplay = 10,
  suggestionTextColor,
  suggestionTextPadding,
  suggestionText = 'Looking for something else?',
  suggestionPath = '/products/catalogue',
  maUserPath = '/results',
  noResultsText = 'View all products and programs',
  maUserNoResultsText = 'View all programs',
  noResultsPath,
  placeholderText
}: IAutoSuggestContainerProps) => {
  const { t } = useTranslation()
  const { dispatch, userRole, portfolioCountryCode } = useAppContext()

  const isMaUser = !!(userRole && UserRoleRecord[userRole as UserRole].isMaUser)
  const location = useLocation()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [query, setQuery] = useState<string>(initialQuery)
  const [suggestions, setSuggestions] = useState<CatalogDtoSuggestionDto[]>([])
  const [highlightedIndex, setHighlightedIndex] = useState(-1)

  //check if user did't see this feature and if not return tooltip object with title and description.

  const { newFeaturesList } = useNewFeaturesList(PageNames.GlobalSearch)
  const featureExists: boolean =
    !!newFeaturesList[NewFeatureElements.GlobalSearchNewUserFeature]

  const resetAll = () => {
    setSuggestions([])
    setQuery('')
    setHighlightedIndex(0)
    setIsLoading(false)
    setShowSuggestions(false)
  }

  const requestSuggestions = useCallback(
    (searchString: string) => {
      if (!searchString || searchString === '') {
        resetAll()
        return
      }
      // Empty string means receiving programs and products simultaneously
      const userRoleFlag = isAusMaUser(portfolioCountryCode, userRole) // related to clos-1614 aus ma users can see products
        ? ''
        : isMaUser
        ? 'ManagedAccessProgram'
        : isAusGaUser(portfolioCountryCode, userRole) // added this condition related to clos-1610 aus ga users can not see access program
        ? 'Generic'
        : ''
      setIsLoading(true)
      cancelGetProductsSuggestion()
      analyticsServiceSingleton.trackEvent(AnalyticsEvent.SubmitSearchQuery, {
        query: searchString,
        searchLocation: 'global',
        searchAPI: 'suggest'
      })
      getProductsSuggestion({
        query: searchString,
        limit: maxResultsToDisplay,
        derivedFromFilter: userRoleFlag,
        countryCode: portfolioCountryCode
      })
        .then((results) => {
          setIsLoading(false)
          setHighlightedIndex(-1)
          setSuggestions(results.data.result)
          setShowSuggestions(
            results.data.result.length > 0 && searchString.length > 0
          )
          if (searchString && searchString !== '') {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.ResultsForSearchQuery,
              {
                query: searchString,
                numberResults: results.data.result.length
              }
            )
          }
        })
        .catch((error: AxiosError) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching suggestions. ${error.message} ${
                error.code ? error.code : ''
              }`
            )
          )
          setShowSuggestions(false)
        })
    },
    [dispatch, isMaUser, maxResultsToDisplay, portfolioCountryCode]
  )

  // Debounced call to API to request suggestions
  const debounceEventHandler = useMemo(
    () =>
      _.debounce(
        (searchString: string) => requestSuggestions(searchString),
        debounceTimeMs
      ),
    [requestSuggestions, debounceTimeMs]
  )

  const updateRecentSearches = (searchTerm: string, url: string) => {
    const storedSearchHistory = localStorage.getItem('recentSearches')
    const searchHistory = storedSearchHistory
      ? JSON.parse(storedSearchHistory)
      : []
    searchHistory.unshift({ searchTerm, url })
    const updatedSearchHistory = searchHistory.slice(0, 3)
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearchHistory))
  }

  // Helper function to render no results state
  const renderNoResults = (): ReactNode => {
    return (
      <>
        <StyledAutoSuggestResultItem
          tabIndex={0}
          key={'no-results-title'}
          style={{
            color: ClinTheme.colors.black,
            fontSize: ClinTheme.fontSizes[2]
          }}
        >
          {t('global_search:no_suggestions')}
        </StyledAutoSuggestResultItem>
        <StyledAutoSuggestResultItem
          tabIndex={0}
          key={'no-results'}
          suggestionTextPadding={suggestionTextPadding}
          borderRadiusLastLiElement={borderRadiusLastLiElement}
          selected={highlightedIndex === suggestions.length}
        >
          {renderNoResultsLink()}
        </StyledAutoSuggestResultItem>
      </>
    )
  }

  // Helper function to render the no results link or text
  const renderNoResultsLink = (): ReactNode => {
    const text = isMaUser ? maUserNoResultsText : noResultsText
    const path = isMaUser
      ? `${maUserPath}?q=*`
      : noResultsPath || suggestionPath

    if (noResultsPath) {
      return (
        <Link
          to={path}
          style={{
            fontWeight: ClinTheme.fontWeights.medium,
            color: suggestionTextColor ?? ClinTheme.colors.black,
            fontSize: ClinTheme.fontSizes[2]
          }}
        >
          {text}
        </Link>
      )
    }

    return (
      <StyledNoResults
        onClick={() => {
          resetAll()
          handleOnSearch && handleOnSearch('*')
        }}
      >
        <ClinText
          fontWeight={ClinTheme.fontWeights.medium}
          fontSize={ClinTheme.fontSizes[2]}
        >
          {text}
        </ClinText>
      </StyledNoResults>
    )
  }

  // Helper function to render a single suggestion item
  const renderSuggestionItem = (
    suggestion: CatalogDtoSuggestionDto,
    index: number
  ): ReactNode => {
    // Separate out bolded text
    const matchingText = suggestion.text
    const genericName = suggestion.document.catalogItemName
    const brands = suggestion.document.brands

    // Strip tags to get generic or brand name
    const genericOrBrand = matchingText.replace(/<(\/)?(b)[^>]*>/g, '')

    // Determine which is the match
    const isGeneric = genericName === genericOrBrand

    // If its a brand show that brand as markup
    const programOrProduct =
      suggestion.document.derivedFrom === 'ManagedAccessProgram'
        ? `/programs/access-programs/${suggestion.document.programId}`
        : `/product/${suggestion.document.catalogDocumentId}`

    return (
      <StyledAutoSuggestResultItem
        key={`${suggestion.document.catalogDocumentId}-${index}`}
        tabIndex={0}
        selected={highlightedIndex === index}
      >
        <Link
          to={programOrProduct}
          tabIndex={-1}
          onClick={() => {
            featureExists && handleClick()
            updateRecentSearches(
              suggestions[index].document.catalogItemName,
              programOrProduct
            )
          }}
        >
          <div
            tabIndex={-1}
            style={{
              color: ClinTheme.colors.black,
              fontSize: ClinTheme.fontSizes[2],
              fontWeight: ClinTheme.fontWeights.normal
            }}
            dangerouslySetInnerHTML={{
              // If its a generic display matching text in generic
              __html: isGeneric ? matchingText : genericName
            }}
          />
          <ClinText as="div" color={ClinTheme.colors.darkGrey}>
            {renderBrands(brands, isGeneric, genericOrBrand, matchingText)}
          </ClinText>
        </Link>
      </StyledAutoSuggestResultItem>
    )
  }

  // Helper function to render brand names
  const renderBrands = (
    brands: string[],
    isGeneric: boolean,
    genericOrBrand: string,
    matchingText: string
  ): ReactNode => {
    return brands.map((brand, idx) => {
      if (!isGeneric && brand === genericOrBrand) {
        return (
          <span
            key={`${brand}-${idx}`}
            dangerouslySetInnerHTML={{ __html: matchingText }}
          />
        )
      }

      return idx !== brands.length - 1 ? (
        <span key={`${brand}-${idx}`}>{brand}, </span>
      ) : (
        <span key={`${brand}-${idx}`}>{brand}</span>
      )
    })
  }

  // Helper function to render the "Looking for something else?" link
  const renderSuggestionLink = (): ReactNode => {
    const path = isMaUser ? maUserPath : suggestionPath

    return (
      <StyledAutoSuggestResultItem
        suggestionTextPadding={suggestionTextPadding}
        borderRadiusLastLiElement={borderRadiusLastLiElement}
        tabIndex={0}
        key={'no-results'}
        selected={highlightedIndex === suggestions.length}
      >
        <Link
          to={path}
          onClick={() => {
            updateRecentSearches(suggestionText, path)
          }}
          style={{
            fontWeight: ClinTheme.fontWeights.medium,
            color: suggestionTextColor ?? ClinTheme.colors.black,
            fontSize: ClinTheme.fontSizes[2]
          }}
        >
          {suggestionText}
        </Link>
      </StyledAutoSuggestResultItem>
    )
  }

  // Simplified main rendering function with reduced complexity
  const renderSuggestions = (
    items: CatalogDtoSuggestionDto[]
  ): ReactNode | ReactNode[] => {
    // Handle empty results
    if (items && items.length === 0 && !isLoading) {
      return renderNoResults()
    }

    // Handle items with suggestions
    if (!items || items.length === 0) {
      return null
    }

    // Map each suggestion to its rendered component
    const suggestionItems = items.map(renderSuggestionItem)

    // Add the "Looking for something else?" link at the end
    return [...suggestionItems, renderSuggestionLink()]
  }

  const handleEscapeKey = () => {
    resetAll()
  }

  const handleDownOrTabKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()
    const INITIAL_IDX = 0
    const idx = highlightedIndex
    const nextIdx = idx !== undefined ? idx + 1 : INITIAL_IDX

    if (nextIdx < suggestions.length) {
      setHighlightedIndex(nextIdx)
    } else {
      setHighlightedIndex(INITIAL_IDX)
    }
  }

  const handleUpOrShiftTabKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()
    const lastIdx = suggestions.length - 1
    const idx = highlightedIndex
    const prevIdx = idx !== undefined ? idx - 1 : lastIdx

    if (prevIdx >= 0) {
      setHighlightedIndex(prevIdx)
    } else {
      setHighlightedIndex(lastIdx)
    }
  }

  const handleEnterKey = () => {
    if (highlightedIndex >= 0) {
      handleSelect && handleSelect(highlightedIndex)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const UP = 'ArrowUp'
    const DOWN = 'ArrowDown'
    const ENTER = 'Enter'
    const ESCAPE = 'Escape'
    const TAB = 'Tab'

    switch (e.key) {
      case ESCAPE:
        handleEscapeKey()
        break
      case DOWN:
      case TAB:
        handleDownOrTabKey(e)
        break
      case UP:
        handleUpOrShiftTabKey(e)
        break
      case ENTER:
        handleEnterKey()
        break
      default:
        // Handle shift+tab as a special case
        if (e.shiftKey && e.key === TAB) {
          handleUpOrShiftTabKey(e)
        }
        break
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const queryStr = e.target.value
    setQuery(queryStr)
    debounceEventHandler(queryStr)
    handleOnChange && handleOnChange(queryStr)
  }

  //clos new feature tooltip if user click on link of suggested products
  const handleClick = () => {
    const element = document.getElementById('global-search-new-user')
    if (element) {
      element.click()
    }
  }

  const handleEnter = () => {
    featureExists && handleClick()
    const userRolePath = isMaUser ? `/results` : `/products/catalogue`
    handleOnSearch
      ? handleOnSearch(query)
      : history.push(`${userRolePath}?q=${encodeURIComponent(query)}`)
  }

  const handleSelect = (selectedIndex: number) => {
    const userRolePath = isMaUser ? `/programs/access-programs/` : `/product/`
    const itemId = isMaUser ? `programId` : `catalogDocumentId`
    if (suggestions[selectedIndex]) {
      updateRecentSearches(
        suggestions[selectedIndex].document.catalogItemName,
        `${userRolePath}${suggestions[selectedIndex].document[itemId]}`
      )
      history.push(
        `${userRolePath}${suggestions[selectedIndex].document[itemId]}`
      )
    }
  }

  // Detect outside click
  const parentNode = useRef<HTMLDivElement>(null)
  const handleClickedOutside = (event: MouseEvent) => {
    if (parentNode?.current === (event.target as Node)) {
      // inside click
      return
    }
    // outside click - clear results
    resetAll()
  }

  useEffect(() => {
    document.addEventListener('click', handleClickedOutside)
    return () => document.removeEventListener('click', handleClickedOutside)
  })

  useEffect(() => {
    resetAll()
    return () => cancelGetProductsSuggestion()
  }, [location.pathname])

  useEffect(() => {
    setQuery(initialQuery)
  }, [initialQuery])

  if (pathsToHide && matchInArray(location.pathname, pathsToHide)) {
    return null
  }

  return (
    <StyledAutoSuggestWrapper ref={parentNode}>
      {searchComponent({
        query,
        isLoading,
        showSuggestions: showSuggestions,
        suggestions,
        renderSuggestions,
        placeholderText,
        handleChange,
        handleKeyDown,
        handleEnter
      } as IAutoSuggestProps)}
    </StyledAutoSuggestWrapper>
  )
}
