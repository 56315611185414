import { AxiosError } from 'axios'
import { SetStateAction, useEffect, Dispatch } from 'react'

import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { IPagination } from '../../components/ClinPagination/ClinPagination.model'
import { AppEvent } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import {
  AuthError,
  cancelGetBookmarks,
  getBookmarks
} from '../../services/ApiService'
import { BookmarkableItemType } from '../../types/BookmarkableItem'
import {
  BookmarkDto,
  BookmarkSearchDto,
  WarehouseDto
} from '../../types/swaggerTypes'

export enum BookmarkStatus {
  NotBookmarked,
  Bookmarked,
  Loading
}

export interface IBookmarkDto extends BookmarkDto {
  warehouse: WarehouseDto
}

export const useAugmentedBookmarks = (
  searchParams: BookmarkSearchDto,
  itemType: BookmarkableItemType,
  selectedTabIndex: number,
  dispatch: (event: AppEvent) => void,
  setBookmarks: Dispatch<SetStateAction<BookmarkDto[] | null>>,
  setPagination: Dispatch<SetStateAction<IPagination>>
): void => {
  useEffect(() => {
    cancelGetBookmarks()
    setBookmarks(null)
    getBookmarks(searchParams)
      .then((bookmarks) => {
        // TODO: BookmarkSearchResultDto?
        // @ts-ignore
        setBookmarks(bookmarks.data.result)
        // @ts-ignore
        setPagination(bookmarks.data.pagination)
      })
      .catch((error: AxiosError) => {
        // If request is cancelled continue
        if (error.message === AuthError.RequestCancelled) {
          return
        }
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `There was an error updating your bookmark. ${error.message} ${
              error.code ? error.code : ''
            }`
          )
        )
      })
    return () => cancelGetBookmarks()
  }, [
    dispatch,
    itemType,
    searchParams,
    selectedTabIndex,
    setBookmarks,
    setPagination
  ])
}
