import { DateTime } from 'luxon'

import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ICustomSelectOption } from '../../../features/CustomSelect/CustomSelect'
import { ProgramSearchDto } from '../../../types/swaggerTypes'

export const defaultState: ICreatePatientFormState = {
  patientInitials: '',
  dateOfBirth: '',
  instituteIdentifier: '',
  program: 0,
  physician: '',
  realWorldDataEnrolFlag: false,
  stockOrderFlag: false,
  rwdNominationDetails: {
    nominationFlag: false,
    fullName: '',
    emailAddress: '',
    classification: ''
  }
}

export const maximumDate = {
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  day: new Date().getDate()
}

export interface ICreatePatientFormState {
  patientInitials: string
  dateOfBirth: string
  instituteIdentifier: string
  program?: number
  physician?: string
  realWorldDataEnrolFlag?: boolean
  stockOrderFlag?: boolean
  rwdNominationDetails: CreatePatientRwdNominationDetailsDTO
}

export interface CreatePatientRwdNominationDetailsDTO {
  nominationFlag?: boolean
  fullName: string
  emailAddress: string
  classification: string
}

export const defaultEnrolledSearchParams: ProgramSearchDto = {
  query: '',
  filter: {
    therapeuticAreas: [],
    availabilities: []
  },
  pagination: {
    skip: 0,
    take: 100
  },
  sorting: {
    sortBy: 'programName',
    order: SortDirectionType.Ascending
  }
}
// Some programs have a business case rule that the date should have only a MM and YYYY,
// however the backend is built on dates and the OPA validation works with the DD as 01
// Program Sotatercept by Merck Sharp and Dohme LLC has this condition apply only in case of Uk and Ireland --Program id is 256020
export const MonthYearOnlyProgramsIds = [
  79009, 79011, 79010, 225018, 256020, 337021
]
// Some programs have a business case rule that the date should have only a YYYY,
// however the backend is built on dates and the OPA validation works with the DD and MM as 01/01
export const YearOnlyProgramIds = [100011, 270021, 325022, 289020, 265020]
// Some programs have a business case rule that the date should have only a YYYY but set to 1950 and disabled
export const YearOnlySet1950ProgramIds = [351020, 340020, 107014]
// Some programs are gated by being over 18 years of age
export const DOBGreaterThan18ProgramIds: number[] = [223018, 207021, 277021]
// Some programs are gated by being under the age of 18
export const DOBLesserThan18ProgramIds: number[] = [2097]
// //RWD opt in is mandatory for these program/country combinations223018
// export const RWDOptInMandatoryForProgramIdandCountryCode : RWDProgramCountryStruct = {}
// Some programs require the initials to be ZZZ
export const InitialsToZZZProgramsIds = [270021, 325022, 351020, 340020, 107014]
// Some programs require the initials to be AAA, BBB, etc
export const InitialsToAAAProgramsIds = [289020, 265020]

export const isRWDMandatoryForProgramCountry = (
  countryCode: string
): boolean => {
  return countryCode === 'FR'
}

export const generateYearsArray = (
  startYear: number,
  endYear: number
): ICustomSelectOption[] => {
  const years: ICustomSelectOption[] = []
  for (let year = endYear; year > startYear; year--) {
    years.push({
      id: year,
      value: year.toString(),
      isActive: false
    })
  }
  return years
}

export const yearOptions = generateYearsArray(1910, new Date().getFullYear())
export const monthOptions: ICustomSelectOption[] = [
  {
    id: '01',
    value: 'January',
    isActive: false
  },
  {
    id: '02',
    value: 'February',
    isActive: false
  },
  {
    id: '03',
    value: 'March',
    isActive: false
  },
  {
    id: '04',
    value: 'April',
    isActive: false
  },
  {
    id: '05',
    value: 'May',
    isActive: false
  },
  {
    id: '06',
    value: 'June',
    isActive: false
  },
  {
    id: '07',
    value: 'July',
    isActive: false
  },
  {
    id: '08',
    value: 'August',
    isActive: false
  },
  {
    id: '09',
    value: 'September',
    isActive: false
  },
  {
    id: '10',
    value: 'October',
    isActive: false
  },
  {
    id: '11',
    value: 'November',
    isActive: false
  },
  {
    id: '12',
    value: 'December',
    isActive: false
  }
]
export const is18YearsOfAge = (dateOfBirth: string): boolean => {
  // Define the format of the input date string
  const format = 'LLLL d, yyyy'
  if (dateOfBirth === 'Invalid Date') {
    return false // Return false if the input is not long enough to be a valid date
  }
  // Parse the date of birth using the defined format
  const dob = DateTime.fromFormat(dateOfBirth, format)
  // Check if the date is valid
  if (!dob.isValid) {
    throw new Error('Invalid date format')
  }
  // Get the current date
  const now = DateTime.now()
  // Calculate the age in years
  const age = now.diff(dob, 'years').years
  return age >= 18
}

export const processDateWithProgramSpecificRules = (
  dateString: string,
  programId?: number,
  countryCode?: string
): string => {
  if (!programId || dateString === '') return ''
  // Parse the date from the string
  let date = DateTime.fromISO(dateString)

  // Check if the date is valid
  if (!date.isValid) {
    throw new Error('Invalid date format')
  }

  // Modify the date based on the flags
  if (YearOnlyProgramIds.includes(programId)) {
    // Set month and day to 01
    date = date.set({ month: 1, day: 1 })
  } else if (
    MonthYearOnlyProgramsIds.includes(programId) &&
    programId !== SotaterceptId
  ) {
    //Sotatercept by Merck Sharp and Dohme LLC has this condition apply only in case of Uk and Ireland
    // Set day to 01
    date = date.set({ day: 1 })
  } else if (YearOnlySet1950ProgramIds.includes(programId)) {
    date = date.set({ month: 1, day: 1, year: 1950 })
  } else if (
    programId === SotaterceptId &&
    (countryCode === 'GB' || countryCode === 'IE')
  ) {
    date = date.set({ day: 1 })
  }
  // Return the date in the original format
  return date.toFormat('yyyy-MM-dd')
}
export const SotaterceptId = 256020
