import React, { FunctionComponent, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { SideBarMode } from './ProgramDetail.models'
import {
  StyledCreateButton,
  StyledEnrolButton,
  StyledHeader,
  StyledHeaderButtonWrapper,
  StyledHeaderItems,
  StyledHeaderItemsWrapper,
  StyledStockOrderButton,
  SponsorLogo
} from './ProgramDetail.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinText } from '../../../components/ClinText'
import { ProgramCountryStatus } from '../../../constants'
import { useAppContext } from '../../../context/app'
import { useFeatureFlags } from '../../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../../context/featureFlags/FeatureFlagKeys'
import { AnalyticsEvent } from '../../../services/Analytics'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'

interface IProgramDetailHeaderProps {
  programId: number
  showStockOrderButton?: boolean
  programName: string | undefined
  sponsor: string | undefined
  programCountryStatus: string | undefined
  isProgramEnrolled?: boolean
  programInfoVariant?: SideBarMode
}

export const ProgramDetailHeader: FunctionComponent<
  IProgramDetailHeaderProps
> = ({
  programId,
  showStockOrderButton,
  programName,
  sponsor,
  programCountryStatus,
  isProgramEnrolled,
  programInfoVariant
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { userDetails, institute } = useAppContext()
  const [sponsorLogoExists, setSponsorLogoExists] = useState<
    boolean | undefined
  >(undefined)

  const { useFeatureFlag } = useFeatureFlags()
  const enableClientLogo = useFeatureFlag(FeatureFlagKeys.EnableClientLogo)

  const sponsorFormatted = sponsor
    ?.toLowerCase()
    .replace(/[^\w\s]/g, '')
    .replace(/\s+/g, '-')

  const sponsorLogoPath = `https://cloud.squidex.io/api/assets/clinigen-localisation-app/${sponsorFormatted}`

  useEffect(() => {
    if (!enableClientLogo) {
      setSponsorLogoExists(false)
      return
    }

    const img = new Image()

    img.onload = function () {
      setSponsorLogoExists(true)
    }

    img.onerror = function () {
      setSponsorLogoExists(false)
    }

    img.src = sponsorLogoPath
  }, [enableClientLogo, sponsorLogoPath])

  return (
    <StyledHeader>
      <Container>
        <Row>
          <Col sm={10}>
            <StyledHeaderItemsWrapper>
              {programName && (
                <StyledHeaderItems>
                  <ClinText
                    fontSize={ClinTheme.fontSizes[4]}
                    fontWeight={ClinTheme.fontWeights.medium}
                  >
                    {enableClientLogo && sponsorLogoExists ? (
                      <Row>
                        <Col
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <ClinText
                            fontSize={ClinTheme.fontSizes[4]}
                            fontWeight={ClinTheme.fontWeights.medium}
                            color={ClinTheme.colors.black}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <span>
                                {programName} {t('program_detail:by')}
                              </span>
                            </div>
                          </ClinText>
                          {sponsorLogoExists && (
                            <SponsorLogo
                              src={sponsorLogoPath}
                              alt="SponsorLogo"
                            />
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <>
                        {programName}
                        {sponsorLogoExists === false && sponsor && (
                          <ClinText
                            fontSize={ClinTheme.fontSizes[3]}
                            fontWeight={ClinTheme.fontWeights.medium}
                            color={ClinTheme.colors.darkGrey}
                          >
                            {t('program_detail:by')} {sponsor}
                          </ClinText>
                        )}
                      </>
                    )}
                  </ClinText>
                </StyledHeaderItems>
              )}
              {isProgramEnrolled ? (
                <StyledHeaderButtonWrapper>
                  {showStockOrderButton &&
                    programCountryStatus ===
                      ProgramCountryStatus.AcceptingNewPatients && (
                      <StyledStockOrderButton
                        variant="purpleSecondaryNarrow"
                        onClick={() => {
                          history.push(`/create-patient`, {
                            programId: programId,
                            stockOrder: true,
                            from: window.location.pathname
                          })
                        }}
                      >
                        {t('program_detail:stock_order_button')}
                      </StyledStockOrderButton>
                    )}
                  {programCountryStatus ===
                    ProgramCountryStatus.AcceptingNewPatients && (
                    <StyledCreateButton
                      variant="purpleNarrow"
                      onClick={() => {
                        analyticsServiceSingleton.trackEvent(
                          AnalyticsEvent.CreatePatient,
                          {
                            location: history.location.pathname,
                            instituteId: institute.data?.instituteId,
                            userId: userDetails?.contactCard.contactId
                          }
                        )

                        history.push(`/create-patient`, {
                          programId: programId,
                          from: window.location.pathname
                        })
                      }}
                    >
                      {t('program_detail:create_patient_button')}
                    </StyledCreateButton>
                  )}
                </StyledHeaderButtonWrapper>
              ) : (
                programInfoVariant === SideBarMode.NotEnrolled && (
                  <StyledHeaderButtonWrapper>
                    <StyledEnrolButton
                      variant="purpleNarrow"
                      onClick={() => {
                        history.push(
                          `/programs/access-programs/${programId}/enrolment-form`
                        )
                      }}
                    >
                      {t('program_detail:enrol_program_button')}
                    </StyledEnrolButton>
                  </StyledHeaderButtonWrapper>
                )
              )}
            </StyledHeaderItemsWrapper>
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  )
}
