import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'

export const StyledButtonContainer = styled.div({
  textAlign: 'right'
})

export const StyledContainer = styled.div({
  position: 'relative',
  backgroundColor: ClinTheme.colors.primaryMid,
  padding: ClinTheme.fontSizes[2],
  borderRadius: ClinTheme.radii[3],

  '&::after': {
    content: '""',
    position: 'absolute',
    top: `-${ClinTheme.space[3]}px`,
    left: `${ClinTheme.space[3]}%`,
    transform: 'translateX(-50%)',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderBottom: `20px solid ${ClinTheme.colors.primaryMid}`
  }
})

export const StyledBurgerButton = styled.div({
  cursor: 'pointer',
  marginTop: ClinTheme.space[2]
})

export const StyledButton = styled(ClinButton)({
  color: ClinTheme.colors.white,
  height: ClinTheme.fontSizes[5]
})
