import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from 'react'

import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { isAusGaUser, UserRole } from '../../constants'
import { AnnounceEventType, IAnnounceEvent } from '../../events/AnnounceEvent'
import { AuthEventType, IAuthEvent } from '../../events/AuthEvent'
import {
  BasketErrorEventType,
  IBasketErrorEvent
} from '../../events/BasketErrorEvent'
import {
  BootstrapEventType,
  IBootstrappedEvent
} from '../../events/BootstrapEvent'
import {
  ConfirmationEventType,
  IConfirmationEvent
} from '../../events/ConfirmationEvent'
import {
  DeliveryAddressEventType,
  IDeliveryAddress
} from '../../events/DeliveryAddress'
import {
  IInstituteEvent,
  InstituteEventType
} from '../../events/InstituteEvent'
import {
  IInstituteModalEvent,
  InstituteModalEventType
} from '../../events/InstituteModalEvent'
import {
  ProgramEnroledEvent,
  ProgramEnroledEventType
} from '../../events/ProgramEvents'
import {
  ISupportContactCountry,
  SupportContactCountryEventType
} from '../../events/SupportContact'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  cancelProgramsEnrolled,
  getCurrentUser,
  getInstituteForId,
  getSupportContact,
  programsEnrolled
} from '../../services/ApiService'
import authService from '../../services/AuthService'
import config from '../../services/ConfigProvider'
import { localStorageName } from '../../services/UserInstituteService'
import { IUser } from '../../types'
import { IAnnouncement } from '../../types/IAnnouncement'
import { IBasketError } from '../../types/IBasketError'
import {
  BootstrapErrorType,
  IBootstrapError
} from '../../types/IBootstrapError'
import { IConfirmation } from '../../types/IConfirmation'
import { IRestEndpointState } from '../../types/IRestEndpointState'
import {
  CountryDto,
  InstituteDto,
  LightweightInstituteDto,
  OrgAddressDto,
  UserDto
} from '../../types/swaggerTypes'
import { useOnMount } from '../../utils/useOnMount'

export interface IAppContext {
  dispatch: (event: AppEvent) => void
  isAuthenticated: boolean | undefined
  isBootstrapped: boolean
  user: IUser | undefined
  userDetails: UserDto | undefined
  userRole: UserRole | undefined
  announcement: IAnnouncement | undefined
  confirmation: IConfirmation | null
  isInstituteModalOpen: boolean | undefined
  instituteWasSwitched: boolean
  institute: IRestEndpointState<InstituteDto>
  institutes: LightweightInstituteDto[] | undefined
  defaultShippingAddress: OrgAddressDto | undefined
  isFirstAccess: boolean
  portfolioCountryCode: string
  supportContact: CountryDto
  basketError: IBasketError | undefined
  bootstrapError: IBootstrapError | undefined
  numberOfEnrolledPrograms: number | undefined
}

export type AppEvent =
  | IAuthEvent
  | IBootstrappedEvent
  | IAnnounceEvent
  | IConfirmationEvent
  | IInstituteModalEvent
  | IInstituteEvent
  | IDeliveryAddress
  | ISupportContactCountry
  | IBasketErrorEvent
  | ProgramEnroledEvent

const defaultAppContext: IAppContext = {
  dispatch: () => null,
  isAuthenticated: undefined,
  isBootstrapped: false,
  user: undefined,
  userDetails: undefined,
  userRole: undefined,
  announcement: undefined,
  confirmation: null,
  isInstituteModalOpen: undefined,
  instituteWasSwitched: false,
  institute: {
    isLoading: true
  },
  portfolioCountryCode: 'UNKNOWN',
  institutes: undefined,
  defaultShippingAddress: undefined,
  isFirstAccess: false,
  supportContact: {
    csEmailAddress: 'ukcustomerservice@clinigengroup.com',
    csPhoneNumber: '+44 (0) 1932 824100',
    rawPhoneNumber: '+441932824100'
  },
  basketError: undefined,
  bootstrapError: undefined,
  numberOfEnrolledPrograms: 0
}

export const AppContext = createContext<IAppContext>(defaultAppContext)

export const ProvideAppContext: FunctionComponent = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined
  )
  const [isBootstrapped, setIsBootstrapped] = useState<boolean>(false)
  const [isFirstAccess, setIsFirstAccess] = useState<boolean>(false)
  const [user, setUser] = useState<IUser>()
  const [userDetails, setUsersDetails] = useState<UserDto>()
  const [userRole, setUserRole] = useState<UserRole | undefined>()
  const [announcement, setAnnouncement] = useState<IAnnouncement>()
  const [confirmation, setConfirmation] = useState<IConfirmation | null>(null)
  const [isInstituteModalOpen, setIsInstituteModalOpen] = useState<
    boolean | undefined
  >()
  const [numberOfEnrolledPrograms, setNumberOfEnrolledPrograms] =
    useState<number>(0)
  const [instituteWasSwitched, setInstituteWasSwitched] =
    useState<boolean>(false)
  const [institute, setInstitute] = useState<IRestEndpointState<InstituteDto>>({
    isLoading: true
  })
  const [institutes, setInstitutes] = useState<LightweightInstituteDto[]>()

  const [defaultShippingAddress, setDefaultShippingAddress] =
    useState<OrgAddressDto>()

  const [portfolioCountryCode, setPortfolioCountryCode] =
    useState<string>('unknown')

  const [supportContact, setSupportContact] = useState<CountryDto>(
    defaultAppContext.supportContact
  )

  const [basketError, setBasketError] = useState<IBasketError>()

  const [bootstrapError, setBootstrapError] = useState<IBootstrapError>()
  useOnMount(() => {
    programsEnrolled({
      query: '',
      filter: {},
      pagination: {
        take: 100,
        skip: 0
      },
      sorting: {
        sortBy: 'programName',
        order: 'ASC'
      }
    }).then((response) => {
      setNumberOfEnrolledPrograms(response?.data.result.length ?? 0)
    })
    return () => {
      cancelProgramsEnrolled()
    }
  })
  const [appContext, setAppContext] = useState<IAppContext>({
    dispatch: (event: AppEvent) => {
      // console.info(event.type, event)
      // dispatch is always bound to the original closure from when
      // the component was first created, so we need to access the
      // current app context values differently

      switch (event.type) {
        case AuthEventType.GET_USER:
          authService
            .getUser()
            .then((currentUser) => {
              setIsAuthenticated(!!currentUser)
              currentUser && setUser(currentUser)
              // Get full user details
              getCurrentUser()
                .then((response) => {
                  const userDetails = response.data
                  window.localStorage.setItem(
                    'current_user',
                    JSON.stringify(userDetails)
                  )
                  // Check the account has been set up correctly with user roles
                  const hasUserRoles =
                    userDetails.contactCard.clientRelationships.length > 0
                  if (!hasUserRoles) {
                    setBootstrapError({
                      type: BootstrapErrorType.NO_USER_ROLES_SET,
                      description: `No user roles set in account [${
                        currentUser?.profile.name
                      } ${new Date(Date.now())}]`
                    })
                    analyticsServiceSingleton.trackError(
                      new Error(`No user roles set in account`)
                    )
                    return
                  }
                  //----------------------------
                  // Track Hotjar user details
                  //----------------------------
                  if ((window as any).hj) {
                    const primaryRole =
                      userDetails.contactCard.clientRelationships[0]
                    ;(window as any).hj(
                      'identify',
                      userDetails.contactCard.contactId,
                      {
                        email: userDetails.contactCard.email,
                        name: userDetails.contactCard.name,
                        specialism: userDetails.contactCard.specialism,
                        classification: userDetails.contactCard.classification,
                        institute: primaryRole.name,
                        roleType: primaryRole.roleType,
                        shipToCountryCode: primaryRole.shipToCountryCode
                      }
                    )
                  }
                  setUsersDetails(userDetails)
                })
                .catch((error) => {
                  setBootstrapError({
                    type: BootstrapErrorType.USER_DETAILS_ERROR,
                    description: `An error occurred while getting user details [${
                      currentUser?.profile.name
                    } ${new Date(Date.now())}]`
                  })
                  analyticsServiceSingleton.trackError(
                    error,
                    JSON.parse(localStorage.getItem('current_user') || '{}')
                  )
                })
            })
            .catch((error) => {
              setBootstrapError({
                type: BootstrapErrorType.USER_AUTH_ERROR,
                description: `An error occurred while authenticating the user [${new Date(
                  Date.now()
                )}]`
              })
              analyticsServiceSingleton.trackError(
                error,
                JSON.parse(localStorage.getItem('current_user') || '{}')
              )
            })

          break
        case AuthEventType.LOG_IN:
          authService
            .login(event.redirectUrl.replace(config.clientRoot, '/'))
            .then(() => {
              setIsAuthenticated(true)
            })
            .catch((err) => {
              console.warn('An error occurred while logging in')
              setIsAuthenticated(false)
              setAnnouncement({
                mode: AnnounceMode.Error,
                description: 'An error occurred while logging in'
              })
            })
          break
        case AuthEventType.GET_ROLE:
          const { userRole } = event
          setUserRole(userRole)
          break
        case BootstrapEventType.UPDATE:
          const { bool } = event
          setIsBootstrapped(bool)
          break
        case AnnounceEventType.SHOW:
          const { mode, description, title } = event
          setAnnouncement({ mode, description, title })
          break
        case AnnounceEventType.HIDE:
          setAnnouncement(undefined)
          break
        case ConfirmationEventType.SHOW:
          setConfirmation({ ...event })
          break
        case ConfirmationEventType.HIDE:
          setConfirmation(null)
          break
        case InstituteEventType.GET_INSTITUTES:
          const { institutes } = event
          setInstitutes(institutes)
          break
        case InstituteEventType.GET_INSTITUTE:
          const { instituteId } = event
          setInstitute({
            ...institute,
            isLoading: true
          })
          // Update on to window variable
          window.instituteId = null
          getInstituteForId(instituteId)
            .then((response) => {
              setInstitute({
                data: response.data,
                isLoading: false
              })
              // Save on to window variable
              window.instituteId = response.data.instituteId.toString()
              // Save default shipping address
              const defaultShippingAddress = response.data.shipTo.find(
                (add) => add.primaryFlag === 'Y'
              )
              if (defaultShippingAddress) {
                setPortfolioCountryCode(defaultShippingAddress.country)
                setDefaultShippingAddress(defaultShippingAddress)
              }
            })
            .catch(() => {
              setBootstrapError({
                type: BootstrapErrorType.INSTITUTE_ERROR,
                description: `There was an error fetching institute details. [${
                  user?.profile.name
                } ${new Date(Date.now())}]`
              })
            })
          break
        case InstituteEventType.SWITCH:
          setInstitute({
            isLoading: true
          })
          // Reset on to window variable
          window.instituteId = null
          const { dontNotify } = event
          const instituteRef = event.instituteId
          getInstituteForId(instituteRef)
            .then((response) => {
              // Make sure to update localStorage before updating app state,
              // as UserInstituteService reads the institute from localStorage
              localStorage.setItem(
                localStorageName,
                JSON.stringify(response.data)
              )
              setInstitute({
                data: response.data,
                isLoading: false
              })
              // Save on to window variable
              window.instituteId = response.data.instituteId.toString()

              // Save default shipping address
              const defaultShippingAddress = response.data.shipTo.find(
                (add) => add.primaryFlag === 'Y'
              )
              if (defaultShippingAddress) {
                setPortfolioCountryCode(defaultShippingAddress.country)
                setDefaultShippingAddress(defaultShippingAddress)
              }
              // Don't re-trigger a refresh of institutes call in institutes modal
              dontNotify && setInstituteWasSwitched(true)
              // Fire analytics event
              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.SwitchInstitute,
                { newInstituteId: instituteRef }
              )
            })
            .catch((error) => {
              setInstitute({
                isLoading: false,
                error: `Failed to set institute. ${error}`
              })
              // Update on to window variable
              window.instituteId = null
              setBootstrapError({
                type: BootstrapErrorType.INSTITUTE_ERROR,
                description: `There was an error fetching institute details. [${
                  user?.profile.name
                } ${new Date(Date.now())}]`
              })
              console.error(error)
            })
          break
        case DeliveryAddressEventType.SWITCH:
          const { address } = event
          setDefaultShippingAddress(address)
          break
        case InstituteModalEventType.SHOW:
          const path = window.location.pathname
          const onboardingCheck = path.includes('/onboarding')
          const accessProgramCheck =
            /\/programs\/access-programs\/\d+\/patient-access-form\/\d+\/institute\/\d+\/patient\/[^/]+/.test(
              path
            )

          if (!onboardingCheck && !accessProgramCheck) {
            setIsInstituteModalOpen(true)
          }
          break
        case InstituteModalEventType.HIDE:
          setIsInstituteModalOpen(false)
          break
        case SupportContactCountryEventType.UPDATE:
          const { countryCode } = event
          if (countryCode === 'unknown') break
          getSupportContact(countryCode)
            .then((response) => {
              setSupportContact(response.data)
            })
            .catch((error) => {
              setAnnouncement({
                mode: AnnounceMode.Error,
                description: `There was an error loading customer support information. ${error}`
              })
            })
          break
        case InstituteModalEventType.SET_FIRST_ACCESS:
          const { isFirstAccess } = event
          setIsFirstAccess(isFirstAccess)
          break
        case BasketErrorEventType.BASKET_ERROR:
          const { type, errorType } = event
          setBasketError({
            type,
            errorType
          })
          console.warn(`A basket error of type: ${errorType} occurred`)
          break
        case BasketErrorEventType.CLEAR_ERROR:
          setBasketError(undefined)
          break
        case ProgramEnroledEventType.AUTOENROLEDEVENT:
          setNumberOfEnrolledPrograms((n) => n + 1)
          break
        default:
          console.warn(`Unknown event received: ${event}.`)
      }
    },
    isAuthenticated,
    isBootstrapped,
    user,
    userDetails,
    userRole,
    announcement,
    confirmation,
    isInstituteModalOpen,
    institute,
    institutes,
    instituteWasSwitched,
    defaultShippingAddress,
    isFirstAccess,
    portfolioCountryCode,
    supportContact,
    basketError,
    bootstrapError,
    numberOfEnrolledPrograms
  })

  const localStorageAUSGAUserName = 'user_GA_AUS'

  useEffect(() => {
    const AusGaUser = isAusGaUser(portfolioCountryCode, userRole)
    localStorage.setItem(localStorageAUSGAUserName, AusGaUser.toString())
  }, [userRole, portfolioCountryCode])

  useEffect(() => {
    const newAppContext = {
      ...appContext,
      isAuthenticated,
      isBootstrapped,
      user,
      userDetails,
      userRole,
      announcement,
      confirmation,
      isInstituteModalOpen,
      institute,
      institutes,
      instituteWasSwitched,
      defaultShippingAddress,
      isFirstAccess,
      portfolioCountryCode,
      supportContact,
      basketError,
      bootstrapError,
      numberOfEnrolledPrograms
    }
    setAppContext(newAppContext)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    isBootstrapped,
    user,
    userDetails,
    userRole,
    announcement,
    confirmation,
    isInstituteModalOpen,
    institute,
    institutes,
    instituteWasSwitched,
    defaultShippingAddress,
    portfolioCountryCode,
    supportContact,
    isFirstAccess,
    basketError,
    bootstrapError,
    numberOfEnrolledPrograms
  ])

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  )
}

/**
 * Export as a custom function so have less boilerplate instead of useContext(AppContext)
 */
export const useAppContext = () => {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useAppContext must be used within a ProvideAppContext')
  }
  return context
}
