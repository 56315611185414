import { CountryAlphaCodes } from './countryAlpha2Codes'
import { TPhoneResponseType } from '../types/IContact'

/**
 * Values are used to compare against returned IDS roles
 */
export enum IdsUserRole {
  CUSTOMER = 'Customer',
  CLIENT = 'Client',
  ADMIN = 'Admin'
}

/**
 * Values are used to compare against returned user roles
 */
export enum UserRole {
  BASIC = 'XXCL_MVP_BASIC_USER',
  BASIC_MA = 'XXCL_MVP_BASIC_USER_MA_ONLY',
  SUPER = 'XXCL_MVP_SUPER_USER',
  SUPER_MA = 'XXCL_MVP_SUPER_USER_MA_ONLY'
}

export const nonMaUsers = [UserRole.BASIC, UserRole.SUPER]
export const maUsers = [UserRole.BASIC_MA, UserRole.SUPER_MA]
export const adminUsers = [UserRole.SUPER, UserRole.SUPER_MA]
export const allRoles = [
  UserRole.BASIC,
  UserRole.SUPER,
  UserRole.BASIC_MA,
  UserRole.SUPER_MA
]

interface UserRoleDetail {
  contactCode: UserRole
  isMaUser: boolean
  isAdmin: boolean
}

/**
 * Look up record/table for returning a contacts permission value and description
 * @usage:
 *
 * const { contactCode, contactType, contactDescription } = UserRoleRecord[contact]
 *
 */
export const UserRoleRecord: Record<UserRole, UserRoleDetail> = {
  [UserRole.BASIC]: {
    contactCode: UserRole.BASIC,
    isMaUser: false,
    isAdmin: false
  },
  [UserRole.BASIC_MA]: {
    contactCode: UserRole.BASIC_MA,
    isMaUser: true,
    isAdmin: false
  },
  [UserRole.SUPER]: {
    contactCode: UserRole.SUPER,
    isMaUser: false,
    isAdmin: true
  },
  [UserRole.SUPER_MA]: {
    contactCode: UserRole.SUPER_MA,
    isMaUser: true,
    isAdmin: true
  }
}

export interface IJobType {
  jobCode: string
  view: string
}

export const JobTypes: IJobType[] = [
  {
    jobCode: 'AP CLERK',
    view: 'Accounts payable clerk'
  },
  {
    jobCode: 'BUYER',
    view: 'Buyer'
  },
  {
    jobCode: 'DENTIST',
    view: 'Dentist'
  },
  {
    jobCode: 'NURSE',
    view: 'Nurse'
  },
  {
    jobCode: 'PHARMACIST',
    view: 'Pharmacist'
  },
  {
    jobCode: 'PHARMACIST TECHNICIAN',
    view: 'Pharmacist Technician'
  },
  {
    jobCode: 'PHYSICIAN',
    view: 'Physician'
  },
  {
    jobCode: 'PHYSICIANS ASSISTANT',
    view: 'Physician assistant'
  },
  {
    jobCode: 'PROJECT MANAGER',
    view: 'Project Manager'
  },
  {
    jobCode: 'PURCHASING MANAGER',
    view: 'Purchasing manager'
  },
  {
    jobCode: 'SITE CO_ORDINATOR',
    view: 'Site coordinator'
  },
  {
    jobCode: 'VETERINARY NURSE',
    view: 'Veterinary nurse'
  },
  {
    jobCode: 'VETERINARY SURGEON',
    view: 'Veterinary surgeon'
  }
]

export enum PhoneResponseType {
  GEN = 'GEN',
  MOBILE = 'MOBILE',
  FAX = 'FAX'
}

interface ContactResponseTypeDetail {
  type: string
  viewValue: string
  translationKey: string
}

/**
 * Look up record/table for returning phone types and view values
 * @usage:
 *
 * const { type, viewValue, translationKey } = phoneTypesRecord[contact]
 *
 */
export const phoneTypesRecord: Record<
  TPhoneResponseType,
  ContactResponseTypeDetail
> = {
  [PhoneResponseType.GEN]: {
    type: PhoneResponseType.GEN,
    viewValue: 'Phone',
    translationKey: 'glossary:phone'
  },
  [PhoneResponseType.MOBILE]: {
    type: PhoneResponseType.MOBILE,
    viewValue: 'Mobile',
    translationKey: 'glossary:mobile'
  },
  [PhoneResponseType.FAX]: {
    type: PhoneResponseType.FAX,
    viewValue: 'Fax',
    translationKey: 'glossary:fax'
  }
}

export enum ContactTitles {
  DR = 'DR.',
  MISS = 'MISS',
  MR = 'MR.',
  MRS = 'MRS.',
  MS = 'MS.',
  PROF = 'PROF',
  SIR = 'SIR',
  MX = 'MX'
}

interface UserTitle {
  transKey: string
}

/**
 * Look up record/table for returning a title value
 * @usage:
 *
 * const { transKey } = userTitleRecord[ContactTitles.DR]
 *
 */
export const userTitleRecord: Record<ContactTitles, UserTitle> = {
  [ContactTitles.DR]: {
    transKey: 'common:titles.dr'
  },
  [ContactTitles.MISS]: {
    transKey: 'common:titles.miss'
  },
  [ContactTitles.MR]: {
    transKey: 'common:titles.mr'
  },
  [ContactTitles.MRS]: {
    transKey: 'common:titles.mrs'
  },
  [ContactTitles.MS]: {
    transKey: 'common:titles.ms'
  },
  [ContactTitles.PROF]: {
    transKey: 'common:titles.prof'
  },
  [ContactTitles.SIR]: {
    transKey: 'common:titles.sir'
  },
  [ContactTitles.MX]: {
    transKey: 'common:titles.mx'
  }
}

export enum PhysicianTitles {
  DR = 'Dr.',
  MISS = 'Miss',
  MR = 'Mr.',
  MRS = 'Mrs.',
  MS = 'Ms.',
  PROF = 'Prof',
  SIR = 'Sir',
  MX = 'Mx.'
}

interface PhysicianTitle {
  transKey: string
}

/**
 * Look up record/table for returning a title value
 * @usage:
 *
 * const { transKey } = physicianTitleRecord[PhysicianTitles.DR]
 *
 */
export const physicianTitleRecord: Record<PhysicianTitles, PhysicianTitle> = {
  [PhysicianTitles.DR]: {
    transKey: 'common:titles.dr'
  },
  [PhysicianTitles.MISS]: {
    transKey: 'common:titles.miss'
  },
  [PhysicianTitles.MR]: {
    transKey: 'common:titles.mr'
  },
  [PhysicianTitles.MRS]: {
    transKey: 'common:titles.mrs'
  },
  [PhysicianTitles.MS]: {
    transKey: 'common:titles.ms'
  },
  [PhysicianTitles.PROF]: {
    transKey: 'common:titles.prof'
  },
  [PhysicianTitles.SIR]: {
    transKey: 'common:titles.sir'
  },
  [PhysicianTitles.MX]: {
    transKey: 'common:titles.mx'
  }
}

export enum PhoneContactResponseType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export enum ContactStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PENDING = 'Pending',
  ERROR = 'Error'
}

export enum UserRoleWithCountry {
  AusBase = 'australia-base-role',
  AusSuper = 'australia-super-role',
  AusBaseMa = 'australia-base-ma',
  AusSuperMa = 'australia-super-ma',
  Base = 'base-role',
  Super = 'super-role',
  BaseMa = 'base-ma',
  SuperMa = 'super-ma'
}

export enum OnboardingCommonJobs {
  Physician = 'Physician',
  Pharmacist = 'Pharmacist',
  SiteCoordinator = 'Site Co-ordinator',
  PharmacistTechnician = 'Pharmacist Technician',
  Nurse = 'Nurse'
}
export enum OnboardingCommonSpecialisms {
  MedicalOncology = 'Medical Oncology',
  Haematology = 'Haematology',
  GeneralPharmacy = 'General Pharmacy',
  Neurology = 'Neurology',
  InternalMedicine = 'Internal Medicine'
}

export const isValidRoleAndCountry = (
  countryCode: string,
  allowedCountryCode: CountryAlphaCodes,
  userRole: string | undefined,
  allowedRoles: UserRole[]
) => {
  return (
    userRole &&
    countryCode === allowedCountryCode &&
    allowedRoles.includes(userRole as UserRole)
  )
}

export const getUserRoleWithCountry = (
  countryCode: string,
  userRole: string | undefined
) => {
  if (countryCode === CountryAlphaCodes.Australia) {
    switch (userRole) {
      case UserRole.BASIC:
        return UserRoleWithCountry.AusBase
      case UserRole.SUPER:
        return UserRoleWithCountry.AusSuper
      case UserRole.BASIC_MA:
        return UserRoleWithCountry.AusBaseMa
      case UserRole.SUPER_MA:
        return UserRoleWithCountry.AusSuperMa
    }
  } else {
    switch (userRole) {
      case UserRole.BASIC:
        return UserRoleWithCountry.Base
      case UserRole.SUPER:
        return UserRoleWithCountry.Super
      case UserRole.BASIC_MA:
        return UserRoleWithCountry.BaseMa
      case UserRole.SUPER_MA:
        return UserRoleWithCountry.SuperMa
    }
  }
}

export const isAusGaUser = (
  countryCode: string,
  userRole: string | undefined
) => {
  const userRoleWithCountry = getUserRoleWithCountry(countryCode, userRole)

  return (
    userRoleWithCountry === UserRoleWithCountry.AusBase ||
    userRoleWithCountry === UserRoleWithCountry.AusSuper
  )
}

export const isAusMaUser = (
  countryCode: string,
  userRole: string | undefined
) => {
  const userRoleWithCountry = getUserRoleWithCountry(countryCode, userRole)

  return (
    userRoleWithCountry === UserRoleWithCountry.AusBaseMa ||
    userRoleWithCountry === UserRoleWithCountry.AusSuperMa
  )
}

export const isAusUser = (countryCode: string) => {
  return countryCode === CountryAlphaCodes.Australia
}
