import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinModal } from '../../../components/ClinModal'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'

interface IOpaExitPreventionModalProps {
  /** When the prompt should be enabled */
  isOpen?: boolean
  handleClose: () => void
}

export const OpaExitPreventionModal: FunctionComponent<
  IOpaExitPreventionModalProps
> = ({ handleClose, isOpen }) => {
  const { t } = useTranslation()

  return (
    <ClinModal
      maxWidth="xxs"
      padding={`${ClinTheme.space[5]}px`}
      isOpen={isOpen}
      isDismissible={false}
    >
      <Row>
        <Col>
          <ClinText
            variant={TypographyVariant.LargerParagraph}
            fontWeight={ClinTheme.fontWeights.bold}
            color={ClinTheme.colors.primary}
            lineHeight={`${ClinTheme.space[4]}px`}
            letterSpacing={`${ClinTheme.space[0]}px`}
          >
            {t('opa_checkout:opa_exit_prevention_modal.title')}
          </ClinText>
          <ClinText
            variant={TypographyVariant.LargeParagraph}
            lineHeight={`${ClinTheme.space[4]}px`}
          >
            {t('opa_checkout:opa_exit_prevention_modal.description')}
          </ClinText>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[3]} />
      <Row>
        <Col md={6}>
          <Row>
            <Col lg={7}>
              <ClinButton
                style={{
                  width: '100%',
                  background: ClinTheme.colors.primaryLight,
                  borderColor: ClinTheme.colors.primaryLight,
                  color: ClinTheme.colors.midGrey
                }}
                variant="purpleNarrow"
                onClick={() => {
                  handleClose()
                }}
              >
                {t('common:buttons.ok')}
              </ClinButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </ClinModal>
  )
}
