import React, { FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  StyledActions,
  StyledOverlayLoader,
  StyledSubmit
} from './OrderLineCancellationModal.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { ClinButton } from '../../components/ClinButton'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinLoader } from '../../components/ClinLoader'
import { ClinModal } from '../../components/ClinModal'
import { ClinSelect } from '../../components/ClinSelect'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinTextarea } from '../../components/ClinTextarea'
import {
  findOrderCancellationDescription,
  TOrderCancellationCodes
} from '../../types/IOrder'
import { OrderLineDto } from '../../types/swaggerTypes'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../utils/Forms/getInputPlaceholder'

interface IOrderLineCancellationModalProps {
  /** Control internal state of modal */
  orderLine?: OrderLineDto
  /** Control internal state of modal */
  isOpen?: boolean
  /** displays error notification */
  hasError?: boolean
  /** displays error notification */
  errorMessage?: string
  /** The selected cancellation reason  */
  selectedOption?: TOrderCancellationCodes | ''
  /** An array of availble cancelation reasons  */
  cancellationOptions?: TOrderCancellationCodes[] | null
  /** Whether the submission is still processing*/
  isSubmitting: boolean
  /** Handle change option change  */
  handleOptionChange: (id: string) => void
  /** Handle change option change  */
  handleCommentChange: (comment: string) => void
  /** Handle submit option  */
  handleSubmit: () => void
  /** Handle close modal  */
  handleClose: () => void
}

export const OrderLineCancellationModal: FunctionComponent<
  IOrderLineCancellationModalProps
> = ({
  orderLine,
  isOpen,
  hasError,
  errorMessage,
  selectedOption,
  cancellationOptions,
  isSubmitting,
  handleOptionChange,
  handleCommentChange,
  handleSubmit,
  handleClose
}) => {
  const { t } = useTranslation()
  return (
    <ClinModal
      onClose={handleClose}
      maxWidth="sm"
      height="auto"
      isOpen={isOpen}
    >
      {cancellationOptions && (
        <Container>
          <Row justify="center">
            <Col sm={12} md={11}>
              {errorMessage && hasError && (
                <Row>
                  <Col>
                    <ClinAnnounceBar title={'Error'}>
                      {errorMessage}
                    </ClinAnnounceBar>
                  </Col>
                </Row>
              )}
              {orderLine && (
                <Row>
                  <Col sm={12}>
                    <ClinText
                      as="h4"
                      variant={TypographyVariant.H4}
                      fontWeight={ClinTheme.fontWeights.bold}
                      wordBreak="break-word"
                    >
                      {t('order_line_cancelation_modal:title', {
                        order: orderLine.itemDescription
                      })}
                    </ClinText>
                  </Col>
                </Row>
              )}
              <ClinSpacer height={ClinTheme.space[4]} />
              <Row>
                <Col sm={12} md={10}>
                  <ClinText>
                    {t('order_line_cancelation_modal:description')}
                  </ClinText>
                </Col>
              </Row>
              <ClinSpacer height={ClinTheme.space[4]} />
              <Row>
                <Col>
                  <ClinSelect
                    defaultValue={selectedOption}
                    onChange={(event) =>
                      handleOptionChange(event.currentTarget.value)
                    }
                    label={t('order_line_cancelation_modal:select_reason')}
                    width={'100%'}
                  >
                    {!selectedOption && (
                      <option value="" disabled={true}>
                        {getInputPlaceholder(PlaceholderType.SelectInput)}
                      </option>
                    )}
                    {cancellationOptions.map(
                      (item: TOrderCancellationCodes, index: number) => (
                        <option key={`${index}`} value={`${item}`}>
                          {findOrderCancellationDescription[item]}
                        </option>
                      )
                    )}
                  </ClinSelect>
                </Col>
              </Row>
              <ClinSpacer height={ClinTheme.space[4]} />
              <Row>
                <Col>
                  <ClinTextarea
                    id="cancel-orderline-description"
                    label={t(
                      'order_line_cancelation_modal:additional_comments'
                    )}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleCommentChange(event.currentTarget.value)
                    }
                  />
                </Col>
              </Row>
              <ClinSpacer />
              <ClinSpacer height={ClinTheme.space[3]} hasBorder={true} />
              <StyledSubmit>
                <Row justify="between">
                  <Col sm={12} md={5} lg={6}>
                    <StyledActions>
                      <ClinButton
                        className="cancel"
                        onClick={() => handleClose()}
                      >
                        {t('common:buttons.cancel')}
                      </ClinButton>
                      <ClinButton
                        className="submit-cancellation"
                        variant="primary"
                        disabled={selectedOption === undefined}
                        onClick={() =>
                          selectedOption !== undefined && handleSubmit()
                        }
                      >
                        {t('order_line_cancelation_modal:cancel_this_order')}
                      </ClinButton>
                    </StyledActions>
                  </Col>
                </Row>
              </StyledSubmit>
            </Col>
          </Row>
        </Container>
      )}
      {isSubmitting && (
        <StyledOverlayLoader>
          <Container>
            <Row justify="center" align="center">
              <Col sm={12}>
                <ClinText
                  marginLeft="auto"
                  marginRight="auto"
                  marginBottom={ClinTheme.space[5]}
                  textAlign="center"
                  variant={TypographyVariant.LargeParagraph}
                >
                  {t('order_line_cancelation_modal:please_wait')}
                </ClinText>
                <ClinGroup justifyContent="center">
                  <div
                    style={{
                      height: ClinTheme.space[9] * 2,
                      width: ClinTheme.space[9] * 2
                    }}
                  >
                    <ClinLoader minHandDuration={'1s'} />
                  </div>
                </ClinGroup>
              </Col>
            </Row>
          </Container>
        </StyledOverlayLoader>
      )}
    </ClinModal>
  )
}
