import { AxiosError } from 'axios'
import { t } from 'i18next'
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from 'react'
import { RouteComponentProps } from 'react-router'

import { EditContact } from './EditContact'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { LovName } from '../../../constants'
import { AppContext } from '../../../context/app'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import {
  AuthError,
  cancelGetContactDetails,
  cancelGetSpecialisms,
  getContactDetails,
  updateContact
} from '../../../services/ApiService'
import {
  InstituteContactDto,
  UpdateContactDto
} from '../../../types/swaggerTypes'
import { useLov } from '../../../utils/useLov'

interface IEditContactRouteParams {
  contactId: string
}

interface IEditContactProps
  extends RouteComponentProps<IEditContactRouteParams> {}

export const EditContactContainer: FunctionComponent<IEditContactProps> = ({
  match,
  history
}) => {
  const { contactId } = match.params
  const { dispatch, institute } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [contact, setContact] = useState<InstituteContactDto | undefined>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { isLovLoading: specialismsLoading, lovValues: specialisms } = useLov(
    LovName.SPECIALISM
  )
  const { isLovLoading: jobTitlesLoading, lovValues: jobTitles } = useLov(
    LovName.JOB_TITLES
  )

  useEffect(() => {
    setIsLoading(true)
    contactId &&
      getContactDetails(contactId)
        .then((response) => {
          setContact(response.data)
          setIsLoading(false)
        })
        .catch((error: AxiosError) => {
          const { code, message } = error
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          // If we have a full error show it
          if (error.response) {
            const { title, detail } = error.response.data
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error retrieving this contact. ${
                  title ? title : ''
                } ${message ? message : ''}`
              )
            )
            console.warn(title, detail)
          } else {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error retrieving this contact. ${message} ${
                  code ? code : ''
                }`
              )
            )
          }
          setIsLoading(false)
        })
    return cancelGetContactDetails()
  }, [contactId, dispatch, institute])

  const handleFormSubmission = (data: UpdateContactDto) => {
    setIsSubmitting(true)
    contact &&
      updateContact(contactId, data)
        .then((response) => {
          if (response.status === 200) {
            history.push(
              {
                pathname: '/institute/details'
              },
              {
                title: t('edit_contact:user_updated')
              }
            )
          }
        })
        .catch((error: AxiosError) => {
          const { code, message } = error

          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          // If we have a full error show it
          if (error.response) {
            const { title, detail } = error.response.data
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `${t('edit_contact:error_submitting_updates')} ${
                  title ? title : ''
                } ${message ? message : ''}`
              )
            )
            console.warn(title, detail)
          } else {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `${t('edit_contact:error_submitting_updates')} ${message} ${
                  code ? code : ''
                }`
              )
            )
          }
          setIsSubmitting(false)
          window.scrollTo(0, 0)
        })
  }

  const handleCancel = () => {
    history.push('/institute/details')
  }

  useEffect(() => {
    return () => {
      cancelGetContactDetails()
      cancelGetSpecialisms()
    }
  }, [])

  return (
    <>
      <EditContact
        isLoading={isLoading || specialismsLoading || jobTitlesLoading}
        jobTitles={jobTitles}
        specialisms={specialisms}
        contact={contact}
        handleFormSubmission={handleFormSubmission}
        handleCancel={handleCancel}
        isSubmitting={isSubmitting}
      />
    </>
  )
}
