import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { StyleTranscriptDownload } from './SupportVideoGuide.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinResponsiveVideoEmbed } from '../../components/ClinResponsiveVideoEmbed'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { UserRole, isAusGaUser } from '../../constants'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'
import config from '../../services/ConfigProvider'
import { Video } from '../../types/ContentTypes'
import { truncateString } from '../../utils/truncateString'

interface ISupportVideoGuideProps {
  /** Video id provided to display a video */
  videoId: string
  /** The slug of the page */
  slug: string
  /** The main content of the page */
  content: Video
  /** A ui prop to inform if a file is being downloaded */
  isFileDownloading: boolean
  /** The name of the parent page */
  parentPageName: string
  /** User country*/
  userCountry: string
  /** User role*/
  userRole?: UserRole
  /** A function which is called when a user requests to go back to another page */
  handleGoBack?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** A function which is called when a user requests to download a file */
  handleDownload?: () => void
  /** A function which is called when a user requests to go back to another page */
  handlePageChangeRequest?: (path: string) => void
}

export const SupportVideoGuide: FunctionComponent<ISupportVideoGuideProps> = ({
  videoId,
  slug,
  content,
  isFileDownloading,
  parentPageName,
  userCountry,
  userRole,
  handleGoBack,
  handleDownload,
  handlePageChangeRequest
}) => {
  const { t } = useTranslation()
  const { enableNewHomePage } = useCheckUnableHomePage()
  return (
    <ClinPageContentFrame
      crumbs={[
        isAusGaUser(userCountry, userRole) && enableNewHomePage
          ? {
              path: '/home',
              name: t('navigation:home')
            }
          : { path: '/orders', name: t('orders:page_name') },
        { path: '/support', name: parentPageName },
        {
          path: `/support/${slug}`,
          name: content.flatData.title
            ? truncateString(content?.flatData?.title)
            : ''
        }
      ]}
    >
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col>
          <ClinButton variant="linkButton" onClick={handleGoBack}>
            <ClinIcon iconName={ClinIconPathName.ArrowLeft} />
            &nbsp;{t('common:support.back_to_btn')}
          </ClinButton>
          <ClinSpacer />
        </Col>
      </Row>
      <Row>
        <Col>
          <ClinText
            as="h1"
            fontWeight={ClinTheme.fontWeights.bold}
            variant={TypographyVariant.H2}
            marginTop="0px"
          >
            {content?.flatData?.title}
          </ClinText>
        </Col>
      </Row>
      <ClinSpacer />

      <Row>
        <Col sm={12}>
          <ClinResponsiveVideoEmbed
            title={content?.flatData?.title ?? 'Video Title'}
            videoId={videoId}
          />
        </Col>
      </Row>

      <ClinSpacer height={ClinTheme.space[5]} />

      <Row>
        <Col sm={12} md={10} lg={8}>
          <div
            dangerouslySetInnerHTML={{
              __html: `${content?.flatData?.description}`
            }}
          />
        </Col>
      </Row>

      {content?.flatData?.transcriptDownload?.[0]?.id && handleDownload && (
        <Row>
          <Col sm={12}>
            <StyleTranscriptDownload>
              <ClinButton
                isLoading={isFileDownloading}
                variant="ghost"
                onClick={() => handleDownload()}
                disabled={isFileDownloading}
              >
                {isFileDownloading ? 'Downloading ' : 'Download transcript '}
                &nbsp;
                {!isFileDownloading && (
                  <ClinIcon iconName={ClinIconPathName.Download} />
                )}
              </ClinButton>
            </StyleTranscriptDownload>
          </Col>
        </Row>
      )}

      {content.flatData.relatedVideos &&
        content.flatData.relatedVideos.length > 0 && (
          <>
            <ClinSpacer height={ClinTheme.space[9]} />
            <Row>
              <Col sm={3}>
                <ClinText
                  as="h3"
                  variant={TypographyVariant.H4}
                  marginTop="0px"
                  marginBottom={ClinTheme.space[7]}
                >
                  {t('common:support.related_videos')}
                </ClinText>
              </Col>
              <Col sm={9}>
                <Row>
                  {content.flatData.relatedVideos.map((video: Video) => {
                    return (
                      <Col sm={6} key={`video-thumbnail-${video.id}`}>
                        <ClinButton
                          as="a"
                          onClick={() =>
                            handlePageChangeRequest &&
                            handlePageChangeRequest(
                              `/support/video-guide/${video.flatData.vimeoId}`
                            )
                          }
                          variant="linkButton"
                        >
                          {video?.flatData?.vimeoThumbnail?.[0] && (
                            <img
                              src={`${config.cmsRoot}/api/assets/clinigen-online-services-2/${video.flatData.vimeoThumbnail[0].id}`}
                              width="100%"
                              alt={
                                video.flatData.vimeoThumbnail[0].fileName ?? ''
                              }
                            />
                          )}
                        </ClinButton>
                        <ClinText
                          fontWeight={ClinTheme.fontWeights.medium}
                          marginTop={ClinTheme.space[2]}
                          marginBottom={ClinTheme.space[4]}
                        >
                          {video.flatData.shortDescription}
                        </ClinText>
                      </Col>
                    )
                  })}
                </Row>
              </Col>
            </Row>
          </>
        )}
    </ClinPageContentFrame>
  )
}
