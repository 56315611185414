import { useEffect, useState } from 'react'

import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import {
  getEnrolledPhysicians,
  cancelGetEnrolledPhysicians
} from '../../services/ApiService'
import { PhysiciansEnrolledSummaryDto } from '../../types/swaggerTypes'

interface IUseGetEnrolledPhysicians {
  physiciansLoading: boolean
  physiciansError: boolean
  physicians?: PhysiciansEnrolledSummaryDto[]
}

export const useGetEnrolledPhysicians = (
  programId: string,
  isStatusReady: boolean
): IUseGetEnrolledPhysicians => {
  const { dispatch } = useAppContext()
  const [physiciansLoading, setPhysiciansLoading] = useState<boolean>(true)
  const [physiciansError, setPhysiciansError] = useState<boolean>(false)
  const [physicians, setPhysicians] = useState<PhysiciansEnrolledSummaryDto[]>()

  useEffect(() => {
    programId &&
      isStatusReady &&
      getEnrolledPhysicians(programId)
        .then((response) => {
          response && setPhysicians(response.data.physicians)
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the enrolled physicians. ${error}`
            )
          )
          setPhysiciansError(true)
        })
        .finally(() => {
          setPhysiciansLoading(false)
        })

    return () => {
      cancelGetEnrolledPhysicians()
    }
  }, [dispatch, isStatusReady, programId])

  return {
    physiciansLoading,
    physiciansError,
    physicians
  }
}
