import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../ClinButton'

export const StyledTooltip = styled.div((position) => ({
  position: 'relative',
  borderRadius: '8px'
}))

export const StyledFloatingUITooltip = styled.div(() => ({
  borderRadius: '8px',
  zIndex: ClinTheme.zIndices.confirmation
}))

export const StyledFloatingUITooltipArrow = styled.div<any>(
  ({ color, hideArrow }) => ({
    position: 'absolute',
    background: color ?? ClinTheme.colors.primaryMid,
    width: '15px',
    height: '15px',
    transform: 'rotate(45deg) !important',
    visibility: hideArrow ? 'hidden' : 'visible'
  })
)

export const StyledClinNewFeatureOverlay = styled.div({
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: '1'
})

export const StyledNextButton = styled(ClinButton)({
  color: ClinTheme.colors.white,
  paddingTop: `${2}px`,
  paddingBottom: `${2}px`,
  cursor: 'pointer'
})

export const StyledClinNewFeatureText = styled.div({
  width: '90%'
})
