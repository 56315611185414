import React from 'react'

import { Homepage } from './Homepage'
import { StyledHomeContainer } from './Homepage.styles'
import { ClinTheme } from '../../ClinTheme'
import useChangeBackgroundColor from '../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'

export const HomepageContainer: React.FC = () => {
  useChangeBackgroundColor(ClinTheme.colors.lightGrey)
  return (
    <StyledHomeContainer>
      <Homepage />
    </StyledHomeContainer>
  )
}
