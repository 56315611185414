import { ProgramColumnNames } from '../constants'
import { ProgramSummaryDto } from './swaggerTypes'
import {
  ProgramStatus,
  ProgramCountryStatus,
  ProgramAvailability
} from '../constants/program'

import { IIndexable } from '.'

export interface IProgramSummary extends ProgramSummaryDto {
  isOpen?: boolean
}

export type TProgramColumnNames =
  | ProgramColumnNames.ProgramName
  | ProgramColumnNames.Sponsor
  | ProgramColumnNames.TherapeuticArea
  | ProgramColumnNames.Status
  | ProgramColumnNames.Availability

export const findProgramStatus: IIndexable<ProgramStatus> = {
  [ProgramStatus.Open.toUpperCase()]: ProgramStatus.Open,
  [ProgramStatus.Closed.toUpperCase()]: ProgramStatus.Closed
}

export const findProgramCountryStatus: IIndexable<ProgramCountryStatus> = {
  [ProgramCountryStatus.AcceptingNewPatients.toUpperCase()]:
    ProgramCountryStatus.AcceptingNewPatients,
  [ProgramCountryStatus.OpenForResupply.toUpperCase()]:
    ProgramCountryStatus.OpenForResupply,
  [ProgramCountryStatus.Closed.toUpperCase()]: ProgramCountryStatus.Closed
}

export const findProgramAvailability: IIndexable<ProgramAvailability> = {
  [ProgramAvailability.Enrol.toUpperCase()]: ProgramAvailability.Enrol,
  [ProgramAvailability.NotEnrolled.toUpperCase()]:
    ProgramAvailability.NotEnrolled,
  [ProgramAvailability.NotAvailableInYourCountry.toUpperCase()]:
    ProgramAvailability.NotAvailableInYourCountry
}

interface IProgramStatus {
  translationKey: string
}

/**
 * Look up record/table for a translated program status
 * @usage:
 * const programStatus = ProgramTranslatedStatusRecord[ProgramCountryStatus.AcceptingNewPatients]
 */
export const ProgramTranslatedStatusRecord: Record<
  ProgramCountryStatus | ProgramStatus | ProgramAvailability,
  IProgramStatus
> = {
  [ProgramCountryStatus.AcceptingNewPatients]: {
    translationKey: 'program_detail:statuses.accepting_new_patients'
  },
  [ProgramCountryStatus.OpenForResupply]: {
    translationKey: 'program_detail:statuses.open_for_resupply'
  },
  [ProgramStatus.Open]: {
    translationKey: 'program_detail:statuses.open'
  },
  [ProgramStatus.Closed]: {
    translationKey: 'program_detail:statuses.closed'
  },
  [ProgramAvailability.NotYetAvailableInYourCountry]: {
    translationKey: 'program_detail:statuses.not_yet_available_in_your_country'
  },
  [ProgramAvailability.NotAvailableInYourCountry]: { translationKey: '' },
  [ProgramAvailability.Unknown]: { translationKey: '' },
  [ProgramAvailability.Enrol]: { translationKey: '' },
  [ProgramAvailability.NotEnrolled]: { translationKey: '' },
  [ProgramCountryStatus.Closed]: { translationKey: '' }
}
