import { AxiosError } from 'axios'
import { t } from 'i18next'
import { DateTime } from 'luxon'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'

import { CreatePatient } from './CreatePatient'
import {
  ICreatePatientFormState,
  defaultEnrolledSearchParams,
  processDateWithProgramSpecificRules
} from './CreatePatient.model'
import { ClinTheme } from '../../../ClinTheme'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { LovName } from '../../../constants'
import { useAppContext } from '../../../context/app'
import useFeatureFlagRedirect from '../../../context/featureFlags/FeatureFlagBasedRedirect'
import { FeatureFlagKeys } from '../../../context/featureFlags/FeatureFlagKeys'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { ICustomSelectOption } from '../../../features/CustomSelect/CustomPhysiciansSelect'
import useChangeBackgroundColor from '../../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { AnalyticsEvent } from '../../../services/Analytics'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import {
  createPatient,
  getPatients,
  AuthError,
  getEnrolledPhysicians,
  cancelGetEnrolledPhysicians,
  associatePhysician,
  getEnrolledPrograms,
  cancelGetEnrolledPrograms
} from '../../../services/ApiService'
import { IProgramSummary } from '../../../types'
import {
  CreatePatientRequestDTO,
  PatientSummaryDto,
  PatientSummarySearchDto,
  PhysiciansEnrolledSummaryDto
} from '../../../types/swaggerTypes'
import { useLov } from '../../../utils/useLov'
import { useOnMount } from '../../../utils/useOnMount'
import {
  CreatePatientsViewMode,
  OPAOrderTypes,
  PatientOrderStatus,
  getRandomColor,
  patientOrderStatusTooltipTranslation,
  patientOrderStatusTranslation
} from '../PatientDashboard/Patient.model'
import {
  StyledPatientColumn,
  StyledPatientDateNameWrap,
  StyledPatientInitialsAvatar,
  StyledStatusWrapper
} from '../PatientDashboard/PatientDashboard.styles'
import {
  IColumn,
  trimInitials
} from '../PatientDashboard/PatientDashboardContainer'
import StatusTooltip from '../PatientDashboard/StatusTooltip'
import StyledTrackingLinkWrapper from '../PatientDashboard/StyledTrackingLinkWrapper'
import { sortOptions } from '../Patients.model'

interface IProgramRouteParams {
  programId: string
}
type LocationState = {
  programId?: string
  from?: string
  physicianIds?: string
  orderType?: string
  patientNumber?: string
  stockOrder?: boolean
  patientCreationFlag?: boolean
  patientId?: number
}
interface ICreatePatientsProps
  extends RouteComponentProps<
    IProgramRouteParams,
    StaticContext,
    LocationState
  > {}

export const CreatePatientContainer: FunctionComponent<
  ICreatePatientsProps
> = ({ history, match, location }) => {
  const { portfolioCountryCode } = useAppContext()
  useFeatureFlagRedirect(FeatureFlagKeys.PatientCentricJourneyPerUser)

  const duplicatePatientSearchParams: PatientSummarySearchDto = {
    query: '',
    filter: {
      programs: [],
      patientOrderStatuses: [],
      physicianId: undefined,
      showMyPatient: false,
      showMyPhysician: false,
      showMyProgram: false
    },
    pagination: {
      skip: 0,
      take: 5
    },
    sorting: {
      sortBy: sortOptions[0].optionId,
      order: SortDirectionType.Ascending
    }
  }

  const [viewMode, setViewMode] = useState<CreatePatientsViewMode>(
    CreatePatientsViewMode.ShowForm
  )

  const [patientDuplicate, setPatientDuplicate] = useState<
    PatientSummaryDto[] | undefined
  >(undefined)

  const [selectedProgramId, setSelectedProgramId] = useState<
    string | undefined
  >('')
  const { dispatch } = useAppContext()

  const [physiciansLoading, setPhysiciansLoading] = useState<boolean>(false)
  const [physicians, setPhysicians] = useState<
    PhysiciansEnrolledSummaryDto[] | undefined
  >()

  const [physicianPermissionVisible, setPhysicianPermissionVisible] =
    useState<boolean>(false)
  const [physicianPermission, setPhysicianPermission] = useState<boolean>(false)
  const [preselectedPhysician, setPreselectedPhysician] = useState<string>('')
  const [isEnrolled, setIsEnrolled] = useState<boolean>(false)
  const [selectedPhysician, setSelectedPhysician] = useState<
    ICustomSelectOption | undefined
  >()

  const [enrolledPrograms, setEnrolledPrograms] = useState<string[]>([])

  const { lovValues: jobTitles } = useLov(LovName.JOB_TITLES)

  //Background
  useChangeBackgroundColor(ClinTheme.colors.lightGrey)

  useEffect(() => {
    if (selectedProgramId && isEnrolled) {
      setPhysiciansLoading(true)
      getEnrolledPhysicians(selectedProgramId)
        .then((response) => {
          response && setPhysicians(response.data.physicians)
          setPhysiciansLoading(false)
          if (history.location.state && history.location.state.physicianIds) {
            setPreselectedPhysician(history.location.state.physicianIds)
          }
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the enrolled physicians. ${error}`
            )
          )
        })
        .finally(() => {
          setPhysiciansLoading(false)
        })
    } else {
      setPhysicians(undefined)
      setPhysicianPermission(false)
      setPhysicianPermissionVisible(false)
    }

    return () => {
      cancelGetEnrolledPhysicians()
    }
  }, [selectedProgramId, isEnrolled, dispatch, history.location.state])

  const handleEditDetails = () => {
    setViewMode(CreatePatientsViewMode.ShowForm)
  }

  //TODO refactor this with parsing to object
  const parsePatientNumberFromErrorMessage = (message: any) => {
    if (!message) return ''

    const messageLine = message.split(',')
    if (!messageLine[7] || !messageLine[8]) return ''

    const errorCodeResponse = messageLine[8]
    if (!errorCodeResponse) return ''

    const errorCodeLine = errorCodeResponse.split(':')
    if (!errorCodeLine[2]) return ''

    const errorCode = errorCodeLine[2]
    if (!errorCode.includes('XXCL_CREAT_PAT_DUP_PAT_ERR')) return ''

    const messageLineText = messageLine[7].split(':')
    if (!messageLineText[3]) return ''

    const patientNumberLine = messageLineText[3]
    const patientNumberLineSplited = patientNumberLine.split(' ')
    if (!patientNumberLineSplited[1]) return ''

    const patientId = patientNumberLineSplited[1]
    return patientId
  }

  const handleGoToExistingPatient = (
    physicianId: string,
    patientId: number
  ) => {
    history.push(`/programs/my-physicians/${physicianId}/${patientId}`)
  }

  const getFormattedDate = (date: string) => {
    const parsedDate = DateTime.fromFormat(date, 'MMMM d, yyyy')
    let formattedDate: string = ''
    // Format the parsed date to the desired format
    if (parsedDate.isValid) {
      formattedDate = parsedDate.toFormat('yyyy-MM-dd')
    }
    return formattedDate
  }

  const handleCreatePatient = (
    values: ICreatePatientFormState,
    flagValidatePatient: boolean
  ) => {
    setViewMode(CreatePatientsViewMode.Submitting)
    //Form DOB with special cases
    const formatedDate = values.stockOrderFlag
      ? ''
      : getFormattedDate(values.dateOfBirth) // Stock has '' as date
    const finalDate = processDateWithProgramSpecificRules(
      formatedDate,
      values.program,
      portfolioCountryCode
    ) // Additional program rules
    //Manual override of form values in case of a stock order. BE doesnt handle this for some reason
    const payload: CreatePatientRequestDTO = {
      ...values,
      patientInitials: values.stockOrderFlag ? '' : values.patientInitials,
      dateOfBirth: finalDate,
      realWorldDataEnrolFlag: values.realWorldDataEnrolFlag ?? false,
      patientValidationFlag: flagValidatePatient,
      stockOrderFlag: values.stockOrderFlag
    }

    createPatient(values.physician!, Number(values.program!), payload)
      .then((response) => {
        if (response) {
          response.data?.realWorldDataEnrolFlag &&
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.EnrolPatientIntoRWD,
              {
                patientId: response.data?.patientId,
                physicianId: response.data?.physicianId,
                programId: response.data?.programId
              }
            )

          handleRedirectUserToOrderOPA(
            values,
            response.data.patientNumber,
            values.stockOrderFlag
              ? OPAOrderTypes.StockOrder
              : OPAOrderTypes.InitialOrder,
            response.data.patientId
          )
        }
      })
      .catch((error: AxiosError) => {
        let patientNumber: string | undefined = '' //TODO read from error response :'(
        if (error.message === AuthError.RequestCancelled) {
          return
        }

        if (error.response && error.response.data) {
          patientNumber = parsePatientNumberFromErrorMessage(
            error.response.data.dependency.response
          )
        }

        if (patientNumber) {
          getPatients({
            ...duplicatePatientSearchParams,
            query: patientNumber
          })
            .then((response) => {
              response &&
                response.data &&
                setPatientDuplicate(response.data.result)
              setViewMode(CreatePatientsViewMode.DuplicatePatient)
            })
            .catch((error: AxiosError) => {
              setViewMode(CreatePatientsViewMode.ShowForm)
              window.scrollTo({
                behavior: 'smooth',
                top: 0
              })
              dispatch(
                createAnnounceEvent(
                  AnnounceMode.Error,
                  `There was an error with getting patient. ${error.message} ${
                    error.code ? error.code : ''
                  }`
                )
              )
            })
        } else {
          setViewMode(CreatePatientsViewMode.ShowForm)
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error with creating patient. ${error.message} ${
                error.code ? error.code : ''
              }`
            )
          )
        }
      })

      .finally()
  }

  const handleRedirectUserToOrderOPA = (
    values: ICreatePatientFormState,
    patientNumber: string,
    orderType: OPAOrderTypes,
    patientId: number
  ) => {
    if (values.program && values.physician) {
      history.push({
        pathname: `/programs/access-programs/${values.program}/patient-access-form/${values.physician}`,
        state: {
          orderType: orderType,
          patientNumber: patientNumber,
          patientId: patientId,
          stockOrder: values.stockOrderFlag,
          patientCreationFlag: true,
          from: location?.state?.from
        }
      })
    }
  }

  const handleCreatePatientSubmit = (
    values: ICreatePatientFormState,
    flagValidatePatient: boolean
  ) => {
    if (
      (!physicianPermissionVisible || physicianPermission) &&
      selectedPhysician
    ) {
      !selectedPhysician.isActive
        ? associatePhysician(selectedPhysician.id.toString())
            .then((response) => {
              if (response.status === 200) {
                handleCreatePatient(values, flagValidatePatient)
              }
            })
            .catch((error: AxiosError) => {
              dispatch(
                createAnnounceEvent(
                  AnnounceMode.Error,
                  `There was an error with associate physician. ${error}`
                )
              )
            })
        : handleCreatePatient(values, flagValidatePatient)
    }
  }

  const columns: IColumn[] = [
    {
      title: t('patient_detail:patient_table_columns.patient_column'),
      renderValue: function column(patient: PatientSummaryDto, index?: number) {
        return (
          <StyledPatientColumn>
            <StyledPatientInitialsAvatar
              className={'avatar'}
              background={getRandomColor(1).at(0)}
              blur={!patient.initials}
            >
              {trimInitials(patient.initials)}
            </StyledPatientInitialsAvatar>
            <StyledPatientDateNameWrap>
              <ClinText
                className={patient.dateOfBirth ? '' : 'blur'}
                variant={TypographyVariant.SmallUI}
                lineHeight={ClinTheme.lineHeights.largeParagraph}
              >
                {patient.dateOfBirth
                  ? DateTime.fromISO(patient.dateOfBirth, { zone: 'utc' })
                      .toFormat('dd/MMM/yyyy')
                      .toUpperCase()
                  : '**/***/****'}
              </ClinText>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                lineHeight={ClinTheme.lineHeights.heading[0]}
              >
                {patient.patientNumber}
              </ClinText>
            </StyledPatientDateNameWrap>
          </StyledPatientColumn>
        )
      },
      width: '19.5%'
    },
    {
      title: t('patient_detail:patient_table_columns.program_column'),
      renderValue: function column(patient: PatientSummaryDto) {
        return (
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
          >
            {patient.programName}
          </ClinText>
        )
      },
      width: '20%'
    },
    {
      title: t('patient_detail:patient_table_columns.physician_column'),
      renderValue: function column(patient: PatientSummaryDto) {
        return (
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
          >
            {patient.physicianFullName}
          </ClinText>
        )
      },
      width: '20%'
    },
    {
      title: t('patient_detail:patient_table_columns.last_updated_column'),
      renderValue: function column(patient: PatientSummaryDto) {
        return (
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            color="#4F5A65"
          >
            {patient.lastUpdateDate
              ? DateTime.fromISO(patient.lastUpdateDate)
                  .toFormat('dd/MM/yyyy')
                  .toUpperCase()
              : ''}
          </ClinText>
        )
      },
      width: '12%'
    },
    {
      title: t('patient_detail:patient_table_columns.status_column'),
      renderValue: function column(patient: PatientSummaryDto) {
        let statusComponent
        switch (patient.patientOrderStatus) {
          case PatientOrderStatus.PatientDiscontinued:
          case PatientOrderStatus.ProgramClosed:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[
                    patient.patientOrderStatus
                  ]
                )}
              >
                <StyledStatusWrapper color="rgba(117, 117, 117, 0.1)">
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.darkGrey}
                    textAlign="center"
                    fontSize={ClinTheme.fontSizes[1]}
                    lineHeight={ClinTheme.lineHeights.small}
                  >
                    {t(
                      patientOrderStatusTranslation[patient.patientOrderStatus]
                    )}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          case PatientOrderStatus.BeginOrder:
          case PatientOrderStatus.IncompletePAF:
          case PatientOrderStatus.IncompleteCheckout:
          case PatientOrderStatus.ReadyForResupply:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[
                    patient.patientOrderStatus
                  ]
                )}
              >
                <StyledStatusWrapper color="rgba(0, 67, 206, 0.1)">
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.tealishBlue}
                    textAlign="center"
                    fontSize={ClinTheme.fontSizes[1]}
                    lineHeight={ClinTheme.lineHeights.small}
                  >
                    {t(
                      patientOrderStatusTranslation[patient.patientOrderStatus]
                    )}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          case PatientOrderStatus.InTransit:
          case PatientOrderStatus.OutForDelivery:
          case PatientOrderStatus.Available_for_Pickup:
          case PatientOrderStatus.FailedAttempt:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[
                    patient.patientOrderStatus
                  ]
                )}
              >
                <StyledStatusWrapper color="rgba(0, 162, 35, 0.1)">
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.greenValid}
                    textAlign="center"
                    fontSize={ClinTheme.fontSizes[1]}
                    lineHeight={ClinTheme.lineHeights.small}
                  >
                    {t(
                      patientOrderStatusTranslation[patient.patientOrderStatus]
                    )}
                    {patient.trackingLink && (
                      <StyledTrackingLinkWrapper
                        href={patient.trackingLink}
                        text={t('patient_detail:patient_actions.track')}
                        target="_blank"
                      />
                    )}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          case PatientOrderStatus.Delivered:
            statusComponent = (
              <StyledStatusWrapper color="rgba(0, 162, 35, 0.1)">
                <ClinText
                  whiteSpace="nowrap"
                  fontWeight={ClinTheme.fontWeights.medium}
                  color={ClinTheme.colors.greenValid}
                  textAlign="center"
                  fontSize={ClinTheme.fontSizes[1]}
                  lineHeight={ClinTheme.lineHeights.small}
                >
                  {t(patientOrderStatusTranslation[patient.patientOrderStatus])}
                </ClinText>
              </StyledStatusWrapper>
            )
            break
          case PatientOrderStatus.Pending:
          case PatientOrderStatus.Shipped:
          case PatientOrderStatus.Submitted:
          case PatientOrderStatus.Processing:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[
                    patient.patientOrderStatus
                  ]
                )}
              >
                <StyledStatusWrapper color="rgba(0, 162, 35, 0.1)">
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.greenValid}
                    textAlign="center"
                    fontSize={ClinTheme.fontSizes[1]}
                    lineHeight={ClinTheme.lineHeights.small}
                  >
                    {t(
                      patientOrderStatusTranslation[patient.patientOrderStatus]
                    )}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          case PatientOrderStatus.UnderMedicalReview:
          case PatientOrderStatus.FailedDelivery:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[
                    patient.patientOrderStatus
                  ]
                )}
              >
                <StyledStatusWrapper color="rgba(215, 106, 0, 0.10)">
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.orangeWarn}
                    textAlign="center"
                    fontSize={ClinTheme.fontSizes[1]}
                    lineHeight={ClinTheme.lineHeights.small}
                  >
                    {t(
                      patientOrderStatusTranslation[patient.patientOrderStatus]
                    )}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          default:
            statusComponent = (
              <StyledStatusWrapper color="rgba(117, 117, 117, 0.1)">
                <ClinText
                  whiteSpace="nowrap"
                  fontWeight={ClinTheme.fontWeights.medium}
                  color={ClinTheme.colors.primary}
                  textAlign="center"
                  fontSize={ClinTheme.fontSizes[1]}
                  lineHeight={ClinTheme.lineHeights.small}
                >
                  {patient.patientOrderStatus}
                </ClinText>
              </StyledStatusWrapper>
            )
            break
        }

        return statusComponent
      },
      width: '20%'
    }
  ]

  useOnMount(() => {
    //we need to have list of enrolled programs for user, so that we can now on change or when program has to be automatically populated
    getEnrolledPrograms(defaultEnrolledSearchParams)
      .then((response) => {
        if (response) {
          const programsFromAPI: IProgramSummary[] | null = response.data.result
          setEnrolledPrograms(
            programsFromAPI.map((x) => x.programId.toString())
          )
        }
      })
      .catch((error: AxiosError) => {
        const { code, message } = error

        // If request is cancelled continue
        if (error.message === AuthError.RequestCancelled) {
          return
        }
        // If we have a full error show it
        if (error.response) {
          const { title } = error.response.data
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching enrolled access programs. ${
                title ? title : ''
              } ${message ? message : ''}`
            )
          )
        } else {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching enrolled access programs. ${message} ${
                code ? code : ''
              }`
            )
          )
        }

        return () => {
          cancelGetEnrolledPrograms()
        }
      })

    if (history.location?.state?.programId) {
      setSelectedProgramId(history.location?.state?.programId)
    }
  })

  const handleProgramChanged = (programId: number) => {
    setSelectedProgramId(programId.toString())
  }

  /*
      IF the physician is associated, allow pop and submit
      IF the physician is not associated, 
        checkbox visible,
          on checkbox false disable form submission but enable populating,
          on checkbox true allow both BUT before submitting to CreatePatient call the associate API for the physician
    */

  const handlePhysicianSelected = (
    selectedPhysicianOption: ICustomSelectOption
  ) => {
    setSelectedPhysician(selectedPhysicianOption)
    setPhysicianPermissionVisible(
      !!physicians && !selectedPhysicianOption.isActive
    )
  }

  return (
    <>
      <CreatePatient
        physicians={physicians}
        patients={patientDuplicate}
        selectedProgramId={selectedProgramId}
        selectStockOrder={
          history.location.state && history.location.state.stockOrder
        }
        viewMode={viewMode}
        columns={columns}
        countryCode={portfolioCountryCode}
        backButtonUrl={
          history.location.state && history.location.state.from
            ? history.location.state.from
            : '/patients'
        }
        isPhysicianLoading={physiciansLoading}
        physicianPermission={physicianPermission}
        physicianPermissionVisible={physicianPermissionVisible}
        selectedPhysician={selectedPhysician}
        preselectedPhysician={preselectedPhysician}
        enrolledPrograms={enrolledPrograms}
        jobTitles={jobTitles}
        setPhysicianPermission={setPhysicianPermission}
        setIsEnrolled={setIsEnrolled}
        handlePhysicianSelected={handlePhysicianSelected}
        handleCreatePatientSubmit={handleCreatePatientSubmit}
        handleEditDetails={handleEditDetails}
        handleGoToExistingPatient={handleGoToExistingPatient}
        handleProgramChanged={handleProgramChanged}
      />
    </>
  )
}
