import React, {
  cloneElement,
  FunctionComponent,
  ReactElement,
  useState,
  Children
} from 'react'

import {
  StyledAccordion,
  StyledAccordionHeader,
  StyledAccordionPanel,
  StyledAccordionItem
} from './ClinAccordion.styles'
import { ClinTheme } from '../../ClinTheme'
import { slugify } from '../../utils/slugify'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'

export interface IClinAccordionStyles {
  /** Whether the accordion item is extended */
  isActive?: boolean
  /** Optionally hide an accordion item */
  isHidden?: boolean
  /** Optionally hide top border */
  hideBorder?: boolean
  /** Optionally send font size for accordian title */
  fontSize?: number
  /** Optionally send font weight for accordian title */
  fontWeight?: number
  /** Optionally send height for accordian icon */
  iconHeight?: number
  /** Optionally send height for accordian icon */
  iconSize?: number
  iconFill?: string
  /** Optionally send top padding */
  paddingTop?: number
}

interface IClinAccordionItemProps extends IClinAccordionStyles {
  /** The title of the accordion item */
  title: string
  /** The index of the accordion item */
  itemIndex?: number
  /** Callback function to allow access to the section click event */
  handleSectionCallback?: () => void
  /** Internal event only - when section title is clicked - passes index to parent accordion */
  onSectionClick?: (index: number) => void
}
type ClinAccordionElement = ReactElement<IClinAccordionItemProps>
export const ClinAccordionItem: FunctionComponent<IClinAccordionItemProps> = ({
  children,
  itemIndex = 0,
  title = 'Section Title',
  isActive = false,
  hideBorder = false,
  fontSize,
  fontWeight,
  iconHeight,
  iconSize,
  iconFill,
  isHidden,
  paddingTop,
  handleSectionCallback,
  onSectionClick
}) => {
  const sluggedTitle = slugify(title)
  return (
    <StyledAccordion className="accordion" isHidden={isHidden}>
      <StyledAccordionHeader
        className="accordion-header"
        id={`sect-${sluggedTitle}-header`}
        role="heading"
        isActive={isActive}
        hideBorder={hideBorder}
        fontSize={fontSize}
        fontWeight={fontWeight}
        paddingTop={paddingTop}
      >
        <button
          aria-controls={`sect-${sluggedTitle}`}
          aria-expanded={!isActive}
          type="button"
          onClick={() => {
            handleSectionCallback?.()
            onSectionClick?.(itemIndex)
          }}
        >
          <span>{title}</span>
          <ClinIcon
            iconName={ClinIconPathName.ChevronDown}
            iconFill={iconFill ?? ClinTheme.colors.primaryLight}
            iconSize={iconSize ?? ClinTheme.space[5]}
            iconHeight={iconHeight}
          />
        </button>
      </StyledAccordionHeader>
      <StyledAccordionPanel
        className="accordion-panel"
        id={`sect-${sluggedTitle}`}
        aria-labelledby={`sect-${sluggedTitle}-header`}
        hidden={!isActive}
      >
        {children}
      </StyledAccordionPanel>
    </StyledAccordion>
  )
}

export interface IClinAccordionProps {
  /**  The section indices that are initially active  */
  activeItemIndices?: number[]
  /**  Optional parameter to hide bottom border */
  hideBorder?: boolean
  /** Only accepts children that are ClinAccordionElement */
  children: ClinAccordionElement | ClinAccordionElement[]
  /** Triggers callback when an accordion item header is clicked */
  handleAccordionItemClicked?: (itemIndex: number, isOpen: boolean) => void
}
export const ClinAccordion: FunctionComponent<IClinAccordionProps> = ({
  activeItemIndices = [],
  hideBorder,
  handleAccordionItemClicked,
  children
}) => {
  const initialSelection: number[] = [...activeItemIndices]
  const [selectedItemIndexes, setSelectedItemIndexes] =
    useState<number[]>(initialSelection)
  const onSectionClick = (itemIndex: number) => {
    const itemAlreadySelected = selectedItemIndexes.some((i) => i === itemIndex)
    if (itemAlreadySelected) {
      setSelectedItemIndexes([
        ...selectedItemIndexes.filter((idx) => idx !== itemIndex)
      ])
    } else {
      setSelectedItemIndexes([...selectedItemIndexes, itemIndex])
    }
    handleAccordionItemClicked?.(itemIndex, !itemAlreadySelected)
  }

  return (
    <StyledAccordionItem className="accordion__item" hideBorder={hideBorder}>
      {Children.map(
        children,
        (accordionItem: ClinAccordionElement, index: number) =>
          cloneElement(accordionItem, {
            ...accordionItem.props,
            itemIndex: index,
            key: `${accordionItem.key}-accordion-item`,
            isActive: selectedItemIndexes.some((i) => i === index),
            onSectionClick
          })
      )}
    </StyledAccordionItem>
  )
}
