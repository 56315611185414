import React, { FunctionComponent } from 'react'
import {
  StyledAddedToBasketConfirmation,
  StyledBody,
  StyledCrossButton,
  StyledHeader,
  Container,
  Header,
  Row,
  Column,
  StyledIcon,
  StyledClinAnnounceBarSuccess
} from './AddedToBasketConfirmationNew.styles'
import { ClinText } from '../../../components/ClinText'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { numberToCurrencyString } from '../../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../../utils/getBrowserLocale'
import { useTranslation } from 'react-i18next'
import { CountryAlphaCodes } from '../../../constants/countryAlpha2Codes'
import CheckCircleIcon from '../../../assets/media/icons/check-circle-green.svg'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'

export interface IBasketItemDetails {
  title: string
  sku: string
  quantity: number
  amount: number
  currencyCode: string
  requiresDocumentation: boolean
}

interface IAddedToBasketConfirmationProps {
  /** The basket item details */
  basketItemDetails: IBasketItemDetails
  /**Flag when the item is removed */
  wasItemRemoved?: boolean
  /** Conditional check for AUS users */
  countryCode?: string
  /** Handle the callback for going to the basket */
  handleGoToBasket?: () => void
  /** Handle the callback for going to the Checkout */
  handleProceedToCheckout?: () => void
  /** Handle closing the confirmation */
  handleClose?: () => void
}

export const AddedToBasketConfirmationNew: FunctionComponent<
  IAddedToBasketConfirmationProps
> = ({
  basketItemDetails,
  wasItemRemoved,
  handleGoToBasket,
  handleProceedToCheckout,
  handleClose,
  countryCode
}) => {
  const { t } = useTranslation()
  const { title, sku, quantity, amount, currencyCode, requiresDocumentation } =
    basketItemDetails
  const handleProceed = () => {
    try {
      localStorage.setItem('basketProceedClicked', 'true')
    } catch (error) {
      console.error('Error saving to localStorage:', error)
    }
    handleProceedToCheckout && handleProceedToCheckout()
  }
  return (
    <StyledAddedToBasketConfirmation>
      <StyledHeader>
        <ClinGroup justifyContent={'space-between'}>
          <ClinText
            as={'div'}
            fontWeight={ClinTheme.fontWeights.medium}
            fontSize={ClinTheme.fontSizes[3]}
            color={ClinTheme.colors.black}
            lineHeight={ClinTheme.lineHeights.heading[1]}
          >
            {t('add_to_basket_confirmation:recently_added')}
          </ClinText>
          <StyledCrossButton
            iconName={ClinIconPathName.Cross}
            iconFill={ClinTheme.colors.darkGrey}
            iconSize={20}
            onClick={() => {
              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.CloseCartModal
              )

              handleClose && handleClose()
            }}
          />
        </ClinGroup>
      </StyledHeader>
      <StyledBody>
        {wasItemRemoved ? (
          <ClinAnnounceBar mode={AnnounceMode.Information} title={' '}>
            <ClinText as={'div'} color={ClinTheme.colors.primaryMid}>
              {t('add_to_basket_confirmation:medicine_removed_accounce')}
            </ClinText>
          </ClinAnnounceBar>
        ) : (
          <StyledClinAnnounceBarSuccess
            marginBottom={ClinTheme.space[2]}
            mode={AnnounceMode.Success}
            title={' '}
          >
            <StyledIcon width={24} height={24} src={CheckCircleIcon} />
            <ClinText as={'div'} color={ClinTheme.colors.black}>
              {t('add_to_basket_confirmation:medicine_add_to_basket')}
            </ClinText>
          </StyledClinAnnounceBarSuccess>
        )}
        {/* Order details */}
        {!wasItemRemoved && (
          <>
            <Container>
              <Header>
                <Column>
                  <ClinText
                    as={'div'}
                    fontSize={ClinTheme.fontSizes[0]}
                    fontWeight={ClinTheme.fontWeights.medium}
                    lineHeight={ClinTheme.lineHeights.largeParagraph}
                  >
                    {t('add_to_basket_confirmation:quantity_label')}
                  </ClinText>
                </Column>
                <Column>
                  <ClinText
                    as={'div'}
                    fontSize={ClinTheme.fontSizes[0]}
                    fontWeight={ClinTheme.fontWeights.medium}
                    lineHeight={ClinTheme.lineHeights.largeParagraph}
                  >
                    {t('add_to_basket_confirmation:sku')}
                  </ClinText>
                </Column>
                <Column>
                  <ClinText
                    as={'div'}
                    fontSize={ClinTheme.fontSizes[0]}
                    fontWeight={ClinTheme.fontWeights.medium}
                    lineHeight={ClinTheme.lineHeights.largeParagraph}
                    textAlign={'right'}
                  >
                    {t('add_to_basket_confirmation:product_code_label')}
                  </ClinText>
                </Column>
              </Header>

              <Row>
                <Column>
                  <ClinText
                    as={'div'}
                    fontSize={ClinTheme.fontSizes[1]}
                    lineHeight={ClinTheme.lineHeights.heading[0]}
                  >
                    {quantity}
                  </ClinText>
                </Column>
                <Column>
                  <ClinText
                    as={'div'}
                    fontSize={ClinTheme.fontSizes[1]}
                    lineHeight={ClinTheme.lineHeights.heading[0]}
                  >
                    {title}
                  </ClinText>
                </Column>
                <Column>
                  <ClinText
                    as={'div'}
                    fontSize={ClinTheme.fontSizes[1]}
                    lineHeight={ClinTheme.lineHeights.heading[0]}
                    textAlign={'right'}
                  >
                    {sku}
                  </ClinText>
                </Column>
              </Row>
            </Container>
            <ClinSpacer hasBorder={true} height={ClinTheme.space[7]} />
            <ClinGroup justifyContent={'space-between'} alignItems={'center'}>
              <ClinText
                as={'div'}
                fontSize={ClinTheme.fontSizes[3]}
                fontWeight={ClinTheme.fontWeights.medium}
                lineHeight={ClinTheme.lineHeights.heading[1]}
              >
                {t('add_to_basket_confirmation:amount_label')}
              </ClinText>
              <ClinText
                as={'div'}
                className={'amount'}
                fontSize={ClinTheme.fontSizes[4]}
                fontWeight={ClinTheme.fontWeights.medium}
                lineHeight={ClinTheme.lineHeights.heading[1]}
              >
                {numberToCurrencyString(amount, getBrowserLocale(), {
                  style: 'currency',
                  currency: currencyCode,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </ClinText>
            </ClinGroup>
            <ClinSpacer height={ClinTheme.space[5]} />
            {/* Supporting documentation */}
            {countryCode !== CountryAlphaCodes.Australia &&
              countryCode !== CountryAlphaCodes.Ireland &&
              requiresDocumentation && (
                <>
                  <ClinAnnounceBar mode={AnnounceMode.Information} title={' '}>
                    <ClinText as={'div'} color={ClinTheme.colors.primaryMid}>
                      {t(
                        'add_to_basket_confirmation:supporting_document_required'
                      )}
                    </ClinText>
                  </ClinAnnounceBar>
                  <ClinSpacer height={ClinTheme.space[4]} />
                </>
              )}
          </>
        )}
        <ClinButton
          display={'block'}
          variant={'secondary'}
          onClick={handleGoToBasket}
        >
          {t('add_to_basket_confirmation:view_basket_checkout')}
        </ClinButton>
        <ClinSpacer height={ClinTheme.space[2]} />
        <ClinButton
          display={'block'}
          variant={'primary'}
          onClick={() => {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.CheckoutCartModal,
              {
                sku: sku,
                quantity: quantity,
                price: amount,
                currency: currencyCode
              }
            )

            handleProceed()
          }}
        >
          {t('add_to_basket_confirmation:checkout')}
        </ClinButton>
      </StyledBody>
    </StyledAddedToBasketConfirmation>
  )
}
