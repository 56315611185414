import { transparentize } from 'polished'
import { CSSProperties } from 'react'
import OptionTypeBase, {
  ControlProps,
  OptionProps
} from 'react-select/dist/declarations/src/'
import styled from 'styled-components'

import { ClinMultiSelectOption } from './ClinMultiSelect'
import { ClinTheme } from '../../ClinTheme'

export const StyledClinMultiSelect = styled.div({})

export const StyledDropdownIndicator = styled.div({
  lineHeight: '1em',
  '&:hover path:first-child': {
    fill: ClinTheme.colors.black
  },
  svg: {
    marginRight: ClinTheme.space[2],
    marginLeft: ClinTheme.space[2]
  }
})

export const StyledClearIndicator = styled.div({
  lineHeight: '1em',
  '&:hover path:first-child': {
    fill: ClinTheme.colors.black
  },
  svg: {
    marginRight: ClinTheme.space[2],
    marginLeft: ClinTheme.space[1],
    width: 18,
    height: 18
  }
})

export const StyledMultiValueRemove = styled.div({
  svg: {
    width: 12,
    height: 12,
    verticalAlign: 'middle',
    alignSelf: 'center'
  }
})

export const getReactSelectStyles = (hasError?: boolean): any => ({
  control: (
    base: CSSProperties,
    state: ControlProps<OptionTypeBase, boolean>
  ) => {
    const value = state.getValue() as unknown as ClinMultiSelectOption[]
    return {
      ...base,
      minHeight: 40,
      paddingLeft: value.length === 0 ? ClinTheme.space[2] : undefined,
      border: `${ClinTheme.borderWidths[0]}px solid ${
        state.isFocused ? ClinTheme.colors.primaryMid : ClinTheme.colors.grey
      }`,
      boxShadow: hasError
        ? `0px 0px 3px 1px ${ClinTheme.colors.redInvalid}`
        : 'none',
      '&:hover': {
        border: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryMid}`
      }
    }
  },
  valueContainer: (base: CSSProperties) => ({
    ...base,
    padding: ClinTheme.space[1]
  }),
  multiValue: (base: CSSProperties) => ({
    ...base,
    border: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryMid}`,
    borderRadius: ClinTheme.radii[2],
    background: transparentize(0.85, ClinTheme.colors.primaryMid),
    fontWeight: ClinTheme.fontWeights.medium
  }),
  multiValueLabel: (base: CSSProperties) => ({
    ...base,
    color: ClinTheme.colors.primaryMid,
    padding: ClinTheme.space[1],
    paddingLeft: ClinTheme.space[2],
    lineHeight: '1em',
    fontSize: ClinTheme.fontSizes[1]
  }),
  placeholder: (base: CSSProperties) => ({
    ...base,
    color: ClinTheme.colors.darkGrey
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    border: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryMid}`,
    boxShadow: `0px 2px 8px ${transparentize(
      0.5,
      ClinTheme.colors.primaryMid
    )}`,
    borderRadius: ClinTheme.radii[2]
  }),
  multiValueRemove: (base: CSSProperties) => ({
    ...base,
    color: ClinTheme.colors.primaryMid,
    paddingTop: ClinTheme.space[1],
    paddingBottom: ClinTheme.space[1],
    // make height match width
    paddingLeft: 5,
    paddingRight: 5,
    '&:hover': {
      background: transparentize(0.75, ClinTheme.colors.primaryMid),
      color: ClinTheme.colors.primaryMid
    }
  }),
  option: (
    base: CSSProperties,
    state: OptionProps<OptionTypeBase, boolean>
  ) => ({
    ...base,
    padding: 12, // compromise between 8 and 16 because it looks better
    paddingLeft: ClinTheme.space[3],
    background: state.isFocused ? ClinTheme.colors.lightGrey : undefined,
    '&:hover': {
      background: ClinTheme.colors.lightGrey
    }
  })
})
