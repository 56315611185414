import React, { FunctionComponent } from 'react'

import { ChipState, IClinChipStyles, StyledClinChip } from './ClinChip.styles'

interface IClinChipProps
  extends IClinChipStyles,
    React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  showGlowEffect?: boolean
}

export const ClinChip: FunctionComponent<IClinChipProps> = (
  props: IClinChipProps
) => (
  <StyledClinChip
    showGlowEffect={props.showGlowEffect}
    {...props}
    className={`clin-chip ${props.className ? props.className : ''} ${
      props.state && props.state === ChipState.Active ? 'active' : ''
    }`}
  >
    {props.children}
  </StyledClinChip>
)
