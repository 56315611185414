import React from 'react'
import { IAugmentedCatalogItemDto } from '../../../../services/PortfolioJourneys/PortfolioJourney.types'
import { Container, Row, Column, StyledClinButton } from './MobileItem.styles'
import { CatalogDto } from '../../../../types/swaggerTypes'
import { useTranslation } from 'react-i18next'
import { ClinText } from '../../../../components/ClinText'
import { ClinTheme } from '../../../../ClinTheme'
import { StyledStrengthCell } from '../ProductVariant.styles'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import StatusColumn from '../StatusColumn/StatusColumn'
import { ProductItemAvailabilityStatus, UserRole } from '../../../../constants'
import { ClinSpinner } from '../../../../components/ClinSpinner'
import { numberToCurrencyString } from '../../../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../../../utils/getBrowserLocale'
import { Basket } from '../Basket/Basket'

interface IMobileItem {
  catalogItem: IAugmentedCatalogItemDto
  userCountry: string
  userRole?: UserRole
  product: CatalogDto
  catalogueItemStatus: string
  catalogueItemDeliveryDate: string
  setIsDrawerOpen?: (isOpen: boolean) => void
  handleGoToBasket: () => void
  handleProceedToCheckout: () => void
  handleSelectVariant?: (sku: string) => void
}

const MobileItem: React.FC<IMobileItem> = (props) => {
  const {
    catalogItem,
    userCountry,
    userRole,
    product,
    catalogueItemStatus,
    catalogueItemDeliveryDate,
    setIsDrawerOpen,
    handleGoToBasket,
    handleProceedToCheckout,
    handleSelectVariant
  } = props
  const { t } = useTranslation()
  const isAvailable =
    catalogItem.availableStatus === ProductItemAvailabilityStatus.AVAILABLE
  const isPriceLoading = isAvailable && catalogItem.unitPrice === undefined

  const renderPrice = () => {
    if (isPriceLoading) {
      return <ClinSpinner size={ClinTheme.space[3]} />
    }

    if (
      isAvailable &&
      catalogItem.unitPrice !== undefined &&
      catalogItem.currencyCode
    ) {
      return numberToCurrencyString(catalogItem.unitPrice, getBrowserLocale(), {
        style: 'currency',
        currency: catalogItem.currencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }

    return '--'
  }

  return (
    <Container
      onClick={() =>
        handleSelectVariant && handleSelectVariant(catalogItem.item.sku)
      }
    >
      <Row>
        <Column>
          {catalogItem.products.map((product: any) => (
            <StyledStrengthCell key={`catalogue-item-${product.productId}`}>
              <ClinText
                variant={TypographyVariant.SmallUI}
                lineHeight={ClinTheme.lineHeights.heading[0]}
                fontSize={ClinTheme.fontSizes[1]}
              >
                {product.genericName}
              </ClinText>
              <ClinText
                lineHeight={ClinTheme.lineHeights.heading[0]}
                fontSize={ClinTheme.fontSizes[1]}
              >
                {product.strength}
              </ClinText>
            </StyledStrengthCell>
          ))}
        </Column>
        <Column>
          <StatusColumn catalogueItemStatus={catalogueItemStatus} />
        </Column>
      </Row>
      <Row>
        <Column>
          <ClinText
            fontSize={ClinTheme.fontSizes[0]}
            fontWeight={ClinTheme.fontWeights.medium}
            lineHeight={ClinTheme.lineHeights.heading[1]}
          >
            {t('product_variants:column_titles.brand')}
          </ClinText>
        </Column>
        <Column>
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
          >
            {catalogItem.item.brand ?? '--'}
          </ClinText>
        </Column>
      </Row>
      <Row>
        <Column>
          <ClinText
            fontSize={ClinTheme.fontSizes[0]}
            fontWeight={ClinTheme.fontWeights.medium}
            lineHeight={ClinTheme.lineHeights.heading[1]}
          >
            {t('product_variants:column_titles.dosage_form_and_pack_size')}
          </ClinText>
        </Column>
        <Column>
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
          >
            {catalogItem.item.formulation}
          </ClinText>
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
          >
            {catalogItem.item.packSize}
          </ClinText>
        </Column>
      </Row>
      <Row>
        <Column>
          <ClinText
            fontSize={ClinTheme.fontSizes[0]}
            fontWeight={ClinTheme.fontWeights.medium}
            lineHeight={ClinTheme.lineHeights.heading[1]}
          >
            {t('product_variants:column_titles.pack_price')}
          </ClinText>
        </Column>
        <Column>
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
          >
            {renderPrice()}
          </ClinText>
        </Column>
      </Row>
      <Row>
        <Column>
          <ClinText
            fontSize={ClinTheme.fontSizes[0]}
            fontWeight={ClinTheme.fontWeights.medium}
            lineHeight={ClinTheme.lineHeights.heading[1]}
          >
            {t('product_variants:column_titles.estimated_delivery')}
          </ClinText>
          <ClinText
            fontSize={ClinTheme.fontSizes[0]}
            fontWeight={ClinTheme.fontWeights.normal}
            lineHeight={ClinTheme.lineHeights.heading[1]}
          >
            {t('product_variants:column_titles.order_time')}
          </ClinText>
        </Column>
        <Column>
          <ClinText
            variant={TypographyVariant.SmallUI}
            lineHeight={ClinTheme.lineHeights.small}
            fontSize={ClinTheme.fontSizes[0]}
          >
            {catalogueItemDeliveryDate}
          </ClinText>
        </Column>
      </Row>
      <Row>
        <Column className="full-width basket">
          <Basket
            basketType="mobile"
            userCountry={userCountry}
            userRole={userRole}
            product={product}
            catalogItem={catalogItem}
            setIsDrawerOpen={setIsDrawerOpen}
            handleGoToBasket={handleGoToBasket}
            handleProceedToCheckout={handleProceedToCheckout}
          />
        </Column>
      </Row>
      <Row>
        <Column className="full-width">
          <StyledClinButton
            variant="secondary"
            onClick={() =>
              handleSelectVariant && handleSelectVariant(catalogItem.item.sku)
            }
          >
            {t('product_variants:view_full_product_details')}
          </StyledClinButton>
        </Column>
      </Row>
    </Container>
  )
}

export default MobileItem
