import React, { Fragment, FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { ClinTheme } from '../../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../../components/ClinAccordion'
import { ClinButton } from '../../../components/ClinButton'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { IOrder } from '../../../types/IOrder'
import {
  OrderLineDto,
  PriceAndAvailabilityDto
} from '../../../types/swaggerTypes'
import {
  StyledCheckoutBlock,
  StyledCheckoutBlockBody,
  StyledCheckoutOrder,
  StyledCheckoutSummary
} from './CheckoutOld.styles'
import { CheckoutViewMode } from './CheckoutContainerOld'
import { CheckoutOrderItem } from './CheckoutOrderItemOld'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { Prompt } from '../../../features/Prompt'
import { ClinLoader } from '../../../components/ClinLoader'
import { ClinDividerHeading } from '../../../components/ClinDividerHeading'
import {
  ClinStepper,
  StepState
} from '../../../components/ClinStepper/ClinStepper'
import { HoldAnnounce } from '../../../features/HoldAnnounce'
import { HoldAnnounceMode } from '../../../features/HoldAnnounce/HoldAnnounce'
import { numberToCurrencyString } from '../../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../../utils/getBrowserLocale'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'

interface ICheckoutProps {
  /** The decided view which should be displayed **/
  viewMode: CheckoutViewMode
  /** An array of processed orders **/
  orders?: IOrder[]
  /** An array of pricing for all order item SKUs (for holds and shipping info) **/
  priceAndAvailability: PriceAndAvailabilityDto[]
  /** Whether the order has been split in to separate orders **/
  isOrderSplit: boolean
  /** Whether the order has been successfully submitted **/
  orderWasSubmitted: boolean
  /** When back to address is clicked **/
  handleBackToDeliveryOptions: () => void

  /** Handles a request to place the orders in the checkout **/
  handleOrderSubmit: () => void
}

export const Checkout: FunctionComponent<ICheckoutProps> = ({
  orders,
  priceAndAvailability,
  viewMode,
  isOrderSplit,
  orderWasSubmitted,
  handleBackToDeliveryOptions,
  handleOrderSubmit
}) => {
  const { t } = useTranslation()

  //Potential fix for the 2 year old bug with doubleclickers
  const handleOrderSubmitDebounced = debounce(handleOrderSubmit, 500)

  /**
   *  Look through price and availability array for correct shipping method
   *  */
  const getShippingMethodForSku = (sku: string | undefined): string => {
    if (sku && priceAndAvailability.length > 0) {
      const pa = priceAndAvailability.find((p) => p.itemNumber === sku)
      return pa ? pa.shippingMethod : t('glossary:not_applicable')
    }
    return t('glossary:not_applicable')
  }

  return (
    <ClinPageContentFrame>
      {viewMode === CheckoutViewMode.Loading && (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      )}

      {viewMode === CheckoutViewMode.Submitting && (
        <>
          <Row>
            <Col sm={6}>
              <ClinText
                variant={TypographyVariant.H4}
                marginTop={ClinTheme.space[4]}
              >
                {t('checkout:placing_your_order')}
              </ClinText>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <StyledSpinnerContainer>
                <ClinLoader />
              </StyledSpinnerContainer>
            </Col>
          </Row>
        </>
      )}

      {viewMode === CheckoutViewMode.ShowOrders && (
        <>
          <Row>
            <Col sm={12}>
              <ClinText
                as="h1"
                fontWeight={ClinTheme.fontWeights.bold}
                variant={TypographyVariant.H3}
              >
                {t('checkout:title')}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[3]} hasBorder={true} />
          <ClinButton
            style={{ marginTop: ClinTheme.space[3] }}
            variant="linkButton"
            onClick={() =>
              handleBackToDeliveryOptions && handleBackToDeliveryOptions()
            }
          >
            <ClinIcon
              style={{ marginRight: ClinTheme.space[2] }}
              iconSize={ClinTheme.fontSizes[3]}
              iconName={ClinIconPathName.ChevronLeft}
            />
            {t('checkout:back_to')}
          </ClinButton>
          <ClinSpacer height={ClinTheme.space[4]} />
          <Row>
            <Col sm={12}>
              <ClinStepper
                steps={[
                  {
                    state: StepState.Complete,
                    displayText: t('checkout:stepper.step_1')
                  },
                  {
                    state: StepState.Complete,
                    displayText: t('checkout:stepper.step_2')
                  },
                  {
                    state: StepState.Active,
                    displayText: t('checkout:stepper.step_3')
                  },
                  {
                    state: StepState.InComplete,
                    displayText: t('checkout_options:stepper.step_4')
                  }
                ]}
                width={800}
              />
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[7]} />
          <Prompt when={!orderWasSubmitted} />
          {/* Is the order split ?*/}
          {isOrderSplit && (
            <Row>
              <Col>
                <HoldAnnounce
                  mode={HoldAnnounceMode.Information}
                  title={t('checkout:split_order_announce_title')}
                >
                  {t('checkout:split_order_announce_description')}
                </HoldAnnounce>
                <ClinSpacer height={ClinTheme.space[5]} />
              </Col>
            </Row>
          )}
          {orders &&
            orders.map((order: IOrder, index: number) => {
              const orderLinesCount = order.lines.filter(
                (l: OrderLineDto) => l.medicationFlag === 'Y'
              ).length
              return (
                <Fragment key={`order-${order.orderNumber}-${index}`}>
                  <StyledCheckoutOrder>
                    <ClinDividerHeading>
                      <ClinText
                        as="h2"
                        textTransform="uppercase"
                        fontWeight={ClinTheme.fontWeights.medium}
                        color={ClinTheme.colors.white}
                      >
                        {t('checkout:order_line_count', {
                          current: index + 1,
                          total: orders.length
                        })}
                      </ClinText>
                    </ClinDividerHeading>

                    <ClinAccordion activeItemIndices={[0, 1]}>
                      <ClinAccordionItem
                        title={t('checkout:order_details_and_line_count', {
                          ordersCount: orderLinesCount,
                          item: t('checkout:item', {
                            count: orderLinesCount
                          })
                        })}
                      >
                        {order.lines
                          .filter((o) => o.medicationFlag === 'Y')
                          .map((orderLine: OrderLineDto, index: number) => (
                            <CheckoutOrderItem
                              key={`${orderLine.skuCatalogItem?.sku}-order-line-${index}`}
                              orderLine={orderLine}
                              currencyCode={order.currencyCode}
                              shippingMethod={getShippingMethodForSku(
                                orderLine?.skuCatalogItem?.sku
                              )}
                            />
                          ))}
                      </ClinAccordionItem>
                      <ClinAccordionItem title={t('checkout:order_summary')}>
                        <StyledCheckoutSummary>
                          <Row justify="end">
                            <Col sm={12} md={8} lg={7}>
                              {order.lines
                                .filter((o) => o.medicationFlag !== 'Y')
                                .map(
                                  (orderLine: OrderLineDto, index: number) => (
                                    <Fragment
                                      key={`order-summary-${order.orderNumber}-${index}`}
                                    >
                                      <Row
                                        key={`order-${index}-${orderLine.itemDescription}`}
                                        justify="between"
                                      >
                                        <Col>
                                          <ClinText
                                            color={ClinTheme.colors.primary}
                                          >
                                            {orderLine.itemDescription}:
                                          </ClinText>
                                        </Col>
                                        <Col width="auto">
                                          <ClinText
                                            as="div"
                                            className={'sub-total'}
                                            color={ClinTheme.colors.primary}
                                            fontSize={ClinTheme.fontSizes[3]}
                                          >
                                            {numberToCurrencyString(
                                              orderLine.totals.subTotal,
                                              getBrowserLocale(),
                                              {
                                                style: 'currency',
                                                currency: order.currencyCode,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                              }
                                            )}
                                          </ClinText>
                                        </Col>
                                      </Row>
                                      <ClinSpacer />
                                    </Fragment>
                                  )
                                )}
                              <ClinSpacer />
                              <Row justify="between">
                                <Col>
                                  <ClinText color={ClinTheme.colors.primary}>
                                    {t('checkout:vat')}
                                  </ClinText>
                                </Col>
                                <Col width="auto">
                                  <ClinText
                                    className={'tax'}
                                    as="div"
                                    color={ClinTheme.colors.primary}
                                    fontSize={ClinTheme.fontSizes[3]}
                                  >
                                    {numberToCurrencyString(
                                      order.totals.tax,
                                      getBrowserLocale(),
                                      {
                                        style: 'currency',
                                        currency: order.currencyCode,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      }
                                    )}
                                  </ClinText>
                                </Col>
                              </Row>
                              <ClinSpacer
                                hasBorder={true}
                                height={ClinTheme.space[8]}
                              />
                              <Row justify="between" align="end">
                                <Col>
                                  <ClinText
                                    fontWeight={ClinTheme.fontWeights.medium}
                                    color={ClinTheme.colors.primary}
                                  >
                                    {t('checkout:total')}
                                  </ClinText>
                                </Col>
                                <Col width="auto">
                                  <ClinText
                                    className={'total'}
                                    as="div"
                                    fontWeight={ClinTheme.fontWeights.normal}
                                    color={ClinTheme.colors.primary}
                                    fontSize={ClinTheme.fontSizes[5]}
                                  >
                                    {numberToCurrencyString(
                                      order.totals.total,
                                      getBrowserLocale(),
                                      {
                                        style: 'currency',
                                        currency: order.currencyCode,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      }
                                    )}
                                  </ClinText>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </StyledCheckoutSummary>
                      </ClinAccordionItem>
                    </ClinAccordion>
                  </StyledCheckoutOrder>
                  <ClinSpacer height={ClinTheme.space[9]} />
                </Fragment>
              )
            })}
          <ClinDividerHeading marginBottom={ClinTheme.space[5]}>
            <ClinText
              as="h2"
              textTransform="uppercase"
              fontWeight={ClinTheme.fontWeights.medium}
              color={ClinTheme.colors.white}
            >
              {t('checkout:complete_your_order')}
            </ClinText>
          </ClinDividerHeading>
          <StyledCheckoutBlock>
            <StyledCheckoutBlockBody>
              <Row justify="between">
                <Col xs={12} md={5} lg={4}>
                  <ClinText>
                    {t('checkout:complete_your_order_description')}
                  </ClinText>
                </Col>
                <Col style={{ textAlign: 'right' }} xs={12} md={8} lg={5}>
                  <ClinButton
                    onClick={() => handleOrderSubmitDebounced()}
                    variant="primary"
                  >
                    {t('checkout:place_order')}
                  </ClinButton>
                </Col>
              </Row>
            </StyledCheckoutBlockBody>
          </StyledCheckoutBlock>
        </>
      )}
    </ClinPageContentFrame>
  )
}
