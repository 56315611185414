import React, { FunctionComponent } from 'react'

import {
  ICustomSelectStyles,
  StyledCustomSelect,
  StyledCustomSelectTrigger,
  StyledCustomSelectTriggerInner,
  StyledCustomSelectOptions,
  StyledCustomSelectOption,
  StyledCustomSelectTick
} from './CustomSelect.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinDropdown } from '../../components/ClinDropdown'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { IDefaultStyleProps } from '../../types'

export interface ICustomSelectOption {
  /** Used as a unique reference */
  id: number | string
  /** Used to display the value in the view */
  value: string
  /** Used to display whether the option is active */
  isActive?: boolean
}

interface ICustomSelectProps extends IDefaultStyleProps, ICustomSelectStyles {
  /** Label to sit above the dropdown */
  label?: string
  /** Enables an array of options to be passed in to the dropdown*/
  options?: ICustomSelectOption[]
  /** Displays the selected value within the drop down */
  selectedOption?: ICustomSelectOption
  /** Placeholder if no value is selected */
  placeholder?: string
  /** The label to display below the input */
  prompt?: string
  /** Enables a function to be called when an option is selected */
  handleSelect: (option: ICustomSelectOption) => void
}

export const CustomSelect: FunctionComponent<ICustomSelectProps> = ({
  label,
  options,
  selectedOption,
  hasError,
  prompt,
  placeholder = 'Please select',
  handleSelect
}) => {
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    option: ICustomSelectOption
  ) => {
    handleSelect && event.keyCode === 13 && handleSelect(option)
  }
  return (
    <>
      {label && (
        <ClinText
          className="custom-select-label"
          as="div"
          fontSize={ClinTheme.fontSizes[1]}
          color="inherit"
          marginBottom={ClinTheme.space[2]}
        >
          {label}
        </ClinText>
      )}
      {!options || options?.length === 0 ? (
        <StyledCustomSelectTrigger>
          <StyledCustomSelectTriggerInner
            isActive={!!selectedOption}
            tabIndex={0}
          >
            <ClinText
              whiteSpace="nowrap"
              color={ClinTheme.colors.darkGrey}
              variant={TypographyVariant.LargeParagraph}
            >
              {placeholder}
            </ClinText>
          </StyledCustomSelectTriggerInner>
        </StyledCustomSelectTrigger>
      ) : (
        <StyledCustomSelect>
          <ClinDropdown
            trigger={() => {
              return (
                <StyledCustomSelectTrigger>
                  <StyledCustomSelectTriggerInner
                    className="custom-select-trigger"
                    options={options}
                    isActive={!!selectedOption}
                    tabIndex={0}
                    title={selectedOption && (selectedOption.value as string)}
                  >
                    <ClinText
                      whiteSpace="nowrap"
                      variant={TypographyVariant.LargeParagraph}
                    >
                      {selectedOption ? selectedOption.value : placeholder}
                    </ClinText>
                    <ClinIcon
                      iconName={ClinIconPathName.ChevronSmallUpDown}
                      iconSize={ClinTheme.space[4]}
                    />
                  </StyledCustomSelectTriggerInner>
                </StyledCustomSelectTrigger>
              )
            }}
          >
            <StyledCustomSelectOptions>
              {options &&
                options.map((option: ICustomSelectOption, index: number) => {
                  return (
                    <StyledCustomSelectOption
                      className={`custom-select-option-${index}`}
                      title={option && (option.value as string)}
                      tabIndex={0}
                      isActive={option.id === selectedOption?.id}
                      key={`custom-select-${index}`}
                      onClick={() => handleSelect(option)}
                      onKeyDown={(event) => {
                        handleKeyDown(event, option)
                      }}
                    >
                      <ClinText
                        whiteSpace="nowrap"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {option.value}
                      </ClinText>

                      {option.isActive && (
                        <StyledCustomSelectTick>
                          <ClinIcon
                            iconName={ClinIconPathName.Tick}
                            iconSize={ClinTheme.space[3]}
                          />
                        </StyledCustomSelectTick>
                      )}
                    </StyledCustomSelectOption>
                  )
                })}
            </StyledCustomSelectOptions>
          </ClinDropdown>
        </StyledCustomSelect>
      )}
      {prompt && (
        <ClinText
          className="custom-select-prompt"
          as="div"
          fontSize={ClinTheme.fontSizes[1]}
          color={hasError ? ClinTheme.colors.redInvalid : 'inherit'}
          marginTop={ClinTheme.space[2]}
        >
          {prompt}
        </ClinText>
      )}
    </>
  )
}
