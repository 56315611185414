import React, { FunctionComponent, useState, useEffect } from 'react'
import {
  StyledOpaHeaderStepper,
  StyledStepperItem,
  StyledStepperLine,
  StyledStepperItemsWrapper,
  StyledStepperButtonWrapper,
  StyledSaveButton,
  StyledStepperItems
} from './OpaHeaderStepper.styles'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../ClinText'
import { TypographyVariant } from '../ClinText/ClinText.styles'
import { ClinButton } from '../ClinButton'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../context/featureFlags/FeatureFlagKeys'

interface IOpaHeaderStepperProps {
  currentStep: number
  totalSteps: number
  textContentArray: string[]
  poNumber?: string
  programName?: string
  programId?: string
  handleCancelButton: () => void
  handleSaveAndCloseButton: (
    programName?: string,
    programId?: string,
    poNumber?: string
  ) => void
  isParentSaving?: boolean
  isParentCancelling?: boolean
}

export const OpaHeaderStepper: FunctionComponent<IOpaHeaderStepperProps> = ({
  currentStep,
  totalSteps,
  textContentArray,
  poNumber,
  isParentSaving = false,
  isParentCancelling = false,
  programName,
  programId,
  handleCancelButton,
  handleSaveAndCloseButton
}) => {
  const { t } = useTranslation()
  const { useFeatureFlag } = useFeatureFlags()
  const patientCentricFeatureFlag = useFeatureFlag(
    FeatureFlagKeys.PatientCentricJourneyPerUser
  )

  const [isSaving, setIsSaving] = useState(isParentSaving)
  const [isCancelling, setIsCancelling] = useState(isParentCancelling)

  useEffect(() => {
    setIsSaving(isParentSaving)
    setIsCancelling(isParentCancelling)
  }, [isParentSaving, isParentCancelling])

  const steps = Array(totalSteps)
    .fill(false)
    .map((_, i) => i < currentStep)

  return (
    <StyledOpaHeaderStepper>
      <StyledStepperItemsWrapper>
        <StyledStepperItems>
          {steps.map((isCompleted, index) => (
            <StyledStepperItem key={index}>
              <ClinIcon
                viewBox="0 0 20 20"
                iconSize="20px"
                iconName={
                  isCompleted
                    ? ClinIconPathName.WhiteCheckboxPurpleCircle
                    : ClinIconPathName.EmptyPurpleCircle
                }
                iconFill={ClinTheme.colors.primaryMid}
              />
              <ClinText variant={TypographyVariant.H5}>
                {textContentArray[index]}
              </ClinText>
              {index < steps.length - 1 && <StyledStepperLine />}
            </StyledStepperItem>
          ))}
        </StyledStepperItems>
        {patientCentricFeatureFlag && (
          <StyledStepperButtonWrapper>
            <StyledSaveButton
              variant="purpleNarrow"
              onClick={() => {
                if (!isCancelling && !isSaving) {
                  handleSaveAndCloseButton(programName, programId, poNumber)
                  setIsSaving(true)
                }
              }}
              disabled={isSaving || isCancelling}
            >
              {t('common:buttons.save_and_close')}
            </StyledSaveButton>
            <ClinButton
              variant="purpleSecondaryNarrow"
              onClick={() => {
                if (!isCancelling && !isSaving) {
                  handleCancelButton()
                  setIsCancelling(true)
                }
              }}
              disabled={isSaving || isCancelling}
            >
              {t('common:buttons.delete_form')}
            </ClinButton>
          </StyledStepperButtonWrapper>
        )}
      </StyledStepperItemsWrapper>
    </StyledOpaHeaderStepper>
  )
}
