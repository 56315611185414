import React from 'react'
import { t } from 'i18next'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinText } from '../../../../components/ClinText'
import {
  CatalogueItemConstants,
  productStatusTooltipTranslation
} from '../ProductVariant.models'
import StatusTooltip from '../../../Patients/PatientDashboard/StatusTooltip'

interface CatalogueItemStatusComponent {
  catalogueItemStatus: string
}

const StatusColumn: React.FC<CatalogueItemStatusComponent> = ({
  catalogueItemStatus
}) => {
  const statusColors: {
    [key: string]: { backgroundColor: string; textColor: string }
  } = {
    [CatalogueItemConstants.InStock]: {
      backgroundColor: ClinTheme.colors.paleMint,
      textColor: ClinTheme.colors.deepGreen
    },
    [CatalogueItemConstants.LowStock]: {
      backgroundColor: ClinTheme.colors.peachBeige,
      textColor: ClinTheme.colors.orangeWarn
    },
    [CatalogueItemConstants.BackOrder]: {
      backgroundColor: ClinTheme.colors.palePink,
      textColor: ClinTheme.colors.darkRed
    },
    [CatalogueItemConstants.RequestQuote]: {
      backgroundColor: ClinTheme.colors.lightViolet,
      textColor: ClinTheme.colors.primaryMid
    },
    [CatalogueItemConstants.OutOfStock]: {
      backgroundColor: ClinTheme.colors.warmBeige,
      textColor: ClinTheme.colors.black
    }
  }

  const { backgroundColor, textColor } = statusColors[catalogueItemStatus] || {
    backgroundColor: 'rgba(117, 117, 117, 0.1)',
    textColor: ClinTheme.colors.darkGrey
  }

  const getTooltipTranslation = (status: string): string => {
    const tooltipKey =
      productStatusTooltipTranslation[
        status as keyof typeof productStatusTooltipTranslation
      ]
    return tooltipKey ? t(tooltipKey) : ''
  }

  const renderStatusContent = (backgroundColor: string, textColor: string) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor,
        padding: '5px 10px',
        borderRadius: '8px'
      }}
    >
      <ClinText
        whiteSpace="nowrap"
        fontWeight={ClinTheme.fontWeights.medium}
        color={textColor}
        textAlign="center"
        fontSize={ClinTheme.fontSizes[0]}
        lineHeight={ClinTheme.lineHeights.largeParagraph}
        display="flex"
      >
        {catalogueItemStatus}
      </ClinText>
    </div>
  )

  return (
    <StatusTooltip tooltipText={getTooltipTranslation(catalogueItemStatus)}>
      <span style={{ position: 'relative' }}>
        {renderStatusContent(backgroundColor, textColor)}
      </span>
    </StatusTooltip>
  )
}

export default StatusColumn
