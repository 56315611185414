import React, { FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { Trans, useTranslation } from 'react-i18next'

import {
  StyledOverlayLoader,
  StyledSubmit
} from './RemoveUserConfirmation.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { ClinButton } from '../../components/ClinButton'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinLoader } from '../../components/ClinLoader'
import { ClinModal } from '../../components/ClinModal'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinTextInput } from '../../components/ClinTextInput'
import { ContactResponseDto } from '../../types/swaggerTypes'

interface IRemoveUserConfirmationProps {
  /** The user object of who will be deleted **/
  contact: ContactResponseDto | undefined
  /** Control internal state of modal **/
  isOpen?: boolean
  /** Checks whether the request is related to a cancelled invite **/
  isInvite?: boolean
  /** Whether we are loading or not **/
  isLoading: boolean
  /** displays error notification **/
  hasError?: boolean
  /** displays error notification **/
  errorMessage?: string
  /** Whether the submission is still processing **/
  isSubmitting: boolean
  /** Handle submit institution selection  **/
  handleSubmit: () => void
  /** Handle close institution selection  **/
  handleClose: () => void
}

export const RemoveUserConfirmation: FunctionComponent<
  IRemoveUserConfirmationProps
> = ({
  contact,
  isOpen,
  isLoading,
  isInvite,
  hasError,
  errorMessage,
  isSubmitting,
  handleSubmit,
  handleClose
}) => {
  const { t } = useTranslation()

  const [typedName, setTypedName] = React.useState<string | undefined>()

  const clearTextInput = () => setTypedName(undefined)

  const cancelIntro = (
    <Trans
      i18nKey="institute_details:cancel_intro"
      components={{ bold: <strong /> }}
      values={{ name: contact?.contactName }}
    >
      <></>
    </Trans>
  )

  const inviteIntro = (
    <Trans
      i18nKey="institute_details:invite_intro"
      components={{ bold: <strong /> }}
      values={{ name: contact?.contactName }}
    >
      <></>
    </Trans>
  )
  return (
    <ClinModal
      onClose={() => {
        handleClose()
        clearTextInput()
      }}
      maxWidth="sm"
      height="auto"
      isOpen={isOpen}
    >
      {isLoading && (
        <Container>
          <Row justify="center" align="center">
            <Col sm={12}>
              <ClinGroup justifyContent="center">
                <ClinSpinner />
              </ClinGroup>
            </Col>
          </Row>
        </Container>
      )}
      {!isLoading && (
        <Container>
          <Row justify="center">
            <Col sm={12} md={11}>
              {errorMessage && hasError && (
                <Row>
                  <Col>
                    <ClinAnnounceBar title={'Error'}>
                      {errorMessage}
                    </ClinAnnounceBar>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={12} md={10}>
                  <ClinText
                    as="h3"
                    variant={TypographyVariant.H3}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {!isInvite
                      ? t('institute_details:remove_user')
                      : t('institute_details:cancel_invite')}
                  </ClinText>

                  <ClinText variant={TypographyVariant.LargeParagraph}>
                    {!isInvite ? cancelIntro : inviteIntro}
                  </ClinText>
                </Col>
              </Row>

              <ClinSpacer height={ClinTheme.space[3]} />
              <Row>
                <Col sm={12} md={7}>
                  <ClinTextInput
                    id="type-check"
                    name="typeCheck"
                    width="100%"
                    label={
                      !isInvite
                        ? t('institute_details:remove_user_label')
                        : t('institute_details:cancel_invite_label')
                    }
                    onChange={(event) =>
                      setTypedName(event.currentTarget.value)
                    }
                  />
                  <StyledSubmit>
                    <Row justify="between">
                      <Col md={5} lg={6}>
                        <ClinGroup>
                          <ClinButton
                            className="cancel-institute"
                            onClick={() => {
                              handleClose()
                              clearTextInput()
                            }}
                          >
                            {t('common:buttons.cancel')}
                          </ClinButton>
                          <ClinButton
                            className="submit-institute"
                            disabled={
                              contact?.contactName.toLocaleLowerCase() !==
                              typedName?.toLocaleLowerCase()
                            }
                            variant="primary"
                            onClick={() => {
                              handleSubmit()
                              clearTextInput()
                            }}
                          >
                            {!isInvite
                              ? t('institute_details:remove_user')
                              : t('institute_details:cancel_invite_label')}
                          </ClinButton>
                        </ClinGroup>
                      </Col>
                    </Row>
                  </StyledSubmit>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
      {isSubmitting && (
        <StyledOverlayLoader>
          <Container>
            <Row justify="center" align="center">
              <Col sm={12}>
                <ClinText
                  marginLeft="auto"
                  marginRight="auto"
                  marginBottom={ClinTheme.space[5]}
                  textAlign="center"
                  variant={TypographyVariant.LargeParagraph}
                >
                  {t('institute_details:please_wait')}
                </ClinText>
                <ClinGroup justifyContent="center">
                  <div
                    style={{
                      height: ClinTheme.space[9] * 2,
                      width: ClinTheme.space[9] * 2
                    }}
                  >
                    <ClinLoader minHandDuration={'1s'} />
                  </div>
                </ClinGroup>
              </Col>
            </Row>
          </Container>
        </StyledOverlayLoader>
      )}
    </ClinModal>
  )
}
