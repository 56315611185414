import React, { FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  StyledOverlayLoader,
  StyledSubmit
} from './RemoveAddressConfirmation.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { ClinButton } from '../../components/ClinButton'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinLoader } from '../../components/ClinLoader'
import { ClinModal } from '../../components/ClinModal'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'

interface IRemoveAddressConfirmationProps {
  /** Control internal state of modal **/
  isOpen?: boolean
  /** Whether we are loading or not **/
  isLoading: boolean
  /** displays error notification **/
  hasError?: boolean
  /** displays error notification **/
  errorMessage?: string
  /** Whether the submission is still processing **/
  isSubmitting: boolean
  /** Handle submit institution selection  **/
  handleSubmit: () => void
  /** Handle close institution selection  **/
  handleClose: () => void
}

export const RemoveAddressConfirmation: FunctionComponent<
  IRemoveAddressConfirmationProps
> = ({
  isOpen,
  isLoading,
  hasError,
  errorMessage,
  isSubmitting,
  handleSubmit,
  handleClose
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ClinModal
        onClose={handleClose}
        maxWidth="sm"
        height="auto"
        isOpen={isOpen}
      >
        {isLoading && (
          <Container>
            <Row justify="center" align="center">
              <Col sm={12}>
                <ClinGroup justifyContent="center">
                  <ClinSpinner />
                </ClinGroup>
              </Col>
            </Row>
          </Container>
        )}
        {!isLoading && (
          <Container>
            <Row justify="center">
              <Col sm={12} md={11}>
                {errorMessage && hasError && (
                  <Row>
                    <Col>
                      <ClinAnnounceBar title={'Error'}>
                        {errorMessage}
                      </ClinAnnounceBar>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col sm={12} md={10}>
                    <ClinText
                      as="h3"
                      variant={TypographyVariant.H3}
                      fontWeight={ClinTheme.fontWeights.bold}
                    >
                      {t('institute_details:remove_address_modal_title')}
                    </ClinText>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ClinText>
                      {t('institute_details:remove_address_modal_description')}
                    </ClinText>
                  </Col>
                </Row>

                <StyledSubmit>
                  <Row justify="between">
                    <Col md={5} lg={6}>
                      <ClinGroup>
                        <ClinButton
                          className="cancel-institute"
                          onClick={() => handleClose()}
                        >
                          {t('common:buttons.cancel')}
                        </ClinButton>
                        <ClinButton
                          className="submit-institute"
                          variant="primary"
                          onClick={() => handleSubmit()}
                        >
                          {t('common:buttons.submit_request')}
                        </ClinButton>
                      </ClinGroup>
                    </Col>
                  </Row>
                </StyledSubmit>
              </Col>
            </Row>
          </Container>
        )}
        {isSubmitting && (
          <StyledOverlayLoader>
            <Container>
              <Row justify="center" align="center">
                <Col sm={12}>
                  <ClinText
                    marginLeft="auto"
                    marginRight="auto"
                    marginBottom={ClinTheme.space[5]}
                    textAlign="center"
                    variant={TypographyVariant.LargeParagraph}
                  >
                    {t('institute_details:please_wait')}
                  </ClinText>
                  <ClinGroup justifyContent="center">
                    <div
                      style={{
                        height: ClinTheme.space[9] * 2,
                        width: ClinTheme.space[9] * 2
                      }}
                    >
                      <ClinLoader minHandDuration={'1s'} />
                    </div>
                  </ClinGroup>
                </Col>
              </Row>
            </Container>
          </StyledOverlayLoader>
        )}
      </ClinModal>
    </>
  )
}
