import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { Trans, useTranslation } from 'react-i18next'

import { StyledPageHeader } from './HomepageOld.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { AutoSuggest } from '../../../features/Search/AutoSuggest'
import { GlobalSearchAUSOld } from '../../../features/Search/GlobalSearch/old/GlobalSearchAUSOld'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinButton } from '../../../components/ClinButton'
import { getBanner } from '../../../services/ApiService'
import { IBanner } from '../../../types/IBanner'
import { useOnMount } from '../../../utils/useOnMount'
import config from '../../../services/ConfigProvider'
import {
  StyledOrdersAnnounce,
  StyledOrdersAnnounceText,
  StyledOrdersAnnounceCta
} from '../../OrderPages/Orders/Orders.styles'

export const HomepageOld: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [banner, setBanner] = useState<IBanner>()

  useOnMount(() => {
    if (config.showBanner === 'true' || config.showBanner === true) {
      getBanner().then((result) => {
        try {
          // Get data from squidEx dict using language as key accessor
          const bannerObj = result.data?.items[0].data.banner[
            i18n.language
          ] as IBanner
          setBanner(bannerObj)
        } catch (e) {
          console.warn('An error occurred fetching the banner')
        }
      })
    }
  })

  return (
    <>
      {banner && (
        <ClinAnnounceBar
          title={' '}
          mode={AnnounceMode.Information}
          marginBottom={ClinTheme.space[4]}
        >
          <StyledOrdersAnnounce>
            <StyledOrdersAnnounceText>
              <strong>{banner.title}</strong>
              <br />
              {banner.description}
            </StyledOrdersAnnounceText>
            {banner.buttonTitle && banner.buttonUrl && (
              <StyledOrdersAnnounceCta>
                <a
                  href={banner.buttonUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ClinButton as="div" variant="primary">
                    {banner.buttonTitle}
                  </ClinButton>
                </a>
              </StyledOrdersAnnounceCta>
            )}
          </StyledOrdersAnnounce>
        </ClinAnnounceBar>
      )}
      <StyledPageHeader>
        <Row style={{ flexShrink: 1, margin: ClinTheme.space[0] }}>
          <Col className="clin_column" xs={12} md={12}>
            <ClinText
              variant={TypographyVariant.H2}
              color={ClinTheme.colors.white}
              as="h1"
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {t('product_catalogue:aus_title_old')}
            </ClinText>
            <ClinText
              className="description"
              variant={TypographyVariant.LargerParagraph}
              color={ClinTheme.colors.white}
              marginTop={'0px'}
            >
              <Trans
                i18nKey="product_catalogue:aus_description"
                components={{ bold: <strong /> }}
              >
                <></>
              </Trans>
            </ClinText>
          </Col>
        </Row>
      </StyledPageHeader>
      <AutoSuggest
        noResultsText={t('global_search:suggestion_text_aus')}
        maUserNoResultsText={t('global_search:ma_user_no_results_text')}
        suggestionText={t('global_search:suggestion_text_aus')}
        noResultsPath={'/products/catalogue?q=*'}
        suggestionTextColor={ClinTheme.colors.primaryMid}
        suggestionTextPadding={`${ClinTheme.space[3]}px`}
        borderRadiusLastLiElement={'28'}
        searchComponent={(props) => <GlobalSearchAUSOld {...props} />}
      />
      <ClinPageContentFrame hideBreadcrumbs={true} marginBottom="0">
        <></>
      </ClinPageContentFrame>
    </>
  )
}
