import React, { FunctionComponent } from 'react'
import PhoneInput from 'react-phone-input-2'
import {
  StyledClinTextInput,
  StyledInputWrapper,
  StyledLabel,
  StyledInputContainer,
  StyledPrompt,
  PhoneInputWrapper
} from './ClinTextInput.styles'
import { ClinTheme } from '../../ClinTheme'
import { IDefaultStyleProps } from '../../types'
import { isMobile } from '../../utils/isMobile'
import { useLayoutResize } from '../../utils/useLayoutResize'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinText } from '../ClinText'
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/bootstrap.css'

/**
 * Export version with forward ref (for use with react-hook-forms)
 */
export const ClinTextInput = React.forwardRef<
  HTMLInputElement,
  ITextInputProps
>((props: ITextInputProps, ref) => {
  return <ClinTextInputBase inputRef={ref} {...props} />
})

export interface ITextInputStyles
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Sets field styles to error */
  hasError?: boolean
  /** Sets field styles to valid */
  isValid?: boolean
  /** Disables the input */
  disabled?: boolean
  /** Css width value */
  width?: string | number
  /** Css margin bottom value for responsive */
  marginBottom?: string
  placeholderColor?: string
  labelFontSize?: number
  labelLineHeight?: number
  labelFontWeight?: number
}

interface ITextInputProps extends IDefaultStyleProps, ITextInputStyles {
  /** HTML id attribute */
  id: string
  /* Name attribute of the `input` element. */
  name: string
  /** The label to display above the input */
  label?: string
  /** The label to display below the input */
  prompt?: string | React.ReactElement
  /** Sets the html type */
  type?: 'text' | 'email' | 'password' | 'tel' | string
  /** Sets the autocomplete value */
  autoComplete?: 'on' | 'off' | string
  /** Sets the value of the text input */
  value?: string | number | string[]
  /** Automatically sets the focus state */
  autoFocus?: boolean
  /** Placeholder copy to display */
  placeholder?: string
  /** Placeholder copy to display for mobile devices */
  placeholderForMobile?: string
  placeholderColor?: string
  /**  Pass a ref to the `input` element */
  inputRef?: React.Ref<HTMLInputElement>
  /** Function that takes an event and returns nothing */
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  /** Function that takes an event and returns nothing */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** Function that takes an event and returns nothing */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  /** Function that takes an event and returns nothing */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  isPhone?: boolean
  onPhoneChange?: (formattedValue: string) => void
  labelFontSize?: number
  labelLineHeight?: number
  labelFontWeight?: number
}

const InputLabel: React.FC<{
  id: string
  label?: string
  disabled?: boolean
  required?: boolean
  labelFontSize?: number
  labelLineHeight?: number
  labelFontWeight?: number
}> = ({
  id,
  label,
  disabled,
  required,
  labelFontSize,
  labelLineHeight,
  labelFontWeight
}) => {
  if (!label) return null

  return (
    <ClinText
      as="div"
      fontSize={labelFontSize || ClinTheme.fontSizes[1]}
      lineHeight={labelLineHeight || ClinTheme.lineHeights.small}
      fontWeight={labelFontWeight || ClinTheme.fontWeights.light}
      color={disabled ? ClinTheme.colors.grey : 'initial'}
      marginBottom={ClinTheme.space[2]}
    >
      {label}
      {required && '*'}
    </ClinText>
  )
}

const renderPhoneInput = (props: {
  id: string
  name: string
  value?: string | number | string[]
  disabled?: boolean
  required?: boolean
  hasError?: boolean
  isValid?: boolean
  onPhoneChange?: (formattedValue: string) => void
}) => {
  const {
    id,
    name,
    value,
    disabled,
    required,
    hasError,
    isValid,
    onPhoneChange
  } = props

  return (
    <PhoneInputWrapper
      hasError={hasError}
      isValid={isValid}
      disabled={disabled}
    >
      <PhoneInput
        inputProps={{
          id,
          name,
          required,
          disabled
        }}
        disabled={disabled}
        value={value as string}
        country={'gb'}
        preferredCountries={['gb', 'ie']}
        enableSearch={true}
        searchPlaceholder="Search country..."
        onChange={(value, country, event, formattedValue) => {
          onPhoneChange?.(formattedValue)
        }}
      />
    </PhoneInputWrapper>
  )
}

const renderStandardInput = (props: {
  id: string
  name: string
  type?: string
  value?: string | number | string[]
  autoComplete?: string
  autoFocus?: boolean
  disabled?: boolean
  placeholder?: string
  placeholderColor?: string
  isValid?: boolean
  hasError?: boolean
  width?: string | number
  inputRef?: React.Ref<HTMLInputElement>
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  [key: string]: any
}) => {
  const {
    id,
    name,
    type,
    value,
    autoComplete,
    autoFocus,
    disabled,
    placeholder,
    placeholderColor,
    isValid,
    hasError,
    width,
    inputRef,
    onChange,
    onKeyDown,
    onFocus,
    onBlur,
    ...rest
  } = props

  const showStatusIcon = (hasError || isValid) && type !== 'number'

  return (
    <StyledInputWrapper>
      <StyledClinTextInput
        {...rest}
        width={width}
        id={id}
        ref={inputRef}
        type={type}
        name={name}
        value={value}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        isValid={isValid}
        hasError={hasError}
        onChange={(event) => onChange?.(event)}
        onKeyDown={(event) => onKeyDown?.(event)}
        onFocus={(event) => onFocus?.(event)}
        onBlur={(event) => onBlur?.(event)}
      />
      {showStatusIcon && (
        <ClinIcon
          style={{
            position: 'absolute',
            right: ClinTheme.space[2],
            top: '50%',
            transform: 'translateY(-50%)'
          }}
          iconName={hasError ? ClinIconPathName.Cross : ClinIconPathName.Tick}
          iconFill={
            hasError ? ClinTheme.colors.redInvalid : ClinTheme.colors.greenValid
          }
        />
      )}
    </StyledInputWrapper>
  )
}

const renderPrompt = (
  prompt: string | React.ReactElement | undefined,
  hasError?: boolean,
  disabled?: boolean
) => {
  if (!prompt || disabled) return null

  return (
    <ClinText
      as="div"
      fontSize={ClinTheme.fontSizes[1]}
      color={hasError ? ClinTheme.colors.redInvalid : 'inherit'}
      marginTop={ClinTheme.space[2]}
    >
      {prompt}
    </ClinText>
  )
}

const ClinTextInputBase: FunctionComponent<ITextInputProps> = ({
  className = '',
  id,
  name,
  label,
  prompt,
  type = 'text',
  value,
  autoComplete = 'off',
  autoFocus = false,
  disabled = false,
  width = 268,
  marginBottom,
  placeholder,
  placeholderForMobile,
  placeholderColor,
  hasError = false,
  isValid,
  inputRef,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  isPhone = false,
  onPhoneChange,
  labelFontSize,
  labelLineHeight,
  labelFontWeight,
  ...props
}) => {
  const getPlaceholder = (): string | undefined =>
    isMobile() ? placeholderForMobile ?? placeholder : placeholder
  const actualPlaceholder = useLayoutResize<string | undefined>(getPlaceholder)

  if (isPhone) {
    return (
      <StyledInputContainer>
        <StyledLabel
          tabIndex={-1}
          className={`clin-text-input ${className}`}
          title={id}
          htmlFor={id}
          disabled={disabled}
          isValid={isValid}
          hasError={hasError}
          width={width}
          marginBottom={marginBottom}
        >
          <InputLabel
            id={id}
            label={label}
            disabled={disabled}
            required={props.required}
            labelFontSize={labelFontSize}
            labelLineHeight={labelLineHeight}
            labelFontWeight={labelFontWeight}
          />
        </StyledLabel>
        {renderPhoneInput({
          id,
          name,
          value,
          disabled,
          required: props.required,
          hasError,
          isValid,
          onPhoneChange
        })}
        {prompt && <StyledPrompt>{prompt}</StyledPrompt>}
      </StyledInputContainer>
    )
  }

  return (
    <StyledLabel
      tabIndex={-1}
      className={`clin-text-input ${className}`}
      title={id}
      htmlFor={id}
      disabled={disabled}
      isValid={isValid}
      hasError={hasError}
      width={width}
      marginBottom={marginBottom}
    >
      <InputLabel
        id={id}
        label={label}
        disabled={disabled}
        labelFontSize={labelFontSize}
        labelLineHeight={labelLineHeight}
        labelFontWeight={labelFontWeight}
      />
      {renderStandardInput({
        ...props,
        id,
        name,
        type,
        value,
        autoComplete,
        autoFocus,
        disabled,
        placeholder: actualPlaceholder,
        placeholderColor,
        isValid,
        hasError,
        width,
        inputRef,
        onChange,
        onKeyDown,
        onFocus,
        onBlur
      })}
      {renderPrompt(prompt, hasError, disabled)}
    </StyledLabel>
  )
}
