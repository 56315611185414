import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { StyledAccordionPanel } from '../../components/ClinAccordion/ClinAccordion.styles'
import { mediaQuery } from '../../utils/mediaQuery'

const smallUpBreakpoint = ClinTheme.breakpoints[0]
const mediumUpBreakpoint = ClinTheme.breakpoints[1]
const largeUpBreakpoint = ClinTheme.breakpoints[2]

export const StyledSupportLinks = styled.div({
  ul: {
    paddingLeft: 0,

    li: {
      marginBottom: ClinTheme.space[2],
      listStyle: 'none',
      paddingLeft: 0
    }
  }
})

export const StyleSupportHeaderIcon = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end'
})

export const StyledSupportAccordion = styled.div({
  [`${StyledAccordionPanel}`]: {
    width: '100%'
  }
})

export const StyleSupportLinkCards = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',

  [mediaQuery(mediumUpBreakpoint)]: {
    marginLeft: `-${ClinTheme.space[2]}px`
  }
})

export const StyleSupportLinkCard = styled.div({
  flex: '0 0 100%',
  boxSizing: 'border-box',
  marginBottom: ClinTheme.space[3],

  [mediaQuery(smallUpBreakpoint)]: {
    marginBottom: ClinTheme.space[3]
  },
  [mediaQuery(mediumUpBreakpoint)]: {
    padding: ClinTheme.space[2],
    marginBottom: ClinTheme.space[2],
    flex: '0 0 50%'
  },
  [mediaQuery(largeUpBreakpoint)]: {
    flex: '0 0 33%'
  }
})
