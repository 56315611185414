import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

const xLargeUpBreakpoint = ClinTheme.breakpoints[3]

export interface IClinLogoSvgStyleProps {
  /** Whether to display the optional cliniport logo (default is Clinigen logo) */
  isCliniport?: boolean
}

export const StyledClinLogoSvg = styled.svg<IClinLogoSvgStyleProps>(
  {
    height: 32
  },
  ({ isCliniport }: IClinLogoSvgStyleProps) => ({
    width: isCliniport ? 113 : 167,
    [mediaQuery(xLargeUpBreakpoint)]: {
      width: isCliniport ? 141 : 140,
      height: isCliniport ? 40 : 26
    }
  })
)
