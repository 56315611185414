import { AxiosError } from 'axios'
import { useState, useEffect } from 'react'

import { AnnounceMode } from '../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../context/app'
import { createAnnounceEvent } from '../events/AnnounceEvent'
import { cancelGetCountries, getCountries } from '../services/ApiService'
import { CountryDto } from '../types/swaggerTypes'

export interface ICountries {
  isCountriesLoading: boolean
  countries: CountryDto[]
}

/**
 * Custom hook to retrieve an array of countries
 */
export const useCountries = (): ICountries => {
  const [countries, setCountries] = useState<CountryDto[]>([])
  const [isCountriesLoading, setIsCountriesLoading] = useState<boolean>(true)
  const { dispatch } = useAppContext()

  useEffect(() => {
    getCountries()
      .then((response) => response.data && setCountries(response.data))
      .catch((error: AxiosError) =>
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `There was an error fetching country data. ${error.message}`
          )
        )
      )
      .finally(() => setIsCountriesLoading(false))

    return () => {
      cancelGetCountries()
    }
  }, [dispatch])

  return {
    isCountriesLoading,
    countries
  }
}
