import {
  useFloating,
  offset,
  computePosition,
  useDismiss,
  useInteractions,
  autoUpdate,
  flip,
  shift,
  arrow
} from '@floating-ui/react-dom-interactions'
import React, { useEffect, useRef } from 'react'

import {
  arrowOffset,
  crossSides,
  getMainSide,
  getRotation,
  svgWidth,
  unsetSides
} from './ClinNewFeatureTooltip.config'
import { ArrowPosition, INewFeature } from './ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipFloatingUI } from './ClinNewFeatureTooltipFloatingUI'
import { ActionType, useNewFeatures } from '../../context/newFeatures'
import {
  addNewFeatureClosedEvent,
  addNewFeatureDisplayedEvent,
  getNewFeatureEventDescription,
  setFeatureToViewed
} from '../../services/NewFeaturesService'
import {
  FLOATING_OVERLAY_VISIBLE,
  eventEmitter
} from '../../utils/eventEmitter'

interface IClinNewFeatureTooltipProps {
  feature: INewFeature
  //total number of features on one page
  total?: number
  //current number of feature on the page
  current?: number
  openTooltipInitially?: boolean
  disableDismiss?: boolean //if we want to prevent tooltip to close after clicking out of the tooltip
  disableClinFeature?: boolean //if we want to disable initially open tooltip to be closed on refresh
  showFeatureFromTable?: boolean
  hideCloseButton?: boolean
  tooltipWidth?: string
}

export const ClinNewFeatureTooltipContainer: React.FC<
  IClinNewFeatureTooltipProps
> = ({
  feature,
  openTooltipInitially,
  disableDismiss,
  disableClinFeature,
  total,
  current,
  showFeatureFromTable,
  hideCloseButton,
  tooltipWidth
}) => {
  const [, newFeaturesDispatch] = useNewFeatures()
  const [open, setOpen] = React.useState<boolean>(!!openTooltipInitially)

  //Disable tooltip dismiss option when change institute modal is opened
  const alowDismiss =
    document.getElementsByClassName('tooltipTarget')[0] === undefined

  const tooltip = document.getElementById(
    `tooltip-${feature.id}`
  ) as HTMLElement
  const button = document.getElementById(`button-${feature.id}`) as HTMLElement
  const arrowEl = document.getElementById(`arrow-${feature.id}`) as HTMLElement
  const arrowRef: any = useRef(null)

  const handleCloseTooltip = () => {
    if (tooltip) {
      tooltip.style.display = 'none'
    }
  }

  const handleClose = (isOpen: boolean) => {
    if (!isOpen) {
      handleCloseTooltip()
    }
  }

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: handleClose,
    whileElementsMounted: autoUpdate
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useDismiss(context, { enabled: alowDismiss && !disableDismiss })
  ])

  const handleNewFeatureViewed = (isCancelled?: boolean) => {
    setFeatureToViewed(feature.id, feature.version, isCancelled)
      .then(() => {
        newFeaturesDispatch({
          type: ActionType.UpdateNewFeatures,
          feature: { id: feature.id, isCancelled }
        })
        addNewFeatureClosedEvent(getNewFeatureEventDescription(feature.id))
        clearFeatureFromLocalStorage(feature.id)
      })
      .catch((error: any) => {})
  }

  const handleRemoveTooltip = (isCancelled?: boolean) => {
    //we need this in local storage to prevent mix panel close tooltip event to trigger twice
    localStorage.setItem(`feature-${feature.id}-viewed`, feature.id)
    handleNewFeatureViewed(isCancelled)
  }

  const clearFeatureFromLocalStorage = (featureId: string) => {
    localStorage.removeItem(`feature-${featureId}`)
    localStorage.removeItem(`feature-${featureId}-viewed`)
  }

  const cleanSeenFeatures = () => {
    const savedFeature = localStorage.getItem(`feature-${feature.id}`)
    const isFeatureViewedInStorage =
      localStorage.getItem(`feature-${feature.id}-viewed`) === feature.id

    if (savedFeature === feature.id && !isFeatureViewedInStorage) {
      if (feature.isCancelled) {
        clearFeatureFromLocalStorage(feature.id)
      } else {
        handleNewFeatureViewed()
      }
    }
  }

  const handleOpenTooltip = () => {
    if (!tooltip) return

    if (!feature.isCancelled) {
      localStorage.setItem(`feature-${feature.id}`, feature.id)
    }

    setOpen(true)
    tooltip.style.display = 'block'

    //call mixpanel event for displaying new feature to user
    const eventDescription = getNewFeatureEventDescription(feature.id)
    if (eventDescription) {
      addNewFeatureDisplayedEvent(eventDescription)
    }
  }

  const applyStaticArrowPosition = (
    arrowEl: HTMLElement,
    mainSide: string,
    placement: string
  ) => {
    const crossSide: any = crossSides.find((x) => x.name === placement)?.value

    Object.assign(arrowEl.style, {
      ...unsetSides,
      [mainSide]: arrowOffset,
      [crossSide]: 'min(15%, 15px)'
    })
  }

  const applyDynamicArrowPosition = (
    arrowEl: HTMLElement,
    mainSide: string,
    xArrow: number | null,
    yArrow: number | null,
    placement: string
  ) => {
    const isEndAlignment = placement.split('-')[1] === ArrowPosition.end
    let calcX = xArrow ? xArrow + svgWidth / 2 : ''

    if (isEndAlignment) {
      calcX = xArrow ? xArrow - svgWidth / 2 : ''
    }

    Object.assign(arrowEl.style, {
      ...unsetSides,
      left: xArrow !== null ? `${calcX}px` : '',
      top: yArrow !== null ? `${yArrow}px` : '',
      [mainSide]: arrowOffset
    })
  }

  const updateArrowPosition = (placement: any, middlewareData: any) => {
    if (!arrowEl || !middlewareData.arrow) return

    const { x: xArrow, y: yArrow } = middlewareData.arrow
    const [side, alignment] = placement.split('-')
    const mainSide = getMainSide(side)

    // Set arrow rotation
    const rotation = getRotation(side)
    arrowEl.style.transform = `rotate(${rotation})`

    // Determine positioning method
    const isAligned = alignment !== null
    const isVerticalSide =
      side === ArrowPosition.top || side === ArrowPosition.bottom
    const isRefWider = button?.offsetWidth > tooltip?.offsetWidth
    const isRefTaller = button?.offsetHeight > tooltip?.offsetHeight

    const useStaticPositioning =
      isAligned && (isVerticalSide ? isRefWider : isRefTaller)

    // Apply appropriate positioning
    if (useStaticPositioning) {
      applyStaticArrowPosition(arrowEl, mainSide, placement)
    } else {
      applyDynamicArrowPosition(arrowEl, mainSide, xArrow, yArrow, placement)
    }
  }

  const updatePosition = () => {
    if (!button || !tooltip) return

    computePosition(button, tooltip, {
      placement: feature.position ? feature.position : 'top-start',
      middleware: [
        offset(20),
        flip({ padding: 5 }),
        shift(),
        arrow({ element: arrowRef })
      ]
    }).then(({ x, y, placement, middlewareData }) => {
      const calculateX =
        placement.split('-')[1] === ArrowPosition.end ? x + 5 : x - 6

      Object.assign(tooltip.style, {
        left: `${calculateX}px`,
        top: `${y}px`
      })

      updateArrowPosition(placement, middlewareData)
    })
  }

  const toggleOverlayForTableFeature = (visible: boolean) => {
    if (!showFeatureFromTable) return

    const overlayElement = document.querySelector(
      '.feature-overlay'
    ) as HTMLElement
    if (overlayElement) {
      overlayElement.style.display = visible ? 'block' : 'none'
      eventEmitter.emit(FLOATING_OVERLAY_VISIBLE, visible)
    }
  }

  const setupBodyScroll = (shouldLock: boolean) => {
    if (shouldLock) {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop
      document.body.style.overflow = 'hidden'
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.scrollTop = scrollPosition
      toggleOverlayForTableFeature(true)
    } else {
      document.body.style.pointerEvents = 'auto'
      document.documentElement.style.overflow = 'auto'
      document.body.style.overflow = 'auto'
      toggleOverlayForTableFeature(false)
    }
  }

  // Effect for position updates
  useEffect(() => {
    updatePosition()
    if (!openTooltipInitially && tooltip) {
      tooltip.style.display = 'none'
    }
  }, [tooltip])

  // Effect for feature tracking initialization
  useEffect(() => {
    if (
      openTooltipInitially &&
      alowDismiss &&
      !disableClinFeature &&
      !feature.isCancelled
    ) {
      localStorage.setItem(`feature-${feature.id}`, feature.id)
    }
    return () => cleanSeenFeatures()
  }, [])

  // Effect for body scroll locking
  useEffect(() => {
    const shouldLockScroll = open && alowDismiss && !feature.isCancelled
    setupBodyScroll(shouldLockScroll)

    return () => setupBodyScroll(false)
  }, [open, alowDismiss])

  // Effect for auto-positioning
  useEffect(() => {
    if (button && tooltip) {
      const cleanup = autoUpdate(button, tooltip, updatePosition, {
        ancestorScroll: true,
        ancestorResize: false,
        elementResize: false
      })
      return cleanup
    }
  }, [button, tooltip])

  return (
    <ClinNewFeatureTooltipFloatingUI
      id={feature.id}
      title={feature.title}
      description={feature.description}
      total={total}
      current={current}
      style={feature.style}
      tooltipWidth={tooltipWidth}
      open={open}
      position={feature.position}
      cssClass={feature.cssClass}
      tooltip={tooltip}
      button={button}
      arrowEl={arrowEl}
      arrowRef={arrowRef}
      x={x}
      y={y}
      strategy={strategy}
      showFeatureFromTable={showFeatureFromTable}
      isCancelled={feature.isCancelled}
      reference={reference}
      floating={floating}
      getFloatingProps={getFloatingProps}
      getReferenceProps={getReferenceProps}
      handleRemoveTooltip={handleRemoveTooltip}
      handleOpenTooltip={handleOpenTooltip}
      hideCloseButton={hideCloseButton}
    />
  )
}
