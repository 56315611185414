import styled from 'styled-components'

import { HoldAnnounceMode } from './HoldAnnounce'
import { ClinTheme } from '../../ClinTheme'

export interface IHoldAnnounceStyles {
  /** The mode of the announce */
  mode?: HoldAnnounceMode
  /** Add bottom margin */
  marginBottom?: number
}

export const StyledHoldAnnounce = styled.div<IHoldAnnounceStyles>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${ClinTheme.space[4]}px;
  border-radius: ${ClinTheme.radii[2]}px;
  overflow: hidden;
  color: ${ClinTheme.colors.black};
  background-color: ${ClinTheme.colors.white};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  box-shadow: ${`0px 4px 8px 0px rgba(0, 0, 0, 0.25)`};
`

export const StyledMessage = styled.div({
  marginRight: 'auto',
  marginLeft: ClinTheme.space[4],
  flex: '1 0 0'
})

export const StyledCloseButton = styled.div({
  position: 'absolute',
  top: `${ClinTheme.space[1]}px`,
  right: `${ClinTheme.space[3]}px`,
  [`.clin-text`]: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
})

export const StyledVerticalLine = styled.div<IHoldAnnounceStyles>(
  {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 4
  },
  ({ mode }) => ({
    backgroundColor:
      mode === HoldAnnounceMode.Error
        ? ClinTheme.colors.redInvalid
        : ClinTheme.colors.primaryMid
  })
)
