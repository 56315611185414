import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { UserRole, isAusGaUser } from '../../../constants'
import { useCheckUnableHomePage } from '../../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'
import {
  StyledSummaryHeading,
  StyledSummaryIcon
} from '../../CheckoutSummary/CheckoutSummary.styles'

interface IRequestAccessSuccessProps {
  userCountry: string
  userRole?: UserRole
}

export const RequestAccessSuccess: FunctionComponent<
  IRequestAccessSuccessProps
> = ({ userCountry, userRole }) => {
  const { t } = useTranslation()
  const ausGaUser = isAusGaUser(userCountry, userRole)
  const { enableNewHomePage } = useCheckUnableHomePage()

  return (
    <ClinPageContentFrame
      crumbs={[
        ausGaUser && enableNewHomePage
          ? {
              path: '/home',
              name: t('navigation:home')
            }
          : { path: '/orders', name: t('orders:page_name') },
        {
          name: t('request_access:page_name'),
          path: '/institutes/request-access'
        }
      ]}
    >
      <StyledSummaryHeading>
        <StyledSummaryIcon>
          <ClinIcon
            iconName={ClinIconPathName.Tick}
            iconSize={ClinTheme.space[4]}
          />
        </StyledSummaryIcon>
        <ClinText
          variant={TypographyVariant.H2}
          as="span"
          fontWeight={ClinTheme.fontWeights.bold}
          marginLeft={ClinTheme.space[2]}
        >
          Your request was successful.
        </ClinText>
      </StyledSummaryHeading>
      <ClinSpacer height={ClinTheme.space[4]} hasBorder={true} />
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col sm={12} md={10} lg={8}>
          <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
            Your request has been submitted to our customer services team. We
            will be in touch once we have verified you are able to place orders
            on behalf of this institute. If you require immediate access, please
            contact our customer services team directly on{' '}
            <a style={{ display: 'inline-block' }} href="tel:441932824100">
              +44 (0) 1932 824100
            </a>{' '}
            or email{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:ukcustomerservice@clinigengroup.com"
            >
              ukcustomerservice@clinigengroup.com
            </a>
          </ClinText>
          <ClinSpacer height={ClinTheme.space[7]} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={10} lg={8}>
          <Link to={ausGaUser && enableNewHomePage ? '/home' : '/orders'}>
            <ClinButton variant="primary">
              {ausGaUser
                ? t('common:buttons.back_to_home')
                : t('common:buttons.back')}
            </ClinButton>
            <ClinSpacer height={ClinTheme.space[5]} />
          </Link>
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
