import i18next from 'i18next'
import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  StyledUserCard,
  StyledUserCardHeader,
  StyledUserCardProfile,
  StyledUserCardProfileAvatar,
  StyledUserCardHeaderAction,
  StyledUserCardRow,
  StyledLoadingOverlay
} from './InstituteDetailUserCard.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinCard } from '../../../components/ClinCard'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ContactTitles, UserRole } from '../../../constants/user'
import { IRole } from '../../../types'
import { IContact } from '../../../types/IContact'
import { getSquidExObjectForKeyAndValue } from '../../../utils/getSquidExObjectForKeyAndValue'

export interface IInstituteDetailUserCardStyles {
  isPending?: boolean
}

export interface IInstituteDetailUserCardProps
  extends IInstituteDetailUserCardStyles {
  isUser: boolean
  contact: IContact
  onEditUser?: (userId: number) => void
  onRemoveUser?: (user: IContact, isInvite: boolean) => void
  onRequestInvite?: (user: IContact) => void
}

export const InstituteDetailUserCard: FunctionComponent<
  IInstituteDetailUserCardProps
> = ({
  isUser,
  isPending,
  contact,
  onEditUser,
  onRemoveUser,
  onRequestInvite
}) => {
  const { t } = useTranslation()

  const contactTitlesOptions = Object.values(ContactTitles)
  const contactTitlesArray = contactTitlesOptions.map((item) => item.valueOf())
  const name = contact.contactName.split(' ')
  const initials = name
    .filter((item) => !contactTitlesArray.includes(item))
    .map((item) => item.charAt(0))
    .join('')

  const role = getSquidExObjectForKeyAndValue(
    'key',
    contact.clientRelationships[0].roleType as UserRole,
    i18next.t('user_roles:roles', {
      returnObjects: true
    })
  ) as IRole

  return (
    <ClinCard removePadding={true}>
      <StyledUserCard isPending={isPending}>
        {contact.isLoading && (
          <StyledLoadingOverlay>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledLoadingOverlay>
        )}
        <StyledUserCardHeader>
          <Row nogutter={true}>
            <Col width="auto">
              <StyledUserCardProfile>
                <StyledUserCardProfileAvatar className={'avatar'}>
                  {initials}
                </StyledUserCardProfileAvatar>
              </StyledUserCardProfile>
            </Col>
            <Col>
              <Row nogutter={true} justify="between">
                <Col width="auto">
                  <ClinText
                    fontSize={ClinTheme.fontSizes[2]}
                    fontWeight={ClinTheme.fontWeights.medium}
                  >
                    {contact.contactName}
                  </ClinText>
                  <ClinText fontSize={ClinTheme.fontSizes[2]}>
                    {contact?.contactEmail}
                  </ClinText>
                </Col>
                <Col sm={12} md={4}>
                  <StyledUserCardHeaderAction>
                    {isPending && onRequestInvite && (
                      <ClinButton
                        style={{ marginRight: ClinTheme.space[2] }}
                        variant="linkButton"
                        onClick={() => onRequestInvite(contact)}
                      >
                        {t('common:buttons.resend')}
                      </ClinButton>
                    )}
                    {!isPending && onEditUser && (
                      <ClinButton
                        style={{ marginRight: ClinTheme.space[2] }}
                        variant="linkButton"
                        onClick={() => onEditUser(contact.contactId)}
                      >
                        {t('common:buttons.edit')}
                      </ClinButton>
                    )}
                    <ClinButton
                      disabled={isUser}
                      style={{ marginRight: ClinTheme.space[2] }}
                      variant="linkButton"
                      onClick={() =>
                        onRemoveUser &&
                        onRemoveUser(contact, isPending ? true : false)
                      }
                    >
                      {isPending
                        ? t('common:buttons.cancel')
                        : t('common:buttons.remove')}
                    </ClinButton>
                  </StyledUserCardHeaderAction>
                </Col>
              </Row>
            </Col>
          </Row>
        </StyledUserCardHeader>

        <StyledUserCardRow>
          <Row justify="between">
            <Col xs={12} sm={4} md={3}>
              <ClinText variant={TypographyVariant.LargeParagraph}>
                {t('institute_details:job_type')}
              </ClinText>
            </Col>
            <Col xs={12} sm={8} md={9}>
              <ClinText
                variant={TypographyVariant.LargeParagraph}
                fontWeight={ClinTheme.fontWeights.bold}
              >
                {contact.classification ? contact.classification : '-'}
              </ClinText>
            </Col>
          </Row>
        </StyledUserCardRow>
        <StyledUserCardRow>
          <Row justify="between">
            <Col xs={12} sm={4} md={3}>
              <ClinText variant={TypographyVariant.LargeParagraph}>
                {t('institute_details:specialism')}
              </ClinText>
            </Col>
            <Col xs={12} sm={8} md={9}>
              <ClinText
                variant={TypographyVariant.LargeParagraph}
                fontWeight={ClinTheme.fontWeights.bold}
              >
                {contact.specialism ? contact.specialism : '-'}
              </ClinText>
            </Col>
          </Row>
          {contact.licenseNumber && (
            <>
              <Row justify="between">
                <ClinSpacer />

                <Col xs={12} sm={4} md={3}>
                  <ClinText variant={TypographyVariant.LargeParagraph}>
                    {t('institute_details:licence_number')}
                  </ClinText>
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <ClinText
                    variant={TypographyVariant.LargeParagraph}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {contact.licenseNumber}
                  </ClinText>
                </Col>
              </Row>
            </>
          )}
        </StyledUserCardRow>
        <StyledUserCardRow>
          <Row justify="between">
            <Col xs={12} sm={4} md={3}>
              <ClinText variant={TypographyVariant.LargeParagraph}>
                {t('institute_details:phone_number')}
              </ClinText>
            </Col>
            <Col xs={12} sm={8} md={9}>
              {contact.phones.map((item, index) => {
                return (
                  <ClinText
                    key={`contact-number-${index}`}
                    variant={TypographyVariant.LargeParagraph}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {item.phoneNumber}
                  </ClinText>
                )
              })}
              {contact.phones.length === 0 && (
                <ClinText
                  variant={TypographyVariant.LargeParagraph}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  -
                </ClinText>
              )}
            </Col>
          </Row>
        </StyledUserCardRow>
        <StyledUserCardRow>
          <Row justify="between">
            <Col xs={12} sm={4} md={3}>
              <ClinText variant={TypographyVariant.LargeParagraph}>
                {t('institute_details:permissions')}
              </ClinText>
            </Col>
            <Col xs={12} sm={8} md={9}>
              {role && (
                <>
                  <ClinText
                    variant={TypographyVariant.LargeParagraph}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {role.contact_type ? role.contact_description : ''}
                  </ClinText>
                  <ClinText variant={TypographyVariant.LargeParagraph}>
                    {role.contact_description ? role.contact_description : ''}
                  </ClinText>
                </>
              )}
            </Col>
          </Row>
        </StyledUserCardRow>
      </StyledUserCard>
    </ClinCard>
  )
}
