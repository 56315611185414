import { AxiosError } from 'axios'

import { Dispatch } from './BasketContext'
import { ActionType } from './BasketContext.actions'
import { IDocumentForBasketItem } from '../../pages/Products/ProductDetail/ProductDetailContainer'
import {
  getCurrentBasket,
  getPriceAndAvailabilityForSku,
  setHoldDocumentToBasketItem,
  upsertSkuItemToBasket
} from '../../services/ApiService'
import {
  BasketItemDocumentDto,
  BasketItemDto,
  PriceAndAvailabilityDto
} from '../../types/swaggerTypes'

/**
 * A collection of async helper functions for the basket context
 * Each one accepted the dispatch and deals with dispatching its own events once
 * promise calls have been resolved
 */

/**
 * Async API calls need to await and dispatch their own updates
 * @param dispatch
 * @usage:
 * <button onClick={(e) => getBasketAsync(basketDispatch)}>Update</button>
 */
async function getBasketAsync(dispatch: Dispatch) {
  dispatch({
    type: ActionType.UpdateBasket,
    basket: { isLoading: true }
  })
  try {
    const apiResponse = await getCurrentBasket()
    apiResponse &&
      dispatch({
        type: ActionType.UpdateBasket,
        basket: { data: apiResponse.data, isLoading: false }
      })
  } catch (error) {
    dispatch({
      type: ActionType.UpdateBasket,
      basket: { error: error as AxiosError, isLoading: false }
    })
  }
}

/**
 * Pricing and availability call
 * @param dispatch
 * @param skus
 * @param addressId
 */
async function getPriceAndAvailabilityAsync(
  dispatch: Dispatch,
  skus: string[],
  addressId: string
) {
  dispatch({
    type: ActionType.LoadPriceAndAvailability,
    priceAndAvailability: { isLoading: true }
  })

  const pricingCalls: Promise<PriceAndAvailabilityDto>[] = skus.map((sku) =>
    getPriceAndAvailabilityForSku(sku, addressId).then((r) => r.data)
  )
  Promise.all(pricingCalls)
    .then((results) => {
      dispatch({
        type: ActionType.LoadPriceAndAvailability,
        priceAndAvailability: { data: results, isLoading: false }
      })
    })
    .catch((error) => {
      dispatch({
        type: ActionType.LoadPriceAndAvailability,
        priceAndAvailability: { error, isLoading: false }
      })
    })
}

/**
 * Adding or updating a basket item
 * @param dispatch
 * @param sku
 * @param quantity
 * @param priceAndAvailability
 * @param basketItemDocuments
 */
async function addBasketItemAsync(
  dispatch: Dispatch,
  sku: string,
  quantity: number,
  priceAndAvailability: PriceAndAvailabilityDto,
  basketItemDocuments?: IDocumentForBasketItem[]
) {
  dispatch({
    type: ActionType.AddBasketItem,
    basket: { isLoading: true }
  })
  try {
    const apiResponse = await upsertSkuItemToBasket(sku, quantity)
    const addedBasketItem: BasketItemDto | undefined =
      apiResponse.data.items.find((b) => b.sku === sku)

    if (!addedBasketItem) {
      dispatch({
        type: ActionType.AddBasketItem,
        basket: { error: 'Error updating your basket item', isLoading: false }
      })
      return
    }
    // Update state:
    let updatedBasketItem: BasketItemDto = {
      ...addedBasketItem
    }
    // Check for any documents for this item!
    if (
      addedBasketItem &&
      basketItemDocuments &&
      basketItemDocuments?.length > 0
    ) {
      // Send a request for each document
      const documentHoldCalls: Promise<BasketItemDto>[] =
        basketItemDocuments.map(({ sku, document, holdDetails }) =>
          setHoldDocumentToBasketItem({
            basketItemDocumentId: document.uploadedDocumentId,
            basketItemId: addedBasketItem.basketItemId,
            documentType: holdDetails.requiredDocumentName!, // The name of the requiredDocumentName from the hold
            holdName: holdDetails.holdDto!.holdName,
            uploadedDocument: document,
            uploadedDocumentId: document.uploadedDocumentId
          }).then((r) => r.data)
        )
      // Get back all new updated basket items (with docs)
      const updatedBasketItemsWithDocs: BasketItemDto[] = await Promise.all(
        documentHoldCalls.map(async (updatedBasketItem) => updatedBasketItem)
      )
      // Get all basketItem documents
      const uploadedDocuments: BasketItemDocumentDto[] =
        updatedBasketItemsWithDocs
          .map((item) => item.documents)
          .reduce((a, c) => a.concat(c), [])

      // TODO: Check this isn't dispatching twice in SKU selector
      // Update state with new docs:
      updatedBasketItem = {
        ...addedBasketItem,
        documents: [...uploadedDocuments]
      }
    }

    dispatch({
      type: ActionType.AddBasketItem,
      basket: { data: apiResponse.data, isLoading: false },
      basketItem: updatedBasketItem,
      pricing: priceAndAvailability,
      quantity
    })
  } catch (error) {
    dispatch({
      type: ActionType.AddBasketItem,
      basket: { error: error as AxiosError, isLoading: false }
    })
    throw error
  }
}

export { getBasketAsync, getPriceAndAvailabilityAsync, addBasketItemAsync }
