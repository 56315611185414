import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledItemDetails,
  StyledProductInformation,
  StyledProductInformationContainer,
  StyledDocumentsSection,
  StyledClinButton
} from './ProductInformation.styles'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import { IAugmentedCatalogItemDto } from '../../../../services/PortfolioJourneys/PortfolioJourney.types'

interface ProductInformationProps {
  catalogItem: IAugmentedCatalogItemDto
  handleSelectVariant?: (sku: string) => void
}

const ProductInformation: React.FC<ProductInformationProps> = ({
  catalogItem,
  handleSelectVariant
}) => {
  const { t } = useTranslation()

  return (
    <StyledProductInformation>
      <ClinText
        variant={TypographyVariant.SmallUI}
        lineHeight={ClinTheme.lineHeights.heading[1]}
        fontSize={ClinTheme.fontSizes[2]}
      >
        {t('product_variants:product_information')}
      </ClinText>
      <ClinText
        lineHeight={ClinTheme.lineHeights.heading[0]}
        fontSize={ClinTheme.fontSizes[1]}
      >
        {t('glossary:product_code')}: {catalogItem.item.sku || '--'}
      </ClinText>
      <ClinSpacer hasBorder color={ClinTheme.colors.softGrey} />
      <StyledProductInformationContainer>
        <StyledItemDetails>
          <ClinText
            variant={TypographyVariant.SmallUI}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {t('glossary:manufacturer')}
          </ClinText>
          <ClinText
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {catalogItem.item.manufacturer || '--'}
          </ClinText>
        </StyledItemDetails>
        <StyledItemDetails>
          <ClinText
            variant={TypographyVariant.SmallUI}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {t('glossary:expiry_date')}
          </ClinText>
          <ClinText
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {t('product_variants:greater_than_six_month')}
          </ClinText>
        </StyledItemDetails>
        <StyledItemDetails minWidth="168px">
          <ClinText
            variant={TypographyVariant.SmallUI}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {t('glossary:controlled_drug_status')}
          </ClinText>
          <ClinText
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {catalogItem.item.controlledDrug || '--'}
          </ClinText>
        </StyledItemDetails>
        <StyledItemDetails minWidth="158px">
          <ClinText
            variant={TypographyVariant.SmallUI}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {t('product_variants:storage')}
          </ClinText>
          <ClinText
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {catalogItem.item.storageTemperature}
            &#176;C
          </ClinText>
        </StyledItemDetails>
        <StyledItemDetails>
          <ClinText
            variant={TypographyVariant.SmallUI}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {t('glossary:country_of_license')}
          </ClinText>
          <ClinText
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {catalogItem.licenses?.length
              ? catalogItem.licenses
                  .map((license: any) => license.country)
                  .join(', ')
              : '--'}
          </ClinText>
        </StyledItemDetails>
        <StyledItemDetails>
          <ClinText
            variant={TypographyVariant.SmallUI}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {t('product_variants:license_number')}
          </ClinText>
          <ClinText
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {catalogItem.licenses?.length
              ? catalogItem.licenses
                  .map((license: any) => license.licenseNumber)
                  .join(', ')
              : '--'}
          </ClinText>
        </StyledItemDetails>
        <StyledItemDetails>
          <ClinText
            variant={TypographyVariant.SmallUI}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {t('glossary:license_holder')}
          </ClinText>
          <ClinText
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {catalogItem.item.maHolder || '--'}
          </ClinText>
        </StyledItemDetails>
      </StyledProductInformationContainer>
      <ClinSpacer hasBorder color={ClinTheme.colors.softGrey} />
      <StyledDocumentsSection>
        <div>
          <ClinText
            variant={TypographyVariant.SmallUI}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {t('product_variants:available_documents')}
          </ClinText>
          <ClinText
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontSize={ClinTheme.fontSizes[1]}
          >
            {catalogItem.documents?.length
              ? catalogItem.documents
                  .filter((item: any) => item.externallyVisible === 'Y')
                  .map((documentItem) => documentItem.documentType)
                  .join(', ')
              : '--'}
          </ClinText>
          {catalogItem && catalogItem.cannotOrder && (
            <ClinButton
              as="span"
              variant="link"
              color={ClinTheme.colors.tealishBlue}
              style={{ fontWeight: 500 }}
              onClick={() =>
                handleSelectVariant && handleSelectVariant(catalogItem.item.sku)
              }
            >
              {t('product_variants:view_documents_full_product_details')}
            </ClinButton>
          )}
        </div>
        {catalogItem && !catalogItem.cannotOrder && (
          <StyledClinButton
            variant="secondary"
            onClick={() =>
              handleSelectVariant && handleSelectVariant(catalogItem.item.sku)
            }
          >
            {t('product_variants:view_full_product_details')}
          </StyledClinButton>
        )}
      </StyledDocumentsSection>
    </StyledProductInformation>
  )
}

export default ProductInformation
