import { AxiosError } from 'axios'
import React, { FunctionComponent, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { RequestAccess } from './RequestAccess'
import { RequestAccessSuccess } from './RequestAccessSuccess'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { AppContext } from '../../../context/app'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { AnalyticsEvent } from '../../../services/Analytics'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { createInstitute } from '../../../services/ApiService'
import { NewInstituteRequestDto } from '../../../types/swaggerTypes'
import { useBeforeUnload } from '../../../utils/useBeforeUnload'
import { useCountries } from '../../../utils/useCountries'

export const RequestAccessContainer: FunctionComponent = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { dispatch, portfolioCountryCode, userRole } = useContext(AppContext)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isPageEdited, setIsPageEdited] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const leaveMessage = t('common:leave_message')
  useBeforeUnload(leaveMessage, isPageEdited && !isSubmitted)

  const { isCountriesLoading, countries } = useCountries()

  const handleFormSubmission = (data: NewInstituteRequestDto) => {
    setIsSubmitting(true)
    createInstitute(data)
      .then((response) => {
        if (response.status === 200) {
          createAnnounceEvent(
            AnnounceMode.Success,
            t('request_access:success_response'),
            '  '
          )
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.RequestAccessToInstitute
          )
          setIsSubmitted(true)
        }
      })
      .catch((error: AxiosError) => {
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `There was an error requesting your institute. ${error.message} ${
              error.code ? error.code : ''
            }`
          )
        )
        window.scrollTo(0, 0)
      })
      .finally(() => setIsSubmitting(false))
  }

  const handleIsPageEdited = (isEdited: boolean) => {
    setIsPageEdited(isEdited)
  }

  const handleCancel = () => {
    history.push('/orders')
  }

  return (
    <>
      {!isSubmitted ? (
        <RequestAccess
          isLoading={isCountriesLoading}
          isSubmitting={isSubmitting}
          isPageEdited={isPageEdited}
          countries={countries}
          userCountry={portfolioCountryCode}
          userRole={userRole}
          handleFormSubmission={handleFormSubmission}
          handleIsPageEdited={handleIsPageEdited}
          handleCancel={handleCancel}
        />
      ) : (
        <RequestAccessSuccess
          userCountry={portfolioCountryCode}
          userRole={userRole}
        />
      )}
    </>
  )
}
