import {
  Calendar,
  Day,
  utils
} from '@amir04lm26/react-modern-calendar-date-picker'
import { AxiosError } from 'axios'
import download from 'downloadjs'
import set from 'lodash/set'
import { DateTime } from 'luxon'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  useMemo
} from 'react'
import { RouteComponentProps, StaticContext, matchPath } from 'react-router'
import { $enum } from 'ts-enum-util'

import { PatientDetail } from './PatientDetailOld'
import { ClinTheme } from '../../../../ClinTheme'
import { AnnounceMode } from '../../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { IPagination } from '../../../../components/ClinPagination/ClinPagination.model'
import { PatientDocumentType } from '../../../../constants'
import { useAppContext } from '../../../../context/app'
import { useFeatureFlags } from '../../../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../../../context/featureFlags/FeatureFlagKeys'
import {
  clearAnnounceEvent,
  createAnnounceEvent
} from '../../../../events/AnnounceEvent'
import { useEffectOnlyOnce } from '../../../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { AnalyticsEvent } from '../../../../services/Analytics'
import { AnalyticsPageEvent } from '../../../../services/Analytics/AnalyticsPageEvent'
import analyticsServiceSingleton from '../../../../services/Analytics/initAnalytics'
import {
  AuthError,
  downloadDocumentById,
  reconcileVialForPatient
} from '../../../../services/ApiService'
import { IRestEndpointState } from '../../../../types/IRestEndpointState'
import {
  InstituteDto,
  PatientDetailsDto,
  ProgramDocumentDto
} from '../../../../types/swaggerTypes'
import {
  convertDateStringToDay,
  convertDayToISODateString
} from '../../../../utils/dateUtils'
import { getBrowserLocale } from '../../../../utils/getBrowserLocale'
import { getFilenameFromUrl } from '../../../../utils/getFilenameFromUrl'
import { useOnMount } from '../../../../utils/useOnMount'
import {
  PatientOrderStatus,
  OPAOrderTypes,
  ActionDropdownConstants
} from '../../PatientDashboard/Patient.model'
import { DiscontinueConfirmationModal } from '../DiscontinueConfirmationModal'
import { useDiscontinueConfirmationModal } from '../DiscontinueConfirmationModal/DiscontinueConfirmationModal.model'
import { DiscontinueWarningModal } from '../DiscontinueWarningModal'
import {
  displayReconcileAnnounce,
  getNumberOfVialsToReconcile,
  OrderHistoryColumn,
  PatientTab,
  useAugmentedOrders,
  useProgram,
  useReadOnlyPatientAccessForm
} from '../PatientDetail.model'
import {
  StyledCalendarModal,
  StyledCalendarWrapper
} from '../PatientDetail.styles'
import {
  ICalendarPicker,
  IPastOrderSummary,
  IPatientVial,
  VialReconciliationType
} from '../PatientDetail.types'
import { PatientDetailDiscontinued } from '../PatientDetailDiscontinued/PatientDetailDiscontinued'
import { ITransferForm } from '../TransferPatientForm/TransferPatientForm'

export enum ReconcileStatus {
  SuccessfulReconciliation,
  RequiresReconciliation,
  NoReconciliationRequired
}

const defaultPagination: IPagination = {
  count: 0,
  skip: 0,
  take: 5,
  total: 0
}

type LocationState = {
  from?: string
  endpoint?: string
  orderNumber?: string
}

interface IPatientDetailRouteParams {
  patientId: string
  physicianId: string
}

interface IPatientDetailProps
  extends RouteComponentProps<
    IPatientDetailRouteParams,
    StaticContext,
    LocationState
  > {}

const allStatusActions = {
  [ActionDropdownConstants.BeginOrder]: {
    text: 'patient_detail:patient_actions.begin_order'
  },
  [ActionDropdownConstants.DiscontinuePatient]: {
    text: 'patient_detail:patient_actions.discontinue_patients'
  },
  [ActionDropdownConstants.ContinueOrder]: {
    text: 'patient_detail:patient_actions.continue_order'
  },
  [ActionDropdownConstants.OrderResuply]: {
    text: 'patient_detail:patient_actions.order_resupply'
  },
  [ActionDropdownConstants.TrackYourOrder]: {
    text: 'patient_detail:patient_actions.track_your_order'
  },
  [ActionDropdownConstants.ReconcileVials]: {
    text: 'patient_detail:patient_actions.reconcile_vials'
  },
  [ActionDropdownConstants.TransferPatient]: {
    text: 'patient_detail:patient_actions.transfer_patients'
  }
}

export const PatientDetailContainerOld: FunctionComponent<
  IPatientDetailProps
> = ({ match, history }) => {
  //region useState and useEffect
  const { dispatch, institute, portfolioCountryCode } = useAppContext()
  const { patientId, physicianId } = match.params
  const [pagination, setPagination] = useState<IPagination>(defaultPagination)
  const [hasReconciliation, setHasReconciliation] = useState<boolean>(false)
  const [reconciliationStatus, setReconciliationStatus] =
    useState<ReconcileStatus>(ReconcileStatus.NoReconciliationRequired)
  const [detailsCtaButtonTitle, setDetailsCtaButtonTitle] = useState<string>('')
  const [orderNumber, setOrderNumber] = useState<string>('')

  const { useFeatureFlag } = useFeatureFlags()
  const patientCentricFeatureFlag = useFeatureFlag(
    FeatureFlagKeys.PatientCentricJourneyPerUser
  )

  useOnMount(() => {
    const matchPatientDetails =
      history.location.state &&
      history.location?.state?.from &&
      matchPath(history.location?.state?.from, {
        path: `/patients`
      })
    if (matchPatientDetails) {
      setSelectedTabIndex(PatientTab.OrderHistory)
    }

    if (history.location?.state?.orderNumber) {
      setOrderNumber(history.location?.state?.orderNumber)
    }
  })

  const {
    isLoading,
    patient,
    augmentedOrders,
    isProgramClosed,
    allowsDiscontinuation,
    getPatient
  } = useAugmentedOrders(
    patientId,
    physicianId,
    setPagination,
    setHasReconciliation,
    dispatch
  )
  const [vialsToReconcileCounter, setVialsToReconcileCounter] =
    useState<number>(0)

  const { isProgramLoading, program } = useProgram(patient?.program.programId)

  const {
    isConfirmationOpen,
    isConfirmationSubmitting,
    isConfirmationSubmitted,
    confirmationError,
    handleOpenConfirmation,
    handleCloseConfirmation,
    handleClearSubmission
  } = useDiscontinueConfirmationModal(
    patientId,
    physicianId,
    institute.data?.instituteId
  )

  const getUrlParams = useCallback(() => {
    const urlSearchParams = new URLSearchParams(history.location.search)
    const isFormShow: boolean = Boolean(
      urlSearchParams.get('transfer-patient-form')
    )
    setFormOpen(isFormShow)
  }, [history.location.search])

  // Update orders when received and augmented
  useEffect(() => {
    const allPastOrdersClone = augmentedOrders.slice()
    setPastOrders([...allPastOrdersClone.splice(0, pagination.take)])

    getUrlParams()
    const unListen = history.listen(() => {
      getUrlParams()
    })
    return () => {
      unListen()
    }
  }, [augmentedOrders, getUrlParams, history, pagination.take])

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    PatientTab.PatientDetails
  )
  const [isFormOpen, setFormOpen] = useState<boolean>(false)
  const [isReconcileWarningOpen, setIsReconcileWarningOpen] =
    useState<boolean>(false)

  // Past orders and vial reconciliation
  const [pastOrders, setPastOrders] = useState<IPastOrderSummary[]>([])
  // Selected row logic
  const [selectedVialRow, setSelectedVialRow] = useState<
    IPatientVial | undefined
  >()
  const [showCalendar, setShowCalendar] = useState<ICalendarPicker>({
    isOpen: false,
    minimumDate: utils('en').getToday(),
    selectedDay: utils('en').getToday(),
    maximumDate: utils('en').getToday()
  })
  const [isSavingVial, setIsSavingVial] = useState<boolean>(false)
  const { isLoadingPatientAccessForm, patientAccessFormUrl } =
    useReadOnlyPatientAccessForm(dispatch, patient)

  const [isTransferringAnotherInstitute, setIsTransferringAnotherInstitute] =
    useState(false)

  //endregion

  const memoizedStatusActions = useMemo(
    () => allStatusActions,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasReconciliation, patient]
  )

  const setDetailsCtaButtonTitleBasedOnStatus = useCallback(() => {
    if (!patient) return

    const stockOrderFlag = patient?.stockOrderFlag === 'Y'

    let ctaTitle = ''

    const defaultCtaTitle = 'patient_detail:button_request_resupply'

    switch (patient.patientOrderStatus) {
      case PatientOrderStatus.FailedDelivery:
      case PatientOrderStatus.Expired:
      case PatientOrderStatus.Pending:
      case PatientOrderStatus.Delivered:
      case PatientOrderStatus.Shipped:
      case PatientOrderStatus.ReadyForResupply:
      case PatientOrderStatus.InTransit:
      case PatientOrderStatus.OutForDelivery:
      case PatientOrderStatus.Available_for_Pickup:
      case PatientOrderStatus.FailedAttempt:
        ctaTitle = defaultCtaTitle
        if (hasReconciliation) {
          ctaTitle =
            memoizedStatusActions[ActionDropdownConstants.ReconcileVials].text
          setDetailsCtaButtonTitle(ctaTitle)
          return
        }
        if (
          patient.patientOrderStatus === PatientOrderStatus.ReadyForResupply &&
          !stockOrderFlag
        ) {
          setDetailsCtaButtonTitle(defaultCtaTitle)
          return
        }
        break

      case PatientOrderStatus.BeginOrder:
        ctaTitle =
          memoizedStatusActions[ActionDropdownConstants.BeginOrder].text
        break

      case PatientOrderStatus.IncompletePAF:
      case PatientOrderStatus.IncompleteCheckout:
        ctaTitle =
          memoizedStatusActions[ActionDropdownConstants.ContinueOrder].text
        break

      case PatientOrderStatus.ProgramClosed:
      case PatientOrderStatus.PatientDiscontinued:
      case PatientOrderStatus.UnderMedicalReview:
        ctaTitle = ''
        break

      default:
        ctaTitle = ''
        break
    }
    setDetailsCtaButtonTitle(ctaTitle)
  }, [patient, hasReconciliation, memoizedStatusActions])

  useEffect(() => {
    setDetailsCtaButtonTitleBasedOnStatus()
  }, [patient, setDetailsCtaButtonTitleBasedOnStatus])

  // Action Handlers Object
  const actionHandlers = {
    resupply: (patient: PatientDetailsDto) =>
      history.push(
        `/programs/access-programs/${patient.program.programId}/patient-access-form/${patient.currentPhysician.physicianId}`,
        {
          orderType: OPAOrderTypes.Resupply,
          patientNumber: patient.patientNumber,
          patientId: patient.patientId,
          patientStatus: patient.patientOrderStatus,
          stockOrder: patient.stockOrderFlag
        } as LocationState
      ),

    reconcileVials: (patient: PatientDetailsDto) =>
      history.push(
        `/programs/my-physicians/${patient.program.programId}/${patient.currentPhysician.physicianId}`,
        {
          from: window.location.pathname
        }
      ),

    trackOrder: (patient: PatientDetailsDto) => {
      if (patient.trackingLink) {
        window.location.href = patient.trackingLink
      }
    },

    transferPatient: (patient: PatientDetailsDto) =>
      history.push(
        `/programs/my-physicians/${patient.currentPhysician.physicianId}/${patient.patientId}?transfer-patient-form=true`
      ),

    beginOrder: (patient: PatientDetailsDto) =>
      history.push(
        `/programs/access-programs/${patient.program.programId}/patient-access-form/${patient.currentPhysician.physicianId}`,
        {
          orderType:
            patient.stockOrderFlag === 'Y'
              ? OPAOrderTypes.StockOrder
              : OPAOrderTypes.InitialOrder,
          patientNumber: patient.patientNumber,
          patientId: patient.patientId,
          patientStatus: patient.patientOrderStatus,
          stockOrder: patient.stockOrderFlag
        } as LocationState
      ),

    continueOrder: (patient: PatientDetailsDto) => {
      const orderType =
        patient.patientOrderStatus === PatientOrderStatus.BeginOrder
          ? patient.stockOrderFlag
            ? OPAOrderTypes.StockOrder
            : OPAOrderTypes.InitialOrder
          : patient.orderType

      history.push(
        `/programs/access-programs/${patient.program.programId}/patient-access-form/${patient.currentPhysician.physicianId}`,
        {
          orderType,
          patientNumber: patient.patientNumber,
          patientId: patient.patientId,
          patientStatus: patient.patientOrderStatus,
          stockOrder: patient.stockOrderFlag
        } as LocationState
      )
    },

    continueCheckout: (patient: PatientDetailsDto) => {
      orderNumber &&
        history.push(`/opa-checkout/${orderNumber}`, {
          from: window.location.pathname,
          patientId: patient.patientId,
          patientNumber: patient.patientNumber,
          orderType: patient.orderType,
          programId: patient.program.programId,
          stockOrder: patient.stockOrderFlag
        } as LocationState)
    }
  }

  const handlePrimaryCTA = () => {
    if (!patient) return

    if (!patientCentricFeatureFlag) {
      handleGoProgramDetail()
      return
    }

    const stockOrderFlag = patient?.stockOrderFlag === 'Y'
    const trackingLinkExists = patient?.trackingLink !== 'N/A'

    switch (patient.patientOrderStatus) {
      case PatientOrderStatus.FailedDelivery:
      case PatientOrderStatus.Expired:
      case PatientOrderStatus.Pending:
      case PatientOrderStatus.Delivered:
      case PatientOrderStatus.Shipped:
      case PatientOrderStatus.ReadyForResupply:
        actionHandlers['resupply']?.(patient)
        if (
          patient.patientOrderStatus === PatientOrderStatus.Pending &&
          hasReconciliation
        ) {
          actionHandlers['reconcileVials']?.(patient)
          return
        }
        break
      case PatientOrderStatus.InTransit:
      case PatientOrderStatus.OutForDelivery:
      case PatientOrderStatus.Available_for_Pickup:
      case PatientOrderStatus.FailedAttempt:
        if (!stockOrderFlag) {
          actionHandlers['resupply']?.(patient)
          return
        }
        if (trackingLinkExists) {
          actionHandlers['trackOrder']?.(patient)
          return
        }
        break
      case PatientOrderStatus.BeginOrder:
        actionHandlers['beginOrder']?.(patient)
        return
      case PatientOrderStatus.IncompletePAF:
        actionHandlers['continueOrder']?.(patient)
        return
      case PatientOrderStatus.IncompleteCheckout:
        actionHandlers['continueCheckout']?.(patient)
        return
      case PatientOrderStatus.PatientDiscontinued:
      default:
        handleGoProgramDetail()
        return
    }
  }

  const handleGoProgramDetail = () => {
    history.push(`/programs/access-programs/${patient?.program.programId}`)
  }

  const handleFormSubmission = (form: ITransferForm) => {
    const transferTo =
      form.destinationRadioId === '1' ? 'physician' : 'institute'
    history.push(
      `/programs/my-physicians/${physicianId}/${patientId}/transfer-patient/${transferTo}`
    )
  }

  const handleDestinationInputChange = (radioId: string) => {
    if (radioId === '1') {
      setIsTransferringAnotherInstitute(false)
      dispatch(clearAnnounceEvent())
      return
    }
    setIsTransferringAnotherInstitute(radioId === '2')
  }

  const handleHideTransferForm = () => {
    setFormOpen(false)
    setIsTransferringAnotherInstitute(false)
    dispatch(clearAnnounceEvent())
    history.push(`/programs/my-physicians/${physicianId}/${patientId}`)
  }

  const handleShowTransferForm = () => {
    dispatch(clearAnnounceEvent()) // Hide any vial reconciliation
    setFormOpen(true)
    history.push(
      `/programs/my-physicians/${physicianId}/${patientId}?transfer-patient-form=true`
    )
  }

  const handleReferenceOrderClicked = (selectedOrderId: string | number) => {
    history.push(`/order/${selectedOrderId}`, {
      from: window.location.pathname
    })
  }

  const handlePageSizeChange = (pageSize: number) => {
    const allPastOrdersClone = augmentedOrders.slice()
    if (allPastOrdersClone) {
      setPastOrders([...allPastOrdersClone.splice(0, pageSize)])
      setPagination({
        ...pagination,
        total: patient?.pastOrders.length ?? 0,
        take: pageSize,
        skip: 0
      })
    }
  }

  const handlePageClicked = (selectedPageIndex: number) => {
    const newSkip = (selectedPageIndex - 1) * pagination.take
    const allPastOrdersClone = augmentedOrders.slice()

    if (allPastOrdersClone) {
      const newPastOrders = [
        ...allPastOrdersClone.splice(newSkip, pagination.take)
      ]
      setPastOrders(newPastOrders)
      setPagination({
        ...pagination,
        skip: newSkip
      })
    }
  }

  const handleRowToggled = (orderNumber: number) => {
    const selectedOrder = pastOrders.find((o) => o.orderNumber === orderNumber)
    if (selectedOrder) {
      selectedOrder.isOrderToggledOpen = !selectedOrder.isOrderToggledOpen
      setPastOrders([...pastOrders])
    }
  }

  /**
   * Trigger when clicking on a date selector show calendar
   * @param vial
   */
  const handleVialDateInputSelected = (vial: IPatientVial) => {
    // Work out minimum date (date order was shipped)
    const shippedDateForOrder: string | undefined = patient?.pastOrders.find(
      (o) => o.orderNumber.toString() === vial.orderNumber.toString()
    )?.shippedDate
    // Maximum date is always today
    const today = new Date().toISOString()
    // Convert to a 'Day' for the minimum date for date picker or use current day
    const minimumDate = shippedDateForOrder || today
    // Select the vial row (so we can update the correct one later)
    setSelectedVialRow(vial)
    // Show calendar
    setShowCalendar({
      minimumDate: convertDateStringToDay(minimumDate),
      selectedDay: convertDateStringToDay(vial.dateAdministered || today), // Work out day selected (if their is one)
      maximumDate: convertDateStringToDay(today),
      isOpen: true
    })
  }

  /**
   * When day is selected in calendar update the nested vial row date
   * @param selectedDay
   */
  const handleDaySelected = (selectedDay: Day) => {
    // Find matching order and vial
    const matchingOrderIndex = pastOrders.findIndex(
      (ord) =>
        ord.orderNumber.toString() === selectedVialRow?.orderNumber.toString()
    )
    const matchingOrder = pastOrders[matchingOrderIndex]
    const matchingVialIndex = matchingOrder.vialsToReconcile.findIndex(
      (v) => v.vialNumber === selectedVialRow?.vialNumber
    )
    const vialToUpdate = matchingOrder.vialsToReconcile[matchingVialIndex]
    const isoDate = convertDayToISODateString(selectedDay)
    const updatedVial = {
      ...vialToUpdate,
      dateAdministered: isoDate,
      dateAdministeredFormatted: DateTime.fromISO(isoDate)
        .setLocale(getBrowserLocale())
        .toLocaleString(DateTime.DATE_SHORT)
    }
    // Update all the orders with the updated vial
    const updatedPastOrders = {
      ...pastOrders
    }
    // Use lodash to update the nested vial
    set(
      updatedPastOrders,
      `[${matchingOrderIndex}].vialsToReconcile[${matchingVialIndex}]`,
      updatedVial
    )
    // Deselect the row
    setSelectedVialRow(undefined)
    // Close calendar
    setShowCalendar({
      ...showCalendar,
      minimumDate: matchingOrder.dayOrderShipped,
      selectedDay: selectedDay,
      isOpen: false
    })
  }

  const handleSaveChangesToVial = (updatedVial: IPatientVial) => {
    const { dateAdministered, vialNumber, vialStatus, batchNumber } =
      updatedVial
    setIsSavingVial(true)
    setSelectedVialRow(updatedVial)
    patient?.currentPhysician &&
      reconcileVialForPatient(
        patient.currentPhysician.physicianId.toString(),
        patient.patientId.toString(),
        {
          dateAdministered,
          vialNumber,
          vialStatus,
          batchNumber
        }
      )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            // Find matching order and vial
            const matchingOrderIndex = pastOrders.findIndex(
              (ord) =>
                ord?.orderNumber?.toString() ===
                updatedVial?.orderNumber?.toString()
            )
            const matchingOrder = pastOrders[matchingOrderIndex]
            const matchingVialIndex = matchingOrder.vialsToReconcile.findIndex(
              (v) =>
                v?.vialNumber?.toString() ===
                updatedVial?.vialNumber?.toString()
            )
            // Use lodash to update the nested vial that was just reconciled
            const updatedOrders = set(
              pastOrders,
              `[${matchingOrderIndex}].vialsToReconcile[${matchingVialIndex}]`,
              {
                ...updatedVial,
                wasReconciled: true
              }
            )

            setPastOrders(updatedOrders)

            // Update vials to reconcile counter
            const totalVialsToReconcile =
              getNumberOfVialsToReconcile(updatedOrders)

            setVialsToReconcileCounter(totalVialsToReconcile)

            analyticsServiceSingleton.trackEvent(AnalyticsEvent.ReconcileVial, {
              patientId,
              programId: program?.program?.cliniportProgramId,
              programName: program?.programName
            })

            // Should we scroll to top and show vial reconciliation complete message?
            if (totalVialsToReconcile === 0) {
              window.scrollTo({
                behavior: 'smooth',
                top: 0
              })
              setReconciliationStatus(ReconcileStatus.SuccessfulReconciliation)
              dispatch(
                createAnnounceEvent(
                  AnnounceMode.Success,
                  `All products have been reconciled.`,
                  'Complete'
                )
              )
            }
          }
        })
        .catch((error) => {
          window.scrollTo(0, 0)
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error reconciling the vial. ${error}`
            )
          )
        })
        .finally(() => {
          setIsSavingVial(false)
          setSelectedVialRow(undefined)
        })
  }

  const handleGoBack = () => {
    if (
      history.location.state &&
      history.location.state.from === `/programs/my-physicians/${physicianId}`
    ) {
      history.goBack()
    } else {
      history.push(`/programs/my-physicians/${physicianId}`)
    }
  }

  const handleTabSelected = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex)
  }

  const handleDiscontinueFromProgram = (): void => {
    if (
      vialsToReconcileCounter &&
      vialsToReconcileCounter > 0 &&
      hasReconciliation
    ) {
      setIsReconcileWarningOpen(true)
      handleTabSelected(1)
      return
    } else {
      handleOpenConfirmation()
    }
  }

  const handleCloseReconcileWarningModal = (): void => {
    setIsReconcileWarningOpen(false)
  }

  const handleBackToPatientDetails = () => {
    handleTabSelected(0)
    handleClearSubmission()
    getPatient()
  }

  const handleDownload = (documentItem: ProgramDocumentDto) => {
    documentItem.generatedId &&
      downloadDocumentById(documentItem.generatedId.toString())
        .then((response) => {
          documentItem.documentTypeName &&
            documentItem.documentUrl &&
            download(
              response.data,
              getFilenameFromUrl(documentItem.documentUrl),
              documentItem.documentTypeName
            )
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.DocumentDownload,
            {
              ...documentItem,
              patientId
            }
          )
        })
        .catch((error: AxiosError) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error downloading your document. ${error}`
            )
          )
        })
  }

  const goToAdverseEventPage = () => {
    history.push('/products/adverse-event')
  }

  const handleAdverseEventDownload = () => {
    if (
      program &&
      program.programDocuments &&
      program.programDocuments.length > 0
    ) {
      const adverseEventDocument: ProgramDocumentDto | undefined =
        program.programDocuments.find(
          (document: ProgramDocumentDto) =>
            document.documentTypeCode?.toUpperCase() ===
            PatientDocumentType.ADVERSE_EVENT_FORM.toUpperCase()
        )
      if (adverseEventDocument) {
        handleDownload(adverseEventDocument)
      } else {
        goToAdverseEventPage()
      }
    } else {
      goToAdverseEventPage()
    }
  }

  const handleOpenPatientAccessForm = () => {
    patientAccessFormUrl &&
      history.push(
        {
          pathname: `/programs/access-programs/${patient?.program.programId}/patient-access-form/${physicianId}`
        },
        {
          endpoint: patientAccessFormUrl,
          from: window.location.pathname
        }
      )
  }

  const handlePatientAccessFormError = () => {
    history.push('/about/contact-us')
  }

  const mockRedirect = () => {
    history.push(
      `/programs/my-physicians/${physicianId}/${patientId}/discontinue-patient`
    )
  }

  //endregion
  //region useEffects

  // Update vials to reconcile counter when orders are augmented
  useEffect(() => {
    // Check if transfer form is open
    const urlSearchParams = new URLSearchParams(history.location.search)
    const isFormShow: boolean = Boolean(
      urlSearchParams.get('transfer-patient-form')
    )
    if (
      (hasReconciliation && pastOrders.length && !isFormShow) ||
      (isFormShow && isTransferringAnotherInstitute)
    ) {
      const totalVialsToReconcile = getNumberOfVialsToReconcile(pastOrders)
      totalVialsToReconcile &&
        displayReconcileAnnounce(
          totalVialsToReconcile,
          dispatch,
          isTransferringAnotherInstitute
        )
      setVialsToReconcileCounter(totalVialsToReconcile)
    }
  }, [
    augmentedOrders,
    dispatch,
    pastOrders,
    hasReconciliation,
    isTransferringAnotherInstitute,
    history.location.search
  ])

  useEffect(() => {
    if (
      vialsToReconcileCounter &&
      vialsToReconcileCounter > 0 &&
      hasReconciliation
    ) {
      setReconciliationStatus(ReconcileStatus.RequiresReconciliation)
      displayReconcileAnnounce(
        vialsToReconcileCounter,
        dispatch,
        isTransferringAnotherInstitute
      )
    }
  }, [
    dispatch,
    hasReconciliation,
    vialsToReconcileCounter,
    isTransferringAnotherInstitute
  ])

  type Deps = [boolean, PatientDetailsDto, IRestEndpointState<InstituteDto>]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      analyticsServiceSingleton.trackPageView(AnalyticsPageEvent.ViewPatient, {
        'Physician ID': dependencies[1].currentPhysician.physicianId,
        'Patient ID': dependencies[1].patientId,
        'Institute ID': dependencies[2].data?.instituteId
      })
    },
    [
      isLoading || isProgramLoading || isLoadingPatientAccessForm,
      patient,
      institute
    ],
    (dependencies: Deps) =>
      dependencies[0] === false && dependencies[1] && dependencies[2]
  )

  //endregion
  return (
    <>
      {!isConfirmationSubmitted ? (
        <>
          <DiscontinueWarningModal
            isOpen={isReconcileWarningOpen}
            handleClose={handleCloseReconcileWarningModal}
          />
          <DiscontinueConfirmationModal
            isOpen={isConfirmationOpen}
            isSubmitting={isConfirmationSubmitting}
            hasError={confirmationError?.isAxiosError}
            errorMessage={confirmationError?.message}
            handleSubmit={mockRedirect}
            handleClose={handleCloseConfirmation}
          />
          <PatientDetail
            isLoading={
              isLoading || isProgramLoading || isLoadingPatientAccessForm
            }
            detailsCtaButtonTitle={detailsCtaButtonTitle}
            isProgramClosed={isProgramClosed}
            allowsDiscontinuation={allowsDiscontinuation}
            patient={patient}
            pastOrders={pastOrders}
            selectedTabIndex={selectedTabIndex}
            selectedVialRow={selectedVialRow}
            columns={[
              OrderHistoryColumn.OrderReference,
              OrderHistoryColumn.DateSubmitted,
              OrderHistoryColumn.Status,
              OrderHistoryColumn.TotalCost,
              OrderHistoryColumn.VialReconciliationToggle
            ]}
            isFormOpen={isFormOpen}
            disableTransferButton={
              isTransferringAnotherInstitute && vialsToReconcileCounter > 0
            }
            reconciliationStatus={reconciliationStatus}
            vialsToReconcileCounter={vialsToReconcileCounter}
            vialsUnitType={program?.program?.unitType ?? null}
            userCountry={portfolioCountryCode}
            vialReconciliationType={$enum(
              VialReconciliationType
            ).asValueOrDefault(
              program?.program?.reconciliationType,
              VialReconciliationType.DispositionBatchAndDate
            )}
            handleHideTransferForm={handleHideTransferForm}
            handleFormSubmission={handleFormSubmission}
            handleDestinationInputChange={handleDestinationInputChange}
            onTabSelected={handleTabSelected}
            handlePrimaryCTA={handlePrimaryCTA}
            handleReferenceOrderClicked={handleReferenceOrderClicked}
            pagination={pagination}
            handlePageClicked={handlePageClicked}
            handlePageSizeChange={handlePageSizeChange}
            isSavingVial={isSavingVial}
            handleRowToggled={handleRowToggled}
            handleSaveChangesToVial={handleSaveChangesToVial}
            handleVialDateInputSelected={handleVialDateInputSelected}
            handleGoBack={handleGoBack}
            handleDiscontinueFromProgram={handleDiscontinueFromProgram}
            handleShowTransferForm={handleShowTransferForm}
            patientAccessFormUrl={patientAccessFormUrl}
            handleOpenPatientAccessForm={handleOpenPatientAccessForm}
            handlePatientAccessFormError={handlePatientAccessFormError}
          />
          <StyledCalendarModal
            isOpen={showCalendar.isOpen}
            isDismissible={true}
            maxWidth={'none'}
            removePadding={true}
            onClose={() => setShowCalendar({ ...showCalendar, isOpen: false })}
          >
            <StyledCalendarWrapper>
              <Calendar
                value={showCalendar.selectedDay}
                minimumDate={showCalendar.minimumDate}
                maximumDate={showCalendar.maximumDate}
                colorPrimary={ClinTheme.colors.primaryMid}
                colorPrimaryLight={ClinTheme.colors.primaryLight}
                onChange={handleDaySelected}
              />
              <StyledCalendarModal
                isOpen={showCalendar.isOpen}
                isDismissible={true}
                maxWidth={'none'}
                removePadding={true}
                onClose={() =>
                  setShowCalendar({ ...showCalendar, isOpen: false })
                }
              >
                <StyledCalendarWrapper>
                  <Calendar
                    value={showCalendar.selectedDay}
                    minimumDate={showCalendar.minimumDate}
                    maximumDate={showCalendar.maximumDate}
                    colorPrimary={ClinTheme.colors.primaryMid}
                    colorPrimaryLight={ClinTheme.colors.primaryLight}
                    onChange={handleDaySelected}
                  />
                </StyledCalendarWrapper>
              </StyledCalendarModal>
            </StyledCalendarWrapper>
          </StyledCalendarModal>
        </>
      ) : (
        <>
          {patient && patient.patientNumber && (
            <PatientDetailDiscontinued
              patientNumber={patient.patientNumber}
              handleAdverseEventDownload={handleAdverseEventDownload}
              handleBackToPatientDetails={handleBackToPatientDetails}
            />
          )}
        </>
      )}
    </>
  )
}
