import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { AxiosError, AxiosResponse } from 'axios'

import {
  StyledBasketDrawer,
  StyledDrawerBody,
  StyledDrawerHeader,
  Container,
  Header,
  Row,
  Column,
  StyledIcon,
  StyledClinSuccess,
  StyledClinButton,
  StyledSpinnerContainer
} from './BasketDrawer.styles'
import { ClinText } from '../../../../components/ClinText'
import { ClinGroup } from '../../../../components/ClinGroup'
import { AnnounceMode } from '../../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import { numberToCurrencyString } from '../../../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../../../utils/getBrowserLocale'
import CheckCircleIcon from '../../../../assets/media/icons/check-circle-green.svg'
import { ActionType, useBasket } from '../../../../context/basket'
import {
  getCurrentBasket,
  getPriceAndAvailabilityForSku
} from '../../../../services/ApiService'
import { BasketDto } from '../../../../types/swaggerTypes'
import { createAnnounceEvent } from '../../../../events/AnnounceEvent'
import { useAppContext } from '../../../../context/app'
import { ClinSpinner } from '../../../../components/ClinSpinner'
import { BasketViewState } from '../../../Basket/Basket'

interface IBasketDrawerProps {
  /** Handle closing the confirmation */
  handleClose?: () => void
}

export const BasketDrawer: React.FC<IBasketDrawerProps> = ({ handleClose }) => {
  const { dispatch, defaultShippingAddress, portfolioCountryCode } =
    useAppContext()
  const history = useHistory()
  const { t } = useTranslation()
  const [basket, setBasket] = React.useState<BasketDto | undefined>()
  const [basketViewState, setBasketViewState] = React.useState<BasketViewState>(
    BasketViewState.Loading
  )
  const [{ viewBasket }, basketDispatch] = useBasket()

  const handleSelectProduct = (basketItem: any) => {
    handleClose && handleClose()
    history.push(
      `/product/${basketItem.skuCatalogItem.parentCatalogDocumentId}/variant/${basketItem.sku}`
    )
  }

  const handleGoToBasket = () => {
    handleClose && handleClose()
    history.push('/basket')
  }

  const handleProceedToCheckout = () => {
    handleClose && handleClose()
    history.push('/checkout')
  }

  // Helper function to handle price and availability error
  const handlePriceAndAvailabilityError = (error: any) => {
    dispatch(
      createAnnounceEvent(
        AnnounceMode.Error,
        `An error occurred fetching your basket. ${error}`
      )
    )
  }

  // Extracted function for getting price and availability
  const getPriceAndAvailability = async () => {
    if (!basket || !defaultShippingAddress?.addressId) return

    try {
      const skus = basket.items.map((item) => item.sku)
      const pricingCalls = skus.map((sku) =>
        getPriceAndAvailabilityForSku(
          sku,
          defaultShippingAddress.addressId
        ).then((r) => r.data)
      )

      const results = await Promise.all(pricingCalls)

      basketDispatch({
        type: ActionType.SetCurrentBasket,
        basket: basket,
        priceAndAvailability: results,
        countryCode: portfolioCountryCode
      })
      setBasketViewState(BasketViewState.Basket)
    } catch (error) {
      handlePriceAndAvailabilityError(error)
    }
  }

  // Wait for current basket
  useEffect(() => {
    if (!basket) {
      getCurrentBasket()
        .then((response: AxiosResponse<BasketDto> | null) => {
          // Update badge value
          if (response?.data) {
            response.data.items ??= []
            response.data.orders ??= []
            setBasket(response.data)
            basketDispatch({
              type: ActionType.UpdateBasket,
              basket: { data: response.data, isLoading: false }
            })
          }
        })
        .catch((error: AxiosError) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the basket. ${error}`
            )
          )
        })
    }
  }, [basket, dispatch, basketDispatch])

  // With address and a basket get pricing and availability information
  useEffect(() => {
    if (viewBasket && defaultShippingAddress) {
      getPriceAndAvailability()
    }
  }, [basket, defaultShippingAddress])

  return (
    <StyledBasketDrawer>
      {basketViewState === BasketViewState.Loading ? (
        <StyledSpinnerContainer>
          <ClinText
            as={'div'}
            display={'block'}
            fontWeight={ClinTheme.fontWeights.medium}
            fontSize={ClinTheme.fontSizes[3]}
            color={ClinTheme.colors.black}
            lineHeight={ClinTheme.lineHeights.heading[1]}
          >
            {t('add_to_basket_confirmation:loading_basket')}
          </ClinText>
          <ClinSpinner size={ClinTheme.space[4]} />
        </StyledSpinnerContainer>
      ) : (
        <>
          <StyledDrawerHeader>
            <ClinGroup justifyContent={'space-between'}>
              <ClinText
                as={'div'}
                fontWeight={ClinTheme.fontWeights.medium}
                fontSize={ClinTheme.fontSizes[3]}
                color={ClinTheme.colors.black}
                lineHeight={ClinTheme.lineHeights.heading[1]}
              >
                {t('add_to_basket_confirmation:basket_title')}
              </ClinText>
            </ClinGroup>
          </StyledDrawerHeader>
          <StyledDrawerBody>
            <StyledClinSuccess
              marginBottom={ClinTheme.space[2]}
              mode={AnnounceMode.Success}
              title={' '}
            >
              <StyledIcon width={24} height={24} src={CheckCircleIcon} />
              <ClinText as={'div'} color={ClinTheme.colors.black}>
                {t('add_to_basket_confirmation:medicine_add_to_basket')}
              </ClinText>
            </StyledClinSuccess>
            {/* Order details */}
            <>
              <Container>
                <Header>
                  <Column>
                    <ClinText
                      as={'div'}
                      fontSize={ClinTheme.fontSizes[0]}
                      fontWeight={ClinTheme.fontWeights.medium}
                      lineHeight={ClinTheme.lineHeights.largeParagraph}
                    >
                      {t('add_to_basket_confirmation:quantity_label')}
                    </ClinText>
                  </Column>
                  <Column>
                    <ClinText
                      as={'div'}
                      fontSize={ClinTheme.fontSizes[0]}
                      fontWeight={ClinTheme.fontWeights.medium}
                      lineHeight={ClinTheme.lineHeights.largeParagraph}
                    >
                      {t('add_to_basket_confirmation:sku')}
                    </ClinText>
                  </Column>
                  <Column>
                    <ClinText
                      as={'div'}
                      fontSize={ClinTheme.fontSizes[0]}
                      fontWeight={ClinTheme.fontWeights.medium}
                      lineHeight={ClinTheme.lineHeights.largeParagraph}
                      textAlign={'right'}
                    >
                      {t('add_to_basket_confirmation:subtotal')}
                    </ClinText>
                  </Column>
                </Header>

                {viewBasket?.items?.map((basketItem) => (
                  <Row key={`basket-item-${basketItem.sku}`}>
                    <Column>
                      <ClinText
                        as={'div'}
                        fontSize={ClinTheme.fontSizes[1]}
                        lineHeight={ClinTheme.lineHeights.heading[0]}
                      >
                        {basketItem.quantity}
                      </ClinText>
                    </Column>
                    <Column>
                      <ClinButton
                        as="span"
                        variant="link"
                        color={ClinTheme.colors.tealishBlue}
                        style={{
                          height: 'auto',
                          minWidth: '24px',
                          whiteSpace: 'normal',
                          overflowWrap: 'break-word',
                          fontSize: ClinTheme.fontSizes[1],
                          lineHeight: ClinTheme.lineHeights.heading[0]
                        }}
                        onClick={() => handleSelectProduct(basketItem)}
                      >
                        {
                          basketItem.skuCatalogItem?.item
                            .genericDosageConcatenation
                        }
                      </ClinButton>
                    </Column>
                    <Column>
                      <ClinText
                        as={'div'}
                        fontSize={ClinTheme.fontSizes[1]}
                        fontWeight={ClinTheme.fontWeights.medium}
                        lineHeight={ClinTheme.lineHeights.heading[0]}
                        textAlign={'right'}
                      >
                        {numberToCurrencyString(
                          basketItem.amount,
                          getBrowserLocale(),
                          {
                            style: 'currency',
                            currency:
                              basketItem?.priceAndAvailability?.currencyCode ??
                              viewBasket.currencyCode,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )}
                      </ClinText>
                    </Column>
                  </Row>
                ))}
              </Container>
              <ClinSpacer hasBorder={true} height={ClinTheme.space[5]} />
              <ClinGroup justifyContent={'space-between'} alignItems={'center'}>
                <ClinText
                  as={'div'}
                  fontSize={ClinTheme.fontSizes[3]}
                  fontWeight={ClinTheme.fontWeights.medium}
                  lineHeight={ClinTheme.lineHeights.heading[1]}
                >
                  {t('add_to_basket_confirmation:amount_label')}
                </ClinText>
                <ClinText
                  as={'div'}
                  className={'amount'}
                  fontSize={ClinTheme.fontSizes[4]}
                  fontWeight={ClinTheme.fontWeights.medium}
                  lineHeight={ClinTheme.lineHeights.heading[1]}
                >
                  {!viewBasket?.total
                    ? '--'
                    : numberToCurrencyString(
                        viewBasket.total,
                        getBrowserLocale(),
                        {
                          style: 'currency',
                          currency: viewBasket.currencyCode,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      )}
                </ClinText>
              </ClinGroup>
              <ClinSpacer height={ClinTheme.space[5]} />
            </>
            <StyledClinButton
              display={'block'}
              variant={'primary'}
              onClick={handleProceedToCheckout}
            >
              {t('add_to_basket_confirmation:checkout')}
            </StyledClinButton>
            <ClinSpacer height={ClinTheme.space[3]} />
            <StyledClinButton
              display={'block'}
              variant={'secondary'}
              onClick={handleGoToBasket}
            >
              {t('add_to_basket_confirmation:view_basket_checkout')}
            </StyledClinButton>
          </StyledDrawerBody>
        </>
      )}
    </StyledBasketDrawer>
  )
}
