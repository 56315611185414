import React, { FunctionComponent, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'

import { getRandomColor } from './PatientDetail.model'
import {
  StyledAttributePatientInitialsAvatar,
  StyledPatientHeaderButtonContainer,
  StyledPatientHeaderItemAvatar,
  StyledPatientHeaderItems,
  StyledPatientHeaderItemsWrapper
} from './PatientDetail.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinText } from '../../../components/ClinText'
import { PatientStatus } from '../../../constants'
import { PatientDetailsDto } from '../../../types/swaggerTypes'
import { StyledHeader } from '../../Programs/ProgramDetail/ProgramDetail.styles'
import {
  OPAOrderTypes,
  PatientOrderStatus,
  patientOrderStatusTooltipTranslation
} from '../PatientDashboard/Patient.model'
import { trimInitials } from '../PatientDashboard/PatientDashboardContainer'

interface IPatientDetailHeaderProps {
  patient: PatientDetailsDto
  /** If the program is closed displaying an appropriated banner */
  isProgramClosed: boolean
  /** Details CTA title */
  primaryCTAButtonText: string
  /** Details CTA tooltip */
  primaryCTAButtonTooltip: string
  /** Called when the user click on "Primary CTA" */
  handlePrimaryCTA?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Called when user open  form */
  handleShowTransferForm?: () => void
  /** Discontinue from program */
  handleDiscontinueFromProgram?: () => void
  /** Allows discontinuation */
  allowsDiscontinuation: boolean
}

export const PatientDetailHeader: FunctionComponent<
  IPatientDetailHeaderProps
> = ({
  patient,
  isProgramClosed,
  primaryCTAButtonText,
  primaryCTAButtonTooltip,
  handleShowTransferForm,
  handlePrimaryCTA,
  handleDiscontinueFromProgram,
  allowsDiscontinuation
}) => {
  const { t } = useTranslation()
  const [avatarColor] = useState(getRandomColor())

  const ineligibleStatuses = [
    PatientOrderStatus.PatientDiscontinued,
    PatientOrderStatus.UnderMedicalReview,
    PatientOrderStatus.ProgramClosed,
    PatientOrderStatus.Submitted,
    PatientOrderStatus.HealthAuthorityReview,
    PatientOrderStatus.UnderReview
  ]

  const isPatientForResupply =
    (patient.patientOrderStatus === PatientOrderStatus.MedicalReviewDeclined ||
      patient.patientOrderStatus ===
        PatientOrderStatus.HealthAuthorityDeclined) &&
    patient.orderType !== OPAOrderTypes.Resupply

  const isPatientUneligible =
    !patient.patientOrderStatus ||
    ineligibleStatuses.includes(
      patient.patientOrderStatus as PatientOrderStatus
    ) ||
    (patient.patientOrderStatus === PatientOrderStatus.Processing &&
      patient.trackingLink === 'N/A') ||
    isPatientForResupply

  const isDiscontinueButtonDisabled =
    patient.patientOrderStatus === PatientOrderStatus.MedicalReviewDeclined ||
    patient.patientOrderStatus === PatientOrderStatus.HealthAuthorityReview ||
    patient.patientOrderStatus === PatientOrderStatus.HealthAuthorityDeclined ||
    patient.patientOrderStatus === PatientOrderStatus.Submitted ||
    patient.patientStatus.toUpperCase() ===
      PatientStatus.DISCONTINUED.toUpperCase() ||
    !allowsDiscontinuation

  const isTransferButtonDisabled =
    isProgramClosed ||
    patient.patientOrderStatus === PatientOrderStatus.MedicalReviewDeclined ||
    patient.patientOrderStatus === PatientOrderStatus.HealthAuthorityDeclined

  const isPrimaryCTAButtonDisabled =
    isPatientUneligible || isProgramClosed || primaryCTAButtonText === ''

  return (
    <StyledHeader>
      <Container>
        <Row>
          <Col md={9} lg={9}>
            <StyledPatientHeaderItemsWrapper>
              {patient.initials && (
                <StyledPatientHeaderItems>
                  <StyledPatientHeaderItemAvatar>
                    <StyledAttributePatientInitialsAvatar
                      className={'avatar'}
                      background={avatarColor}
                    >
                      {trimInitials(patient.initials)}
                    </StyledAttributePatientInitialsAvatar>

                    <ClinText
                      fontSize={ClinTheme.fontSizes[4]}
                      fontWeight={ClinTheme.fontWeights.medium}
                      lineHeight={ClinTheme.lineHeights.largeParagraph}
                    >
                      {patient.patientNumber}
                    </ClinText>
                  </StyledPatientHeaderItemAvatar>

                  <StyledPatientHeaderButtonContainer>
                    <Tooltip
                      theme="light"
                      position="bottom"
                      trigger="mouseenter"
                      arrow={true}
                      title={t('patient_detail:discontinue_title')}
                    >
                      <ClinButton
                        className="discontinue-button"
                        variant="purpleLightSecondary"
                        disabled={isDiscontinueButtonDisabled}
                        onClick={handleDiscontinueFromProgram}
                      >
                        {t('patient_detail:button_discontinue')}
                      </ClinButton>
                    </Tooltip>

                    <Tooltip
                      theme="light"
                      position="bottom"
                      trigger="mouseenter"
                      arrow={true}
                      title={t('patient_detail:title_transfer_column')}
                    >
                      <ClinButton
                        className="transfer-button"
                        variant="purpleLightSecondary"
                        disabled={isTransferButtonDisabled}
                        onClick={handleShowTransferForm}
                      >
                        {t('patient_detail:button_transfer')}
                      </ClinButton>
                    </Tooltip>

                    <Tooltip
                      theme="light"
                      position="bottom"
                      trigger="mouseenter"
                      arrow={true}
                      disabled={!isPatientForResupply}
                      title={t(
                        patientOrderStatusTooltipTranslation[
                          patient.patientOrderStatus as keyof typeof patientOrderStatusTooltipTranslation
                        ]
                      )}
                    >
                      <ClinButton
                        className="resupply-button"
                        variant="purpleLight"
                        disabled={isPrimaryCTAButtonDisabled}
                        onClick={handlePrimaryCTA}
                      >
                        {primaryCTAButtonText}
                      </ClinButton>
                    </Tooltip>
                  </StyledPatientHeaderButtonContainer>
                </StyledPatientHeaderItems>
              )}
            </StyledPatientHeaderItemsWrapper>
          </Col>
          <Col md={3} lg={3}></Col>
        </Row>
      </Container>
    </StyledHeader>
  )
}
