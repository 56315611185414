import { Row } from 'react-grid-system'
import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'

export const StyledTransferButton = styled(ClinButton)({}, () => ({
  flex: 1
}))

export const StyledRow = styled(Row)({
  '#sect-program-details': {
    width: '100%'
  }
})

export const StyledCustomGreenBadge = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 0 auto',
  width: ClinTheme.space[5],
  height: ClinTheme.space[5],
  marginLeft: ClinTheme.space[2],
  borderRadius: '100%',
  background: ClinTheme.colors.secondary,
  color: ClinTheme.colors.white
})
