import React from 'react'

import {
  StyledRowContainer,
  StyledTopRow,
  StyledColumnContainer,
  StyledDataRow,
  StyledColumnLabel,
  StyledColumnData,
  StyledButtonContainer
} from './MobileClinTableRow.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { OrderSummaryDto } from '../../types/swaggerTypes'
import { ClinTableToggleRowButton } from '../ClinTableToggleRowButton'

interface MobileClinTableRowProps {
  order: OrderSummaryDto
  columns: Array<{
    name: string
    viewName: string
    renderValue?: (order: OrderSummaryDto) => React.ReactNode
  }>
  handleRowClicked: (orderId: string) => void
  topColumns: [string, string] // Array of two column names to be rendered at the top
}

export const MobileClinTableRow: React.FC<MobileClinTableRowProps> = ({
  order,
  columns,
  handleRowClicked,
  topColumns
}) => {
  const topColumnData = columns.filter((column) =>
    topColumns.includes(column.name)
  )
  const mainColumns = columns.filter(
    (column) => !topColumns.includes(column.name)
  )

  return (
    <StyledRowContainer
      onClick={() => handleRowClicked(order.orderNumber || '')}
    >
      <StyledTopRow>
        {topColumnData.map((column) => (
          <StyledColumnContainer key={column.name}>
            {column.renderValue ? (
              column.renderValue(order)
            ) : (
              <ClinText
                variant={TypographyVariant.H2}
                lineHeight={ClinTheme.lineHeights.largeParagraph}
                fontWeight={700}
              >
                {(order as any)[column.name]}
              </ClinText>
            )}
          </StyledColumnContainer>
        ))}
      </StyledTopRow>
      {mainColumns.map(
        (column, index) =>
          column.name !== 'actions' && (
            <StyledDataRow key={column.name}>
              <StyledColumnLabel
                variant={TypographyVariant.SmallUI}
                fontSize={14}
                lineHeight={ClinTheme.lineHeights.small}
                fontWeight={500}
              >
                {column.viewName}
              </StyledColumnLabel>
              <StyledColumnData>
                {column.renderValue ? (
                  column.renderValue(order)
                ) : (
                  <ClinText
                    variant={TypographyVariant.SmallUI}
                    lineHeight={ClinTheme.lineHeights.small}
                  >
                    {(order as any)[column.name]}
                  </ClinText>
                )}
              </StyledColumnData>
            </StyledDataRow>
          )
      )}
      <StyledButtonContainer>
        <ClinTableToggleRowButton
          useCustomBackground
          onClick={() => {}}
          isToggled={false}
        />
      </StyledButtonContainer>
    </StyledRowContainer>
  )
}
