import React, { FunctionComponent, useEffect, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { RouteComponentProps } from 'react-router'

import {
  PatientDiscontinuation,
  IDiscontinuationReason
} from './PatientDiscontinuation'
import { ClinTheme } from '../../ClinTheme'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinSpinner } from '../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { LovName } from '../../constants'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { getPatientDetail, discontinuePatient } from '../../services/ApiService'
import { PatientDetailsDto } from '../../types/swaggerTypes'
import { useLov } from '../../utils/useLov'

interface IDiscontinuePatientRouteParams {
  patientId: string
  physicianId: string
}

interface IDiscontinuePatientProps
  extends RouteComponentProps<IDiscontinuePatientRouteParams> {}

export const PatientDiscontinuationContainer: FunctionComponent<
  IDiscontinuePatientProps
> = ({ match, history }) => {
  const { dispatch, institute } = useAppContext()
  const { patientId, physicianId } = match.params
  const [patient, setPatient] = useState<PatientDetailsDto>()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isReasonSubmitted, setIsReasonSubmitted] = useState<boolean>(false)
  useEffect(() => {
    patientId &&
      physicianId &&
      getPatientDetail(patientId, physicianId)
        .then((response) => {
          setPatient(response.data)
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred while retrieving this patients details . ${error}`
            )
          )
          window.scrollTo(0, 0)
          handleCancel()
        })
        .finally(() => setIsLoading(false))
  }, [dispatch, patientId, physicianId])

  const handleCancel = () => {
    history.push(`/programs/my-physicians/${physicianId}/${patientId}`)
  }

  const handleAdverseEvent = () => {
    history.push('/products/adverse-event')
  }

  const instituteId = institute.data?.instituteId

  const handleSubmit = (reason: IDiscontinuationReason): void => {
    setIsSubmitting(true)
    discontinuePatient(physicianId, patientId, {
      reasonCode: reason.reasonCode
    })
      .then((response) => {
        if (response.status === 200) {
          setIsSubmitting(false)
          setIsReasonSubmitted(true)
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.PatientDiscontinue,
            { physicianId, patientId, instituteId }
          )
        }
      })
      .catch((error) => {
        window.scrollTo({
          behavior: 'smooth',
          top: 0
        })
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `Patient discontinuation did not succeed. ${error}`
          )
        )
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const {
    isLovLoading: isPatientDiscontinuationReasonsLoading,
    lovValues: patientDiscontinuationReasons
  } = useLov(LovName.PATIENT_DISCONTINUATION_REASON)

  return isLoading || isSubmitting ? (
    <ClinPageContentFrame>
      <Row align="center">
        <Col>
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </ClinPageContentFrame>
  ) : (
    <ClinPageContentFrame className="my-physicians">
      <PatientDiscontinuation
        isReasonSubmitted={isReasonSubmitted}
        patient={patient}
        handleCancel={handleCancel}
        handleAdverseEvent={handleAdverseEvent}
        handleFormSubmittion={handleSubmit}
        lovList={patientDiscontinuationReasons || []}
        isLovLoading={isPatientDiscontinuationReasonsLoading}
      />
    </ClinPageContentFrame>
  )
}
