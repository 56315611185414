import { AxiosError, AxiosResponse } from 'axios'
import _ from 'lodash'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Row, Col } from 'react-grid-system'
import { RouteComponentProps } from 'react-router'

import { ProductVariant } from './ProductVariant'
import {
  getMatchingDefaultWarehouseCodes,
  ICatalogItemDto,
  sortCatalogItemsByAvailability,
  sortCatalogItemsByAvailabilityAus
} from './ProductVariant.models'
import {
  StyledHeader,
  StyledHeaderColumn,
  StyledHeaderContainer,
  StyledHeaderTitle
} from './ProductVariant.styles'
import { ClinTheme } from '../../../ClinTheme'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  NewFeatureElements,
  INewFeature
} from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipContainer } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import {
  ProductItemAvailabilityStatus,
  isAusMaUser,
  nonMaUsers
} from '../../../constants'
import { CountryAlphaCodes } from '../../../constants/countryAlpha2Codes'
import { useAppContext } from '../../../context/app'
import {
  createAnnounceEvent,
  clearAnnounceEvent
} from '../../../events/AnnounceEvent'
import { BookmarkToggleButtonContainer } from '../../../features/BookmarkToggleButton/BookmarkToggleButtonContainer'
import { ProductNotAvailable } from '../../../features/ProductNotAvailable'
import useChangeBackgroundColor from '../../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { useEffectOnlyOnce } from '../../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { AnalyticsEvent } from '../../../services/Analytics'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import {
  cancelGetPriceAndAvailabilityForSku,
  cancelGetProductById,
  getPriceAndAvailabilityForSku,
  getProductById,
  getWarehouseForCodes
} from '../../../services/ApiService'
import { getVariantInformationForCatalogItem } from '../../../services/PortfolioJourneys/PortfolioJourney.model'
import { IAugmentedCatalogItemDto } from '../../../services/PortfolioJourneys/PortfolioJourney.types'
import {
  BookmarkableItem,
  BookmarkableItemType
} from '../../../types/BookmarkableItem'
import { CatalogDto } from '../../../types/swaggerTypes'
import { useErrorMessage } from '../../../utils/useErrorMessage'
import { StyledSpinnerContainer } from '../../OpaCheckout/OpaCheckout.styles'

interface IProductVariantRouteParams {
  productId: string
}

interface IProductVariantProps
  extends RouteComponentProps<IProductVariantRouteParams> {
  /** List with user unseen features from context */
  newFeaturesList?: INewFeature[]
  renderBookmarkToggle: React.FC<BookmarkableItem>
}

export const ProductVariantContainer: React.FC<IProductVariantProps> = ({
  match,
  location,
  history,
  newFeaturesList,
  renderBookmarkToggle
}) => {
  //region Hooks & variables
  const { productId } = match.params
  const urlSearchParams = new URLSearchParams(location.search)
  const urlParamSource = urlSearchParams.get('src')
  const pricesLoadedRef = useRef(false)
  const [catalogItemsWithPrice, setCatalogItemsWithPrice] = useState<
    ICatalogItemDto[]
  >([])
  const {
    portfolioCountryCode,
    institute,
    userRole,
    dispatch,
    defaultShippingAddress
  } = useAppContext()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [product, setProduct] = React.useState<CatalogDto>()
  const [catalogItems, setCatalogItems] = React.useState<ICatalogItemDto[]>([])
  const [filteredCatalogItems, setFilteredCatalogItems] = React.useState<
    ICatalogItemDto[]
  >([])

  const [hasPermissionToView, setHasPermissionToView] = React.useState<
    boolean | undefined
  >(undefined)

  const handleProductError = useErrorMessage(
    'There was an error fetching the product.'
  )
  const handleWarehouseError = useErrorMessage(
    'There was an error fetching warehouse details.'
  )
  //endregion

  //region Handlers/methods
  const handleSelectVariant = (sku: string) => {
    history.push(`/product/${productId}/variant/${sku}`)
  }

  const handleBackToTop = () => {
    window.scrollTo(0, 0)
  }
  //endregion

  useChangeBackgroundColor(ClinTheme.colors.lightGrey)

  const handleGoToBasket = () => {
    history.push('/basket')
  }

  const handleProceedToCheckout = () => {
    history.push('/checkout')
  }
  const handleProceedToCheckoutOld = () => {
    history.push('/basket')
  }

  //region useEffects

  // Get product
  useEffect(() => {
    if (userRole && productId) {
      getProductById(productId)
        .then((response: AxiosResponse<CatalogDto>) => {
          const catalog = response.data
          setProduct(catalog)

          // Derived from is MAP and any user role then redirect to access program page
          if (catalog.derivedFrom === 'ManagedAccessProgram') {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.ProductReferralAccessProgram,
              { productId }
            )
            history.replace(`/programs/access-programs/${catalog.programId}`)
            return
          }

          // Derived from is Generic and user MaOnly then show error
          const isNonMaUser = nonMaUsers.includes(userRole)
          // Its an ma user track analytics
          if (!isNonMaUser) {
            setIsLoading(false)
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.ProductReferralRoleError,
              { productId }
            )
          }
          // Disallow ma users from viewing product
          setHasPermissionToView(
            isNonMaUser || isAusMaUser(portfolioCountryCode, userRole) //related to clos-1614 aus ma users can see products
          )

          // Only continue with second call if user roles allow
          if (isNonMaUser || isAusMaUser(portfolioCountryCode, userRole)) {
            // For each catalogueItem get the matching default warehouses and save unique ones
            const allWarehousesCodes: string[] = []
            let catalogItems: ICatalogItemDto[] = catalog.items.map(
              (catalogItem) => {
                // Get the warehouses that this item ships from
                const availableWarehouseCodes: string[] =
                  getMatchingDefaultWarehouseCodes(
                    catalogItem.stockLevels,
                    portfolioCountryCode,
                    institute.data?.warehouses
                  )
                // Make up a list of all codes so we can get more detail for them all
                allWarehousesCodes.push(...availableWarehouseCodes)
                // Augment object
                return {
                  ...catalogItem,
                  warehouseCodes: availableWarehouseCodes,
                  warehouses: undefined //to call in API
                }
              }
            )

            // POC: Filtering duplicate variants will be implemented on the FE at some point in time, sadly the requirements are not up to par as we are
            //filtering based on 5 arbitrary  out of ~120 properties considered for a variant on the product level.
            // if (isAusUser(portfolioCountryCode)) {
            //   catalogItems = filterVarietyCards(catalogItems)
            // }

            // Get the unique list of warehouse codes so we can batch call
            const uniqueWarehousesCodes = _.uniq(allWarehousesCodes)

            // Make the API calls and augment info onto catalog items
            getWarehouseForCodes(uniqueWarehousesCodes)
              .then((response) => {
                const warehouses = response.data
                // Add in warehouse DTO from BFF
                catalogItems = catalogItems
                  .map((cat) => {
                    // Get the default warehouses full DTO for this catalog item
                    const defaultWarehouses = warehouses.filter((w) =>
                      w.warehouseCode
                        ? cat.warehouseCodes.includes(w.warehouseCode)
                        : false
                    )
                    // Add portfolio information
                    const augItem: IAugmentedCatalogItemDto =
                      getVariantInformationForCatalogItem(
                        cat,
                        portfolioCountryCode,
                        defaultWarehouses[0],
                        defaultWarehouses
                      )
                    return {
                      ...cat,
                      ...augItem,
                      warehouses: defaultWarehouses
                    }
                  })
                  .sort((a, b) =>
                    portfolioCountryCode === CountryAlphaCodes.Australia
                      ? sortCatalogItemsByAvailabilityAus(
                          a,
                          b,
                          portfolioCountryCode
                        )
                      : sortCatalogItemsByAvailability(
                          a,
                          b,
                          portfolioCountryCode
                        )
                  )
                setCatalogItems(catalogItems)
              })
              .catch((error) => {
                handleWarehouseError(error)
              })
              .finally(() => setIsLoading(false))
          }
        })
        .catch((error: AxiosError) => {
          handleProductError(error)
          setIsLoading(false)
        })

      return () => {
        cancelGetProductById()
      }
    }
  }, [
    handleProductError,
    handleWarehouseError,
    productId,
    portfolioCountryCode,
    institute.data,
    userRole,
    history
  ])
  //Get price
  useEffect(() => {
    if (catalogItems.length) {
      setCatalogItemsWithPrice(
        catalogItems.map((item) => ({
          ...item,
          unitPrice: undefined,
          currencyCode: undefined
        }))
      )
    }
  }, [catalogItems])

  useEffect(() => {
    let isCanceledRequest = false

    if (
      catalogItemsWithPrice.length &&
      defaultShippingAddress &&
      !pricesLoadedRef.current
    ) {
      const itemsToPrice = catalogItemsWithPrice.filter(
        (item) =>
          item.availableStatus === ProductItemAvailabilityStatus.AVAILABLE
      )
      if (itemsToPrice.length > 0) {
        Promise.allSettled(
          itemsToPrice.map((item) =>
            getPriceAndAvailabilityForSku(
              item.sku,
              defaultShippingAddress.addressId
            )
          )
        ).then((results) => {
          if (isCanceledRequest) {
            dispatch(clearAnnounceEvent())
            return
          }

          const hasRejections = results.some(
            (result) => result.status === 'rejected'
          )
          const updatedItems = catalogItemsWithPrice.map((item) => {
            const index = itemsToPrice.findIndex((i) => i.sku === item.sku)
            if (index !== -1) {
              const result = results[index]
              if (result.status === 'fulfilled') {
                const priceData = result.value.data

                return {
                  ...item,
                  unitPrice: parseFloat(priceData.listPrice),
                  currencyCode: priceData.currencyCode,
                  priceAvailability: priceData
                }
              } else {
                return {
                  ...item,
                  unitPrice: undefined,
                  currencyCode: undefined,
                  priceAvailability: undefined
                }
              }
            }
            return item
          })
          setCatalogItemsWithPrice(updatedItems)
          pricesLoadedRef.current = true
          if (hasRejections) {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `We could not retrieve the price for some items, please try and refresh your page`
              )
            )
          }
        })
      } else {
        pricesLoadedRef.current = true
      }
    }
    return () => {
      isCanceledRequest = true
      cancelGetPriceAndAvailabilityForSku()
    }
  }, [catalogItemsWithPrice, defaultShippingAddress, dispatch])

  // Do analytics for referrals on mount - check if url params exist ?src=public
  useEffect(() => {
    if (urlParamSource === 'public') {
      analyticsServiceSingleton.trackEvent(
        AnalyticsEvent.ProductReferralFromPublicSite,
        { productId }
      )
    }
  }, [urlParamSource, productId])
  //endregion

  type Deps = boolean[]
  useEffectOnlyOnce(
    () => {
      analyticsServiceSingleton.trackPageView(location.pathname, {})
    },
    [isLoading],
    (dependencies: Deps) => dependencies[0] === false
  )

  const filterHiddenVariants = useCallback(() => {
    return catalogItemsWithPrice.filter(
      (item) => item.availableStatus !== ProductItemAvailabilityStatus.HIDDEN
    )
  }, [catalogItemsWithPrice])

  useEffect(() => {
    setFilteredCatalogItems(filterHiddenVariants()) // related to clos-3103 Hide SAGE SKU's for Global Users
  }, [catalogItemsWithPrice, filterHiddenVariants])

  const listOfNewFeatures = undefined //expired features

  return hasPermissionToView !== false ? (
    product ? (
      <>
        <StyledHeader>
          <ClinPageContentFrame marginBottom="0">
            <StyledHeaderContainer>
              <StyledHeaderColumn>
                <StyledHeaderTitle
                  as="h1"
                  variant={TypographyVariant.H2}
                  fontWeight={ClinTheme.fontWeights.bold}
                  color={ClinTheme.colors.black}
                >
                  {product.catalogItemName}
                </StyledHeaderTitle>
                {newFeaturesList?.find(
                  (x) => x.id === NewFeatureElements.BookmarkFeature
                ) && (
                  <ClinNewFeatureTooltipContainer
                    feature={
                      newFeaturesList.find(
                        (x) => x.id === NewFeatureElements.BookmarkFeature
                      ) as INewFeature
                    }
                  />
                )}
              </StyledHeaderColumn>
              <StyledHeaderColumn>
                <BookmarkToggleButtonContainer
                  itemType={BookmarkableItemType.Generic}
                  itemId={productId}
                  iconFirst={true}
                />
              </StyledHeaderColumn>
            </StyledHeaderContainer>
          </ClinPageContentFrame>
        </StyledHeader>
        <ProductVariant
          isLoading={isLoading}
          countryCode={portfolioCountryCode}
          product={product}
          catalogItems={filteredCatalogItems}
          userCountry={portfolioCountryCode}
          userRole={userRole}
          newFeaturesList={listOfNewFeatures}
          handleSelectVariant={handleSelectVariant}
          handleBackToTop={handleBackToTop}
          handleGoToBasket={handleGoToBasket}
          handleProceedToCheckout={handleProceedToCheckout}
          handleProceedToCheckoutOld={handleProceedToCheckoutOld}
        />
      </>
    ) : (
      <ClinPageContentFrame>
        <Row align="center">
          <Col>
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      </ClinPageContentFrame>
    )
  ) : (
    <ProductNotAvailable
      isLoading={isLoading}
      productName={product?.catalogItemName}
    />
  )
}
