import styled, { keyframes, css } from 'styled-components'

import { ClinTheme } from '../../ClinTheme'

export interface ISkeletonLoaderGroupStyles {}

export interface ISkeletonLoaderItemStyles {
  /** Background color */
  backgroundColor?: string
  /** Width - defaults to 100% */
  width?: string | number
  /** Height */
  height?: number
  /** Margin bottom */
  marginBottom?: number
}

const pulse = keyframes`
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`
const pulseAnimation = () => {
  return css`
    ${pulse} ease-in-out 1.2s infinite alternate
  `
}

export const StyledSkeletonLoaderGroup = styled.div<ISkeletonLoaderGroupStyles>`
  animation: ${pulseAnimation};
  margin-bottom: ${ClinTheme.space[5]}px;
`
export const StyledSkeletonItem = styled.div<ISkeletonLoaderItemStyles>(
  {
    width: 'auto',
    height: ClinTheme.space[3],
    backgroundColor: ClinTheme.colors.midGrey,
    marginBottom: ClinTheme.space[2]
  },
  ({ width, height, backgroundColor, marginBottom }) => ({
    width,
    height,
    backgroundColor,
    marginBottom
  })
)
