import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { ClinTheme } from '../../ClinTheme'
import { ClinCard } from '../../components/ClinCard'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { OrderAddressDto } from '../../types/swaggerTypes'

interface IMaDeliveryDetailsProps {
  /** Deliver adress */
  deliverTo: OrderAddressDto
  /** The name of the recipient */
  deliverToContact: string
  /** Purchase Order number */
  poNumber: string
  /** Delivery method */
  deliveryMethod: string
}

const getAddressField = (address: string, prefix?: string) =>
  address ? (
    <>
      {prefix ? `${prefix} ` : ''}
      {address}
      <br />
    </>
  ) : (
    <></>
  )

export const MaDeliveryDetails: FunctionComponent<IMaDeliveryDetailsProps> = ({
  deliverTo,
  deliverToContact,
  poNumber,
  deliveryMethod
}) => {
  const { t } = useTranslation()

  return (
    <ClinCard>
      <ClinText as="h3" variant={TypographyVariant.H3} marginTop={'0'}>
        {t('ma_order_detail:delivery_and_purchase_order.title')}
      </ClinText>
      <Row>
        <Col>
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.primary}
          >
            {t('ma_order_detail:delivery_and_purchase_order.delivery_method')}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[1]} />
          <ClinText
            fontSize={ClinTheme.fontSizes[2]}
            fontWeight={ClinTheme.fontWeights.light}
            color={ClinTheme.colors.black}
          >
            {deliveryMethod}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[4]} />
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.primary}
          >
            {t('ma_order_detail:delivery_and_purchase_order.delivery_address')}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[1]} />
          <ClinText
            fontSize={ClinTheme.fontSizes[2]}
            fontWeight={ClinTheme.fontWeights.light}
            color={ClinTheme.colors.black}
            wordBreak={'break-word'}
          >
            {getAddressField(deliverToContact, 'c/o')}
            {getAddressField(deliverTo.address1)}
            {getAddressField(deliverTo.address2)}
            {getAddressField(deliverTo.address3)}
            {getAddressField(deliverTo.address4)}
            {getAddressField(deliverTo.city)}
            {getAddressField(deliverTo.state)}
            {getAddressField(deliverTo.country)}
            {getAddressField(deliverTo.postalCode)}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[4]} />
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.primary}
          >
            {t(
              'ma_order_detail:delivery_and_purchase_order.purchase_order_number'
            )}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[1]} />
          <ClinText
            fontSize={ClinTheme.fontSizes[2]}
            fontWeight={ClinTheme.fontWeights.light}
            color={ClinTheme.colors.black}
          >
            {poNumber}
          </ClinText>
        </Col>
      </Row>
    </ClinCard>
  )
}
