import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledClinTable,
  FixedWidthCell,
  StyledClinTableOrderToggleWrapper,
  ActionCell,
  StyledTooltipSearchWrapper,
  StyledClinPatientTableHeaderCell,
  StyledNotFound
} from './OrdersTable.styles'
import { ClinTheme } from '../../ClinTheme'
import {
  NewFeatureElements,
  PageNames
} from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipContainer } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import { ClinSpinner } from '../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import {
  ClinTableBodyCell,
  ClinTableRow
} from '../../components/ClinTable/ClinTable'
import { ClinTableOrderToggle } from '../../components/ClinTableOrderToggle'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { MobileClinTableRow } from '../../components/MobileClinTableRow'
import { useNewFeaturesList } from '../../hooks/useNewFeaturesList/useNewFeaturesList'
import { ActionDropdownProvider } from '../../pages/OrderPages/Orders/ActionsColumn/ActionsColumn'
import ColumnTooltip from '../../pages/OrderPages/Orders/ColumnTooltip/ColumnTooltip'
import { OrderSummaryDto } from '../../types/swaggerTypes'
import { useMediaQuery } from '../../utils/useMediaQuery'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinIcon } from '../../components/ClinIcon'

interface OrdersTableProps {
  orders: OrderSummaryDto[]
  isLoading: boolean
  columns: Array<{
    name: string
    viewName: string
    toolTipKey?: string
    width: string
    minWidth?: string
    maxWidth?: string
    isSortable?: boolean
    renderValue?: (order: OrderSummaryDto) => React.ReactNode
  }>
  isEmptySearch: boolean
  selectedSortColumn?: any
  selectedSortDirection?: SortDirectionType
  supportContact: any
  handleToggleSort: (columnName: string | undefined) => void
  handleRowClicked: (orderId: string) => void
  handleTabSelected: (tabIndex: number) => void
  handleToggleOrderRow: (orderNumber: string) => void
}

export const OrdersTable: React.FC<OrdersTableProps> = ({
  orders,
  isLoading,
  columns,
  selectedSortColumn,
  selectedSortDirection,
  handleToggleSort,
  handleRowClicked
}) => {
  const { t } = useTranslation()
  const isLargeScreen = useMediaQuery(ClinTheme.breakpoints[2])
  const { newFeaturesList, displayNewFeature, displayNewFeatureGlowEffect } =
    useNewFeaturesList(PageNames.Orders)

  const isShowFeatureOrderFirst =
    displayNewFeature && displayNewFeature(NewFeatureElements.OrdersFirst)

  if (isLoading) {
    return (
      <StyledSpinnerContainer>
        <ClinSpinner size={ClinTheme.space[7]} />
      </StyledSpinnerContainer>
    )
  }

  if (orders.length === 0) {
    return (
      <StyledNotFound>
        <ClinIcon
          iconName={ClinIconPathName.OrderBox}
          iconSize={'172px'}
          iconFill={ClinTheme.colors.primaryLight}
        />
        <ClinText
          fontSize={ClinTheme.fontSizes[4]}
          fontWeight={ClinTheme.fontWeights.bold}
          marginTop={ClinTheme.space[3]}
          color={ClinTheme.colors.primary}
          textAlign="center"
        >
          No orders found.
        </ClinText>
        <ClinText
          fontSize={ClinTheme.fontSizes[2]}
          marginTop={ClinTheme.space[3]}
          textAlign="center"
        >
          Please adjust your search filters and try again.
        </ClinText>
      </StyledNotFound>
    )
  }

  return (
    <ActionDropdownProvider>
      <StyledTooltipSearchWrapper>
        {newFeaturesList &&
          displayNewFeature(NewFeatureElements.OrdersFirst) && (
            <ClinNewFeatureTooltipContainer
              feature={newFeaturesList[NewFeatureElements.OrdersFirst]}
              openTooltipInitially={
                !newFeaturesList[NewFeatureElements.OrdersFirst]?.isCancelled
              }
              disableDismiss={true}
              tooltipWidth="242px"
              total={3}
              current={1}
            />
          )}
      </StyledTooltipSearchWrapper>
      <StyledClinTable
        borderColapseSeparate={true}
        borderSpacing="0 8px"
        width="1282px"
        tableFor="orders"
        tableHeader={() =>
          columns.map((column, index) => (
            <StyledClinPatientTableHeaderCell
              key={index}
              background={ClinTheme.colors.lightGrey}
              style={{ cursor: 'default' }}
            >
              <FixedWidthCell
                key={index}
                width={column.width}
                minWidth={column.minWidth}
                maxWidth={column.maxWidth}
              >
                {column.isSortable ? (
                  <StyledClinTableOrderToggleWrapper>
                    <ClinTableOrderToggle
                      sortDirection={
                        selectedSortColumn.name === column.name
                          ? selectedSortDirection
                          : SortDirectionType.None
                      }
                      onClick={() => handleToggleSort(column.name)}
                      isUppercase={false}
                    >
                      <ColumnTooltip
                        tooltipText={
                          column.toolTipKey ? t(column.toolTipKey) : ''
                        }
                      >
                        {column.viewName}
                      </ColumnTooltip>
                    </ClinTableOrderToggle>
                  </StyledClinTableOrderToggleWrapper>
                ) : (
                  <StyledClinTableOrderToggleWrapper>
                    <ClinTableOrderToggle hideSortIcon={true}>
                      <ColumnTooltip
                        tooltipText={
                          column.toolTipKey ? t(column.toolTipKey) : ''
                        }
                      >
                        {column.viewName}
                      </ColumnTooltip>
                    </ClinTableOrderToggle>
                  </StyledClinTableOrderToggleWrapper>
                )}
              </FixedWidthCell>
            </StyledClinPatientTableHeaderCell>
          ))
        }
        className="TableOverflowVisible"
      >
        {orders.map((order: OrderSummaryDto, rowIndex) => {
          const isHighlighted =
            isShowFeatureOrderFirst &&
            order.orderNumber === orders[0]?.orderNumber

          return (
            <React.Fragment key={rowIndex}>
              {isLargeScreen ? (
                <ClinTableRow
                  key={rowIndex}
                  background={ClinTheme.colors.white}
                  onRowClick={() => handleRowClicked(order.orderNumber || '')}
                  style={{
                    height: '62px',
                    position: isHighlighted ? 'relative' : 'unset',
                    zIndex: isHighlighted ? 1 : 0,
                    boxShadow:
                      newFeaturesList &&
                      displayNewFeatureGlowEffect &&
                      displayNewFeatureGlowEffect?.(
                        NewFeatureElements.OrdersFirst
                      )
                        ? '0 0 14px #fff'
                        : ''
                  }}
                >
                  {columns.map((column, index) => (
                    <ClinTableBodyCell
                      key={index}
                      style={{ padding: '11px 20px 11px 16px' }}
                    >
                      {column.name === 'actions' ? (
                        <ActionCell key={index} width={column.width}>
                          {column.renderValue ? (
                            column.renderValue(order)
                          ) : (
                            <ClinText
                              variant={TypographyVariant.SmallUI}
                              lineHeight={ClinTheme.lineHeights.largeParagraph}
                            >
                              {(order as any)[column.name]}
                            </ClinText>
                          )}
                        </ActionCell>
                      ) : (
                        <FixedWidthCell
                          key={index}
                          width={column.width}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                        >
                          {column.renderValue ? (
                            column.renderValue(order)
                          ) : (
                            <ClinText
                              variant={TypographyVariant.SmallUI}
                              lineHeight={ClinTheme.lineHeights.largeParagraph}
                            >
                              {(order as any)[column.name]}
                            </ClinText>
                          )}
                        </FixedWidthCell>
                      )}
                    </ClinTableBodyCell>
                  ))}
                </ClinTableRow>
              ) : (
                <MobileClinTableRow
                  order={order}
                  columns={columns}
                  handleRowClicked={handleRowClicked}
                  topColumns={['orderNumber', 'status']}
                />
              )}
            </React.Fragment>
          )
        })}
      </StyledClinTable>
    </ActionDropdownProvider>
  )
}
