import * as React from 'react'

import {
  StyledTopicLinkCard,
  StyledTopicLinkCardInner
} from './TopicLinkCard.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinCard } from '../../components/ClinCard'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinText } from '../../components/ClinText'
import { IDefaultStyleProps } from '../../types'

interface ITopicLinkCardProps extends IDefaultStyleProps {
  /** Icon name   */
  iconName?: ClinIconPathName
  /** Title text  */
  title?: string
  /** A function which is called when a user requests to go back to another page */
  handlePageChangeRequest?: () => void
}

export const TopicLinkCard: React.FC<ITopicLinkCardProps> = ({
  iconName,
  title,
  handlePageChangeRequest
}) => (
  <StyledTopicLinkCard>
    <ClinCard
      removePadding={true}
      onClick={() => handlePageChangeRequest && handlePageChangeRequest()}
    >
      <StyledTopicLinkCardInner>
        {iconName && (
          <ClinIcon
            className="topic-icon"
            iconSize={ClinTheme.fontSizes[6]}
            iconName={iconName}
            iconFill={ClinTheme.colors.primary}
          />
        )}
        {title && (
          <ClinText
            className="topic-title"
            fontWeight={ClinTheme.fontWeights.normal}
            marginTop={0}
            marginBottom={0}
            color={ClinTheme.colors.primary}
            fontSize={ClinTheme.fontSizes[3]}
          >
            {title}
          </ClinText>
        )}
      </StyledTopicLinkCardInner>
    </ClinCard>
  </StyledTopicLinkCard>
)
