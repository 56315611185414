import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'

interface INewFeatureButtonsWrapper {
  justifyContent: string
}

export const NewFeatureTooltip = styled.div({
  padding: ClinTheme.space[3],
  maxWidth: '240px',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  color: ClinTheme.colors.white,
  fontWeight: ClinTheme.fontWeights.medium,
  fontSize: ClinTheme.fontSizes[1]
})

export const NewFeatureTooltipHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: ClinTheme.space[1],
  marginBottom: ClinTheme.space[3],
  lineHeight: '16px'
})

export const NewFeatureTooltipContent = styled.div({
  lineHeight: ClinTheme.lineHeights.heading[2],
  fontWeight: ClinTheme.fontWeights.normal
})

export const NewFeatureTooltipXButton = styled.div({
  position: 'relative',
  cursor: 'pointer'
})

export const NewFeatureTooltipXButton1 = styled.div({
  position: 'absolute',
  right: '10%',
  top: '33%',
  backgroundColor: ClinTheme.colors.white,
  borderRadius: ClinTheme.borderWidths[0],
  transform: 'rotate(-45deg)',
  width: ClinTheme.space[3],
  height: ClinTheme.borderWidths[1]
})

export const NewFeatureTooltipXButton2 = styled.div({
  position: 'absolute',
  right: '10%',
  top: '33%',
  backgroundColor: ClinTheme.colors.white,
  borderRadius: ClinTheme.borderWidths[0],
  transform: 'rotate(45deg)',
  width: ClinTheme.space[3],
  height: ClinTheme.borderWidths[1]
})

export const NewFeatureTooltipButtonsWrapper =
  styled.div<INewFeatureButtonsWrapper>(
    {
      display: 'flex',
      alignItems: 'center',
      marginTop: `${ClinTheme.space[2]}px`
    },
    ({ justifyContent }) => ({
      justifyContent: justifyContent
    })
  )
