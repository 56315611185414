import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { mediaQueryDown } from '../../utils/mediaQuery'

const xLargeUpBreakpoint = 1534 //Guru :(
const phoneBreakpoint = 525

export const StyledChatSupportIcon = styled.div({
  position: 'fixed',
  bottom: '28px',
  right: '28px',
  display: 'flex',
  width: '64px',
  height: '64px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  background: ClinTheme.colors.primaryLight,
  color: ClinTheme.colors.white,
  cursor: 'pointer',
  filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.16))',
  zIndex: '6',
  [`@media print`]: {
    display: 'none'
  },
  [mediaQueryDown(xLargeUpBreakpoint)]: {
    bottom: '88px'
  }
})

export const StyledChatSupportIconText = styled.div({
  position: 'fixed',
  bottom: '102px',
  right: '84px',
  background: '#EEEDFF',
  color: ClinTheme.colors.black,
  padding: '5px 10px',
  transition: 'all 0.3s ease-in-out',

  [mediaQueryDown(xLargeUpBreakpoint)]: {
    display: 'none'
  }
})

export const StyledPopUpButton = styled.button({
  backgroundColor: 'transparent',
  width: `${ClinTheme.space[8]}px`,
  height: `${ClinTheme.space[8]}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  border: 'none'
})

export const StyledChatSupportPopup = styled.div({
  backgroundColor: ClinTheme.colors.lightGrey,
  borderRadius: ClinTheme.radii[2],
  overflow: 'hidden',
  position: 'absolute',
  right: '62px',
  bottom: '62px',
  boxShadow: ClinTheme.shadows[2],
  maxWidth: '440px',
  width: '100vw',
  zIndex: '6',
  [mediaQueryDown(phoneBreakpoint)]: {
    position: 'fixed',
    right: '-28px',
    bottom: '62px'
  }
})

export const StyledPopUpHeader = styled.div({
  marginBottom: '-42px',
  backgroundColor: ClinTheme.colors.primaryMid,
  padding: '32px 28px 0px'
})

export const StyledChatSupportPopupCard = styled.div({
  margin: '0px 14px 16px',
  backgroundColor: ClinTheme.colors.white,
  boxShadow: ClinTheme.shadows[2],
  borderRadius: ClinTheme.radii[2],
  position: 'relative',
  padding: '27px 76px 27px 31px'
})

export const StyledChevronWrapper = styled.div({
  top: '31px',
  right: '23px',

  position: 'absolute'
})
