import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  EnrolPhysicianOnToProgramStep,
  getViewForStepOne,
  getViewForStepTwo,
  isContinueDisabled
} from './EnrolPhysicianOnToProgram.model'
import {
  StyledSpinnerContainer,
  StyledActions,
  IEnrolPhysicianOnToProgramStyleProps,
  StyledLoader
} from './EnrolPhysicianOnToProgram.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinStepper } from '../../components/ClinStepper'
import { StepState } from '../../components/ClinStepper/ClinStepper'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { Prompt } from '../../features/Prompt'
import {
  NewPhysicianEnrollDto,
  PhysiciansSummaryDto,
  ProgramCatalogDto
} from '../../types/swaggerTypes'

interface IEnrolPhysicianOnToProgramProps
  extends IEnrolPhysicianOnToProgramStyleProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** Selected program */
  program?: ProgramCatalogDto
  /** The current step */
  currentStep: EnrolPhysicianOnToProgramStep
  /** The selected step state */
  selectedSteppers: StepState[]
  /** Selected physicians */
  selectedPhysicians?: PhysiciansSummaryDto[]
  /** Saved physicians */
  savedPhysicians?: NewPhysicianEnrollDto[]
  /** Index of the selected tab */
  selectedTabIndex: number
  /** Whether a user has confirmed whether they would like to associate with a physician or not **/
  associateConfirmation?: boolean
  /** Decides whether the form is edited or not */
  isFormEdited: boolean
  isSubmitted?: boolean
  /** Called when the user selects a tab */
  handleTabSelected: (selectedTabIndex: number) => void
  /** Call back to broadcast a selected physician */
  handleOnSelect: (suggestion: PhysiciansSummaryDto) => void
  /** Call back request to add a new physician */
  handleRequestToAddPhysician: () => void
  /** Call back request to remove a selected physician */
  handlePhysicianRemoval: (physicianId: number) => void
  /** Save physician details */
  handleSavedPhysicianDetails: (physician: NewPhysicianEnrollDto) => void
  /** Call back to remove a selected saved physician */
  handleSavedPhysicianRemoval: (email: string) => void
  /** Handle whether the form is edited or not */
  handleIsFormEdited: (isEdited: boolean) => void
  /** Call back to go to the next step */
  handleGoToNextStep: () => void
  /** Call back to go to the previous step */
  handleGoToPreviousStep: () => void
  /** Call back handle to confirm request */
  handleConfirmationSelect: (confirmed: boolean) => void
  /** Call back handle a request to cancel and go back to the associated program */
  handleSubmit: () => void
  /** Call back handle a request to cancel */
  handleRequestToViewProgram: () => void
}

export const EnrolPhysicianOnToProgram: FunctionComponent<
  IEnrolPhysicianOnToProgramProps
> = ({
  isLoading,
  program,
  currentStep,
  selectedSteppers,
  selectedPhysicians,
  savedPhysicians,
  selectedTabIndex,
  associateConfirmation,
  isSubmitting,
  isFormEdited,
  isSubmitted,
  handleTabSelected,
  handleOnSelect,
  handleRequestToAddPhysician,
  handlePhysicianRemoval,
  handleSavedPhysicianDetails,
  handleSavedPhysicianRemoval,
  handleIsFormEdited,
  handleGoToNextStep,
  handleGoToPreviousStep,
  handleConfirmationSelect,
  handleSubmit,
  handleRequestToViewProgram
}) => {
  const { t } = useTranslation()

  return (
    <ClinPageContentFrame className="enrol-user-on-to-program">
      {isLoading ? (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      ) : (
        <>
          {((!isSubmitted && isFormEdited) ||
            (savedPhysicians && savedPhysicians?.length > 0) ||
            (selectedPhysicians && selectedPhysicians?.length > 0)) && (
            <Prompt />
          )}
          <Row>
            <Col sm={12} md={10} lg={8}>
              <ClinSpacer height={ClinTheme.space[5]} />
              <ClinText
                as="h1"
                className="physician-full-name"
                variant={TypographyVariant.H2}
                fontWeight={ClinTheme.fontWeights.bold}
              >
                {t('enrol_physician_on_to_program:enrol_physician', {
                  programName: program?.program?.programName
                })}
              </ClinText>
              <ClinSpacer height={ClinTheme.space[5]} />
              {selectedSteppers && (
                <ClinStepper
                  steps={[
                    {
                      state: selectedSteppers[0],
                      displayText: t(
                        'enrol_physician_on_to_program:stepper.step_1'
                      )
                    },
                    {
                      state: selectedSteppers[1],
                      displayText: t(
                        'enrol_physician_on_to_program:stepper.step_2'
                      )
                    }
                  ]}
                  width={400}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={10} lg={8}>
              <ClinSpacer height={ClinTheme.space[8]} hasBorder />
            </Col>
          </Row>
          {currentStep === EnrolPhysicianOnToProgramStep.STEP1 &&
            getViewForStepOne(
              selectedTabIndex,
              handleTabSelected,
              handleOnSelect,
              handleRequestToAddPhysician,
              handlePhysicianRemoval,
              handleSavedPhysicianDetails,
              handleSavedPhysicianRemoval,
              handleIsFormEdited,
              selectedPhysicians,
              savedPhysicians,
              program
            )}
          {currentStep === EnrolPhysicianOnToProgramStep.STEP2 &&
            getViewForStepTwo(
              handlePhysicianRemoval,
              handleSavedPhysicianRemoval,
              handleConfirmationSelect,
              associateConfirmation,
              isSubmitting,
              selectedPhysicians,
              savedPhysicians
            )}
          <Row>
            <Col sm={12} md={10} lg={8}>
              <ClinSpacer hasBorder height={0} />
              <StyledActions>
                <ClinButton
                  className="cancel"
                  onClick={() => handleRequestToViewProgram()}
                >
                  {t('common:buttons.cancel')}
                </ClinButton>
                {currentStep === EnrolPhysicianOnToProgramStep.STEP2 && (
                  <ClinButton
                    className="back"
                    onClick={() => handleGoToPreviousStep()}
                  >
                    {t('common:buttons.back')}
                  </ClinButton>
                )}
                {currentStep === EnrolPhysicianOnToProgramStep.STEP1 && (
                  <ClinButton
                    className="continue"
                    disabled={isContinueDisabled(
                      savedPhysicians,
                      selectedPhysicians
                    )}
                    variant="primary"
                    onClick={() => handleGoToNextStep()}
                  >
                    {t('common:buttons.continue')}
                  </ClinButton>
                )}
                {currentStep === EnrolPhysicianOnToProgramStep.STEP2 && (
                  <ClinButton
                    className="submit"
                    disabled={associateConfirmation === false || isSubmitting}
                    variant="primary"
                    onClick={() => handleSubmit()}
                  >
                    {t('common:buttons.submit_request')}
                    {isSubmitting && (
                      <StyledLoader>
                        <ClinSpinner size={ClinTheme.space[3]} />
                      </StyledLoader>
                    )}
                  </ClinButton>
                )}
              </StyledActions>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[7]} />
        </>
      )}
    </ClinPageContentFrame>
  )
}
