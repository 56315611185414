import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import 'react-tippy/dist/tippy.css'
import { StyledProductRequestHeader } from './ConfirmationScreen .styles'
import { ClinTheme } from '../../ClinTheme'
import { CountryAlphaCodes } from '../../constants/countryAlpha2Codes'
import { ValueCell } from '../../features/IconValueCell/ValueCell'
import {
  StyledSummaryHeading,
  StyledSummaryIcon
} from '../../pages/CheckoutSummary/CheckoutSummary.styles'
import { ConfirmationProductDetail } from '../../pages/SourcingEnquiry/SourcingEnquiry.models'
import { ClinButton } from '../ClinButton'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinPageContentFrame } from '../ClinPageContentFrame'
import { ClinSpacer } from '../ClinSpacer'
import { ClinText } from '../ClinText'
import { TypographyVariant } from '../ClinText/ClinText.styles'

interface IConfirmationScreenDetailProps {
  productDetials: ConfirmationProductDetail
  title: string
  detailsDescription: string
  portfolioCountryCode: string
  submitButtonText: string

  handleBackButton: () => void
}

export const ConfirmationScreen: FunctionComponent<
  IConfirmationScreenDetailProps
> = ({
  productDetials,
  title,
  detailsDescription,
  portfolioCountryCode,
  submitButtonText,
  handleBackButton
}) => {
  const { t } = useTranslation()

  const estimatedTimeForContact =
    portfolioCountryCode === CountryAlphaCodes.Australia
      ? t(
          'sourcing_enquiry:sourcing_enquiry_submitted_details.description_one_aus'
        )
      : t('sourcing_enquiry:sourcing_enquiry_submitted_details.description_one')

  return (
    <ClinPageContentFrame>
      <>
        <ClinSpacer height={ClinTheme.space[0]} />
        <StyledProductRequestHeader>
          <Row>
            <Col sm={10}>
              <StyledSummaryHeading>
                <StyledSummaryIcon>
                  <ClinIcon
                    iconName={ClinIconPathName.ArrowRight}
                    iconSize={ClinTheme.space[4]}
                  />
                </StyledSummaryIcon>
                <ClinText
                  variant={TypographyVariant.H2}
                  as="span"
                  fontWeight={ClinTheme.fontWeights.bold}
                  marginLeft={ClinTheme.space[2]}
                >
                  {title}
                </ClinText>
              </StyledSummaryHeading>
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <ClinText
                as="div"
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
                marginBottom={ClinTheme.space[1]}
                marginTop={ClinTheme.space[4]}
              >
                {t(
                  'sourcing_enquiry:sourcing_enquiry_submitted_details.product_request_number'
                )}
              </ClinText>
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <ClinText
                as="div"
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.normal}
                marginBottom={ClinTheme.space[1]}
                variant={TypographyVariant.H2}
              >
                {productDetials?.referenceNumber}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[3]} />
        </StyledProductRequestHeader>
        <ClinSpacer height={ClinTheme.space[3]} />
        <StyledProductRequestHeader>
          <Row>
            <Col sm={10}>
              <ClinText
                as="div"
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.bold}
                marginBottom={ClinTheme.space[4]}
                fontSize={ClinTheme.fontSizes[4]}
              >
                {t(
                  'sourcing_enquiry:sourcing_enquiry_submitted_details.subtitle_one'
                )}
              </ClinText>
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <ClinText
                as="div"
                color={ClinTheme.colors.black}
                fontWeight={ClinTheme.fontWeights.normal}
                fontSize={ClinTheme.fontSizes[2]}
              >
                {estimatedTimeForContact}
              </ClinText>
              <ClinText
                as="div"
                color={ClinTheme.colors.black}
                fontWeight={ClinTheme.fontWeights.normal}
                marginBottom={ClinTheme.space[4]}
                fontSize={ClinTheme.fontSizes[2]}
              >
                {t(
                  'sourcing_enquiry:sourcing_enquiry_submitted_details.description_two'
                )}
              </ClinText>
            </Col>
          </Row>
        </StyledProductRequestHeader>
        <ClinSpacer height={ClinTheme.space[3]} />
        <Row>
          <Col sm={10}>
            <ClinText
              as="div"
              color={ClinTheme.colors.primary}
              fontWeight={ClinTheme.fontWeights.bold}
              marginBottom={ClinTheme.space[6]}
              fontSize={ClinTheme.fontSizes[4]}
            >
              {detailsDescription}
            </ClinText>
          </Col>
        </Row>
        <Row>
          <Col sm={10}>
            <ValueCell
              title={t(
                'sourcing_enquiry:sourcing_enquiry_submitted_details.generic_name'
              )}
              description={productDetials.genericName}
            ></ValueCell>
            {productDetials.brandName && (
              <ValueCell
                title={t(
                  'sourcing_enquiry:sourcing_enquiry_submitted_details.brand_name'
                )}
                description={productDetials.brandName}
              ></ValueCell>
            )}
            {productDetials.strength && (
              <ValueCell
                title={t(
                  'sourcing_enquiry:sourcing_enquiry_submitted_details.strength'
                )}
                description={productDetials.strength}
              ></ValueCell>
            )}
            {!!productDetials.packSize && (
              <ValueCell
                title={t(
                  'sourcing_enquiry:sourcing_enquiry_submitted_details.pack_size'
                )}
                description={productDetials.packSize}
              ></ValueCell>
            )}
            {productDetials.dosageForm && (
              <ValueCell
                title={t(
                  'sourcing_enquiry:sourcing_enquiry_submitted_details.dosage_form'
                )}
                description={productDetials.dosageForm}
              ></ValueCell>
            )}
            {!!productDetials.requiredQuantity && (
              <ValueCell
                title={t(
                  'sourcing_enquiry:sourcing_enquiry_submitted_details.required_quantity'
                )}
                description={productDetials.requiredQuantity}
              ></ValueCell>
            )}

            {productDetials.comment && (
              <ValueCell
                title={t(
                  'sourcing_enquiry:sourcing_enquiry_submitted_details.other'
                )}
                description={productDetials.comment}
              ></ValueCell>
            )}
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[6]} />
        <ClinSpacer height={ClinTheme.space[6]} />
        <ClinSpacer height={ClinTheme.space[3]} />

        <ClinButton variant="primary" onClick={handleBackButton}>
          {submitButtonText}
        </ClinButton>
        <ClinSpacer height={ClinTheme.space[9]} />
        <ClinSpacer height={ClinTheme.space[4]} />
      </>
    </ClinPageContentFrame>
  )
}
