import React, { FunctionComponent, useEffect, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import {
  StyledSpinnerContainer,
  StyledTimeoutSpinnerContainer,
  StyledTimeoutSpinnerWrapper
} from './OpaCheckout.styles'
import { IDeliveryData } from './OpaCheckoutContainer'
import { ClinTheme } from '../../ClinTheme'
import { OrderDetails } from './OpaCheckoutSteps/OrderDetails'
import { ClinButton } from '../../components/ClinButton'
import { ClinLoader } from '../../components/ClinLoader'
import {
  INewFeature,
  NewFeatureElements
} from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { OpaHeader } from '../../components/OpaHeader'
import {
  OrderDto,
  OrgAddressDto,
  ProgramCatalogDto
} from '../../types/swaggerTypes'

type LocationState = {
  from: string
}

interface IOpaCheckoutProps {
  isLoading: boolean
  isSubmitting: boolean
  isSubmittingSaveAndClose: boolean
  isSubmitted: boolean
  order?: OrderDto
  program?: ProgramCatalogDto
  displayEditMode: boolean
  deliveryData: IDeliveryData
  deliveryNote: string
  shippingAddress?: OrgAddressDto
  patientNumber: string
  physicianTitleAndFullName?: string
  programSponsor?: string
  patientDob?: string
  deliveryAddresses: OrgAddressDto[]
  newFeaturesList?: { [id: string]: INewFeature }
  noteZIndex?: string
  buttonZIndex?: string
  checkboxShaddow?: string
  oldAddressId: string
  isErrorVisible?: boolean
  isSaved?: boolean
  isCancelling?: boolean
  setIsLogoClicked: (val: boolean) => void
  handleSetDisplayMode: (value: boolean) => void
  handleConfirmOrder: (poNumber: string | undefined) => void
  setDeliveryNote: (value: string) => void
  handleSetDeliveryData: (data: IDeliveryData) => void
  handleSetIsNewShippingAddressOpen: (value: boolean) => void
  handleSetShippingAddress: (shippingAddress: OrgAddressDto) => void
  handleDeliveryDataChange: (value: IDeliveryData) => void
  handleSetOldAddressId: (value: string) => void
  displayNewFeature?: (feature: NewFeatureElements) => boolean | undefined
  handleCancelButton: () => void
  handleSaveAndCloseButton: (
    programName?: string,
    programId?: string,
    poNumber?: string
  ) => void
  handleErrorModal: (val: boolean) => void
}

export const OpaCheckout: FunctionComponent<IOpaCheckoutProps> = ({
  isLoading,
  isSubmitting,
  isSubmittingSaveAndClose,
  isSubmitted,
  isErrorVisible,
  order,
  program,
  displayEditMode,
  deliveryData,
  deliveryNote,
  shippingAddress,
  patientNumber,
  physicianTitleAndFullName,
  programSponsor,
  patientDob,
  deliveryAddresses,
  newFeaturesList,
  checkboxShaddow,
  noteZIndex,
  buttonZIndex,
  oldAddressId,
  isSaved,
  setIsLogoClicked,
  displayNewFeature,
  handleErrorModal,
  isCancelling,
  handleSetOldAddressId,
  setDeliveryNote,
  handleSetDisplayMode,
  handleConfirmOrder,
  handleSetIsNewShippingAddressOpen,
  handleSetShippingAddress,
  handleDeliveryDataChange,
  handleCancelButton,
  handleSaveAndCloseButton
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const handleBack = () => {
    history.push('/orders')
  }
  const [poNumber, setPoNumber] = useState<string | undefined>(undefined)
  const [fromPage, setFromPage] = useState<string>('')
  const patientAccessFormPath = '/patient-access-form'
  const [isOrderBeingPlaced, setIsOrderBeingPlaced] = useState(false)

  useEffect(() => {
    const locationState = history.location.state as LocationState
    if (locationState?.from?.includes(patientAccessFormPath)) {
      setFromPage(patientAccessFormPath)
    }
  }, [])

  useEffect(() => {
    if (!isSubmitting && isOrderBeingPlaced) {
      setIsOrderBeingPlaced(false)
    }
  }, [isSubmitting])

  const onBackButtonEvent = (e: PopStateEvent) => {
    e.preventDefault()
    handleErrorModal(true)
    window.history.pushState(null, '', window.location.pathname)
  }

  useEffect(() => {
    fromPage === patientAccessFormPath &&
      window.history.pushState(null, '', window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [fromPage])

  const handlePlaceOrder = () => {
    setIsOrderBeingPlaced(true)
    handleConfirmOrder(poNumber)
  }

  return (
    <>
      <OpaHeader
        patientName={patientNumber}
        dateOfBirth={patientDob}
        programDetails={program?.programName}
        physicianName={physicianTitleAndFullName}
        sponsorName={programSponsor}
        poNumber={poNumber}
        isParentCancelling={isCancelling || isOrderBeingPlaced}
        isParentSaving={isSubmittingSaveAndClose || isOrderBeingPlaced}
        programId={program?.projectId?.toString()}
        setIsLogoClicked={setIsLogoClicked}
        handleCancelButton={() => {
          if (!isOrderBeingPlaced) {
            handleCancelButton()
          }
        }}
        handleSaveAndCloseButton={(programName, programId, poNumber) => {
          if (!isOrderBeingPlaced) {
            handleSaveAndCloseButton(programName, programId, poNumber)
          }
        }}
      />
      <ClinPageContentFrame
        isLoading={isLoading || !order || !program}
        marginBottom="0"
        className="min-height-ma-checkout-class"
        hideBreadcrumbs={true}
      >
        {(isSubmitting || isSubmittingSaveAndClose) && (
          <>
            {!isErrorVisible && (
              <Row>
                <Col sm={6}>
                  <ClinText
                    variant={TypographyVariant.H4}
                    marginTop={ClinTheme.space[4]}
                  >
                    {isSubmitting
                      ? t('opa_checkout:placing_your_order')
                      : t('opa_checkout:saving_your_order_loader')}
                  </ClinText>
                </Col>
              </Row>
            )}
            {isErrorVisible ? (
              <StyledTimeoutSpinnerWrapper>
                <ClinSpacer height={62} />
                <ClinLoader />
                <StyledTimeoutSpinnerContainer>
                  <StyledTimeoutSpinnerWrapper>
                    <ClinText
                      marginTop={54}
                      variant={TypographyVariant.H4}
                      fontWeight={ClinTheme.fontWeights.normal}
                      lineHeight={ClinTheme.lineHeights.largeParagraph}
                      color={ClinTheme.colors.black}
                    >
                      {t('opa_checkout:delayed_error_heading')}
                    </ClinText>
                    <ClinText
                      width="40%"
                      textAlign="center"
                      variant={TypographyVariant.LargerParagraph}
                      fontWeight={ClinTheme.fontWeights.normal}
                      marginTop={'0px'}
                      marginBottom={'0px'}
                    >
                      {t('opa_checkout:delay_error_text_second')}
                    </ClinText>
                    <ClinText
                      width="40%"
                      textAlign="center"
                      variant={TypographyVariant.LargerParagraph}
                      fontWeight={ClinTheme.fontWeights.normal}
                      marginTop={'0px'}
                    >
                      {t('opa_checkout:delayed_error_text')}
                    </ClinText>
                    <ClinButton
                      style={{ marginTop: '16px' }}
                      href="/orders"
                      variant="primary"
                      onClick={(e) => {
                        e.preventDefault()
                        handleBack && handleBack()
                      }}
                    >
                      {t('opa_checkout:delayed_error_cta')}
                    </ClinButton>
                  </StyledTimeoutSpinnerWrapper>
                </StyledTimeoutSpinnerContainer>
              </StyledTimeoutSpinnerWrapper>
            ) : (
              <Row justify="center">
                <Col>
                  <StyledSpinnerContainer>
                    <ClinLoader />
                  </StyledSpinnerContainer>
                </Col>
              </Row>
            )}
          </>
        )}
        {order && !isSubmitting && !isSubmittingSaveAndClose && (
          <OrderDetails
            order={order}
            deliveryData={deliveryData}
            deliveryNote={deliveryNote}
            shippingAddress={shippingAddress}
            deliveryAddresses={deliveryAddresses}
            newFeaturesList={newFeaturesList}
            noteZIndex={noteZIndex}
            buttonZIndex={buttonZIndex}
            checkboxShaddow={checkboxShaddow}
            oldAddressId={oldAddressId}
            displayNewFeature={displayNewFeature}
            handleSetOldAddressId={handleSetOldAddressId}
            setDeliveryNote={setDeliveryNote}
            handleConfirmOrder={handlePlaceOrder}
            handleSetDisplayMode={handleSetDisplayMode}
            displayEditMode={displayEditMode}
            handleDeliveryDetailsChange={handleDeliveryDataChange}
            handleNewShippingAddress={() =>
              handleSetIsNewShippingAddressOpen(true)
            }
            handleSetShippingAddress={handleSetShippingAddress}
            setPoNumber={setPoNumber}
          />
        )}
      </ClinPageContentFrame>
    </>
  )
}
