import { AxiosError } from 'axios'
import React, { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { $enum } from 'ts-enum-util'

import { ProductSearch } from './ProductSearch'
import {
  getColumnForSortField,
  getDerivedFilter,
  getSortFieldForColumn,
  getUpdatedFiltersForTag
} from './ProductSearch.models'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  decodeObjToUrlParams,
  getParamValueFor,
  IPagination,
  useUpdateQueryParam
} from '../../../components/ClinPagination/ClinPagination.model'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import {
  UserRoleRecord,
  UserRole,
  isAusGaUser,
  isAusMaUser
} from '../../../constants'
import { CountryAlphaCodes } from '../../../constants/countryAlpha2Codes'
import { useAppContext } from '../../../context/app'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { useEffectOnlyOnce } from '../../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { AnalyticsEvent } from '../../../services/Analytics/AnalyticsEvent'
import { AnalyticsPageEvent } from '../../../services/Analytics/AnalyticsPageEvent'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import {
  AuthError,
  cancelGetProducts,
  cancelGetTherapeuticAreas,
  getProducts,
  getSearchFacetsForCountry
} from '../../../services/ApiService'
import { IProductCatalog } from '../../../types'
import {
  CatalogDto,
  FacetDto,
  FieldFacetsDto,
  ProductSearchDto
} from '../../../types/swaggerTypes'
import { useOnMount } from '../../../utils/useOnMount'
import {
  ProductColumn,
  isAccessProgram,
  isExceptionTag,
  getAvailabilityDisplayText
} from '../Product.models'

const rowsPerPage: number = 100

const defaultPagination: IPagination = {
  count: 0,
  skip: 0,
  take: rowsPerPage,
  total: 0
}

const defaultSearchParams: ProductSearchDto = {
  query: '*',
  filter: {
    tags: [],
    availabilities: [],
    countryCode: 'UNKNOWN',
    derivedFromFilter: ''
  },
  pagination: {
    skip: 0,
    take: rowsPerPage
  },
  sorting: {
    sortBy: `countryAvailability/UNKNOWN`,
    order: SortDirectionType.Ascending
  }
}

const defaultAusGaFilter: string[] = ['Available', 'Available On Request']
export const defaultAusGaDerivedFromFilter: string = 'Generic'

interface IProductSearchProps extends RouteComponentProps {}

export const ProductSearchContainer: FunctionComponent<IProductSearchProps> = ({
  location,
  history
}) => {
  const { t } = useTranslation()
  const { dispatch, portfolioCountryCode, userRole } = useAppContext()
  const isMaUser = !!(userRole && UserRoleRecord[userRole as UserRole].isMaUser)
  const columns =
    isMaUser && !isAusMaUser(portfolioCountryCode, userRole)
      ? [
          ProductColumn.ToggleExpand,
          ProductColumn.GenericName,
          ProductColumn.BrandName,
          ProductColumn.TherapeuticArea,
          ProductColumn.Sponsor
        ]
      : [
          ProductColumn.ToggleExpand,
          ProductColumn.GenericName,
          ProductColumn.BrandName,
          ProductColumn.TherapeuticArea,
          ProductColumn.Availability,
          ProductColumn.Bookmark
        ]
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [products, setProducts] = React.useState<IProductCatalog[] | undefined>(
    undefined
  )
  const [areaFilters, setAreaFilters] = React.useState<FacetDto[]>([])
  const [availabilityFilters, setAvailabilityFilters] = React.useState<
    FacetDto[]
  >([])

  // Restore page state from URL params
  const urlSearchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )

  // Query
  const initialQuery = React.useMemo(() => {
    const productQuery = urlSearchParams.get('q')
    return productQuery === '*' ? '' : productQuery ?? ''
  }, [urlSearchParams])

  const derivedFilter = React.useMemo(() => {
    // Empty string means receiving programs and products simultaneously
    return getDerivedFilter(portfolioCountryCode, userRole) //changed related to clos-1614 aus ma users can see products
  }, [portfolioCountryCode, userRole])

  // Restore applied/selected filters
  const [selectedAreaFilters, setSelectedAreaFilters] = React.useState<
    FacetDto[]
  >(() => {
    let previousSelectedFilters: FacetDto[] = []
    const appliedFilters = urlSearchParams.get('selectedAreaFilters')
    if (appliedFilters) {
      previousSelectedFilters = decodeObjToUrlParams(appliedFilters)
      defaultSearchParams.filter.tags = [
        ...previousSelectedFilters.map((f) => f.value)
      ]
    }
    return previousSelectedFilters
  })

  const [selectedAvailabilityFilters, setSelectedAvailabilityFilters] =
    React.useState<FacetDto[]>(() => {
      let previousSelectedFilters: FacetDto[] = []
      const appliedFilters = urlSearchParams.get('selectedAvailabilityFilters')
      if (appliedFilters) {
        previousSelectedFilters = decodeObjToUrlParams(appliedFilters)
        defaultSearchParams.filter.availabilities = [
          ...previousSelectedFilters.map((f) => f.value)
        ]
      }

      return previousSelectedFilters
    })

  // Restore page Size
  const [productsPerPage, setProductsPerPage] = React.useState<number>(() => {
    const previousPageSize = urlSearchParams.get('pageSize')
    return previousPageSize ? parseInt(previousPageSize) : rowsPerPage
  })

  // Restore pagination from URL params
  defaultSearchParams.pagination.take = getParamValueFor(
    'pageSize',
    urlSearchParams,
    rowsPerPage
  )
  // PageIndex
  defaultPagination.take = defaultSearchParams.pagination.take
  defaultSearchParams.pagination.skip =
    getParamValueFor('pageIndex', urlSearchParams) *
    defaultSearchParams.pagination.take

  const [pagination, setPagination] =
    React.useState<IPagination>(defaultPagination)

  // Restore sort by column from URL params and update query
  const [sortColumn, setSortColumn] = React.useState<ProductColumn>(() => {
    const sortByColumn = urlSearchParams.get('sortBy')
    const previousSortBy = sortByColumn && decodeURIComponent(sortByColumn)
    const defaultSortByColumn = getColumnForSortField(
      defaultSearchParams.sorting.sortBy,
      portfolioCountryCode
    )
    const restoredSortByColumn = $enum(ProductColumn).asValueOrDefault(
      previousSortBy,
      defaultSortByColumn
    )
    defaultSearchParams.sorting.sortBy = getSortFieldForColumn(
      restoredSortByColumn,
      portfolioCountryCode
    )
    return restoredSortByColumn
  })

  // Restore sort direction from URL params
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType>(
    () => {
      const previousSortDirection = urlSearchParams.get('sortDirection')
      const restoredSortDirection = $enum(SortDirectionType).asValueOrDefault(
        previousSortDirection,
        portfolioCountryCode === CountryAlphaCodes.Australia //for aus users we want desc order
          ? SortDirectionType.Descending
          : SortDirectionType.Ascending
      )
      defaultSearchParams.sorting.order = restoredSortDirection.toString()
      return restoredSortDirection
    }
  )

  //if user is aus ga only we should hide access program clos-1610
  if (isAusGaUser(portfolioCountryCode, userRole)) {
    defaultSearchParams.filter.availabilities = defaultAusGaFilter
  }

  // Construct the search query
  defaultSearchParams.filter.countryCode = portfolioCountryCode
  const [searchParams, setSearchParams] = React.useState<ProductSearchDto>({
    ...defaultSearchParams,
    query: initialQuery,
    filter: {
      ...defaultSearchParams.filter,
      derivedFromFilter: derivedFilter
    }
  })

  const handlePageClicked = (selectedPageIndex: number) => {
    cancelGetProducts()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: (selectedPageIndex - 1) * productsPerPage,
        take: productsPerPage
      }
    })
  }

  const handlePageSizeChange = (pageSize: number) => {
    cancelGetProducts()
    setIsLoading(true)
    setProductsPerPage(pageSize)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: 0,
        take: pageSize
      }
    })
  }

  const handleRowClicked = (itemId: string | number, isProgram: boolean) => {
    cancelGetProducts()
    history.push(
      isProgram ? `/programs/access-programs/${itemId}` : `/product/${itemId}`
    )
  }

  const handleFilterToggle = (tag: FacetDto, filterGroupTitle: string) => {
    cancelGetProducts()
    setIsLoading(true)
    const isAvailabilityFilter =
      filterGroupTitle === t('product_catalogue:filter.title')
    const updatedFilters = getUpdatedFiltersForTag(
      tag,
      isAvailabilityFilter ? selectedAvailabilityFilters : selectedAreaFilters
    )

    let updateSearchParams: ProductSearchDto

    if (isAvailabilityFilter) {
      updateSearchParams = {
        ...searchParams,
        pagination: {
          ...searchParams.pagination,
          skip: 0
        },
        filter: {
          ...searchParams.filter,
          availabilities: [...updatedFilters.map((f) => f.value)]
        }
      }
      setSelectedAvailabilityFilters(updatedFilters)
    } else {
      updateSearchParams = {
        ...searchParams,
        pagination: {
          ...searchParams.pagination,
          skip: 0
        },
        filter: {
          ...searchParams.filter,
          tags: [...updatedFilters.map((f) => f.value)]
        }
      }
      setSelectedAreaFilters(updatedFilters)
    }

    setSearchParams(updateSearchParams)
  }

  const handleClearFilters = () => {
    cancelGetProducts()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        ...searchParams.pagination,
        skip: 0
      },
      filter: {
        tags: [],
        countryCode: portfolioCountryCode,
        availabilities: isAusGaUser(portfolioCountryCode, userRole)
          ? defaultAusGaFilter
          : [],
        derivedFromFilter: isAusGaUser(portfolioCountryCode, userRole) // added this related to clos-1610 (ga aus users can not see access program products)
          ? defaultAusGaDerivedFromFilter
          : ''
      }
    })
    setSelectedAreaFilters([])
    setSelectedAvailabilityFilters([])
  }

  const toggleSortDirection = (
    current: SortDirectionType
  ): SortDirectionType => {
    if (current === SortDirectionType.None) {
      return SortDirectionType.Descending
    }
    if (current === SortDirectionType.Descending) {
      return SortDirectionType.Ascending
    }
    return SortDirectionType.None
  }

  const handleToggleSort = (columnName: ProductColumn) => {
    cancelGetProducts()
    setIsLoading(true)
    const newSortDirection =
      sortColumn === columnName
        ? toggleSortDirection(sortDirection)
        : SortDirectionType.Descending
    setSortDirection(newSortDirection)
    setSortColumn(columnName)
    const sortField = getSortFieldForColumn(columnName, portfolioCountryCode)
    setSearchParams({
      ...searchParams,
      sorting: {
        sortBy:
          newSortDirection === SortDirectionType.None
            ? `countryAvailability/${portfolioCountryCode}`
            : sortField,
        order: newSortDirection
      }
    })
  }

  const handleOnChange = (query: string) => {
    cancelGetProducts()
    setIsLoading(true)
    setSearchParams((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        skip: 0
      },
      query
    }))
  }

  const handleToggleProductRow = (catalogItemName: string) => {
    products &&
      setProducts([
        ...products.map((product: IProductCatalog) => {
          if (product.catalogDocumentId === catalogItemName) {
            product.isOpen = !product.isOpen
          }
          return product
        })
      ])
  }

  useOnMount(() => {
    getSearchFacetsForCountry(portfolioCountryCode)
      .then((response) => {
        setIsLoading(false)
        // Therapeutic areas
        const therapeuticAreaFacets: FieldFacetsDto | undefined =
          response.data.facets.find((f) => f.field === 'tags')
        const values = therapeuticAreaFacets?.values?.filter(
          (value) => !isExceptionTag(value.value)
        )
        values && setAreaFilters(values)
        // Availability
        const availabilityFacets: FieldFacetsDto | undefined =
          response.data.facets.find(
            (f) => f.field === `countryAvailability/${portfolioCountryCode}`
          )
        let availabilityValues = availabilityFacets?.values
        if (availabilityValues && isAusGaUser(portfolioCountryCode, userRole)) {
          //condition added related to clos-1610 (Aus ga only user should not be able to filter access program products)
          availabilityValues = availabilityValues.filter(
            (a) => a.value !== 'Access Program'
          )
        }

        availabilityValues && setAvailabilityFilters(availabilityValues)
      })
      .catch((error: AxiosError) => {
        const { code, message } = error
        // If request is cancelled continue
        if (error.message === AuthError.RequestCancelled) {
          return
        }
        // If we have a full error show it
        if (error.response) {
          const { title, detail } = error.response.data
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching therapeutic areas. ${title ?? ''} ${
                message ?? ''
              }`
            )
          )
          console.warn(title, detail)
        } else {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching therapeutic areas. ${message} ${
                code ?? ''
              }`
            )
          )
        }

        analyticsServiceSingleton.trackError(
          error,
          JSON.parse(localStorage.getItem('current_user') || '{}')
        )
        setIsLoading(false)
      })

    return () => {
      cancelGetTherapeuticAreas()
    }
  })

  // Get products
  useEffect(() => {
    // Below is a potential fix for how the view all might work - requires a date time stamp in the AutoSuggest
    // Handles the situation where you are on the current page and click View all products
    const queryParam = new URLSearchParams(location.search)
    const productQuery = queryParam.get('q')
    if (productQuery === '*') {
      history.push({
        pathname: location.pathname,
        search: ``
      })
      // Reset it all!
      setIsLoading(true)
      setSelectedAreaFilters([])
      setPagination(defaultPagination)
      setProducts([])
      setSearchParams((prevState) => ({
        ...defaultSearchParams,
        filter: { ...prevState.filter }
      }))
      return
    }

    // searchParams and pagination changes then make call
    getProducts(searchParams)
      .then((response) => {
        if (productQuery && productQuery !== '') {
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.SubmitSearchQuery,
            {
              query: productQuery,
              searchLocation: 'product catalogue',
              searchAPI: 'search'
            }
          )
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.ResultsForSearchQuery,
            {
              query: productQuery,
              numberResults: response.data.result.length
            }
          )
        }
        setIsLoading(false)
        if (response) {
          const productsFromApi: CatalogDto[] | null = response.data.result
          productsFromApi.forEach((product: IProductCatalog) => {
            const { tags, countryAvailability } = product
            product.isBookmarkable = isAccessProgram(tags)
            product.availabilityText =
              countryAvailability &&
              getAvailabilityDisplayText(
                countryAvailability,
                portfolioCountryCode,
                t
              )
            product.tags = product.tags.filter((t) => !isExceptionTag(t))
          })
          setProducts(productsFromApi)
          const paginationDto = response.data.pagination
          setPagination({
            count: paginationDto.count,
            skip: paginationDto.skip,
            take: paginationDto.take,
            total: paginationDto.total
          })
        }
      })
      .catch((error: AxiosError) => {
        const { code, message } = error

        // If request is cancelled continue
        if (error.message === AuthError.RequestCancelled) {
          return
        }
        // If we have a full error show it
        if (error.response) {
          const { title, detail } = error.response.data
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching products. ${title ?? ''} ${
                message ?? ''
              }`
            )
          )
          console.warn(title, detail)
        } else {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching products. ${message} ${code ?? ''}`
            )
          )
        }

        analyticsServiceSingleton.trackError(
          error,
          JSON.parse(localStorage.getItem('current_user') || '{}')
        )
        setIsLoading(false)
      })

    return () => {
      cancelGetProducts()
    }
  }, [
    dispatch,
    history,
    location.pathname,
    location.search,
    portfolioCountryCode,
    searchParams,
    t
  ])

  // Track changes to pagination and tabs as they update and update query string if it changes
  useUpdateQueryParam({ q: searchParams.query })
  useUpdateQueryParam({
    selectedAreaFilters
  })
  useUpdateQueryParam({
    selectedAvailabilityFilters
  })

  useUpdateQueryParam({ sortBy: sortColumn.toString() })
  useUpdateQueryParam({ sortDirection: sortDirection.toString() })
  useUpdateQueryParam({ pageSize: searchParams.pagination.take })
  useUpdateQueryParam({
    pageIndex: searchParams.pagination.skip / searchParams.pagination.take
  })

  type Deps = [boolean]
  useEffectOnlyOnce(
    () => {
      analyticsServiceSingleton.trackPageView(
        AnalyticsPageEvent.ViewProductCatalogue,
        {}
      )
    },
    [isLoading],
    (dependencies: Deps) => dependencies[0] === false
  )

  return (
    <ProductSearch
      isLoading={isLoading}
      isMaUser={isMaUser}
      initialQuery={initialQuery}
      products={products}
      pagination={pagination}
      areaFilters={areaFilters}
      selectedAreaFilters={selectedAreaFilters}
      availabilityFilters={availabilityFilters}
      selectedAvailabilityFilters={selectedAvailabilityFilters}
      columns={columns}
      selectedSortColumn={sortColumn}
      selectedSortDirection={sortDirection}
      userCountry={portfolioCountryCode}
      handleRowClicked={(itemId, isProgram) =>
        handleRowClicked(itemId, isProgram)
      }
      handlePageClicked={handlePageClicked}
      handlePageSizeChange={handlePageSizeChange}
      handleFilterToggle={handleFilterToggle}
      handleClearFilters={handleClearFilters}
      handleToggleSort={handleToggleSort}
      handleOnChange={handleOnChange}
      handleToggleProductRow={handleToggleProductRow}
    />
  )
}
