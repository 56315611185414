import React from 'react'

import { HomepageOld } from './HomepageOld'
import { ClinTheme } from '../../../ClinTheme'
import useChangeBackgroundColor from '../../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'

export const HomepageContainerOld: React.FC = () => {
  useChangeBackgroundColor(ClinTheme.colors.lightGrey)

  return <HomepageOld />
}
