import { TFunction } from 'i18next'

import { IIndexable } from '../../types'
import { CatalogDto } from '../../types/swaggerTypes'

export enum ProductColumn {
  ToggleExpand = 'ToggleExpand',
  GenericName = 'Generic name',
  ProgramName = 'Program name',
  BrandName = 'Brand name',
  TherapeuticArea = 'Therapeutic area',
  Sponsor = 'Sponsor',
  Availability = 'Availability',
  Bookmark = 'Bookmark'
}

export enum ProductSourceSystem {
  EBS = 'eBS',
  Sagex3 = 'JAPAC X3'
}

interface productColumnDetail {
  name: string
  translationKey: string
}

/**
 * Look up record/table for returning phone types and view values
 * @usage:
 *
 * const { name translationKey } = productColumnRecord[column]
 *
 */
export const productColumnRecord: Record<ProductColumn, productColumnDetail> = {
  [ProductColumn.ToggleExpand]: {
    name: ProductColumn.ToggleExpand,
    translationKey: ''
  },
  [ProductColumn.GenericName]: {
    name: ProductColumn.GenericName,
    translationKey: 'product_catalogue:column_titles.generic_name'
  },
  [ProductColumn.ProgramName]: {
    name: ProductColumn.ProgramName,
    translationKey: 'product_catalogue:column_titles.program_name'
  },
  [ProductColumn.BrandName]: {
    name: ProductColumn.BrandName,
    translationKey: 'product_catalogue:column_titles.brand_name'
  },
  [ProductColumn.TherapeuticArea]: {
    name: ProductColumn.TherapeuticArea,
    translationKey: 'product_catalogue:column_titles.therapeutic_area'
  },
  [ProductColumn.Sponsor]: {
    name: ProductColumn.Sponsor,
    translationKey: 'product_catalogue:column_titles.sponsor'
  },
  [ProductColumn.Availability]: {
    name: ProductColumn.Availability,
    translationKey: 'product_catalogue:column_titles.availability'
  },
  [ProductColumn.Bookmark]: {
    name: ProductColumn.Bookmark,
    translationKey: 'product_catalogue:column_titles.bookmark'
  }
}

const exceptionTags = ['Access Program', 'Covid19']

/**
 * Reduce all tags down to an array of exclusive tags only (no dupes)
 * @param products
 */
export const getTherapeuticAreas = (products: CatalogDto[]): string[] => {
  return products.reduce((areas: string[], product) => {
    if (
      product &&
      product.tags &&
      product.tags.every((t) => areas.indexOf(t) === -1)
    ) {
      areas.push(...product.tags)
    }
    return areas
  }, [])
}

/**
 * Utility method to return the correct text color
 * "countryAvailability": {
    "GB": 65536,
    "XN": 256,
    "GG": 65536,
    "JE": 65536,
    "IM": 65536
}
 The numerical values are a bit flag. Either 1 << 8 (= 256) for "Available on request" or 1 << 16 (= 65536) for "Available". This is to allow for future expansion, for example with MA.
 */
export const getAvailabilityDisplayText = (
  countryAvailability: IIndexable<string>,
  countryCode: string,
  t: TFunction
): string => {
  switch (parseInt(countryAvailability[countryCode])) {
    case 16:
      return t('product_catalogue:availability.access_program')
    case 65536:
      return t('product_catalogue:availability.available')
    case 256:
      return t('product_catalogue:availability.available_on_request')
    default:
      return ''
  }
}

/**
 * Determine is a shipTo country is a "core country" and can have custom shipping
 * @param shipToCountryCode
 * //TODO: May not be required
 */
export const canShipCustom = (shipToCountryCode: string): boolean => {
  const coreCountries = [
    'GB',
    'AT',
    'BE',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'HR',
    'DE',
    'VA',
    'JE',
    'GR',
    'IE',
    'IT',
    'LU',
    'MT',
    'MC',
    'NL',
    'NO',
    'PT',
    'SK',
    'SI',
    'ES',
    'XC',
    'SE',
    'CH'
  ]
  return coreCountries.indexOf(shipToCountryCode) > -1
}

/**
 * Checks if the product belongs to the Access Program
 * @param tags
 */
export const isAccessProgram = (tags: string[]): boolean => {
  const filteredTags = tags.filter((t) => !isExceptionTag(t))
  return filteredTags.length !== tags.length
}

export const isExceptionTag = (tag: string) => exceptionTags.includes(tag)
