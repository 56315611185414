import { TFunction } from 'i18next'
import _ from 'lodash'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'

import { CheckoutOptions } from './CheckoutOptions'
import { NewShippingAddressExitModal } from './NewShippingAddressExitModal'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { UserRoleRecord } from '../../constants'
import { useAppContext } from '../../context/app'
import {
  ActionSetExpectedDeliveryDate,
  ActionType,
  useBasket
} from '../../context/basket'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { placeDraftOrder } from '../../services/ApiService'
import { localStorageRecipientName } from '../../types/localStorageConstants'
import {
  OrderAddressDto,
  OrgAddressDto,
  UpdateBasketDetailsDto
} from '../../types/swaggerTypes'
import { handleBasketError } from '../../utils/basketUtils'
import { useCountries } from '../../utils/useCountries'

export const CheckoutOptionsContainer: FunctionComponent<
  RouteComponentProps
> = ({ history }) => {
  const { t } = useTranslation(['checkout_options'])

  const {
    dispatch,
    institute,
    defaultShippingAddress,
    supportContact,
    userRole
  } = useAppContext()
  const [{ basket, expectedDeliveryDate, basketDetails }, basketDispatch] =
    useBasket()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isNewShippingAddressOpen, setIsNewShippingAddressOpen] =
    useState<boolean>(false)
  const [hasErrorMessage, setHasErrorMessage] = useState<string | undefined>(
    undefined
  )

  const item = basket?.data?.items?.[0]?.skuCatalogItem
  const [deliveryOptions, setDeliveryOptions] =
    useState<UpdateBasketDetailsDto>(basketDetails)

  const [deliveryAddresses, setDeliveryAddresses] =
    useState<OrderAddressDto[]>()

  /**
   * Validate Purchase Order
   * */
  const getPOErrorMessage = (
    poNumber: string,
    transFn: TFunction
  ): string | undefined => {
    const purchaseOrderNumber = poNumber
    const regex = /[^\w-]/
    if (purchaseOrderNumber && purchaseOrderNumber.indexOf(' ') >= 0) {
      return transFn('po_error_message_no_space')
    }
    if (purchaseOrderNumber && purchaseOrderNumber?.length >= 50) {
      return transFn('po_error_message_max_length')
    }
    if (purchaseOrderNumber && purchaseOrderNumber === '') {
      return transFn('po_error_message_not_supplied')
    }
    if (purchaseOrderNumber && regex.test(purchaseOrderNumber)) {
      return transFn('po_error_message_specia_charachters', {
        characters: '+/#',
        interpolation: { escapeValue: false }
      })
    }
    return undefined
  }

  const handleBackToBasket = () => {
    history.push('/basket')
  }

  /**
   * Called when the Continue button is clicked on the Select delivery options screen
   */
  const handlePlaceDraftOrder = () => {
    setIsSubmitting(true)
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
    deliveryOptions.shippingAddressId &&
      deliveryOptions.poNumber &&
      placeDraftOrder(
        deliveryOptions.currencyCode,
        deliveryOptions.deliverToContact.trim(),
        deliveryOptions.poNumber.trim(),
        deliveryOptions.shippingAddressId.toString(),
        undefined, //this is for custom order address and we don't have it here for ga users
        deliveryOptions.recipientEmail?.trim() ?? '',
        deliveryOptions.recipientPhoneNumber?.trim() ?? ''
      )
        .then((response) => {
          if (response.status === 200) {
            basketDispatch({
              type: ActionType.PlaceOrder,
              basket: response.data.basket,
              orders: response.data.createdOrders
            })
            history.push('/checkout')
          }
        })
        .catch((error) => {
          setIsSubmitting(false)
          const basketError = handleBasketError(error, dispatch)
          !basketError &&
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `An error occurred creating your order. ${error}`
              )
            )
        })
  }

  const { countries } = useCountries()

  const defaultCountry = countries?.find(
    (c) => c.countryCode === 'GB' || c.countryCode === 'IE'
  )

  const hasStandardShipping = !!(
    defaultCountry &&
    userRole &&
    UserRoleRecord[userRole].isMaUser === false
  )

  const getDeliverToContactInfo = (): string => {
    const recipientName = localStorage.getItem(localStorageRecipientName)
    return deliveryOptions.deliverToContact
      ? deliveryOptions.deliverToContact
      : recipientName ?? ''
  }

  const handleRecipientsNameChange = (value: string) => {
    setDeliveryOptions({
      ...deliveryOptions,
      deliverToContact: value
    })
    updateRecipientNameDebounced(value)
  }

  const updateDeliveryOptionsDebounced = useCallback(
    _.debounce((deliveryOptions: UpdateBasketDetailsDto) => {
      basketDispatch({
        type: ActionType.UpdateBasketDetails,
        basketDetails: deliveryOptions
      })
    }, 500),
    []
  )

  const updateRecipientNameDebounced = useCallback(
    _.debounce((recipientName: string) => {
      if (recipientName !== '') {
        localStorage.setItem(localStorageRecipientName, recipientName)
      }
    }, 250),
    []
  )
  //method for sorting addresses so that the one with primary flag Y always be the first
  const sortAddresses = (a: OrgAddressDto, b: OrgAddressDto): number => {
    return a.primaryFlag === 'Y' ? -1 : 1
  }

  /**
   * When addresses are loaded set them and select the default shipping address
   */
  const isOptionsLoaded = useRef(false) // Make sure this only happens once
  useEffect(() => {
    if (
      !isOptionsLoaded.current &&
      !institute.isLoading &&
      institute?.data &&
      defaultShippingAddress
    ) {
      setDeliveryAddresses(
        institute.data.shipTo.map((address) => address).sort(sortAddresses)
      ) // Map from (OrgAddressDto to OrderAddressDto)
      setDeliveryOptions({
        ...deliveryOptions,
        shippingAddressId: deliveryOptions.shippingAddressId
          ? deliveryOptions.shippingAddressId
          : defaultShippingAddress.addressId
      })
      isOptionsLoaded.current = true
    }
  }, [institute, defaultShippingAddress, deliveryOptions])

  const handleNewShippingAddress = () => {
    history.push('/new-shipping-address')
  }

  useEffect(() => {
    const countryCode = deliveryAddresses?.[0].country ?? ''
    if (item && countryCode) {
      basketDispatch({
        type: ActionType.SetExpectedDeliveryDate,
        countryCode: countryCode
      } as ActionSetExpectedDeliveryDate)
    }
  }, [item, deliveryAddresses, basketDispatch])

  useEffect(() => {
    updateDeliveryOptionsDebounced(deliveryOptions)
  }, [deliveryOptions, updateDeliveryOptionsDebounced])

  return (
    <>
      <NewShippingAddressExitModal
        isOpen={isNewShippingAddressOpen}
        handleSubmit={handleNewShippingAddress}
        handleClose={() => setIsNewShippingAddressOpen(false)}
      />
      <CheckoutOptions
        hasStandardShipping={hasStandardShipping}
        isSubmitting={isSubmitting}
        expectedDeliveryDate={expectedDeliveryDate}
        supportContact={supportContact}
        recipientPhoneNumber={basketDetails.recipientPhoneNumber ?? ''}
        recipientEmail={basketDetails.recipientEmail}
        deliveryOption={deliveryOptions.deliveryOption}
        customerPoNumber={deliveryOptions.poNumber ?? ''}
        deliverToContact={getDeliverToContactInfo()}
        errorMessage={hasErrorMessage}
        selectedAddressId={
          deliveryOptions.shippingAddressId
            ? deliveryOptions.shippingAddressId
            : ''
        }
        deliveryAddresses={deliveryAddresses}
        handleDeliveryTypeChange={(deliveryType) =>
          setDeliveryOptions({
            ...deliveryOptions,
            deliveryOption: deliveryType
          })
        }
        handleChangeAddress={(selectedAddressId) =>
          setDeliveryOptions({
            ...deliveryOptions,
            shippingAddressId: selectedAddressId
          })
        }
        handleBackToBasket={handleBackToBasket}
        handlePoChange={(value) => {
          setDeliveryOptions({ ...deliveryOptions, poNumber: value })
          setHasErrorMessage(getPOErrorMessage(value, t))
        }}
        handleRecipientsNameChange={(value) =>
          handleRecipientsNameChange(value)
        }
        handleRecipientEmailChange={(value) => {
          setDeliveryOptions({ ...deliveryOptions, recipientEmail: value })
        }}
        handleRecipientPhoneChange={(value) => {
          setDeliveryOptions({
            ...deliveryOptions,
            recipientPhoneNumber: value
          })
        }}
        handlePlaceDraftOrder={handlePlaceDraftOrder}
        handleNewShippingAddress={() => setIsNewShippingAddressOpen(true)}
      />
    </>
  )
}
