import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery } from '../../../utils/mediaQuery'

const mediumUpBreakpoint = ClinTheme.breakpoints[0]

export const StyledAdverseEventForm = styled.div({
  display: 'flex',
  padding: ClinTheme.space[4],
  flexDirection: 'column',
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`,

  [mediaQuery(mediumUpBreakpoint)]: {
    alignItems: 'center',
    flexDirection: 'row',
    minHeight: 60, // so that it's same height, regardless of whether the text is 1 or 2 lines

    ' > *:last-child': {
      textAlign: 'right'
    },
    ' > *': {
      width: '50%'
    }
  }
})
