import React, { FunctionComponent } from 'react'

import { StyledClinText, ITextStyles } from './ClinText.styles'
import { ClinTheme } from '../../ClinTheme'
import { IDefaultStyleProps, IIndexable } from '../../types'

export interface IClinTextProps extends IDefaultStyleProps, ITextStyles {
  width?: string
  overflow?: string
  textOverflow?: string
}

export const ClinText: FunctionComponent<IClinTextProps> = ({
  className = '',
  as = 'p',
  children,
  variant,
  textAlign = 'left',
  fontSize,
  fontWeight = (ClinTheme.fontWeights as IIndexable)[
    ClinTheme.fontWeights.normal
  ],
  lineHeight = '',
  color,
  marginTop = 0,
  marginRight = 0,
  marginBottom = 0,
  marginLeft = 0,
  textTransform = 'none',
  whiteSpace = 'normal',
  display,
  inverted = false,
  wordBreak,
  width,
  overflow,
  textOverflow,
  letterSpacing
}) => (
  <StyledClinText
    className={`clin-text ${className}`}
    as={as}
    variant={variant}
    textAlign={textAlign}
    fontSize={fontSize}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    color={color}
    marginTop={marginTop}
    marginRight={marginRight}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    textTransform={textTransform}
    whiteSpace={whiteSpace}
    display={display}
    inverted={inverted}
    wordBreak={wordBreak}
    width={width}
    overflow={overflow}
    textOverflow={textOverflow}
    letterSpacing={letterSpacing}
  >
    {children}
  </StyledClinText>
)
