import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import {
  betweenQueryTooltip,
  mediaQuery,
  mediaQueryDown
} from '../../utils/mediaQuery'
import { ClinButton } from '../ClinButton'
import {
  StyledClinDropdown,
  StyledClinDropdownContainer
} from '../ClinDropdown/ClinDropdown.styles'
import { StyledClinDropdownMenu } from '../ClinDropdownMenu/ClinDropdownMenu.styles'
import { ClinLogo } from '../ClinLogo'
import { ClinMobileLogo } from '../ClinMobileLogo'

const xLargeUpBreakpoint = ClinTheme.breakpoints[2]

export interface IStyledClinNavBarProps {
  isMenuOpen?: boolean
}

export const StyledClinNavBar = styled.nav<IStyledClinNavBarProps>({
  padding: `${ClinTheme.space[3]}px`,
  paddingTop: '48px',
  display: 'flex',
  position: 'relative',
  backgroundColor: ClinTheme.colors.primary,
  margin: 'auto',
  [`@media print`]: {
    display: 'none'
  },
  [mediaQuery(ClinTheme.breakpoints[2])]: {
    padding: '31px 10px 32px 24px',
    alignItems: 'stretch',
    paddingLeft: ClinTheme.space[7],
    height: '26px'
  }
})

export const StyledDivider = styled.div<IStyledClinNavBarProps>(
  {
    height: '64px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    boxShadow: `0 2px 4px 0px rgba(0,0,0,0.16)`
  },
  ({ isMenuOpen }) => ({
    display: isMenuOpen ? 'initial' : 'none'
  })
)

export const StyledLogoLink = styled(Link)({
  display: 'flex',
  alignItems: 'flexStart',
  marginRight: 'auto',
  [mediaQuery(xLargeUpBreakpoint)]: {
    marginRight: 'initial',
    alignItems: 'center'
  }
})

export const StyledLogoButton = styled(ClinButton)({
  display: 'flex',
  alignItems: 'flexStart',
  marginRight: 'auto',
  [mediaQuery(xLargeUpBreakpoint)]: {
    marginRight: 'initial',
    alignItems: 'center'
  }
})

export const StyledLogoLinkInner = styled.span({})

export const StyledLogo = styled(ClinLogo)({})

export const StyledMobileLogo = styled(ClinMobileLogo)({})

export const StyledNavItemsContainer = styled.div<IStyledClinNavBarProps>(
  {
    display: 'none',
    backgroundColor: ClinTheme.colors.primaryDark,
    color: ClinTheme.colors.primary,
    zIndex: ClinTheme.zIndices.dropdown,

    [mediaQuery(xLargeUpBreakpoint)]: {
      display: 'flex',
      alignItems: 'left',
      justifyContent: 'flex-start',
      paddingTop: ClinTheme.space[2],
      backgroundColor: ClinTheme.colors.primaryDark
    }
  },
  ({ isMenuOpen }) => ({
    display: isMenuOpen ? 'initial' : 'none',
    position: isMenuOpen ? 'absolute' : 'initial',
    top: 'auto',
    height: isMenuOpen ? '750px' : 'auto',
    marginLeft: isMenuOpen ? 'initial' : 'auto',
    order: isMenuOpen ? 1 : 0,
    width: isMenuOpen ? '100%' : 'initial',
    left: isMenuOpen ? 0 : 'initial',
    marginTop: isMenuOpen ? '-1px' : 0,
    boxShadow: isMenuOpen ? ClinTheme.shadows[0] : 'none',
    [mediaQueryDown(xLargeUpBreakpoint)]: {
      color: ClinTheme.colors.midGrey,
      [StyledClinDropdownContainer]: {
        position: isMenuOpen ? 'relative' : 'absolute',
        marginTop: isMenuOpen ? 0 : ClinTheme.space[3],
        width: isMenuOpen ? '100%' : 'initial'
      },
      [StyledClinDropdown]: {
        display: isMenuOpen ? 'block' : 'initial',
        width: isMenuOpen ? '100%' : 'initial',
        textAlign: isMenuOpen ? 'center' : 'initial',
        margin: isMenuOpen ? 0 : 'initial'
      },
      [StyledClinDropdownMenu]: {
        marginTop: isMenuOpen ? 0 : 'initial',
        width: isMenuOpen ? '100%' : 'initial',
        boxShadow: 'none',
        [`a`]: {
          padding: `${ClinTheme.space[3]}px 0`
        }
      }
    }
  })
)

export const StyledNavIconsContainer = styled.div({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',

  // Remove the end padding from the last nested icon
  [mediaQueryDown(ClinTheme.breakpoints[0])]: {
    justifyContent: 'start',
    display: 'none',
    marginTop: '15px'
  },
  [betweenQueryTooltip(ClinTheme.breakpoints[0], xLargeUpBreakpoint)]: {
    justifyContent: 'end'
  },
  [mediaQuery(xLargeUpBreakpoint)]: {
    'div.clin-dropdown:last-child > .clin-navicon-btn': {
      paddingRight: 0
    },
    [`.clin-badge.notifications`]: {
      left: '16px !important'
    },
    [`.clin-badge.basket`]: {
      top: '0 !important',
      left: '18px !important'
    }
  }
})

export const StyledBurgerButton = styled.div({
  cursor: 'pointer',
  order: 0,
  marginRight: ClinTheme.space[2],
  zIndex: ClinTheme.zIndices.dropdown,
  [mediaQuery(xLargeUpBreakpoint)]: {
    display: 'none'
  }
})

export const StyledMainItemsName = styled.span({
  display: 'none',

  [mediaQuery(ClinTheme.breakpoints[2])]: {
    display: 'inline'
  }
})

export const StyledBasketCounter = styled.span({
  position: 'absolute',
  width: '27px',
  bottom: '5px',
  fontSize: '11px',
  justifyContent: 'center',
  display: 'flex',
  [mediaQueryDown(xLargeUpBreakpoint)]: {
    width: '32px'
  }
})

export const StyledSecondNavWrapper = styled.nav({
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '1140px'
})

export const StyledHeaderWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  [mediaQueryDown(ClinTheme.breakpoints[0])]: {
    flexDirection: 'row',
    justifyContent: 'start'
  }
})

export const StyledInstitutionToolTip = styled.span({
  position: 'relative'
})

export const StyledBackButton = styled.div({
  cursor: 'pointer',
  order: 0,
  zIndex: ClinTheme.zIndices.dropdown,
  color: ClinTheme.colors.midGrey,
  display: 'flex',
  fontSize: '14px',
  lineHeight: '18.2px',
  fontWeight: '700',
  padding: '16px 24px 0px 24px',
  [`.clin-icon`]: {
    height: '10px',
    width: '10px',
    marginTop: '4px'
  }
})

export const StyledLanguageButton = styled.div({
  cursor: 'pointer',
  order: 0,
  marginRight: ClinTheme.space[2],
  zIndex: ClinTheme.zIndices.dropdown,
  display: 'flex',
  fontSize: '14px',
  lineHeight: '18.2px',
  fontWeight: '400',
  marginLeft: 'initial',
  color: ClinTheme.colors.midGrey,
  paddingTop: '40px',
  [`.earth-icon`]: {
    height: 'auto',
    marginTop: '-1px',
    paddingLeft: '24px',
    paddingRight: '8px'
  },
  [`.clin-icon`]: {
    height: '10px',
    marginTop: '5px'
  }
})

export const StyledLanguageOption = styled.div({
  cursor: 'pointer',
  order: 0,
  marginRight: ClinTheme.space[2],
  zIndex: ClinTheme.zIndices.dropdown,
  display: 'flex',
  fontSize: '18px',
  lineHeight: '23.4px',
  fontWeight: '400',
  paddingLeft: '24px',
  color: ClinTheme.colors.midGrey,
  paddingTop: '40px'
})
