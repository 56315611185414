import { useEffect, useState } from 'react'

import {
  getDispatchTimeMessage,
  getVariantForSkuGivenCountry,
  getStockLevelsForWarehouse,
  IStockLevel
} from './PortfolioJourney.model'
import { UIVariationState } from './PortfolioJourney.types'
import { useAppContext } from '../../context/app'
import { IViewBasket } from '../../context/basket'
import {
  InstituteDto,
  PortfolioRuleDto,
  StockDto,
  WarehouseDto
} from '../../types/swaggerTypes'

/**
 * Custom hook to retrieve the portfolio variant
 * @param portfolioRules
 * @param shipToCountryCode
 */
export const usePortfolioVariant = (
  portfolioRules: PortfolioRuleDto[] | undefined,
  shipToCountryCode?: string
): { uiVariationState?: UIVariationState; endpoint?: number } => {
  const [uiState, setUIState] = useState<UIVariationState | undefined>(
    undefined
  )
  const [endpoint, setEndpoint] = useState<number>()
  useEffect(() => {
    if (shipToCountryCode && portfolioRules && portfolioRules.length > 0) {
      const uiVariation = getVariantForSkuGivenCountry(
        portfolioRules,
        shipToCountryCode
      )
      const { uiVariationState, endpoint } = uiVariation
      setUIState(uiVariationState)
      setEndpoint(endpoint)
    }
  }, [portfolioRules, shipToCountryCode])

  return {
    uiVariationState: uiState,
    endpoint
  }
}

/**
 * Custom hook to determine available stock and colour
 * @param warehouseCode
 * @param stockLevels
 * @param variant
 */
export const useStockLevels = (
  warehouseCode?: string | null,
  stockLevels?: StockDto[],
  variant?: UIVariationState
): IStockLevel | undefined => {
  const [stockLevel, setStockLevel] = useState<IStockLevel | undefined>(
    undefined
  )

  const { portfolioCountryCode } = useAppContext()
  useEffect(() => {
    if (variant && stockLevels && stockLevels.length > 0 && warehouseCode) {
      setStockLevel(
        getStockLevelsForWarehouse(
          portfolioCountryCode,
          warehouseCode,
          stockLevels,
          variant
        )
      )
    }
  }, [warehouseCode, stockLevels, variant, portfolioCountryCode])

  return stockLevel
}

export const useDefaultShipCountry = (
  institute: InstituteDto | undefined
): string | undefined => {
  const [country, setCountry] = useState<string | undefined>(undefined)
  // Get country from default a ship address from institute
  useEffect(() => {
    if (institute) {
      const defaultShip = institute.shipTo.find(
        (add) => add.primaryFlag === 'Y'
      )
      if (defaultShip) {
        setCountry(defaultShip.country)
      }
    }
  }, [institute])
  return country
}

export const useDispatchInformation = (
  warehouse: WarehouseDto | undefined,
  stockLevels: StockDto[] | undefined,
  portfolioRules?: PortfolioRuleDto[],
  institute?: InstituteDto | undefined
): string => {
  const [message, setMessage] = useState<string>('')
  const [countryCode, setCountryCode] = useState<string | undefined>()
  const [uiState, setUiState] = useState<UIVariationState>()
  // Get country from default a ship address from institute
  useEffect(() => {
    if (institute) {
      const defaultShipInst = institute.shipTo.find(
        (inst) => inst.primaryFlag === 'Y'
      )
      defaultShipInst && setCountryCode(defaultShipInst.country)
    }
    if (countryCode && portfolioRules) {
      setUiState(
        getVariantForSkuGivenCountry(portfolioRules, countryCode)
          .uiVariationState
      )
    }
    if (uiState && warehouse && stockLevels && stockLevels.length > 0) {
      setMessage(getDispatchTimeMessage(warehouse, stockLevels, uiState))
    }
  }, [warehouse, stockLevels, institute, portfolioRules, countryCode, uiState])
  return message
}

export const useBasketHasAtLeastOneUnavailableItem = (
  viewBasket: IViewBasket | undefined
): boolean => {
  const [bool, setBool] = useState<boolean>(false)
  useEffect(() => {
    if (viewBasket) {
      setBool(viewBasket.items.filter((item) => item.unavailable).length > 0)
    }
  }, [viewBasket])
  return bool
}
