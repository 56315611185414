import styled from 'styled-components'

import { IGroupStyles } from './ClinGroup'
import { ClinTheme } from '../../ClinTheme'

export const StyledGroup = styled.div<IGroupStyles>(
  {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'flex-end',
    width: '100%'
  },
  ({ space, justifyContent, alignItems, direction, display }) => ({
    justifyContent,
    alignItems,
    flexDirection: direction,
    display: display,
    '> *:nth-child(n)': {
      margin:
        direction === 'row'
          ? `${ClinTheme.space[0]}px ${space}px`
          : `${space}px ${ClinTheme.space[0]}px`
    },

    '> *:first-child': {
      marginLeft: '0px'
    },

    '> *:last-child': {
      marginRight: '0px'
    }
  })
)
