import React from 'react'

import {
  StyledClinConfirmation,
  StyledClinConfirmationBox,
  IClinConfirmationStyles
} from './ClinConfirmation.styles'
import { IConfirmation } from '../../types/IConfirmation'

interface IClinConfirmationProps extends IClinConfirmationStyles {
  confirmation: IConfirmation
}

export const ClinConfirmation = React.forwardRef<
  HTMLDivElement,
  IClinConfirmationProps
>(function ClinConfirmation({ confirmation }, forwardedRef) {
  return (
    <StyledClinConfirmation ref={forwardedRef}>
      <StyledClinConfirmationBox confirmation={confirmation}>
        {confirmation.children}
      </StyledClinConfirmationBox>
    </StyledClinConfirmation>
  )
})
