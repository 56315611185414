import { AxiosError } from 'axios'
import { useState } from 'react'

import { associatePhysician } from '../../../../services/ApiService'
import { PhysiciansEnrolledSummaryDto } from '../../../../types/swaggerTypes'

export enum AssociateMode {
  active,
  submitting,
  hasError
}

export const useAssociateModal = (
  selectedPhysician: PhysiciansEnrolledSummaryDto | undefined,
  handleBeginProductOrder: () => void
) => {
  const [associateMode, setAssociateMode] = useState<
    AssociateMode | undefined
  >()
  const [associateError, setAssociateError] = useState<AxiosError | undefined>()
  const [associateConfirmation, setAssociateConfirmation] =
    useState<boolean>(false)

  const handleConfirmationSelect = (confirmed: boolean) => {
    setAssociateConfirmation(confirmed)
  }

  const handleConfirmation = () => {
    selectedPhysician &&
      associatePhysician(selectedPhysician.physicianId.toString())
        .then((response) => {
          if (response.status === 200) {
            setAssociateMode(undefined)
            associateConfirmation && handleBeginProductOrder()
          }
        })
        .catch((error: AxiosError) => {
          setAssociateError(error)
        })
  }

  const handleAssociateClose = () => {
    setAssociateMode(undefined)
    setAssociateError(undefined)
    setAssociateConfirmation(false)
  }

  const handleAssociateOpen = () => {
    setAssociateMode(AssociateMode.active)
  }

  return {
    associateMode,
    associateConfirmation,
    associateError,
    handleConfirmation,
    handleAssociateOpen,
    handleAssociateClose,
    handleConfirmationSelect
  }
}
