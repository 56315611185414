import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinButtonIcon } from '../ClinButtonIcon'
import { mediaQueryDown } from '../../utils/mediaQuery'

export const ANIMATION_DURATION = 225

const mobileUpBreakpoint = ClinTheme.breakpoints[2]

export const Overlay = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  opacity: isOpen ? 1 : 0,
  visibility: isOpen ? 'visible' : 'hidden',
  transition:
    'opacity ' +
    ANIMATION_DURATION +
    'ms cubic-bezier(0.4, 0, 0.2, 1), ' +
    'visibility ' +
    ANIMATION_DURATION +
    'ms cubic-bezier(0.4, 0, 0.2, 1)',
  zIndex: 1200
}))

export const DrawerContainer = styled.div<{ isOpen: boolean; width?: number }>(
  ({ isOpen, width }) => ({
    position: 'fixed',
    top: 0,
    right: 0,
    width: width ?? '300px',
    height: '100%',
    backgroundColor: ClinTheme.colors.white,
    boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.3)',
    transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
    transition:
      'transform ' + ANIMATION_DURATION + 'ms cubic-bezier(0.4, 0, 0.2, 1)',
    zIndex: 1300,
    [mediaQueryDown(mobileUpBreakpoint)]: {
      width: '100%'
    }
  })
)

export const Header = styled.div({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  padding: '8px',
  height: '48px',
  boxSizing: 'border-box'
})

export const StyledClinButtonIcon = styled(ClinButtonIcon)({
  padding: '4px',

  '&:focus, &:hover': {
    boxShadow: 'none',
    backgroundColor: 'rgba(0,0,0,0.04)'
  }
})
