import React, { FunctionComponent } from 'react'
import { NavLink } from 'react-router-dom'

import {
  IDropdownNavButtonStyles,
  StyledDropdownUpperNavButton
} from './DropdownNavButton.styles'

interface IDropdownNavButtonProps extends IDropdownNavButtonStyles {}

export const DropdownUpperNavButton: FunctionComponent<
  IDropdownNavButtonProps
> = (props) => {
  // render a standard link if this is external
  const isExternalLink = props.to.toString().startsWith('http')
  const isSamePage = props.to.toString() === '#'

  return (
    <StyledDropdownUpperNavButton
      as={isExternalLink ? 'a' : isSamePage ? 'div' : NavLink}
      href={props.to.toString()}
      target={isExternalLink ? '_blank' : '_self'}
      tabIndex={props.disabled ? -1 : props.tabIndex || 0}
      disabled={props.disabled}
      onClick={props.onClick}
      {...props}
    >
      {props.children}
    </StyledDropdownUpperNavButton>
  )
}
