import { rgba } from 'polished'
import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { mediaQuery, mediaQueryDown } from '../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export interface IStyledOpaOrderItemProps {
  hasBorder: boolean
  isShowingProductDetails: boolean
}

export const StyledOpaOrderItem = styled.div<IStyledOpaOrderItemProps>(
  {},
  ({ hasBorder, isShowingProductDetails }) => ({
    paddingLeft: ClinTheme.space[5],
    paddingRight: ClinTheme.space[5],
    paddingTop: ClinTheme.space[4],
    paddingBottom: ClinTheme.space[0],
    borderBottom: hasBorder
      ? `1px ${rgba(ClinTheme.colors.green, 0.6)} solid`
      : 0,
    [`.order-data`]: {
      marginBottom: isShowingProductDetails
        ? ClinTheme.space[6]
        : ClinTheme.space[4],
      [mediaQueryDown(mobileUpBreakpoint)]: {
        marginBottom: ClinTheme.space[3]
      },
      [`@media print`]: {
        display: 'block !important'
      }
    },
    [`> div > div:first-of-type`]: {
      '.clin-text': {
        textAlign: 'left'
      }
    },
    [`.accordion button > span`]: {
      fontSize: ClinTheme.fontSizes[2],
      fontWeight: ClinTheme.fontWeights.medium,
      color: ClinTheme.colors.black
    },
    [`.accordion-panel`]: {
      width: '100%'
    },
    [`.accordion__item`]: {
      border: 0,
      marginBottom: ClinTheme.space[0]
    }
  })
)

export const StyledOpaOrderItemDetailMobileLabel = styled.div({
  display: 'block',
  marginRight: ClinTheme.space[3],
  [mediaQuery(mobileUpBreakpoint)]: {
    display: 'none'
  },
  [`@media print`]: {
    display: 'block'
  }
})

export const StyledOpaOrderItemDetailValue = styled.div({
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(mobileUpBreakpoint)]: {
    display: 'block'
  },
  [`.clin-text`]: {
    textAlign: 'left',
    paddingBottom: ClinTheme.space[2],
    [mediaQuery(mobileUpBreakpoint)]: {
      textAlign: 'right'
    }
  },
  [`@media print`]: {
    display: 'block',
    [`.clin-text`]: {
      textAlign: 'left'
    }
  }
})

export const StyledOpaOrderItemStatus = styled.div({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: ClinTheme.space[3],
  [mediaQuery(mobileUpBreakpoint)]: {
    paddingBottom: ClinTheme.space[4]
  }
})

export const StyledOpaOrderItemDocs = styled.div({
  borderTop: `1px ${ClinTheme.colors.grey} solid`,
  paddingTop: ClinTheme.space[0],
  paddingBottom: ClinTheme.space[2],
  marginTop: ClinTheme.space[2]
})

export const StyledOpaOrderItemName = styled(ClinText)({
  fontSize: ClinTheme.fontSizes[2],
  fontWeight: ClinTheme.fontWeights.normal,
  color: ClinTheme.colors.black,
  textAlign: 'left',
  wordBreak: 'break-word'
})

export const StyledHoldFileItem = styled.div({
  backgroundColor: ClinTheme.colors.lightGrey,
  borderTop: '1px solid ' + ClinTheme.colors.grey,
  padding: `${ClinTheme.space[4]}px ${ClinTheme.space[3]}px`,
  [`:last-child`]: {
    borderBottom: '1px solid ' + ClinTheme.colors.grey
  },
  [`.sku-information, .warehouse-address`]: {
    flexBasis: '100%'
  }
})
