import React, { Fragment, FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { locationsSortFn } from './ContactUs.model'
import { StyledContactCard, StyledContactName } from './ContactUs.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { UserRole, isAusGaUser } from '../../constants'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  BasicContentBlock,
  ContactUsTemplate,
  LocationContactBlock,
  Maybe
} from '../../types/ContentTypes'

interface IContactUsProps {
  content: ContactUsTemplate
  userCountry: string
  userRole?: UserRole
}

const formatPhoneNumber = (
  phone: Maybe<string> | undefined
): Maybe<string> | undefined => {
  if (!phone) return

  const index = phone.indexOf('(0)')
  if (index !== -1 && phone.indexOf('(0) ') === -1) {
    phone = phone.replace('(0)', '(0) ')
  }

  return phone?.trim()
}

export const ContactUs: FunctionComponent<IContactUsProps> = ({
  content,
  userCountry,
  userRole
}) => {
  const { t } = useTranslation()
  const { enableNewHomePage } = useCheckUnableHomePage()

  return (
    <ClinPageContentFrame
      className="cms"
      crumbs={[
        isAusGaUser(userCountry, userRole) && enableNewHomePage
          ? {
              path: '/home',
              name: t('navigation:home')
            }
          : { path: '/orders', name: t('orders:page_name') },
        {
          path: '/about/contact-us',
          name: content?.flatData?.title ? content?.flatData?.title : ''
        }
      ]}
    >
      <Row>
        <Col>
          <ClinText
            as="h1"
            fontWeight={ClinTheme.fontWeights.bold}
            variant={TypographyVariant.H1}
          >
            {content?.flatData?.title}
          </ClinText>
        </Col>
      </Row>
      <Row>
        <Col>
          {content?.flatData?.content?.map((item: BasicContentBlock) => {
            return (
              <Fragment key={`about-content-${item.id}`}>
                {item.flatData.headline && (
                  <Row>
                    <Col sm={12}>
                      <ClinText
                        as="h2"
                        fontWeight={ClinTheme.fontWeights.bold}
                        variant={TypographyVariant.H2}
                      >
                        {item.flatData.headline}
                      </ClinText>
                    </Col>
                  </Row>
                )}
                <Row key={`about-us-${item.id}`}>
                  <Col sm={12} md={10} lg={8}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${item.flatData.paragraph}`
                      }}
                    />
                  </Col>
                </Row>
                <ClinSpacer />
              </Fragment>
            )
          })}
        </Col>
      </Row>
      <Row>
        <Col>
          {content?.flatData?.locations &&
            content.flatData.locations.length > 0 &&
            content.flatData.locations
              .slice()
              .sort(locationsSortFn)
              .map((item: LocationContactBlock) => {
                const contactNumber = formatPhoneNumber(
                  item.flatData.contactNumber
                )
                const contactFax = formatPhoneNumber(item.flatData.contactFax)
                return (
                  <Fragment key={`about-content-${item.id}`}>
                    <Row key={`about-us-${item.id}`}>
                      <Col sm={12} lg={3}>
                        <StyledContactName>
                          <ClinText
                            fontWeight={ClinTheme.fontWeights.bold}
                            as="h2"
                            variant={TypographyVariant.H3}
                          >
                            {item.flatData.locationName}
                          </ClinText>
                        </StyledContactName>
                      </Col>
                      <Col sm={12} lg={5}>
                        <StyledContactCard>
                          <ClinGroup
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <ClinIcon
                              iconName={ClinIconPathName.Phone}
                              style={{ marginRight: 0 }}
                            />
                            <ClinButton
                              variant="link"
                              style={{
                                marginLeft: `${ClinTheme.space[2]}px`,
                                height: `${ClinTheme.space[5]}px`
                              }}
                              onClick={(event) => {
                                event.preventDefault()
                                window.location.href = `tel:${contactNumber}`
                                analyticsServiceSingleton.trackEvent(
                                  AnalyticsEvent.ContactClicked,
                                  { contactType: 'telephone' }
                                )
                              }}
                            >
                              {contactNumber}
                            </ClinButton>
                          </ClinGroup>
                          <ClinGroup
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <ClinIcon
                              iconName={ClinIconPathName.Print}
                              style={{ marginRight: 0 }}
                            />
                            <ClinButton
                              variant="link"
                              style={{
                                marginLeft: `${ClinTheme.space[2]}px`,
                                height: `${ClinTheme.space[5]}px`
                              }}
                              onClick={(event) => {
                                event.preventDefault()
                                window.location.href = `tel:${contactFax}`
                                analyticsServiceSingleton.trackEvent(
                                  AnalyticsEvent.ContactClicked,
                                  { contactType: 'telephone' }
                                )
                              }}
                            >
                              {contactFax}
                            </ClinButton>
                          </ClinGroup>
                          <ClinGroup
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <ClinIcon
                              iconName={ClinIconPathName.Mail}
                              style={{ marginRight: 0 }}
                            />
                            <ClinButton
                              variant="link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`mailto:${item.flatData.contactEmail}`}
                              style={{
                                marginLeft: `${ClinTheme.space[2]}px`,
                                height: `${ClinTheme.space[5]}px`
                              }}
                              onClick={(event) => {
                                event.preventDefault()
                                window.location.href = `mailto:${item.flatData.contactEmail}`
                                analyticsServiceSingleton.trackEvent(
                                  AnalyticsEvent.ContactClicked,
                                  { contactType: 'email' }
                                )
                              }}
                            >
                              {content?.flatData?.sendEmailBtnText}
                            </ClinButton>
                          </ClinGroup>
                        </StyledContactCard>
                      </Col>
                    </Row>
                    <ClinSpacer height={ClinTheme.space[5]} />
                  </Fragment>
                )
              })}
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
