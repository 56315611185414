import { t } from 'i18next'
import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'

import {
  StyledOnboardingLineItem,
  IOnboardingLineItemStyles
} from './OnboardingLineItem.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../ClinText'

interface IOnboardingLineItemProps extends IOnboardingLineItemStyles {
  itemKey: string
  itemValue: string
}

export const OnboardingLineItem: FunctionComponent<
  IOnboardingLineItemProps
> = ({ itemKey, itemValue }) => {
  return (
    <StyledOnboardingLineItem>
      <Row>
        <Col xs={12} md={6}>
          <ClinText fontSize={ClinTheme.fontSizes[2]}>{t(itemKey)}</ClinText>
        </Col>
        <Col xs={12} md={6}>
          <ClinText fontSize={ClinTheme.fontSizes[2]}>{itemValue}</ClinText>
        </Col>
      </Row>
    </StyledOnboardingLineItem>
  )
}
