import { Row } from 'react-grid-system'
import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'
import { StyledAccordion } from '../../../components/ClinAccordion/ClinAccordion.styles'
import { ClinButton } from '../../../components/ClinButton'
import { StyledClinText } from '../../../components/ClinText/ClinText.styles'
import { ProgramStatus, ProgramCountryStatus } from '../../../constants'
import { mediaQuery, mediaQueryDown } from '../../../utils/mediaQuery'
export interface IProgramDetailStyles {
  /** Selected program status */
  status?: ProgramStatus | ProgramCountryStatus
}

const MediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledProgramDetailSidebar = styled.div({
  padding: ClinTheme.space[5],
  background: ClinTheme.colors.lightGrey
})

export const StyledProgramDetailSidebarInner = styled.div({})

export const StyledProgramDetailSidebarTimes = styled.div({
  marginTop: ClinTheme.space[5],
  padding: ClinTheme.space[5],
  background: ClinTheme.colors.lightGrey
})

export const StyledContact = styled.div({
  display: 'flex',
  marginBottom: ClinTheme.space[3],
  [`.clin-text`]: {
    marginLeft: ClinTheme.space[2]
  },
  '.clin-icon': {
    verticalAlign: 'middle'
  }
})

export const StyledContactLink = styled.a({
  fontSize: ClinTheme.fontSizes[0],
  marginRight: ClinTheme.space[3],
  marginBottom: ClinTheme.space[2],
  lineHeight: '2em',
  span: {
    display: 'block',
    [`:hover`]: {
      textDecoration: 'underline'
    }
  }
})

export const StyleDocumentationDetails = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: ClinTheme.space[3],
  width: '100%',

  [mediaQuery(MediumUpBreakpoint)]: {
    flexDirection: 'row'
  }
})

export const StyledDocumentCard = styled.div({
  display: 'flex',
  flex: '0 0 auto',
  flexDirection: 'column',
  marginBottom: ClinTheme.space[5],
  paddingBottom: ClinTheme.space[5],
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`
})

export const StyledDocumentCardInner = styled.div({
  display: 'flex',
  flex: '0 0'
})

export const StyledProgramDetail = styled.div({
  paddingTop: ClinTheme.space[3],
  [`${StyledAccordion}`]: {
    [`${StyledDocumentCard}:last-of-type`]: {
      borderBottom: 'none',
      paddingBottom: ClinTheme.space[5]
    }
  }
})

export const StyleDocumentationDetail = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
})

export const StyleDocumentationDetailButton = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginTop: ClinTheme.space[4],

  [mediaQuery(MediumUpBreakpoint)]: {
    marginTop: 0
  }
})

export const StyledNoProgramDocuments = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: ClinTheme.space[4],

  [mediaQuery(MediumUpBreakpoint)]: {
    paddingBottom: ClinTheme.space[6]
  },

  [`${StyledClinText}`]: {
    fontSize: ClinTheme.fontSizes[3],
    fontWeight: ClinTheme.fontWeights.bold,

    [mediaQuery(MediumUpBreakpoint)]: {
      fontSize: ClinTheme.fontSizes[4]
    }
  }
})

export const StyledProgramAccordion = styled.div({
  '.clin-icon-title, .clin-icon-value': {
    flex: '0 0 50%'
  },

  '#sect-program-details-header button': {
    borderTop: 'none',
    paddingTop: 0
  }
})

export const StyledTabButtonWrapper = styled.div<IStyledTabButtonProps>(
  {
    flex: '1',
    textAlign: 'center'
  },
  ({ active }) => ({
    borderBottom: active ? '2px solid #5E5ADB' : 'none'
  })
)

export const StyledTabSection = styled.div<IStyledTabButtonProps>(
  {
    display: 'flex',
    padding: '6px 4px 0px 4px',
    gap: `${ClinTheme.space[3]}px`,
    alignItems: 'center',
    background: 'white',
    borderTopLeftRadius: `${ClinTheme.space[2]}px`,
    borderTopRightRadius: `${ClinTheme.space[2]}px`,
    overflow: 'auto',

    [mediaQueryDown(ClinTheme.breakpoints[0])]: {
      overflow: 'scroll',
      borderBottomLeftRadius: `${ClinTheme.space[1]}px`,
      borderBottomRightRadius: `${ClinTheme.space[1]}px`
    }
  },
  ({ selectedTabIndex }) => ({
    borderBottom:
      selectedTabIndex === 0 || selectedTabIndex === 2
        ? `1px solid rgba(0, 0, 0, 0.08)`
        : 'none',
    borderBottomLeftRadius:
      selectedTabIndex === 0 || selectedTabIndex === 2
        ? 0
        : `${ClinTheme.space[1]}px`,
    borderBottomRightRadius:
      selectedTabIndex === 0 || selectedTabIndex === 2
        ? 0
        : `${ClinTheme.space[1]}px`
  })
)

interface IStyledTabButtonProps {
  active?: boolean
  selectedTabIndex?: number
}

export const StyledTabButton = styled(ClinButton)<IStyledTabButtonProps>(
  {
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: 1.43,
    textDecoration: 'none',
    paddingTop: `${ClinTheme.space[2]}px`,
    paddingBottom: '10px'
  },
  ({ active }) => ({
    color: active ? '#5E5ADB' : ClinTheme.colors.darkGrey
  })
)

export const StyledTabBedge = styled.span<IStyledTabButtonProps>(
  {
    marginLeft: '10px',
    fontSize: '10px',
    fontWeight: ClinTheme.fontWeights.medium,
    lineHeight: 1.6,
    letterSpacing: '0.3px',
    display: 'flex',
    padding: '0px 6px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '13px',
    background: '#EDEDFC'
  },
  ({ active }) => ({
    color: active ? '#5E5ADB' : ClinTheme.colors.darkGrey
  })
)

interface IDocumentWrapperProps {
  background?: string
  showTableInTab?: boolean
}

export const StyledDocumentSearchDivWrapper = styled.div<IDocumentWrapperProps>(
  {},
  ({ background, showTableInTab }) => ({
    background: background ?? `${ClinTheme.colors.white}`,
    borderBottomLeftRadius: showTableInTab ? `${ClinTheme.space[2]}px` : 0,
    borderBottomRightRadius: showTableInTab ? `${ClinTheme.space[2]}px` : 0,
    [mediaQueryDown(ClinTheme.breakpoints[0])]: {
      borderTopLeftRadius: showTableInTab ? `${ClinTheme.space[2]}px` : 0,
      borderTopRightRadius: showTableInTab ? `${ClinTheme.space[2]}px` : 0
    }
  })
)

export const StyledDocumentSearchWrapper = styled(Row)<IDocumentWrapperProps>(
  {
    display: 'flex',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    maxWidth: '1140px'
  },
  ({ showTableInTab }) => ({
    paddingTop: showTableInTab ? `${ClinTheme.space[3]}px` : '37px',
    paddingBottom: showTableInTab ? `${ClinTheme.space[3]}px` : '27px'
  })
)

export const StyledDocumentSearchContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%'
})

export const StyledStatusButtonWrapper = styled(
  ClinButton
)<IDocumentWrapperProps>(
  {
    padding: '2px 8px',
    borderRadius: `${ClinTheme.space[2]}px`,
    fontSize: ClinTheme.fontSizes[0],
    fontWeight: ClinTheme.fontWeights.medium,
    lineHeight: '1.5',
    textTransform: 'initial',
    letterSpacing: '0'
  },
  ({ background, color }) => ({
    background: background,
    color: color,
    borderColor: background
  })
)

export const StyledAddPhysicianTextWrapper = styled.div({
  background: ClinTheme.colors.white,
  padding: '13px 0px 11px 0px',
  textAlign: 'center',
  borderRadius: `${ClinTheme.space[1]}px`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

export const StyledAddPhysicianButton = styled(ClinButton)({
  marginLeft: `${ClinTheme.space[3]}px`,
  width: '176px',
  textTransform: 'initial'
})

export const StyledHeader = styled.div({
  background: ClinTheme.colors.white,
  border: '1px solid',
  borderColor: ClinTheme.colors.midGrey
})

export const StyledHeaderItems = styled.div({
  alignItems: 'top',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  paddingRight: `${ClinTheme.space[3]}px`
})

export const StyledHeaderButtonWrapper = styled.div({
  display: 'flex',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    flexDirection: 'column',
    marginTop: '15px',
    alignItems: 'flex-start',
    width: '100%',
    gap: '8px'
  }
})

export const StyledHeaderItemsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: `${ClinTheme.space[5]}px 0px`,
  margin: '0px auto 0px',
  flexWrap: 'nowrap',
  width: '100%',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    flexDirection: 'column',
    marginBottom: '15px'
  }
})

export const StyledStockOrderButton = styled(ClinButton)({
  padding: `${ClinTheme.space[2]}px ${ClinTheme.space[6]}px`,
  textTransform: 'initial',
  lineHeight: `${ClinTheme.space[3]}px`
})

export const StyledCreateButton = styled(ClinButton)({
  marginLeft: `${ClinTheme.space[3]}px`,
  textTransform: 'initial',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    marginLeft: '0px',
    marginTop: '10px'
  }
})

export const StyledEnrolButton = styled(ClinButton)({
  width: '112px',
  textTransform: 'initial',
  lineHeight: ClinTheme.lineHeights.largeParagraph
})

export const StyledProgramAttributes = styled.div({
  minHeight: '121px',
  background: ClinTheme.colors.white,
  border: '1px solid #E7E8E8',
  borderRadius: '10px',
  display: 'flex',
  flexWrap: 'wrap'
})

interface IProgramAttributeCardWrapperProps {
  width?: string
}

export const StyledProgramAttributesCardWrapper =
  styled.div<IProgramAttributeCardWrapperProps>(
    {
      display: 'flex',
      alignItems: 'center',
      minWidth: '15%'
    },
    ({ width }) => ({
      width: width ?? '25%',
      [mediaQueryDown(ClinTheme.breakpoints[2])]: {
        width: '50%'
      },
      [mediaQueryDown(ClinTheme.breakpoints[1])]: {
        width: '100%'
      }
    })
  )

export const StyledProgramAttributesCard = styled.div({
  padding: '20px',
  height: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '250px',

  '@media (max-width: 768px)': {
    maxWidth: '100%',
    whiteSpace: 'normal'
  }
})
export const StyledDocumentLoaderWrapper = styled.div({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: '7px'
})

export const StyledClinTableOrderToggleWrapper = styled.div`
  position: relative;
  font-size: 12px;
  color: ${ClinTheme.colors.black};
  & > div {
    font-size: 12px;
    color: ${ClinTheme.colors.black};
    align-items: end;
    position: relative;
    svg {
      position: absolute;
      top: 8px;
      right: -22px;
    }
  }
`

export const StyledClinPastTableOrderToggleWrapper = styled.div`
  & > div {
    font-size: 12px;
    color: ${ClinTheme.colors.black};
    position: relative;
    svg {
      position: absolute;
      right: -22px;
    }
  }
`
export const StyledTabsRow = styled(Row)({
  overflow: 'auto',

  [mediaQueryDown(ClinTheme.breakpoints[0])]: {
    overflow: 'scroll',
    marginBottom: '15px'
  }
})

export const SponsorLogo = styled.img({
  minWidth: '98px',
  height: '41px',
  marginLeft: `${ClinTheme.space[3]}px;`
})
