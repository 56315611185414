import { t } from 'i18next'
import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { Trans } from 'react-i18next'

import {
  StyledNoPatients,
  StyledNoPatientsIconText,
  StyledNoPatientsWrap
} from './NoPatients.styles'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinIcon } from '../../../../components/ClinIcon'
import { ClinIconPathName } from '../../../../components/ClinIcon/ClinIcon.paths'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'

interface NoPatientsProps {
  isAlternate?: boolean
  isResupply?: boolean
}

export const NoPatients: FunctionComponent<NoPatientsProps> = ({
  isAlternate,
  isResupply
}) => {
  return (
    <StyledNoPatients>
      <StyledNoPatientsWrap>
        <ClinIcon
          iconName={ClinIconPathName.TherapeuticArea}
          iconSize={150}
          iconFill={ClinTheme.colors.primaryLight}
        />

        <StyledNoPatientsIconText>?</StyledNoPatientsIconText>
      </StyledNoPatientsWrap>
      {isResupply ? (
        <Row>
          <Col>
            <ClinText
              textAlign={'center'}
              fontSize={ClinTheme.fontSizes[2]}
              fontWeight={ClinTheme.fontWeights.normal}
            >
              {t('patient_detail:no_patients_resupply')}
            </ClinText>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              {isAlternate ? (
                <ClinText
                  textAlign={'center'}
                  variant={TypographyVariant.H4}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  {t('patient_detail:no_patient_title')}
                </ClinText>
              ) : (
                <ClinText
                  textAlign={'center'}
                  variant={TypographyVariant.H4}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  {t('patient_detail:no_patients_alt_title')}
                </ClinText>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {isAlternate ? (
                <ClinText
                  textAlign={'center'}
                  fontSize={ClinTheme.fontSizes[2]}
                  fontWeight={ClinTheme.fontWeights.normal}
                >
                  <Trans
                    i18nKey={'patient_detail:no_patients_description'}
                    components={{
                      a: <a href={'/create-patient'}>Create Patient</a>
                    }}
                    values={{
                      link: '#'
                    }}
                    shouldUnescape={true}
                  ></Trans>
                </ClinText>
              ) : (
                <ClinText
                  textAlign={'center'}
                  fontSize={ClinTheme.fontSizes[2]}
                  fontWeight={ClinTheme.fontWeights.normal}
                >
                  <Trans
                    i18nKey={'patient_detail:no_patients_alt_desc'}
                  ></Trans>
                </ClinText>
              )}{' '}
            </Col>
          </Row>
        </>
      )}
    </StyledNoPatients>
  )
}
