import React, { FunctionComponent } from 'react'
import { Tooltip } from 'react-tippy'

import { tooltipStyles } from './StatusTooltip.styles'

interface IStatusTooltipProps {
  children: React.ReactNode
  tooltipText: string
  additionalClass?: string
}

const StatusTooltip: FunctionComponent<IStatusTooltipProps> = ({
  children,
  tooltipText,
  additionalClass = ''
}) => {
  return (
    <div style={tooltipStyles} className={additionalClass}>
      <Tooltip
        theme="light"
        title={tooltipText}
        position="bottom"
        trigger="mouseenter"
        arrow={true}
        style={{ width: '100%' }}
      >
        {children}
      </Tooltip>
    </div>
  )
}

export default StatusTooltip
