import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery, mediaQueryDown } from '../../../utils/mediaQuery'
import {
  ClinPatientTableHeaderCell,
  ClinTableRow,
  ClinTableBodyCell,
  ClinTable
} from '../../../components/ClinTable/ClinTable'
import { ClinText } from '../../../components/ClinText'
import { IClinTextProps } from '../../../components/ClinText/ClinText'
import { ClinTableToggleRowButton } from '../../../components/ClinTableToggleRowButton'

interface ClinTableRowStyledProps {
  background?: string
  isOpen?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const mobileUpBreakpoint = ClinTheme.breakpoints[0]
const tabletUpBreakpoint = ClinTheme.breakpoints[1]
const desktopUpBreakpoint = ClinTheme.breakpoints[2]

export const StyledProductHeader = styled.div({
  paddingBottom: ClinTheme.space[3],
  marginBottom: ClinTheme.space[5],
  borderBottom: `solid 1px ${ClinTheme.colors.midGrey}`,
  [`.clin-button`]: {
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[2]}px`
  },
  [`&.productHeaderAus`]: {
    paddingBottom: 0
  },
  [mediaQuery(tabletUpBreakpoint)]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

interface IProductCellProps {
  /** Css justify-content value */
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit'
}
export const StyledProductCell = styled.div<IProductCellProps>(
  {
    alignItems: 'center',
    [mediaQuery(tabletUpBreakpoint)]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  ({ justifyContent }: IProductCellProps) => ({
    display: justifyContent ? 'flex' : 'initial',
    justifyContent
  })
)

export const StyledClinPatientTableHeaderCell = styled(
  ClinPatientTableHeaderCell
)({
  cursor: 'default',
  padding: '24px 8px 8px',

  '&:first-child': {
    padding: '16px 8px 0 16px'
  },

  '&:last-child': {
    padding: '16px 16px 0 0'
  },

  '&:nth-last-child(2)': {
    paddingRight: 0
  },

  div: {
    display: 'block'
  }
})

export const StyledHeader = styled.div({
  background: ClinTheme.colors.white,
  border: '1px solid',
  borderColor: ClinTheme.colors.midGrey,
  marginBottom: '5px'
})

export const StyledHeaderContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [mediaQueryDown(mobileUpBreakpoint)]: {
    display: 'block',
    marginBottom: ClinTheme.space[3]
  }
})

export const StyledHeaderColumn = styled.div({
  maxWidth: '75%',
  [mediaQueryDown(mobileUpBreakpoint)]: {
    width: '100%',
    maxWidth: '100%'
  }
})

export const StyledHeaderTitle = styled(ClinText)<IClinTextProps>({
  fontFamily: ClinTheme.fonts.groteskBold,
  fontSize: ClinTheme.fontSizes[6],
  lineHeight: ClinTheme.lineHeights.heading[4]
})

export const StyledImageText = styled('p')({
  paddingTop: '7.5px',
  margin: '0',
  fontFamily: ClinTheme.fonts.body,
  fontSize: ClinTheme.fontSizes[0],
  color: 'rgba(0, 0, 0, 0.5)',
  fontWeight: ClinTheme.fontWeights.normal,
  lineHeight: '12px'
})

export const StyledImage = styled.img`
  display: flex;
  max-width: 257.24px;
  width: 100%;
  max-height: 257.24px;
  height: 100%;
`
export const StyledImageContainer = styled.div`
  display: block;
  max-width: 257.24px;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const StyledClinTableRow = styled(ClinTableRow)<ClinTableRowStyledProps>`
  border: 0;

  &:focus {
    background: ${ClinTheme.colors.white};
  }

  &.main-row.highlighted {
    position: relative;
    background: ${ClinTheme.colors.white};
    z-index: 2;
    border: 0;
    box-shadow: ${ClinTheme.shadows[3]};
  }

  &.main-row.expanded.highlighted::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 5px;
    background: ${ClinTheme.colors.white};
    z-index: 3;
  }
`

export const StyledClinTableExpandedRow = styled(
  ClinTableRow
)<ClinTableRowStyledProps>`
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 0;

  &:focus {
    background: ${ClinTheme.colors.white};
  }

  &.expanded-row.highlighted {
    position: relative;
    background: ${ClinTheme.colors.white};
    z-index: 1;
    box-shadow: ${(props) => props.isOpen && ClinTheme.shadows[3]};
  }

  & > td {
    padding-top: ${(props) => (props.isOpen ? '11px' : '0!important')};
    padding-bottom: ${(props) => (props.isOpen ? '11px' : '0!important')};
    transition: padding 0.6s;

    & > div {
      max-height: ${(props) => (props.isOpen ? '500px' : '0')};
      opacity: ${(props) => (props.isOpen ? 1 : 0)};
      visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
      transition: ${(props) =>
        props.isOpen
          ? 'max-height 0.6s ease, opacity 0.6s ease, visibility 0.6s'
          : 'max-height 0.6s ease, opacity 0.3s ease, visibility 0.3s'};
    }
  }
`

export const StyledProductInformationBox = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ProductInformationBox = styled.div`
  width: 100%;
`

export const StyledClinTableBodyCell = styled(ClinTableBodyCell)({
  padding: '8px 10px',

  '&:first-child': {
    padding: '8px 10px 8px 16px'
  },

  '&:last-child': {
    padding: '8px 16px 8px 0',
    textAlign: 'right',

    'button.icon-toggle': {
      margin: '4px',
      color: ClinTheme.colors.primaryMid,

      '&:hover svg path:first-child': {
        fill: ClinTheme.colors.primaryMid
      },

      '&:focus': {
        boxShadow: 'none'
      },

      '&:hover': {
        boxShadow: 'rgb(231, 232, 232) 0px 0px 0px 2px'
      }
    }
  },

  '&:nth-last-child(2)': {
    padding: '8px 0 8px 10px',

    div: {
      overflow: 'visible'
    }
  }
})
export const StyledClinTable = styled(ClinTable)<{ width?: string }>`
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  overflow: visible;
`
export const FixedWidthCell = styled.div<{
  width?: string
  minWidth?: string
  maxWidth?: string
}>`
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledDesktopWrapper = styled.div({
  width: '100%',
  overflowX: 'auto',
  overflow: 'visible',
  display: 'none',
  [mediaQuery(desktopUpBreakpoint)]: {
    display: 'block'
  }
})

export const StyledMobileWrapper = styled.div({
  width: '100%',
  overflowX: 'auto',
  overflow: 'visible',
  display: 'block',
  [mediaQuery(desktopUpBreakpoint)]: {
    display: 'none'
  }
})

export const StyledClinTableToggleRowButton = styled(ClinTableToggleRowButton)({
  margin: '4px'
})

export const StyledStrengthCell = styled('div')({
  marginTop: '6px',

  '&:first-child': {
    marginTop: 0
  }
})
