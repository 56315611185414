import { t } from 'i18next'
import React, { useEffect, useState } from 'react' // Import useEffect and useState

import {
  StyledModalContent,
  StyledModalHeader,
  StyledModalText,
  StyledCheckboxContainer,
  StyledCheckboxLabel,
  StyledButtonContainer,
  StyledAssociateButton,
  StyledCancelButton,
  StyledUlList
} from './PhysicianAssociationModal.styles'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinCheckbox } from '../../../../components/ClinCheckbox'
import { ClinModal } from '../../../../components/ClinModal'
import { ClinText } from '../../../../components/ClinText'

interface IPhysicianAssociationModalProps {
  isOpen: boolean
  handleAssociate: () => void
  handleClose: () => void
  physicianName: string | undefined
}

export const PhysicianAssociationModal: React.FC<
  IPhysicianAssociationModalProps
> = ({ isOpen, handleAssociate, handleClose, physicianName }) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

  // Reset checkbox when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setIsCheckboxChecked(false)
    }
  }, [isOpen]) // Listen for changes to isOpen

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(e.target.checked)
  }

  return (
    <ClinModal
      isOpen={isOpen}
      onClose={() => {
        handleClose()
        setIsCheckboxChecked(false) // Also reset when manually closing
      }}
      maxWidth="xs"
      height="auto"
      padding={`${ClinTheme.space[5]}px`}
      boxShadow="0px 0px 16px 0px rgba(0, 0, 0, 0.16)"
      borderRadius="4px"
    >
      <StyledModalContent>
        <StyledModalHeader>
          <ClinText lineHeight={ClinTheme.lineHeights.largeParagraph}>
            <strong>{physicianName}</strong>&nbsp;
            {t('patient_detail:physician_association_modal.modal_title')}
          </ClinText>
        </StyledModalHeader>
        <StyledModalText>
          <ClinText lineHeight={ClinTheme.lineHeights.body}>
            {t(
              'patient_detail:physician_association_modal.modal_description_start'
            )}{' '}
            <strong>{physicianName}</strong>{' '}
            {t(
              'patient_detail:physician_association_modal.modal_description_end'
            )}
          </ClinText>
        </StyledModalText>
        <StyledCheckboxContainer>
          <StyledCheckboxLabel>
            <ClinCheckbox
              id="agreementCheckbox"
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="agreementCheckbox">
              <ClinText
                fontSize={ClinTheme.fontSizes[0]}
                lineHeight={ClinTheme.lineHeights.largeParagraph}
              >
                {t(
                  'patient_detail:physician_association_modal.permission_confirmation_label'
                )}
              </ClinText>
              <StyledUlList>
                <li>
                  <ClinText
                    fontSize={ClinTheme.fontSizes[0]}
                    lineHeight={ClinTheme.lineHeights.largeParagraph}
                  >
                    {t(
                      'patient_detail:physician_association_modal.permission_confirmation_text_1'
                    )}
                  </ClinText>
                </li>
                <li>
                  <ClinText
                    fontSize={ClinTheme.fontSizes[0]}
                    lineHeight={ClinTheme.lineHeights.largeParagraph}
                  >
                    {t(
                      'patient_detail:physician_association_modal.permission_confirmation_text_2'
                    )}
                  </ClinText>
                </li>
                <li>
                  <ClinText
                    fontSize={ClinTheme.fontSizes[0]}
                    lineHeight={ClinTheme.lineHeights.largeParagraph}
                  >
                    {t(
                      'patient_detail:physician_association_modal.permission_confirmation_text_3'
                    )}
                  </ClinText>
                </li>
              </StyledUlList>
            </label>
          </StyledCheckboxLabel>
        </StyledCheckboxContainer>
        <StyledButtonContainer>
          <StyledCancelButton
            variant="purpleSecondaryNarrow"
            onClick={handleClose}
          >
            {t('patient_detail:physician_association_modal.cancel_button_text')}
          </StyledCancelButton>
          <StyledAssociateButton
            onClick={handleAssociate}
            variant="purpleNarrow"
            disabled={!isCheckboxChecked}
          >
            {t(
              'patient_detail:physician_association_modal.associate_button_text'
            )}
          </StyledAssociateButton>
        </StyledButtonContainer>
      </StyledModalContent>
    </ClinModal>
  )
}
