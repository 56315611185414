import React, { FunctionComponent } from 'react'

import {
  IHoldAnnounceStyles,
  StyledCloseButton,
  StyledHoldAnnounce,
  StyledMessage,
  StyledVerticalLine
} from './HoldAnnounce.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'

export enum HoldAnnounceMode {
  Error = 'Error',
  Information = 'Information'
}

export interface IHoldAnnounceProps extends IHoldAnnounceStyles {
  /** The title of the announce - defaults to the mode */
  title?: string
  /** The message of the announce */
  message?: string
  /** Determines if the close x is displayed */
  isDismissible?: boolean
  /** The mode of the announce */
  handleClose?: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const HoldAnnounce: FunctionComponent<IHoldAnnounceProps> = ({
  title,
  message,
  mode = HoldAnnounceMode.Error,
  isDismissible,
  marginBottom,
  children,
  handleClose
}) => (
  <StyledHoldAnnounce
    mode={mode}
    marginBottom={marginBottom}
    className="clin-announce"
  >
    <StyledVerticalLine mode={mode} />
    <ClinIcon
      iconName={ClinIconPathName.ExclamationOutline}
      iconFill={
        mode === HoldAnnounceMode.Error
          ? ClinTheme.colors.redInvalid
          : ClinTheme.colors.primaryMid
      }
    />
    {isDismissible && (
      <StyledCloseButton onClick={(event) => handleClose && handleClose(event)}>
        <ClinText
          variant={TypographyVariant.Paragraph}
          fontSize={ClinTheme.fontSizes[1]}
        >
          Close
        </ClinText>
      </StyledCloseButton>
    )}
    <StyledMessage>
      {title && (
        <ClinText as="div" fontWeight={ClinTheme.fontWeights.bold}>
          {title}
        </ClinText>
      )}

      <ClinText as="div" wordBreak="break-word">
        {children || message}
      </ClinText>
    </StyledMessage>
  </StyledHoldAnnounce>
)
