import React, { FunctionComponent } from 'react'
import { iconStyles } from './StyledTrackingLinkWrapper.styles'
import { ClinIcon } from '../../../components/ClinIcon/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../../ClinTheme'
import { ClinText } from '../../../components/ClinText'
import { StyledExternalLink } from './PatientDashboard.styles'

interface StyledTrackingLinkWrapperProps {
  href: string
  text: string
  target?: string
}

export const StyledTrackingLinkWrapper: FunctionComponent<
  StyledTrackingLinkWrapperProps
> = ({ href, text, target }) => (
  <StyledExternalLink
    href={href}
    onClick={(e) => e.stopPropagation()}
    target={target}
  >
    <ClinIcon
      iconName={ClinIconPathName.DeliveryAus}
      iconHeight={13}
      iconFill={ClinTheme.colors.tealishBlue}
      style={iconStyles}
    />
    <ClinText
      fontSize={ClinTheme.fontSizes[1]}
      lineHeight={ClinTheme.lineHeights.small}
      marginLeft={22}
      display="inline-block"
      fontWeight={ClinTheme.fontWeights.medium}
      color={ClinTheme.colors.tealishBlue}
    >
      {text}
    </ClinText>
  </StyledExternalLink>
)

export default StyledTrackingLinkWrapper
