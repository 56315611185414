import styled from 'styled-components'

import { IButtonStyles } from './ClinButton'
import { ClinTheme } from '../../ClinTheme'
import { ButtonVariant } from '../../types'

const getVariantStyles = (variant: ButtonVariant, disabled: boolean) => {
  const config = {
    primary: {
      color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
      backgroundColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      borderColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      ':hover': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.primary,
        backgroundColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.redOrange,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.redOrange
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primary,
        outline: ClinTheme.colors.primaryLight
      }
    },

    secondary: {
      color: disabled
        ? ClinTheme.colors.darkGrey
        : ClinTheme.colors.primaryLight,
      backgroundColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.white,
      borderColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      ':hover': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primary,
        outline: 'none'
      }
    },

    linkButton: {
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primaryLight,
      backgroundColor: ClinTheme.colors.transparent,
      textAlign: 'left',
      padding: 0,
      borderRadius: 0,
      justifyContent: 'flex-start',
      ':hover': {
        textDecoration: disabled ? 'none' : 'underline',
        backgroundColor: disabled ? '' : ClinTheme.colors.transparent
      },
      ':focus': {}
    },

    link: {
      color: disabled
        ? ClinTheme.colors.darkGrey
        : ClinTheme.colors.tealishBlue,
      backgroundColor: ClinTheme.colors.transparent,
      textAlign: 'left',
      padding: 0,
      borderRadius: 0,
      justifyContent: 'flex-start',
      textDecoration: disabled ? 'none' : 'underline',
      ':hover': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.redOrange
      },
      ':focus': {
        backgroundColor: disabled ? '' : ClinTheme.colors.transparent
      }
    },

    linkWithIcon: {
      color: disabled
        ? ClinTheme.colors.darkGrey
        : ClinTheme.colors.tealishBlue,
      backgroundColor: ClinTheme.colors.transparent,
      textAlign: 'left',
      padding: 0,
      borderRadius: 0,
      justifyContent: 'flex-start',
      ':hover': {
        textDecoration: disabled ? 'none' : 'underline',
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.redOrange
      },
      ':focus': {
        textDecoration: disabled ? 'none' : 'underline',
        backgroundColor: disabled ? '' : ClinTheme.colors.transparent
      }
    },

    ghost: {
      color: disabled
        ? ClinTheme.colors.darkGrey
        : ClinTheme.colors.primaryLight,
      backgroundColor: disabled ? 'transparent' : ClinTheme.colors.transparent,
      justifyContent: 'flex-start',
      ':hover': {
        color: disabled
          ? ClinTheme.colors.lightGrey
          : ClinTheme.colors.redOrange,
        backgroundColor: disabled ? '' : ClinTheme.colors.lightGrey
      },
      ':focus': {
        color: disabled ? '' : ClinTheme.colors.primaryLight,
        borderColor: disabled ? '' : ClinTheme.colors.primary
      }
    },
    secondaryNarrow: {
      padding: `0 ${ClinTheme.space[4]}px`,
      width: '166px',
      color: disabled
        ? ClinTheme.colors.darkGrey
        : ClinTheme.colors.primaryLight,
      backgroundColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.white,
      borderColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      ':hover': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primary,
        outline: 'none'
      }
    },
    primaryNarrow: {
      padding: `0 ${ClinTheme.space[4]}px`,
      color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
      backgroundColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      borderColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      ':hover': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.redOrange,
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.redOrange
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primary,
        outline: ClinTheme.colors.primaryLight
      }
    },
    purpleNarrow: {
      padding: `0 ${ClinTheme.space[4]}px`,
      color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
      backgroundColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      borderColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      ':hover': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.redOrange,
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.redOrange
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primary,
        outline: ClinTheme.colors.primaryLight
      }
    },
    purpleSecondaryNarrow: {
      padding: `0 ${ClinTheme.space[4]}px`,
      width: '151px',
      color: disabled
        ? ClinTheme.colors.darkGrey
        : ClinTheme.colors.primaryLight,
      backgroundColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.white,
      borderColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      ':hover': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primary,
        outline: 'none'
      }
    },
    purpleLight: {
      padding: `${ClinTheme.space[1]}px ${ClinTheme.space[4]}px`,
      boxSizing: 'border-box',
      textTransform: 'none',
      color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
      backgroundColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      borderColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      ':hover': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.primary,
        backgroundColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.redOrange,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.redOrange
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primary,
        outline: 'none'
      }
    },
    purpleLightSecondary: {
      textTransform: 'none',
      padding: `${ClinTheme.space[1]}px ${ClinTheme.space[4]}px`,
      boxSizing: 'border-box',
      color: disabled
        ? ClinTheme.colors.darkGrey
        : ClinTheme.colors.primaryLight,
      backgroundColor: ClinTheme.colors.white,
      borderColor: disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryLight,
      ':hover': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primary,
        outline: 'none'
      }
    }
  }
  return config[variant]
}

export const ClinStyledButton = styled.button<IButtonStyles>(
  {
    alignItems: 'center',
    borderRadius: ClinTheme.radii[5],
    borderWidth: ClinTheme.borderWidths[1],
    borderStyle: 'solid',
    borderColor: ClinTheme.colors.transparent,
    fontFamily: ClinTheme.fonts.body,
    fontSize: ClinTheme.fontSizes[1],
    fontWeight: ClinTheme.fontWeights.medium,
    flex: '0 0 auto',
    lineHeight: `${ClinTheme.space[3]}px`,
    letterSpacing: `${ClinTheme.letterSpacing[2]}px`,
    height: `${ClinTheme.space[7]}px`,
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[5]}px`,
    textDecoration: 'none',
    textAlign: 'center',
    transition:
      '.2s linear background-color, .2s linear color, .2s linear borderColor, .2s linear underline',
    whiteSpace: 'nowrap',
    WebkitTapHighlightColor: ClinTheme.colors.transparent,
    [`@media print`]: {
      display: 'none'
    },
    a: {
      textDecoration: 'none',
      color: 'inherit',
      ':focus': {
        outline: 'none'
      }
    }
  },
  ({ variant, disabled, display, justifyContent }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    display,
    justifyContent,
    width: display === 'block' || display === 'flex' ? '100%' : 'auto',
    ...(getVariantStyles(variant!, disabled!) as any)
  })
)

export const StyledLoader = styled.span({
  display: 'flex',
  marginLeft: ClinTheme.space[3]
})
