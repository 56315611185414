import { AxiosError, AxiosResponse } from 'axios'
import _ from 'lodash'
import React, { FunctionComponent, useEffect, useCallback } from 'react'
import { RouteComponentProps } from 'react-router'

import { ProductVariantOld } from './ProductVariantOld'
import {
  ProductItemAvailabilityStatus,
  isAusMaUser,
  nonMaUsers
} from '../../../../constants'
import { CountryAlphaCodes } from '../../../../constants/countryAlpha2Codes'
import { useAppContext } from '../../../../context/app'
import { BookmarkToggleButtonContainer } from '../../../../features/BookmarkToggleButton/BookmarkToggleButtonContainer'
import { ProductNotAvailable } from '../../../../features/ProductNotAvailable'
import { useEffectOnlyOnce } from '../../../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { AnalyticsEvent } from '../../../../services/Analytics'
import analyticsServiceSingleton from '../../../../services/Analytics/initAnalytics'
import {
  cancelGetProductById,
  getProductById,
  getWarehouseForCodes
} from '../../../../services/ApiService'
import { getVariantInformationForCatalogItem } from '../../../../services/PortfolioJourneys/PortfolioJourney.model'
import { IAugmentedCatalogItemDto } from '../../../../services/PortfolioJourneys/PortfolioJourney.types'
import { CatalogDto, CatalogItemDto } from '../../../../types/swaggerTypes'
import { useErrorMessage } from '../../../../utils/useErrorMessage'
import {
  getMatchingDefaultWarehouseCodes,
  ICatalogItemDto,
  sortCatalogItemsByAvailability,
  sortCatalogItemsByAvailabilityAus
} from '../ProductVariant.models'

interface IProductVariantRouteParams {
  productId: string
}

interface IProductVariantProps
  extends RouteComponentProps<IProductVariantRouteParams> {}

export const ProductVariantContainerOld: FunctionComponent<
  IProductVariantProps
> = ({ match, location, history }) => {
  //region Hooks & variables
  const { productId } = match.params
  const urlSearchParams = new URLSearchParams(location.search)
  const urlParamSource = urlSearchParams.get('src')
  const { portfolioCountryCode, institute, userRole } = useAppContext()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [product, setProduct] = React.useState<CatalogDto>()
  const [catalogItems, setCatalogItems] = React.useState<ICatalogItemDto[]>([])
  const [filteredCatalogItems, setFilteredCatalogItems] = React.useState<
    ICatalogItemDto[]
  >([])

  const [hasPermissionToView, setHasPermissionToView] = React.useState<
    boolean | undefined
  >(undefined)

  const handleProductError = useErrorMessage(
    'There was an error fetching the product.'
  )
  const handleWarehouseError = useErrorMessage(
    'There was an error fetching warehouse details.'
  )
  //endregion

  //region Handlers/methods
  const handleSelectVariant = (variant: CatalogItemDto) => {
    history.push(`/product/${productId}/variant/${variant.item.sku}`)
  }

  const handleBackToTop = () => {
    window.scrollTo(0, 0)
  }
  //endregion

  //region useEffects

  // Get product
  useEffect(() => {
    if (userRole && productId) {
      getProductById(productId)
        .then((response: AxiosResponse<CatalogDto>) => {
          const catalog = response.data
          setProduct(catalog)

          // Derived from is MAP and any user role then redirect to access program page
          if (catalog.derivedFrom === 'ManagedAccessProgram') {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.ProductReferralAccessProgram,
              { productId }
            )
            history.replace(`/programs/access-programs/${catalog.programId}`)
            return
          }

          // Derived from is Generic and user MaOnly then show error
          const isNonMaUser = nonMaUsers.includes(userRole)
          // Its an ma user track analytics
          if (!isNonMaUser) {
            setIsLoading(false)
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.ProductReferralRoleError,
              { productId }
            )
          }
          // Disallow ma users from viewing product
          setHasPermissionToView(
            isNonMaUser || isAusMaUser(portfolioCountryCode, userRole) //related to clos-1614 aus ma users can see products
          )

          // Only continue with second call if user roles allow
          if (isNonMaUser || isAusMaUser(portfolioCountryCode, userRole)) {
            // For each catalogueItem get the matching default warehouses and save unique ones
            const allWarehousesCodes: string[] = []
            let catalogItems: ICatalogItemDto[] = catalog.items.map(
              (catalogItem) => {
                // Get the warehouses that this item ships from
                const availableWarehouseCodes: string[] =
                  getMatchingDefaultWarehouseCodes(
                    catalogItem.stockLevels,
                    portfolioCountryCode,
                    institute.data?.warehouses
                  )
                // Make up a list of all codes so we can get more detail for them all
                allWarehousesCodes.push(...availableWarehouseCodes)
                // Augment object
                return {
                  ...catalogItem,
                  warehouseCodes: availableWarehouseCodes,
                  warehouses: undefined //to call in API
                }
              }
            )

            // POC: Filtering duplicate variants will be implemented on the FE at some point in time, sadly the requirements are not up to par as we are
            //filtering based on 5 arbitrary  out of ~120 properties considered for a variant on the product level.
            // if (isAusUser(portfolioCountryCode)) {
            //   catalogItems = filterVarietyCards(catalogItems)
            // }

            // Get the unique list of warehouse codes so we can batch call
            const uniqueWarehousesCodes = _.uniq(allWarehousesCodes)

            // Make the API calls and augment info onto catalog items
            getWarehouseForCodes(uniqueWarehousesCodes)
              .then((response) => {
                const warehouses = response.data
                // Add in warehouse DTO from BFF
                catalogItems = catalogItems
                  .map((cat) => {
                    // Get the default warehouses full DTO for this catalog item
                    const defaultWarehouses = warehouses.filter((w) =>
                      w.warehouseCode
                        ? cat.warehouseCodes.includes(w.warehouseCode)
                        : false
                    )
                    // Add portfolio information
                    const augItem: IAugmentedCatalogItemDto =
                      getVariantInformationForCatalogItem(
                        cat,
                        portfolioCountryCode,
                        defaultWarehouses[0],
                        defaultWarehouses
                      )
                    return {
                      ...cat,
                      ...augItem,
                      warehouses: defaultWarehouses
                    }
                  })
                  .sort((a, b) =>
                    portfolioCountryCode === CountryAlphaCodes.Australia
                      ? sortCatalogItemsByAvailabilityAus(
                          a,
                          b,
                          portfolioCountryCode
                        )
                      : sortCatalogItemsByAvailability(
                          a,
                          b,
                          portfolioCountryCode
                        )
                  )
                setCatalogItems(catalogItems)
              })
              .catch((error) => {
                handleWarehouseError(error)
              })
              .finally(() => setIsLoading(false))
          }
        })
        .catch((error: AxiosError) => {
          handleProductError(error)
          setIsLoading(false)
        })

      return () => {
        cancelGetProductById()
      }
    }
  }, [
    handleProductError,
    handleWarehouseError,
    productId,
    portfolioCountryCode,
    institute.data,
    userRole,
    history
  ])

  // Do analytics for referrals on mount - check if url params exist ?src=public
  useEffect(() => {
    if (urlParamSource === 'public') {
      analyticsServiceSingleton.trackEvent(
        AnalyticsEvent.ProductReferralFromPublicSite,
        { productId }
      )
    }
  }, [urlParamSource, productId])
  //endregion

  type Deps = boolean[]
  useEffectOnlyOnce(
    () => {
      analyticsServiceSingleton.trackPageView(location.pathname, {})
    },
    [isLoading],
    (dependencies: Deps) => dependencies[0] === false
  )

  const filterHiddenVariants = useCallback(() => {
    return catalogItems.filter(
      (item) => item.availableStatus !== ProductItemAvailabilityStatus.HIDDEN
    )
  }, [catalogItems])

  useEffect(() => {
    setFilteredCatalogItems(filterHiddenVariants()) // related to clos-3103 Hide SAGE SKU's for Global Users
  }, [catalogItems, filterHiddenVariants])

  const listOfNewFeatures = undefined //expired features

  return hasPermissionToView !== false ? (
    <ProductVariantOld
      isLoading={isLoading}
      countryCode={portfolioCountryCode}
      product={product}
      catalogItems={filteredCatalogItems}
      userCountry={portfolioCountryCode}
      userRole={userRole}
      newFeaturesList={listOfNewFeatures}
      handleSelectVariant={handleSelectVariant}
      handleBackToTop={handleBackToTop}
      renderBookmarkToggle={(props) => (
        <BookmarkToggleButtonContainer {...props} />
      )}
    />
  ) : (
    <ProductNotAvailable
      isLoading={isLoading}
      productName={product?.catalogItemName}
    />
  )
}
