import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'
import { StyledAccordion } from '../../../components/ClinAccordion/ClinAccordion.styles'
import { StyledClinText } from '../../../components/ClinText/ClinText.styles'
import { betweenQueryTooltip, mediaQuery } from '../../../utils/mediaQuery'

const MediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledDocumentationIcon = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 0 138px',
  height: 138,
  background: ClinTheme.colors.lightGrey
})

export const StyledSKUQuantitySelector = styled.div({
  backgroundColor: ClinTheme.colors.lightGrey,
  padding: ClinTheme.space[4],
  [`.price-row`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${ClinTheme.space[3]}px 0`
  },
  [`.price-title`]: {
    flex: '1 0 50%'
  },
  [`.clin-text-input`]: {
    marginTop: ClinTheme.space[2]
  }
})

export const StyledProductAccordion = styled.div({
  '.clin-icon-title, .clin-icon-value': {
    flex: '0 0 50%'
  }
})

export const StyledHoldItem = styled.div({
  borderTop: '1px solid ' + ClinTheme.colors.grey,
  [`:last-child`]: {
    borderBottom: '1px solid ' + ClinTheme.colors.grey
  },
  [`.sku-information, .warehouse-address`]: {
    flexBasis: '100%'
  }
})

export const StyleDocumentationDetails = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: ClinTheme.space[3],
  width: '100%',

  [mediaQuery(MediumUpBreakpoint)]: {
    flexDirection: 'row'
  }
})

export const StyledDocumentCard = styled.div({
  display: 'flex',
  flex: '0 0 auto',
  flexDirection: 'column',
  marginBottom: ClinTheme.space[5],
  paddingBottom: ClinTheme.space[5],
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`
})

export const StyledDocumentCardInner = styled.div({
  display: 'flex',
  flex: '0 0'
})

export const StyledProductDetail = styled.div({
  paddingTop: ClinTheme.space[3],
  [`${StyledAccordion}`]: {
    [`${StyledDocumentCard}:last-of-type`]: {
      borderBottom: 'none',
      paddingBottom: ClinTheme.space[5]
    }
  }
})

export const StyleDocumentationDetail = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
})

export const StyleDocumentationDetailButton = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginTop: ClinTheme.space[4],

  [mediaQuery(MediumUpBreakpoint)]: {
    marginTop: 0
  }
})

export const StyledNoProductDocuments = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: ClinTheme.space[4],

  [mediaQuery(MediumUpBreakpoint)]: {
    paddingBottom: ClinTheme.space[6]
  },

  [`${StyledClinText}`]: {
    fontSize: ClinTheme.fontSizes[3],
    fontWeight: ClinTheme.fontWeights.bold,

    [mediaQuery(MediumUpBreakpoint)]: {
      fontSize: ClinTheme.fontSizes[4]
    }
  }
})

export const StyledDocumentsLinkContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  [`.clin-text-document-link`]: {
    display: 'inline-block'
  }
})

export const StyledEstimatedTimeRow = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: `${ClinTheme.space[3]}px 0`
})

export interface IClinStyleEddAusProps {
  padding?: string
}

export const StyledEstimatedTimeRowAus = styled.div<IClinStyleEddAusProps>(
  {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  ({ padding }) => ({
    padding: padding ? padding : `${ClinTheme.space[0]}px 0`
  })
)

export const StyledEstimatedTimeToolTip = styled.span({
  display: 'inline-flex',
  position: 'relative',
  height: ClinTheme.space[3],
  width: ClinTheme.space[3]
})

export const StyledEstimatedTimeToolTipWrap = styled.span({
  display: 'inline-flex',
  position: 'relative',
  height: ClinTheme.space[3],
  width: ClinTheme.space[3],

  [betweenQueryTooltip(ClinTheme.breakpoints[1], ClinTheme.breakpoints[2])]: {
    left: '-100px !important'
  }
})
