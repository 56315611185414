import styled from 'styled-components'
import { rgba } from 'polished'
import { ClinTheme } from '../../ClinTheme'
import { StyledCheckoutOrderItem } from './CheckoutOrderItem.styles'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { TableContainer as MuiTableContainer } from '@mui/material'

// Override the TableContainer to remove scrollbars
export const StyledTableContainer = styled.div`
  display: table;
  width: 100%;
  overflow: hidden;
`

export const StyledCheckoutOrder = styled.div({
  '> div > .accordion': {
    '> div > button': {
      paddingLeft: ClinTheme.space[4],
      borderLeft: `1px solid ${ClinTheme.colors.grey}`,
      borderRight: `1px solid ${ClinTheme.colors.grey}`,
      color: ClinTheme.colors.primary
    },
    '> .accordion-panel': {
      padding: 0,
      width: '100%',
      border: `1px solid ${ClinTheme.colors.grey}`,
      borderBottom: 'none',
      boxSizing: 'border-box',

      '.accordion__item': {
        borderBottom: 'none',
        marginBottom: 0,

        'button ': {
          textAlign: 'left',
          paddingTop: ClinTheme.space[4],
          span: {
            fontSize: ClinTheme.fontSizes[3],
            fontWeight: ClinTheme.fontWeights.medium,
            color: ClinTheme.colors.primary
          },
          '.clin-icon': {
            width: ClinTheme.fontSizes[4],
            height: ClinTheme.fontSizes[4]
          }
        },

        '> .accordion > .accordion-panel': {
          width: '100%',
          padding: 0
        }
      },
      [`${StyledCheckoutOrderItem}:last-of-type`]: {
        borderBottom: 'none'
      }
    }
  }
})
export const StyledNewCheckoutOrder = styled.div({})

export const StyledCheckoutSummary = styled.div({
  padding: ClinTheme.space[4],
  background: ClinTheme.colors.lightGrey
})

export const StyledCheckoutBlock = styled.div({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: ClinTheme.radii[1]
})

export const StyledNewCheckoutBlock = styled.div({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: ClinTheme.radii[3],
  background: ClinTheme.colors.white
})

export const StyledOneStepCheckoutBlock = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${ClinTheme.colors.midGrey}`,
  borderRadius: ClinTheme.radii[2]
})

export const StyledCheckoutTextareaContainer = styled.div({
  maxWidth: '360px',
  width: '100%'
})

export const StyledCheckoutBlockHeader = styled.div({
  flex: '0 0 100%',
  padding: ClinTheme.space[4]
})
export const StyledNewCheckoutBlockHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: ClinTheme.space[4],
  paddingRight: ClinTheme.space[5],
  paddingTop: ClinTheme.space[5],
  paddingBottom: ClinTheme.space[2]
})
export const StyledNewCheckoutBlockBody = styled.div({
  paddingLeft: ClinTheme.space[4],
  paddingRight: ClinTheme.space[5],
  paddingBottom: '44px',
  borderBottomLeftRadius: ClinTheme.radii[3],
  borderBottomRightRadius: ClinTheme.radii[3],
  background: ClinTheme.colors.white
})

export const StyledCheckoutBlockBody = styled.div({
  flex: '0 0 100%',
  padding: ClinTheme.space[4],
  borderTop: `1px solid ${ClinTheme.colors.grey}`,
  background: rgba(ClinTheme.colors.primaryMid, 0.15)
})
export const StyledNewCheckoutAdditionalBlock = styled.div({
  paddingTop: ClinTheme.space[5],
  paddingLeft: ClinTheme.space[4],
  paddingRight: ClinTheme.space[5],
  paddingBottom: ClinTheme.space[5],
  borderBottomLeftRadius: ClinTheme.radii[3],
  borderBottomRightRadius: ClinTheme.radii[3],
  background: ClinTheme.colors.white
})

export const StyledCheckoutDeliveryDateContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: ClinTheme.space[5],
  background: ClinTheme.colors.deliveryDateBackground,
  padding: ClinTheme.space[3],
  borderRadius: ClinTheme.radii[2]
})
export const StyledCheckoutDeliveryDateIconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: ClinTheme.space[3]
})

export const StyledCheckoutPoBlock = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: ClinTheme.space[5],
  paddingTop: ClinTheme.space[5],
  paddingLeft: ClinTheme.space[4],
  paddingRight: ClinTheme.space[5],
  background: ClinTheme.colors.white,
  borderRadius: ClinTheme.radii[3]
})

export const StyledCheckoutDeliveryDateContent = styled.div({
  display: 'flex',
  flexDirection: 'column'
})

export const StyledNewCheckoutUserInfoBlock = styled.div({
  paddingLeft: ClinTheme.space[4],
  paddingRight: ClinTheme.space[4],
  background: ClinTheme.colors.white
})

export const StyledDeclarationCheckoutBlockBody = styled.div({
  flex: '0 0 100%',
  paddingTop: ClinTheme.space[3],
  paddingBottom: ClinTheme.space[5],
  background: ClinTheme.colors.white
})

export const StyledOneStepCheckoutBlockBody = styled.div({
  flex: '0 0 100%',
  padding: ClinTheme.space[4],
  borderTop: `1px solid ${ClinTheme.colors.grey}`,
  backgroundColor: ClinTheme.colors.lightPurple
})
export const StyledCheckoutBlockBodyWrap = styled.div({
  flex: '0 0 100%',
  padding: ClinTheme.space[4],
  borderTop: `1px solid ${ClinTheme.colors.grey}`,
  background: '#EEEDFF'
})

export const StyledCheckoutClinPageContainer = styled('div')({
  backgroundColor: ClinTheme.colors.white
})
export const StyledCheckoutClinPageContentFrame = styled(ClinPageContentFrame)({
  marginBottom: '0 !important'
})
