import React from 'react'

import { ClinTheme } from '../../ClinTheme'
import { IDefaultStyleProps, ButtonVariant } from '../../types'
import { ClinSpinner } from '../ClinSpinner'
import { ClinStyledButton, StyledLoader } from './ClinButton.styles'

export interface IButtonStyles {
  /** The button variant style */
  variant?: ButtonVariant
  /** Css display value */
  display?: 'block' | 'inline-block' | 'flex' | 'inline-flex'
  /** Css justify-content value */
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit'
  /** StyledComponents polymorphic as prop */
  as?: 'div' | 'span' | 'a'
  /** The type of button */
  type?: 'submit' | 'reset' | 'button'
  /** href if the button is an a tag */
  href?: string
  /** target if the button is an a tag */
  target?: string
}

interface IClinButtonProps
  extends IDefaultStyleProps,
    IButtonStyles,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Whether a loader is required or not */
  isLoading?: boolean
}

export const ClinButton: React.FC<IClinButtonProps> = ({
  className = '',
  children,
  variant = 'secondary',
  display = 'inline-flex',
  justifyContent = 'center',
  as,
  type = 'button',
  isLoading,
  ...props
}) => {
  return (
    <ClinStyledButton
      {...props}
      as={as}
      type={type}
      className={`clin-button clin-button-${variant} ${className}`}
      variant={variant}
      display={display}
      justifyContent={justifyContent}
      tabIndex={props.disabled ? -1 : props.tabIndex ?? 0}
    >
      {children}
      {isLoading && (
        <StyledLoader>
          <ClinSpinner size={ClinTheme.space[3]} />
        </StyledLoader>
      )}
    </ClinStyledButton>
  )
}
