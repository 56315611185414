import { useEffect, useState } from 'react'

/**
 * A simple hook to determine if a given media query (or min-width number) matches.
 *
 * @param queryOrWidth - A media query string (e.g., '(min-width: 768px)')
 *                       OR a numeric value representing a min-width in px (e.g., 768)
 * @returns A boolean indicating if the query currently matches.
 */
export function useMediaQuery(queryOrWidth: string | number): boolean {
  const query =
    typeof queryOrWidth === 'number'
      ? `(min-width: ${queryOrWidth}px)`
      : queryOrWidth

  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query)

    const handler = (e: MediaQueryListEvent) => {
      setMatches(e.matches)
    }

    setMatches(mediaQueryList.matches)

    mediaQueryList.addEventListener('change', handler)

    return () => {
      mediaQueryList.removeEventListener('change', handler)
    }
  }, [query])

  return matches
}
