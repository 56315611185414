import React, { FunctionComponent } from 'react'

import { StyledClinLoaderSVG } from './ClinLoader.styles'

interface IClinLoaderProps {
  /** Duration for minute hand rotation */
  minHandDuration?: string
  /** Duration for hour hand rotation */
  hourHandDuration?: string
  /** Width of all line segments hands */
  strokeWidth?: number
}

export const ClinLoader: FunctionComponent<IClinLoaderProps> = ({
  minHandDuration = '3s',
  hourHandDuration = '12s',
  strokeWidth = 20
}) => {
  const circleRadius = 110
  const circumference = 2 * circleRadius * Math.PI
  const offset = circumference - (50 / 100) * circumference
  return (
    <StyledClinLoaderSVG
      className={`clin-loader`}
      viewBox="0 0 244 244"
      preserveAspectRatio="xMidYMid meet"
      x="0"
      y="0"
    >
      <circle
        id={'body'}
        cx="122"
        cy="122"
        r={circleRadius}
        stroke="#7239ff"
        opacity="0.75"
        strokeWidth={strokeWidth}
        fill="#fff"
        style={{
          transform: `rotate(-120deg)`,
          transformOrigin: `50% 50%`,
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: offset,
          strokeLinecap: 'round'
        }}
      />
      <circle
        id={'body'}
        cx="122"
        cy="122"
        r={circleRadius}
        stroke="#7239ff"
        opacity="0.5"
        strokeWidth={strokeWidth}
        fill="#fff"
      />
      <line
        id={'hour-hand'}
        opacity="0.5"
        fill="#d25eff"
        stroke="#d25eff"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        x1="122"
        y1="80"
        x2="122"
        y2="125"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 122 122"
          to="360 122 122"
          begin="0s"
          dur={hourHandDuration}
          repeatCount="indefinite"
        />
      </line>
      <line
        id={'minute-hand'}
        fill="#8bdb1f"
        stroke="#8bdb1f"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        x1="122"
        y1="54"
        x2="122"
        y2="125"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 122 122"
          to="360 122 122"
          begin="0s"
          dur={minHandDuration}
          repeatCount="indefinite"
        />
      </line>
    </StyledClinLoaderSVG>
  )
}
