export const tooltipStyles = {
  position: 'relative',
  display: 'flex',
  width: '100%',
  maxWidth: '200px',
  justifyContent: 'center'
} as const

export const tooltipWrapperStyles = {
  position: 'relative',
  width: '100%',
  overflow: 'hidden'
} as const
