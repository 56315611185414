import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../ClinText'

export const StyledRowContainer = styled.tr`
  background: ${ClinTheme.colors.white};
  padding: 16px;
  display: block;
  margin-bottom: 13px;
  border-radius: 4px;
  cursor: pointer;
`

export const StyledTopRow = styled.td`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 100%;
  > div > div {
    width: inherit;
  }
`

export const StyledColumnContainer = styled.div`
  flex: 1;
  > p {
    font-weight: 700;
    font-size: 16px;
    font-family: ${ClinTheme.fonts.groteskBold};
    line-height: 24px;
  }
`

export const StyledDataRow = styled.td`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: ${ClinTheme.breakpoints[0]}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`
export const StyledColumnLabel = styled(ClinText)`
  flex: 1;
  line-height: 24px;
`
export const StyledColumnData = styled(ClinText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  p {
    line-height: 24px;
  }
  @media screen and (min-width: ${ClinTheme.breakpoints[0]}px) {
    max-width: 100%;
    grid-area: 1 / 2 / 2 / 5;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-self: flex-end;
  margin-top: 10px;
  > button {
    transform: rotate(-90deg);
    background: ${ClinTheme.colors.primaryLight};
  }
`
