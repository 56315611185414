import { useEffect, useState } from 'react'

import { useAppContext } from '../../../../context/app'
import { createSwitchDeliveryAddressEvent } from '../../../../events/DeliveryAddress'
import { OrderAddressDto, OrgAddressDto } from '../../../../types/swaggerTypes'

export const sortAddressesForCurrent = (
  currentAddress: OrgAddressDto,
  addresses: OrgAddressDto[]
) => {
  return addresses.sort(function (x, y) {
    if (x.addressId === currentAddress?.addressId) {
      return -1
    } else if (y.addressId === currentAddress?.addressId) {
      return 1
    } else {
      return 0
    }
  })
}

export enum AssociateMode {
  active,
  submitting,
  hasError
}

interface IAddressModal {
  /** Whether to open the address modal */
  isAddressModalOpen: boolean
  /** The users current address */
  currentAddress: OrgAddressDto | undefined
  /** The users selected address */
  selectedAddress: OrgAddressDto | undefined
  /** A list of addresses that can be selected */
  addresses: OrgAddressDto[] | undefined
  /** A function that calls to open the modal */
  handleOpenAddressModal: () => void
  /** A function that calls to close the modal */
  handleCloseAddressModal: () => void
  /** A function that calls to select a provide address by ID */
  handleAddressSelected: (addressId: string) => void
  /** A function that calls to confirm and submit the users selected address */
  handleSubmitAddress: () => void
}

export const useAddressModal = (): IAddressModal => {
  const { dispatch, defaultShippingAddress, institute } = useAppContext()
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false)
  const [selectedAddress, setSelectedAddress] = useState<
    OrgAddressDto | undefined
  >(defaultShippingAddress)
  const [currentAddress, setCurrentAddress] = useState<
    OrgAddressDto | undefined
  >(defaultShippingAddress)
  const addresses =
    institute?.data?.shipTo &&
    currentAddress &&
    sortAddressesForCurrent(currentAddress, institute?.data?.shipTo)

  const handleAddressSelected = (addressId: string): void => {
    const addressMatch: OrgAddressDto | undefined = addresses?.find(
      (address: OrderAddressDto) => address.addressId.toString() === addressId
    )
    addressMatch && setSelectedAddress(addressMatch)
  }

  const handleSubmitAddress = (): void => {
    selectedAddress &&
      dispatch(createSwitchDeliveryAddressEvent(selectedAddress))
    setIsAddressModalOpen(false)
  }

  const handleOpenAddressModal = (): void => {
    setIsAddressModalOpen(true)
  }

  const handleCloseAddressModal = (): void => {
    setSelectedAddress(currentAddress)
    setIsAddressModalOpen(false)
  }

  useEffect(() => {
    setCurrentAddress(defaultShippingAddress)
    setSelectedAddress(defaultShippingAddress)
  }, [defaultShippingAddress])

  return {
    isAddressModalOpen,
    currentAddress,
    selectedAddress,
    addresses,
    handleOpenAddressModal,
    handleCloseAddressModal,
    handleAddressSelected,
    handleSubmitAddress
  }
}
