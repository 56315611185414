import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { mediaQueryDown } from '../../utils/mediaQuery'

export interface IOpaHeaderStyles {
  /** Example prop please replace or remove! */
  exampleOpaHeaderStyle?: string
}

export const StyledOpaHeader = styled.header<IOpaHeaderStyles>(
  {
    background: ClinTheme.colors.primary
  },
  ({ exampleOpaHeaderStyle }: IOpaHeaderStyles) => ({
    exampleOpaHeaderStyle
  })
)

export const StyledOpaHeaderWrapper = styled.div({
  maxWidth: ClinTheme.containerWidths[3],
  margin: '0 auto',
  padding: '24px 0',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    padding: '20px'
  }
})

export const StyledOpaHeaderContainer = styled.div({})
