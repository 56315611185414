import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme/ClinTheme'
import { mediaQuery } from '../../../utils/mediaQuery'

const tabletBreakpoint = ClinTheme.breakpoints[3]
const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledPatientDetailsCard = styled.aside({
  position: 'relative',
  top: '0',
  right: '0',
  width: 'auto',
  padding: '24px',
  boxSizing: 'border-box',
  border: '1px solid #E7E8E8',
  borderRadius: ClinTheme.radii[2],
  background: ClinTheme.colors.white,
  textAlign: 'left',
  overflow: 'hidden',
  [mediaQuery(mobileUpBreakpoint)]: {
    position: 'relative',
    top: '0',
    right: '0',
    width: 'auto'
  },
  [mediaQuery(tabletBreakpoint)]: {
    position: 'relative',
    width: '267px'
  }
})

export interface IStatusWrapperProps {
  color: string
}

export const StyledStatusWrapper = styled.div<IStatusWrapperProps>(
  {},
  ({ color }: IStatusWrapperProps) => ({
    width: '70%',
    display: 'block',
    background: color,
    padding: '4px 10px',
    borderRadius: `${ClinTheme.space[2]}px`
  })
)

export const StyledHyperlink = styled.a({
  color: ClinTheme.colors.tealishBlue,
  textDecorationLine: 'underline',
  [`:hover, :visited`]: {
    textDecorationLine: 'none',
    cursor: 'pointer'
  }
})
