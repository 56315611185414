import { ContactCardDto } from '../types/swaggerTypes'

export const getUserPhone = (contactCard?: ContactCardDto) => {
  if (!contactCard) {
    return ''
  }
  const mobilePhone = contactCard.customerPhones.find(
    (x) => x.phoneType === 'MOBILE'
  )

  if (mobilePhone) {
    return `+${mobilePhone.countryCode} ${mobilePhone.areaCode} ${mobilePhone.phoneNumber}`
  }

  const genericNumber = contactCard.customerPhones.find(
    (x) => x.phoneType === 'GEN'
  )
  if (genericNumber) {
    return `+${genericNumber.countryCode} ${genericNumber.areaCode} ${genericNumber.phoneNumber}`
  }

  return ''
}
