import { TFunction } from 'i18next'
import React, { FunctionComponent, Fragment } from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import {
  DrugProperty,
  drugPropertyTitleAndIconRecord
} from '../../services/DrugPropertyProvider'
import { CatalogItemDto } from '../../types/swaggerTypes'
import { IconValueCell } from '../IconValueCell'
import {
  StyledDrugPropertyGrid,
  StyledDrugPropertyGridItem
} from './DrugPropertyGrid.styles'

const getDrugPropertyItem = (
  drugProperty: DrugProperty,
  children: React.ReactNode,
  transFunc: TFunction
) => {
  const { iconName, translationKey } =
    drugPropertyTitleAndIconRecord[drugProperty]
  return (
    <>
      <IconValueCell iconName={iconName} title={transFunc(translationKey)} />
      <ClinText
        className="drug-property-value"
        as="p"
        fontWeight={ClinTheme.fontWeights.light}
        variant={TypographyVariant.LargeParagraph}
      >
        {children}
      </ClinText>
    </>
  )
}

interface IDrugPropertyGridProps {
  /** Catalogue item to display */
  catalogueItem: CatalogItemDto
  /** Whether or no the item is unavailable */
  showUnavailable?: boolean
}

export const DrugPropertyGrid: FunctionComponent<IDrugPropertyGridProps> = ({
  catalogueItem,
  showUnavailable,
  children
}) => {
  const { t } = useTranslation()

  return (
    <StyledDrugPropertyGrid>
      {children}
      <div style={{ width: '100%', opacity: showUnavailable ? 0.3 : 1 }}>
        <Row>
          <Col sm={12} md={6} className="col">
            <StyledDrugPropertyGridItem>
              {getDrugPropertyItem(
                DrugProperty.Strength,
                <>
                  {catalogueItem.products.map((product, index) => (
                    <Fragment key={`catalogue-item-${index}`}>
                      {product.genericName}
                      <br /> {product.strength}
                    </Fragment>
                  ))}
                </>,
                t
              )}
            </StyledDrugPropertyGridItem>
            <StyledDrugPropertyGridItem>
              {getDrugPropertyItem(
                DrugProperty.DosageAndPacksize,

                <>
                  {catalogueItem.item.formulation}
                  <br />
                  {catalogueItem.item.packSize}
                </>,
                t
              )}
            </StyledDrugPropertyGridItem>
          </Col>
          <Col sm={12} md={6} className="col">
            <StyledDrugPropertyGridItem>
              {getDrugPropertyItem(
                DrugProperty.Brand,

                catalogueItem.item.brand,
                t
              )}
            </StyledDrugPropertyGridItem>

            <StyledDrugPropertyGridItem>
              {getDrugPropertyItem(
                DrugProperty.CountryOfLicense,

                <>
                  {catalogueItem.licenses
                    .map((license) => license.country)
                    .join(', ')}
                </>,
                t
              )}
            </StyledDrugPropertyGridItem>

            <StyledDrugPropertyGridItem>
              {getDrugPropertyItem(
                DrugProperty.Overlabelled,
                <>
                  {catalogueItem && catalogueItem.item.overlabelled === 'Y'
                    ? `${catalogueItem.item.overlabelLanguage} (${catalogueItem.item.overlabelType})`
                    : 'No'}
                </>,
                t
              )}
            </StyledDrugPropertyGridItem>
          </Col>
        </Row>
      </div>
    </StyledDrugPropertyGrid>
  )
}
