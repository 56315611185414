import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery } from '../../../utils/mediaQuery'

const mediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledOrderDetailItemValue = styled.div({
  display: 'flex',
  [mediaQuery(mediumUpBreakpoint)]: {
    display: 'block'
  },
  [`.clin-text`]: {
    textAlign: 'left',
    [mediaQuery(mediumUpBreakpoint)]: {
      textAlign: 'right'
    }
  }
})

export const StyledOrderDetailMobileLabel = styled.div({
  display: 'block',
  marginRight: ClinTheme.space[3],
  [mediaQuery(mediumUpBreakpoint)]: {
    display: 'none'
  }
})

export const StyledOrderDetailItem = styled.div({
  padding: `${ClinTheme.space[1]}px ${ClinTheme.space[5]}px ${ClinTheme.space[3]}px ${ClinTheme.space[5]}px`,

  [`> div > div:first-of-type`]: {
    '.clin-text': {
      textAlign: 'left'
    }
  },

  [`.accordion-panel`]: {
    padding: 0,
    width: '100%',
    borderBottom: 'none',
    boxSizing: 'border-box'
  },

  [`.accordion__item`]: {
    borderBottom: 'none',
    marginBottom: 0,

    'button ': {
      textAlign: 'left',
      paddingTop: ClinTheme.space[4],
      span: {
        fontSize: ClinTheme.fontSizes[3],
        fontWeight: ClinTheme.fontWeights.medium,
        color: ClinTheme.colors.primary
      },
      '.clin-icon': {
        width: ClinTheme.fontSizes[4],
        height: ClinTheme.fontSizes[4]
      }
    }
  }
})

export const StyledOrderDetailLineAction = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-end',
  textAlign: 'right',
  marginTop: ClinTheme.space[2],
  [mediaQuery(mediumUpBreakpoint)]: {
    marginTop: 0
  },

  [`.clin-button`]: {
    alignSelf: 'flex-end',

    '&:last-of-type': {
      marginTop: ClinTheme.space[4]
    }
  }
})

export const StyledOrderDetailItemProperties = styled.div({
  marginTop: ClinTheme.space[2]
})

export const StyledOrderDetailProperty = styled.div({
  display: 'flex',
  marginBottom: ClinTheme.space[3]
})

export const StyledOrderDetailPropertyDetail = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: ClinTheme.space[1],
  marginLeft: ClinTheme.space[2]
})

export const StyledOrderDetailItemDivider = styled.div({
  width: '100%',
  height: ClinTheme.space[4],
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryLightest}`
})
