import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledBrandsContainer } from './GenericCard.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinButtonIcon } from '../../components/ClinButtonIcon'
import { ClinChip } from '../../components/ClinChip'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinTableToggleRowButton } from '../../components/ClinTableToggleRowButton'
import { ClinText } from '../../components/ClinText'
import { ClinTwoColumnCard } from '../../components/ClinTwoColumnCard'
import { getAvailabilityDisplayText } from '../../pages/Products/Product.models'
import { CatalogDto } from '../../types/swaggerTypes'

interface IGenericCardProps {
  /** Catalogue item to display */
  catalogueItem: CatalogDto
  /** The country code */
  countryCode: string
  /** Text for message that leads to variant/SKU details page */
  cta: string
  /** Whether to show all brands */
  isBrandsExpanded?: boolean
  /** Whether to show an overlay saying the item has been deleted */
  isDeleted?: boolean
  /** Expose onClick for card */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  /** Shows a delete icon and calls this call back when the user clicks on it */
  onClickDelete?: () => void
  /** Called when user clicks button to show all brands */
  onToggleExpandBrands: () => void
}

// Maximum number of brands to render before we show (+N more)
const MAX_BRAND_ENTRIES = 2

export const GenericCard: FunctionComponent<IGenericCardProps> = (
  {
    catalogueItem,
    onClick,
    cta,
    countryCode,
    isBrandsExpanded,
    isDeleted,
    onClickDelete,
    onToggleExpandBrands
  },
  ...props
) => {
  const { t } = useTranslation()
  const { brands } = catalogueItem
  let brandEntries = brands
  if (!isBrandsExpanded && brandEntries.length > MAX_BRAND_ENTRIES) {
    brandEntries = brandEntries.slice(0, MAX_BRAND_ENTRIES - 1)
    brandEntries.push(
      `(+ ${brands.length - MAX_BRAND_ENTRIES + 1} ${t('common:more')})`
    )
  }
  return (
    <ClinTwoColumnCard
      onClick={(event) => onClick && onClick(event)}
      cta={cta}
      isDeleted={isDeleted}
      left={
        <>
          <ClinText
            as="div"
            color={ClinTheme.colors.primary}
            fontWeight={ClinTheme.fontWeights.medium}
            marginBottom={ClinTheme.space[2]}
          >
            {t('glossary:generic_name')}
          </ClinText>
          <ClinText as="div">{catalogueItem.catalogItemName}</ClinText>

          <ClinSpacer height={ClinTheme.space[3]} />
          <StyledBrandsContainer>
            <ClinText
              as="div"
              color={ClinTheme.colors.primary}
              fontWeight={ClinTheme.fontWeights.medium}
              marginRight={ClinTheme.space[1]}
            >
              {t('glossary:brand')}
            </ClinText>
            {brands.length > MAX_BRAND_ENTRIES && (
              <ClinTableToggleRowButton
                isToggled={isBrandsExpanded}
                onClick={onToggleExpandBrands}
              />
            )}
          </StyledBrandsContainer>
          <ClinSpacer height={ClinTheme.space[1]} />
          {brandEntries.map((brandEntry, i) => (
            <ClinText className="brand-entry" key={i}>
              {brandEntry}
            </ClinText>
          ))}

          <ClinSpacer height={ClinTheme.space[3]} />
          {catalogueItem.tags.length > 0 && (
            <>
              <ClinText
                as="div"
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
                marginBottom={ClinTheme.space[1]}
              >
                {t('glossary:therapeutic_area')}
              </ClinText>
              {catalogueItem.tags.map((ta, index) => (
                <ClinChip key={`chip-${index}`}>{ta}</ClinChip>
              ))}
            </>
          )}
        </>
      }
      right={
        <>
          <ClinText
            as="div"
            color={ClinTheme.colors.primary}
            fontWeight={ClinTheme.fontWeights.medium}
            marginBottom={ClinTheme.space[1]}
          >
            {t('glossary:availability')}
            {onClickDelete && (
              <ClinButtonIcon
                iconName={ClinIconPathName.Cross}
                onClick={(e) => {
                  e.stopPropagation()
                  onClickDelete()
                }}
                hoverColor={ClinTheme.colors.primaryMid}
              />
            )}
          </ClinText>

          <ClinText
            as="span"
            color={ClinTheme.colors.greenValid}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {catalogueItem.countryAvailability &&
              getAvailabilityDisplayText(
                catalogueItem.countryAvailability,
                countryCode,
                t
              )}
          </ClinText>
        </>
      }
    />
  )
}
