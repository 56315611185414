import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { ClinTheme } from '../../ClinTheme'
import { ClinGroup } from '../ClinGroup'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinText } from '../ClinText'
import {
  StyledClinCard,
  IClinCardStyles,
  StyledDeletedOverlayOuter,
  StyledDeletedOverlayInner
} from './ClinCard.styles'

interface IClinCardProps
  extends IClinCardStyles,
    React.ButtonHTMLAttributes<HTMLDivElement> {
  /** Whether to show an overlay saying the item has been deleted */
  isDeleted?: boolean
  border?: string
  borderTop?: string
  borderTopLeftRadius?: number
  borderTopRightRadius?: number
  borderBottomRightRadius?: number
  borderBottomLeftRadius?: number
}

export const ClinCard: FunctionComponent<IClinCardProps> = ({
  children,
  disabled,
  tabIndex,
  isDeleted,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <StyledClinCard tabIndex={disabled ? -1 : tabIndex || 0} {...rest}>
      {isDeleted && (
        <StyledDeletedOverlayOuter>
          <StyledDeletedOverlayInner>
            <ClinGroup justifyContent="center">
              <ClinText
                fontWeight={ClinTheme.fontWeights.bold}
                color={ClinTheme.colors.primary}
              >
                {t('glossary:item_deleted')}
              </ClinText>
              <ClinIcon
                iconName={ClinIconPathName.Trashcan}
                iconFill={ClinTheme.colors.primary}
              />
            </ClinGroup>
          </StyledDeletedOverlayInner>
        </StyledDeletedOverlayOuter>
      )}
      {children}
    </StyledClinCard>
  )
}
