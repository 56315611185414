import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'

export const StyledProductRequestHeader = styled.div({
  paddingBottom: ClinTheme.space[3],
  marginBottom: ClinTheme.space[5],
  borderBottom: `solid 1px ${ClinTheme.colors.midGrey}`,
  [`.clin-button`]: {
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[2]}px`
  }
})
