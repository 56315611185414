import _ from 'lodash'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { ClinSearchInput } from '../../../components/ClinSearchInput'

interface IBasicSearchProps {
  /** The initial query that the search uses its first render */
  initialQuery?: string
  /** Placeholder copy to display */
  placeholder?: string
  /** Amount of debounce time in milliseconds */
  debounceTimeMs?: number
  borderRadious?: string
  maxWidth?: string
  iconColor?: string
  iconStyle?: {}
  textIndent?: number
  fontSize?: number
  iconHeight?: string
  iconSize?: string
  height?: string
  marginLeft?: number
  borderColor?: string
  boxShadow?: string
  zIndex?: number
  /** Call back to reveal query */
  handleOnChange(query: string): void
  /** Call back to reveal query */
  handleOnSubmit?(): void
  /** Call back to handle click */
  handleClick?(): void
}

export const BasicSearch: FunctionComponent<IBasicSearchProps> = ({
  initialQuery,
  placeholder,
  debounceTimeMs = 500,
  borderRadious,
  maxWidth,
  iconColor,
  iconStyle,
  textIndent,
  fontSize,
  iconHeight,
  iconSize,
  height,
  marginLeft,
  borderColor,
  boxShadow,
  zIndex,
  handleOnChange,
  handleOnSubmit,
  handleClick
}) => {
  const [query, setQuery] = React.useState<string>(initialQuery ?? '')

  const handleQueryUpdate = useCallback(
    (queryStr) => {
      handleOnChange(queryStr)
    },
    [handleOnChange]
  )

  const debounceEventHandler = useMemo(
    () =>
      _.debounce(
        (searchString: string) => handleQueryUpdate(searchString),
        debounceTimeMs
      ),
    [handleQueryUpdate, debounceTimeMs]
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery: string = event?.currentTarget.value
    setQuery(newQuery)
    debounceEventHandler(newQuery)
  }

  return (
    <ClinSearchInput
      boxShadow={boxShadow}
      zIndex={zIndex}
      borderRadious={borderRadious}
      textIndent={textIndent}
      iconHeight={iconHeight}
      iconSize={iconSize}
      fontSize={fontSize}
      marginLeft={marginLeft}
      height={height}
      borderColor={borderColor}
      maxWidth={maxWidth}
      iconColor={iconColor}
      iconStyle={iconStyle}
      query={query}
      placeholder={placeholder}
      onChange={handleChange}
      onEnter={handleOnSubmit}
      onClick={handleClick}
    />
  )
}
