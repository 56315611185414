import React, { FunctionComponent, ReactNode } from 'react'
import { Col, Row } from 'react-grid-system'
import { Trans, useTranslation } from 'react-i18next'

import { ProgramColumn, ProgramColumnNames } from './PhysicianDetails.models'
import {
  StyledClinTableRow,
  StyledColoredText
} from './PhysicianDetails.styles'
import { ClinTheme } from '../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../components/ClinAccordion'
import {
  AnnounceMode,
  ClinAnnounceBar
} from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinButton } from '../../components/ClinButton'
import { ClinChip } from '../../components/ClinChip'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinSpinner } from '../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { ClinTabHeader } from '../../components/ClinTabHeader'
import {
  ClinTable,
  ClinTableBodyCell,
  ClinTableHeaderCell
} from '../../components/ClinTable/ClinTable'
import { ClinTableOrderToggle } from '../../components/ClinTableOrderToggle'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinTabs } from '../../components/ClinTabs'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import {
  PhysicianDetailsDto,
  PhysicanProgramDto
} from '../../types/swaggerTypes'
import { truncateString } from '../../utils/truncateString'

interface IPhysicianDetailsProps {
  /** Selected physician */
  physician?: PhysicianDetailsDto
  /** Whether we are loading or not */
  isLoading: boolean
  /** Physician is discontinued */
  isDiscontinued?: boolean
  /** Columns to show on the orders table */
  columns: ProgramColumn[]
  /** Index of the selected tab */
  selectedTabIndex: number
  /** Selected column for sorting */
  selectedSortColumn: ProgramColumn
  /** Selected direction for sorting */
  selectedSortDirection: SortDirectionType
  /** User country */
  userCountry?: string
  /** Handles a column sort request */
  handleToggleSort: (event: ProgramColumn) => void
  /** Called when the user selects the 'enrolled programs' or 'all other programs' tab */
  onTabSelected: (selectedTabIndex: number) => void
  /** Called when the user click on backbutton */
  handleGoBack: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Handle request to dissociate patient from physician */
  handleRequestToDissociate: () => void
  /** Handle request to remove physician from institute */
  handleRequestToRemove: () => void
  /** Handle request to view the selected program */
  handleProgramRowClicked: (programId: number) => void
  /** Render prop for showing associated patients table */
  renderAssociatedPatients: FunctionComponent<{ physicianId: number }>
}

export const PhysicianDetails: FunctionComponent<IPhysicianDetailsProps> = ({
  physician,
  isLoading,
  isDiscontinued,
  selectedTabIndex,
  columns,
  selectedSortColumn,
  selectedSortDirection,
  userCountry,
  onTabSelected,
  handleGoBack,
  handleToggleSort,
  handleRequestToDissociate,
  handleRequestToRemove,
  handleProgramRowClicked,
  renderAssociatedPatients
}) => {
  const { t } = useTranslation()

  if (isLoading || !physician) {
    return (
      <ClinPageContentFrame>
        <Row align="center">
          <Col>
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      </ClinPageContentFrame>
    )
  } else {
    const {
      physicianTitle,
      physicianFirstName,
      physicianLastName,
      physicianEmail,
      physicianId
    } = physician
    const physicianFullName = `${
      physicianTitle ?? ''
    } ${physicianFirstName} ${physicianLastName}`
    const showEnrolledPrograms =
      !isLoading && selectedTabIndex === 1 && physician

    const getColumnWidth = (column: ProgramColumnNames) => {
      switch (column) {
        case ProgramColumnNames.ProgramName:
        case ProgramColumnNames.Sponsor:
          return '20%'
        case ProgramColumnNames.TherapeuticArea:
        case ProgramColumnNames.Status:
          return '30%'
        default:
          return 'auto'
      }
    }

    const getCellContent = (
      column: ProgramColumn,
      program: PhysicanProgramDto
    ): ReactNode | string => {
      const {
        programName, // genericName
        sponsor, // brandName
        therapeuticArea, // therapeuticArea
        status // status
      } = program
      switch (column.name) {
        case ProgramColumnNames.ProgramName:
          return (
            <ClinText
              marginLeft={'20px'}
              fontWeight={ClinTheme.fontWeights.bold}
              as="span"
            >
              {programName}
            </ClinText>
          )
        case ProgramColumnNames.Sponsor:
          return <ClinText as="div">{sponsor}</ClinText>
        case ProgramColumnNames.TherapeuticArea:
          return <ClinChip>{therapeuticArea}</ClinChip>
        case ProgramColumnNames.Status:
          return (
            <ClinText
              as="span"
              color={ClinTheme.colors.greenValid}
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {status}
            </ClinText>
          )
        default:
          return 'Unknown type'
      }
    }

    return (
      <ClinPageContentFrame
        className="physician-details"
        crumbs={[
          { path: '/orders', name: t('orders:page_name') },
          { path: '/programs/my-physicians', name: t('my_physicians:title') },
          {
            path: `/programs/my-physicians/${physicianId}`,
            name: truncateString(physicianFullName)
          }
        ]}
      >
        <ClinSpacer />
        <Row>
          <Col sm={12}>
            {isDiscontinued && (
              <>
                <ClinSpacer height={ClinTheme.space[2]} />
                <ClinAnnounceBar
                  mode={AnnounceMode.Success}
                  isDismissible={true}
                  title={t('physician_details:removed_user_announce_title')}
                >
                  {t('physician_details:removed_user_announce_description', {
                    physicianName: physicianFullName
                  })}
                </ClinAnnounceBar>
              </>
            )}
            <ClinText
              as="h1"
              className="physician-full-name"
              variant={TypographyVariant.H2}
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {physicianFullName}
            </ClinText>
            {physicianEmail && (
              <Row>
                <Col>
                  <ClinText
                    as={'span'}
                    variant={TypographyVariant.Paragraph}
                    fontWeight={ClinTheme.fontWeights.light}
                    fontSize={ClinTheme.fontSizes[3]}
                  >
                    {t('physician_details:email')}
                  </ClinText>
                  <ClinText
                    as={'span'}
                    className="physician-email"
                    variant={TypographyVariant.Paragraph}
                    fontWeight={ClinTheme.fontWeights.bold}
                    fontSize={ClinTheme.fontSizes[3]}
                  >
                    {physicianEmail}
                  </ClinText>
                </Col>
              </Row>
            )}
            <ClinSpacer height={ClinTheme.space[7]} hasBorder />
            <Row>
              <Col>
                <ClinButton
                  className="back-button"
                  variant="linkWithIcon"
                  onClick={(event) => handleGoBack && handleGoBack(event)}
                >
                  <ClinIcon
                    style={{ marginRight: ClinTheme.space[2] }}
                    iconSize={ClinTheme.fontSizes[3]}
                    iconName={ClinIconPathName.ArrowLeft}
                  />
                  {t('physician_details:back_to_physicians')}
                </ClinButton>
              </Col>
            </Row>
            <ClinSpacer height={ClinTheme.space[3]} />

            {isDiscontinued ? (
              <Row>
                <Col>
                  <ClinSpacer height={ClinTheme.space[3]} />
                  <ClinText
                    fontSize={ClinTheme.fontSizes[3]}
                    fontWeight={ClinTheme.fontWeights.light}
                  >
                    {t('physician_details:physician_discontinued')}
                  </ClinText>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col>
                    <ClinAccordion activeItemIndices={[0]}>
                      <ClinAccordionItem
                        title={t('physician_details:manage_physician')}
                      >
                        <Row>
                          <Col sm={12} md={6}>
                            <ClinText
                              as="h4"
                              fontSize={ClinTheme.fontSizes[3]}
                              fontWeight={ClinTheme.fontWeights.bold}
                              marginTop="0px"
                              marginBottom={ClinTheme.space[3]}
                            >
                              {t(
                                'physician_details:remove_from_your_institute'
                              )}
                            </ClinText>
                            <ClinText
                              variant={TypographyVariant.LargeParagraph}
                              marginBottom={ClinTheme.space[5]}
                            >
                              <Trans
                                i18nKey="physician_details:remove_their_permissions"
                                components={{ colour: <StyledColoredText /> }}
                              >
                                <></>
                              </Trans>
                            </ClinText>
                            <ClinButton
                              className="remove-physician"
                              onClick={() => handleRequestToRemove()}
                            >
                              {t('common:buttons.remove')}
                            </ClinButton>
                            <ClinSpacer />
                          </Col>
                          <Col sm={12} md={6}>
                            <ClinText
                              as="h4"
                              fontSize={ClinTheme.fontSizes[3]}
                              fontWeight={ClinTheme.fontWeights.bold}
                              marginTop="0px"
                              marginBottom={ClinTheme.space[3]}
                            >
                              {t('physician_details:disassociate_yourself')}
                            </ClinText>
                            <ClinText
                              variant={TypographyVariant.LargeParagraph}
                              marginBottom={ClinTheme.space[5]}
                            >
                              <Trans
                                i18nKey="physician_details:remove_your_permissions"
                                components={{ colour: <StyledColoredText /> }}
                              >
                                <></>
                              </Trans>
                            </ClinText>
                            <ClinButton
                              className="dissociate-physician"
                              onClick={() => handleRequestToDissociate()}
                            >
                              {t('physician_details:disassociate')}
                            </ClinButton>
                            <ClinSpacer />
                          </Col>
                        </Row>
                      </ClinAccordionItem>
                    </ClinAccordion>
                  </Col>
                </Row>
                <ClinSpacer height={ClinTheme.space[4]} />
                <Row>
                  <Col>
                    <ClinTabs
                      activeItemIndex={selectedTabIndex}
                      handleSelected={onTabSelected}
                    >
                      <ClinTabHeader title={t('physician_details:patients')} />
                      <ClinTabHeader
                        title={t('physician_details:enrolled_programs')}
                      />
                    </ClinTabs>
                    <ClinSpacer height={ClinTheme.space[4]} />
                    {selectedTabIndex === 0 &&
                      renderAssociatedPatients({ physicianId })}
                    {isLoading && selectedTabIndex === 1 && (
                      <StyledSpinnerContainer>
                        <ClinSpinner size={ClinTheme.space[7]} />
                      </StyledSpinnerContainer>
                    )}
                    {showEnrolledPrograms && (
                      <>
                        <ClinSpacer height={ClinTheme.space[2]} />
                        <ClinText variant={TypographyVariant.LargeParagraph}>
                          <Trans
                            i18nKey="physician_details:yourself_and_pysician_are_enrolled"
                            components={{
                              bold: (
                                <ClinText
                                  as="span"
                                  fontSize={ClinTheme.fontSizes[2]}
                                  fontWeight={ClinTheme.fontWeights.bold}
                                  textTransform="uppercase"
                                />
                              )
                            }}
                            values={{
                              physician: physicianFullName,
                              interpolation: { escapeValue: false }
                            }}
                          >
                            <></>
                          </Trans>
                        </ClinText>
                        <ClinSpacer height={ClinTheme.space[4]} />
                        {!isLoading && physician.programs && (
                          <ClinTable
                            tableHeader={() =>
                              columns.map((column: ProgramColumn, index) => (
                                <ClinTableHeaderCell
                                  key={index}
                                  width={getColumnWidth(column.name)}
                                  textAlign={'left'}
                                >
                                  {column.name === ProgramColumnNames.Status ? (
                                    <ClinTableOrderToggle hideSortIcon={true}>
                                      {t(column.transcriptKey)}
                                    </ClinTableOrderToggle>
                                  ) : (
                                    <ClinTableOrderToggle
                                      sortDirection={
                                        selectedSortColumn === column
                                          ? selectedSortDirection
                                          : SortDirectionType.None
                                      }
                                      onClick={() =>
                                        handleToggleSort &&
                                        handleToggleSort(column)
                                      }
                                    >
                                      {column.name ===
                                        ProgramColumnNames.ProgramName && (
                                        <ClinText
                                          marginLeft={'20px'}
                                          as="span"
                                        />
                                      )}
                                      {t(column.transcriptKey)}
                                    </ClinTableOrderToggle>
                                  )}
                                </ClinTableHeaderCell>
                              ))
                            }
                          >
                            {physician.programs.length > 0 ? (
                              physician.programs.map((program, index) => {
                                return (
                                  <StyledClinTableRow
                                    key={index}
                                    onRowClick={() =>
                                      handleProgramRowClicked(program.programId)
                                    }
                                  >
                                    {columns.map(
                                      (
                                        column: ProgramColumn,
                                        index: number
                                      ) => (
                                        <ClinTableBodyCell
                                          key={index}
                                          verticalAlign={'top'}
                                          textAlign={'left'}
                                        >
                                          {getCellContent(column, program)}
                                        </ClinTableBodyCell>
                                      )
                                    )}
                                  </StyledClinTableRow>
                                )
                              })
                            ) : (
                              <tr>
                                <td colSpan={columns.length}>No programs</td>
                              </tr>
                            )}
                          </ClinTable>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[4]} />
      </ClinPageContentFrame>
    )
  }
}
