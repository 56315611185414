import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { IIndexable } from '../../types'

export interface IPagination {
  /** number items on page */
  count: number
  /** items to skip */
  skip: number
  /** items in page */
  take: number
  /** total items */
  total: number
}

export interface IPaginationPage {
  /** position of page in array to align to correct order */
  position: number
  /** expect page value */
  value: number
}

export const getCurrentPage = ({ skip, take }: IPagination) =>
  Math.floor(skip / take) + 1

export const getTotalPages = ({ total, take }: IPagination) =>
  Math.ceil(total / take)

export const calcPages = (totalPages: number): IPaginationPage[] => {
  return [...Array(totalPages)].map((i, index) => {
    return {
      position: index,
      value: index + 1
    }
  })
}

export const splicePages = (
  pages: IPaginationPage[],
  position: number,
  pageSteps: number
) => {
  return pages.length <= pageSteps
    ? [...pages]
    : pages.slice(position * pageSteps, position * pageSteps + pageSteps)
}

/**
 * useUpdateQueryParam custom hook that updates a query param for a page
 * * @param param - key value pair
 * * @usage:
 useUpdateQueryParam({ tabIndex: selectedTabIndex })
 */
export const useUpdateQueryParam = (param: IIndexable): string | undefined => {
  const location = useLocation()
  const history = useHistory()
  const [query, setQuery] = useState<string | undefined>()

  useEffect(() => {
    // FYI: This won't work in IE - https://caniuse.com/urlsearchparams
    if (typeof URLSearchParams === 'undefined') {
      console.warn(
        `Browser ${navigator.appVersion} does not support URLSearchParams`
      )
      return
    }

    const searchParams = new URLSearchParams(location.search)

    // Process the parameter
    processUrlParam(searchParams, param)

    // Convert to single line query param string
    searchParams.sort()
    const updatedSearchQuery = searchParams.toString()

    // If it exists and is different then update the query
    if (updatedSearchQuery && updatedSearchQuery !== query) {
      history.replace({
        search: updatedSearchQuery
      })
      setQuery(searchParams.toString())
    }
  }, [query, param, location.search, history, location.pathname])
  return query
}

/**
 * Return a value from query param as number
 * @param paramName
 * @param params
 * @param defaultValue
 * @returns number
 */
export const getParamValueFor = (
  paramName: string,
  params: URLSearchParams,
  defaultValue: number = 0
): number => {
  const val = params.get(paramName)
  if (typeof val === 'string') {
    return parseInt(val)
  }
  return defaultValue
}

/**
 * Encode an array of objects into a base64 URL compliant string
 * @returns string
 * @param arr
 */
export const encodeObjToUrlParams = (arr: any[]): string => {
  return btoa(encodeURIComponent(JSON.stringify(arr)))
}

/**
 * Decode a base64 URL compliant string into an array of Objects
 * @returns string
 * @param str
 */
export const decodeObjToUrlParams = (str: string): any[] => {
  return JSON.parse(decodeURIComponent(atob(str)))
}

/**
 * Process a single URL parameter by updating searchParams
 * @param searchParams - URLSearchParams instance to modify
 * @param param - Parameter object with key/value pair
 */
const processUrlParam = (
  searchParams: URLSearchParams,
  param: IIndexable
): void => {
  const key = Object.keys(param)[0]
  const val = param[key]
  const isArray = Array.isArray(val)

  // Add or update parameter
  if (!isArray || (isArray && val.length > 0)) {
    const encodedVal = isArray
      ? encodeObjToUrlParams(val)
      : encodeURIComponent(val)

    searchParams.has(key)
      ? searchParams.set(key, `${encodedVal}`)
      : searchParams.append(key, `${encodedVal}`)
    return
  }

  // Clean up empty parameters
  if (shouldRemoveParam(key, val, isArray, searchParams)) {
    searchParams.delete(key)
  }
}

/**
 * Determines if a parameter should be removed from URL
 */
const shouldRemoveParam = (
  key: string,
  val: any,
  isArray: boolean,
  searchParams: URLSearchParams
): boolean => {
  if (!searchParams.has(key)) {
    return false
  }

  if (isArray) {
    return val.length === 0
  }

  return val === undefined || val === 0 || val === ''
}

/**
 * useUpdateQueryParams custom hook that updates a query param for a page
 *                            ATTENTION!
 * Don't use this hook for more then one component on the same page, 
 * in other case it could cose race condition for read/write operation of URL string.
 * This is technikal debt that should be fixed in the next release.
 * * @param params - key value pair
 * * @usage:
 useUpdateQueryParams([{ tabIndex: selectedTabIndex }])
 */
export const useUpdateQueryParams = (
  params: IIndexable[]
): string | undefined => {
  const location = useLocation()
  const history = useHistory()
  const [query, setQuery] = useState<string | undefined>()

  useEffect(() => {
    // Early return if URLSearchParams is not supported
    if (typeof URLSearchParams === 'undefined') {
      console.warn(
        `Browser ${navigator.appVersion} does not support URLSearchParams`
      )
      return
    }

    const searchParams = new URLSearchParams(location.search)

    // Process each parameter
    params.forEach((param) => {
      processUrlParam(searchParams, param)
    })

    // Convert to query string and update if changed
    searchParams.sort()
    const updatedSearchQuery = searchParams.toString()

    if (updatedSearchQuery && updatedSearchQuery !== query) {
      history.replace({
        search: updatedSearchQuery
      })
      setQuery(searchParams.toString())
    }
  }, [query, location.search, history, location.pathname, params])

  return query
}
