import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledTabButton,
  StyledTableFilterSearch,
  StyledTableFilterHeader,
  StyledSearch,
  StyledFilterGroupContainer,
  StyledFilterMobileFooter
} from './TableFilterSearch.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { FacetDto } from '../../types/swaggerTypes'
import { FilterGroup } from '../FilterGroup'
import { IFilterGroupProps } from '../FilterGroup/FilterGroup'

interface ITableFilterSearchProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Slot to insert a searchInput - default goes here */
  searchInput?(): React.ReactNode
  /** Title to display when none selected */
  title?: string
  /** Beginning of title to display when filters are selected */
  selectedTitle?: string
  /** Array of filter groups to display */
  filterGroups?: IFilterGroupProps[]
  /** Expand and show all on default */
  isOpenOnMount?: boolean
  /** Expand and show all on default */
  isFiltering?: boolean
  /** Callback when a filter is selected */
  handleSelectFilterGroup?: (filter: FacetDto, filterGroupTitle: string) => void
  /** Callback when clear all is selected */
  handleClearAllFilters?: () => void
}

export const TableFilterSearch: FunctionComponent<ITableFilterSearchProps> = ({
  title = `Advanced filtering`,
  selectedTitle = `Filters Applied`,
  filterGroups = [],
  isOpenOnMount = false,
  isFiltering = true,
  searchInput,
  handleSelectFilterGroup,
  handleClearAllFilters
}) => {
  const { t } = useTranslation()

  const [isShowing, setIsShowing] = React.useState<boolean>(isOpenOnMount)
  /** Utility method to get the total count of selected filters */
  const getSelectedFilterCount = (filterGroups: IFilterGroupProps[]): number =>
    filterGroups.reduce(
      (sum, elt) =>
        sum + (elt && elt.selectedFilters ? elt.selectedFilters.length : 0),
      0
    )
  return (
    <StyledTableFilterSearch>
      <StyledTableFilterHeader isShowing={isShowing}>
        {isFiltering && filterGroups && filterGroups.length > 0 && (
          <StyledTabButton
            areFiltersApplied={getSelectedFilterCount(filterGroups) > 0}
            onClick={() => setIsShowing(!isShowing)}
          >
            <ClinIcon
              iconName={
                isShowing ? ClinIconPathName.Cross : ClinIconPathName.Filter
              }
            />
            <ClinText
              as="span"
              textTransform="uppercase"
              marginLeft={ClinTheme.space[2]}
              fontSize={ClinTheme.fontSizes[1]}
              fontWeight={ClinTheme.fontWeights.medium}
              color={ClinTheme.colors.primaryMid}
            >
              {getSelectedFilterCount(filterGroups) > 0
                ? `${selectedTitle} (${getSelectedFilterCount(filterGroups)})`
                : title}
            </ClinText>
          </StyledTabButton>
        )}
        <StyledSearch isFiltering={isFiltering}>
          <ClinText
            className="search"
            as="span"
            variant={TypographyVariant.Paragraph}
            fontWeight={ClinTheme.fontWeights.medium}
            textTransform="uppercase"
            color={ClinTheme.colors.primary}
            marginLeft={ClinTheme.space[4]}
          >
            {t('common:search')}
          </ClinText>
          {searchInput && searchInput()}
        </StyledSearch>
      </StyledTableFilterHeader>
      {filterGroups && filterGroups.length > 0 && (
        <StyledFilterGroupContainer>
          {isShowing && (
            <>
              <ClinButton
                as="div"
                variant="linkButton"
                className={'clear-btn'}
                color={ClinTheme.colors.primary}
                onClick={handleClearAllFilters}
              >
                {t('common:buttons.clear_all')}
              </ClinButton>
              {filterGroups &&
                filterGroups.map((filter) => (
                  <FilterGroup
                    key={filter.title}
                    {...filter}
                    handleSelectFilter={(facet) =>
                      handleSelectFilterGroup &&
                      handleSelectFilterGroup(facet, filter.title)
                    }
                  />
                ))}
            </>
          )}
          {isShowing && (
            <StyledFilterMobileFooter>
              <ClinButton
                variant="secondary"
                onClick={() => {
                  setIsShowing(false)
                  window.scrollTo({
                    behavior: 'smooth',
                    top: 0
                  })
                }}
              >
                {t('common:buttons.close')}
              </ClinButton>
            </StyledFilterMobileFooter>
          )}
        </StyledFilterGroupContainer>
      )}
    </StyledTableFilterSearch>
  )
}
