import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery, mediaQueryDown } from '../../../utils/mediaQuery'
import { ISearchStylesProps } from '../AutoSuggest/AutoSuggest'

const mobileUpBreakpoint = ClinTheme.breakpoints[0]
const tabletUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledGlobalSearchforAUS = styled.section({
  paddingLeft: '20px',
  paddingRight: '20px',

  [mediaQueryDown(mobileUpBreakpoint)]: {
    paddingLeft: '10px',
    paddingRight: '10px'
  }
})

export const StyledSearchWrapperForAUS = styled.div<ISearchStylesProps>(
  {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    top: '-40px',
    borderRadius: '60px',
    height: '65px',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.16), 0px 0px 8px rgba(0, 0, 0, 0.16)',
    padding: `${ClinTheme.space[0]}px ${ClinTheme.space[4]}`,
    [`.clin-icon`]: {
      transition: '.2s linear color',
      color: ClinTheme.colors.white,
      left: ClinTheme.space[4]
    },
    [`> input:focus`]: {
      [`~ .clin-icon`]: {
        color: ClinTheme.colors.primary
      },
      borderColor: ClinTheme.colors.primaryLighter
    },
    [mediaQuery(tabletUpBreakpoint)]: {
      maxWidth: 1094,
      maxHeight: 68,
      margin: 'auto'
    },
    [mediaQueryDown(mobileUpBreakpoint)]: {
      top: '-20px',
      height: '55px',
      padding: `${ClinTheme.space[0]}px ${ClinTheme.space[3]}`,
      [`span`]: {
        display: 'inline'
      },
      [`.clin-icon`]: {
        height: '24px',
        left: '14px'
      }
    }
  },
  ({ showSuggestions }) => ({
    [`> input:focus`]: {
      borderColor: showSuggestions
        ? `${ClinTheme.colors.primaryMid}`
        : `${ClinTheme.colors.primaryLighter}`,
      borderBottom: showSuggestions
        ? `1px solid ${ClinTheme.colors.primaryMid}`
        : `5px solid ${ClinTheme.colors.primaryLighter}`
    }
  })
)

export const StyledSearchInputAus = styled.input<ISearchStylesProps>(
  {
    fontFamily: ClinTheme.fonts.body,
    position: 'relative',
    flex: '1 1 auto',
    marginBottom: '0',
    textIndent: ClinTheme.space[6],
    paddingBottom: ClinTheme.space[0],
    paddingTop: ClinTheme.space[0],
    paddingLeft: ClinTheme.space[4],
    paddingRight: ClinTheme.space[4],
    fontSize: ClinTheme.fontSizes[2],
    fontWeight: ClinTheme.fontWeights.normal,
    color: ClinTheme.colors.black,
    borderRadius: ClinTheme.space[5],
    textOverflow: 'ellipsis',
    transition: '.2s linear background-color, .2s linear color',
    '::placeholder': {
      color: 'inherit'
    },
    ':-ms-input-placeholder': {
      height: '40px'
    },
    [`:focus`]: {
      color: ClinTheme.colors.primary,
      border: `5px solid ${ClinTheme.colors.primaryMid}`,
      outline: ClinTheme.space[0],
      boxShadow: '0px 3px 0px 0px #0B0C0C'
    },
    [mediaQuery(tabletUpBreakpoint)]: {
      fontSize: ClinTheme.fontSizes[3]
    },
    [mediaQueryDown(mobileUpBreakpoint)]: {
      fontSize: ClinTheme.fontSizes[2],
      textIndent: ClinTheme.space[5],
      paddingLeft: ClinTheme.space[3],
      paddingRight: ClinTheme.space[3]
    }
  },
  ({ showSuggestions }) => ({
    border: showSuggestions
      ? `5px solid ${ClinTheme.colors.primaryMid}`
      : `5px solid ${ClinTheme.colors.primaryLighter}`,
    borderBottom: showSuggestions
      ? `1px solid ${ClinTheme.colors.primaryMid}`
      : `5px solid ${ClinTheme.colors.primaryLighter}`,
    borderBottomLeftRadius: showSuggestions ? 0 : ClinTheme.space[5],
    borderBottomRightRadius: showSuggestions ? 0 : ClinTheme.space[5],
    borderTopLeftRadius: showSuggestions ? '28px' : ClinTheme.space[5],
    borderTopRightRadius: showSuggestions ? '28px' : ClinTheme.space[5]
  })
)

export const StyledResultsAus = styled.ul<ISearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
    zIndex: ClinTheme.zIndices.suggestiveSearch,
    border: `5px solid ${ClinTheme.colors.primaryMid}`,
    borderRadius: '0 0 28px 28px',
    borderTop: 'none',
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.16)`,

    [mediaQueryDown(mobileUpBreakpoint)]: {
      borderRadius: '0 0 20px 20px'
    }
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)
