import React from 'react'

import { ProgramPage } from './RealWorldDataProgram.model'
import {
  ContentContainer,
  PostImage,
  AuthorInfo,
  AuthorImage,
  AuthorText,
  AuthorName,
  AuthorTitle,
  PublishDate,
  ReadTime,
  Content,
  HeaderContainer,
  HeaderColumnTitle,
  HeaderColumnAuthor,
  PostImageContainer
} from './RealWorldDataProgram.styles'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinSkeletonLoader } from '../../components/ClinSkeletonLoader'
import { SkeletonItem } from '../../components/ClinSkeletonLoader/ClinSkeletonLoader'

interface IRealWoldDataProps {
  content: ProgramPage
}

export const RealWorldDataProgram: React.FC<IRealWoldDataProps> = ({
  content
}) => {
  const renderContent = () => {
    if (!content) {
      return (
        <ContentContainer>
          <ClinSkeletonLoader>
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
          </ClinSkeletonLoader>
        </ContentContainer>
      )
    }

    const { data } = content as ProgramPage
    // Extract the image URLs from the data if available
    const authorImage = data.AuthorImage?.iv?.[0]
      ? `https://cloud.squidex.io/api/assets/clinigen-direct-program-pages/${data.AuthorImage.iv[0]}`
      : ''
    const postImage = data.PostImage?.iv?.[0]
      ? `https://cloud.squidex.io/api/assets/clinigen-direct-program-pages/${data.PostImage.iv[0]}`
      : ''

    const formattedDate = data.PublishDate.iv
      ? new Date(data.PublishDate.iv).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        })
      : ''

    return (
      <>
        <HeaderContainer>
          <HeaderColumnTitle>
            <h1>{data.Title.iv}</h1>
            <ReadTime>{data.ReadTime.iv}</ReadTime>
          </HeaderColumnTitle>
          <HeaderColumnAuthor>
            <AuthorInfo>
              {authorImage && <AuthorImage src={authorImage} alt="Author" />}
              <AuthorText>
                <AuthorName>{data.AuthorName.iv}</AuthorName>
                <AuthorTitle>{data.AuthorTitle.iv}</AuthorTitle>
                <br />
                <PublishDate>{formattedDate}</PublishDate>
              </AuthorText>
            </AuthorInfo>
          </HeaderColumnAuthor>
        </HeaderContainer>
        <PostImageContainer>
          {postImage && (
            <PostImage
              src={postImage}
              style={{
                backgroundImage: `url('${postImage}')`
              }}
            />
          )}
        </PostImageContainer>
        <ContentContainer>
          <Content dangerouslySetInnerHTML={{ __html: data.Content.iv }} />
        </ContentContainer>
      </>
    )
  }

  return <ClinPageContentFrame>{renderContent()}</ClinPageContentFrame>
}
