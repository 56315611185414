import { useQuery } from '@apollo/client'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Row, Col } from 'react-grid-system'
import { RouteComponentProps } from 'react-router-dom'

import { SupportVideoGuide } from './SupportVideoGuide'
import { downloadSquidexFile } from './SupportVideoGuide.model'
import { ClinTheme } from '../../ClinTheme'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinSpinner } from '../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { getVideoContentById } from '../../services/ContentApiService'
import { AppQueries } from '../../types/ContentTypes'

interface ISupportVideoGuideContainerRouteParams {
  videoId: string
}

interface ISupportVideoGuideContainerProps
  extends RouteComponentProps<ISupportVideoGuideContainerRouteParams> {}

export const SupportVideoGuideContainer: FunctionComponent<
  ISupportVideoGuideContainerProps
> = ({ match, history }) => {
  const { dispatch, portfolioCountryCode, userRole } = useAppContext()
  const { videoId } = match.params
  const { loading, data, error } = useQuery<AppQueries>(getVideoContentById, {
    errorPolicy: 'all',
    variables: { vimeoId: `data/vimeo_id/en eq '${videoId}'` }
  })
  const [isFileDownloading, setIsFileIsDownloading] = useState(false)
  const parentPageName =
    data &&
    data.querySupportMainPageContents &&
    data.querySupportMainPageContents[0].flatData.title
      ? data.querySupportMainPageContents[0].flatData.title
      : ''

  const handleGoBack = () => {
    history.push('/support')
  }

  const handlePageChangeRequest = (path: string) => {
    history.push(path)
  }

  useEffect(() => {
    if (error) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Error,
          `There was an error loading this content. ${error}`
        )
      )
    }
  }, [dispatch, error])

  const handleDownload = () => {
    if (
      data &&
      data.queryVideoContents &&
      data?.queryVideoContents[0]?.flatData?.transcriptDownload
    ) {
      setIsFileIsDownloading(true)
      const { id, fileName, fileType } =
        data.queryVideoContents[0].flatData.transcriptDownload[0]
      downloadSquidexFile(id, fileName, fileType, () =>
        setIsFileIsDownloading(false)
      )
    }
  }

  if (loading)
    return (
      <Row justify="center">
        <Col width="auto">
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    )

  if (error) return <p>ERROR</p>
  if (!data) return <p>Not found</p>

  return (
    <>
      {data && data.queryVideoContents && data.queryVideoContents[0] && (
        <SupportVideoGuide
          videoId={videoId}
          slug={videoId}
          content={data.queryVideoContents[0]}
          isFileDownloading={isFileDownloading}
          parentPageName={parentPageName}
          userCountry={portfolioCountryCode}
          userRole={userRole}
          handleGoBack={handleGoBack}
          handleDownload={handleDownload}
          handlePageChangeRequest={handlePageChangeRequest}
        />
      )}
    </>
  )
}
