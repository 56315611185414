import React, { FunctionComponent } from 'react'

import {
  StyledSearchWrapper,
  StyledSearchInput,
  StyledResults
} from './PhysicianSearch.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinSpinner } from '../../../components/ClinSpinner'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../../utils/Forms/getInputPlaceholder'
import { IPhysicianAutoSuggestResultProps } from '../PhysicianAutoSuggest/PhysicianAutoSuggest.model'

export const PhysicianSearch: FunctionComponent<
  IPhysicianAutoSuggestResultProps
> = ({
  query = '',
  suggestions = [],
  isLoading,
  showSuggestions,
  handleChange,
  handleKeyDown,
  handleEnter,
  renderSuggestions
}) => {
  return (
    <StyledSearchWrapper
      className={'clin-physician-search-input-wrapper'}
      onKeyDown={handleKeyDown}
    >
      <StyledSearchInput
        className={'clin-physician-search-input'}
        autoFocus={false}
        tabIndex={0}
        showSuggestions={showSuggestions}
        placeholder={getInputPlaceholder(PlaceholderType.physicianSearch)}
        value={query}
        onChange={handleChange}
        onKeyDown={(event) => {
          handleEnter && event.keyCode === 13 && handleEnter(event)
        }}
      />
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: ClinTheme.space[2]
          }}
        >
          <ClinSpinner size={ClinTheme.space[3]} />
        </div>
      )}
      <ClinIcon
        style={{
          position: 'absolute',
          right: ClinTheme.space[3],
          top: '50%',
          transform: 'translateY(-50%)'
        }}
        iconName={ClinIconPathName.Search}
      />
      <StyledResults showSuggestions={showSuggestions}>
        {suggestions && renderSuggestions && renderSuggestions(suggestions)}
      </StyledResults>
    </StyledSearchWrapper>
  )
}
