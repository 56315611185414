import { useQuery } from '@apollo/client'
import React, { FunctionComponent, useEffect } from 'react'
import { Row, Col } from 'react-grid-system'

import { AboutUs } from './AboutUs'
import { ClinTheme } from '../../ClinTheme'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinSpinner } from '../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { getAboutContent } from '../../services/ContentApiService'
import { AppQueries } from '../../types/ContentTypes'

export const AboutUsContainer: FunctionComponent = () => {
  const { dispatch, portfolioCountryCode, userRole } = useAppContext()

  const { loading, data, error } = useQuery<AppQueries>(getAboutContent, {
    errorPolicy: 'all'
  })

  useEffect(() => {
    if (error) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Error,
          `There was an error loading this content. ${error}`
        )
      )
    }
  }, [dispatch, error])

  if (loading)
    return (
      <Row justify="center">
        <Col width="auto">
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    )

  if (!data || error) return <ClinSpacer height={ClinTheme.space[3]} />

  return (
    <>
      {data &&
        data.queryAboutClinigenTemplateContents &&
        data.queryAboutClinigenTemplateContents[0] && (
          <AboutUs
            content={data.queryAboutClinigenTemplateContents[0]}
            userCountry={portfolioCountryCode}
            userRole={userRole}
          />
        )}
    </>
  )
}
