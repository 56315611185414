import React, { FunctionComponent } from 'react'

import {
  StyledTabBedge,
  StyledTabButton,
  StyledTabButtonWrapper
} from '../ProgramDetail.styles'

interface IProgramDetailTabLinkProps {
  linkText: string
  countNumber?: number
  showCountNumber?: boolean
  tabIndex: number
  buttonStyles?: {}
  active?: boolean
  isProgramEnrolled?: boolean
  onClick: (tabIndex: number) => void
}

export const ProgramDetailTabLink: FunctionComponent<
  IProgramDetailTabLinkProps
> = ({
  linkText,
  countNumber,
  showCountNumber,
  buttonStyles,
  tabIndex,
  active,
  isProgramEnrolled,
  onClick
}) => {
  return (
    <StyledTabButtonWrapper active={active}>
      <StyledTabButton
        disabled={!active && !isProgramEnrolled}
        style={buttonStyles ? buttonStyles : { textDecoration: 'none' }}
        active={active}
        onClick={() => onClick(tabIndex)}
        variant={'linkButton'}
      >
        {linkText}
        {showCountNumber && (countNumber || countNumber === 0) ? (
          <StyledTabBedge active={active}>{countNumber}</StyledTabBedge>
        ) : (
          <></>
        )}
      </StyledTabButton>
    </StyledTabButtonWrapper>
  )
}
