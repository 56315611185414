import { rgba } from 'polished'
import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

export interface IClinSingleBoxStyles {
  backgroundColor: string
}

const largeUpBreakpoint = ClinTheme.breakpoints[2]

export const StyledClinSingleBox = styled.div<IClinSingleBoxStyles>(
  {
    marginBottom: ClinTheme.space[3],
    paddingLeft: ClinTheme.space[4],
    paddingRight: ClinTheme.space[4],
    paddingBottom: ClinTheme.space[5],
    paddingTop: '12px',
    color: `#000`,
    textAlign: 'center',
    alignSelf: 'stretch',
    borderRadius: ClinTheme.radii[5],
    boxSizing: 'border-box',
    [`svg`]: { display: 'none' },
    [mediaQuery(largeUpBreakpoint)]: {
      width: '31.9%',
      paddingLeft: ClinTheme.space[7],
      paddingRight: ClinTheme.space[7],
      paddingBottom: ClinTheme.space[4],
      paddingTop: ClinTheme.space[6],
      margin: 0,
      [`svg`]: { display: 'block', margin: 'auto' }
    }
  },
  ({ backgroundColor }: IClinSingleBoxStyles) => ({
    background: rgba(backgroundColor, 0.1)
  })
)
