// PermissionModalStyles.ts
import styled from 'styled-components'

import { ClinTheme } from '../../../../ClinTheme'
import { ClinButton } from '../../../../components/ClinButton/ClinButton'

export const StyledModalContent = styled.div`
  background: ${ClinTheme.colors.white};
  box-shadow: ${ClinTheme.shadows[2]};
  border-radius: ${ClinTheme.radii[2]}px; // 4px
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 28px; // 28px
  display: inline-flex;
  @media (max-width: ${ClinTheme.breakpoints[0]}px) {
    padding: 10px; // Decrease padding on smaller screens
  }
`

export const StyledModalHeader = styled.div`
  p {
    color: ${ClinTheme.colors.primary}; // #270072
    font-size: ${ClinTheme.fontSizes[3]}px; // 18px
    font-family: ${ClinTheme.fonts.heading};
    font-weight: ${ClinTheme.fontWeights.bold};
    word-wrap: break-word;
    @media (max-width: ${ClinTheme.breakpoints[0]}px) {
      font-size: 16px; // Decrease font size on smaller screens
    }
    strong {
      color: ${ClinTheme.colors.primaryMid};
    }
  }
`

export const StyledCheckboxContainer = styled.div`
  padding: 24px 16px;
  background: #eeedff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  display: flex;
`

export const StyledCheckboxLabel = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  ul {
    padding-left: 14px;
  }
`

export const StyledCheckbox = styled.div`
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 4px;
  border: 1px #979797 solid;
`

export const StyledCheckboxText = styled.div`
  color: black;
  font-size: 12px;
  font-family: 'Gotham';
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
`

export const StyledButtonContainer = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  display: inline-flex;
  @media (max-width: ${ClinTheme.breakpoints[0]}px) {
    gap: 8px; // Reduce gap on smaller screens
  }
`

export const StyledModalText = styled.div`
  p {
    font-size: ${ClinTheme.fontSizes[2]}px; // 16px
    strong {
      color: ${ClinTheme.colors.primaryMid};
    }
  }
`

export const StyledCancelButton = styled(ClinButton)`
  width: 98px;
`

export const StyledAssociateButton = styled(ClinButton)`
  width: 128px;
  padding: 0 24px;
  background-color: ${ClinTheme.colors.primaryLight};
  border-radius: 24px;
  border-color: ${ClinTheme.colors.primaryLight};
  color: ${ClinTheme.colors.lightGrey};
  font-family: ${ClinTheme.fonts.body};
  font-weight: ${ClinTheme.fontWeights.medium};
  font-size: ${ClinTheme.fontSizes[0]};
  letter-spacing: 0.65px;
  justify-content: center;
  display: flex;
  align-items: center;
  &:disabled {
    background-color: ${ClinTheme.colors
      .midGrey}; // Adjusted for disabled state
    color: ${ClinTheme.colors.darkGrey}; // Text color in disabled state
    border-color: ${ClinTheme.colors.midGrey}; // Border color in disabled state
    cursor: not-allowed; // Change cursor to indicate action is not allowed
  }
  @media (max-width: ${ClinTheme.breakpoints[0]}px) {
    width: auto; // Allow width to adjust on smaller screens
    padding: 0 12px; // Reduce padding on smaller screens
  }
`

export const StyledUlList = styled.ul`
  margin-top: 12px;
  margin-bottom: 0px;
`
