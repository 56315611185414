import { TFunction } from 'i18next'
import React, { FunctionComponent, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledClinTableRow } from './ProgramsTable.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinChip } from '../../components/ClinChip'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinSpinner } from '../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { ClinTabHeader } from '../../components/ClinTabHeader'
import {
  ClinTable,
  ClinTableBodyCell,
  ClinTableHeaderCell
} from '../../components/ClinTable/ClinTable'
import { ClinTableOrderToggle } from '../../components/ClinTableOrderToggle'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinTabs } from '../../components/ClinTabs'
import { ClinText } from '../../components/ClinText'
import { ProgramColumnNames } from '../../constants'
import { BasicSearch } from '../../features/Search/BasicSearch'
import { TableFilterSearch } from '../../features/TableFilterSearch'
import { ProgramColumn } from '../../pages/AccessPrograms/AccessProgramsContainer.models'
import { IDefaultStyleProps } from '../../types'
import { IProgramSummary } from '../../types/IProgram'
import { CountryDto } from '../../types/swaggerTypes'
import { NoPrograms } from '../NoPrograms'

interface IProgramsTableProps extends IDefaultStyleProps {
  /** Whether table is loading or not */
  isLoading: boolean
  /** The array of orders coming through */
  programs?: IProgramSummary[]
  /** Columns to show on the orders table */
  columns: ProgramColumn[]
  /** Columns to hide sort toggle for */
  hideSortToggleForColumnIndices?: number[]
  /** The selected order id */
  selectedOrderId?: string
  /** The selected tab index */
  selectedTabIndex?: number
  /** The selected column for sorting */
  selectedSortColumn?: ProgramColumn
  /** The selected sort direction */
  selectedSortDirection?: SortDirectionType
  /** The initial query */
  initialQuery?: string
  /** If the user is MA only or not */
  isMaUser?: boolean
  /** Supplied contact data */
  supportContact?: CountryDto
  /** Handle the row clicked and return id */
  handleRowClicked?: (orderId: string) => void
  /** Handle column sort toggle */
  handleToggleSort?: (columnName: string) => void
  /** Handle tab button selection */
  handleTabSelected: (selectedTabIndex: number) => void
  /** Handle expand/collapse order row */
  handleToggleOrderRow: (programId: number) => void
  /** When a search query is entered and ENTER pressed */
  handleOnSearch?: (query: string) => void
}

const getCellContent = (
  column: ProgramColumn,
  program: IProgramSummary,
  handleToggleOrderRow: (programID: number) => void,
  transFn: TFunction
): ReactNode | string => {
  const {
    programName, // programName
    sponsor, // sponsor
    therapeuticArea, // therapeuticAreas
    status, // status
    availability // availability
  } = program
  const closedStatus = status?.length && status.toLowerCase() === 'closed'
  switch (column.name) {
    case ProgramColumnNames.ProgramName:
      return (
        <ClinText fontWeight={ClinTheme.fontWeights.bold} as="span">
          {programName}
        </ClinText>
      )
    case ProgramColumnNames.Sponsor:
      return <ClinText as="div">{sponsor}</ClinText>
    case ProgramColumnNames.TherapeuticArea:
      return <ClinChip>{therapeuticArea}</ClinChip>
    case ProgramColumnNames.Status:
      return (
        <ClinText
          as="span"
          color={
            closedStatus
              ? ClinTheme.colors.redInvalid
              : ClinTheme.colors.greenValid
          }
          fontWeight={ClinTheme.fontWeights.bold}
        >
          {status}
        </ClinText>
      )
    case ProgramColumnNames.Availability:
      return (
        <ClinText as="span" fontWeight={ClinTheme.fontWeights.bold}>
          {formatAvailability(transFn, availability)}
        </ClinText>
      )
    default:
      return 'Unknown type'
  }
}

/**  Need to format text in Availability column cause
 * it can't be done on backend side at the moment*/
const formatAvailability = (transFn: TFunction, avlbt?: string) => {
  if (avlbt === 'Not Available in your country') {
    // const transformedaAvlbt = avlbt.replace('Available', 'available')
    return (
      <>
        {transFn('access_programs:not_available_in_your_country')}
        <ClinSpacer height={ClinTheme.space[1]} />
        <ClinText
          as="span"
          fontWeight={ClinTheme.fontWeights.normal}
          color={ClinTheme.colors.primaryLight}
        >
          {transFn('access_programs:register_your_interest')}
        </ClinText>
      </>
    )
  }
  if (avlbt === 'Enrol') {
    return transFn('access_programs:available')
  }
  return avlbt
}

const getColumnWidth = (column: ProgramColumnNames) => {
  switch (column) {
    case ProgramColumnNames.ProgramName:
      return '18%'
    case ProgramColumnNames.Sponsor:
      return '18%'
    case ProgramColumnNames.TherapeuticArea:
    case ProgramColumnNames.Status:
      return '20%'
    case ProgramColumnNames.Availability:
      return '22%'
    default:
      return 'auto'
  }
}

export const ProgramsTable: FunctionComponent<IProgramsTableProps> = ({
  isLoading = true,
  columns,
  programs,
  selectedTabIndex = 0,
  selectedSortColumn,
  selectedSortDirection = SortDirectionType.None,
  initialQuery,
  isMaUser,
  supportContact,
  handleRowClicked,
  handleToggleSort,
  handleTabSelected,
  handleToggleOrderRow,
  handleOnSearch
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ClinTabs
        activeItemIndex={selectedTabIndex}
        handleSelected={handleTabSelected}
        isDisabled={isLoading}
      >
        <ClinTabHeader title={t('access_programs:tab_one_title')} />
        <ClinTabHeader title={t('access_programs:tab_two_title')} />
      </ClinTabs>
      <ClinSpacer height={ClinTheme.space[4]} />
      {selectedTabIndex === 1 && (
        <TableFilterSearch
          isFiltering={false}
          searchInput={() => (
            <BasicSearch
              initialQuery={initialQuery}
              placeholder={t('access_programs:search_placeholder')}
              debounceTimeMs={500}
              handleOnChange={(query) =>
                handleOnSearch && handleOnSearch(query)
              }
            />
          )}
        />
      )}
      {isLoading && (
        <StyledSpinnerContainer>
          <ClinSpinner size={ClinTheme.space[7]} />
        </StyledSpinnerContainer>
      )}
      {!isLoading && programs && (
        <>
          <ClinTable
            tableHeader={() =>
              columns.map((column: ProgramColumn, index: number) =>
                column.name === ProgramColumnNames.Availability &&
                selectedTabIndex === 0 ? null : (
                  <ClinTableHeaderCell
                    key={index}
                    width={getColumnWidth(column.name)}
                  >
                    <ClinTableOrderToggle
                      sortDirection={
                        selectedSortColumn === column
                          ? selectedSortDirection
                          : SortDirectionType.None
                      }
                      onClick={() =>
                        handleToggleSort && handleToggleSort(column.viewName)
                      }
                    >
                      {t(column.translationKey)}
                    </ClinTableOrderToggle>
                  </ClinTableHeaderCell>
                )
              )
            }
          >
            {programs.length > 0 ? (
              programs.map((program: any, rowIndex: any) => {
                return (
                  <StyledClinTableRow key={rowIndex}>
                    {columns.map((column: ProgramColumn, index: number) =>
                      column.name === ProgramColumnNames.Availability &&
                      selectedTabIndex === 0 ? null : (
                        <ClinTableBodyCell
                          key={index}
                          onCellClick={() => {
                            handleRowClicked &&
                              handleRowClicked(program.programId)
                          }}
                          onCellEnter={() =>
                            handleRowClicked &&
                            program.brands.length < 2 &&
                            handleRowClicked(program.programId)
                          }
                          verticalAlign={'top'}
                        >
                          {getCellContent(
                            column,
                            program,
                            handleToggleOrderRow,
                            t
                          )}
                        </ClinTableBodyCell>
                      )
                    )}
                  </StyledClinTableRow>
                )
              })
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <NoPrograms
                    selectedTabIndex={selectedTabIndex}
                    isMaUser={isMaUser}
                    supportContact={supportContact}
                  />
                </td>
              </tr>
            )}
          </ClinTable>
          <ClinSpacer height={ClinTheme.space[9]} hasBorder />
        </>
      )}
    </>
  )
}
