import { rgba } from 'polished'
import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

const mediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledOpaOrderItemDetailHeader = styled.div({
  display: 'none',
  paddingBottom: ClinTheme.space[2],
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.midGrey}`,
  margin: `${ClinTheme.space[4]}px ${ClinTheme.space[5]}px ${ClinTheme.space[0]}px ${ClinTheme.space[5]}px`,
  [mediaQuery(mediumUpBreakpoint)]: {
    display: 'block'
  },
  [`@media print`]: {
    display: 'none'
  }
})

export const StyledOpaOrderDetailShipping = styled.div({
  padding: `${ClinTheme.space[4]}px ${ClinTheme.space[5]}px`,
  background: rgba(ClinTheme.colors.primaryLightest, 0.15),
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`
})
