// PatientEnrollModalStyles.ts
import styled from 'styled-components'

import { ClinTheme } from '../../../../ClinTheme'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinText } from '../../../../components/ClinText'

export const StyledModalContent = styled.div`
  background: ${ClinTheme.colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  @media (max-width: ${ClinTheme.breakpoints[0]}px) {
    padding: 10px; // Decrease padding on smaller screens
  }
`

const StyledText = styled(ClinText)`
  max-width: 455px;
  word-wrap: break-word;
`

export const StyledCancelButton = styled(ClinButton)`
  width: 98px;
`

export const StyledEnrollButton = styled(ClinButton)`
  width: 128px;
  background-color: ${ClinTheme.colors.primaryLight};
  border-color: ${ClinTheme.colors.primaryLight};
`

export const StyledHeaderText = styled(StyledText)`
  color: ${ClinTheme.colors.primaryDark};
  font-size: 18px; // Keep font size for larger screens
  font-weight: ${ClinTheme.fontWeights.bold};
  line-height: 24px;

  @media (max-width: ${ClinTheme.breakpoints[0]}px) {
    font-size: 16px; // Decrease font size on smaller screens
  }
`

export const StyledSuggestionText = styled(StyledText)`
  color: ${ClinTheme.colors.black};
  font-size: 16px; // Keep font size for larger screens
  font-weight: ${ClinTheme.fontWeights.normal};
  line-height: 24px;

  @media (max-width: ${ClinTheme.breakpoints[0]}px) {
    font-size: 14px; // Decrease font size on smaller screens
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 14px;

  @media (max-width: ${ClinTheme.breakpoints[0]}px) {
    gap: 8px; // Reduce gap on smaller screens
  }
`
