import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { mediaQueryDown } from '../../utils/mediaQuery'

const MediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledFilterWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [mediaQueryDown(MediumUpBreakpoint)]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
})

export const StyledFilter = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'relative'
})

export const StyledSortWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '6px'
})

export const StyledSortDropdown = styled.div({
  position: 'absolute',
  background: 'white',
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
  borderRadius: '3px',
  top: '27px',
  left: 0,
  zIndex: 6,
  textAlign: 'right',
  padding: '16px 8px 8px 8px'
})

interface IStyledSortButtonProps {
  active?: boolean
}

export const StyledSortButton = styled(ClinButton)<IStyledSortButtonProps>(
  {
    width: '100%',
    color: ClinTheme.colors.primary,
    textDecoration: 'none',
    fontSize: `${ClinTheme.fontSizes[1]}px`,

    lineHeight: ClinTheme.lineHeights.heading[0],
    display: 'block',
    textAlign: 'right',
    marginBottom: '8px',
    textTransform: 'none'
  },
  ({ active }: IStyledSortButtonProps) => ({
    fontWeight: active
      ? ClinTheme.fontWeights.medium
      : ClinTheme.fontWeights.normal
  })
)
