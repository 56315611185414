import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { StyledSummaryOrderItem } from './CheckoutSummaryOrderItem.styles'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

const MediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledCheckoutSummary = styled.div({
  [`${StyledSummaryOrderItem}:last-of-type`]: {
    borderBottom: 'none',
    paddingBottom: '0'
  }
})

export const StyledCheckoutSummaryOrder = styled.div({})

export const StyledSummaryHeading = styled.div({
  display: 'inline-flex',
  alignItems: 'baseline'
})

export const StyledSummaryIcon = styled.div({
  borderRadius: '50%',
  width: ClinTheme.space[5],
  height: ClinTheme.space[5],
  display: 'inline-flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  backgroundColor: ClinTheme.colors.secondary
})

export const StyledCheckoutTableSummaryHeader = styled.div({
  display: 'none',
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.midGrey}`,
  paddingBottom: ClinTheme.space[1],
  [mediaQuery(MediumUpBreakpoint)]: {
    display: 'block'
  }
})

export const StyledRequiredDocument = styled.div({
  marginTop: ClinTheme.space[3]
})

export const StyledOrderLink = styled(Link)({
  fontSize: ClinTheme.fontSizes[2],
  lineHeight: ClinTheme.lineHeights.largeParagraph,
  marginTop: ClinTheme.space[2],
  marginBottom: ClinTheme.space[2],
  textDecoration: 'underline',
  color: ClinTheme.colors.primaryMid
})
