import { lighten, transparentize } from 'polished'
import styled from 'styled-components'

import { ICustomSelectOption } from './CustomSelect'
import { ClinTheme } from '../../ClinTheme'
import {
  StyledClinDropdown,
  StyledClinDropdownContainer
} from '../../components/ClinDropdown/ClinDropdown.styles'
import { StyledClinText } from '../../components/ClinText/ClinText.styles'

export interface ICustomSelectStyles
  extends React.HTMLAttributes<HTMLDivElement> {
  /** Indicated an already active option */
  isActive?: boolean
  /** An array of custom select options */
  options?: ICustomSelectOption[]
  /** Sets field styles to error */
  hasError?: boolean
}

export const StyledCustomSelect = styled.div<ICustomSelectStyles>({
  fontFamily: ClinTheme.fonts.body,
  fontWeight: ClinTheme.fontWeights.normal,

  [`${StyledClinDropdown}, ${StyledClinDropdownContainer}`]: {
    width: '100%'
  }
})

export const StyledCustomSelectTrigger = styled.div<ICustomSelectStyles>({
  position: 'relative',
  width: '100%',
  height: ClinTheme.space[7],
  marginBottom: ClinTheme.space[1]
})

export const StyledPhysicianCustomSelectTrigger =
  styled.div<ICustomSelectStyles>({
    position: 'relative',
    width: '100%',
    height: ClinTheme.space[6],
    marginBottom: ClinTheme.space[1]
  })

export const StyledCustomSelectTriggerInner = styled.div<ICustomSelectStyles>(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${ClinTheme.space[1]}px ${ClinTheme.space[2]}px ${ClinTheme.space[1]}px ${ClinTheme.space[3]}px`,
    lineHeight: ClinTheme.lineHeights.heading[1],
    border: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`,
    width: '100%',
    borderRadius: ClinTheme.radii[2],
    background: ClinTheme.colors.white,
    boxSizing: 'border-box',

    [`${StyledClinText}`]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  ({ isActive, options }) => ({
    cursor: !options || options.length === 0 ? 'default' : 'pointer',
    color: isActive ? ClinTheme.colors.black : ClinTheme.colors.darkGrey,

    [`${StyledClinText}`]: {
      color: isActive ? ClinTheme.colors.black : ClinTheme.colors.darkGrey,

      ':focus, :active': {
        color:
          options && options.length > 0
            ? ClinTheme.colors.black
            : ClinTheme.colors.darkGrey
      }
    },

    ':focus, :active': {
      border:
        options && options.length > 0
          ? `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryLight}`
          : `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`,
      color:
        options && options.length > 0
          ? ClinTheme.colors.black
          : ClinTheme.colors.darkGrey,
      outline: 'none',
      boxShadow:
        !options || options.length === 0
          ? 'none'
          : `0px 0px 3px 0px ${ClinTheme.colors.grey}`
    }
  })
)

export const StyledPhysicianCustomSelectTriggerInner =
  styled.div<ICustomSelectStyles>(
    {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${ClinTheme.space[1]}px ${ClinTheme.space[2]}px ${
        ClinTheme.space[1]
      }px ${20}px`,
      lineHeight: ClinTheme.lineHeights.heading[1],
      border: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`,
      width: '100%',
      height: '100%',
      borderRadius: ClinTheme.radii[2],
      background: ClinTheme.colors.white,
      boxSizing: 'border-box',

      [`${StyledClinText}`]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    ({ isActive, options }) => ({
      cursor: !options || options.length === 0 ? 'default' : 'pointer',
      color: isActive ? ClinTheme.colors.black : ClinTheme.colors.darkGrey,

      [`${StyledClinText}`]: {
        color: isActive ? ClinTheme.colors.black : ClinTheme.colors.darkGrey,

        ':focus, :active': {
          color:
            options && options.length > 0
              ? ClinTheme.colors.black
              : ClinTheme.colors.darkGrey
        }
      },

      ':focus, :active': {
        border:
          options && options.length > 0
            ? `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryLight}`
            : `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`,
        color:
          options && options.length > 0
            ? ClinTheme.colors.black
            : ClinTheme.colors.darkGrey,
        outline: 'none',
        boxShadow:
          !options || options.length === 0
            ? 'none'
            : `0px 0px 3px 0px ${ClinTheme.colors.grey}`
      }
    })
  )

export const StyledCustomSelectOptions = styled.div<ICustomSelectStyles>({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: `0px 0px ${ClinTheme.radii[2]}px ${ClinTheme.radii[2]}px`,
  overflow: 'hidden',
  border: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryLight}`,
  boxShadow: `0px 0px 3px 0px ${ClinTheme.colors.grey}`,
  background: ClinTheme.colors.white
})

export const StyledCustomSelectPhysicianOptions =
  styled.div<ICustomSelectStyles>(
    {
      overflow: 'hidden',
      boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 4px 8px 0px rgba(0, 0, 0, 0.16)`,
      background: ClinTheme.colors.white,

      zIndex: '999',
      fontSize: ClinTheme.fontSizes[3],
      fontWeight: ClinTheme.fontWeights.light,
      color: ClinTheme.colors.primary,
      backgroundColor: ClinTheme.colors.white,
      cursor: 'pointer',
      [`a`]: {
        display: 'block',
        color: ClinTheme.colors.primary
      },
      [`:hover, :focus`]: {
        backgroundColor: lighten(0.2, ClinTheme.colors.primaryLightest)
      }
    },
    ({ isActive }) => ({
      backgroundColor: isActive
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.white
    })
  )

export const StyledCustomSelectOption = styled.div<ICustomSelectStyles>(
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
    cursor: 'pointer',

    ':hover, :focus': {
      background: transparentize(0.85, ClinTheme.colors.primaryLightest),
      outline: 'none'
    },

    [`${StyledClinText}`]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  ({ isActive }) => ({
    background: isActive
      ? transparentize(0.85, ClinTheme.colors.primaryLightest)
      : ClinTheme.colors.white
  })
)

export const StyledCustomSelectTick = styled.div<ICustomSelectStyles>({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 0 auto',
  width: ClinTheme.space[4],
  height: ClinTheme.space[4],
  marginLeft: ClinTheme.space[2],
  borderRadius: '100%',
  background: ClinTheme.colors.greenValid,
  color: ClinTheme.colors.white
})
