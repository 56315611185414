import React, { FunctionComponent } from 'react'

import {
  StyledTableWrapper,
  StyledTable,
  StyledTableRow,
  StyledTableHeaderCell,
  IStyledTableHeaderCellProps,
  StyledTableHeader,
  StyledTableBody,
  StyledTableBodyCell,
  IStyledTableBodyCellProps,
  StyledPatientTableHeaderCell
} from './ClinTable.styles'
import { IDefaultStyleProps } from '../../types'
import { ClinSpacer } from '../ClinSpacer'

export interface IClinTableStyleProps extends IDefaultStyleProps {
  /** Render prop with header of the table */
  tableHeader?(): React.ReactNode | React.ReactNode[]
  /** Determines a fixed height for the table */
  height?: number | string
  borderSpacing?: string
  borderColapseSeparate?: boolean
  tableFor?: string // Prop to identify the table
  padding?: string
  spacer?: string
  customBoxShadow?: string
  cursor?: string
}

export const ClinTable: FunctionComponent<IClinTableStyleProps> = ({
  tableHeader,
  children,
  height,
  className,
  borderColapseSeparate,
  borderSpacing,
  padding,
  spacer,
  cursor,
  customBoxShadow
}) => (
  <StyledTableWrapper className={className} height={height}>
    <StyledTable
      borderColapseSeparate={borderColapseSeparate}
      borderSpacing={borderSpacing}
      padding={padding}
    >
      <StyledTableHeader>
        {tableHeader && (
          <StyledTableRow style={cursor ? { cursor: cursor } : {}}>
            {tableHeader()}
          </StyledTableRow>
        )}
      </StyledTableHeader>
      {spacer && <ClinSpacer height={spacer} />}
      <StyledTableBody customBoxShadow={customBoxShadow}>
        {children}
      </StyledTableBody>
    </StyledTable>
  </StyledTableWrapper>
)

export interface IClinTableRowStyles {
  /** Sets disabled row styles and onClick will not be called */
  disabled?: boolean
  background?: string
}

interface IClinTableRowProps
  extends IClinTableRowStyles,
    IDefaultStyleProps,
    React.TableHTMLAttributes<HTMLTableRowElement> {
  /** Handle the row clicked */
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void
  /** Handle enter key when row is focused */
  onRowEnter?: (event: React.KeyboardEvent<HTMLTableRowElement>) => void
}

export const ClinTableRow: FunctionComponent<IClinTableRowProps> = ({
  disabled,
  background,
  onRowClick,
  onRowEnter,
  children,
  ...props
}) => (
  <StyledTableRow
    background={background}
    tabIndex={0}
    {...props}
    disabled={disabled}
    onClick={(event) => !disabled && onRowClick && onRowClick(event)}
    onKeyDown={(event) =>
      ['Enter', 'NumpadEnter'].includes(event.key) &&
      !disabled &&
      onRowEnter &&
      onRowEnter(event)
    }
  >
    {children}
  </StyledTableRow>
)

export const ClinTableHeaderCell: FunctionComponent<
  IStyledTableHeaderCellProps
> = ({ children, width, background, ...props }) => (
  <StyledTableHeaderCell width={width} {...props} background={background}>
    {children}
  </StyledTableHeaderCell>
)

export interface IClinBodyCellStyles {
  /** Sets disabled row styles and onClick will not be called */
  disabled?: boolean
}

interface IClinTableBodyCellProps
  extends IClinBodyCellStyles,
    IDefaultStyleProps,
    IStyledTableBodyCellProps {
  colSpan?: number
  /** Handle the row clicked */
  onCellClick?: (event: React.MouseEvent<HTMLTableCellElement>) => void
  /** Handle enter key when row is focused */
  onCellEnter?: (event: React.KeyboardEvent<HTMLTableCellElement>) => void
}

export const ClinTableBodyCell: FunctionComponent<IClinTableBodyCellProps> = ({
  disabled,
  children,
  onCellClick,
  onCellEnter,
  ...props
}) => (
  <StyledTableBodyCell
    onClick={(event) => !disabled && onCellClick && onCellClick(event)}
    onKeyDown={(event) =>
      ['Enter', 'NumpadEnter'].includes(event.key) &&
      !disabled &&
      onCellEnter &&
      onCellEnter(event)
    }
    {...props}
  >
    {children}
  </StyledTableBodyCell>
)

export const ClinPatientTableHeaderCell: FunctionComponent<
  IStyledTableHeaderCellProps
> = ({ children, width, background, color, ...props }) => (
  <StyledPatientTableHeaderCell
    color={color}
    width={width}
    {...props}
    background={background}
  >
    {children}
  </StyledPatientTableHeaderCell>
)
