import React, { FunctionComponent } from 'react'
import { Col, Row, Container } from 'react-grid-system'

import { ClinTheme } from '../../ClinTheme'
import { ICrumb } from '../ClinBreadcrumb'
import { ClinSpinner } from '../ClinSpinner'
import { StyledSpinnerContainer } from '../ClinSpinner/ClinSpinner.styles'

interface IPageContentFrameProps {
  /**  Breadcrumbs */
  crumbs?: ICrumb[]
  /** Page layout variant, e.g. narrow page */
  layoutVariant?: PageLayoutVariant
  /** Whether order is loading or not */
  isLoading?: boolean
  /** A css class */
  className?: string
  /** Allow children to not be contained and use full browser width */
  isFullWidth?: boolean
  /** Hide bradcrumbs for aus users */
  hideBreadcrumbs?: boolean
  marginBottom?: string
}

export enum PageLayoutVariant {
  Default,
  NarrowContent
}

export const ClinPageContentFrame: FunctionComponent<
  IPageContentFrameProps
> = ({
  children,
  crumbs,
  hideBreadcrumbs,
  layoutVariant = PageLayoutVariant.Default,
  isLoading,
  className,
  isFullWidth,
  marginBottom
}) => {
  return (
    <>
      {isLoading ? (
        <Container>
          <Row align="center">
            <Col>
              <StyledSpinnerContainer>
                <ClinSpinner size={ClinTheme.space[7]} />
              </StyledSpinnerContainer>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          {isFullWidth ? (
            children
          ) : (
            <Container
              className={`page-content ${className}`}
              style={{
                marginBottom: marginBottom ?? ClinTheme.space[9]
              }}
            >
              {layoutVariant === PageLayoutVariant.NarrowContent ? (
                <Row className="narrow-content">
                  <Col xs={12} md={8} offset={{ xs: 0, md: 2 }}>
                    {children}
                  </Col>
                </Row>
              ) : (
                children
              )}
            </Container>
          )}
        </>
      )}
    </>
  )
}
