import { yupResolver } from '@hookform/resolvers/yup'
import { isArray } from 'lodash'
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import { Col, Row } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { array, object, string } from 'yup'

import {
  StyledLoadingContainer,
  StyledLoadingSpinner
} from './InviteNewUser.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinCheckbox } from '../../../components/ClinCheckbox'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ClinSelect } from '../../../components/ClinSelect'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinTextInput } from '../../../components/ClinTextInput'
import {
  UserRole,
  ContactTitles,
  IJobType,
  JobTypes,
  PhoneContactResponseType,
  phoneTypesRecord,
  userTitleRecord,
  countryCodes
} from '../../../constants'
import useEmailValidation from '../../../hooks/useCheckEmailError/useCheckEmailError'
import {
  ContactPhoneDto,
  CreateContactRequestDto,
  SpecialismDto
} from '../../../types/swaggerTypes'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../../utils/Forms/getInputPlaceholder'
import {
  getInputValidation,
  ValidationType
} from '../../../utils/Forms/getInputValidation'
import { requiredMinStringLength } from '../../../utils/Forms/requiredMinStringLength'

interface IInviteNewUserProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** An array of specialism values */
  specialisms: SpecialismDto[] | undefined
  /** The possible role types that can be assigned */
  roleTypes: UserRole[]
  /** Whether the submission API request is in progress */
  isSubmitting?: boolean
  /** Form submission handler */
  handleFormSubmission: (data: CreateContactRequestDto) => void
  /** Cancel form and return to previous page */
  handleCancel: () => void
}

const defaultState: CreateContactRequestDto = {
  firstName: '',
  lastName: '',
  title: '',
  emailAddress: '',
  preferredName: '',
  licenseNumber: '',
  specialism: '',
  classification: '',
  phones: [
    {
      phoneId: '',
      phoneType: phoneTypesRecord['GEN'].type,
      countryCode: '',
      areaCode: '',
      phoneNumber: ''
    }
  ],
  roles: [
    {
      roleId: '',
      roleName: ''
    }
  ]
}

export const InviteNewUser: FunctionComponent<IInviteNewUserProps> = ({
  isLoading,
  specialisms,
  roleTypes,
  isSubmitting,
  handleFormSubmission,
  handleCancel
}) => {
  const { t } = useTranslation()

  const requiredValidationText = getInputValidation(
    ValidationType.RequiredField
  )

  const InviteNewUserSchema = object().shape({
    firstName: requiredMinStringLength(100),
    lastName: requiredMinStringLength(100),
    title: string(),
    emailAddress: string().required(requiredValidationText),
    preferredName: string().test(
      'len',
      getInputValidation(ValidationType.MaxiumumCharacters, '240'),
      (val) => {
        return val === '' || (val && val.length < 240) ? true : false
      }
    ),
    licenseNumber: string().when('classification', {
      is: (classification: string) =>
        classification.toLowerCase() === 'pharmacist' ||
        classification.toLowerCase() === 'physician',
      then: requiredMinStringLength(50)
    }),
    specialism: requiredMinStringLength(240),
    classification: string().test(
      'len',
      getInputValidation(ValidationType.MaxiumumCharacters, '240'),
      (val) => {
        return val === '' || (val && val.length < 240) ? true : false
      }
    ),
    phones: array()
      .compact()
      .of(
        object().shape(
          {
            phoneId: string(),
            phoneType: string(),
            requestType: string(),
            areaCode: string().when(['phoneNumber'], {
              is: (phoneNumber: any) => phoneNumber,
              then: requiredMinStringLength(20)
            }),
            phoneNumber: string().when(['areaCode'], {
              is: (areaCode: any) => areaCode,
              then: requiredMinStringLength(20)
            })
          },
          [['areaCode', 'phoneNumber']]
        )
      ),
    roles: array().of(
      object().shape({
        roleName: string()
      })
    )
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
    control
  } = useForm<CreateContactRequestDto>({
    defaultValues: defaultState,
    resolver: yupResolver(InviteNewUserSchema)
  })
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const { emailErrorMessage, checkEmail } = useEmailValidation()

  useEffect(() => {
    register('roles.0.roleName')
    setValue(
      'roles.0.roleName',
      roleTypes && isAdmin ? roleTypes[1].toString() : roleTypes[0].toString()
    )
  }, [isAdmin, register, roleTypes, setValue])

  const contactTitlesOptions = Object.values(ContactTitles)
  const watchClassification = watch('classification')
  const showLicenceNumber =
    watchClassification.toLowerCase() === 'pharmacist' ||
    watchClassification.toLowerCase() === 'physician'

  return (
    <ClinPageContentFrame>
      {isLoading && (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      )}
      {!isLoading && (
        <form
          onSubmit={handleSubmit((values) => {
            const isEmailError = checkEmail(values.emailAddress)
            if (!isEmailError) {
              const updatedValues = values
              let filterPhoneResponses = null
              if (updatedValues.phones && updatedValues.phones.length > 0) {
                filterPhoneResponses = updatedValues.phones.filter(
                  (item: ContactPhoneDto) => item.areaCode && item.phoneNumber
                )
                updatedValues.phones = filterPhoneResponses
              }
              handleFormSubmission(updatedValues)
            }
          })}
        >
          <Row>
            <Col xs={12} lg={7}>
              <Row>
                <Col xs={12} lg={9}>
                  <ClinText
                    as="h1"
                    variant={TypographyVariant.H2}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {t('invite_new_user:title')}
                  </ClinText>
                  <ClinText variant={TypographyVariant.LargeParagraph}>
                    {t('invite_new_user:description')}
                  </ClinText>
                  <ClinSpacer />
                  <ClinText variant={TypographyVariant.LargeParagraph}>
                    {t('invite_new_user:mandatory_message')}
                  </ClinText>
                </Col>
              </Row>
              <ClinSpacer height={ClinTheme.space[4]} />
              <Row>
                <Col xs={12} lg={9}>
                  <ClinTextInput
                    id="email-address"
                    {...register(`emailAddress`)}
                    label={t('invite_new_user:email_address_label')}
                    width="100%"
                    hasError={!!emailErrorMessage}
                    prompt={emailErrorMessage}
                  />
                  <ClinSpacer height={ClinTheme.space[2]} />
                  <ClinText>{t('invite_new_user:email_address_info')}</ClinText>
                </Col>
              </Row>
              <ClinSpacer height={ClinTheme.space[4]} />
              <Row>
                <Col xs={12} lg={9}>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ClinSelect
                        id="title"
                        label={t('invite_new_user:title_label')}
                        width="100%"
                        value={value}
                        onChange={(
                          changeValue: ChangeEvent<HTMLSelectElement>
                        ) => onChange(changeValue.currentTarget.value)}
                        hasError={!!(errors && errors.title)}
                        prompt={errors.title?.message}
                      >
                        <option value="" disabled={true}>
                          {getInputPlaceholder(PlaceholderType.SelectInput)}
                        </option>
                        {contactTitlesOptions.map((title: string, index) => {
                          return (
                            <option key={`specialism${index}`} value={title}>
                              {t(
                                `${
                                  userTitleRecord[title as ContactTitles]
                                    .transKey
                                }`
                              )}
                            </option>
                          )
                        })}
                      </ClinSelect>
                    )}
                  />
                  <ClinSpacer height={ClinTheme.space[4]} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={9}>
                  <ClinTextInput
                    id="first-name"
                    {...register(`firstName`)}
                    label={t('invite_new_user:first_name_label')}
                    width="100%"
                    maxLength={100}
                    hasError={!!(errors && errors.firstName)}
                    prompt={errors.firstName?.message}
                  />
                  <ClinSpacer height={ClinTheme.space[4]} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={9}>
                  <ClinTextInput
                    id="last-name"
                    {...register(`lastName`)}
                    label={t('invite_new_user:last_name_label')}
                    width="100%"
                    maxLength={100}
                    hasError={!!(errors && errors.lastName)}
                    prompt={errors.lastName?.message}
                  />
                  <ClinSpacer height={ClinTheme.space[4]} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={9}>
                  <ClinTextInput
                    id="preferred-name"
                    {...register(`preferredName`)}
                    label={t('invite_new_user:preferred_name_label')}
                    width="100%"
                    maxLength={240}
                    hasError={!!(errors && errors.preferredName)}
                    prompt={errors.preferredName?.message}
                  />
                  <ClinSpacer height={ClinTheme.space[4]} />
                </Col>
              </Row>

              {specialisms && (
                <Row>
                  <Col xs={12} lg={9}>
                    <Controller
                      name="specialism"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ClinSelect
                          id="specialism"
                          label={t('invite_new_user:specialism_label')}
                          width="100%"
                          value={value}
                          onChange={(
                            changeValue: ChangeEvent<HTMLSelectElement>
                          ) => onChange(changeValue.currentTarget.value)}
                          hasError={!!(errors && errors.specialism)}
                          prompt={errors.specialism?.message}
                        >
                          <option value="" disabled={true}>
                            {getInputPlaceholder(PlaceholderType.SelectInput)}
                          </option>
                          {specialisms
                            .sort((a, b) => a.meaning.localeCompare(b.meaning))
                            .map((specialism: SpecialismDto, index) => {
                              return (
                                <option
                                  key={`specialism${index}`}
                                  value={specialism.code}
                                >
                                  {specialism.meaning}
                                </option>
                              )
                            })}
                        </ClinSelect>
                      )}
                    />
                    <ClinSpacer height={ClinTheme.space[4]} />
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12} lg={9}>
                  <Controller
                    name="classification"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ClinSelect
                        id="classification"
                        label={t('invite_new_user:job_type_label')}
                        width="100%"
                        value={value}
                        onChange={(
                          changeValue: ChangeEvent<HTMLSelectElement>
                        ) => onChange(changeValue.currentTarget.value)}
                        hasError={!!(errors && errors.classification)}
                        prompt={errors.classification?.message}
                      >
                        <option value="" disabled={true}>
                          {getInputPlaceholder(PlaceholderType.SelectInput)}
                        </option>
                        {JobTypes.map((jobType: IJobType, index) => {
                          return (
                            <option
                              key={`classification-${index}`}
                              value={jobType.jobCode}
                            >
                              {jobType.view}
                            </option>
                          )
                        })}
                      </ClinSelect>
                    )}
                  />
                  <ClinSpacer height={ClinTheme.space[4]} />
                </Col>
              </Row>
              {showLicenceNumber && (
                <Row>
                  <Col xs={12} lg={9}>
                    <ClinTextInput
                      id="license-number"
                      {...register(`licenseNumber`)}
                      label={t('invite_new_user:license_number_label')}
                      width="100%"
                      maxLength={50}
                      hasError={!!(errors && errors.licenseNumber)}
                      prompt={errors.licenseNumber?.message}
                    />
                    <ClinSpacer height={ClinTheme.space[4]} />
                  </Col>
                </Row>
              )}

              <Row>
                <Col xs={12} lg={4}>
                  <div style={{ display: 'none' }}>
                    <ClinTextInput
                      id="request-type"
                      {...register(`phones.0.requestType`)}
                      value={PhoneContactResponseType.CREATE}
                    />
                  </div>

                  <Controller
                    name="phones.0.countryCode"
                    control={control}
                    defaultValue={'44'}
                    render={({ field: { onChange, value } }) => {
                      !value &&
                        setValue('phones.0.countryCode', '44', {
                          shouldDirty: true
                        })
                      return (
                        <ClinSelect
                          id="country-code"
                          label={t('invite_new_user:country_code_label')}
                          width="100%"
                          defaultValue={value}
                          onChange={(
                            changeValue: ChangeEvent<HTMLSelectElement>
                          ) => onChange(changeValue.currentTarget.value)}
                          hasError={
                            !!(
                              isArray(errors.phones) &&
                              errors.phones[0] !== undefined &&
                              errors.phones[0].countryCode
                            )
                          }
                          prompt={
                            isArray(errors.phones) &&
                            errors.phones[0] !== undefined &&
                            errors.phones[0].countryCode
                              ? errors.phones[0].countryCode?.message
                              : undefined
                          }
                        >
                          <option value="44">+44</option>
                          {countryCodes.map((code: string, index: number) => {
                            return (
                              <option key={`code-${index}`} value={code}>
                                {`+${code}`}
                              </option>
                            )
                          })}
                        </ClinSelect>
                      )
                    }}
                  />
                </Col>
                <Col xs={12} lg={3}>
                  <ClinTextInput
                    id="area-code"
                    {...register(`phones.0.areaCode`)}
                    label={t('invite_new_user:area_code_label')}
                    width="100%"
                    type="tel"
                    maxLength={20}
                    hasError={
                      !!(
                        isArray(errors.phones) &&
                        errors.phones[0] !== undefined &&
                        errors.phones[0].areaCode
                      )
                    }
                    prompt={
                      isArray(errors.phones) &&
                      errors.phones[0] !== undefined &&
                      errors.phones[0].areaCode
                        ? errors.phones[0].areaCode?.message
                        : undefined
                    }
                  />
                </Col>
                <Col xs={12} lg={5}>
                  <ClinTextInput
                    id="phone-number"
                    {...register(`phones.0.phoneNumber`)}
                    label={t('invite_new_user:phone_number_label')}
                    width="100%"
                    type="tel"
                    maxLength={20}
                    hasError={
                      !!(
                        isArray(errors.phones) &&
                        errors.phones[0] !== undefined &&
                        errors.phones[0].phoneNumber
                      )
                    }
                    prompt={
                      isArray(errors.phones) &&
                      errors.phones[0] !== undefined &&
                      errors.phones[0].phoneNumber
                        ? errors.phones[0].phoneNumber?.message
                        : undefined
                    }
                  />
                </Col>
              </Row>
              <ClinSpacer height={ClinTheme.space[4]} />

              <ClinSpacer height={ClinTheme.space[4]} />
              <Row>
                <Col xs={12} lg={9}>
                  <ClinText as="h4" variant={TypographyVariant.H4}>
                    {t('invite_new_user:permissions')}
                  </ClinText>
                  <ClinText
                    fontWeight={ClinTheme.fontWeights.bold}
                    variant={TypographyVariant.LargeParagraph}
                  >
                    {t('invite_new_user:is_this_an_administrator')}
                  </ClinText>
                  <ClinSpacer height={ClinTheme.space[4]} />

                  <ClinCheckbox
                    id="is-administrator"
                    label={t('invite_new_user:administrator_label')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setIsAdmin(event.currentTarget.checked)
                    }
                  />

                  <ClinSpacer height={ClinTheme.space[4]} />
                </Col>
              </Row>
              <ClinSpacer height={ClinTheme.space[4]} />

              <ClinGroup>
                <ClinButton onClick={() => handleCancel()}>
                  {t('common:buttons.cancel')}
                </ClinButton>
                <StyledLoadingContainer>
                  <ClinButton
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting || !isValid}
                  >
                    {t('common:buttons.invite')}
                  </ClinButton>
                  {isSubmitting && (
                    <StyledLoadingSpinner>
                      <ClinSpinner />
                    </StyledLoadingSpinner>
                  )}
                </StyledLoadingContainer>
              </ClinGroup>
            </Col>
          </Row>
        </form>
      )}
    </ClinPageContentFrame>
  )
}
