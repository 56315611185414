import { rgba } from 'polished'
import styled from 'styled-components'

import { ClinTheme } from '../../../../ClinTheme'

const MobileBreakpoint = ClinTheme.breakpoints[1]

export const StyledTooltipFilterWrapper = styled.span({
  position: 'absolute',
  left: '75px'
})

export const StyledFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${ClinTheme.space[3]}px;
  padding-top: ${ClinTheme.space[5]}px;

  @media (max-width: ${MobileBreakpoint}px) {
    padding: 0 16px 24px 16px;
    flex-wrap: wrap;
  }
`

export const StyledFilterButtonGroup = styled.div`
  display: flex;
  gap: ${ClinTheme.space[2]}px;

  @media (max-width: ${MobileBreakpoint}px) {
    flex-wrap: wrap;
  }
`

export const StyledFilterButton = styled.button<{
  isActive: boolean
  isFeatureShow: boolean
}>`
  padding: 4px 16px;
  border: 1px solid
    ${(props) =>
      props.isActive ? ClinTheme.colors.primaryMid : ClinTheme.colors.darkGrey};
  border-color: ${(props) =>
    props.isFeatureShow ? ClinTheme.colors.white : ClinTheme.colors.primaryMid};
  border-radius: 4px;
  background-color: ${(props) =>
    props.isActive ? rgba(114, 57, 255, 0.15) : 'white'};
  color: ${ClinTheme.colors.primaryLight};
  font-family: 'HCo Gotham', sans-serif;
  font-weight: ${ClinTheme.fontWeights.medium};
  font-size: ${ClinTheme.fontSizes[1]}px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`
