import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { StyledClinText } from '../../components/ClinText/ClinText.styles'
import { StyledLabel } from '../../components/ClinTextInput/ClinTextInput.styles'
import { mediaQuery } from '../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

interface ITableFilterHeaderProps {
  /*** Whether all the filters are showing **/
  isShowing: boolean
}

interface IStyledSearch {
  /*** Are filters available  **/
  isFiltering: boolean
}

export const StyledTableFilterHeader = styled.div<ITableFilterHeaderProps>(
  {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [mediaQuery(mobileUpBreakpoint)]: {
      flexWrap: 'nowrap'
    }
  },
  ({ isShowing }: ITableFilterHeaderProps) => ({
    borderBottom: `solid ${isShowing ? ClinTheme.borderWidths[0] : 0}px ${
      ClinTheme.colors.grey
    }`
  })
)

export const StyledTableFilterSearch = styled.div({
  backgroundColor: ClinTheme.colors.lightGrey,
  border: `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.grey}`,
  marginBottom: ClinTheme.space[3]
})

export const StyledSearch = styled.div<IStyledSearch>(
  {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    [StyledLabel]: {
      display: 'inline-block',
      marginBottom: 0,
      marginLeft: ClinTheme.space[2],
      marginRight: ClinTheme.space[3]
    },
    width: '100%',
    padding: ClinTheme.space[3],
    [StyledClinText]: {
      display: 'none'
    },
    [mediaQuery(mobileUpBreakpoint)]: {
      width: 'auto',
      [StyledClinText]: {
        display: 'initial'
      }
    }
  },
  ({ isFiltering }: IStyledSearch) => ({
    [mediaQuery(mobileUpBreakpoint)]: {
      padding: !isFiltering
        ? `${ClinTheme.space[3]}px 224px ${ClinTheme.space[3]}px 0`
        : 0
    }
  })
)
// TODO: Fix for placeholder being too wide - needs designing
// export const StyledSearchInputContainer = styled.div({
//   flex: '1 1 0',
//   '* ::placeholder': {
//     fontSize: ClinTheme.fontSizes[0]
//   },
//   [mediaQuery(mobileUpBreakpoint)]: {
//     '* ::placeholder': {
//       fontSize: 'inherit'
//     }
//   }
// })

interface ITableFilterSearchStyles {
  /*** Whether any filters are applied **/
  areFiltersApplied: boolean
}

export const StyledTabButton = styled.div<ITableFilterSearchStyles>(
  {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-out',
    padding: `${ClinTheme.space[3]}px ${ClinTheme.space[4]}px ${ClinTheme.space[3]}px ${ClinTheme.space[3]}px`,
    color: ClinTheme.colors.primaryMid,
    backgroundColor: ClinTheme.colors.lightGrey,
    [`:hover`]: {
      color: ClinTheme.colors.white,
      backgroundColor: ClinTheme.colors.primaryMid,
      [`${StyledClinText}`]: {
        color: ClinTheme.colors.white
      }
    },
    width: '100%',
    [mediaQuery(mobileUpBreakpoint)]: {
      width: 'auto',
      borderRight: `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.grey}`,
      borderBottom: 'none',
      padding: `${ClinTheme.space[4]}px ${ClinTheme.space[4]}px ${ClinTheme.space[4]}px ${ClinTheme.space[3]}px`
    }
  },
  ({ areFiltersApplied }: ITableFilterSearchStyles) => ({
    color: areFiltersApplied
      ? ClinTheme.colors.white
      : ClinTheme.colors.primaryLight,
    backgroundColor: areFiltersApplied
      ? ClinTheme.colors.primaryMid
      : 'initial',
    [`${StyledClinText}`]: {
      color: areFiltersApplied
        ? ClinTheme.colors.white
        : ClinTheme.colors.primaryLight
    }
  })
)

export const StyledFilterGroupContainer = styled.div({
  position: 'relative',
  [`.clin-button.clear-btn`]: {
    position: 'absolute',
    right: ClinTheme.space[3],
    top: ClinTheme.space[4]
  }
})

export const StyledFilterMobileFooter = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: ClinTheme.space[4],
  [mediaQuery(mobileUpBreakpoint)]: {
    display: 'none'
  }
})
