import React, { FunctionComponent, ReactNode } from 'react'
import { Col, Row } from 'react-grid-system'
import { Trans, useTranslation } from 'react-i18next'

import { MyPhysiciansColumn } from './MyPhysicians.model'
import { ClinTheme } from '../../ClinTheme'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinPagination } from '../../components/ClinPagination'
import {
  getCurrentPage,
  getTotalPages,
  IPagination
} from '../../components/ClinPagination/ClinPagination.model'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinSpinner } from '../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { ClinTable } from '../../components/ClinTable'
import {
  ClinTableBodyCell,
  ClinTableRow
} from '../../components/ClinTable/ClinTable'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { NoPhysicians } from '../../features/NoPhysicians'
import { PhysiciansSummaryDto } from '../../types/swaggerTypes'

interface IMyPhysiciansProps {
  /** The array of physicians search results */
  physicians?: PhysiciansSummaryDto[]
  /** Whether table is loading or not */
  isLoading?: boolean
  /** Columns to show on the physicians table */
  columns: MyPhysiciansColumn[]
  /** Pagination */
  pagination?: IPagination
  /** User country */
  userCountry?: string
  /** When table row clicked */
  handleRowClicked?: (selectedProductId: string | number) => void
  /** When pagination page is selected */
  handlePageClicked?: (selectedPageIndex: number) => void
  /** Select results per page */
  handlePageSizeChange?: (pageSize: number) => void
}

export const MyPhysicians: FunctionComponent<IMyPhysiciansProps> = ({
  physicians,
  isLoading,
  columns,
  pagination,
  userCountry,
  handleRowClicked,
  handlePageClicked,
  handlePageSizeChange
}) => {
  const { t } = useTranslation()

  const getCellContent = (
    column: MyPhysiciansColumn,
    physician: PhysiciansSummaryDto
  ): ReactNode | string => {
    const {
      physicianEmail,
      physicianTitle,
      physicianFirstName,
      physicianLastName
    } = physician
    switch (column) {
      case MyPhysiciansColumn.PhysicianName:
        return (
          <ClinText>{`${
            physicianTitle ?? ''
          } ${physicianFirstName} ${physicianLastName}`}</ClinText>
        )
      case MyPhysiciansColumn.Email:
        return physicianEmail ? (
          <ClinText>{physicianEmail}</ClinText>
        ) : undefined
      default:
        return 'Unknown type'
    }
  }

  const isPhysicianExists = physicians && physicians.length > 0
  const minRowsPerPage = 5
  const physiciansTotalNumber = pagination?.total

  return (
    <ClinPageContentFrame
      className="my-physicians"
      crumbs={[
        { path: '/orders', name: t('orders:page_name') },
        { path: '/my-physicians', name: t('my_physicians:title') }
      ]}
    >
      <Row>
        <Col sm={12} md={7}>
          <ClinText
            as="h1"
            variant={TypographyVariant.H2}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('my_physicians:title')}
          </ClinText>
        </Col>
      </Row>
      {physicians && physicians.length > 0 && (
        <>
          <Row>
            <Col sm={12} md={7}>
              <ClinText
                variant={TypographyVariant.LargeParagraph}
                fontWeight={ClinTheme.fontWeights.light}
              >
                {t('my_physicians:select_physician_to_manage')}
              </ClinText>
              <ClinSpacer height={ClinTheme.space[6]} />
              <ClinText fontSize={ClinTheme.fontSizes[3]}>
                <Trans
                  i18nKey="my_physicians:physicians_counter"
                  components={{ b: <b /> }}
                  values={{
                    physiciansTotal: physiciansTotalNumber,
                    physicians: t('glossary:physician', {
                      count: physiciansTotalNumber
                    })
                  }}
                >
                  <></>
                </Trans>
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[5]} />
          <ClinSpacer height={ClinTheme.space[0]} hasBorder={true} />
        </>
      )}

      <Row>
        <Col sm={12}>
          {isLoading ? (
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          ) : isPhysicianExists ? (
            <ClinTable>
              {physicians?.map((physician: PhysiciansSummaryDto, rowIndex) => {
                return (
                  <ClinTableRow
                    key={rowIndex}
                    onRowClick={() =>
                      handleRowClicked &&
                      physician.physicianId &&
                      handleRowClicked(physician.physicianId)
                    }
                    onRowEnter={() =>
                      handleRowClicked &&
                      physician.physicianId &&
                      handleRowClicked(physician.physicianId)
                    }
                  >
                    {columns.map(
                      (column: MyPhysiciansColumn, index: number) => (
                        <ClinTableBodyCell key={index}>
                          {getCellContent(column, physician)}
                        </ClinTableBodyCell>
                      )
                    )}
                  </ClinTableRow>
                )
              })}
            </ClinTable>
          ) : (
            <NoPhysicians />
          )}
        </Col>
      </Row>
      <ClinSpacer />
      <Row>
        <Col>
          {!isLoading &&
          isPhysicianExists &&
          pagination &&
          pagination.total > minRowsPerPage ? (
            <ClinPagination
              currentPage={getCurrentPage(pagination)}
              totalPages={getTotalPages(pagination)}
              pageSize={pagination.take}
              handlePageSelected={(pageIndex) =>
                handlePageClicked && handlePageClicked(pageIndex)
              }
              handlePageSizeChange={handlePageSizeChange}
            />
          ) : undefined}
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
