import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledGlobalSearch,
  StyledSearchWrapper,
  StyledSearchInput,
  StyledResults
} from './GlobalSearch.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { IAutoSuggestProps } from '../AutoSuggest/AutoSuggest.model'

export const GlobalSearch: FunctionComponent<IAutoSuggestProps> = ({
  query = '',
  suggestions = [],
  isLoading,
  showSuggestions,
  isMenuSearch,
  handleChange,
  handleKeyDown,
  handleEnter,
  renderSuggestions
}) => {
  const { t } = useTranslation()

  const getClassNames = (isMenuSearch: boolean | undefined) => {
    const baseClasses = {
      resultsClassName: 'clin-global-search-results',
      wrapperClassName: 'clin-global-search-input-wrapper',
      inputClassName: 'clin-global-search-input'
    }

    if (isMenuSearch) {
      return {
        resultsClassName: 'clin-global-search-menu-results',
        wrapperClassName: 'clin-global-search-menu-input-wrapper',
        inputClassName: 'clin-global-search-menu-input'
      }
    }
    return baseClasses
  }

  const { resultsClassName, wrapperClassName, inputClassName } =
    getClassNames(isMenuSearch)

  return (
    <StyledGlobalSearch>
      <StyledSearchWrapper
        className={wrapperClassName}
        onKeyDown={handleKeyDown}
      >
        <StyledSearchInput
          className={inputClassName}
          showSuggestions={showSuggestions}
          autoFocus={false}
          placeholder={t('global_search:global_search_placeholder')}
          value={query}
          onChange={handleChange}
          onKeyDown={(event) => {
            handleEnter && event.keyCode === 13 && handleEnter(event)
          }}
        />

        {isLoading && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: ClinTheme.space[2]
            }}
          >
            <ClinSpinner size={ClinTheme.space[3]} />
          </div>
        )}
        <ClinIcon
          style={{
            position: 'absolute',
            left: ClinTheme.space[3],
            top: '50%',
            transform: 'translateY(-50%)'
          }}
          iconName={ClinIconPathName.Search}
        />
        <StyledResults
          showSuggestions={showSuggestions}
          className={resultsClassName}
        >
          {suggestions && renderSuggestions && renderSuggestions(suggestions)}
        </StyledResults>
      </StyledSearchWrapper>
    </StyledGlobalSearch>
  )
}
