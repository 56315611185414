import {
  ReferenceType,
  Strategy,
  FloatingOverlay
} from '@floating-ui/react-dom-interactions'
import React, { RefObject, useEffect } from 'react'
import { Position as TooltipPosition } from 'react-tippy'

import { ClinNewFeatureDot } from './ClinNewFeatureDot/ClinNewFeatureDot'
import { NewFeatureDotWrap } from './ClinNewFeatureDot/ClinNewFeatureDot.styles'
import {
  StyledClinNewFeatureOverlay,
  StyledFloatingUITooltip,
  StyledFloatingUITooltipArrow,
  StyledTooltip
} from './ClinNewFeatureTooltip.styles'
import { ClinNewFeatureTooltipContent } from './ClinNewFeatureTooltipContent/ClinNewFeatureTooltipContent'
import { ClinTheme } from '../../ClinTheme'
import {
  FLOATING_OVERLAY_VISIBLE,
  eventEmitter
} from '../../utils/eventEmitter' // Import the event emitter

interface IClinNewFeatureTooltipProps {
  id: string
  style?: object
  title?: string
  showFeatureFromTable?: boolean
  description: string
  // total number on new features on one page
  total?: number
  // current number on feature on one page
  current?: number
  position?: TooltipPosition
  color?: string
  cssClass?: string
  offsetNumber?: number
  isCancelled?: boolean
  open: boolean
  tooltip: HTMLElement
  button: HTMLElement
  arrowEl?: HTMLElement
  hideCloseButton?: boolean
  tooltipWidth?: string
  arrowRef?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
  reference: (node: ReferenceType | null) => void
  floating: (node: HTMLElement | null) => void
  strategy: Strategy
  x: number | null
  y: number | null
  getReferenceProps: () => {}
  getFloatingProps: () => {}
  handleRemoveTooltip: (isCancelled?: boolean) => void
  handleOpenTooltip: () => void
}

export const ClinNewFeatureTooltipFloatingUI: React.FC<
  IClinNewFeatureTooltipProps
> = ({
  id,
  title,
  description,
  total,
  current,
  showFeatureFromTable,
  cssClass,
  color,
  tooltipWidth,
  open,
  arrowRef,
  hideCloseButton,
  isCancelled,
  reference,
  floating,
  strategy,
  x,
  y,
  getReferenceProps,
  getFloatingProps,
  handleRemoveTooltip,
  handleOpenTooltip
}) => {
  useEffect(() => {
    if (!showFeatureFromTable) {
      eventEmitter.emit(FLOATING_OVERLAY_VISIBLE, true)
    } else {
      eventEmitter.emit(FLOATING_OVERLAY_VISIBLE, false)
    }
  }, [showFeatureFromTable])

  return (
    <>
      {!isCancelled ? (
        <StyledTooltip>
          <NewFeatureDotWrap
            className={id}
            id={`button-${id}`}
            {...getReferenceProps()}
            ref={reference}
            onClick={handleOpenTooltip}
          >
            <ClinNewFeatureDot />
          </NewFeatureDotWrap>
          {!showFeatureFromTable && <StyledClinNewFeatureOverlay />}
          {open && (
            <FloatingOverlay
              // lockScroll
              style={{
                pointerEvents: 'auto',
                zIndex: ClinTheme.zIndices.newFeatureFloatingUi
              }}
            >
              <StyledFloatingUITooltip
                ref={floating}
                {...getFloatingProps()}
                id={`tooltip-${id}`}
                className="feature-tooltip"
                style={{
                  position: strategy,
                  left: x ?? '0',
                  top: y ?? '0',
                  backgroundColor: color ?? ClinTheme.colors.primaryMid,
                  width: tooltipWidth ?? 'max-content'
                }}
              >
                <ClinNewFeatureTooltipContent
                  id={id}
                  title={title ?? ''}
                  description={description}
                  total={total}
                  current={current}
                  hideCloseButton={hideCloseButton}
                  closeTooltipHandler={handleRemoveTooltip}
                />
                {arrowRef && (
                  <StyledFloatingUITooltipArrow
                    id={`arrow-${id}`}
                    ref={arrowRef}
                    color={color}
                  />
                )}
              </StyledFloatingUITooltip>
            </FloatingOverlay>
          )}
        </StyledTooltip>
      ) : (
        <StyledTooltip>
          <NewFeatureDotWrap
            className={id}
            id={`button-${id}`}
            {...getReferenceProps()}
            ref={reference}
            onClick={handleOpenTooltip}
          >
            <ClinNewFeatureDot />
          </NewFeatureDotWrap>
          {
            <StyledFloatingUITooltip
              ref={floating}
              {...getFloatingProps()}
              id={`tooltip-${id}`}
              className="feature-tooltip"
              style={{
                position: strategy,
                left: x ?? '0',
                top: y ?? '0',
                backgroundColor: color ?? ClinTheme.colors.primaryMid,
                width: tooltipWidth ?? 'max-content'
              }}
            >
              <ClinNewFeatureTooltipContent
                id={id}
                title={title ?? ''}
                description={description}
                total={total}
                current={current}
                isCancelled={isCancelled}
                hideCloseButton={hideCloseButton}
                closeTooltipHandler={handleRemoveTooltip}
              />
              {arrowRef && (
                <StyledFloatingUITooltipArrow
                  id={`arrow-${id}`}
                  ref={arrowRef}
                  color={color}
                />
              )}
            </StyledFloatingUITooltip>
          }
        </StyledTooltip>
      )}
    </>
  )
}
