import { DateTime } from 'luxon'
import React, { ChangeEvent, FunctionComponent, MouseEvent } from 'react'
import { Col, Row } from 'react-grid-system'
import { Trans, useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'

import {
  PhoneInputWrapper,
  StyledClinRadio,
  StyledClinSpacer,
  StyledDeliveryAddressText,
  StyledDeliveryAddressWrapper,
  StyledNewShippingAddressLink
} from './CheckoutOptions.styles'
import { DeliveryAddress } from './DeliveryAddress'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinDividerHeading } from '../../components/ClinDividerHeading'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinSpinner } from '../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import {
  ClinStepper,
  StepState
} from '../../components/ClinStepper/ClinStepper'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinTextInput } from '../../components/ClinTextInput'
import { OrderDelivery } from '../../constants'
import i18n from '../../i18n/config'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { CountryDto, OrderAddressDto } from '../../types/swaggerTypes'
import {
  StyledCheckoutBlock,
  StyledCheckoutBlockBodyWrap,
  StyledCheckoutBlockHeader,
  StyledCheckoutOrder
} from '../Checkout/Checkout.styles'

import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/bootstrap.css'
import useEmailValidation from '../../hooks/useCheckEmailError/useCheckEmailError'

interface ICheckoutOptionsProps {
  /** When we are updating order **/
  isSubmitting?: boolean
  /** Supplied Purchase Order number **/
  customerPoNumber?: string
  /** Supplied as Regular default **/
  deliveryOption: OrderDelivery
  /** Supplied deliver to **/
  deliverToContact?: string
  /** An array of delivery addresses **/
  deliveryAddresses?: OrderAddressDto[]
  /** Selected address id */
  selectedAddressId: string
  /** recipient phone number from support contact or choosed by user */
  recipientPhoneNumber: string
  /** recipient email  */
  recipientEmail?: string
  /** Error in PO */
  errorMessage?: string
  /** When back to basket is clicked **/
  handleBackToBasket?: () => void
  /** Requests that an address change has been made **/
  handleChangeAddress?: (selectedAddressId?: string) => void
  /** Handles a recipient's name change for an order **/
  handleRecipientsNameChange?: (value: string) => void
  /** Handles a po value change for an order **/
  handlePoChange?: (value: string) => void
  /** Handles a request to place the orders in the checkout **/
  handlePlaceDraftOrder?: () => void
  /** Handles a request to exit and add a new shipping address **/
  handleNewShippingAddress?: () => void
  /** Expected delivery date for regular delivery from basket context  */
  expectedDeliveryDate?: string
  /** Handles a delivery type change for an order **/
  handleDeliveryTypeChange: (deliveryType: OrderDelivery) => void
  /** Handles recipient phone change **/
  handleRecipientPhoneChange: (phone: string) => void
  /** Handles recipient email change **/
  handleRecipientEmailChange: (phone: string) => void
  /** Support contact details **/
  supportContact: CountryDto
  /** Whether to display standard shipping or not **/
  hasStandardShipping: boolean
}

export const CheckoutOptions: FunctionComponent<ICheckoutOptionsProps> = ({
  isSubmitting,
  customerPoNumber,
  deliverToContact,
  selectedAddressId,
  recipientPhoneNumber,
  recipientEmail,
  errorMessage,
  deliveryAddresses,
  handleBackToBasket,
  handleChangeAddress,
  handlePlaceDraftOrder,
  handlePoChange,
  handleRecipientsNameChange,
  handleNewShippingAddress,
  handleRecipientPhoneChange,
  handleRecipientEmailChange,
  expectedDeliveryDate,
  supportContact,
  hasStandardShipping,
  handleDeliveryTypeChange,
  deliveryOption
}) => {
  const { t } = useTranslation()
  const { emailErrorMessage, checkEmail } = useEmailValidation()

  return (
    <ClinPageContentFrame>
      {isSubmitting ? (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col sm={12}>
              <ClinText
                as="h1"
                fontWeight={ClinTheme.fontWeights.bold}
                variant={TypographyVariant.H3}
              >
                {t('checkout_options:title')}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[3]} hasBorder={true} />
          <ClinButton
            style={{ marginTop: ClinTheme.space[3] }}
            variant="linkButton"
            onClick={() => handleBackToBasket && handleBackToBasket()}
          >
            <ClinIcon
              style={{ marginRight: ClinTheme.space[2] }}
              iconSize={ClinTheme.fontSizes[3]}
              iconName={ClinIconPathName.ChevronLeft}
            />
            {t('checkout_options:back_to_basket')}
          </ClinButton>
          <ClinSpacer height={ClinTheme.space[4]} />
          <Row>
            <Col sm={12}>
              <ClinStepper
                steps={[
                  {
                    state: StepState.Complete,
                    displayText: t('checkout_options:stepper.step_1')
                  },
                  {
                    state: StepState.Active,
                    displayText: t('checkout_options:stepper.step_2')
                  },
                  {
                    state: StepState.InComplete,
                    displayText: t('checkout_options:stepper.step_3')
                  },
                  {
                    state: StepState.InComplete,
                    displayText: t('checkout_options:stepper.step_4')
                  }
                ]}
                width={800}
              />
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[7]} />
          <StyledCheckoutOrder>
            <ClinDividerHeading>
              <ClinText
                as="h2"
                textTransform="uppercase"
                fontWeight={ClinTheme.fontWeights.medium}
                color={ClinTheme.colors.white}
              >
                {t('opa_checkout:delivery_details')}
              </ClinText>
            </ClinDividerHeading>

            <ClinSpacer />

            <StyledCheckoutBlock>
              <StyledCheckoutBlockHeader>
                <Row align="center">
                  <Col sm={12} md={12}>
                    <ClinText
                      as="h2"
                      fontWeight={ClinTheme.fontWeights.light}
                      fontSize={ClinTheme.fontSizes[4]}
                      color={ClinTheme.colors.primary}
                    >
                      {t('checkout_options:section_one_title')}
                    </ClinText>
                  </Col>
                </Row>
              </StyledCheckoutBlockHeader>
              <StyledCheckoutBlockBodyWrap>
                <Row>
                  <ClinSpacer height={ClinTheme.space[4]} />
                  <Col sm={12} md={6}>
                    <ClinTextInput
                      width="100%"
                      id="po-number"
                      name="poNumber"
                      label={t('opa_checkout:your_purchase_order_label')}
                      required={true}
                      hasError={!!errorMessage}
                      prompt={errorMessage ?? ''}
                      defaultValue={customerPoNumber}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handlePoChange &&
                        handlePoChange(event.currentTarget.value)
                      }
                    />
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <ClinText
                      fontSize={ClinTheme.fontSizes[2]}
                      fontWeight={ClinTheme.fontWeights.light}
                    >
                      {t('opa_checkout:personal_details_warning')}
                    </ClinText>
                    <ClinSpacer height={ClinTheme.space[3]} />
                  </Col>
                </Row>
                <ClinSpacer />
              </StyledCheckoutBlockBodyWrap>
            </StyledCheckoutBlock>
            <ClinSpacer height={ClinTheme.space[6]} />
            <StyledCheckoutBlock>
              <StyledCheckoutBlockHeader>
                <Row align="center">
                  <Col sm={12} md={12}>
                    <ClinText
                      as="h2"
                      fontWeight={ClinTheme.fontWeights.light}
                      fontSize={ClinTheme.fontSizes[4]}
                      color={ClinTheme.colors.primary}
                    >
                      {t('checkout_options:section_two_title')}
                    </ClinText>
                  </Col>
                </Row>
              </StyledCheckoutBlockHeader>
              <StyledCheckoutBlockBodyWrap>
                <Row>
                  <Col
                    sm={12}
                    md={5}
                    style={{ marginBottom: ClinTheme.space[2] }}
                  >
                    <ClinTextInput
                      width="100%"
                      id="recipients-name"
                      name="recipientsName"
                      label={t('checkout_options:delivery_contact_label')}
                      defaultValue={deliverToContact}
                      onChange={(event) =>
                        handleRecipientsNameChange &&
                        handleRecipientsNameChange(event.currentTarget.value)
                      }
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={5}
                    offset={{ md: 1 }}
                    style={{ marginBottom: ClinTheme.space[2] }}
                  >
                    <ClinText
                      as="div"
                      fontSize={ClinTheme.fontSizes[1]}
                      color={'initial'}
                      marginBottom={ClinTheme.space[2]}
                    >
                      {t('checkout_options:delivery_contact_phone')}
                    </ClinText>

                    <PhoneInputWrapper>
                      <PhoneInput
                        inputProps={{
                          id: 'recipients-phone',
                          name: 'recipientsPhone'
                        }}
                        inputStyle={{ height: '40px', width: '100%' }}
                        autoFormat={true}
                        value={recipientPhoneNumber}
                        onChange={(value, coutry, event, formattedValue) =>
                          handleRecipientPhoneChange &&
                          handleRecipientPhoneChange(formattedValue)
                        }
                      />
                    </PhoneInputWrapper>
                  </Col>
                </Row>
                <StyledClinSpacer />
                <Row>
                  <Col sm={12} md={5}>
                    <ClinTextInput
                      type="email"
                      width="100%"
                      id="recipients-email"
                      name="recipientsEmail"
                      label={t('checkout_options:delivery_contact_email')}
                      defaultValue={recipientEmail}
                      hasError={!!emailErrorMessage}
                      prompt={emailErrorMessage ?? ''}
                      onChange={(event) =>
                        handleRecipientEmailChange &&
                        handleRecipientEmailChange(event.currentTarget.value)
                      }
                    />
                  </Col>
                </Row>
                <ClinSpacer />
                <Row>
                  <Col sm={12}>
                    <>
                      <ClinText
                        as="h5"
                        variant={TypographyVariant.H4}
                        marginBottom={ClinTheme.space[2]}
                      >
                        {t('checkout_options:address_select_title')}:
                      </ClinText>
                      <StyledDeliveryAddressWrapper className="deliveryAddressWrapper">
                        {deliveryAddresses?.map((address, index) => (
                          <div key={address.addressId}>
                            <StyledClinRadio
                              label={`${address.addressId}-index`}
                              id={`${address.addressId}-index`}
                              isLast={index === deliveryAddresses.length - 1}
                              checked={address.addressId === selectedAddressId}
                              isCompact={true}
                              onClick={() =>
                                handleChangeAddress &&
                                handleChangeAddress(address.addressId)
                              }
                            >
                              <Row>
                                <Col>
                                  <DeliveryAddress
                                    address={address}
                                    isCompact={true}
                                  />
                                </Col>
                              </Row>
                            </StyledClinRadio>
                          </div>
                        ))}
                      </StyledDeliveryAddressWrapper>
                    </>
                  </Col>
                </Row>
              </StyledCheckoutBlockBodyWrap>
              <div>
                <StyledDeliveryAddressText>
                  {t('checkout_options:do_not_see_delivery_options')}
                </StyledDeliveryAddressText>
                <StyledNewShippingAddressLink
                  onClick={() =>
                    handleNewShippingAddress && handleNewShippingAddress()
                  }
                >
                  {t('checkout_options:request_new_shipping_address_btn')}
                </StyledNewShippingAddressLink>
                <ClinSpacer height={ClinTheme.space[3]} />
              </div>
            </StyledCheckoutBlock>
            <StyledCheckoutBlock style={{ marginTop: ClinTheme.space[6] }}>
              <StyledCheckoutBlockHeader>
                <ClinText
                  as="h2"
                  fontWeight={ClinTheme.fontWeights.light}
                  fontSize={ClinTheme.fontSizes[4]}
                  color={ClinTheme.colors.primary}
                >
                  {t('checkout:choose_preferred_delivery_option')}
                </ClinText>
              </StyledCheckoutBlockHeader>
              <StyledCheckoutBlockBodyWrap>
                <Row>
                  <Col>
                    <Row justify="start">
                      <Col>
                        <StyledClinRadio
                          className="delivery-input-regular"
                          id="regular"
                          label="Regular"
                          checked={deliveryOption === OrderDelivery.Regular}
                          onClick={() =>
                            handleDeliveryTypeChange(OrderDelivery.Regular)
                          }
                          disabled={!hasStandardShipping}
                        >
                          <Row>
                            <Col>
                              <ClinText
                                as="div"
                                fontWeight={ClinTheme.fontWeights.medium}
                                color={ClinTheme.colors.black}
                              >
                                {t('checkout:delivery_option_one')}
                              </ClinText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {expectedDeliveryDate && (
                                <div
                                  style={{
                                    marginTop: ClinTheme.space[3],
                                    marginLeft: ClinTheme.space[0],
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <ClinIcon
                                    iconName={ClinIconPathName.CustomDelivery}
                                    iconSize={ClinTheme.space[3]}
                                    iconFill={ClinTheme.colors.primary}
                                  />
                                  <ClinText
                                    marginLeft={ClinTheme.space[1]}
                                    marginRight={ClinTheme.space[1]}
                                    as="div"
                                    color={ClinTheme.colors.black}
                                    fontWeight={ClinTheme.fontWeights.normal}
                                    lineHeight={
                                      ClinTheme.lineHeights.heading[0]
                                    }
                                  >
                                    {t(
                                      'product_detail:estimated_delivery_title'
                                    )}
                                  </ClinText>
                                  <ClinText
                                    as="div"
                                    className={'unit-price'}
                                    color={ClinTheme.colors.black}
                                    fontWeight={ClinTheme.fontWeights.medium}
                                    lineHeight={
                                      ClinTheme.lineHeights.heading[0]
                                    }
                                    whiteSpace="normal"
                                  >
                                    {DateTime.fromISO(
                                      expectedDeliveryDate || '',
                                      { zone: 'utc' }
                                    )
                                      .setLocale(i18n.language)
                                      .toFormat('cccc, MMMM d')}
                                  </ClinText>
                                </div>
                              )}
                            </Col>
                          </Row>
                        </StyledClinRadio>
                      </Col>
                    </Row>
                    <Row justify="start">
                      <Col>
                        <StyledClinRadio
                          className="delivery-input-custom"
                          id="custom"
                          label="Custom"
                          checked={deliveryOption === OrderDelivery.Custom}
                          onClick={(event: MouseEvent<HTMLInputElement>) =>
                            handleDeliveryTypeChange(OrderDelivery.Custom)
                          }
                        >
                          <Row>
                            <Col>
                              <ClinText
                                as="div"
                                fontWeight={ClinTheme.fontWeights.medium}
                                color={ClinTheme.colors.black}
                              >
                                {t('checkout:delivery_option_two')}
                              </ClinText>
                            </Col>
                          </Row>
                          {deliveryOption === OrderDelivery.Custom && (
                            <>
                              <ClinSpacer height={ClinTheme.space[3]} />
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  marginBottom: ClinTheme.space[3]
                                }}
                              >
                                <ClinIcon
                                  iconName={ClinIconPathName.InfoOutline}
                                  iconSize={ClinTheme.space[3]}
                                  iconFill={ClinTheme.colors.primary}
                                />
                                <ClinText
                                  as="div"
                                  marginLeft={ClinTheme.space[2]}
                                >
                                  {t('checkout:custom_delivery_info')}
                                </ClinText>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexDirection: 'row'
                                }}
                              >
                                <div>
                                  <ClinIcon
                                    iconName={ClinIconPathName.Phone}
                                    iconSize={ClinTheme.space[3]}
                                    iconFill={ClinTheme.colors.primary}
                                  />
                                </div>
                                <ClinText
                                  as="div"
                                  marginLeft={ClinTheme.space[2]}
                                >
                                  <Trans
                                    i18nKey="checkout:custom_delivery_contact_info"
                                    components={{
                                      phone: (
                                        <a
                                          aria-label={`Call support: ${supportContact.csPhoneNumber}`}
                                          key="1"
                                          href={`tel:${supportContact.rawPhoneNumber}`}
                                          onClick={() => {
                                            analyticsServiceSingleton.trackEvent(
                                              AnalyticsEvent.ContactClicked,
                                              {
                                                contactType: 'telephone'
                                              }
                                            )
                                          }}
                                        >
                                          {supportContact.csPhoneNumber}
                                        </a>
                                      ),
                                      email: (
                                        <a
                                          aria-label={`Email support: ${supportContact.csEmailAddress}`}
                                          key="2"
                                          href={`mailto:${supportContact.csEmailAddress}`}
                                          onClick={() => {
                                            analyticsServiceSingleton.trackEvent(
                                              AnalyticsEvent.ContactClicked,
                                              { contactType: 'email' }
                                            )
                                          }}
                                        >
                                          {supportContact.csEmailAddress}
                                        </a>
                                      )
                                    }}
                                    values={{
                                      phoneNumber: supportContact.csPhoneNumber,
                                      emailAddress:
                                        supportContact.csEmailAddress
                                    }}
                                  >
                                    <></>
                                  </Trans>
                                </ClinText>
                              </div>
                            </>
                          )}
                        </StyledClinRadio>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </StyledCheckoutBlockBodyWrap>
              <div>
                <StyledDeliveryAddressText>
                  {t('opa_checkout:shipping_documents_info')}
                </StyledDeliveryAddressText>
                <ClinSpacer height={ClinTheme.space[3]} />
              </div>
            </StyledCheckoutBlock>
          </StyledCheckoutOrder>

          <ClinSpacer height={ClinTheme.space[3]} />
          <ClinSpacer height={ClinTheme.space[2]} />
          <ClinGroup justifyContent="flex-end">
            <ClinButton
              onClick={() => {
                const isEmailError = recipientEmail
                  ? checkEmail(recipientEmail)
                  : false
                if (!isEmailError) {
                  handlePlaceDraftOrder && handlePlaceDraftOrder()
                }
              }}
              variant="primary"
              disabled={!customerPoNumber || errorMessage !== undefined}
            >
              {t('common:buttons.continue')}
            </ClinButton>
          </ClinGroup>
        </>
      )}
    </ClinPageContentFrame>
  )
}
