import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery } from '../../../utils/mediaQuery'
import { IStatusWrapperProps } from '../../Patients/PatientDashboard/PatientDashboard.styles'

const MediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledGrayWrapper = styled.div({
  backgroundColor: ClinTheme.colors.lightGrey
})

export const StyledWhiteWrapper = styled.section({
  backgroundColor: ClinTheme.colors.white
})

export const StyledOrdersAnnounce = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1140px',
  margin: '0 auto',
  [mediaQuery(MediumUpBreakpoint)]: {
    flexDirection: 'row'
  }
})

export const StyledOrdersAnnounceText = styled.div({
  flex: '0 0 100%',
  [mediaQuery(MediumUpBreakpoint)]: {
    flex: '0 0 60%'
  }
})

export const StyledOrdersAnnounceCta = styled.div({
  flex: '0 0 100%',
  textAlign: 'left',
  boxSizing: 'border-box',
  marginTop: ClinTheme.space[2],

  [mediaQuery(MediumUpBreakpoint)]: {
    flex: '0 0 40%',
    textAlign: 'right',
    marginTop: 0,
    paddingRight: ClinTheme.space[2]
  }
})

export const StyledMyOrdersToggle = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'relative',
  alignItems: 'center',
  [`> .clin-text, > .clin-switch`]: {
    margin: 0
  }
})

// New styles similar to PatientDashboard styles
export const StyledClinTableOrderToggleWrapper = styled.div`
  position: relative;
  font-size: 12px;
  color: ${ClinTheme.colors.black};
  & > div {
    font-size: 12px;
    color: ${ClinTheme.colors.black};
    align-items: center;
    display: flex;
    flex-direction: row;
  }
`

export const StyledStatusWrapper = styled.div<{ color: string }>(
  ({ color }) => ({
    display: 'flex',
    background: color,
    margin: '0 auto',
    padding: '4px 10px',
    borderRadius: `${ClinTheme.space[2]}px`,
    alignItems: 'center',
    justifyContent: 'center'
  })
)

export const StyledStatusWrapperForOrders = styled.div<IStatusWrapperProps>(
  {},
  ({ color }: IStatusWrapperProps) => ({
    display: 'block',
    width: '200px',
    background: color,
    margin: '0 auto',
    padding: '4px 10px',
    borderRadius: `${ClinTheme.space[2]}px`
  })
)

export const StyledTrackOrderWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
})

export const StyledActionWrapper = styled.div({
  textAlign: 'right',
  position: 'relative',
  color: ClinTheme.colors.primary
})

export const StyledTooltipActionsWrapper = styled.span({
  position: 'absolute',
  left: '-7px',
  top: '-3px'
})

export const StyledTooltipWrapper = styled.span({
  top: '-40px',
  position: 'absolute',
  right: '-40px'
})

export const StyledFilterWrapper = styled.div({
  marginBottom: ClinTheme.space[3]
})

export const StyledFilter = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: ClinTheme.space[2]
})

export const StyledTitleText = styled.div({
  margin: '0',
  [`> .clin-text`]: {
    fontFamily: 'CabinetGrotesk-Bold, sans-serif'
  }
})

export const StyledFilterIconContainer = styled.div({
  right: '0',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '-24px'
})

export const StyledMobileOrderSearch = styled.div({
  margin: '0 16px 0 16px',
  width: '100%'
})

export const StyledFilterCounter = styled.span({
  position: 'absolute',
  top: '5px',
  right: '0',
  width: '14px',
  height: '14px',
  fontSize: '10px',
  justifyContent: 'center',
  display: 'flex',
  color: 'white',
  backgroundColor: 'red',
  borderRadius: '50%'
})
