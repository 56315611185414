import { AxiosError } from 'axios'
import { LocationState } from 'history'
import { t } from 'i18next'
import { DateTime } from 'luxon'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { RouteComponentProps, StaticContext, useLocation } from 'react-router'

import { PatientDashboardDiscontinueModal } from './PatentDashboardDiscontinueModal/PatientDashboardDiscontinueModal'
import {
  ActionDropdownConstants,
  OPAOrderTypes,
  PatientOrderStatus,
  getRandomColor,
  patientOrderStatusTooltipTranslation,
  patientOrderStatusTranslation
} from './Patient.model'
import PatientDashboard from './PatientDashboard'
import {
  StyledActionWrapper,
  StyledIcon,
  StyledPatientColumn,
  StyledPatientDateNameWrap,
  StyledPatientInitialsAvatar,
  StyledStatusWrapper,
  StyledTooltipActionsWrapper,
  StyledTooltipWrapper
} from './PatientDashboard.styles'
import { PatientDeleteModal } from './PatientDeleteModal/PatientDeleteModal'
import { PatientEnrollModal } from './PatientEnrollModal/PatientEnrollModal'
import { PhysicianAssociationModal } from './PhysicianAssociationModal/PhysicianAssociationModal'
import StatusTooltip from './StatusTooltip'
import { StyledTrackingLinkWrapper } from './StyledTrackingLinkWrapper'
import GlowActionIcon from '../../../assets/media/icons/action_menu_glow.svg'
import { ClinTheme } from '../../../ClinTheme'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinButton } from '../../../components/ClinButton'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinModal } from '../../../components/ClinModal'
import {
  NewFeatureElements,
  PageNames
} from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipContainer } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import { ClinNotification } from '../../../components/ClinNotification'
import {
  getParamValueFor,
  IPagination,
  useUpdateQueryParam
} from '../../../components/ClinPagination/ClinPagination.model'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { useAppContext } from '../../../context/app'
import useFeatureFlagRedirect from '../../../context/featureFlags/FeatureFlagBasedRedirect'
import { FeatureFlagKeys } from '../../../context/featureFlags/FeatureFlagKeys'
import { ActionType, useNewFeatures } from '../../../context/newFeatures'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import useChangeBackgroundColor from '../../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { useNewFeaturesList } from '../../../hooks/useNewFeaturesList/useNewFeaturesList'
import { AnalyticsEvent } from '../../../services/Analytics'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import {
  AuthError,
  associatePhysician,
  cancelGetPatientDetail,
  cancelGetPatients,
  deleteOpa,
  deletePatient,
  getPatientDetail,
  getPatients,
  removeOrder
} from '../../../services/ApiService'
import {
  addNewFeatureClosedEvent,
  getNewFeatureEventDescription,
  setFeatureToViewed
} from '../../../services/NewFeaturesService'
import {
  DeleteOrderDto,
  PatientSummaryDto,
  PatientSummarySearchDto,
  PatientSummarySearchFilterDto
} from '../../../types/swaggerTypes'
import { truncateString } from '../../../utils/truncateString'
import { useErrorMessage } from '../../../utils/useErrorMessage'
import { ModalMode } from '../../PhysicianDetails/PhysicianDetailsContainer'
import TableActionMenu from '../../Programs/ProgramDetail/TableActionMenu'
import {
  PatientColumn,
  PatientFilterOptions,
  sortOption,
  sortOptions
} from '../Patients.model'

export interface IColumn {
  title: string
  renderValue: (
    patient: PatientSummaryDto,
    index?: number
  ) => JSX.Element | string
  width: string
  hide?: boolean
  isSortable?: boolean
  name?: string
}

export interface ActionDropdownItem {
  text: string
  color?: string
  onClick: (patient: PatientSummaryDto) => void
}

export interface DropdownAction {
  id: ActionDropdownConstants
  item: ActionDropdownItem
}

export const trimInitials = (initials: string | undefined | null): string => {
  if (!initials || initials === '') return '**'
  if (initials.length && initials.length < 3) return initials
  else return initials.slice(0, 2)
}

interface RouteStateType {
  patientNumber?: string
  newFeature?: NewFeatureElements
}

type OnCancelLocationState = {
  stockOrder?: boolean
}

type PatientDashboardContainerLocationState =
  | LocationState
  | OnCancelLocationState

export interface IPatientDashboardProps
  extends RouteComponentProps<
    {},
    StaticContext,
    PatientDashboardContainerLocationState
  > {
  showTableInTab?: boolean
  programName?: string
  isProgramOpenForResupply?: boolean
  handleResultCountChange?: (count: number) => void
}

export const PatientDashboardContainer: FunctionComponent<
  IPatientDashboardProps
> = ({
  location,
  history,
  showTableInTab,
  programName,
  isProgramOpenForResupply,
  handleResultCountChange
}) => {
  const stockOrderOnCancel = useMemo(
    () =>
      (history.location.state as OnCancelLocationState)?.stockOrder !==
        undefined &&
      !(history.location.state as OnCancelLocationState)?.stockOrder,
    []
  )
  const [colorsArray, setColorsArray] = React.useState<string[]>([])
  const [patientNumber, setPatientNumber] = React.useState<string>()
  const [newFeature, setNewFeature] = React.useState<NewFeatureElements>()
  const rowsPerPage: number = showTableInTab ? 5 : 10
  const routeLocation = useLocation<RouteStateType>()
  const [isNoPatientsAlternate, setIsNoPatientsAlternate] = useState(false)
  const [isEnrollModalOpen, setIsEnrollModalOpen] = useState(false)
  const [isDiscontinuationSubmitted, setIsDiscontinuationSubmitted] =
    useState(false)
  const [selectedPatientId, setSelectedPatientId] = useState<number>()
  const [selectedPatientHasVials, setSelectedPatientHasVials] =
    useState<boolean>(false)
  const [selectedPhysicianId, setSelectedPhysicianId] = useState<number>()
  const [selectedProgramId, setSelectedProgramId] = useState<number>()
  const {
    dispatch,
    portfolioCountryCode,
    userDetails,
    defaultShippingAddress
  } = useAppContext()
  const [patientFilterChecked, setPatientFilterChecked] = useState<
    boolean | null
  >(false)
  const [isPatientOrderNumber, setIsPatientOrderNumber] =
    useState<boolean>(false)

  useFeatureFlagRedirect(FeatureFlagKeys.PatientCentricJourneyPerUser)

  const [physicianFullName, setPhysicianFullName] = useState<string>()

  const [isPhysicianAssociationModalOpen, setIsPhysicianAssociationModalOpen] =
    useState(false)
  const [isDiscontinuePatientModalOpen, setIsDiscontinuePatientModalOpen] =
    useState(false)
  const [deletePatientModalMode, setDeletePatientModalMode] = React.useState<
    ModalMode | undefined
  >()
  const [showDeleteNotification, setShowDeleteNotification] = useState(false)

  useEffect(() => {
    if (!patientNumber && routeLocation.state) {
      routeLocation.state?.patientNumber &&
        setPatientNumber(routeLocation.state?.patientNumber)
      routeLocation.state?.newFeature &&
        setNewFeature(routeLocation.state?.newFeature)
    }
  }, [patientNumber, routeLocation.state])

  const defaultPagination: IPagination = {
    count: 0,
    skip: 0,
    take: rowsPerPage,
    total: 0
  }
  //Background
  useChangeBackgroundColor(ClinTheme.colors.lightGrey)

  const { newFeaturesList, displayNewFeature, displayNewFeatureGlowEffect } =
    useNewFeaturesList(PageNames.Patients)

  const [, newFeaturesDispatch] = useNewFeatures()

  const defaultSearchParams: PatientSummarySearchDto = {
    query: '',
    filter: {
      programs: programName ? [programName] : [],
      patientOrderStatuses: [],
      physicianId: undefined,
      showMyPatient: false,
      showMyPhysician: false,
      showMyProgram: showTableInTab ? false : true
    },
    pagination: {
      skip: 0,
      take: rowsPerPage
    },
    sorting: {
      sortBy: sortOptions[0].optionId,
      order: SortDirectionType.Descending
    }
  }

  // Restore from query params
  const queryParam = new URLSearchParams(location.search)

  // Restore page Size
  const [patientsPerPage, setPatientsPerPage] = React.useState<number>(() => {
    const previousPageSize = queryParam.get('pageSize')
    return previousPageSize ? parseInt(previousPageSize) : rowsPerPage
  })

  // Restore pagination from URL params
  defaultSearchParams.pagination.take = getParamValueFor(
    'pageSize',
    queryParam,
    rowsPerPage
  )

  // PageIndex
  defaultPagination.take = defaultSearchParams.pagination.take
  defaultSearchParams.pagination.skip =
    getParamValueFor('pageIndex', queryParam) *
    defaultSearchParams.pagination.take

  if (queryParam.get('sort')) {
    defaultSearchParams.sorting.sortBy = queryParam.get('sort')!
    defaultSearchParams.sorting.order = queryParam.get('sortDirection') ?? 'asc'
  }

  if (queryParam.get('query')) {
    defaultSearchParams.query = queryParam.get('query')!
  }

  const [pagination, setPagination] =
    React.useState<IPagination>(defaultPagination)

  const [searchParams, setSearchParams] =
    React.useState<PatientSummarySearchDto>({
      ...defaultSearchParams
    })

  const [shouldFetchPatients, setShouldFetchPatients] = useState(true)

  const [patients, setPatients] = useState<PatientSummaryDto[] | undefined>([])
  const [loadedPatients, setLoadedPatients] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [selectedSortOption, setSelectedSortOption] =
    React.useState<sortOption>({
      optionId: queryParam.get('sort') ?? sortOptions[0].optionId,
      optionValue:
        sortOptions.find((x) => x.optionId === queryParam.get('sort'))
          ?.optionValue ?? sortOptions[0].optionValue
    })
  const [openActionIndex, setOpenActionIndex] = useState(-1)
  const [selectedFilterOptions, setSelectedFilterOptions] = React.useState<
    string[]
  >(
    showTableInTab
      ? ['']
      : [PatientFilterOptions.MyPrograms, PatientFilterOptions.MyPhysicians]
  )

  const handleError = useErrorMessage('There was an error fetching patients.')
  const handlePatientError = useErrorMessage(
    'There was an error fetching patient information'
  )

  const canRedirectToOrderDetailsPage = (
    orderStatus?: string,
    orderNumber?: string
  ): boolean => {
    return (
      !!orderNumber &&
      !!orderStatus &&
      orderStatus !== PatientOrderStatus.IncompleteCheckout &&
      orderStatus !== PatientOrderStatus.IncompletePAF &&
      orderStatus !== PatientOrderStatus.BeginOrder
    )
  }

  const handleRowClicked = (
    selectedPhysicianId: string | number,
    selectedPatientId: string | number,
    programId?: string | number,
    isStock?: boolean,
    orderNumber?: string,
    orderStatus?: string
  ) => {
    setSelectedPatientId(Number(selectedPatientId))
    setSelectedPhysicianId(Number(selectedPhysicianId))
    setSelectedProgramId(Number(programId))

    if (shouldOpenEnrollModal(selectedPatientId)) {
      setIsEnrollModalOpen(true)
      return
    }

    if (shouldOpenPhysicianAssociationModal(selectedPatientId)) {
      setIsPhysicianAssociationModalOpen(true)
      return
    }

    cancelGetPatients()
    //for stock orders we want to redirect to order detials only if we have order number, and it is not in this statuses since we don't have booked order
    if (isStock) {
      if (canRedirectToOrderDetailsPage(orderStatus, orderNumber)) {
        history.push(`/order/${orderNumber}`)
      } else {
        const patient = patients?.find(
          (patient) => patient.patientId === selectedPatientId
        )
        handleContinueOrder(patient!)
      }
    } else {
      history.push({
        pathname: `/programs/my-physicians/${selectedPhysicianId}/${selectedPatientId}`,
        state: {
          orderNumber: orderNumber
        }
      })
    }
  }

  const handleCloseActionMenu = () => {
    setOpenActionIndex(-1)
  }

  const shouldOpenEnrollModal = (patientId: string | number) => {
    const selectedPatient = getSelectedPatient(Number(patientId))
    const { programEnrolledFlag } = selectedPatient as PatientSummaryDto
    return !programEnrolledFlag
  }

  const shouldOpenPhysicianAssociationModal = (patientId: string | number) => {
    const selectedPatient = getSelectedPatient(Number(patientId))
    const { physicianAssociationFlag } = selectedPatient as PatientSummaryDto

    setPhysicianFullName(
      `${selectedPatient?.physicianTitle} ${selectedPatient?.physicianFullName}`
    )
    return !physicianAssociationFlag
  }

  const handleDeleteAction = () => {
    handleCloseActionMenu()
    setDeletePatientModalMode(ModalMode.active)
  }

  const handleActionAfterCheck = (
    patient: PatientSummaryDto,
    action: () => void
  ) => {
    setSelectedPatientId(patient && Number(patient.patientId))
    setSelectedPhysicianId(patient && Number(patient.physicianId))

    if (shouldOpenEnrollModal(patient.patientId)) {
      setIsEnrollModalOpen(true)
      return
    }

    if (shouldOpenPhysicianAssociationModal(patient.patientId)) {
      setIsPhysicianAssociationModalOpen(true)
      return
    }

    action()
  }

  const handleEnroll = () => {
    setIsEnrollModalOpen(false)
    setIsLoading(true)
    if (selectedProgramId !== undefined) {
      history.push({
        pathname: `/programs/access-programs/${selectedProgramId}/enrolment-form`,
        state: {
          from: window.location.pathname
        }
      })
    }
  }

  const handleAssociate = () => {
    setIsLoading(true)
    selectedPhysicianId &&
      associatePhysician(selectedPhysicianId.toString())
        .then((response) => {
          if (response.status === 200) {
            doGetPatients({
              ...searchParams,
              sorting: {
                ...searchParams.sorting,
                order: searchParams.sorting.order.toUpperCase()
              }
            })
            setIsPhysicianAssociationModalOpen(false)
            setIsLoading(false)
          }
        })
        .catch((error: AxiosError) => {
          setIsPhysicianAssociationModalOpen(false)
          setIsLoading(false)
        })
  }

  const getSelectedPatient = (
    patientId: number
  ): PatientSummaryDto | undefined => {
    return patients?.find(
      (x: PatientSummaryDto) => x.patientId === Number(patientId)
    )
  }

  const handlePageClicked = (selectedPageIndex: number) => {
    const newSearchParams = {
      ...searchParams,
      pagination: {
        skip: (selectedPageIndex - 1) * patientsPerPage,
        take: patientsPerPage
      }
    }
    setSearchParams(newSearchParams)
    setShouldFetchPatients(true)
  }

  const handlePageSizeChange = (pageSize: number) => {
    setPatientsPerPage(pageSize)
    const newSearchParams = {
      ...searchParams,
      pagination: {
        skip: 0,
        take: pageSize
      }
    }
    setSearchParams(newSearchParams)
    setShouldFetchPatients(true)
  }

  const handleCloseFeatureHighlight = (feature: NewFeatureElements) => {
    const viewedFeature = newFeaturesList[feature]
    viewedFeature &&
      setFeatureToViewed(viewedFeature.id, viewedFeature.version, true)
        .then(() => {
          newFeaturesDispatch({
            type: ActionType.UpdateNewFeatures,
            feature: { id: viewedFeature.id }
          })
          addNewFeatureClosedEvent(
            getNewFeatureEventDescription(viewedFeature.id)
          )
        })
        .catch((error: any) => {})
  }

  const handleBeginOrder = (patient: PatientSummaryDto) => {
    setIsLoading(true)
    patient.patientId &&
      patient.physicianId &&
      getPatientDetail(patient.patientId.toString(), patient.physicianId)
        .then((response) => {
          const programId = response.data.program.programId
          programId &&
            history.push({
              pathname: `/programs/access-programs/${programId}/patient-access-form/${patient.physicianId}`,
              state: {
                orderType: patient.stockOrderFlag
                  ? OPAOrderTypes.StockOrder
                  : OPAOrderTypes.InitialOrder,
                patientNumber: patient.patientNumber,
                patientId: patient.patientId,
                patientStatus: patient.patientOrderStatus,
                stockOrder: patient.stockOrderFlag,
                from: window.location.pathname
              }
            })
        })
        .catch((err) => {
          cancelGetPatientDetail()
        })
        .finally(() => setIsLoading(false))
  }

  const handleDeletePatientRequest = () => {
    const patientId = selectedPatientId && Number(selectedPatientId)
    const selectedPatient = patients?.find((x) => x.patientId === patientId)
    setDeletePatientModalMode(ModalMode.submitting)
    if (selectedPatient) {
      if (
        selectedPatient.patientOrderStatus === PatientOrderStatus.BeginOrder &&
        !selectedPatient.orderNumber
      ) {
        handleDeletePatient(selectedPatient)
      } else if (
        selectedPatient.patientOrderStatus ===
        PatientOrderStatus.IncompleteCheckout
      ) {
        handleDeleteOrder(selectedPatient)
      } else {
        handleDeleteOpa(selectedPatient)
      }
    }
  }

  const handleDeleteOrder = (patient: PatientSummaryDto) => {
    const deleteReason: DeleteOrderDto = {
      cancelReason: 'delete patient',
      cancelComments: ''
    }

    if (!patient.orderNumber) {
      handleDeletePatient(patient)
      return
    }

    patient.orderNumber &&
      patient?.programId &&
      removeOrder(patient.orderNumber, deleteReason)
        .then(() => {
          handleDeletePatient(patient)
        })
        .catch((error: AxiosError) => {
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error deleting order. ${error.message} ${
                error.code ?? ''
              }`
            )
          )
        })
  }

  const handleDeleteOpa = (patient: PatientSummaryDto) => {
    patient.programId &&
      deleteOpa(
        patient.programId.toString(),
        patient.physicianId,
        defaultShippingAddress?.addressId ?? '',
        patient.patientNumber
      )
        .then((response) => {
          if (patient.orderNumber) {
            handleDeleteOrder(patient)
          } else {
            handleDeletePatient(patient)
          }
        })
        .catch((error) => {
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error deleting OPA. ${error.message} ${
                error.code ?? ''
              }`
            )
          )
        })
  }

  const handleDeletePatient = (patient: PatientSummaryDto) => {
    deletePatient(patient.patientId.toString(), patient.physicianId)
      .then((response) => {
        doGetPatients(searchParams)
        // Show success notification
        setShowDeleteNotification(true)
      })
      .catch((error) => {
        // If request is cancelled continue
        if (error.message === AuthError.RequestCancelled) {
          return
        }
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `There was an error deleting patient. ${error.message} ${
              error.code ?? ''
            }`
          )
        )
      })
      .finally(() => {
        setDeletePatientModalMode(undefined)
      })
  }

  const handleContinueOrder = (patient: PatientSummaryDto) => {
    if (patient.patientOrderStatus === PatientOrderStatus.IncompletePAF) {
      setIsLoading(true)
      //Send to OPA page but make set OrderTypeTo ''
      patient.patientId &&
        patient.physicianId &&
        getPatientDetail(patient.patientId.toString(), patient.physicianId)
          .then((response) => {
            analyticsServiceSingleton.trackEvent(AnalyticsEvent.ContinueOrder, {
              userId: userDetails?.contactCard.contactId,
              typeOfContinue: 'IncompletePAF'
            })
            const programId = response.data.program.programId
            programId &&
              history.push({
                pathname: `/programs/access-programs/${programId}/patient-access-form/${patient.physicianId}`,
                state: {
                  orderType: patient.orderType,
                  patientNumber: patient.patientNumber,
                  patientId: patient.patientId,
                  patientStatus: patient.patientOrderStatus,
                  stockOrder: patient.stockOrderFlag,
                  from: window.location.pathname
                }
              })
          })
          .catch((err) => {
            cancelGetPatientDetail()
          })
    } else if (
      patient.patientOrderStatus === PatientOrderStatus.IncompleteCheckout
    ) {
      if (!patient.orderNumber) {
        setIsPatientOrderNumber(true)
        return
      }

      getPatientDetail(patient.patientId.toString(), patient.physicianId)
        .then((response) => {
          analyticsServiceSingleton.trackEvent(AnalyticsEvent.ContinueOrder, {
            userId: userDetails?.contactCard.contactId,
            typeOfContinue: 'Incomplete checkout'
          })
          const programId = response.data.program.programId
          programId &&
            history.push(`/opa-checkout/${patient.orderNumber}`, {
              from: window.location.pathname,
              patientId: patient.patientId,
              patientNumber: patient.patientNumber,
              orderType: patient.orderType,
              programId: programId,
              stockOrder: patient.stockOrderFlag
            })
        })
        .catch((err) => {
          cancelGetPatientDetail()
        })
    } else if (patient.patientOrderStatus === PatientOrderStatus.BeginOrder) {
      patient.patientId &&
        patient.physicianId &&
        getPatientDetail(patient.patientId.toString(), patient.physicianId)
          .then((response) => {
            const programId = response.data.program.programId
            programId &&
              history.push({
                pathname: `/programs/access-programs/${programId}/patient-access-form/${patient.physicianId}`,
                state: {
                  orderType: patient.stockOrderFlag
                    ? OPAOrderTypes.StockOrder
                    : OPAOrderTypes.InitialOrder,
                  patientNumber: patient.patientNumber,
                  patientId: patient.patientId,
                  patientStatus: patient.patientOrderStatus,
                  stockOrder: patient.stockOrderFlag,
                  from: window.location.pathname
                }
              })
          })
          .catch((err) => {
            cancelGetPatientDetail()
          })
          .finally(() => setIsLoading(false))
    } else if (
      patient.patientOrderStatus === PatientOrderStatus.ReadyForResupply
    ) {
      handleOrderResuplly(patient, true)
    }
  }

  const handleDiscontinuePatient = (patient: PatientSummaryDto) => {
    setSelectedPatientId(patient.patientId)
    setSelectedPhysicianId(Number(patient.physicianId))
    setSelectedPatientHasVials(patient.openVialsFlag ?? false)
    setIsDiscontinuePatientModalOpen(true)
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.PatientDiscontinue, {
      userId: userDetails?.contactCard.contactId,
      location: 'contextMenu',
      patientId: patient.patientId,
      physicianId: patient.physicianId
    })
  }

  const handleDiscontiuationRequest = () => {
    if (selectedPatientHasVials) {
      return
    } else {
      setIsDiscontinuationSubmitted(true)
      history.push(
        `/programs/my-physicians/${selectedPhysicianId}/${selectedPatientId}/discontinue-patient`
      )
    }
  }

  const orderResupplyRedirect = (
    programId: number,
    patient: PatientSummaryDto
  ) => {
    history.push({
      pathname: `/programs/access-programs/${programId}/patient-access-form/${patient.physicianId}`,
      state: {
        orderType: OPAOrderTypes.Resupply,
        patientNumber: patient.patientNumber,
        patientId: patient.patientId,
        patientStatus: patient.patientOrderStatus,
        stockOrder: patient.stockOrderFlag,
        from: window.location.pathname
      }
    })
  }

  const handleOrderResuplly = (
    patient: PatientSummaryDto,
    statusFlag: boolean = false
  ) => {
    setIsLoading(true)
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.ReadyForResupply, {
      userId: userDetails?.contactCard.contactId,
      location: statusFlag ? 'status' : 'contextMenu'
    })
    patient.programId && orderResupplyRedirect(patient.programId, patient)

    //Fallback for programId missing in patient summary
    getPatientDetail(patient.patientId.toString(), patient.physicianId)
      .then((response) => {
        const programId = response.data.program.programId
        programId && orderResupplyRedirect(programId, patient)
      })
      .catch((err) => {
        handlePatientError(err)
        cancelGetPatientDetail()
        setIsLoading(false)
      })
  }

  const handleReconcileVials = (patient: PatientSummaryDto) => {
    history.push({
      pathname: `/programs/my-physicians/${patient.physicianId}/${patient.patientId}`,
      state: {
        from: window.location.pathname,
        activeTabIndex: 1 // Pass the activeTabIndex parameter to set the second tab as active
      }
    })
  }

  const handleTrackYourOrder = (patient: PatientSummaryDto) => {
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.DeliveryTracking, {
      location: history.location.pathname,
      userId: userDetails?.contactCard.contactId
    })
    if (patient.trackingLink) {
      window.location.href = patient.trackingLink
    }
  }

  const handleTransferPatient = (patient: PatientSummaryDto) => {
    history.push(
      `/programs/my-physicians/${patient.physicianId}/${patient.patientId}?transfer-patient-form=true`
    )
  }

  const allStatusActions = {
    [ActionDropdownConstants.BeginOrder]: {
      text: 'patient_detail:patient_actions.begin_order',
      onClick: (patient: PatientSummaryDto) =>
        handleActionAfterCheck(patient, () => handleBeginOrder(patient))
    },
    [ActionDropdownConstants.DiscontinuePatient]: {
      text: 'patient_detail:patient_actions.discontinue_patients',
      onClick: (patient: PatientSummaryDto) =>
        handleActionAfterCheck(patient, () => handleDiscontinuePatient(patient))
    },
    [ActionDropdownConstants.ContinueOrder]: {
      text: 'patient_detail:patient_actions.continue_order',
      onClick: (patient: PatientSummaryDto) =>
        handleActionAfterCheck(patient, () => handleContinueOrder(patient))
    },
    [ActionDropdownConstants.OrderResuply]: {
      text: 'patient_detail:patient_actions.order_resupply',
      onClick: (patient: PatientSummaryDto) =>
        handleActionAfterCheck(patient, () => handleOrderResuplly(patient))
    },
    [ActionDropdownConstants.TrackYourOrder]: {
      text: 'patient_detail:patient_actions.track_your_order',
      onClick: (patient: PatientSummaryDto) =>
        handleActionAfterCheck(patient, () => handleTrackYourOrder(patient))
    },
    [ActionDropdownConstants.ReconcileVials]: {
      text: 'patient_detail:patient_actions.reconcile_vials',
      onClick: (patient: PatientSummaryDto) =>
        handleActionAfterCheck(patient, () => handleReconcileVials(patient))
    },
    [ActionDropdownConstants.TransferPatient]: {
      text: 'patient_detail:patient_actions.transfer_patients',
      onClick: (patient: PatientSummaryDto) =>
        handleActionAfterCheck(patient, () => handleTransferPatient(patient))
    },
    [ActionDropdownConstants.DeletePatient]: {
      text: 'patient_detail:patient_actions.delete_patient',
      onClick: (patient: PatientSummaryDto) =>
        handleActionAfterCheck(patient, () => handleDeleteAction())
    }
  }

  const getStatusActions = (
    patient: PatientSummaryDto
  ): ActionDropdownItem[] => {
    const {
      patientOrderStatus: status,
      openVialsFlag: hasReconciliation,
      trackingLink,
      stockOrderFlag,
      programSupportsDiscontinuation,
      orderType
    } = patient

    const actions: ActionDropdownItem[] = []

    // Define action constants for easier reference
    const resupplyAction =
      allStatusActions[ActionDropdownConstants.OrderResuply]
    const trackOrderAction =
      allStatusActions[ActionDropdownConstants.TrackYourOrder]
    const reconcileVialsAction =
      allStatusActions[ActionDropdownConstants.ReconcileVials]
    const transferPatientAction =
      allStatusActions[ActionDropdownConstants.TransferPatient]
    const discontinuePatientAction =
      allStatusActions[ActionDropdownConstants.DiscontinuePatient]
    const beginOrderAction =
      allStatusActions[ActionDropdownConstants.BeginOrder]
    const continueOrderAction =
      allStatusActions[ActionDropdownConstants.ContinueOrder]
    const deletePatientAction =
      allStatusActions[ActionDropdownConstants.DeletePatient]

    switch (status) {
      case PatientOrderStatus.Processing:
        if (!stockOrderFlag && patient.trackingLink !== 'N/A') {
          actions.push(resupplyAction)
        }
        break
      case PatientOrderStatus.FailedDelivery:
      case PatientOrderStatus.Expired:
        if (!stockOrderFlag) {
          actions.push(resupplyAction)
        }
        break

      case PatientOrderStatus.Pending:
        if (!stockOrderFlag) {
          actions.push(resupplyAction)
        }
        if (hasReconciliation && !stockOrderFlag) {
          actions.push(reconcileVialsAction)
        }
        break

      case PatientOrderStatus.InTransit:
      case PatientOrderStatus.OutForDelivery:
      case PatientOrderStatus.Available_for_Pickup:
      case PatientOrderStatus.FailedAttempt:
        if (!stockOrderFlag) {
          actions.push(resupplyAction)
        }
        if (trackingLink) {
          actions.push(trackOrderAction)
        }
        break

      case PatientOrderStatus.Delivered:
      case PatientOrderStatus.Shipped:
      case PatientOrderStatus.ReadyForResupply:
        if (!stockOrderFlag) {
          actions.push(resupplyAction)
        }
        if (hasReconciliation && !stockOrderFlag) {
          actions.push(reconcileVialsAction)
        }
        if (!stockOrderFlag) {
          actions.push(transferPatientAction)
        }
        if (!stockOrderFlag && programSupportsDiscontinuation) {
          actions.push(discontinuePatientAction)
        }
        break

      case PatientOrderStatus.ProgramClosed:
        if (!stockOrderFlag && programSupportsDiscontinuation) {
          actions.push(discontinuePatientAction)
        }
        break

      case PatientOrderStatus.BeginOrder:
        actions.push(beginOrderAction)
        if (
          !stockOrderFlag &&
          programSupportsDiscontinuation &&
          orderType !== OPAOrderTypes.InitialOrder
        ) {
          actions.push(discontinuePatientAction)
        }
        if (
          orderType === OPAOrderTypes.InitialOrder ||
          orderType === OPAOrderTypes.StockOrder
        ) {
          actions.push(deletePatientAction)
        }
        break

      case PatientOrderStatus.IncompletePAF:
        actions.push(continueOrderAction)
        if (
          !stockOrderFlag &&
          programSupportsDiscontinuation &&
          orderType !== OPAOrderTypes.InitialOrder
        ) {
          actions.push(discontinuePatientAction)
        }
        if (
          orderType === OPAOrderTypes.InitialOrder ||
          orderType === OPAOrderTypes.StockOrder
        ) {
          actions.push(deletePatientAction)
        }
        break
      case PatientOrderStatus.IncompleteCheckout:
        actions.push(continueOrderAction)
        if (
          orderType === OPAOrderTypes.InitialOrder ||
          orderType === OPAOrderTypes.StockOrder
        ) {
          actions.push(deletePatientAction)
        }
        break
      case PatientOrderStatus.MedicalReviewDeclined:
      case PatientOrderStatus.HealthAuthorityDeclined:
        if (orderType === OPAOrderTypes.Resupply) {
          actions.push(resupplyAction)
        }
        break

      case PatientOrderStatus.PatientDiscontinued:
      default:
        break
    }

    return actions
  }

  const doGetPatients = (searchParams: PatientSummarySearchDto): any => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    getPatients({
      ...searchParams,
      sorting: {
        ...searchParams.sorting,
        order: searchParams.sorting.order.toUpperCase()
      }
    })
      .then((response: any) => {
        if (response) {
          setPatients(response?.data.result)
          setLoadedPatients(true)
          handleResultCountChange &&
            handleResultCountChange(response?.data.pagination.total ?? 0)
          const paginationDto = response.data.pagination
          setPagination({
            count: paginationDto.count,
            skip: paginationDto.skip,
            take: paginationDto.take,
            total: paginationDto.total
          })
          //Display nudge link to create a patient
          searchParams.query === '' &&
          (!response.data.result || response.data.result.length < 1)
            ? setIsNoPatientsAlternate(true)
            : setIsNoPatientsAlternate(false)
          setIsLoading(false)
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.ResultsForSearchQuery,
            {
              searchLocation: history.location.pathname,
              query: searchParams.query,
              userId: userDetails?.contactCard.contactId,
              numberResults: response?.data?.result?.length
            }
          )
        }
      })
      .catch((error: AxiosError) => handleError(error))
      .finally(() => {
        setIsLoading(false)
      })
  }

  // Get patients
  useEffect(() => {
    if (shouldFetchPatients) {
      doGetPatients(searchParams)
      setShouldFetchPatients(false)
    }
  }, [searchParams, shouldFetchPatients, doGetPatients])

  // Track changes to pagination and tabs as they update and update query string if it changes
  useUpdateQueryParam({ sort: searchParams.sorting.sortBy })
  useUpdateQueryParam({
    sortDirection: searchParams.sorting.order
  })
  useUpdateQueryParam({ pageSize: searchParams.pagination.take })
  useUpdateQueryParam({
    pageIndex: searchParams.pagination.skip / searchParams.pagination.take
  })
  useUpdateQueryParam({
    query: searchParams.query
  })

  //Initials Colours
  useMemo(() => {
    setColorsArray(getRandomColor(searchParams.pagination.take))
  }, [searchParams])

  const handleOnSearch = (queryString: string) => {
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.SubmitSearchQuery, {
      location: history.location.pathname,
      query: queryString,
      searchAPI: 'suggest',
      userId: userDetails?.contactCard.contactId
    })
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: 0,
        take: patientsPerPage
      },
      query: queryString
    })
    setShouldFetchPatients(true)
  }

  const handleSetSelectedFilterOption = useCallback((option: string) => {
    setSelectedFilterOptions((prevOptions) => {
      const isOptionIncluded = prevOptions.includes(option)
      return isOptionIncluded
        ? prevOptions.filter((x) => x !== option)
        : [...prevOptions, option]
    })
  }, [])

  useEffect(() => {
    // https://clinigen.atlassian.net/browse/CLOS-8123
    // const patientFilterCheckedFlag = localStorage.getItem(
    //   'patientFilterChecked'
    // )
    // patientFilterCheckedFlag !== 'false'
    setPatientFilterChecked(false)
  }, [])

  useEffect(() => {
    const newFilter: PatientSummarySearchFilterDto = {
      ...searchParams.filter,
      showMyPatient: selectedFilterOptions.includes(
        PatientFilterOptions.MyPatients
      ),
      showMyProgram: selectedFilterOptions.includes(
        PatientFilterOptions.MyPrograms
      ),
      showMyPhysician: false
    }
    if (shouldSearchParamsUpdate(searchParams.filter, newFilter)) {
      setSearchParams((prevParams) => ({
        ...prevParams,
        pagination: {
          skip: 0,
          take: patientsPerPage
        },
        filter: newFilter
      }))
      setShouldFetchPatients(true)
    }
  }, [selectedFilterOptions, searchParams.filter, patientsPerPage])

  const handleSetSortOption = (option: sortOption) => {
    const newSearchParams = {
      ...searchParams,
      sorting: {
        sortBy: option.optionId,
        order:
          option.optionId === sortOptions[0].optionId
            ? SortDirectionType.Descending
            : SortDirectionType.Ascending
      },
      pagination: {
        ...searchParams.pagination,
        skip: 0 // Reset to first page when sorting
      }
    }
    setSearchParams(newSearchParams)
    setSelectedSortOption(option)
    setShouldFetchPatients(true)
  }

  const shouldSearchParamsUpdate = (
    existingFilters: PatientSummarySearchFilterDto,
    newFilters: PatientSummarySearchFilterDto
  ) => {
    return (
      existingFilters.showMyPatient !== newFilters.showMyPatient ||
      existingFilters.showMyPhysician !== newFilters.showMyPhysician ||
      existingFilters.showMyProgram !== newFilters.showMyProgram
    )
  }

  const columns: IColumn[] = [
    {
      title: t('patient_detail:patient_table_columns.patient_column'),
      renderValue: function column(patient: PatientSummaryDto, index?: number) {
        return (
          <StyledPatientColumn>
            <StyledPatientInitialsAvatar
              className={'avatar'}
              background={colorsArray[index!]}
              blur={
                !patient.stockOrderFlag &&
                (!patient.physicianAssociationFlag ||
                  !patient.programEnrolledFlag)
              }
            >
              {trimInitials(patient.initials)}
            </StyledPatientInitialsAvatar>

            <StyledPatientDateNameWrap>
              <ClinText
                className={
                  patient.stockOrderFlag
                    ? ''
                    : patient.dateOfBirth
                    ? !patient.physicianAssociationFlag ||
                      !patient.programEnrolledFlag // Add this condition to determine if the blur should be applied.
                      ? 'blur'
                      : ''
                    : 'blur'
                }
                variant={TypographyVariant.SmallUI}
                lineHeight={ClinTheme.lineHeights.largeParagraph}
              >
                {patient.stockOrderFlag
                  ? t('patient_detail:create_patients.stock_order_title')
                  : patient.dateOfBirth
                  ? DateTime.fromISO(patient.dateOfBirth, { zone: 'utc' })
                      .toFormat('dd/MMM/yyyy')
                      .toUpperCase()
                  : '**/***/****'}
              </ClinText>
              <ClinText
                whiteSpace="nowrap"
                fontSize={ClinTheme.fontSizes[1]}
                lineHeight={ClinTheme.lineHeights.heading[0]}
              >
                {patient.patientNumber}
              </ClinText>
            </StyledPatientDateNameWrap>
          </StyledPatientColumn>
        )
      },
      width: '19.5%',
      isSortable: true,
      name: PatientColumn.Patient
    },
    {
      title: t('patient_detail:patient_table_columns.program_column'),
      renderValue: function column(patient: PatientSummaryDto) {
        return (
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
          >
            {patient.programName}
          </ClinText>
        )
      },
      width: '20%',
      hide: showTableInTab,
      isSortable: true,
      name: PatientColumn.Program
    },
    {
      title: t('patient_detail:patient_table_columns.physician_column'),
      renderValue: function column(patient: PatientSummaryDto) {
        return (
          <ClinText
            whiteSpace="nowrap"
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
          >
            {showTableInTab
              ? patient.stockOrderFlag
                ? truncateString(patient.physicianFullName, 15)
                : truncateString(patient.physicianFullName)
              : patient.physicianFullName}
          </ClinText>
        )
      },
      width: '20%',
      isSortable: true,
      name: PatientColumn.Physician
    },
    {
      title: t('patient_detail:patient_table_columns.last_updated_column'),
      renderValue: function column(patient: PatientSummaryDto) {
        return (
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            color="#4F5A65"
          >
            {patient.lastUpdateDate
              ? DateTime.fromISO(patient.lastUpdateDate)
                  .toFormat('dd/MMM/yyyy')
                  .toUpperCase()
              : ''}
          </ClinText>
        )
      },
      width: '12%',
      isSortable: true,
      name: PatientColumn.LastUpdated
    },
    {
      title: showTableInTab
        ? t('patient_detail:patient_table_columns.status_column_short')
        : t('patient_detail:patient_table_columns.status_column'),
      renderValue: function column(patient: PatientSummaryDto) {
        let statusComponent
        switch (patient.patientOrderStatus) {
          case PatientOrderStatus.PatientDiscontinued:
          case PatientOrderStatus.ProgramClosed:
          case PatientOrderStatus.HealthAuthorityDeclined:
          case PatientOrderStatus.MedicalReviewDeclined:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[
                    patient.patientOrderStatus
                  ]
                )}
              >
                <StyledStatusWrapper color="rgba(117, 117, 117, 0.1)">
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.darkGrey}
                    textAlign="center"
                    fontSize={
                      showTableInTab
                        ? ClinTheme.fontSizes[0]
                        : ClinTheme.fontSizes[1]
                    }
                    lineHeight={
                      showTableInTab ? '2' : ClinTheme.lineHeights.small
                    }
                  >
                    {t(
                      patientOrderStatusTranslation[patient.patientOrderStatus]
                    )}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          case PatientOrderStatus.BeginOrder:
          case PatientOrderStatus.IncompletePAF:
          case PatientOrderStatus.IncompleteCheckout:
          case PatientOrderStatus.ReadyForResupply:
            statusComponent = (
              <span style={{ position: 'relative' }}>
                {newFeaturesList &&
                patientNumber &&
                newFeature &&
                patient.patientNumber === patientNumber &&
                newFeaturesList[newFeature] ? (
                  <StyledTooltipWrapper onClick={(e) => e.stopPropagation()}>
                    <ClinNewFeatureTooltipContainer
                      feature={newFeaturesList[newFeature]}
                      openTooltipInitially={true}
                      showFeatureFromTable={true}
                      disableDismiss={true}
                      tooltipWidth="242px"
                    ></ClinNewFeatureTooltipContainer>
                  </StyledTooltipWrapper>
                ) : (
                  <></>
                )}
                <StatusTooltip
                  tooltipText={t(
                    patientOrderStatusTooltipTranslation[
                      patient.patientOrderStatus
                    ]
                  )}
                  additionalClass={
                    newFeaturesList &&
                    patientNumber &&
                    newFeature &&
                    patient.patientNumber === patientNumber &&
                    newFeaturesList[newFeature]
                      ? 'navbarZIndexTooltipUp'
                      : ''
                  }
                >
                  <ClinButton
                    variant="linkButton"
                    style={{
                      textDecoration: 'none'
                    }}
                    display="block"
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      allStatusActions[ActionDropdownConstants.ContinueOrder] &&
                        allStatusActions[
                          ActionDropdownConstants.ContinueOrder
                        ].onClick(patient)
                    }}
                  >
                    <StyledStatusWrapper color="rgba(0, 67, 206, 0.1)">
                      <ClinText
                        whiteSpace="nowrap"
                        fontWeight={ClinTheme.fontWeights.medium}
                        color={ClinTheme.colors.tealishBlue}
                        textAlign="center"
                        fontSize={
                          showTableInTab
                            ? ClinTheme.fontSizes[0]
                            : ClinTheme.fontSizes[1]
                        }
                        lineHeight={
                          showTableInTab ? '2' : ClinTheme.lineHeights.small
                        }
                      >
                        {t(
                          patientOrderStatusTranslation[
                            patient.patientOrderStatus
                          ]
                        )}
                      </ClinText>
                    </StyledStatusWrapper>
                  </ClinButton>
                </StatusTooltip>
              </span>
            )
            break
          case PatientOrderStatus.InTransit:
          case PatientOrderStatus.OutForDelivery:
          case PatientOrderStatus.Available_for_Pickup:
          case PatientOrderStatus.FailedAttempt:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[
                    patient.patientOrderStatus
                  ]
                )}
              >
                <StyledStatusWrapper color="rgba(0, 162, 35, 0.1)">
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.greenValid}
                    textAlign="center"
                    fontSize={
                      showTableInTab
                        ? ClinTheme.fontSizes[0]
                        : ClinTheme.fontSizes[1]
                    }
                    lineHeight={
                      showTableInTab ? '2' : ClinTheme.lineHeights.small
                    }
                  >
                    {t(
                      patientOrderStatusTranslation[patient.patientOrderStatus]
                    )}
                    {patient.trackingLink && (
                      <StyledTrackingLinkWrapper
                        href={patient.trackingLink}
                        text={t('patient_detail:patient_actions.track')}
                        target="_blank"
                      />
                    )}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          case PatientOrderStatus.Delivered:
            statusComponent = (
              <StyledStatusWrapper color="rgba(0, 162, 35, 0.1)">
                <ClinText
                  whiteSpace="nowrap"
                  fontWeight={ClinTheme.fontWeights.medium}
                  color={ClinTheme.colors.greenValid}
                  textAlign="center"
                  fontSize={
                    showTableInTab
                      ? ClinTheme.fontSizes[0]
                      : ClinTheme.fontSizes[1]
                  }
                  lineHeight={
                    showTableInTab ? '2' : ClinTheme.lineHeights.small
                  }
                >
                  {t(patientOrderStatusTranslation[patient.patientOrderStatus])}
                </ClinText>
              </StyledStatusWrapper>
            )
            break
          case PatientOrderStatus.Pending:
          case PatientOrderStatus.Shipped:
          case PatientOrderStatus.Submitted:
          case PatientOrderStatus.Processing:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[
                    patient.patientOrderStatus
                  ]
                )}
              >
                <StyledStatusWrapper color="rgba(0, 162, 35, 0.1)">
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.greenValid}
                    textAlign="center"
                    fontSize={
                      showTableInTab
                        ? ClinTheme.fontSizes[0]
                        : ClinTheme.fontSizes[1]
                    }
                    lineHeight={
                      showTableInTab ? '2' : ClinTheme.lineHeights.small
                    }
                  >
                    {t(
                      patientOrderStatusTranslation[patient.patientOrderStatus]
                    )}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          case PatientOrderStatus.UnderReview:
          case PatientOrderStatus.UnderMedicalReview:
          case PatientOrderStatus.HealthAuthorityReview:
          case PatientOrderStatus.FailedDelivery:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[
                    patient.patientOrderStatus
                  ]
                )}
              >
                <StyledStatusWrapper color="rgba(215, 106, 0, 0.10)">
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.orangeWarn}
                    textAlign="center"
                    fontSize={
                      showTableInTab
                        ? ClinTheme.fontSizes[0]
                        : ClinTheme.fontSizes[1]
                    }
                    lineHeight={
                      showTableInTab ? '2' : ClinTheme.lineHeights.small
                    }
                  >
                    {t(
                      patientOrderStatusTranslation[patient.patientOrderStatus]
                    )}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          default:
            statusComponent = (
              <StyledStatusWrapper color="rgba(117, 117, 117, 0.1)">
                <ClinText
                  whiteSpace="nowrap"
                  fontWeight={ClinTheme.fontWeights.medium}
                  color={ClinTheme.colors.darkGrey}
                  textAlign="center"
                  fontSize={
                    showTableInTab
                      ? ClinTheme.fontSizes[0]
                      : ClinTheme.fontSizes[1]
                  }
                  lineHeight={
                    showTableInTab ? '2' : ClinTheme.lineHeights.small
                  }
                >
                  {patient.patientOrderStatus}
                </ClinText>
              </StyledStatusWrapper>
            )
            break
        }

        return statusComponent
      },
      width: '20%',
      isSortable: true,
      name: PatientColumn.Order
    },
    {
      title: t('patient_detail:patient_table_columns.actions_column'),
      renderValue: function column(patient: PatientSummaryDto, index?: number) {
        const openAction = index === openActionIndex
        const openActionStyles = {
          background: '#F5F5F5',
          borderRadius: '4px',
          paddingRight: '3px'
        }

        const actionItems: ActionDropdownItem[] = getStatusActions(patient)

        const closedActionStyles = {
          paddingRight: '3px'
        }
        const firstPatient = patients?.[0]
        return (
          <div style={{ position: 'relative' }}>
            <div
              style={
                !actionItems.length ||
                !patient.physicianAssociationFlag ||
                !patient.programEnrolledFlag
                  ? { cursor: 'default' }
                  : { cursor: 'pointer' }
              }
              onClick={(e) => {
                e.stopPropagation()
                if (newFeaturesList?.[NewFeatureElements.PatientsAction]) {
                  handleCloseFeatureHighlight &&
                    handleCloseFeatureHighlight(
                      NewFeatureElements.PatientsAction
                    )
                }
                if (
                  actionItems.length &&
                  patient.physicianAssociationFlag &&
                  patient.programEnrolledFlag
                ) {
                  setOpenActionIndex(openActionIndex === index ? -1 : index!)
                }
              }}
            >
              {newFeaturesList &&
                displayNewFeature(NewFeatureElements.PatientsAction) &&
                firstPatient &&
                firstPatient.patientId === patient.patientId && (
                  <StyledTooltipActionsWrapper
                    onClick={(event) => event.stopPropagation()}
                  >
                    <ClinNewFeatureTooltipContainer
                      feature={
                        newFeaturesList?.[NewFeatureElements.PatientsAction]
                      }
                      openTooltipInitially={
                        !newFeaturesList[NewFeatureElements.PatientsAction]
                          ?.isCancelled
                      }
                      showFeatureFromTable={true}
                      disableDismiss={true}
                      tooltipWidth="242px"
                      total={4}
                      current={4}
                    ></ClinNewFeatureTooltipContainer>
                  </StyledTooltipActionsWrapper>
                )}
              <StyledActionWrapper
                showGlowEffect={
                  newFeaturesList &&
                  displayNewFeatureGlowEffect(
                    NewFeatureElements.PatientsAction
                  ) &&
                  firstPatient &&
                  firstPatient.patientId === patient.patientId
                }
              >
                {newFeaturesList &&
                displayNewFeatureGlowEffect(
                  NewFeatureElements.PatientsAction
                ) &&
                firstPatient &&
                firstPatient.patientId === patient.patientId ? (
                  <StyledIcon width={28} height={28} src={GlowActionIcon} />
                ) : (
                  <ClinIcon
                    style={openAction ? openActionStyles : closedActionStyles}
                    iconName={ClinIconPathName.ThreeDots}
                    iconFill={
                      actionItems.length === 0 ||
                      !patient.physicianAssociationFlag ||
                      !patient.programEnrolledFlag
                        ? ClinTheme.colors.grey
                        : ClinTheme.colors.darkGrey
                    }
                    iconSize={28}
                    viewBox="0 0 25 26"
                  />
                )}
              </StyledActionWrapper>
            </div>
            {/* I wont open this div */}
            {openAction && (
              <TableActionMenu
                handleCloseActionMenu={handleCloseActionMenu}
                items={actionItems}
                action={patient}
              ></TableActionMenu>
            )}
          </div>
        )
      },
      width: '8.5%',
      isSortable: false
    }
  ]

  const handleToggleSort = (columnName: string | undefined) => {
    const currentScrollY = window.scrollY
    const sortColumn = columns.find((x) => x.name === columnName)
      ?.name as string

    if (sortColumn) {
      const newSortDirection =
        searchParams.sorting.sortBy === sortColumn &&
        searchParams.sorting.order === SortDirectionType.Descending
          ? SortDirectionType.Ascending
          : SortDirectionType.Descending

      const newSearchParams = {
        ...searchParams,
        sorting: {
          sortBy: sortColumn,
          order: newSortDirection
        },
        pagination: {
          ...searchParams.pagination,
          skip: 0 // Reset to first page when sorting
        }
      }

      setSearchParams(newSearchParams)
      setShouldFetchPatients(true)

      // Use a setTimeout to ensure the scrollTo action is placed into the event loop
      // after the state update has caused a re-render.
      setTimeout(() => {
        window.scrollTo(0, currentScrollY)
      }, 0)
    }
  }

  const isVisibleNotification = (): boolean => {
    return stockOrderOnCancel
  }

  return (
    <>
      {isVisibleNotification() && (
        <ClinNotification
          title={t('patient_detail:notification_cancel_order_success_title')}
          onClose={() => {}}
        />
      )}
      {showDeleteNotification && (
        <ClinNotification
          title={t('patient_detail:notification_delete_patient_title')}
          onClose={() => setShowDeleteNotification(false)}
        />
      )}
      <PatientDashboard
        patients={patients}
        loadedPatients={loadedPatients}
        isLoading={isLoading}
        showTableInTab={showTableInTab}
        columns={columns.filter((x) => !x.hide)}
        query={searchParams.query}
        pagination={pagination}
        userCountry={portfolioCountryCode}
        selectedSortOption={selectedSortOption}
        selectedSortDirection={searchParams.sorting.order}
        selectedFilterOptions={selectedFilterOptions}
        newFeaturePatientNumber={patientNumber}
        selectedSortColumn={searchParams.sorting.sortBy}
        newFeaturesList={newFeaturesList}
        isNoPatientsAlternate={isNoPatientsAlternate}
        isProgramOpenForResupply={isProgramOpenForResupply}
        handlePageClicked={handlePageClicked}
        handlePageSizeChange={handlePageSizeChange}
        handleOnSearch={handleOnSearch}
        handleSetSelectedFilterOption={handleSetSelectedFilterOption}
        handleSelectedSortOption={handleSetSortOption}
        handleRowClicked={handleRowClicked}
        handleToggleSort={handleToggleSort}
        handleCloseFeatureHighlight={handleCloseFeatureHighlight}
        displayNewFeature={displayNewFeature}
        displayNewFeatureGlowEffect={displayNewFeatureGlowEffect}
        patientFilterChecked={patientFilterChecked}
      />
      <PatientEnrollModal
        isOpen={isEnrollModalOpen}
        handleEnroll={handleEnroll}
        handleClose={() => setIsEnrollModalOpen(false)}
      />
      <PhysicianAssociationModal
        isOpen={isPhysicianAssociationModalOpen}
        handleAssociate={handleAssociate}
        handleClose={() => setIsPhysicianAssociationModalOpen(false)}
        physicianName={physicianFullName}
      />
      <PatientDashboardDiscontinueModal
        isOpen={isDiscontinuePatientModalOpen}
        isSubmitting={isDiscontinuationSubmitted}
        selectedPatientHasVials={selectedPatientHasVials}
        handleSubmit={handleDiscontiuationRequest}
        handleClose={() => setIsDiscontinuePatientModalOpen(false)}
      />
      <PatientDeleteModal
        isOpen={deletePatientModalMode !== undefined}
        isSubmitting={deletePatientModalMode === ModalMode.submitting}
        handleDelete={handleDeletePatientRequest}
        handleClose={() => setDeletePatientModalMode(undefined)}
      />
      <ClinModal
        isOpen={isPatientOrderNumber}
        isDismissible={false}
        onClose={() => setIsPatientOrderNumber(false)}
      >
        <ClinText variant={TypographyVariant.LargeParagraph}>
          Unable to load your order, please contact Customer Services to
          complete your order
        </ClinText>
        <ClinSpacer height={ClinTheme.space[4]} />
        <ClinGroup justifyContent="flex-end">
          <ClinButton
            variant="primary"
            onClick={() => setIsPatientOrderNumber(false)}
          >
            {t('common:buttons.ok')}
          </ClinButton>
        </ClinGroup>
      </ClinModal>
    </>
  )
}
