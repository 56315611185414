import styled from 'styled-components'

import { ClinTheme } from '../../../../ClinTheme'
import { ClinButton } from '../../../../components/ClinButton'

export const Container = styled.div({
  background: ClinTheme.colors.white,
  width: '100%',
  overflow: 'hidden',
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  margin: '16px 0',

  '.full-width': {
    gridColumn: 'span 2',
    textAlign: 'center',
    padding: '16px'
  }
})

export const Row = styled.div`
  display: contents;
  box-sizing: border-box;
  width: 100%;

  :last-child .full-width {
    padding-top: 8px;
  }
`

export const Column = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px 4px 8px 16px;

  :last-child {
    padding: 8px 16px 8px 4px;
  }

  &.full-width {
    grid-column: span 2;
    text-align: center;
    padding: 16px;
  }

  &.basket {
    padding: 0;
  }

  .clin-mobile-view {
    padding: 16px;
  }
`

export const StyledClinButton = styled(ClinButton)({
  fontSize: ClinTheme.fontSizes[2],
  padding: '8px 24px',
  width: '100%'
})
