import { transparentize } from 'polished'
import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'

export enum ChipState {
  Default,
  Active,
  Disabled
}

export interface IClinChipStyles {
  /** The state of the chip */
  state?: ChipState
  /** Whether it receives clicks */
  isInteractive?: boolean
  showGlowEffect?: boolean
}

export const StyledClinChip = styled.div<IClinChipStyles>(
  {
    display: 'inline-flex',
    fontWeight: ClinTheme.fontWeights.medium,
    fontSize: ClinTheme.fontSizes[1],
    margin: ClinTheme.space[1],
    padding: `${ClinTheme.space[1]}px ${ClinTheme.space[3]}px`,
    color: ClinTheme.colors.primaryMid,
    border: `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.primaryMid}`,
    borderRadius: `${ClinTheme.radii[2]}px`,
    hyphens: 'auto'
  },
  ({
    state = ChipState.Default,
    isInteractive = false,
    showGlowEffect
  }: IClinChipStyles) => ({
    borderColor:
      state === ChipState.Active
        ? ClinTheme.colors.primaryMid
        : ClinTheme.colors.grey,
    color:
      state === ChipState.Disabled
        ? ClinTheme.colors.grey
        : ClinTheme.colors.primaryMid,
    backgroundColor:
      state === ChipState.Active
        ? transparentize(0.85, ClinTheme.colors.primaryMid)
        : ClinTheme.colors.white,
    cursor: isInteractive ? 'pointer' : 'default',
    zIndex: showGlowEffect ? 3 : 0,
    boxShadow: showGlowEffect ? '0px 0px 14px 0px #FFF' : 'unset'
  })
)
