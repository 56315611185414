import React, { Fragment, FunctionComponent, RefObject } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  StyledSupportAccordion,
  StyleSupportHeaderIcon,
  StyleSupportLinkCards,
  StyleSupportLinkCard
} from './SupportDetail.styles'
import { ClinTheme } from '../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../components/ClinAccordion'
import { ClinButton } from '../../components/ClinButton'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinResponsiveVideoEmbed } from '../../components/ClinResponsiveVideoEmbed'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { UserRole, isAusGaUser } from '../../constants'
import { TopicLinkCard } from '../../features/TopLinkCard'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'
import {
  Accordion,
  AccordionContent,
  TopicTemplate
} from '../../types/ContentTypes'
import { truncateString } from '../../utils/truncateString'

interface ISupportDetailProps {
  /** The slug of the page */
  slug: string
  /** The main content of the page */
  content: TopicTemplate
  /** A list of other available topics */
  otherTopicLinks: TopicTemplate[]
  /** The name of the parent page */
  parentPageName: string
  /** User country*/
  userCountry: string
  /** User role*/
  userRole?: UserRole
  /** A function which is called when a user requests to go back to another page */
  handleGoBack?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** A function which is called when a user clicks an accordion item header */
  handleSectionCallback?: (ref: RefObject<HTMLIFrameElement>) => void
  /** A function which is called when a user requests to go back to another page */
  handlePageChangeRequest?: (path: string) => void
}

export const SupportDetail: FunctionComponent<ISupportDetailProps> = ({
  slug,
  content,
  otherTopicLinks,
  parentPageName,
  userCountry,
  userRole,
  handleGoBack,
  handleSectionCallback,
  handlePageChangeRequest
}) => {
  const { t } = useTranslation()
  const { enableNewHomePage } = useCheckUnableHomePage()
  const accordionBlocks = content?.flatData?.content?.filter(
    (item) => item.__typename === 'Accordion'
  ) as Accordion[]

  return (
    <ClinPageContentFrame
      crumbs={[
        isAusGaUser(userCountry, userRole) && enableNewHomePage
          ? {
              path: '/home',
              name: t('navigation:home')
            }
          : { path: '/orders', name: t('orders:page_name') },
        { path: '/support', name: parentPageName },
        {
          path: `/support/${slug}`,
          name: content?.flatData?.title
            ? truncateString(content?.flatData?.title)
            : ''
        }
      ]}
    >
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col>
          <ClinButton variant="linkButton" onClick={handleGoBack}>
            <ClinIcon iconName={ClinIconPathName.ArrowLeft} />
            &nbsp;{t('common:support.back_to_btn')}
          </ClinButton>
          <ClinSpacer />
        </Col>
      </Row>
      <Row align="center">
        <Col xs={10}>
          <ClinText
            as="h1"
            fontWeight={ClinTheme.fontWeights.bold}
            variant={TypographyVariant.H2}
          >
            {content?.flatData?.title}
          </ClinText>
        </Col>
        <Col xs={2}>
          {content?.flatData?.icon && (
            <StyleSupportHeaderIcon>
              <ClinIcon
                iconSize={ClinTheme.fontSizes[7]}
                iconName={content.flatData.icon as ClinIconPathName}
                iconFill={ClinTheme.colors.primary}
              />
            </StyleSupportHeaderIcon>
          )}
        </Col>
      </Row>
      <ClinSpacer />
      {accordionBlocks?.map((item: Accordion) => {
        return (
          <Row key={`accordion-${item.id}`}>
            <Col sm={12}>
              {item.flatData.accordionItems &&
                item.flatData.accordionItems.length > 0 && (
                  <StyledSupportAccordion>
                    <ClinAccordion>
                      {item.flatData.accordionItems.map(
                        (accordionItem: AccordionContent) => {
                          const ref = React.createRef<HTMLIFrameElement>()
                          return (
                            <ClinAccordionItem
                              key={`accordion-item-${accordionItem.id}`}
                              title={accordionItem.flatData.title ?? ''}
                              handleSectionCallback={() =>
                                ref &&
                                handleSectionCallback &&
                                handleSectionCallback(ref)
                              }
                            >
                              {accordionItem?.flatData?.video?.[0]?.flatData
                                ?.vimeoId && (
                                <>
                                  <ClinResponsiveVideoEmbed
                                    title={`Supporting video: ${accordionItem.flatData.title}`}
                                    videoId={
                                      accordionItem.flatData.video[0].flatData
                                        .vimeoId
                                    }
                                    ref={ref}
                                  />
                                  <ClinSpacer />
                                </>
                              )}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${accordionItem.flatData.content}`
                                }}
                              />
                            </ClinAccordionItem>
                          )
                        }
                      )}
                    </ClinAccordion>
                  </StyledSupportAccordion>
                )}
            </Col>
          </Row>
        )
      })}

      <ClinSpacer height={ClinTheme.space[6]} />

      <Row>
        <Col sm={12}>
          <ClinText
            as="h2"
            fontWeight={ClinTheme.fontWeights.bold}
            variant={TypographyVariant.H4}
            marginTop="0"
          >
            {t('common:support.support_topics')}
          </ClinText>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <StyleSupportLinkCards>
            {otherTopicLinks?.map((item: TopicTemplate) => {
              const { icon, title, slug } = item.flatData
              return (
                <Fragment key={`support-content-${item.id}`}>
                  <StyleSupportLinkCard>
                    {title && (
                      <TopicLinkCard
                        title={title}
                        handlePageChangeRequest={() =>
                          handlePageChangeRequest &&
                          handlePageChangeRequest(`/support/${slug}`)
                        }
                        iconName={icon as ClinIconPathName}
                      />
                    )}
                  </StyleSupportLinkCard>
                </Fragment>
              )
            })}
          </StyleSupportLinkCards>
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
