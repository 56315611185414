import { ContactStatus } from '../../../constants'
import { IContact } from '../../../types/IContact'
import { UserStatusDto } from '../../../types/swaggerTypes'

/**
 * Add status information to contacts
 * @param statuses
 * @param contacts
 * @param targetStatus
 */
export const augmentContactWithStatus = (
  statuses: UserStatusDto[],
  contacts: IContact[],
  targetStatus: ContactStatus
): IContact[] => {
  const targetContacts: IContact[] = []
  contacts.forEach((contact) => {
    const isTarget: UserStatusDto | undefined = statuses.find(
      (s) =>
        s.email === contact.contactEmail && s.status === targetStatus.toString()
    )
    if (isTarget) {
      targetContacts.push({
        ...contact,
        status: targetStatus
      })
    }
  })
  return targetContacts
}
