import { rgba } from 'polished'
import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledSubmit = styled.div({
  paddingTop: ClinTheme.space[4],
  paddingBottom: ClinTheme.space[4],
  [mediaQuery(mobileUpBreakpoint)]: {
    marginTop: ClinTheme.space[3]
  }
})

export const StyledOverlayLoader = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  background: rgba(ClinTheme.colors.white, 0.95)
})
