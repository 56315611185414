export enum FeatureFlagKeys {
  PatientCentricJourneyPerUser = 'PatientCentricJourney',
  NewPatientDetailsPage = 'NewPatientDetailsPage',
  EnableClientLogo = 'EnableClientLogo',
  NewHomePage = 'NewHomePage',
  NewOrderTablePage = 'NewOrderTablePage',
  NewProductVariantPage = 'NewProductVariantPage',
  NewBasketPopup = 'NewBasketPopup',
  NewOneStepCheckoutPage = 'NewOneStepCheckoutPage',
  NewProductDetails = 'NewProductDetails',
  NewBasketDrawer = 'NewBasketDrawer'
}
