import { LocationState } from 'history'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Redirect, RouteComponentProps, StaticContext } from 'react-router'

import { CheckoutSummary } from './CheckoutSummary'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { OrderCancellationCodes, isAusGaUser } from '../../constants'
import { CountryAlphaCodes } from '../../constants/countryAlpha2Codes'
import { useAppContext } from '../../context/app'
import { ActionType, useBasket } from '../../context/basket'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { removeOrderLine } from '../../services/ApiService'
import { useOrderLevelHolds } from '../../services/Holds/holds'
import { findOrderCancellationDescription } from '../../types/IOrder'
import { DeleteOrderDto } from '../../types/swaggerTypes'
import { IEddArray } from '../Products/ProductDetail/ProductDetailContainer'

export enum OrderAnnounce {
  CreditHold = 'Credit hold',
  ComplianceHold = 'Compliance hold',
  BackOrder = 'Back order'
}

export interface IOrderSummaryHold {
  orderNumber: string
  errorType:
    | OrderAnnounce.BackOrder
    | OrderAnnounce.ComplianceHold
    | OrderAnnounce.CreditHold
  errorMessage: string
}

export enum SummaryViewMode {
  Loading,
  NoOrders,
  ShowOrders
}

export interface ICheckoutSummaryProps
  extends RouteComponentProps<{}, StaticContext, LocationState> {}

export const CheckoutSummaryContainer: FunctionComponent<
  ICheckoutSummaryProps
> = ({ history }) => {
  const { dispatch, portfolioCountryCode, userRole } = useAppContext()
  const [{ completedOrder }, basketDispatch] = useBasket()

  const orderLevelHolds = useOrderLevelHolds(completedOrder?.checkedOutOrders)

  const isAus = portfolioCountryCode === CountryAlphaCodes.Australia

  let SkuAndStockArray: IEddArray

  const [viewMode, setViewMode] = useState<SummaryViewMode>(
    SummaryViewMode.Loading
  )
  const [cancelledItemIds, setCancelledItemIds] = useState<number[]>([])

  const handleCancelOrderLine = (orderNumber: string, orderLineId: number) => {
    const deleteReason: DeleteOrderDto = {
      cancelComments: '',
      cancelReason:
        findOrderCancellationDescription[OrderCancellationCodes.XXCLORDERR]
    }
    removeOrderLine(orderNumber, orderLineId.toString(), deleteReason)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setCancelledItemIds([...cancelledItemIds, orderLineId])
        }
      })
      .catch((error) => {
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `An error occurred cancelling your item. ${error}`
          )
        )
      })
  }

  useEffect(() => {
    // Set view mode directly since both conditions do the same thing
    setViewMode(SummaryViewMode.ShowOrders)

    // Note: If you need to trigger EDD calcs only when orders exist,
    // you can add that logic here:
    if (
      completedOrder?.checkedOutOrders &&
      completedOrder.checkedOutOrders.length > 0
    ) {
      // Trigger Edd Calcs if needed
    }
  }, [completedOrder])

  // On unmount clear completed orders
  useEffect(
    () => () => {
      basketDispatch({ type: ActionType.ClearCompletedOrder })
      localStorage.removeItem('EDDs')
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  if (viewMode === SummaryViewMode.NoOrders) {
    return <Redirect to={'/orders'} />
  }

  const getEddsFromLocalStorage = () => {
    const localStorageData = localStorage.getItem('EDDs')
    SkuAndStockArray = localStorageData
      ? JSON.parse(localStorageData)
      : { skuAndEddArray: [] }

    return SkuAndStockArray
  }

  const handleGoBack = () => {
    if (isAusGaUser(portfolioCountryCode, userRole)) {
      history.push('/products/catalogue')
    } else {
      history.goBack()
    }
  }

  //Stores state of accordions on the page, Expands all before calling print, Collapses to previous state
  //Handles hiding of the wootric survey as well
  const handlePrint = () => {
    const accoridonPanels = document.querySelectorAll('.accordion-panel')
    const wootricModal = document.querySelector('.wootric-desktop-modal')
    const state: number[] = []

    /**If theres accordions on page expand and save state */
    if (accoridonPanels) {
      for (let index = 0; index < accoridonPanels.length; index++) {
        state[index] = accoridonPanels[index].hasAttribute('hidden') ? 1 : 0
      }

      accoridonPanels.forEach((panel) => {
        panel.removeAttribute('hidden')
      })
    }

    wootricModal?.setAttribute('hidden', 'hidden')
    /**Actual print */
    window.print()
    /**Collapse to previous state */
    if (accoridonPanels) {
      for (let index = 0; index < accoridonPanels.length; index++) {
        if (state[index])
          accoridonPanels[index].setAttribute('hidden', 'hidden')
      }
    }
    wootricModal?.removeAttribute('hidden')
  }
  return (
    <CheckoutSummary
      isLoading={viewMode === SummaryViewMode.Loading}
      orders={completedOrder?.checkedOutOrders}
      cancelledItemIds={cancelledItemIds}
      orderLevelHolds={orderLevelHolds}
      uploadedDocuments={completedOrder?.uploadedDocuments}
      isAus={isAus}
      deliveryEdds={getEddsFromLocalStorage()}
      handleGoBack={handleGoBack}
      handleCancelOrderLine={handleCancelOrderLine}
      handlePrint={handlePrint}
    />
  )
}
