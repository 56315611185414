import { AxiosError } from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'

import { MyPhysicians } from './MyPhysicians'
import { MyPhysiciansColumn } from './MyPhysicians.model'
import {
  getParamValueFor,
  IPagination,
  useUpdateQueryParam
} from '../../components/ClinPagination/ClinPagination.model'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { useAppContext } from '../../context/app'
import { useEffectOnlyOnce } from '../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { AnalyticsPageEvent } from '../../services/Analytics/AnalyticsPageEvent'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { cancelGetPhysicians, getPhysicians } from '../../services/ApiService'
import {
  PhysiciansSummaryDto,
  PhysiciansSummarySearchDto
} from '../../types/swaggerTypes'
import { useErrorMessage } from '../../utils/useErrorMessage'

const rowsPerPage: number = 5

const defaultPagination: IPagination = {
  count: 0,
  skip: 0,
  take: rowsPerPage,
  total: 0
}

const defaultSearchParams: PhysiciansSummarySearchDto = {
  query: '',
  filter: {},
  pagination: {
    skip: 0,
    take: rowsPerPage
  },
  sorting: {
    sortBy: 'physicianFullName',
    order: SortDirectionType.Ascending
  }
}

interface IMyPhysiciansProps extends RouteComponentProps {}

export const MyPhysiciansContainer: FunctionComponent<IMyPhysiciansProps> = ({
  location,
  history
}) => {
  const { dispatch, portfolioCountryCode } = useAppContext()

  // Restore from query params
  const queryParam = new URLSearchParams(location.search)

  // Restore page Size
  const [physiciansPerPage, setPhysiciansPerPage] = React.useState<number>(
    () => {
      const previousPageSize = queryParam.get('pageSize')
      return previousPageSize ? parseInt(previousPageSize) : rowsPerPage
    }
  )

  // Restore pagination from URL params
  defaultSearchParams.pagination.take = getParamValueFor(
    'pageSize',
    queryParam,
    rowsPerPage
  )
  // PageIndex
  defaultPagination.take = defaultSearchParams.pagination.take
  defaultSearchParams.pagination.skip =
    getParamValueFor('pageIndex', queryParam) *
    defaultSearchParams.pagination.take

  const [pagination, setPagination] =
    React.useState<IPagination>(defaultPagination)

  const [searchParams, setSearchParams] =
    React.useState<PhysiciansSummarySearchDto>({
      ...defaultSearchParams
    })

  const [physicians, setPhysicians] = useState<
    PhysiciansSummaryDto[] | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const handleError = useErrorMessage('There was an error fetching physicians.')

  const handleRowClicked = (selectedPhysicianId: string | number) => {
    cancelGetPhysicians()
    history.push(`/programs/my-physicians/${selectedPhysicianId}`)
  }

  const handlePageClicked = (selectedPageIndex: number) => {
    cancelGetPhysicians()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: (selectedPageIndex - 1) * physiciansPerPage,
        take: physiciansPerPage
      }
    })
  }

  const handlePageSizeChange = (pageSize: number) => {
    cancelGetPhysicians()
    setIsLoading(true)
    setPhysiciansPerPage(pageSize)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: 0,
        take: pageSize
      }
    })
  }

  // Get physicians
  useEffect(() => {
    // searchParams and pagination changes then make call
    getPhysicians(searchParams)
      .then((response) => {
        if (response) {
          setPhysicians(response.data.result)
          const paginationDto = response.data.pagination
          setPagination({
            count: paginationDto.count,
            skip: paginationDto.skip,
            take: paginationDto.take,
            total: paginationDto.total
          })
          setIsLoading(false)
        }
      })
      .catch((error: AxiosError) => handleError(error))

    return () => {
      cancelGetPhysicians()
    }
  }, [searchParams, location, history, dispatch, handleError])

  // Track changes to pagination and tabs as they update and update query string if it changes
  useUpdateQueryParam({ pageSize: searchParams.pagination.take })
  useUpdateQueryParam({
    pageIndex: searchParams.pagination.skip / searchParams.pagination.take
  })

  type Deps = boolean[]
  useEffectOnlyOnce(
    () => {
      analyticsServiceSingleton.trackPageView(
        AnalyticsPageEvent.ViewMyPhysicians,
        {}
      )
    },
    [isLoading],
    (dependencies: Deps) => dependencies[0] === false
  )

  return (
    <MyPhysicians
      physicians={physicians}
      isLoading={isLoading}
      columns={[MyPhysiciansColumn.PhysicianName, MyPhysiciansColumn.Email]}
      pagination={pagination}
      userCountry={portfolioCountryCode}
      handleRowClicked={(selectedPhysicianId) =>
        handleRowClicked(selectedPhysicianId)
      }
      handlePageClicked={handlePageClicked}
      handlePageSizeChange={handlePageSizeChange}
    />
  )
}
