import React, { FunctionComponent } from 'react'
import { Hidden, Visible } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { calcPages } from './ClinPagination.model'
import { ClinTheme } from '../../ClinTheme'
import { ClinSelect, ClinSelectVariant } from '../ClinSelect'
import { ClinText } from '../ClinText'
import {
  StyledClinPagination,
  StyledPageButton,
  StyledPagination,
  StyledCenterContent,
  StyledLeftSpacer,
  StyledResultsPerPage,
  StyledResultsPerPageSelect,
  StyledClinTabsPagination,
  StyledCenterTabContent,
  StyledPageTabButton,
  StyledTabResultsPerPage,
  StyledTabResultsPerPageSelect,
  StyledTabResultsPerPageWrapper
} from './ClinPagination.styles'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'

interface IPaginationProps {
  /** The current page number ( 1 indexed )  */
  currentPage?: number
  /** The total number of pages  */
  totalPages: number
  /** Number of items per page */
  pageSize: number
  showLeftSpacer?: boolean
  //new design for pagination for tables in tabs
  showTabsPagination?: boolean
  showResultsPerPage?: boolean
  /** Callback when a page number is clicked  */
  handlePageSelected?: (page: number) => void
  /** Callback when results per page is selected */
  handlePageSizeChange?: (pageSize: number) => void
}

export const ClinPagination: FunctionComponent<IPaginationProps> = ({
  currentPage = 1,
  totalPages,
  pageSize,
  showLeftSpacer,
  showTabsPagination,
  showResultsPerPage = true,
  handlePageSelected,
  handlePageSizeChange
}) => {
  const { t } = useTranslation()

  const pages = calcPages(totalPages)

  return showTabsPagination ? (
    <StyledClinTabsPagination role="navigation" aria-label="pagination">
      {/* spacer to ensure main UI is centered */}
      {!showLeftSpacer && (
        <Hidden xs sm md lg>
          <StyledLeftSpacer />
        </Hidden>
      )}
      <StyledCenterTabContent>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            marginRight: '56px'
          }}
        >
          <StyledPageTabButton
            variant="linkButton"
            disabled={currentPage === 1}
            onClick={() => handlePageSelected?.(currentPage - 1)}
          >
            <ClinIcon
              iconSize={ClinTheme.fontSizes[3]}
              iconName={ClinIconPathName.ArrowLeft}
              style={{
                marginRight: ClinTheme.space[2]
              }}
            />
            {t('common:pagination.previous')}
          </StyledPageTabButton>
        </div>

        <StyledPagination>
          {/* Mobile */}
          <Visible xs>
            <ClinText as={'span'}>
              {t('common:pagination.page')} {currentPage}
              &nbsp;
              {t('common:pagination.of')} {pages.length}
            </ClinText>
          </Visible>
          {/* Tablet and up */}
          <Hidden xs>
            <ClinText
              as={'span'}
              marginRight={ClinTheme.space[3]}
              fontWeight={ClinTheme.fontWeights.medium}
              color={ClinTheme.colors.primary}
            >
              {t('common:pagination.page')}
            </ClinText>
            <ClinSelect
              color={ClinTheme.colors.primaryDark}
              width={71}
              lineHeight={1.88}
              padding="0px 24px 0px 16px"
              iconTop={4}
              value={currentPage}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                handlePageSelected?.(parseInt(event.currentTarget.value, 0))
              }
            >
              {pages.map((item) => (
                <option key={`option-${item.value}`} value={item.value}>
                  {item.value}
                </option>
              ))}
            </ClinSelect>
            <ClinText
              color={ClinTheme.colors.primaryDark}
              as={'span'}
              marginLeft={ClinTheme.space[3]}
            >
              {t('common:pagination.of')} {pages.length}
            </ClinText>
          </Hidden>
        </StyledPagination>

        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            marginLeft: '56px'
          }}
        >
          <StyledPageTabButton
            variant="linkButton"
            disabled={currentPage >= totalPages}
            onClick={() => {
              handlePageSelected?.(currentPage + 1)
            }}
          >
            {t('common:pagination.next')}
            <ClinIcon
              iconSize={ClinTheme.fontSizes[3]}
              iconName={ClinIconPathName.ArrowRight}
              style={{
                marginLeft: ClinTheme.space[2]
              }}
            />
          </StyledPageTabButton>
        </div>
      </StyledCenterTabContent>
      {showResultsPerPage && (
        <Hidden xs sm>
          <StyledTabResultsPerPageWrapper>
            <StyledTabResultsPerPage>
              {t('common:pagination.results_per_page')}:
              <StyledTabResultsPerPageSelect
                background={ClinTheme.colors.transparent}
                value={pageSize}
                width={
                  pageSize === 100
                    ? ClinTheme.space[6] * 2
                    : ClinTheme.space[4] * 3
                }
                variant={ClinSelectVariant.secondary}
                onChange={(event) =>
                  handlePageSizeChange?.(parseFloat(event.currentTarget.value))
                }
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </StyledTabResultsPerPageSelect>
            </StyledTabResultsPerPage>
          </StyledTabResultsPerPageWrapper>
        </Hidden>
      )}
    </StyledClinTabsPagination>
  ) : (
    <StyledClinPagination role="navigation" aria-label="pagination">
      {/* spacer to ensure main UI is centered */}
      {!showLeftSpacer && (
        <Hidden xs sm md lg>
          <StyledLeftSpacer />
        </Hidden>
      )}
      <StyledCenterContent>
        <StyledPageButton
          disabled={currentPage === 1}
          onClick={() => handlePageSelected?.(currentPage - 1)}
        >
          <ClinIcon
            iconSize={ClinTheme.fontSizes[3]}
            iconName={ClinIconPathName.ArrowLeft}
            style={{
              marginRight: ClinTheme.space[2],
              position: 'relative',
              top: `${ClinTheme.space[1]}px`
            }}
          />
          {t('common:pagination.previous')}
        </StyledPageButton>
        <StyledPagination>
          {/* Mobile */}
          <Visible xs>
            <ClinText as={'span'}>
              {t('common:pagination.page')} {currentPage}
              &nbsp;
              {t('common:pagination.of')} {pages.length}
            </ClinText>
          </Visible>
          {/* Tablet and up */}
          <Hidden xs>
            <ClinText
              as={'span'}
              marginRight={ClinTheme.space[3]}
              fontWeight={ClinTheme.fontWeights.medium}
              color={ClinTheme.colors.primary}
            >
              {t('common:pagination.page')}
            </ClinText>
            <ClinSelect
              width={90}
              value={currentPage}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                handlePageSelected?.(parseInt(event.currentTarget.value, 0))
              }
            >
              {pages.map((item) => (
                <option key={`option-${item.value}`} value={item.value}>
                  {item.value}
                </option>
              ))}
            </ClinSelect>
            <ClinText as={'span'} marginLeft={ClinTheme.space[3]}>
              {t('common:pagination.of')} {pages.length}
            </ClinText>
          </Hidden>
        </StyledPagination>
        <StyledPageButton
          disabled={currentPage >= totalPages}
          onClick={() => {
            handlePageSelected?.(currentPage + 1)
          }}
        >
          {t('common:pagination.next')}
          <ClinIcon
            iconSize={ClinTheme.fontSizes[3]}
            iconName={ClinIconPathName.ArrowRight}
            style={{
              marginLeft: ClinTheme.space[2],
              position: 'relative',
              top: `${ClinTheme.space[1]}px`
            }}
          />
        </StyledPageButton>
      </StyledCenterContent>
      {showResultsPerPage && (
        <Hidden xs sm>
          <StyledResultsPerPage>
            {t('common:pagination.results_per_page')}:
            <StyledResultsPerPageSelect
              value={pageSize}
              width={
                pageSize === 100
                  ? ClinTheme.space[6] * 2
                  : ClinTheme.space[4] * 3
              }
              variant={ClinSelectVariant.secondary}
              onChange={(event) =>
                handlePageSizeChange?.(parseFloat(event.currentTarget.value))
              }
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </StyledResultsPerPageSelect>
          </StyledResultsPerPage>
        </Hidden>
      )}
    </StyledClinPagination>
  )
}
