import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery } from '../../../utils/mediaQuery'
const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledAddedToBasketConfirmation = styled.div({
  padding: 0,
  [`.clin-announce`]: {
    marginBottom: ClinTheme.space[3]
  },
  [mediaQuery(mobileUpBreakpoint)]: {
    paddingBottom: ClinTheme.space[4]
  }
})

export const StyledHeader = styled.div({
  padding: `${ClinTheme.space[4]}px ${ClinTheme.space[4]}px`,
  color: ClinTheme.colors.primary,
  background: ClinTheme.colors.midGrey
})

export const StyledBody = styled.div({
  margin: ClinTheme.space[3]
})
