import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'

export interface ITextareaStyles {
  /** Disables the textarea */
  disabled?: boolean
  /** Sets field styles to error */
  hasError?: boolean
  /** Sets field styles to valid */
  isValid?: boolean
  /** Sets the height value of the textarea */
  height?: number | string
}

export const StyledLabel = styled.label<ITextareaStyles>({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column'
})

export const StyledTextarea = styled.textarea<ITextareaStyles>(
  {
    boxSizing: 'border-box',
    fontFamily: ClinTheme.fonts.body,
    fontSize: ClinTheme.fontSizes[1],
    lineHeight: ClinTheme.lineHeights.body,
    padding: ClinTheme.space[3],
    width: '100%',
    borderRadius: ClinTheme.radii[2],
    border: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`,

    ':focus': {
      outline: 'none'
    },
    '::placeholder': {
      color: ClinTheme.colors.grey
    },
    ':disabled': {
      cursor: 'not-allowed',
      color: ClinTheme.colors.grey
    }
  },
  ({ hasError, disabled, height = ClinTheme.space[7] * 2 }) => ({
    height,
    borderColor: hasError ? ClinTheme.colors.redInvalid : ClinTheme.colors.grey,
    color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.black,
    resize: 'none'
  })
)
