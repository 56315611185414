import React, { FunctionComponent, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { Bookmarks } from './Bookmarks'
import { useAugmentedBookmarks } from './Bookmarks.model'
import { IPagination } from '../../components/ClinPagination/ClinPagination.model'
import { useAppContext } from '../../context/app'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  cancelCreateBookmark,
  cancelDeleteBookmark,
  deleteBookmark
} from '../../services/ApiService'
import { BookmarkableItemType } from '../../types/BookmarkableItem'
import { BookmarkDto, BookmarkSearchDto } from '../../types/swaggerTypes'
import { noop } from '../../utils/noop'

const defaultPagination: IPagination = {
  take: 10,
  skip: 0,
  count: 0,
  total: 1
}

const defaultSearchParams: BookmarkSearchDto = {
  query: '*',
  filter: {
    itemTypes: ['Generic']
  },
  pagination: {
    take: 10,
    skip: 0
  },
  sorting: {
    sortBy: 'created',
    order: 'desc'
  }
}

function getItemTypeFromTabIndex(tabIndex: number): BookmarkableItemType {
  return tabIndex === 0
    ? BookmarkableItemType.Generic
    : BookmarkableItemType.SKU
}

export const BookmarksContainer: FunctionComponent = () => {
  const history = useHistory()
  const { dispatch, portfolioCountryCode, userRole } = useAppContext()
  const [bookmarks, setBookmarks] = useState<null | BookmarkDto[]>(null)
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  const [searchParams, setSearchParams] =
    useState<BookmarkSearchDto>(defaultSearchParams)
  const [bookmarksWithBrandsExpanded, setBookmarksWithBrandsExpanded] =
    useState<BookmarkDto[]>([])
  const [recentlyDeletedBookmarks, setRecentlyDeletedBookmarks] = useState<
    BookmarkDto[]
  >([])
  const [
    clearRecentlyDeleteBookmarksTimerId,
    setClearRecentlyDeleteBookmarksTimerId
  ] = useState<number>(-1)
  const [deletedBookmarks, setDeletedBookmarks] = useState<BookmarkDto[]>([])
  const [pagination, setPagination] = useState<IPagination>(defaultPagination)

  const itemType = getItemTypeFromTabIndex(selectedTabIndex)

  useAugmentedBookmarks(
    searchParams,
    itemType,
    selectedTabIndex,
    dispatch,
    setBookmarks,
    setPagination
  )

  useEffect(() => {
    return () => {
      cancelCreateBookmark().then(() => noop())
      cancelDeleteBookmark().then(() => noop())
      clearTimeout(clearRecentlyDeleteBookmarksTimerId)
    }
  }, [clearRecentlyDeleteBookmarksTimerId])

  const onPageSelected = (selectedPageIndex: number) => {
    const skip = (selectedPageIndex - 1) * pagination.take
    setSearchParams({
      ...searchParams,
      pagination: {
        ...searchParams.pagination,
        skip
      }
    })
    setPagination({
      ...pagination,
      skip
    })
  }

  const onPageSizeChange = (pageSize: number) => {
    const paginationUpdates = {
      take: pageSize,
      skip: 0
    }
    setPagination({
      ...pagination,
      ...paginationUpdates
    })
    setSearchParams({
      ...searchParams,
      pagination: {
        ...searchParams.pagination,
        ...paginationUpdates
      }
    })
  }

  const onTabSelected = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex)
    setPagination(defaultPagination)
    setSearchParams({
      ...searchParams,
      pagination: {
        ...searchParams.pagination,
        skip: 0
      },
      filter: {
        itemTypes: [getItemTypeFromTabIndex(tabIndex)]
      }
    })
  }

  const onToggleExpandBrands = (bookmark: BookmarkDto) => {
    if (bookmarksWithBrandsExpanded.includes(bookmark)) {
      setBookmarksWithBrandsExpanded(
        bookmarksWithBrandsExpanded.filter((b) => b !== bookmark)
      )
    } else {
      setBookmarksWithBrandsExpanded([...bookmarksWithBrandsExpanded, bookmark])
    }
  }

  const onClickDelete = (bookmark: BookmarkDto) => {
    setDeletedBookmarks([...deletedBookmarks, bookmark])
    setRecentlyDeletedBookmarks([...recentlyDeletedBookmarks, bookmark])
    setTimeout(() => {
      setRecentlyDeletedBookmarks(
        recentlyDeletedBookmarks.filter((b) => b !== bookmark)
      )
      setClearRecentlyDeleteBookmarksTimerId(-1)
    }, 1000)

    deleteBookmark(bookmark.bookmarkId)
      .then(() => {
        // Nothing to do (just tracking)
        analyticsServiceSingleton.trackEvent(
          AnalyticsEvent.BookmarkRemoveEvent,
          {
            bookmarkId: bookmark.bookmarkId
          }
        )
      })
      .catch(() => {
        setDeletedBookmarks(deletedBookmarks.filter((b) => b !== bookmark))
      })
  }

  const onClick = (bookmark: BookmarkDto) => {
    if (bookmark.itemType === 'Generic') {
      history.push(`/product/${bookmark.value.catalog.catalogDocumentId}`)
    } else {
      history.push(`/product/sku/${bookmark.value.sku.sku}`)
    }
  }

  return (
    <Bookmarks
      bookmarks={bookmarks}
      countryCode={portfolioCountryCode}
      userRole={userRole}
      selectedTabIndex={selectedTabIndex}
      itemType={itemType}
      pagination={pagination}
      bookmarksWithBrandsExpanded={bookmarksWithBrandsExpanded}
      recentlyDeletedBookmarks={recentlyDeletedBookmarks}
      deletedBookmarks={deletedBookmarks}
      onTabSelected={onTabSelected}
      onPageSizeChange={onPageSizeChange}
      onPageSelected={onPageSelected}
      onClickDelete={onClickDelete}
      onClick={onClick}
      onToggleExpandBrands={onToggleExpandBrands}
    />
  )
}
