import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'
import { StyledIconValueCell } from '../IconValueCell/IconValueCell.styles'

const SmallUpBreakpoint = ClinTheme.breakpoints[0]

export const StyledDrugPropertyGridItem = styled.div({
  marginBottom: ClinTheme.space[4],

  [`${StyledIconValueCell}`]: {
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none'
  },

  '.drug-property-value': {
    marginTop: ClinTheme.space[1],
    marginBottom: 0,
    paddingLeft: ClinTheme.space[5] + ClinTheme.space[2]
  },

  '.clin-icon-value-container': {
    width: '100%',
    flexWrap: 'wrap',
    minHeight: 'auto'
  },

  '.clin-icon-title': {
    flex: '1 0 100%'
  }
})

export const StyledDrugPropertyGrid = styled.div({
  [mediaQuery(SmallUpBreakpoint)]: {
    '.col': {
      [`${StyledDrugPropertyGridItem}:last-of-type`]: {
        marginBottom: 0
      }
    }
  }
})
