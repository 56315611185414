import React, { FunctionComponent, useEffect, useState } from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'

import { OpaOrderSummary } from './OpaOrderSummary'
import { OrderCancellationCodes } from '../../constants'
import { useAppContext } from '../../context/app'
import { OrderLineCancellationModal } from '../../features/OrderLineCancellationModal'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  AuthError,
  cancelGetOrderById,
  cancelGetProgramFromSearchIndexById,
  cancelGetReadOnlyOpaUrl,
  cancelRemoveOrder,
  cancelUpdateOrder,
  getOrderById,
  getProgramFromSearchIndexById,
  getReadOnlyOpaUrl,
  removeOrder
} from '../../services/ApiService'
import { useOrderLevelHolds } from '../../services/Holds/holds'
import { getHoldForType, HoldType } from '../../services/Holds/holds.constants'
import { IError } from '../../types'
import { findOrderCancellationLookupCode } from '../../types/IOrder'
import {
  DeleteOrderDto,
  OrderDto,
  ProgramCatalogDto
} from '../../types/swaggerTypes'
import { useErrorMessage } from '../../utils/useErrorMessage'
import { useOnMount } from '../../utils/useOnMount'
import {
  LocalStorageOPAKeys,
  isOrderCancellable
} from '../OpaCheckout/OpaCheckout.models'

interface IOpaOrderSummaryRouteParams {
  orderId: string
}

type LocationState = {
  endpoint?: string
  programId?: string
  from?: string
}

interface IOpaOrderSummaryProps
  extends RouteComponentProps<
    IOpaOrderSummaryRouteParams,
    StaticContext,
    LocationState
  > {}

export const OpaOrderSummaryContainer: FunctionComponent<
  IOpaOrderSummaryProps
> = ({ match, history }) => {
  const { orderId } = match.params
  const programId = history.location.state?.programId // || '2028' // for debug
  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(true)
  const [isProgramLoading, setIsProgramLoading] = useState<boolean>(true)
  const [isLoadingPatientAccessForm, setIsLoadingPatientAccessForm] =
    useState(true)
  const [order, setOrder] = useState<OrderDto>()
  const [program, setProgram] = useState<ProgramCatalogDto>()
  const [deliveryNote, setDeliveryNote] = useState<string>('')
  const [patientAccessFormUrl, setPatientAccessFormUrl] = useState<
    string | undefined
  >()
  const [isCancelOrderlineModalOpen, setIsCancelOrderlineModalOpen] =
    useState<boolean>(false)
  const [deleteOrderDescription, setDeleteOrderDescription] = useState<
    DeleteOrderDto | undefined
  >()
  const [cancellationSubmitting, setCancellationSubmitting] =
    useState<boolean>(false)
  const cancellationOptions =
    OrderCancellationCodes && Object.values(OrderCancellationCodes)
  const [cancellationHasError, setCancellationHasError] = useState<IError>()
  const [isLoadingCancelOrder, setIsLoadingCancelOrder] =
    useState<boolean>(false)
  const { supportContact, institute } = useAppContext()
  const orderLevelHolds = useOrderLevelHolds(undefined, order, supportContact)

  const handleOrderError = useErrorMessage('There was an error fetching order.')
  const handleAccessFormUrlError = useErrorMessage(
    `There was an error fetching patient access form URL.`
  )
  const handleProgramError = useErrorMessage(
    `There was an error fetching program.`
  )

  const getDeliveryNote = () => {
    const existingDeliveryNote = localStorage.getItem(
      LocalStorageOPAKeys.DeliveryNote
    )
    if (existingDeliveryNote) {
      setDeliveryNote(existingDeliveryNote)
    }
  }

  const deleteDeliveryNote = () => {
    if (localStorage.getItem(LocalStorageOPAKeys.DeliveryNote))
      localStorage.removeItem(LocalStorageOPAKeys.DeliveryNote)
  }

  useEffect(() => {
    window.addEventListener('beforeunload', deleteDeliveryNote)
    return () => {
      deleteDeliveryNote()
      window.removeEventListener('beforeunload', deleteDeliveryNote)
    }
  }, [])

  useEffect(() => {
    orderLevelHolds.push(getHoldForType(HoldType.CustomDelivery))
  }, [orderLevelHolds])

  useOnMount(() => {
    orderId &&
      getOrderById(orderId)
        .then((response) => {
          setOrder(response.data)
        })
        .catch((error) => {
          handleOrderError(error)
        })
        .finally(() => setIsOrderLoading(false))
    programId &&
      getProgramFromSearchIndexById(programId)
        .then((result) => {
          setProgram(result.data)
        })
        .catch((error) => handleProgramError(error))
        .finally(() => setIsProgramLoading(false))
    getDeliveryNote()
    return () => {
      cancelGetOrderById()
      cancelGetProgramFromSearchIndexById()
      cancelUpdateOrder()
      cancelRemoveOrder()
    }
  })

  useEffect(() => {
    if (order?.opaAccessToken && order?.orderNumber) {
      getReadOnlyOpaUrl(Number(order?.orderNumber), {
        opaAccessToken: order?.opaAccessToken
      })
        .then((response) => {
          if (response.data.endPoint) {
            setPatientAccessFormUrl(response.data.endPoint)
          }
        })
        .catch((error) => handleAccessFormUrlError(error))
        .finally(() => setIsLoadingPatientAccessForm(false))
    } else if (order) {
      setIsLoadingPatientAccessForm(false)
    }
    return () => {
      cancelGetReadOnlyOpaUrl()
    }
  }, [handleAccessFormUrlError, order])

  const handlePatientAccessForm = () => {
    const physicianId = order?.lines[0].physician?.physicianId
    const programId = history.location.state?.programId
    patientAccessFormUrl
      ? history.push(
          `/programs/access-programs/${programId}/patient-access-form/${physicianId}`,
          {
            endpoint: patientAccessFormUrl,
            from: window.location.pathname
          }
        )
      : history.push('/about/contact-us')
  }

  const handlePlaceOrderAgain = () => {
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.PlaceOrderMAAgain, {
      programId,
      orderId
    })
    history.push(`/programs/access-programs/${programId}`)
  }

  const handleViewDashboard = () => {
    history.push(`/orders`)
  }

  const handleCancelOrder = () => {
    const updateOrderLineDto: DeleteOrderDto = {
      cancelReason: '',
      cancelComments: ''
    }
    setDeleteOrderDescription(updateOrderLineDto)
    setIsCancelOrderlineModalOpen(true)
  }

  const handleCancellationOptionChange = (id: string) => {
    if (deleteOrderDescription) {
      const updateDeleteOrderDesc: DeleteOrderDto = {
        ...deleteOrderDescription,
        cancelReason: id ? findOrderCancellationLookupCode[id] : ''
      }
      setDeleteOrderDescription(updateDeleteOrderDesc)
    }
  }

  const handleCancellationTextChange = (comment: string) => {
    if (deleteOrderDescription) {
      const updateOrderLineDto: DeleteOrderDto = {
        ...deleteOrderDescription,
        cancelComments: comment
      }
      setDeleteOrderDescription(updateOrderLineDto)
    }
  }

  const handleSubmitCancellation = () => {
    if (order && deleteOrderDescription) {
      setCancellationSubmitting(true)
      setIsLoadingCancelOrder(true)
      removeOrder(orderId, deleteOrderDescription)
        .then((response) => {
          setIsCancelOrderlineModalOpen(false)
          analyticsServiceSingleton.trackEvent(AnalyticsEvent.CancelOrderMA, {
            programId,
            orderId,
            location: 'Ma Order Summary'
          })
          history.push('/orders')
        })
        .catch((error) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          setCancellationHasError({
            hasError: true,
            errorMessage: `There was an error when cancelling your item. ${error}`
          })
          analyticsServiceSingleton.trackError(
            error,
            JSON.parse(localStorage.getItem('current_user') || '{}')
          )
        })
        .finally(() => {
          setCancellationSubmitting(false)
          setIsLoadingCancelOrder(false)
        })
    }
  }

  const handleCancellationClose = () => {
    setIsCancelOrderlineModalOpen(false)
    setDeleteOrderDescription(undefined)
  }

  //Stores state of accordions on the page, Expands all before calling print, Collapses to previous state
  //Handles hiding of the wootric survey as well
  const handlePrint = () => {
    const accoridonPanels = document.querySelectorAll('.accordion-panel')
    const wootricModal = document.querySelector('.wootric-desktop-modal')
    const state: number[] = []

    /**If theres accordions on page expand and save state */
    if (accoridonPanels) {
      for (let index = 0; index < accoridonPanels.length; index++) {
        state[index] = accoridonPanels[index].hasAttribute('hidden') ? 1 : 0
      }

      accoridonPanels.forEach((panel) => {
        panel.removeAttribute('hidden')
      })
    }

    wootricModal?.setAttribute('hidden', 'hidden')
    /**Actual print */
    window.print()
    /**Collapse to previous state */
    if (accoridonPanels) {
      for (let index = 0; index < accoridonPanels.length; index++) {
        if (state[index])
          accoridonPanels[index].setAttribute('hidden', 'hidden')
      }
    }
    wootricModal?.removeAttribute('hidden')
  }

  return (
    <>
      <OrderLineCancellationModal
        isOpen={isCancelOrderlineModalOpen}
        selectedOption={
          deleteOrderDescription &&
          findOrderCancellationLookupCode[deleteOrderDescription?.cancelReason]
        }
        cancellationOptions={cancellationOptions}
        isSubmitting={cancellationSubmitting}
        handleCommentChange={handleCancellationTextChange}
        handleOptionChange={handleCancellationOptionChange}
        handleSubmit={handleSubmitCancellation}
        handleClose={handleCancellationClose}
        hasError={cancellationHasError && cancellationHasError.hasError}
        errorMessage={cancellationHasError && cancellationHasError.errorMessage}
      />
      <OpaOrderSummary
        isLoading={
          isOrderLoading ||
          isLoadingPatientAccessForm ||
          isProgramLoading ||
          isLoadingCancelOrder
        }
        isOrderCancellable={order && isOrderCancellable(order)}
        order={order}
        program={program}
        orderLevelHolds={orderLevelHolds}
        patientAccessFormUrl={patientAccessFormUrl}
        handlePatientAccessForm={handlePatientAccessForm}
        handleCancelOrder={handleCancelOrder}
        handlePlaceOrderAgain={handlePlaceOrderAgain}
        handleViewDashboard={handleViewDashboard}
        handlePrint={handlePrint}
        isMaOrder={false}
        deliveryNote={deliveryNote}
        institute={institute.data?.instituteName}
      />
    </>
  )
}
