import React from 'react'
import styled from 'styled-components'

export interface IPatientAccessFormStyles
  extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  /** The height of the iFrame (dynamic) */
  height: number
  width: string
  shouldHide: boolean
}

export const StyledIFrame = styled.iframe<IPatientAccessFormStyles>(
  {
    border: 'none',
    overflow: 'hidden',
    width: '100%',
    margin: '0 auto'
  },
  ({ height, width, shouldHide }: IPatientAccessFormStyles) => ({
    display: shouldHide ? 'none' : '',
    height,
    width
  })
)
