import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import { Col, Row } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

import {
  StyledAccountDetails,
  StyledPhoneExtension,
  StyledPhoneExtensionNumber,
  StyledRow,
  StyledRowFilled,
  StyledRowTitle
} from './AccountDetails.styles'
import {
  EditableFields,
  getLocalisationKeyForValue
} from './AccountDetailsCountainer'
import { ClinTheme } from '../../../ClinTheme'
import { ICrumb } from '../../../components/ClinBreadcrumb'
import { ClinButton } from '../../../components/ClinButton'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ClinSelect } from '../../../components/ClinSelect'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinTextInput } from '../../../components/ClinTextInput'
import {
  PhoneContactResponseType,
  PhoneResponseType,
  phoneTypesRecord,
  UserRole,
  countryCodes
} from '../../../constants'
import { TPhoneResponseType } from '../../../types/IContact'
import {
  ContactCardDto,
  InstituteDto,
  LovDto,
  UpdateContactDto,
  UserPhoneDto
} from '../../../types/swaggerTypes'
import {
  StyledLoadingContainer,
  StyledLoadingSpinner
} from '../../InstitutePages/InviteNewUser/InviteNewUser.styles'

import '../../../i18n/config'

import { getSquidExObjectForKeyAndValue } from '../../../utils/getSquidExObjectForKeyAndValue'
import { IRole } from '../../../types'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../../utils/Forms/getInputPlaceholder'
import {
  getInputValidation,
  ValidationType
} from '../../../utils/Forms/getInputValidation'

interface IAccountDetailsProps {
  /** Whether the submission API request is in progress */
  isSubmitting?: boolean
  /** Which field is in edit mode */
  fieldToEdit?: EditableFields
  /** Users current institute */
  institute: InstituteDto
  /** Additional user details to be provided */
  userDetails: ContactCardDto
  /** An array of specialism values */
  specialisms: LovDto[]
  /** An array of job type values */
  jobTitles: LovDto[]
  /** Breadcrumbs to display */
  crumbs?: ICrumb[]
  /** Back button text (different for aus ga users) */
  backButtonText?: string
  /** When change button clicked */
  handleFormSubmission: (data: UpdateContactDto) => void
  /** When change institute button is clicked */
  handleChangeInstitute(): void
  /** When requesting to edit a field */
  handleEditRequest: (field: EditableFields) => void
  /** When requesting to go back to the dashboard */
  handleRequestToExitEditing(): void
  /** When requesting to go back to the dashboard */
  handleRequestToGoBack(): void
}

interface FormErrors {
  preferredName?: string
  specialism?: string
  classification?: string
  licenseNumber?: string
  customerPhones?: {
    [index: number]: {
      countryCode?: string
      areaCode?: string
      phoneNumber?: string
      phoneExtension?: string
    }
  }
}

const AccountDetailsSchema = object().shape({
  licenseNumber: string().when('classification', {
    is: (classification: string) =>
      classification &&
      (classification.toLowerCase() === 'pharmacist' ||
        classification.toLowerCase() === 'physician'),
    then: string().required(getInputValidation(ValidationType.RequiredField))
  })
  // Add other field validations as needed
})

export const AccountDetails: FunctionComponent<IAccountDetailsProps> = ({
  isSubmitting,
  fieldToEdit,
  institute,
  userDetails,
  specialisms,
  jobTitles,
  crumbs,
  backButtonText,
  handleFormSubmission,
  handleChangeInstitute,
  handleEditRequest,
  handleRequestToExitEditing,
  handleRequestToGoBack
}) => {
  const { t } = useTranslation()
  const [formErrors] = useState<FormErrors>({})
  const [showLicenceNumber, setShowLicenseNumber] = useState<boolean>()

  // Form setup
  const formMethods = useForm<ContactCardDto>({
    defaultValues: userDetails,
    resolver: yupResolver(AccountDetailsSchema)
  })

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    clearErrors,
    formState: { errors }
  } = formMethods

  // Extract watched values
  const watchClassification = watch('classification')
  const watchCustomerPhones = watch('customerPhones')

  // Helper function to prepare phone data for submission
  const preparePhoneData = (phone: any) => ({
    ...phone,
    phoneId: phone.phoneId.toString()
  })

  // Helper function to prepare form data for submission
  const prepareFormData = (values: ContactCardDto): UpdateContactDto => ({
    ...values,
    phones: values.customerPhones.map(preparePhoneData)
  })

  const onSubmit = (values: ContactCardDto) => {
    const updateValues = prepareFormData(values)
    handleFormSubmission(updateValues)
  }

  const getPhoneError = (
    index: number,
    field: 'countryCode' | 'areaCode' | 'phoneNumber' | 'phoneExtension'
  ) => {
    return formErrors.customerPhones?.[index]?.[field]
  }

  // Get user role
  const userRole = userDetails.clientRelationships.find(
    (item) => item.instituteId === institute.instituteId
  )

  const role = getSquidExObjectForKeyAndValue(
    'key',
    userRole?.roleType as UserRole,
    t('user_roles:roles', {
      returnObjects: true
    })
  ) as IRole

  // Layout helpers
  const fullWidthColInput = fieldToEdit === undefined ? 9 : 12

  // Effects
  useEffect(() => {
    handleClassificationChange()
  }, [watchClassification])

  useEffect(() => {
    reset(userDetails)
  }, [reset, userDetails])

  // Extracted functions to reduce complexity
  const handleClassificationChange = () => {
    if (watchClassification) {
      const classification = watchClassification.toLowerCase().toString()
      if (classification === 'physician' || classification === 'pharmacist') {
        clearErrors('licenseNumber')
        setShowLicenseNumber(true)
      } else {
        setShowLicenseNumber(false)
      }
    }
  }

  // Render helper functions
  const renderHiddenFields = () => (
    <div style={{ display: 'none' }}>
      <ClinTextInput
        width="100%"
        {...register(`roles.0.roleName`)}
        id="role-name"
        value={userRole?.roleType}
      />
    </div>
  )

  const renderHeader = () => (
    <Row>
      <Col xs={fullWidthColInput}>
        <ClinText
          as="h1"
          variant={TypographyVariant.H2}
          fontWeight={ClinTheme.fontWeights.bold}
        >
          {fieldToEdit === undefined
            ? t('account_details:title')
            : t(getLocalisationKeyForValue(fieldToEdit))}
        </ClinText>
      </Col>
    </Row>
  )

  const renderEditButton = (field: EditableFields) => (
    <Col width="auto" style={{ textAlign: 'right' }}>
      <ClinButton onClick={() => handleEditRequest(field)} variant="linkButton">
        {field === EditableFields.password
          ? t('common:labels.change')
          : t('common:labels.edit')}
      </ClinButton>
    </Col>
  )

  const shouldShowRow = (targetField?: EditableFields) => {
    return fieldToEdit === undefined || fieldToEdit === targetField
      ? 'block'
      : 'none'
  }

  return (
    <ClinPageContentFrame crumbs={!fieldToEdit ? crumbs : undefined}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderHiddenFields()}
        <StyledAccountDetails
          className={`${fieldToEdit === undefined ? 'not-editing' : ''}`}
        >
          <Row>
            <Col xs={12} md={12} lg={11} xl={9}>
              {renderHeader()}

              <StyledRow
                style={{
                  display: shouldShowRow()
                }}
              >
                <Row align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.full_name')}:
                        </StyledRowTitle>
                      </Col>
                      <Col md={9}>
                        <ClinTextInput
                          width="100%"
                          name="name"
                          id="name"
                          readOnly={true}
                          className={
                            fieldToEdit === undefined ? 'inactive' : ''
                          }
                          value={userDetails.name}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </StyledRow>

              <StyledRow
                style={{
                  display: shouldShowRow()
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={12}>
                    <Row align="center">
                      <Col
                        md={3}
                        style={{
                          marginRight: '-6%'
                        }}
                      >
                        <StyledRowTitle as="div">
                          {t('common:labels.email')}:{' '}
                        </StyledRowTitle>
                      </Col>
                      <Col md={9}>
                        <ClinTextInput
                          id="email"
                          width="100%"
                          name="email"
                          readOnly={true}
                          className={
                            fieldToEdit === undefined ? 'inactive' : ''
                          }
                          value={userDetails.email}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </StyledRow>
              <StyledRow
                style={{
                  display:
                    fieldToEdit === undefined ||
                    fieldToEdit === EditableFields.preferredName
                      ? 'block'
                      : 'none'
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.preferred_name')}:{' '}
                        </StyledRowTitle>
                      </Col>
                      <Col md={8}>
                        <ClinTextInput
                          width="100%"
                          {...register('preferredName')}
                          id="preferred-name"
                          className={
                            fieldToEdit === undefined ? 'inactive' : ''
                          }
                          readOnly={
                            !(fieldToEdit === EditableFields.preferredName)
                          }
                        />
                        {formErrors.preferredName && (
                          <span>{formErrors.preferredName}</span>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  {renderEditButton(EditableFields.preferredName)}
                </Row>
              </StyledRow>
              <StyledRow
                style={{
                  display:
                    fieldToEdit === undefined ||
                    fieldToEdit === EditableFields.password
                      ? 'block'
                      : 'none'
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.password')}:
                        </StyledRowTitle>
                      </Col>
                      <Col md={8}>
                        <ClinTextInput
                          id="password"
                          name="password"
                          readOnly={!(fieldToEdit === EditableFields.password)}
                          className={
                            fieldToEdit === undefined ? 'inactive' : ''
                          }
                          value="*******"
                        />
                      </Col>
                    </Row>
                  </Col>
                  {renderEditButton(EditableFields.password)}
                </Row>
              </StyledRow>
              <StyledRow
                style={{
                  display:
                    fieldToEdit === undefined ||
                    fieldToEdit === EditableFields.specialism
                      ? 'block'
                      : 'none'
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.specialism')}:
                        </StyledRowTitle>
                      </Col>
                      <Col md={8}>
                        <Controller
                          name="specialism"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <div
                              style={{
                                display:
                                  fieldToEdit === EditableFields.specialism
                                    ? 'block'
                                    : 'none'
                              }}
                            >
                              <ClinSelect
                                id="specialism"
                                width="100%"
                                value={value}
                                onChange={(
                                  changeValue: ChangeEvent<HTMLSelectElement>
                                ) => onChange(changeValue.currentTarget.value)}
                                hasError={!!formErrors.specialism}
                                prompt={formErrors.specialism}
                              >
                                <option value="" disabled={true}>
                                  {getInputPlaceholder(
                                    PlaceholderType.SelectInput
                                  )}
                                </option>
                                {specialisms
                                  .sort((a, b) =>
                                    a.lovValue && b.lovValue
                                      ? a.lovValue.localeCompare(b.lovValue)
                                      : 0
                                  )
                                  .map((specialism: LovDto, index) => {
                                    return (
                                      <option
                                        key={`specialism${index}`}
                                        value={specialism.lovCode}
                                      >
                                        {specialism.lovValue}
                                      </option>
                                    )
                                  })}
                              </ClinSelect>
                            </div>
                          )}
                        />
                        {!fieldToEdit && (
                          <ClinTextInput
                            id="specialism"
                            width="100%"
                            name="specialism"
                            readOnly={true}
                            value={userDetails.specialism}
                            className="inactive"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  {renderEditButton(EditableFields.specialism)}
                </Row>
              </StyledRow>
              <StyledRow
                style={{
                  display:
                    fieldToEdit === undefined ||
                    fieldToEdit === EditableFields.classification
                      ? 'block'
                      : 'none'
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.job_type')}:
                        </StyledRowTitle>
                      </Col>
                      <Col md={8}>
                        <Controller
                          name="classification"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <div
                              style={{
                                boxSizing: 'border-box',
                                width: '100%',
                                display:
                                  fieldToEdit === EditableFields.classification
                                    ? 'block'
                                    : 'none'
                              }}
                            >
                              <ClinSelect
                                id="classification"
                                width="100%"
                                value={value}
                                onChange={(
                                  changeValue: ChangeEvent<HTMLSelectElement>
                                ) => onChange(changeValue.currentTarget.value)}
                                hasError={!!formErrors.classification}
                                prompt={formErrors.classification}
                              >
                                <option value="" disabled={true}>
                                  {getInputPlaceholder(
                                    PlaceholderType.SelectInput
                                  )}
                                </option>
                                {jobTitles.map((jobType: LovDto, index) => {
                                  return (
                                    <option
                                      key={`classification-${index}`}
                                      value={jobType.lovCode}
                                    >
                                      {jobType.lovValue}
                                    </option>
                                  )
                                })}
                              </ClinSelect>
                            </div>
                          )}
                        />
                        {!fieldToEdit && (
                          <ClinTextInput
                            id="classification"
                            name="classification"
                            width="100%"
                            readOnly={true}
                            className="inactive"
                            value={
                              userDetails.classification === 'SITE CO_ORDINATOR'
                                ? 'SITE CO-ORDINATOR'
                                : userDetails.classification
                            }
                          />
                        )}
                      </Col>
                    </Row>

                    {showLicenceNumber && (
                      <>
                        <ClinSpacer height={ClinTheme.space[6]} />
                        <Row>
                          <Col md={3}>
                            <StyledRowTitle as="div">
                              {t('common:labels.license_number')}:
                            </StyledRowTitle>
                          </Col>
                          <Col md={8}>
                            <ClinTextInput
                              id="license-number"
                              {...register('licenseNumber')}
                              width="100%"
                              readOnly={
                                !(fieldToEdit === EditableFields.classification)
                              }
                              className={
                                fieldToEdit === undefined ? 'inactive' : ''
                              }
                              hasError={!!errors.licenseNumber}
                              prompt={errors.licenseNumber?.message}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                  {renderEditButton(EditableFields.classification)}
                </Row>
              </StyledRow>
              <StyledRow
                style={{
                  display: shouldShowRow()
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.hospital_or_institute')}:
                        </StyledRowTitle>
                      </Col>
                      <Col md={8}>
                        <ClinText
                          as="div"
                          fontWeight={ClinTheme.fontWeights.bold}
                        >{`${institute.instituteName} (current)`}</ClinText>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3} md={2} style={{ textAlign: 'right' }}>
                    <ClinButton
                      onClick={() => handleChangeInstitute()}
                      variant="linkButton"
                    >
                      {t('common:labels.switch')}
                    </ClinButton>
                  </Col>
                </Row>
              </StyledRow>
              <StyledRowFilled
                style={{
                  display: shouldShowRow()
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={9}>
                    <Row align="center">
                      <Col offset={{ xs: 0, lg: 1 }} md={4}>
                        <StyledRowTitle as="div">
                          {t('common:labels.permissions')}:
                        </StyledRowTitle>
                      </Col>
                      <Col xs={7} md={5}>
                        <ClinText
                          as="div"
                          fontWeight={ClinTheme.fontWeights.bold}
                        >
                          {role?.contact_type && role.contact_type}
                        </ClinText>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </StyledRowFilled>
              <StyledRowFilled
                className="phone-fields"
                style={{
                  display:
                    fieldToEdit === undefined ||
                    fieldToEdit === EditableFields.phoneNumber
                      ? 'block'
                      : 'none'
                }}
              >
                <Row justify="between" align="center">
                  {!fieldToEdit && (
                    <Col xs={9}>
                      <Row align="center">
                        <Col offset={{ xs: 0, lg: 1 }} md={4}>
                          <StyledRowTitle as="div">
                            {t('common:labels.phone_number', {
                              count: watchCustomerPhones.length
                            })}
                            :
                          </StyledRowTitle>
                        </Col>
                        <Col md={7}>
                          {watchCustomerPhones.map(
                            (item: UserPhoneDto, index) => {
                              return (
                                <Row key={`phone-number-${index}`}>
                                  <Col>
                                    <ClinText
                                      as="p"
                                      display={'inline-block'}
                                      fontWeight={ClinTheme.fontWeights.bold}
                                      fontSize={ClinTheme.fontSizes[3]}
                                    >
                                      {`+${item.countryCode} ${item.areaCode} ${item.phoneNumber}`}
                                    </ClinText>
                                    {item.phoneExtension && (
                                      <>
                                        <StyledPhoneExtension>
                                          Ext:
                                        </StyledPhoneExtension>
                                        <StyledPhoneExtensionNumber>
                                          {item.phoneExtension}
                                        </StyledPhoneExtensionNumber>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              )
                            }
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col
                    style={{
                      display:
                        fieldToEdit === EditableFields.phoneNumber &&
                        userDetails.customerPhones.length
                          ? 'block'
                          : 'none'
                    }}
                  >
                    {watchCustomerPhones.length !== 0 &&
                      watchCustomerPhones.map((item: UserPhoneDto, index) => {
                        const responseType =
                          watch(`customerPhones.${index}.countryCode`) === '' &&
                          watch(`customerPhones.${index}.areaCode`) === '' &&
                          watch(`customerPhones.${index}.phoneNumber`) === ''
                            ? PhoneContactResponseType.DELETE
                            : PhoneContactResponseType.UPDATE

                        return (
                          <Row key={`phone-type-${index}`}>
                            <Col xs={12} lg={3}>
                              <div style={{ display: 'none' }}>
                                <ClinTextInput
                                  id="phone-id"
                                  {...register(
                                    `customerPhones.${index}.phoneId`
                                  )}
                                />
                                <ClinTextInput
                                  id="phone-type"
                                  {...register(
                                    `customerPhones.${index}.phoneType`
                                  )}
                                  value={item.phoneType}
                                />
                                <ClinTextInput
                                  id="request-type"
                                  {...register(
                                    `customerPhones.${index}.requestType`
                                  )}
                                  value={responseType}
                                />
                              </div>
                              <Controller
                                name={`customerPhones.${index}.countryCode`}
                                control={control}
                                render={({ field: { onChange } }) => {
                                  return (
                                    <ClinSelect
                                      id="country-code"
                                      label={t('common:labels.country_code')}
                                      width="100%"
                                      defaultValue={item.countryCode}
                                      onChange={(
                                        changeValue: ChangeEvent<HTMLSelectElement>
                                      ) =>
                                        onChange(
                                          changeValue.currentTarget.value
                                        )
                                      }
                                      hasError={
                                        !!getPhoneError(index, 'countryCode')
                                      }
                                      prompt={getPhoneError(
                                        index,
                                        'countryCode'
                                      )}
                                    >
                                      <option value="">
                                        {t('common:labels.select_placeholder')}
                                      </option>
                                      <option value="44">+44</option>
                                      {countryCodes.map(
                                        (code: string, index: number) => {
                                          return (
                                            <option
                                              key={`code-${index}`}
                                              value={code}
                                            >
                                              {`+${code}`}
                                            </option>
                                          )
                                        }
                                      )}
                                    </ClinSelect>
                                  )
                                }}
                              />
                            </Col>
                            <Col xs={12} lg={2}>
                              <ClinTextInput
                                id="area-code"
                                {...register(
                                  `customerPhones.${index}.areaCode`
                                )}
                                label={t('common:labels.area_code')}
                                type="tel"
                                width="100%"
                                hasError={!!getPhoneError(index, 'areaCode')}
                                readOnly={
                                  !(fieldToEdit === EditableFields.phoneNumber)
                                }
                                prompt={getPhoneError(index, 'areaCode')}
                              />
                            </Col>
                            <Col xs={12} lg={4}>
                              <ClinTextInput
                                id="phone-number"
                                {...register(
                                  `customerPhones.${index}.phoneNumber`
                                )}
                                label={t(
                                  `${
                                    phoneTypesRecord[
                                      item.phoneType as TPhoneResponseType
                                    ].translationKey
                                  }`
                                )}
                                width="100%"
                                type="tel"
                                readOnly={
                                  !(fieldToEdit === EditableFields.phoneNumber)
                                }
                                hasError={!!getPhoneError(index, 'phoneNumber')}
                                prompt={getPhoneError(index, 'phoneNumber')}
                              />
                            </Col>
                            <Col xs={12} lg={2}>
                              {item.phoneType !== PhoneResponseType.MOBILE && (
                                <ClinTextInput
                                  id="extension"
                                  {...register(
                                    `customerPhones.${index}.phoneExtension`
                                  )}
                                  label={t('account_details:extension')}
                                  type="tel"
                                  width="100%"
                                  hasError={
                                    !!getPhoneError(index, 'phoneExtension')
                                  }
                                  readOnly={
                                    !(
                                      fieldToEdit === EditableFields.phoneNumber
                                    )
                                  }
                                  prompt={getPhoneError(
                                    index,
                                    'phoneExtension'
                                  )}
                                />
                              )}
                            </Col>
                          </Row>
                        )
                      })}
                  </Col>

                  <Col
                    style={{
                      display:
                        fieldToEdit === EditableFields.phoneNumber &&
                        userDetails.customerPhones.length === 0
                          ? 'block'
                          : 'none'
                    }}
                  >
                    {userDetails.customerPhones.length === 0 && (
                      <Row key={`phone-type-0`}>
                        <Col xs={12} lg={3}>
                          <div style={{ display: 'none' }}>
                            <ClinTextInput
                              id="phone-type"
                              {...register(`customerPhones.0.phoneType`)}
                              value={phoneTypesRecord['GEN'].type}
                            />
                            <ClinTextInput
                              id="request-type"
                              {...register(`customerPhones.0.requestType`)}
                              value={PhoneContactResponseType.CREATE}
                            />
                          </div>
                          <Controller
                            name={`customerPhones.0.countryCode`}
                            control={control}
                            defaultValue={'44'}
                            render={({ field: { onChange, value } }) => {
                              !value &&
                                setValue('customerPhones.0.countryCode', '44', {
                                  shouldDirty: true
                                })
                              return (
                                <ClinSelect
                                  id="country-code"
                                  label={t('common:labels.country_code')}
                                  width="100%"
                                  defaultValue={value}
                                  onChange={(
                                    changeValue: ChangeEvent<HTMLSelectElement>
                                  ) =>
                                    onChange(changeValue.currentTarget.value)
                                  }
                                  hasError={!!getPhoneError(0, 'countryCode')}
                                  prompt={getPhoneError(0, 'countryCode')}
                                >
                                  <option value="">
                                    {t('common:labels.select_placeholder')}
                                  </option>
                                  <option value="44">+44</option>
                                  {countryCodes.map(
                                    (code: string, index: number) => {
                                      return (
                                        <option
                                          key={`code-${index}`}
                                          value={code}
                                        >
                                          {`+${code}`}
                                        </option>
                                      )
                                    }
                                  )}
                                </ClinSelect>
                              )
                            }}
                          />
                        </Col>
                        <Col xs={12} lg={3}>
                          <ClinTextInput
                            id="area-code"
                            {...register(`customerPhones.0.areaCode`)}
                            label={t('common:labels.area_code')}
                            type="tel"
                            width="100%"
                            hasError={!!getPhoneError(0, 'areaCode')}
                            prompt={getPhoneError(0, 'areaCode')}
                            readOnly={
                              !(fieldToEdit === EditableFields.phoneNumber)
                            }
                          />
                        </Col>
                        <Col xs={12} lg={6}>
                          <ClinTextInput
                            id="phone-number"
                            {...register(`customerPhones.0.phoneNumber`)}
                            label={t('common:labels.phone')}
                            width="100%"
                            type="tel"
                            readOnly={
                              !(fieldToEdit === EditableFields.phoneNumber)
                            }
                            hasError={!!getPhoneError(0, 'phoneNumber')}
                            prompt={getPhoneError(0, 'phoneNumber')}
                          />
                        </Col>
                        <Col xs={12} lg={3}>
                          <ClinTextInput
                            id="extension"
                            {...register(`customerPhones.0.phoneExtension`)}
                            label={t('account_details:extension')}
                            type="tel"
                            width="100%"
                            hasError={!!getPhoneError(0, 'phoneExtension')}
                            prompt={getPhoneError(0, 'phoneExtension')}
                            readOnly={
                              !(fieldToEdit === EditableFields.phoneNumber)
                            }
                          />
                        </Col>
                        <ClinSpacer height={ClinTheme.space[4]} />
                      </Row>
                    )}
                  </Col>

                  {fieldToEdit !== EditableFields.phoneNumber && (
                    <Col xs={2} style={{ textAlign: 'right' }}>
                      <ClinButton
                        onClick={() =>
                          handleEditRequest(EditableFields.phoneNumber)
                        }
                        variant="linkButton"
                      >
                        {t('common:labels.edit')}
                      </ClinButton>
                    </Col>
                  )}
                </Row>
              </StyledRowFilled>

              <ClinSpacer height={ClinTheme.space[5]} />

              {fieldToEdit === undefined && (
                <ClinButton
                  variant="primary"
                  onClick={() => handleRequestToGoBack()}
                >
                  {backButtonText}
                </ClinButton>
              )}

              {fieldToEdit !== undefined && (
                <Row justify="end">
                  <Col width="auto">
                    <ClinGroup
                      justifyContent="space-between"
                      space={ClinTheme.space[3]}
                    >
                      <ClinButton
                        onClick={() => {
                          // TODO: Clean up this mess!
                          fieldToEdit === 'Phone Numbers' &&
                            setValue('customerPhones.0.countryCode', '')
                          handleRequestToExitEditing()
                          reset()
                        }}
                      >
                        {t('common:labels.cancel')}
                      </ClinButton>
                      <StyledLoadingContainer>
                        <ClinButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="primary"
                        >
                          {t('common:labels.save')}
                        </ClinButton>
                        {isSubmitting && (
                          <StyledLoadingSpinner>
                            <ClinSpinner />
                          </StyledLoadingSpinner>
                        )}
                      </StyledLoadingContainer>
                    </ClinGroup>
                  </Col>
                </Row>
              )}

              <ClinSpacer height={ClinTheme.space[8]} />
            </Col>
          </Row>
        </StyledAccountDetails>
      </form>
    </ClinPageContentFrame>
  )
}
