import { TFunction, t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-grid-system'
import { Trans } from 'react-i18next'

import {
  StyledPersonalDetails,
  StyledPersonalDetailsRow,
  StyledEnrolledIcon,
  StyledEnrolledPhysicians,
  StyledEnrolledPhysician,
  StyledEnrolledPhysicianIcon,
  StyledEnrolledPhysicianText,
  StyleAdditionalPhysicians,
  StyleAdditionalPhysician,
  StyleAdditionalPhysicianText,
  StyleAdditionalPhysicianAction,
  StyledContactUsAction,
  StyleAdditionalPhysicianTitle,
  StyleAdditionalPhysicianBadge
} from './EnrolUserOnToProgram.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinCheckbox } from '../../components/ClinCheckbox'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../components/ClinSpacer'
import { StepState } from '../../components/ClinStepper/ClinStepper'
import { ClinTabHeader } from '../../components/ClinTabHeader'
import { ClinTabs } from '../../components/ClinTabs'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { PhysicianAutoSuggest } from '../../features/Search/PhysicianAutoSuggest'
import { PhysicianSearch } from '../../features/Search/PhysicianSearch'
import { useGetEnrolledPhysicians } from '../../hooks/useGetEnrolledPhysicians/useGetEnrolledPhysicians'
import { IIndexable } from '../../types'
import {
  PhysiciansSummaryDto,
  NewPhysicianEnrollDto,
  UserDto,
  InstituteDto,
  PhysiciansEnrolledSummaryDto,
  ProgramCatalogDto
} from '../../types/swaggerTypes'
import { isPhysicianUnique } from '../../utils/isPhysicianUnique'
import { EnrolPhysicianDetailsForm } from '../EnrolPysicianOnToProgram/EnrolPhysicianDetailsForm'

export interface IPhysiciansToEnrol {
  physiciansLoading: boolean
  physiciansError: boolean
  physicians: PhysiciansEnrolledSummaryDto[] | undefined
  selectedPhysicians: PhysiciansSummaryDto[] | undefined
  savedPhysicians: NewPhysicianEnrollDto[] | undefined
  handleOnSelect: (summary: PhysiciansSummaryDto) => void
  handlePhysicianRemoval: (physicianId: number) => void
  handleSavedPhysicianDetails: (physician: NewPhysicianEnrollDto) => void
  handleSavedPhysicianRemoval: (email: string) => void
}

export const usePhysiciansToEnrol = (
  programId: string,
  isStatusReady: boolean
): IPhysiciansToEnrol => {
  const [selectedPhysicians, setSelectedPhysicians] =
    useState<PhysiciansSummaryDto[]>()
  const [savedPhysicians, setSavedPhysicians] =
    useState<NewPhysicianEnrollDto[]>()

  const { physicians, physiciansError, physiciansLoading } =
    useGetEnrolledPhysicians(programId, isStatusReady)

  const handleOnSelect = (summary: PhysiciansSummaryDto): void => {
    if (
      summary.physicianEmail &&
      isPhysicianUnique(
        savedPhysicians,
        selectedPhysicians,
        summary.physicianEmail
      )
    ) {
      setSelectedPhysicians([...(selectedPhysicians || []), summary])
    }
  }

  const handleSavedPhysicianDetails = (
    physician: NewPhysicianEnrollDto
  ): void => {
    if (
      physician.emailAddress &&
      isPhysicianUnique(
        savedPhysicians,
        selectedPhysicians,
        physician.emailAddress
      )
    ) {
      setSavedPhysicians([...(savedPhysicians || []), physician])
    }
  }

  const handlePhysicianRemoval = (physicianId: number): void => {
    setSelectedPhysicians(
      selectedPhysicians?.filter(
        (physician: PhysiciansSummaryDto) =>
          physician.physicianId !== physicianId
      )
    )
  }
  const handleSavedPhysicianRemoval = (email: string): void => {
    setSavedPhysicians(
      savedPhysicians?.filter(
        (physician: NewPhysicianEnrollDto) => physician.emailAddress !== email
      )
    )
  }

  return {
    physiciansLoading,
    physiciansError,
    physicians,
    selectedPhysicians,
    savedPhysicians,
    handleOnSelect,
    handlePhysicianRemoval,
    handleSavedPhysicianDetails,
    handleSavedPhysicianRemoval
  }
}

export enum EnrolUserOnToProgramStep {
  STEP1,
  STEP2,
  STEP3
}

export const EnrolUserOnToProgramSteps: IIndexable = {
  [EnrolUserOnToProgramStep.STEP1]: [
    StepState.Active,
    StepState.InComplete,
    StepState.InComplete
  ],
  [EnrolUserOnToProgramStep.STEP2]: [
    StepState.Complete,
    StepState.Active,
    StepState.InComplete
  ],
  [EnrolUserOnToProgramStep.STEP3]: [
    StepState.Complete,
    StepState.Complete,
    StepState.Active
  ]
}

export const getViewForStepOne = (
  /** Translate function */
  tFunc: TFunction,
  /** Call back to go to the contact us page */
  handleGoToContactUs: () => void,
  /** The current users details */
  userDetails?: UserDto,
  /** The users associated institute */
  institute?: InstituteDto
): React.ReactNode => {
  return (
    <>
      <ClinText
        color={ClinTheme.colors.primary}
        marginBottom={ClinTheme.space[6]}
        variant={TypographyVariant.H4}
        fontWeight={ClinTheme.fontWeights.bold}
      >
        {tFunc('enrol_user_on_to_program:enrol_user_step_1.title')}
      </ClinText>
      <ClinText
        color={ClinTheme.colors.primary}
        marginBottom={ClinTheme.space[3]}
        variant={TypographyVariant.H4}
      >
        {tFunc('enrol_user_on_to_program:enrol_user_step_1.form_title')}
      </ClinText>
      <Row className="step-one">
        <Col sm={12} md={10} lg={7}>
          <StyledPersonalDetails>
            <StyledPersonalDetailsRow>
              <Row align="center" gutterWidth={0}>
                <Col md={3}>
                  <ClinText fontSize={ClinTheme.fontSizes[3]}>
                    {tFunc('common:labels.full_name')}:
                  </ClinText>
                </Col>
                <Col md={9}>
                  <ClinText
                    className="full-name"
                    fontSize={ClinTheme.fontSizes[3]}
                    fontWeight={ClinTheme.fontWeights.bold}
                    wordBreak="break-all"
                  >
                    {userDetails?.contactCard.name}
                  </ClinText>
                </Col>
              </Row>
            </StyledPersonalDetailsRow>
            <StyledPersonalDetailsRow>
              <Row align="center" gutterWidth={0}>
                <Col md={3}>
                  <ClinText fontSize={ClinTheme.fontSizes[3]}>
                    {tFunc('common:labels.email')}:
                  </ClinText>
                </Col>
                <Col md={9}>
                  <ClinText
                    className="email"
                    fontSize={ClinTheme.fontSizes[3]}
                    fontWeight={ClinTheme.fontWeights.bold}
                    wordBreak="break-all"
                  >
                    {userDetails?.contactCard.email}
                  </ClinText>
                </Col>
              </Row>
            </StyledPersonalDetailsRow>
            <StyledPersonalDetailsRow>
              <Row align="center" gutterWidth={0}>
                <Col md={3}>
                  <ClinText fontSize={ClinTheme.fontSizes[3]}>
                    {tFunc('common:labels.hospital_or_institute')}:
                  </ClinText>
                </Col>
                <Col md={9}>
                  <ClinText
                    className="institute-name"
                    fontSize={ClinTheme.fontSizes[3]}
                    fontWeight={ClinTheme.fontWeights.bold}
                    wordBreak="break-all"
                  >
                    {institute?.instituteName}
                  </ClinText>
                </Col>
              </Row>
            </StyledPersonalDetailsRow>
          </StyledPersonalDetails>

          <ClinText fontSize={ClinTheme.fontSizes[2]}>
            <Trans
              i18nKey="enrol_user_on_to_program:enrol_user_step_1.form_sub"
              components={[
                <StyledContactUsAction
                  key="enrol-form"
                  onClick={() => handleGoToContactUs()}
                />
              ]}
            />
          </ClinText>
        </Col>
      </Row>
    </>
  )
}

export const getViewForStepTwo = (
  /** Translate function */
  tFunc: TFunction,
  /** Index of the selected tab */
  selectedTabIndex: number,
  /** Enrolled physicians */
  physicians: PhysiciansEnrolledSummaryDto[] | undefined,
  /** Disable the ability to skip */
  disableSkip: boolean,
  /** Called when the user selects a tab */
  handleTabSelected: (selectedTabIndex: number) => void,
  /** Call back to broadcast onClick */
  handleOnSelect: (suggestion: PhysiciansSummaryDto) => void,
  /** Call back request to add a new physician */
  handleRequestToAddPhysician: () => void,
  /** Call back request to remove a selected physician */
  handlePhysicianRemoval: (physicianId: number) => void,
  /** Save physician details */
  handleSavedPhysicianDetails: (physician: NewPhysicianEnrollDto) => void,
  /** Call back to remove a selected saved physician */
  handleSavedPhysicianRemoval: (email: string) => void,
  /** Handle whether the form is edited or not */
  handleIsFormEdited: (isEdited: boolean) => void,
  /** Call back to go to the next step */
  handleGoToNextStep: () => void,
  /** Selected physicians */
  selectedPhysicians?: PhysiciansSummaryDto[],
  /** Saved physicians */
  savedPhysicians?: NewPhysicianEnrollDto[],
  /** Program */
  program?: ProgramCatalogDto
): React.ReactNode => {
  return (
    <>
      <Row className="step-two">
        <Col sm={12} md={10} lg={8}>
          <ClinText
            as="h4"
            variant={TypographyVariant.H4}
            fontWeight={ClinTheme.fontWeights.medium}
          >
            {tFunc('enrol_user_on_to_program:enrol_user_step_2.title')}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[2]} />
        </Col>
      </Row>
      {physicians && physicians.length > 0 ? (
        <>
          <Row>
            <Col sm={12} md={10} lg={8}>
              <ClinText variant={TypographyVariant.LargeParagraph}>
                {tFunc(
                  'enrol_user_on_to_program:enrol_user_step_2.description_1'
                )}
              </ClinText>
              <ClinText variant={TypographyVariant.LargeParagraph}>
                {tFunc(
                  'enrol_user_on_to_program:enrol_user_step_2.description_2'
                )}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[4]} />
          <Row>
            <Col sm={12} md={10} lg={8}>
              <ClinGroup alignItems="center">
                <StyledEnrolledIcon>
                  <ClinIcon
                    iconName={ClinIconPathName.Tick}
                    iconSize={ClinTheme.space[4]}
                  />
                </StyledEnrolledIcon>
                <ClinText variant={TypographyVariant.LargeParagraph}>
                  {tFunc(
                    'enrol_user_on_to_program:enrol_user_step_2.list_title'
                  )}
                </ClinText>
              </ClinGroup>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[5]} />
          <Row>
            <Col sm={12} md={6}>
              <StyledEnrolledPhysicians>
                {physicians &&
                  physicians.map((physician, index) => (
                    <StyledEnrolledPhysician key={`physician-${index}`}>
                      <StyledEnrolledPhysicianIcon
                        style={{
                          visibility:
                            physician.physicianLinkedToUser.toUpperCase() ===
                            'Y'
                              ? 'visible'
                              : 'hidden'
                        }}
                      >
                        <ClinIcon
                          iconName={ClinIconPathName.Tick}
                          iconSize={ClinTheme.space[3]}
                        />
                      </StyledEnrolledPhysicianIcon>

                      <StyledEnrolledPhysicianText>
                        <ClinText
                          variant={TypographyVariant.LargeParagraph}
                          fontWeight={ClinTheme.fontWeights.medium}
                        >
                          {`${physician?.physicianTitle ?? ''} ${
                            physician?.physicianFirstName
                          } ${physician?.physicianLastName}`}
                        </ClinText>
                        <ClinText variant={TypographyVariant.LargeParagraph}>
                          {physician.physicianEmail}
                        </ClinText>
                      </StyledEnrolledPhysicianText>
                    </StyledEnrolledPhysician>
                  ))}
              </StyledEnrolledPhysicians>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[8]} />
        </>
      ) : (
        <Row>
          <Col sm={12} md={10} lg={8}>
            <ClinText variant={TypographyVariant.LargeParagraph}>
              <Trans
                i18nKey="enrol_user_on_to_program:enrol_user_step_2.no_physicians_warning"
                components={{ bold: <strong />, br: <br /> }}
              >
                <></>
              </Trans>
            </ClinText>
            <ClinSpacer height={ClinTheme.space[6]} />
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={12} md={10} lg={8}>
          <ClinText
            as="h4"
            variant={TypographyVariant.H4}
            fontWeight={ClinTheme.fontWeights.medium}
          >
            {tFunc('enrol_user_on_to_program:enrol_user_step_2.add_new_title')}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[2]} />
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {tFunc(
              'enrol_user_on_to_program:enrol_user_step_2.add_new_description'
            )}
          </ClinText>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col sm={12} md={10} lg={8}>
          <ClinGroup justifyContent="flex-end">
            <ClinButton
              className="skip-this-step"
              style={{ visibility: disableSkip ? 'hidden' : 'visible' }}
              onClick={() => handleGoToNextStep()}
              variant="linkButton"
            >
              {tFunc(
                'enrol_user_on_to_program:enrol_user_step_2.add_new_skip_button'
              )}
              <ClinIcon
                style={{ marginLeft: ClinTheme.space[2] }}
                iconName={ClinIconPathName.ArrowRight}
              />
            </ClinButton>
          </ClinGroup>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col sm={12} md={10} lg={8}>
          <ClinTabs
            activeItemIndex={selectedTabIndex}
            handleSelected={handleTabSelected}
          >
            <ClinTabHeader
              title={tFunc(
                'enrol_user_on_to_program:enrol_user_step_2.tab_1_title'
              )}
            />
            <ClinTabHeader
              title={tFunc(
                'enrol_user_on_to_program:enrol_user_step_2.tab_2_title'
              )}
            />
          </ClinTabs>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[5]} />
      {selectedTabIndex === 0 && (
        <Row>
          <Col sm={12} md={10} lg={8}>
            <ClinText marginBottom={ClinTheme.space[2]}>
              {tFunc('enrol_user_on_to_program:enrol_user_step_2.search_title')}
            </ClinText>
            <PhysicianAutoSuggest
              searchComponent={(props) => <PhysicianSearch {...props} />}
              handleOnSelect={handleOnSelect}
              handleRequestToAddPhysician={handleRequestToAddPhysician}
              searchNotEnrolled={true}
              programId={program?.projectId ? `${program.projectId}` : ''}
            />
          </Col>
        </Row>
      )}
      {selectedTabIndex === 1 && (
        <Row>
          <Col>
            <EnrolPhysicianDetailsForm
              handleSavedPhysicianDetails={handleSavedPhysicianDetails}
              handleIsFormEdited={handleIsFormEdited}
            />
          </Col>
        </Row>
      )}

      {selectedPhysicians && selectedPhysicians.length > 0 && (
        <Row>
          <Col sm={12} md={10} lg={8}>
            <ClinSpacer hasBorder height={0} />
            <ClinSpacer height={ClinTheme.space[4]} />
            <ClinText
              color={ClinTheme.colors.primary}
              fontSize={ClinTheme.fontSizes[3]}
              marginBottom={ClinTheme.space[4]}
            >
              {tFunc(
                'enrol_user_on_to_program:enrol_user_step_2.enrolled_title'
              )}
            </ClinText>
            <StyleAdditionalPhysicians>
              <StyleAdditionalPhysicianTitle>
                <ClinText
                  wordBreak="break-all"
                  variant={TypographyVariant.LargeParagraph}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  {t('enrol_user_on_to_program:existing_physicians_title')}
                </ClinText>
                {program?.program?.autoEnrolmentFlag === 'Yes' &&
                selectedPhysicians ? (
                  <StyleAdditionalPhysicianBadge
                    style={{ backgroundColor: '#00A2231A' }}
                  >
                    <ClinText
                      wordBreak="break-all"
                      variant={TypographyVariant.Paragraph}
                      fontWeight={ClinTheme.fontWeights.medium}
                      color={ClinTheme.colors.greenValid}
                    >
                      {t(
                        'enrol_user_on_to_program:additional_physician_badge_text_eligible'
                      )}
                    </ClinText>
                  </StyleAdditionalPhysicianBadge>
                ) : (
                  <StyleAdditionalPhysicianBadge
                    style={{ backgroundColor: '#EEEDFF' }}
                  >
                    <ClinText
                      wordBreak="break-all"
                      variant={TypographyVariant.Paragraph}
                      fontWeight={ClinTheme.fontWeights.medium}
                      color={ClinTheme.colors.primaryLight}
                    >
                      {t(
                        'enrol_user_on_to_program:additional_physician_badge_text_verification'
                      )}
                    </ClinText>
                  </StyleAdditionalPhysicianBadge>
                )}
              </StyleAdditionalPhysicianTitle>
              {selectedPhysicians ? (
                selectedPhysicians.map(
                  (physician: PhysiciansSummaryDto, index) => {
                    return (
                      <StyleAdditionalPhysician key={`physician-${index}`}>
                        <StyleAdditionalPhysicianText>
                          <ClinText
                            wordBreak="break-all"
                            variant={TypographyVariant.LargeParagraph}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {`${physician?.physicianTitle ?? ''} ${
                              physician?.physicianFirstName
                            } ${physician?.physicianLastName}`}{' '}
                          </ClinText>
                          <ClinText
                            wordBreak="break-all"
                            variant={TypographyVariant.LargeParagraph}
                          >
                            {physician.physicianEmail}
                          </ClinText>
                        </StyleAdditionalPhysicianText>
                        <StyleAdditionalPhysicianAction
                          onClick={() =>
                            handlePhysicianRemoval(physician.physicianId)
                          }
                        >
                          {tFunc('common:buttons.remove')}
                        </StyleAdditionalPhysicianAction>
                      </StyleAdditionalPhysician>
                    )
                  }
                )
              ) : (
                <p>No physician selected</p>
              )}
            </StyleAdditionalPhysicians>
          </Col>
        </Row>
      )}

      {savedPhysicians && savedPhysicians.length > 0 && (
        <Row>
          <Col sm={12} md={10} lg={8}>
            <StyleAdditionalPhysicians>
              <StyleAdditionalPhysicianTitle>
                <ClinText
                  wordBreak="break-all"
                  variant={TypographyVariant.LargeParagraph}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  {t('enrol_user_on_to_program:new_physicians_title')}
                </ClinText>
                <StyleAdditionalPhysicianBadge
                  style={{ backgroundColor: '#EEEDFF' }}
                >
                  <ClinText
                    wordBreak="break-all"
                    variant={TypographyVariant.Paragraph}
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.primaryLight}
                  >
                    {t(
                      'enrol_user_on_to_program:additional_physician_badge_text_verification'
                    )}
                  </ClinText>
                </StyleAdditionalPhysicianBadge>
              </StyleAdditionalPhysicianTitle>

              {savedPhysicians &&
                savedPhysicians.map(
                  (physician: NewPhysicianEnrollDto, index) => {
                    return (
                      <StyleAdditionalPhysician key={`physician-${index}`}>
                        <StyleAdditionalPhysicianText>
                          <ClinText
                            wordBreak="break-all"
                            variant={TypographyVariant.LargeParagraph}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {`${physician.title} ${physician.firstName} ${physician.lastName}`}
                          </ClinText>
                          <ClinText
                            wordBreak="break-all"
                            variant={TypographyVariant.LargeParagraph}
                          >
                            {physician.emailAddress}
                          </ClinText>
                        </StyleAdditionalPhysicianText>
                        <StyleAdditionalPhysicianAction
                          onClick={() =>
                            physician.emailAddress &&
                            handleSavedPhysicianRemoval(physician.emailAddress)
                          }
                        >
                          {tFunc('common:buttons.remove')}
                        </StyleAdditionalPhysicianAction>
                      </StyleAdditionalPhysician>
                    )
                  }
                )}
            </StyleAdditionalPhysicians>
          </Col>
        </Row>
      )}
    </>
  )
}

export const getViewForStepThree = (
  /** Translate function */
  tFunc: TFunction,
  /** Call back request to remove a selected physician */
  handlePhysicianRemoval: (physicianId: number) => void,
  /** Call back to remove a selected saved physician */
  handleSavedPhysicianRemoval: (email: string) => void,
  /** Call back handle to confirm request */
  handleConfirmationSelect: (confirmed: boolean) => void,
  /** Whether a user has confirmed whether they would like to associate with a physician or not **/
  associateConfirmation?: boolean,
  /** If the user is submitting their request */
  isSubmitting?: boolean,
  /** Selected physicians */
  selectedPhysicians?: PhysiciansSummaryDto[],
  /** Saved physicians */
  savedPhysicians?: NewPhysicianEnrollDto[],
  isAutoEnrolled?: boolean
): React.ReactNode => {
  return (
    <>
      {(!selectedPhysicians || selectedPhysicians?.length === 0) &&
      (!savedPhysicians || savedPhysicians.length === 0) ? (
        <>
          <ClinSpacer height={ClinTheme.space[4]} />
          {isAutoEnrolled ? (
            <Row className="skipped-to-step-three">
              <Col sm={12} md={10} lg={7}>
                <ClinText variant={TypographyVariant.LargeParagraph}>
                  {tFunc(
                    'enrol_user_on_to_program:enrol_user_step_3.conditional_message_copy'
                  )}
                </ClinText>
              </Col>
            </Row>
          ) : (
            <Row className="skipped-to-step-three">
              <Col sm={12} md={10} lg={7}>
                <ClinText variant={TypographyVariant.LargeParagraph}>
                  {tFunc(
                    'enrol_user_on_to_program:enrol_user_step_3.skipped_copy'
                  )}
                </ClinText>
              </Col>
            </Row>
          )}

          <ClinSpacer height={ClinTheme.space[5]} />
        </>
      ) : (
        <>
          <Row className="step-three">
            <Col sm={12} md={10} lg={7}>
              <ClinText
                color={ClinTheme.colors.primary}
                marginBottom={ClinTheme.space[6]}
                variant={TypographyVariant.H4}
                fontWeight={ClinTheme.fontWeights.bold}
              >
                {tFunc('enrol_user_on_to_program:enrol_user_step_3.title')}
              </ClinText>
              <StyleAdditionalPhysicians isSubmitting={isSubmitting}>
                {selectedPhysicians &&
                  selectedPhysicians.map(
                    (physician: PhysiciansSummaryDto, index) => {
                      return (
                        <StyleAdditionalPhysician key={`physician-${index}`}>
                          <StyleAdditionalPhysicianText>
                            <ClinText
                              wordBreak="break-all"
                              variant={TypographyVariant.LargeParagraph}
                              fontWeight={ClinTheme.fontWeights.medium}
                            >
                              {physician.physicianFullName}
                            </ClinText>
                            <ClinText
                              wordBreak="break-all"
                              variant={TypographyVariant.LargeParagraph}
                            >
                              {physician.physicianEmail}
                            </ClinText>
                          </StyleAdditionalPhysicianText>
                          <StyleAdditionalPhysicianAction
                            onClick={() =>
                              !isSubmitting &&
                              handlePhysicianRemoval(physician.physicianId)
                            }
                          >
                            {tFunc('common:buttons.remove')}
                          </StyleAdditionalPhysicianAction>
                        </StyleAdditionalPhysician>
                      )
                    }
                  )}
                {savedPhysicians &&
                  savedPhysicians.map(
                    (physician: NewPhysicianEnrollDto, index) => {
                      return (
                        <StyleAdditionalPhysician key={`physician-${index}`}>
                          <StyleAdditionalPhysicianText>
                            <ClinText
                              wordBreak="break-all"
                              variant={TypographyVariant.LargeParagraph}
                              fontWeight={ClinTheme.fontWeights.medium}
                            >
                              {`${physician.title} ${physician.firstName} ${physician.lastName}`}
                            </ClinText>
                            <ClinText
                              wordBreak="break-all"
                              variant={TypographyVariant.LargeParagraph}
                            >
                              {physician.emailAddress}
                            </ClinText>
                          </StyleAdditionalPhysicianText>
                          <StyleAdditionalPhysicianAction
                            onClick={() =>
                              !isSubmitting &&
                              physician.emailAddress &&
                              handleSavedPhysicianRemoval(
                                physician.emailAddress
                              )
                            }
                          >
                            {tFunc('common:buttons.remove')}
                          </StyleAdditionalPhysicianAction>
                        </StyleAdditionalPhysician>
                      )
                    }
                  )}
              </StyleAdditionalPhysicians>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[5]} />
          <Row>
            <Col>
              <ClinText
                color={ClinTheme.colors.primary}
                marginBottom={ClinTheme.space[7]}
                variant={TypographyVariant.H4}
                fontWeight={ClinTheme.fontWeights.bold}
              >
                {tFunc(
                  'enrol_user_on_to_program:enrol_user_step_3.permission_title'
                )}
              </ClinText>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={10} lg={7}>
              <ClinGroup alignItems="flex-start" justifyContent="space-between">
                <ClinCheckbox
                  checked={associateConfirmation}
                  onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                    handleConfirmationSelect &&
                    handleConfirmationSelect(event.currentTarget.checked)
                  }
                  id="confirmation-select"
                />
                <ClinText variant={TypographyVariant.LargeParagraph}>
                  {tFunc(
                    'enrol_user_on_to_program:enrol_user_step_3.permission_copy_1'
                  )}
                </ClinText>
              </ClinGroup>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[9]} />
          {isAutoEnrolled && (!savedPhysicians || !savedPhysicians.length) ? (
            <Row>
              <Col sm={12} md={10} lg={7}>
                <ClinText variant={TypographyVariant.LargeParagraph}>
                  {tFunc(
                    'enrol_user_on_to_program:enrol_user_step_3.conditional_message_copy'
                  )}
                </ClinText>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={12} md={10} lg={7}>
                <ClinText variant={TypographyVariant.LargeParagraph}>
                  {tFunc(
                    'enrol_user_on_to_program:enrol_user_step_3.permission_copy_2'
                  )}
                </ClinText>
              </Col>
            </Row>
          )}
          <ClinSpacer height={ClinTheme.space[9]} />
        </>
      )}
    </>
  )
}

export interface IEnrolUserPageState {
  currentStep: EnrolUserOnToProgramStep
  selectedTabIndex: number
  disableSkip: boolean
  associateConfirmation: boolean
  handleGoToNextStep: () => void
  handleGoToPreviousStep: () => void
  handleTabSelected: (selectedTabIndex: number) => void
  handleRequestToAddPhysician: () => void
  handleConfirmationSelect: (confirmed: boolean) => void
  handleGetSteppers: () => StepState[]
}

export const useEnrolUserPageState = (
  physicians: PhysiciansEnrolledSummaryDto[] | undefined,
  selectedPhysicians: PhysiciansSummaryDto[] | undefined,
  savedPhysicians: NewPhysicianEnrollDto[] | undefined
): IEnrolUserPageState => {
  const [currentStep, setCurrentStep] = useState<EnrolUserOnToProgramStep>(
    EnrolUserOnToProgramStep.STEP1
  )
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  const [disableSkip, setDisableSkip] = useState<boolean>(false)
  const [associateConfirmation, setAssociateConfirmation] =
    useState<boolean>(false)

  const handleBackToTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (
      (!physicians || physicians.length === 0) &&
      (!selectedPhysicians || selectedPhysicians.length === 0) &&
      (!savedPhysicians || savedPhysicians.length === 0)
    ) {
      if (currentStep === EnrolUserOnToProgramStep.STEP3) {
        setDisableSkip(true)
        setCurrentStep(EnrolUserOnToProgramStep.STEP2)
      } else if (currentStep === EnrolUserOnToProgramStep.STEP2) {
        setDisableSkip(true)
      } else {
        setDisableSkip(false)
      }
    } else if (
      (selectedPhysicians && selectedPhysicians.length > 0) ||
      (savedPhysicians && savedPhysicians.length > 0)
    ) {
      setDisableSkip(false)
      setAssociateConfirmation(false)
    } else if (physicians && physicians.length > 0) {
      setDisableSkip(false)
      setAssociateConfirmation(true)
    } else {
      setDisableSkip(false)
      setAssociateConfirmation(false)
    }
  }, [currentStep, physicians, savedPhysicians, selectedPhysicians])

  const handleGoToNextStep = (): void => {
    const nextStep = () => {
      switch (currentStep) {
        case EnrolUserOnToProgramStep.STEP1:
          return EnrolUserOnToProgramStep.STEP2
        case EnrolUserOnToProgramStep.STEP2:
          return EnrolUserOnToProgramStep.STEP3
        default:
          return EnrolUserOnToProgramStep.STEP1
      }
    }
    handleBackToTop()
    setCurrentStep(nextStep)
  }
  const handleGoToPreviousStep = (): void => {
    const previousStep = () => {
      switch (currentStep) {
        case EnrolUserOnToProgramStep.STEP3:
          return EnrolUserOnToProgramStep.STEP2
        case EnrolUserOnToProgramStep.STEP2:
          return EnrolUserOnToProgramStep.STEP1
        default:
          return EnrolUserOnToProgramStep.STEP3
      }
    }
    handleBackToTop()
    setCurrentStep(previousStep)
  }

  const handleTabSelected = (selectedTabIndex: number): void => {
    setSelectedTabIndex(selectedTabIndex)
  }

  const handleRequestToAddPhysician = (): void => {
    setSelectedTabIndex(1)
  }

  const handleConfirmationSelect = (confirmed: boolean) => {
    setAssociateConfirmation(confirmed)
  }

  const handleGetSteppers = (): StepState[] => {
    return EnrolUserOnToProgramSteps[currentStep]
  }

  return {
    currentStep,
    selectedTabIndex,
    disableSkip,
    associateConfirmation,
    handleGoToNextStep,
    handleGoToPreviousStep,
    handleTabSelected,
    handleRequestToAddPhysician,
    handleConfirmationSelect,
    handleGetSteppers
  }
}
