import { yupResolver } from '@hookform/resolvers/yup'
import React, { ChangeEvent, FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

import { ClinTheme } from '../../../ClinTheme'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinSelect } from '../../../components/ClinSelect'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinTextInput } from '../../../components/ClinTextInput'
import { physicianTitleRecord, PhysicianTitles } from '../../../constants'
import useEmailValidation from '../../../hooks/useCheckEmailError/useCheckEmailError'
import { NewPhysicianDetilsDto } from '../../../types/swaggerTypes'
import {
  getInputValidation,
  ValidationType
} from '../../../utils/Forms/getInputValidation'
import { InputFieldLength } from '../models/TransferPatientContainer.model'
import { StyledTransferButton } from '../styles/TransferPatient.styles'

interface IPhysicianDetailsProps {
  /** List of titles from the LOV */
  physicianTitles?: string[]
  /** Save physician details */
  savePhysicianDetails: (data: NewPhysicianDetilsDto) => void
  //** Physician details */
  physicianDetails: NewPhysicianDetilsDto
  /** Cancel form and return to previous page */
  handleCancel: () => void
}
const requireText = getInputValidation(ValidationType.RequiredField)
const PhysicianDetailsSchema = object().shape({
  newPhysicianTitle: string(),
  newPhysicianFirstName: string().required(requireText),
  newPhysicianLastName: string().required(requireText),
  newPhysicianEmail: string().required(requireText)
})

export const PhysicianDetailsForm: FunctionComponent<
  IPhysicianDetailsProps
> = ({ savePhysicianDetails, physicianDetails, handleCancel }) => {
  const { t } = useTranslation()
  const { emailErrorMessage, checkEmail } = useEmailValidation()

  const defaultState: NewPhysicianDetilsDto = {
    newPhysicianTitle:
      physicianDetails?.newPhysicianTitle || PhysicianTitles.DR,
    newPhysicianFirstName: physicianDetails?.newPhysicianFirstName || '',
    newPhysicianLastName: physicianDetails?.newPhysicianLastName || '',
    newPhysicianEmail: physicianDetails?.newPhysicianEmail || ''
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    control
  } = useForm<NewPhysicianDetilsDto>({
    defaultValues: defaultState,
    mode: 'onChange',
    resolver: yupResolver(PhysicianDetailsSchema)
  })
  return (
    <>
      <Row>
        <Col sm={12} md={9}>
          <ClinText
            as="h5"
            variant={TypographyVariant.H4}
            color={ClinTheme.colors.primary}
            fontWeight={ClinTheme.fontWeights.bold}
            marginBottom={ClinTheme.space[4]}
          >
            {t('transfer_patient_to_institute:new_physician_form_intro')}
          </ClinText>
        </Col>
      </Row>
      <form
        className="physician-details-form"
        onSubmit={handleSubmit((values) => {
          const isEmailError = values.newPhysicianEmail
            ? checkEmail(values.newPhysicianEmail)
            : false

          if (!isEmailError) {
            savePhysicianDetails(values)
          }
        })}
      >
        <Row>
          <Col xs={12} md={2} lg={2}>
            <Controller
              name="newPhysicianTitle"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ClinSelect
                  id="title"
                  label={t('common:labels.title')}
                  width="100%"
                  value={value}
                  onChange={(changeValue: ChangeEvent<HTMLSelectElement>) =>
                    onChange(changeValue.currentTarget.value)
                  }
                  hasError={!!(errors && errors.newPhysicianTitle)}
                  prompt={errors.newPhysicianTitle?.message}
                >
                  {Object.values(PhysicianTitles).map((val, idx) => {
                    return (
                      <option key={`title-names-${idx}`} value={val}>
                        {t(
                          `${
                            physicianTitleRecord[val as PhysicianTitles]
                              .transKey
                          }`
                        )}
                      </option>
                    )
                  })}
                </ClinSelect>
              )}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <ClinTextInput
              id="first-name"
              {...register('newPhysicianFirstName')}
              label={t('common:labels.first_name')}
              width="100%"
              maxLength={InputFieldLength.MAX80}
              hasError={!!(errors && errors.newPhysicianFirstName)}
              prompt={errors.newPhysicianFirstName?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <ClinTextInput
              id="last-name"
              {...register('newPhysicianLastName')}
              label={t('common:labels.last_name')}
              width="100%"
              maxLength={InputFieldLength.MAX80}
              hasError={!!(errors && errors.newPhysicianLastName)}
              prompt={errors.newPhysicianLastName?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <ClinTextInput
              id="email"
              {...register('newPhysicianEmail')}
              label={t('common:labels.email')}
              width="100%"
              maxLength={InputFieldLength.MAX100}
              hasError={!!emailErrorMessage}
              prompt={emailErrorMessage}
            />
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[7]} />
        <Row>
          <Col sm={12} md={9} lg={9}>
            <ClinAnnounceBar
              mode={AnnounceMode.Information}
              title={t('transfer_patient_to_institute:please_note')}
            >
              {t('transfer_patient_to_institute:note_two')}
            </ClinAnnounceBar>
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[8]} />
        <Row>
          <Col sm={12} md={9} lg={6}>
            <ClinGroup space={ClinTheme.space[3]}>
              <StyledTransferButton
                className="cancel"
                variant="secondary"
                justifyContent="space-around"
                onClick={() => handleCancel()}
              >
                {t('common:buttons.cancel')}
              </StyledTransferButton>
              <StyledTransferButton
                className="continue"
                variant="primary"
                justifyContent="space-around"
                disabled={!formState.isValid}
                type="submit"
              >
                {t('common:buttons.continue')}
              </StyledTransferButton>
            </ClinGroup>
          </Col>
        </Row>
      </form>
    </>
  )
}
