import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinSpacer } from '../../components/ClinSpacer'
import {
  ClinPatientTableHeaderCell,
  ClinTable
} from '../../components/ClinTable/ClinTable'

export const StyledStatusWrapper = styled.div<{ color: string }>(
  ({ color }) => ({
    display: 'block',
    background: color,
    margin: '0 auto',
    padding: '4px 10px',
    borderRadius: `${ClinTheme.space[2]}px`
  })
)

export const StyledActionWrapper = styled.div<{ showGlowEffect?: boolean }>(
  ({ showGlowEffect }) => ({
    textAlign: 'right',
    position: 'relative',
    color: ClinTheme.colors.primary,
    zIndex: showGlowEffect ? 3 : 0
  })
)

export const StyledPatientColumn = styled.div({
  display: 'flex',
  alignItems: 'center'
})

export const StyledExternalLink = styled.a<{ showTableInTab?: boolean }>(
  {
    fontWeight: ClinTheme.fontWeights.medium,
    color: ClinTheme.colors.tealishBlue,
    textTransform: 'initial',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center'
  },
  ({ showTableInTab }) => ({
    fontSize: showTableInTab ? ClinTheme.fontSizes[0] : ClinTheme.fontSizes[1],
    lineHeight: showTableInTab ? '2' : ClinTheme.lineHeights.small
  })
)

export const StyledTable = styled.table({
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: 0
})

export const StyledTableHeader = styled.th({
  padding: ClinTheme.space[2],
  textAlign: 'left',
  fontWeight: ClinTheme.fontWeights.medium,
  fontSize: ClinTheme.fontSizes[1],
  color: ClinTheme.colors.black
})

export const StyledTableRow = styled.tr({
  borderTop: `1px solid ${ClinTheme.colors.grey}`
})

export const StyledTableCell = styled.td({
  padding: ClinTheme.space[2],
  color: ClinTheme.colors.black
})

export const StyledTooltipSearchWrapper = styled.span({
  position: 'absolute',
  left: '-2px',
  top: '190px'
})

export const StyledClinTableOrderToggleWrapper = styled.div`
  position: relative;
  font-size: 12px;
  color: ${ClinTheme.colors.black};
  & > div {
    font-size: 12px;
    color: ${ClinTheme.colors.black};
    align-items: center;
    display: flex;
    flex-direction: row;
  }
`
// Styled component for the table wrapper
export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow: visible;
`

// Updated styled component for the table
export const StyledClinTable = styled(ClinTable)<{
  width?: string
  tableFor?: string
}>`
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  overflow: visible;
  & > table {
    width: 1282px;
  }
  @media screen and (max-width: ${ClinTheme.breakpoints[2]}px) {
    & > table > tbody {
      margin: 24px 21px 38px 21px;
    }
    & > table {
      width: 100%;
    }
  }
`

export const StyledClinPatientTableHeaderCell = styled(
  ClinPatientTableHeaderCell
)<{
  width?: string
}>`
  @media screen and (max-width: ${ClinTheme.breakpoints[2]}px) {
    display: none;
  }
`

export const StyledClinSpacer = styled(ClinSpacer)<{ width?: string }>`
  width: 1282px;
  @media screen and (max-width: ${ClinTheme.breakpoints[2]}px) {
    display: none;
  }
`

// Styled component for fixed-width cells
export const FixedWidthCell = styled.div<{
  width?: string
  minWidth?: string
  maxWidth?: string
}>`
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ActionCell = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  text-align: right;
  position: relative;
`
export const StyledNotFound = styled.div({
  padding: ClinTheme.space[6],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
})
