import React, { FunctionComponent, ReactElement } from 'react'
import PropTypes from 'prop-types'
import {
  ISkeletonLoaderGroupStyles,
  ISkeletonLoaderItemStyles,
  StyledSkeletonItem,
  StyledSkeletonLoaderGroup
} from './ClinSkeletonLoader.styles'

type TSkeletonItem = FunctionComponent<ISkeletonLoaderItemStyles>

export const SkeletonItem: TSkeletonItem = ({
  width,
  height,
  marginBottom,
  backgroundColor
}) => (
  <StyledSkeletonItem
    backgroundColor={backgroundColor}
    width={width}
    height={height}
    marginBottom={marginBottom}
  />
)

SkeletonItem.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  marginBottom: PropTypes.number,
  backgroundColor: PropTypes.string
}

type TReactSkeletonItem = ReactElement<TSkeletonItem>

interface ISkeletonLoaderGroupProps extends ISkeletonLoaderGroupStyles {
  /** Only accepts children which must are of type SkeletonItem */
  children: TReactSkeletonItem | TReactSkeletonItem[]
}

export const ClinSkeletonLoader: FunctionComponent<
  ISkeletonLoaderGroupProps
> = ({ children }) => (
  <StyledSkeletonLoaderGroup>{children}</StyledSkeletonLoaderGroup>
)
