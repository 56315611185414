import axios from 'axios'
import mixpanel from 'mixpanel-browser'
import { Log, User, UserManager, UserManagerSettings } from 'oidc-client'

import config from './ConfigProvider'
import { localStorageName } from './UserInstituteService'

const { stsAuthority, clientId, clientRoot, clientScope } = config

const settings: UserManagerSettings = {
  authority: stsAuthority, // eslint-disable-line
  client_id: clientId, // eslint-disable-line
  redirect_uri: `${clientRoot}signin-callback.html`, // eslint-disable-line
  silent_redirect_uri: `${clientRoot}silent-renew.html`, // eslint-disable-line
  post_logout_redirect_uri: `${clientRoot}`, // eslint-disable-line
  response_type: 'code', // eslint-disable-line
  scope: clientScope,
  accessTokenExpiringNotificationTime: 60 // Number of seconds before an access token expiry event is fired
  // userStore: new WebStorageStateStore({
  // 	store: window.localStorage
  // })
}

class AuthService {
  userManager: UserManager = new UserManager(settings)
  public isAuthenticated: boolean = false

  constructor() {
    Log.logger = console
    Log.level = Log.NONE // TODO: Set log level dependant on environment
    this.handleLogoutEvent()
    this.setupTokenExpiryRenewEvents()
  }

  private handleLogoutEvent() {
    window.addEventListener('storage', (e) => {
      if (e.key === 'notifyLogout') {
        this.logout({ thisTabOnly: true })
      }
    })
  }

  private setupTokenExpiryRenewEvents() {
    this.userManager.events.addAccessTokenExpiring(async (ev) => {
      try {
        await this.renewToken()
      } catch (e) {
        console.error(e)
        await this.userManager.signoutRedirect()
      }
    })
    this.userManager.events.addSilentRenewError((error) =>
      this.userManager.signoutRedirect()
    )
  }

  getUserAsync = async (): Promise<User | null> => {
    return this.userManager.getUser()
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser().then((user) => {
      this.isAuthenticated = !!user
      // Set headers for all subsequent calls
      if (user && user.access_token) {
        axios.defaults.headers.common = {
          Accept: 'application/json',
          Authorization: `Bearer ${user.access_token}`
        }
      }
      return user
    })
  }

  public login(url: string = '/'): Promise<void> {
    return this.userManager.signinRedirect(url && { state: url })
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent()
  }

  renewTokenAsync = async (): Promise<User> => {
    return this.userManager.signinSilent()
  }

  public logout({ thisTabOnly = false } = {}): Promise<void> {
    if (!thisTabOnly) {
      // oidc-client uses session storage to store the session, so if we
      // only clear localstorage in this tab the user will still be logged
      // in in other tabs after logout.
      // Use notifyLogout + storage event listener to detect logout in other tabs
      // because BroadcastChannel API isn't supported in IE11/Safari/...
      localStorage.setItem('notifyLogout', Math.random().toString())
    }

    return this.userManager.signoutRedirect().then(() => {
      localStorage.removeItem(localStorageName)
      //On every log out reset the distinct_id in mp
      if (mixpanel) mixpanel.reset()
    })
  }
}

const authService = new AuthService()
export default authService
