import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'

export const NotificationContainer = styled.div({
  top: ClinTheme.space[10],
  right: '10px',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  borderRadius: ClinTheme.radii[3],
  backgroundColor: ClinTheme.colors.greenValid,
  zIndex: ClinTheme.zIndices.modal
})

export const VerticalLine = styled.div({
  width: '1px',
  height: ClinTheme.space[3],
  margin: ClinTheme.space[3],
  backgroundColor: ClinTheme.colors.black
})

export const DismissButton = styled.button({
  marginRight: ClinTheme.space[2],
  paddingLeft: ClinTheme.space[0],
  fontWeight: ClinTheme.fontWeights.medium,
  fontSize: ClinTheme.fontSizes[1],
  backgroundColor: ClinTheme.colors.greenValid,
  color: ClinTheme.colors.aquaGreen,
  fontFamily: ClinTheme.fonts.body,
  cursor: 'pointer',
  border: 'none'
})
