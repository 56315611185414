import { AxiosError } from 'axios'
import { useState } from 'react'

import { AnalyticsEvent } from '../../../../services/Analytics'
import analyticsServiceSingleton from '../../../../services/Analytics/initAnalytics'
import { discontinuePatient } from '../../../../services/ApiService'

export enum AssociateMode {
  active,
  submitting,
  hasError
}

interface IDiscontinueConfirmationModal {
  /** Whether to open the confirmation modal */
  isConfirmationOpen: boolean
  /** Whether the request is submitting */
  isConfirmationSubmitting: boolean
  /** Whether the request has submitted */
  isConfirmationSubmitted: boolean
  /** Whether the request failed to submit */
  confirmationError?: AxiosError
  /** A function that calls to open the modal */
  handleOpenConfirmation: () => void
  /** A function that calls to close the modal */
  handleCloseConfirmation: () => void
  /** A function that calls to confirm the user wishes to discontinue the patient */
  handleConfirmationSubmit: () => void
  /** Reset confirmation submitted to false */
  handleClearSubmission: () => void
}

/*
 * Controls the opening of the confirmation modal and handles discontinuing of a patient
 * @param patientId
 * @param physicianId
 * @param instituteId
 */
export const useDiscontinueConfirmationModal = (
  patientId: string,
  physicianId: string,
  instituteId?: number
): IDiscontinueConfirmationModal => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false)
  const [isConfirmationSubmitting, setIsConfirmationSubmitting] =
    useState<boolean>(false)
  const [isConfirmationSubmitted, setIsConfirmationSubmitted] =
    useState<boolean>(false)
  const [confirmationError, setConfirmationError] = useState<AxiosError>()
  const handleOpenConfirmation = (): void => {
    setIsConfirmationOpen(true)
  }
  const handleCloseConfirmation = (): void => {
    setIsConfirmationOpen(false)
    setConfirmationError(undefined)
  }

  const handleConfirmationSubmit = (): void => {
    setIsConfirmationSubmitting(true)

    discontinuePatient(physicianId, patientId, {
      reasonCode: 'OTHER'
    })
      .then((response) => {
        if (response.status === 200) {
          setIsConfirmationSubmitted(true)
          setIsConfirmationOpen(false)
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.PatientDiscontinue,
            { physicianId, patientId, instituteId }
          )
        }
      })
      .catch((error) => {
        setConfirmationError(error)
      })
      .finally(() => {
        setIsConfirmationSubmitting(false)
      })
  }

  const handleClearSubmittion = () => {
    setIsConfirmationSubmitted(false)
  }

  return {
    isConfirmationOpen,
    isConfirmationSubmitting,
    isConfirmationSubmitted,
    confirmationError,
    handleOpenConfirmation,
    handleCloseConfirmation,
    handleConfirmationSubmit,
    handleClearSubmission: handleClearSubmittion
  }
}
