import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  EnrolUserOnToProgramStep,
  getViewForStepOne,
  getViewForStepThree,
  getViewForStepTwo
} from './EnrolUserOnToProgram.model'
import {
  StyledSpinnerContainer,
  StyledActions,
  IEnrolUserOnToProgramStyleProps,
  StyledLoader
} from './EnrolUserOnToProgram.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinStepper } from '../../components/ClinStepper'
import { StepState } from '../../components/ClinStepper/ClinStepper'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { Prompt } from '../../features/Prompt'
import {
  InstituteDto,
  NewPhysicianEnrollDto,
  PhysiciansEnrolledSummaryDto,
  PhysiciansSummaryDto,
  ProgramCatalogDto,
  UserDto
} from '../../types/swaggerTypes'

interface IEnrolUserOnToProgramProps extends IEnrolUserOnToProgramStyleProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** The current users details */
  userDetails?: UserDto
  /** The users associated institute */
  institute?: InstituteDto
  /** Selected program */
  program?: ProgramCatalogDto
  /** The current step */
  currentStep: EnrolUserOnToProgramStep
  /** The selected step state */
  selectedSteppers: StepState[]
  /** Enrolled physicians */
  physicians: PhysiciansEnrolledSummaryDto[] | undefined
  /** Selected physicians */
  selectedPhysicians?: PhysiciansSummaryDto[]
  /** Saved physicians */
  savedPhysicians?: NewPhysicianEnrollDto[]
  /** Index of the selected tab */
  selectedTabIndex: number
  /** Whether a user has confirmed whether they would like to associate with a physician or not **/
  associateConfirmation?: boolean
  /** Disable the ability to skip */
  disableSkip: boolean
  /** Decides whether the form is edited or not */
  isFormEdited: boolean
  /** Decides whether the form is submitted or not */
  isSubmitted?: boolean

  isAutoEnrolled?: boolean
  /** Called when the user selects a tab */
  handleTabSelected: (selectedTabIndex: number) => void
  /** Call back to broadcast a selected physician */
  handleOnSelect: (suggestion: PhysiciansSummaryDto) => void
  /** Call back request to add a new physician */
  handleRequestToAddPhysician: () => void
  /** Call back request to remove a selected physician */
  handlePhysicianRemoval: (physicianId: number) => void
  /** Call back to save a physicians details */
  handleSavedPhysicianDetails: (physician: NewPhysicianEnrollDto) => void
  /** Call back to remove a selected saved physician */
  handleSavedPhysicianRemoval: (email: string) => void
  /** Handle whether the form is edited or not */
  handleIsFormEdited: (isEdited: boolean) => void
  /** Call back to go to the next step */
  handleGoToNextStep: () => void
  /** Call back to go to the previous step */
  handleGoToPreviousStep: () => void
  /** Call back handle to confirm request */
  handleConfirmationSelect: (confirmed: boolean) => void
  /** Call back handle a request to submit */
  handleSubmit: () => void
  /** Call back handle a request to cancel and go back to the associated program */
  handleRequestToViewProgram: () => void
  /** Call back to go to the contact us page */
  handleGoToContactUs: () => void
}

export const EnrolUserOnToProgram: FunctionComponent<
  IEnrolUserOnToProgramProps
> = ({
  isLoading,
  userDetails,
  institute,
  program,
  currentStep,
  selectedSteppers,
  physicians,
  selectedPhysicians,
  savedPhysicians,
  selectedTabIndex,
  associateConfirmation,
  isSubmitting,
  disableSkip,
  isFormEdited,
  isSubmitted,
  isAutoEnrolled,
  handleTabSelected,
  handleOnSelect,
  handleRequestToAddPhysician,
  handlePhysicianRemoval,
  handleSavedPhysicianDetails,
  handleSavedPhysicianRemoval,
  handleIsFormEdited,
  handleGoToNextStep,
  handleGoToPreviousStep,
  handleConfirmationSelect,
  handleSubmit,
  handleRequestToViewProgram,
  handleGoToContactUs
}) => {
  const { t } = useTranslation()

  // Calculate whether to show the prompt explicitly as a boolean
  const shouldShowPrompt =
    !isSubmitted &&
    (!!isFormEdited ||
      !!(savedPhysicians && savedPhysicians.length) ||
      !!(selectedPhysicians && selectedPhysicians.length))

  return (
    <ClinPageContentFrame className="enrol-user-on-to-program">
      {isLoading ? (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      ) : (
        <>
          {shouldShowPrompt && <Prompt />}
          <Row>
            <Col sm={12} md={10} lg={9}>
              <ClinSpacer height={ClinTheme.space[5]} />
              <ClinText
                as="h1"
                className="physician-full-name"
                variant={TypographyVariant.H2}
                fontWeight={ClinTheme.fontWeights.bold}
              >
                {t('enrol_user_on_to_program:title', {
                  programName: program?.program?.programName
                })}
              </ClinText>
              <ClinSpacer height={ClinTheme.space[5]} />
              {selectedSteppers && (
                <ClinStepper
                  steps={[
                    {
                      state: selectedSteppers[0],
                      displayText: t('enrol_user_on_to_program:stepper.step_1')
                    },
                    {
                      state: selectedSteppers[1],
                      displayText: t('enrol_user_on_to_program:stepper.step_2')
                    },
                    {
                      state: selectedSteppers[2],
                      displayText: t('enrol_user_on_to_program:stepper.step_3')
                    }
                  ]}
                  width={600}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={10} lg={8}>
              <ClinSpacer height={ClinTheme.space[8]} hasBorder />
            </Col>
          </Row>
          {currentStep === EnrolUserOnToProgramStep.STEP1 &&
            getViewForStepOne(t, handleGoToContactUs, userDetails, institute)}

          {currentStep === EnrolUserOnToProgramStep.STEP2 &&
            getViewForStepTwo(
              t,
              selectedTabIndex,
              physicians,
              disableSkip,
              handleTabSelected,
              handleOnSelect,
              handleRequestToAddPhysician,
              handlePhysicianRemoval,
              handleSavedPhysicianDetails,
              handleSavedPhysicianRemoval,
              handleIsFormEdited,
              handleGoToNextStep,
              selectedPhysicians,
              savedPhysicians,
              program
            )}

          {currentStep === EnrolUserOnToProgramStep.STEP3 &&
            getViewForStepThree(
              t,
              handlePhysicianRemoval,
              handleSavedPhysicianRemoval,
              handleConfirmationSelect,
              associateConfirmation,
              isSubmitting,
              selectedPhysicians,
              savedPhysicians,
              isAutoEnrolled
            )}

          <Row>
            <Col sm={12} md={10} lg={8}>
              {currentStep !== EnrolUserOnToProgramStep.STEP1 && (
                <ClinSpacer hasBorder height={0} />
              )}
              <StyledActions>
                <ClinButton
                  className="cancel"
                  onClick={() => handleRequestToViewProgram()}
                >
                  {t('common:buttons.cancel')}
                </ClinButton>
                {currentStep !== EnrolUserOnToProgramStep.STEP1 && (
                  <ClinButton
                    className="back"
                    onClick={() => handleGoToPreviousStep()}
                  >
                    {t('common:buttons.back_button_text')}
                  </ClinButton>
                )}
                {currentStep !== EnrolUserOnToProgramStep.STEP3 && (
                  <ClinButton
                    className="continue"
                    disabled={disableSkip}
                    variant="primary"
                    onClick={() => handleGoToNextStep()}
                  >
                    {t('common:buttons.continue')}
                  </ClinButton>
                )}
                {currentStep === EnrolUserOnToProgramStep.STEP3 && (
                  <ClinButton
                    className="submit"
                    disabled={associateConfirmation === false || isSubmitting}
                    variant="primary"
                    onClick={() => handleSubmit()}
                  >
                    {t('common:buttons.submit_request')}
                    {isSubmitting && (
                      <StyledLoader>
                        <ClinSpinner size={ClinTheme.space[3]} />
                      </StyledLoader>
                    )}
                  </ClinButton>
                )}
              </StyledActions>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[7]} />
        </>
      )}
    </ClinPageContentFrame>
  )
}
