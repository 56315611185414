import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  // StyledOpaOrderItemLayout,
  StyledOpaOrderItem,
  IStyledOpaOrderItemProps,
  StyledOpaOrderItemDetailMobileLabel,
  StyledOpaOrderItemDetailValue,
  StyledOpaOrderItemStatus
  // StyledProductColumn
} from './OpaOrderItem.styles'
import { ClinTheme } from '../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../components/ClinAccordion'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinText } from '../../components/ClinText'
import { OrderLineDto } from '../../types/swaggerTypes'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'
import { DrugPropertyItem } from '../OrderItems/OrderItem'

export interface IOpaAncillaryOrderItemProps extends IStyledOpaOrderItemProps {
  /** Order items */
  orderLine: OrderLineDto
  /** Currency code */
  currencyCode: string
}

export const OpaAncillaryOrderItem: FunctionComponent<
  IOpaAncillaryOrderItemProps
> = ({
  orderLine,
  currencyCode,
  isShowingProductDetails = true,
  hasBorder
}) => {
  const { t } = useTranslation()
  const { skuCatalogItem } = orderLine
  const genericName = orderLine?.itemDescription
  const quantity = orderLine.quantities.ordered
  const brandName = skuCatalogItem?.item.brand
    ? skuCatalogItem?.item.brand
    : t('glossary:not_applicable')
  const packPrice = numberToCurrencyString(
    orderLine.unitSellingPrice,
    getBrowserLocale(),
    {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  )
  const status = orderLine.status

  return (
    <StyledOpaOrderItem
      hasBorder={hasBorder}
      isShowingProductDetails={isShowingProductDetails}
    >
      <Row className="order-data">
        <Col sm={12} md={6}>
          <StyledOpaOrderItemDetailValue>
            <StyledOpaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_product')}
              </ClinText>
            </StyledOpaOrderItemDetailMobileLabel>
            <ClinText
              className="generic-name"
              fontSize={ClinTheme.fontSizes[3]}
              fontWeight={ClinTheme.fontWeights.bold}
              color={ClinTheme.colors.black}
              textAlign="left"
              wordBreak="break-word"
            >
              {genericName}
            </ClinText>
          </StyledOpaOrderItemDetailValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledOpaOrderItemDetailValue>
            <StyledOpaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_product_code')}
              </ClinText>
            </StyledOpaOrderItemDetailMobileLabel>
            <ClinText
              className="product-code"
              fontSize={ClinTheme.fontSizes[2]}
              textAlign="right"
            >
              {orderLine.skuCatalogItem?.sku || t('glossary:not_applicable')}
            </ClinText>
          </StyledOpaOrderItemDetailValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledOpaOrderItemDetailValue>
            <StyledOpaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_quantity')}
              </ClinText>
            </StyledOpaOrderItemDetailMobileLabel>
            <ClinText
              className="quantity"
              fontSize={ClinTheme.fontSizes[2]}
              textAlign="right"
            >
              {quantity}
            </ClinText>
          </StyledOpaOrderItemDetailValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledOpaOrderItemDetailValue>
            <StyledOpaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_amount')}
              </ClinText>
            </StyledOpaOrderItemDetailMobileLabel>
            <ClinText
              className="amount"
              fontSize={ClinTheme.fontSizes[2]}
              textAlign="right"
            >
              {packPrice}
            </ClinText>
          </StyledOpaOrderItemDetailValue>
        </Col>
      </Row>
      <StyledOpaOrderItemStatus>
        <ClinText
          fontSize={ClinTheme.fontSizes[1]}
          fontWeight={ClinTheme.fontWeights.medium}
          color={ClinTheme.colors.primary}
        >
          {t('glossary:status')}: &nbsp;
        </ClinText>
        <ClinText className="status" fontSize={ClinTheme.fontSizes[2]}>
          {status}
        </ClinText>
      </StyledOpaOrderItemStatus>
      <Row>
        <Col sm={12} md={8}>
          {isShowingProductDetails && (
            <ClinAccordion activeItemIndices={[0]}>
              <ClinAccordionItem title={t('glossary:product_details')}>
                <Row>
                  <DrugPropertyItem
                    iconName={ClinIconPathName.BrandName}
                    title={t('glossary:product')}
                    description={brandName}
                  />
                </Row>
              </ClinAccordionItem>
            </ClinAccordion>
          )}
        </Col>
      </Row>
    </StyledOpaOrderItem>
  )
}
