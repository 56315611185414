import React from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { ClinTheme } from '../../../../ClinTheme'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinModal } from '../../../../components/ClinModal'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'

interface IPatientOPAUrlErrorModalProps {
  isOpen: boolean
  onClose: () => void
}

export const PatientOPAUrlErrorModal: React.FC<
  IPatientOPAUrlErrorModalProps
> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <ClinModal maxWidth="xs" isOpen={isOpen} isDismissible={false}>
        <Row>
          <Col>
            <ClinText
              variant={TypographyVariant.LargerParagraph}
              fontWeight={ClinTheme.fontWeights.bold}
              color={ClinTheme.colors.primary}
              marginLeft={ClinTheme.space[2]}
              marginRight={ClinTheme.space[2]}
            >
              {t('patient_access_form:opa_url_error_modal.title')}
            </ClinText>
            <ClinSpacer height={ClinTheme.space[2]} />
            <ClinText
              variant={TypographyVariant.LargeParagraph}
              marginLeft={ClinTheme.space[2]}
              marginRight={ClinTheme.space[4]}
            >
              {t('patient_access_form:opa_url_error_modal.description')}
            </ClinText>
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[4]} />
        <Row>
          <Col sm={12} md={8}>
            <Row>
              <Col md={5} lg={6}>
                <ClinButton
                  style={{
                    width: '100%',
                    marginLeft: ClinTheme.space[2],
                    marginBottom: ClinTheme.space[2]
                  }}
                  variant="primary"
                  onClick={() => onClose()}
                >
                  {t('common:buttons.ok')}
                </ClinButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </ClinModal>
    </>
  )
}
