import React, {
  ChangeEvent,
  FunctionComponent,
  useState,
  useEffect,
  Fragment
} from 'react'
import { DateTime } from 'luxon'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinText } from '../../components/ClinText'
import { ClinTheme } from '../../ClinTheme'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinButton } from '../../components/ClinButton'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import {
  OrderAddressDto,
  OrderDto,
  OrderLineDto
} from '../../types/swaggerTypes'
import {
  StyledNewCheckoutBlockHeader,
  StyledNewCheckoutBlock,
  StyledNewCheckoutBlockBody,
  StyledNewCheckoutUserInfoBlock,
  StyledNewCheckoutAdditionalBlock,
  StyledDeclarationCheckoutBlockBody,
  StyledCheckoutDeliveryDateContainer,
  StyledCheckoutDeliveryDateContent,
  StyledCheckoutDeliveryDateIconContainer,
  StyledCheckoutPoBlock,
  StyledNewCheckoutOrder,
  StyledCheckoutTextareaContainer,
  StyledCheckoutClinPageContentFrame,
  StyledCheckoutClinPageContainer,
  StyledTableContainer
} from './Checkout.styles'
import { Col, Row } from 'react-grid-system'
import { ClinTextInput } from '../../components/ClinTextInput'
import { DeliveryAddress } from '../CheckoutOptions/DeliveryAddress'
import {
  StyledClinRadio,
  StyledDeliveryAddressCheckoutWrapper,
  StyledDeliveryAddressNewCheckout,
  StyledNewShippingAddressLinkOneStepCheckout,
  StyledDeliveryAddressWrap
} from '../CheckoutOptions/CheckoutOptions.styles'
import { ClinGroup } from '../../components/ClinGroup'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { Trans, useTranslation } from 'react-i18next'
import {
  StyledSummaryItemWrap,
  StyledSummaryOneStepCheckout,
  StyledSummaryTitle
} from '../Basket/Basket.styles'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { CheckoutViewMode } from '../Checkout/CheckoutContainer'
import { IViewBasket } from '../../context/basket'
import { ClinLoader } from '../../components/ClinLoader'
import useEmailValidation from '../../hooks/useCheckEmailError/useCheckEmailError'
import { ClinCheckbox } from '../../components/ClinCheckbox'
import { ClinTextarea } from '../../components/ClinTextarea'
import { debounce } from 'lodash'
import i18n from '../../i18n/config'
import { useAppContext } from '../../context/app'
import { TableRow } from '@mui/material'
import {
  TableHeader,
  QuantityCell,
  SkuCell,
  SubtotalCell
} from '../OpaCheckout/OpaCheckoutSteps/OrderDetails/OrderDetails.styles'

interface ICheckoutProps {
  order?: OrderDto
  /** All orders from createdOrders array */
  allOrders?: OrderDto[]
  /** Supplied Purchase Order number **/
  customerPoNumber?: string
  /** Supplied deliver to **/
  deliverToContact?: string
  /** An array of delivery addresses **/
  deliveryAddresses?: OrderAddressDto[]
  /** Recipient phone number from support contact or choosed by user */
  recipientPhoneNumber: string
  /** Estimated delivery date */
  estimatedDeliveryDate: string | undefined
  /** Cut off time */
  cutOffTime: string | undefined
  /** Recipient email */
  recipientEmail: string
  /** Selected address id */
  selectedAddressId?: string
  /** Error in PO */
  errorMessage?: string
  /** View basket from context */
  basket?: IViewBasket
  /** When back to basket is clicked **/
  handleBackToBasket?: () => void
  /** Requests that an address change has been made **/
  handleChangeAddress?: (selectedAddressId?: string) => void
  /** Handles a recipient's name change for an order **/
  handleRecipientsNameChange?: (value: string) => void
  /** Handles recipient phone change **/
  handleRecipientPhoneChange: (phone: string) => void
  /** Handles recipient email change **/
  handleRecipientEmailChange: (phone: string) => void
  /** Handles a po value change for an order **/
  handlePoChange?: (value: string) => void
  /** Handles a request to exit and add a new shipping address **/
  handleNewShippingAddress?: () => void
  /** The decided view which should be displayed **/
  viewMode: CheckoutViewMode
  /** Handles a request to place the orders in the checkout **/
  handleOrderSubmit: () => void
  /** Handles for sending mail on click on email address in disclaimer **/
  handleSendEmail: () => void
  /** Handles for saving delivery details **/
  handleSaveDeliveryDetails?: (details: {
    recipientName: string
    recipientEmail: string
    recipientPhone: string
    deliveryNote?: string
  }) => boolean
  /** Field errors */
  fieldErrors: {
    nameError?: string
    phoneError?: string
    emailError?: string
  }
  deliveryNote?: string
  handleDeliveryNotesChange?: (notes: string) => void
}

export const Checkout: FunctionComponent<ICheckoutProps> = ({
  order,
  allOrders,
  deliverToContact,
  recipientEmail,
  recipientPhoneNumber,
  selectedAddressId,
  estimatedDeliveryDate,
  errorMessage,
  deliveryAddresses,
  basket,
  handleBackToBasket,
  handleChangeAddress,
  handlePoChange,
  handleNewShippingAddress,
  viewMode,
  handleOrderSubmit,
  handleSaveDeliveryDetails,
  fieldErrors,
  deliveryNote,
  handleDeliveryNotesChange
}) => {
  const { t } = useTranslation()
  const { emailErrorMessage, checkEmail } = useEmailValidation()
  const { institute } = useAppContext()

  const handleOrderSubmitDebounced = debounce(handleOrderSubmit, 500)
  const [isEditing, setIsEditing] = useState(false)
  const [showDeliveryNotes, setShowDeliveryNotes] = useState(false)
  const [agreedTandCs, setAgreedTandCs] = useState<boolean>()

  const [editForm, setEditForm] = useState({
    recipientName: deliverToContact || '',
    recipientEmail: recipientEmail || '',
    recipientPhone: recipientPhoneNumber || '',
    deliveryNote: deliveryNote || ''
  })

  const unlicensedMedicinalProductsGuidance =
    'https://assets.publishing.service.gov.uk/media/645e19f5ad8a03000c38b3bc/The_supply_of_unlicensed_medicinal_products__special_GN14.pdf'

  const handleSave = () => {
    const isEmailError =
      checkEmail(editForm.recipientEmail) || !editForm.recipientEmail.trim()

    if (isEmailError) {
      return
    }

    const success = handleSaveDeliveryDetails?.(editForm)
    if (success) {
      setIsEditing(false)
    }
  }
  useEffect(() => {
    setEditForm({
      recipientName: deliverToContact || '',
      recipientEmail: recipientEmail || '',
      recipientPhone: recipientPhoneNumber || '',
      deliveryNote: deliveryNote || ''
    })
  }, [deliverToContact, recipientEmail, recipientPhoneNumber, deliveryNote])

  const updateOrderLineDescription = (orderLine: OrderLineDto) => {
    if (orderLine.itemDescription.includes('Standard Coldchain Data Logger')) {
      return t('opa_checkout:additional_lines.data_logger_cold')
    } else if (
      orderLine.itemDescription.includes('Standard Ambient Data Logger')
    ) {
      return t('opa_checkout:additional_lines.data_loger_ambient')
    }
    return orderLine.itemDescription
  }

  // Check if the order line is a data logger
  const isDataLogger = (orderLine: OrderLineDto): boolean => {
    return orderLine.itemDescription.includes(
      'Data Logger for 2 C to 8 C - Standard Coldchain Data Logger'
    )
  }

  // Get all data logger charges from all orders
  const getDataLoggerCharges = (): OrderLineDto[] => {
    const dataLoggerLines: OrderLineDto[] = []

    // Check primary order
    if (order) {
      order.lines
        .filter((line) => isDataLogger(line))
        .forEach((line) => dataLoggerLines.push(line))
    }

    // Check additional orders
    if (allOrders && allOrders.length > 1) {
      allOrders.slice(1).forEach((additionalOrder) => {
        additionalOrder.lines
          .filter((line) => isDataLogger(line))
          .forEach((line) => dataLoggerLines.push(line))
      })
    }

    return dataLoggerLines
  }

  // Calculate total from all orders
  const calculateTotalFromAllOrders = (): number => {
    if (!allOrders || allOrders.length === 0) {
      return order?.totals.total ?? 0
    }

    return allOrders.reduce(
      (sum, currentOrder) => sum + (currentOrder.totals.total ?? 0),
      0
    )
  }

  // Calculate total tax from all orders
  const calculateTotalTaxFromAllOrders = (): number => {
    if (!allOrders || allOrders.length === 0) {
      // Ensure we return a number even if order?.totals.tax is undefined
      return typeof order?.totals.tax === 'number' ? order.totals.tax : 0
    }

    return allOrders.reduce((sum, currentOrder) => {
      // Ensure we only add numbers
      const tax =
        typeof currentOrder.totals.tax === 'number'
          ? currentOrder.totals.tax
          : 0
      return sum + tax
    }, 0)
  }

  // Check if tax is available for display
  const isTaxAvailable = (): boolean => {
    if (!allOrders || allOrders.length === 0) {
      return typeof order?.totals.tax === 'number'
    }

    // Check if all orders have valid tax values
    return allOrders.every(
      (currentOrder) => typeof currentOrder.totals.tax === 'number'
    )
  }

  return (
    <ClinPageContentFrame
      isFullWidth={true}
      hideBreadcrumbs={true}
      marginBottom="0"
      className="min-height-aus-checkout-class"
    >
      <ClinPageContentFrame
        hideBreadcrumbs={true}
        marginBottom="0"
        className="min-height-aus-checkout-class-loading-submitting"
      >
        {viewMode === CheckoutViewMode.Submitting && (
          <>
            <Row>
              <Col sm={6}>
                <ClinText
                  variant={TypographyVariant.H4}
                  marginTop={ClinTheme.space[4]}
                >
                  {t('checkout:placing_your_order')}
                </ClinText>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <StyledSpinnerContainer>
                  <ClinLoader />
                </StyledSpinnerContainer>
              </Col>
            </Row>
          </>
        )}
      </ClinPageContentFrame>
      {viewMode === CheckoutViewMode.ShowOrders && (
        <>
          <StyledCheckoutClinPageContainer className="min-height-aus-checkout-class-checkout-header">
            <StyledCheckoutClinPageContentFrame>
              <ClinButton
                style={{ marginTop: '24px', fontSize: '12px', height: 'auto' }}
                variant="linkButton"
                onClick={() => handleBackToBasket && handleBackToBasket()}
              >
                <ClinIcon
                  viewBox="0 0 32 14"
                  style={{ marginRight: '8px' }}
                  iconName={ClinIconPathName.LeftArrow}
                />
                {t('checkout_options:back_to_basket')}
              </ClinButton>
              <Row>
                <Col sm={12}>
                  <ClinText
                    as="h1"
                    fontWeight={ClinTheme.fontWeights.bold}
                    lineHeight={'40px'}
                    fontSize={ClinTheme.fontSizes[5]}
                    marginBottom={ClinTheme.space[4]}
                  >
                    {t('checkout_options:title')}
                  </ClinText>
                </Col>
              </Row>
            </StyledCheckoutClinPageContentFrame>
          </StyledCheckoutClinPageContainer>
          <ClinSpacer height={ClinTheme.space[5]} />
          <ClinPageContentFrame
            hideBreadcrumbs={true}
            marginBottom="0"
            className="min-height-aus-checkout-class-content"
          >
            <StyledNewCheckoutOrder>
              <Row>
                <Col xs={12} lg={8}>
                  <StyledNewCheckoutBlock>
                    <StyledNewCheckoutBlockHeader>
                      <ClinText
                        as="h3"
                        // variant={TypographyVariant.H5}
                        color={ClinTheme.colors.black}
                        lineHeight={ClinTheme.lineHeights.largeParagraph}
                        fontSize={ClinTheme.fontSizes[3]}
                        fontWeight={ClinTheme.fontWeights.medium}
                      >
                        Delivery to
                      </ClinText>
                      {!isEditing ? (
                        <ClinButton
                          className="edit-checkout"
                          onClick={() => setIsEditing(true)}
                        >
                          Edit
                        </ClinButton>
                      ) : (
                        <div>
                          <ClinButton
                            variant="ghost"
                            style={{ marginRight: '16px' }}
                            onClick={() => {
                              setIsEditing(false)
                              setEditForm({
                                ...editForm,
                                recipientName: deliverToContact || '',
                                recipientEmail: recipientEmail || '',
                                recipientPhone: recipientPhoneNumber || '',
                                deliveryNote: deliveryNote || ''
                              })
                            }}
                          >
                            Cancel
                          </ClinButton>
                          <ClinButton variant="secondary" onClick={handleSave}>
                            Save Changes
                          </ClinButton>
                        </div>
                      )}
                    </StyledNewCheckoutBlockHeader>
                    <StyledNewCheckoutUserInfoBlock>
                      {!isEditing && (
                        <>
                          <ClinText
                            lineHeight="24px"
                            fontSize={ClinTheme.fontSizes[1]}
                          >
                            {editForm.recipientName}
                          </ClinText>
                          <ClinText
                            lineHeight="24px"
                            fontSize={ClinTheme.fontSizes[1]}
                          >
                            {editForm.recipientEmail}
                          </ClinText>
                          <ClinText
                            lineHeight="24px"
                            fontSize={ClinTheme.fontSizes[1]}
                          >
                            {editForm.recipientPhone.trim()}
                          </ClinText>
                          <ClinText
                            lineHeight="24px"
                            fontSize={ClinTheme.fontSizes[1]}
                          >
                            {institute.data?.instituteName}
                          </ClinText>
                          {deliveryAddresses?.find(
                            (addr) => addr.addressId === selectedAddressId
                          ) && (
                            <DeliveryAddress
                              fontWeight={ClinTheme.fontWeights.normal}
                              fontSize={ClinTheme.fontSizes[1]}
                              address={
                                deliveryAddresses.find(
                                  (addr) => addr.addressId === selectedAddressId
                                )!
                              }
                              isCompact={true}
                            />
                          )}
                        </>
                      )}
                    </StyledNewCheckoutUserInfoBlock>
                    {isEditing && (
                      <StyledNewCheckoutBlockBody>
                        <Row>
                          <Col xs={12} md={7}>
                            <ClinTextInput
                              width={'100%'}
                              labelFontWeight={ClinTheme.fontWeights.normal}
                              hasError={!!fieldErrors.nameError}
                              prompt={fieldErrors.nameError ?? ''}
                              id="recipients-name"
                              name="recipientsName"
                              label={t(
                                'checkout_options:checkout_options.delivery_contact'
                              )}
                              value={editForm.recipientName}
                              onChange={(e) =>
                                setEditForm({
                                  ...editForm,
                                  recipientName: e.currentTarget.value
                                })
                              }
                              required
                            />
                          </Col>
                          <Col sm={12} md={7}>
                            <ClinSpacer height={ClinTheme.space[3]} />
                            <ClinTextInput
                              labelFontWeight={ClinTheme.fontWeights.normal}
                              type="email"
                              width="100%"
                              id="recipients-email"
                              name="recipientsEmail"
                              label={t(
                                'checkout_options:delivery_contact_email'
                              )}
                              value={editForm.recipientEmail}
                              hasError={!!emailErrorMessage}
                              prompt={emailErrorMessage ?? ''}
                              onChange={(e) =>
                                setEditForm({
                                  ...editForm,
                                  recipientEmail: e.currentTarget.value
                                })
                              }
                              required
                            />
                          </Col>
                          <Col xs={12} md={7}>
                            <ClinSpacer height={ClinTheme.space[3]} />
                            <ClinTextInput
                              labelFontWeight={ClinTheme.fontWeights.normal}
                              isPhone={true}
                              width="100%"
                              id="recipients-phone"
                              name="recipientsPhone"
                              label={t(
                                'checkout_options:delivery_contact_phone'
                              )}
                              hasError={!!fieldErrors.phoneError}
                              prompt={fieldErrors.phoneError ?? ''}
                              value={editForm.recipientPhone}
                              required={true}
                              onPhoneChange={(formattedValue) =>
                                setEditForm({
                                  ...editForm,
                                  recipientPhone: formattedValue
                                })
                              }
                            />
                            <ClinText
                              marginTop={ClinTheme.space[2]}
                              fontSize={ClinTheme.fontSizes[0]}
                              lineHeight={ClinTheme.lineHeights.small}
                              fontWeight={ClinTheme.fontWeights.normal}
                            >
                              Please include the area code
                            </ClinText>
                          </Col>
                        </Row>
                        <ClinSpacer height={ClinTheme.space[5]} />
                        <Row>
                          <Col xs={12} sm={12}>
                            <>
                              <ClinText
                                as="h5"
                                fontSize={ClinTheme.fontSizes[1]}
                                marginBottom={ClinTheme.space[2]}
                                marginTop={'0px'}
                              >
                                {t(
                                  'checkout_options:checkout_options.address_select_title'
                                )}
                              </ClinText>
                              <StyledDeliveryAddressCheckoutWrapper
                                id="scrollView"
                                className="deliveryAddressWrapper"
                              >
                                {deliveryAddresses?.map((address, index) => (
                                  <div key={address.addressId}>
                                    <StyledClinRadio
                                      label={`${address.addressId}-index`}
                                      id={`${address.addressId}-index`}
                                      checked={
                                        address.addressId === selectedAddressId
                                      }
                                      isLast={
                                        index === deliveryAddresses.length - 1
                                      }
                                      isCompact={true}
                                      onClick={() =>
                                        handleChangeAddress &&
                                        handleChangeAddress(address.addressId)
                                      }
                                    >
                                      <Row>
                                        <Col>
                                          <DeliveryAddress
                                            address={address}
                                            isCompact={true}
                                          />
                                        </Col>
                                      </Row>
                                    </StyledClinRadio>
                                  </div>
                                ))}
                              </StyledDeliveryAddressCheckoutWrapper>
                            </>
                          </Col>
                        </Row>
                        <StyledDeliveryAddressWrap id="scrollView">
                          <StyledDeliveryAddressNewCheckout as="div">
                            <ClinText
                              as="h5"
                              variant={TypographyVariant.LargeParagraph}
                            >
                              {t(
                                'checkout_options:do_not_see_delivery_options'
                              )}
                            </ClinText>
                          </StyledDeliveryAddressNewCheckout>
                          <StyledNewShippingAddressLinkOneStepCheckout
                            onClick={() =>
                              handleNewShippingAddress &&
                              handleNewShippingAddress()
                            }
                          >
                            {t(
                              'checkout_options:checkout_options.new_shipping_address_btn'
                            )}
                          </StyledNewShippingAddressLinkOneStepCheckout>
                        </StyledDeliveryAddressWrap>
                      </StyledNewCheckoutBlockBody>
                    )}
                    {!isEditing && (
                      <StyledNewCheckoutAdditionalBlock>
                        <Row>
                          <Col sm={15} md={10}>
                            <ClinCheckbox
                              marginRight="16px"
                              labelFontSize={ClinTheme.fontSizes[2]}
                              id="checkbox-id"
                              label="Add delivery note for our customer service team"
                              checked={showDeliveryNotes}
                              onChange={(e) =>
                                setShowDeliveryNotes(e.target.checked)
                              }
                            />
                            {showDeliveryNotes && (
                              <>
                                <StyledCheckoutTextareaContainer>
                                  <ClinSpacer height={ClinTheme.space[4]} />
                                  <ClinTextarea
                                    labelFontSize={ClinTheme.fontSizes[0]}
                                    id="textarea-id"
                                    name="textarea-name"
                                    label="Delivery notes"
                                    placeholder="Our pharmacy only accepts deliveries before 5:30pm"
                                    value={deliveryNote}
                                    onChange={(e) =>
                                      handleDeliveryNotesChange &&
                                      handleDeliveryNotesChange(e.target.value)
                                    }
                                  />
                                </StyledCheckoutTextareaContainer>
                                <ClinText
                                  fontSize={ClinTheme.fontSizes[0]}
                                  marginTop={ClinTheme.space[2]}
                                  letterSpacing={0}
                                >
                                  Please do not include sensitive patient
                                  details in this field
                                </ClinText>
                              </>
                            )}
                          </Col>
                        </Row>
                        {!isEditing && estimatedDeliveryDate && (
                          <StyledCheckoutDeliveryDateContainer>
                            <StyledCheckoutDeliveryDateIconContainer>
                              <ClinIcon
                                viewBox="0 0 37 35"
                                iconHeight={'32px'}
                                iconSize={'32px'}
                                iconName={ClinIconPathName.DeliveryBox}
                                iconFill={ClinTheme.colors.primary}
                              />
                            </StyledCheckoutDeliveryDateIconContainer>
                            <StyledCheckoutDeliveryDateContent>
                              <ClinText
                                fontSize={ClinTheme.fontSizes[0]}
                                lineHeight={ClinTheme.lineHeights.small}
                                fontWeight={ClinTheme.fontWeights.medium}
                              >
                                Estimated delivery
                              </ClinText>
                              <ClinText
                                fontSize={ClinTheme.fontSizes[1]}
                                fontWeight={ClinTheme.fontWeights.medium}
                                lineHeight={ClinTheme.lineHeights.body}
                                color={ClinTheme.colors.primaryMid}
                              >
                                {DateTime.fromISO(estimatedDeliveryDate, {
                                  zone: 'utc'
                                })
                                  .setLocale(i18n.language)
                                  .toFormat('cccc, MMMM d')}
                              </ClinText>
                              <ClinText
                                fontSize={ClinTheme.fontSizes[0]}
                                fontWeight={ClinTheme.fontWeights.normal}
                                lineHeight="16px"
                              >
                                Delivery estimates are based on all
                                documentation being provided at point of sale,
                                customs clearance provision and stock
                                availability
                              </ClinText>
                            </StyledCheckoutDeliveryDateContent>
                          </StyledCheckoutDeliveryDateContainer>
                        )}
                      </StyledNewCheckoutAdditionalBlock>
                    )}
                  </StyledNewCheckoutBlock>
                  <ClinSpacer height={ClinTheme.space[4]} />
                  <StyledCheckoutPoBlock>
                    <Row>
                      <Col sm={12} md={8}>
                        <ClinTextInput
                          labelFontSize={ClinTheme.fontSizes[3]}
                          labelLineHeight={ClinTheme.lineHeights.body}
                          labelFontWeight={ClinTheme.fontWeights.medium}
                          width="100%"
                          id="po-number"
                          name="poNumber"
                          label="Provide Purchase Order (optional)"
                          required={true}
                          hasError={!!errorMessage}
                          prompt={errorMessage ?? ''}
                          defaultValue={''}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handlePoChange &&
                            handlePoChange(event.currentTarget.value)
                          }
                        />
                      </Col>
                    </Row>
                  </StyledCheckoutPoBlock>
                </Col>
                <Col xs={12} lg={4}>
                  <StyledSummaryOneStepCheckout
                    style={{
                      padding: ClinTheme.space[5],
                      border: `2px solid ${ClinTheme.colors.black}`,
                      borderRadius: '8px'
                    }}
                  >
                    <StyledSummaryTitle>
                      <ClinText
                        as="span"
                        variant={TypographyVariant.LargerParagraph}
                        fontWeight={ClinTheme.fontWeights.medium}
                        lineHeight={`${ClinTheme.space[4]}px`}
                      >
                        {t('basket:summary')}
                      </ClinText>
                    </StyledSummaryTitle>
                    <ClinSpacer height={16} />
                    <StyledTableContainer>
                      <TableHeader>
                        <QuantityCell style={{ paddingLeft: 0 }}>
                          <ClinText
                            variant={TypographyVariant.SmallUI}
                            lineHeight={`${ClinTheme.space[3]}px`}
                          >
                            {t('basket:quantity')}
                          </ClinText>
                        </QuantityCell>
                        <SkuCell>
                          <ClinText
                            variant={TypographyVariant.SmallUI}
                            lineHeight={`${ClinTheme.space[3]}px`}
                          >
                            {t('basket:sku')}
                          </ClinText>
                        </SkuCell>
                        <SubtotalCell style={{ paddingRight: 0 }}>
                          <ClinText
                            variant={TypographyVariant.SmallUI}
                            lineHeight={`${ClinTheme.space[3]}px`}
                            textAlign="right"
                          >
                            {t('basket:subtotal')}
                          </ClinText>
                        </SubtotalCell>
                      </TableHeader>

                      {basket?.items.map((item) => (
                        <TableRow key={item.basketItemId}>
                          <QuantityCell style={{ paddingLeft: 0 }}>
                            <ClinText
                              variant={TypographyVariant.Paragraph}
                              lineHeight={`${ClinTheme.space[4]}px`}
                            >
                              {item.quantity}
                              {'x'}
                            </ClinText>
                          </QuantityCell>
                          <SkuCell>
                            <ClinText
                              variant={TypographyVariant.Paragraph}
                              lineHeight={`${ClinTheme.space[4]}px`}
                            >
                              {item.skuCatalogItem?.genericDosageConcatenation}
                            </ClinText>
                          </SkuCell>
                          <SubtotalCell style={{ paddingRight: 0 }}>
                            <ClinText
                              textAlign="right"
                              as={'h5'}
                              lineHeight={`${ClinTheme.space[3]}px`}
                              fontWeight={ClinTheme.fontWeights.medium}
                              variant={TypographyVariant.Paragraph}
                            >
                              {!item.amount
                                ? '--'
                                : numberToCurrencyString(
                                    item.amount,
                                    getBrowserLocale(),
                                    {
                                      style: 'currency',
                                      currency:
                                        item.priceAndAvailability?.currencyCode,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }
                                  )}
                            </ClinText>
                          </SubtotalCell>
                        </TableRow>
                      ))}
                    </StyledTableContainer>

                    <ClinSpacer hasBorder={true} />
                    <ClinSpacer height={16} />

                    {/* Display regular order lines */}
                    {order?.lines
                      .filter(
                        (o) => o.medicationFlag !== 'Y' && !isDataLogger(o)
                      )
                      .map((orderLine: OrderLineDto, index: number) => (
                        <Fragment
                          key={`order-summary-${order.orderNumber}-${index}`}
                        >
                          <ClinGroup
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <StyledSummaryItemWrap>
                              <ClinText
                                variant={TypographyVariant.Paragraph}
                                lineHeight={`${ClinTheme.space[3]}px`}
                                marginTop={'0px'}
                                marginBottom={'0px'}
                              >
                                {updateOrderLineDescription(orderLine)}
                              </ClinText>
                            </StyledSummaryItemWrap>

                            <ClinText
                              as={'h5'}
                              variant={TypographyVariant.Paragraph}
                              lineHeight={`${ClinTheme.space[3]}px`}
                              fontWeight={ClinTheme.fontWeights.medium}
                            >
                              {numberToCurrencyString(
                                orderLine.unitSellingPrice,
                                getBrowserLocale(),
                                {
                                  style: 'currency',
                                  currency: order?.currencyCode,
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                }
                              )}
                            </ClinText>
                          </ClinGroup>
                          <ClinSpacer height={16} />
                        </Fragment>
                      ))}

                    {/* Display data logger charges from all orders */}
                    {getDataLoggerCharges().map((dataLoggerLine, index) => (
                      <Fragment key={`data-logger-${index}`}>
                        <ClinGroup
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <StyledSummaryItemWrap>
                            <ClinText
                              variant={TypographyVariant.Paragraph}
                              lineHeight={`${ClinTheme.space[3]}px`}
                              marginTop={'0px'}
                              marginBottom={'0px'}
                              fontWeight={ClinTheme.fontWeights.normal}
                            >
                              Cold Chain Temp Tracker
                            </ClinText>
                          </StyledSummaryItemWrap>

                          <ClinText
                            as={'h5'}
                            variant={TypographyVariant.Paragraph}
                            lineHeight={`${ClinTheme.space[3]}px`}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {numberToCurrencyString(
                              dataLoggerLine.totals.subTotal,
                              getBrowserLocale(),
                              {
                                style: 'currency',
                                currency: order?.currencyCode,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            )}
                          </ClinText>
                        </ClinGroup>
                        <ClinSpacer height={16} />
                      </Fragment>
                    ))}

                    <ClinSpacer height={8} />

                    <ClinGroup
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <StyledSummaryItemWrap>
                        <ClinText
                          variant={TypographyVariant.Paragraph}
                          lineHeight={`${ClinTheme.space[3]}px`}
                          marginTop={'0px'}
                          marginBottom={'0px'}
                        >
                          {t('ma_order_detail:tax')}
                        </ClinText>
                      </StyledSummaryItemWrap>

                      <ClinText
                        as={'h5'}
                        variant={TypographyVariant.Paragraph}
                        lineHeight={`${ClinTheme.space[3]}px`}
                        fontWeight={ClinTheme.fontWeights.medium}
                      >
                        {!isTaxAvailable()
                          ? '--'
                          : numberToCurrencyString(
                              calculateTotalTaxFromAllOrders(),
                              getBrowserLocale(),
                              {
                                style: 'currency',
                                currency: basket?.currencyCode,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            )}
                      </ClinText>
                    </ClinGroup>

                    <ClinSpacer height={16} />
                    <ClinGroup justifyContent="space-between">
                      <div>
                        <ClinText
                          variant={TypographyVariant.LargerParagraph}
                          lineHeight={`${ClinTheme.space[4]}px`}
                          fontWeight={ClinTheme.fontWeights.medium}
                        >
                          {t('checkout_options:checkout_options.total')}
                        </ClinText>
                      </div>

                      <ClinText
                        as={'h5'}
                        variant={TypographyVariant.H4}
                        lineHeight={`${ClinTheme.space[5]}px`}
                        fontWeight={ClinTheme.fontWeights.medium}
                        color={ClinTheme.colors.black}
                      >
                        {!basket?.total
                          ? '--'
                          : numberToCurrencyString(
                              calculateTotalFromAllOrders(),
                              getBrowserLocale(),
                              {
                                style: 'currency',
                                currency: order?.currencyCode,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            )}
                      </ClinText>
                    </ClinGroup>

                    <StyledDeclarationCheckoutBlockBody>
                      <Row>
                        <Col>
                          <ClinGroup
                            alignItems="flex-start"
                            justifyContent="space-between"
                          >
                            <ClinCheckbox
                              marginRight={'0px'}
                              onClick={(
                                event: React.MouseEvent<HTMLInputElement>
                              ) => setAgreedTandCs(event.currentTarget.checked)}
                              id="supply-declaration"
                            />
                            <ClinText fontSize={ClinTheme.fontSizes[0]}>
                              <Trans
                                i18nKey={'basket:declaration.confirmation_text'}
                                components={{
                                  embedLink: (
                                    <a
                                      target="_blank"
                                      href={unlicensedMedicinalProductsGuidance}
                                      rel="noreferrer"
                                      style={{
                                        color: ClinTheme.colors.vibrantBlue
                                      }}
                                    ></a>
                                  )
                                }}
                                values={[
                                  <a
                                    href={unlicensedMedicinalProductsGuidance}
                                    style={{
                                      color: ClinTheme.colors.vibrantBlue
                                    }}
                                  ></a>
                                ]}
                                shouldUnescape={true}
                              ></Trans>
                            </ClinText>
                          </ClinGroup>
                          <ClinText
                            fontSize={ClinTheme.fontSizes[0]}
                            marginTop={ClinTheme.space[3]}
                            marginLeft={'42px'}
                          >
                            {t('basket:declaration.confirmation_text_2')}
                          </ClinText>
                        </Col>
                      </Row>
                    </StyledDeclarationCheckoutBlockBody>
                    <ClinButton
                      variant="primary"
                      onClick={() => handleOrderSubmitDebounced()}
                      disabled={!agreedTandCs}
                    >
                      {t('checkout_options:checkout_options.place_order_btn')}
                    </ClinButton>
                  </StyledSummaryOneStepCheckout>
                </Col>
              </Row>
            </StyledNewCheckoutOrder>
          </ClinPageContentFrame>
        </>
      )}
    </ClinPageContentFrame>
  )
}
