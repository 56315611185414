import React, { FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  StyledActions,
  StyledOverlayLoader,
  StyledSubmit
} from './DiscontinueModal.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { ClinButton } from '../../../components/ClinButton'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinLoader } from '../../../components/ClinLoader'
import { ClinModal } from '../../../components/ClinModal'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'

interface IDiscontinueModalProps {
  /** Control internal state of modal **/
  isOpen?: boolean
  /** Displays an error notification **/
  hasError?: boolean
  /** Displays error message **/
  errorMessage?: string
  /** Whether the submission is still processing **/
  isSubmitting: boolean
  /** Handle confirmation of dissociation  **/
  handleConfirmation: () => void
  /** Handle close modal **/
  handleClose: () => void
}

export const DiscontinueModal: FunctionComponent<IDiscontinueModalProps> = ({
  isOpen,
  hasError,
  errorMessage,
  isSubmitting,
  handleConfirmation,
  handleClose
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ClinModal
        onClose={handleClose}
        maxWidth="sm"
        height="auto"
        isOpen={isOpen}
      >
        <Container className="dissociate-physician-confirmation">
          <Row justify="center">
            <Col sm={12} md={11}>
              {errorMessage && hasError && (
                <Row>
                  <Col>
                    <ClinAnnounceBar title={'Error'}>
                      {errorMessage}
                    </ClinAnnounceBar>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={12}>
                  <ClinText
                    as="h3"
                    variant={TypographyVariant.H3}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {t('physician_details:discontinue_modal_title')}
                  </ClinText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ClinText variant={TypographyVariant.LargeParagraph}>
                    {t('physician_details:discontinue_modal_description')}
                  </ClinText>
                </Col>
              </Row>

              <StyledSubmit>
                <Row justify="between">
                  <Col md={5} lg={6}>
                    <StyledActions>
                      <ClinButton
                        className="cancel-disassociation"
                        onClick={() => handleClose()}
                      >
                        {t('common:buttons.cancel')}
                      </ClinButton>
                      <ClinButton
                        className="confirm"
                        variant="primary"
                        onClick={() => handleConfirmation()}
                      >
                        {t('common:buttons.confirm')}
                      </ClinButton>
                    </StyledActions>
                  </Col>
                </Row>
              </StyledSubmit>
            </Col>
          </Row>
        </Container>
        {isSubmitting && (
          <StyledOverlayLoader>
            <Container>
              <Row justify="center" align="center">
                <Col sm={12}>
                  <ClinText
                    marginLeft="auto"
                    marginRight="auto"
                    marginBottom={ClinTheme.space[5]}
                    textAlign="center"
                    variant={TypographyVariant.LargeParagraph}
                  >
                    {t('physician_details:please_wait')}
                  </ClinText>
                  <ClinGroup justifyContent="center">
                    <div
                      style={{
                        height: ClinTheme.space[9] * 2,
                        width: ClinTheme.space[9] * 2
                      }}
                    >
                      <ClinLoader minHandDuration={'1s'} />
                    </div>
                  </ClinGroup>
                </Col>
              </Row>
            </Container>
          </StyledOverlayLoader>
        )}
      </ClinModal>
    </>
  )
}
