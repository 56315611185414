import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { ClinStyledButton } from '../../components/ClinButton/ClinButton.styles'

export const StyledSupportLinks = styled.div({
  ul: {
    paddingLeft: 0,

    li: {
      marginBottom: ClinTheme.space[2],
      listStyle: 'none',
      paddingLeft: 0
    }
  }
})

export const StyleTranscriptDownload = styled.div({
  marginTop: ClinTheme.space[4],

  [`${ClinStyledButton}.clin-button-ghost`]: {
    padding: 0,
    boxShadow: 'none',
    background: 'none',

    [`:hover`]: {
      background: 'none'
    }
  }
})
