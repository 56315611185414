import React, { FunctionComponent } from 'react'

import { StyledAppLoader, StyledLoadingText } from './AppLoader.styles'
import { ClinLoader } from '../../components/ClinLoader'

interface IAppLoaderProps {
  /** The text to display while loading */
  loadingText?: string
}

export const AppLoader: FunctionComponent<IAppLoaderProps> = ({
  loadingText
}) => (
  <StyledAppLoader>
    <ClinLoader />
    <StyledLoadingText>{loadingText}</StyledLoadingText>
  </StyledAppLoader>
)
