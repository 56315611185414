import React, { FunctionComponent, useEffect, useState } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  StyledBurgerButton,
  StyledButton,
  StyledButtonContainer,
  StyledContainer
} from './BookmarkedConfirmation.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'

interface IBookmarkedConfirmationProps {
  closeConfirmation?: () => void
  bookmarksBtnMenu?: HTMLElement | null
}

export const BookmarkedConfirmation: FunctionComponent<
  IBookmarkedConfirmationProps
> = ({ closeConfirmation, bookmarksBtnMenu }) => {
  const { t } = useTranslation()

  const [position, setPosition] = useState<{
    top: number
    left: number
  } | null>(null)

  useEffect(() => {
    if (bookmarksBtnMenu) {
      const rect = bookmarksBtnMenu.getBoundingClientRect()
      setPosition({
        top: rect.bottom + window.scrollY - 130,
        left: rect.left + window.scrollX - 26
      })
    }
  }, [])

  return (
    <StyledContainer
      style={{
        position: 'absolute',
        top: position?.top,
        left: position?.left,
        width: '210px'
      }}
    >
      <Row>
        <Col
          sm={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <ClinText
            fontWeight={ClinTheme.fontWeights.medium}
            fontSize={ClinTheme.fontSizes[1]}
            marginTop={ClinTheme.fontSizes[0]}
            color={ClinTheme.colors.white}
            whiteSpace="nowrap"
          >
            {t('product_variants:bookmark_saved')}
          </ClinText>
          <StyledBurgerButton
            onClick={() => {
              closeConfirmation && closeConfirmation()
            }}
          >
            <ClinIcon
              iconName={ClinIconPathName.BurgerClosed}
              iconSize={ClinTheme.fontSizes[4]}
              iconFill={ClinTheme.colors.white}
            />
          </StyledBurgerButton>
        </Col>
      </Row>

      <ClinText
        fontSize={ClinTheme.fontSizes[1]}
        color={ClinTheme.colors.white}
        marginTop={ClinTheme.fontSizes[4]}
      >
        {t('product_variants:bookmark_helper')}
      </ClinText>
      <ClinSpacer height="4px" />
      <StyledButtonContainer>
        <StyledButton variant="linkButton" onClick={closeConfirmation}>
          {t('product_variants:bookmark_helper_confirm')}
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  )
}
