import { rgba } from 'polished'
import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'
import { mediaQueryDown } from '../../utils/mediaQuery'

const breakpoint = ClinTheme.breakpoints[3]
const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export interface IStyledOrderItemProps {
  hasBorder: boolean
}

interface IStyledSidebarItem {
  isMinimized: boolean
}

export const StyledOrderItem = styled.div<IStyledOrderItemProps>(
  {
    [`.accordion button`]: {
      borderBottom: 0,
      paddingLeft: 0
    },
    [`.accordion button > span`]: {
      fontSize: ClinTheme.fontSizes[3],
      fontWeight: ClinTheme.fontWeights.light,
      color: ClinTheme.colors.black
    },
    [`.accordion__item`]: {
      border: 0,
      marginBottom: `${ClinTheme.space[2]}px`
    }
  },
  ({ hasBorder }) => ({
    borderBottom: hasBorder
      ? `1px ${rgba(ClinTheme.colors.primaryLight, 0.6)} solid`
      : 0
  })
)

export const StyledOrderItemLayout = styled.div({
  paddingLeft: `${ClinTheme.space[5]}px`,
  paddingRight: 0,
  paddingTop: `${ClinTheme.space[6]}px`,
  paddingBottom: `${ClinTheme.space[3]}px`,
  [mediaQueryDown(mobileUpBreakpoint)]: {
    paddingTop: `${ClinTheme.space[4]}px`,
    paddingLeft: `${ClinTheme.space[4]}px`,
    paddingBottom: 0
  }
})

export const StyledOrderItemSidebar = styled.div({
  display: 'flex',
  flexDirection: 'column',
  background: ClinTheme.colors.lightGrey,
  paddingLeft: `${ClinTheme.space[5]}px`,
  paddingRight: `${ClinTheme.space[5]}px`,
  paddingBottom: 0,
  paddingTop: `${ClinTheme.space[6]}px`,
  boxSizing: 'border-box',
  height: '100%',
  [mediaQueryDown(mobileUpBreakpoint)]: {
    paddingTop: `${ClinTheme.space[4]}px`,
    paddingLeft: `${ClinTheme.space[4]}px`
  },
  '.amount-spacer': {
    marginTop: 0,
    ':before': {
      background: ClinTheme.colors.grey
    }
  }
})

export const StyledItemProperty = styled.div({
  display: 'flex',
  marginBottom: ClinTheme.space[3]
})

export const StyledItemPropertyDetail = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: ClinTheme.space[1],
  marginLeft: ClinTheme.space[2]
})

export const StyledSidebarItem = styled.div<IStyledSidebarItem>(
  {},
  ({ isMinimized }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingBottom: isMinimized ? ClinTheme.space[3] : ClinTheme.space[9],
    [`.clin-text`]: {
      paddingBottom: `${ClinTheme.space[2]}px`
    },
    [mediaQueryDown(breakpoint)]: {
      paddingBottom: isMinimized ? ClinTheme.space[3] : ClinTheme.space[5],
      [`.clin-text`]: {
        paddingBottom: `${ClinTheme.space[2]}px`
      }
    },
    [mediaQueryDown(mobileUpBreakpoint)]: {
      paddingBottom: ClinTheme.space[3]
    }
  })
)

export const StyledSidebarAmount = styled.div<IStyledSidebarItem>(
  {},
  ({ isMinimized }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: ClinTheme.space[4],
    paddingBottom: isMinimized ? ClinTheme.space[3] : ClinTheme.space[9],

    [`.clin-text`]: {
      paddingBottom: 0,

      '&:first-of-type': {
        paddingBottom: `${ClinTheme.space[1]}px`
      }
    },
    [mediaQueryDown(breakpoint)]: {
      display: 'block',
      paddingBottom: isMinimized ? ClinTheme.space[3] : ClinTheme.space[5],
      [`.clin-text`]: {
        paddingBottom: `${ClinTheme.space[2]}px`
      }
    },
    [mediaQueryDown(mobileUpBreakpoint)]: {
      paddingBottom: ClinTheme.space[3]
    }
  })
)

export const StyledProductColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: `${ClinTheme.space[5]}px`,
  [`.clin-text`]: {
    marginBottom: `${ClinTheme.space[2]}px`
  },
  [mediaQueryDown(mobileUpBreakpoint)]: {
    marginBottom: `${ClinTheme.space[3]}px`
  }
})
