import styled from 'styled-components'

import { ClinTheme } from '../../../ClinTheme'

export interface IStyledAutoSuggestResultItemProps {
  /** Show highlight styles */
  selected?: boolean
  /** Add border radious on last li element for suggestions */
  borderRadiusLastLiElement?: string
  /** Optional parametar padding for suggestion text*/
  suggestionTextPadding?: string
}

export const StyledAutoSuggestWrapper = styled.div({
  flex: '1 1 auto'
})

export const StyledAutoSuggestResultItem =
  styled.li<IStyledAutoSuggestResultItemProps>(
    {
      fontSize: ClinTheme.fontSizes[3],
      fontWeight: ClinTheme.fontWeights.light,
      color: ClinTheme.colors.primary,
      backgroundColor: ClinTheme.colors.white,
      borderBottom: `solid 1px ${ClinTheme.colors.grey}`,
      [`a`]: {
        display: 'block',
        color: ClinTheme.colors.primary
      },
      [`:hover, :focus`]: {
        backgroundColor: ClinTheme.colors.lightGrey
      },
      [`:last-child`]: {
        borderBottom: 'none'
      }
    },
    ({ selected }) => ({
      backgroundColor: selected
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.white
    }),
    ({ suggestionTextPadding }) => ({
      padding: suggestionTextPadding
        ? suggestionTextPadding
        : `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`
    }),
    ({ borderRadiusLastLiElement }) => ({
      [`:last-child`]: {
        borderBottom: borderRadiusLastLiElement ? '1px solid white' : 'none',
        borderBottomLeftRadius: borderRadiusLastLiElement
          ? `${borderRadiusLastLiElement}px`
          : 0,
        borderBottomRightRadius: borderRadiusLastLiElement
          ? `${borderRadiusLastLiElement}px`
          : 0
      }
    })
  )

export const StyledNoResults = styled.div({
  cursor: 'pointer'
})
