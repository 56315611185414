import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { VialReconcileRow } from './VialReconcileRow'
import {
  StyledTableHeader,
  StyledVialReconciliationTable,
  StyledVialReconcileHeaderWrapper
} from './VialReconciliationTable.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import {
  IPatientVial,
  VialRecordState,
  VialReconciliationType
} from '../../pages/Patients/PatientDetail/PatientDetail.types'

interface IVialReconciliationTableProps {
  /** Whether we are saving */
  isSavingVial?: boolean
  /** The selected vial or row */
  selectedVial?: IPatientVial
  /** The vials to reconcile */
  patientVials?: IPatientVial[]
  /** The type of unit */
  unitType: string | null
  /** The type of vial reconciliation */
  vialReconciliationType: VialReconciliationType
  /** Show debug form data */
  showDebug?: boolean
  /** Show headers */
  showHeaders?: boolean
  vialRecordStates: VialRecordState[]
  handleVialRecordState: (vial: IPatientVial) => void
  /** Triggered when the date picker is selected */
  handleVialDateInputSelected?: (vial: IPatientVial) => void
  /** Called when vial reconciliation row is saved */
  handleSaveChanges?: (updatedVial: IPatientVial) => void
}

export const VialReconciliationTable: FunctionComponent<
  IVialReconciliationTableProps
> = ({
  isSavingVial,
  selectedVial,
  patientVials = [],
  unitType = '',
  vialReconciliationType,
  showDebug,
  showHeaders = true,
  vialRecordStates,
  handleVialRecordState,
  handleVialDateInputSelected,
  handleSaveChanges
}) => {
  const { t } = useTranslation()

  // If the batch number is missing or its one that requires not entering a batch number then display a narrower column
  const isShortBatchColumn = () =>
    (patientVials?.length > 0 &&
      patientVials[0].batchNumber === '' &&
      vialReconciliationType === VialReconciliationType.DispositionOnly) ||
    vialReconciliationType === VialReconciliationType.DispositionDate

  return (
    <StyledVialReconciliationTable>
      {showHeaders && (
        <>
          <StyledVialReconcileHeaderWrapper>
            <ClinText fontSize={ClinTheme.fontSizes[1]} textAlign={'left'}>
              {t('patient_detail:vial_reconciliation_header')}
            </ClinText>
          </StyledVialReconcileHeaderWrapper>
          <StyledTableHeader>
            <ClinText className={'vial'} variant={TypographyVariant.H5}>
              {unitType?.toUpperCase()} #
            </ClinText>
            <ClinText
              className={isShortBatchColumn() ? 'batch-na' : 'batch'}
              variant={TypographyVariant.H5}
            >
              {t('patient_detail:vial_reconciliation_table.table_header_batch')}
            </ClinText>
            <ClinText className={'status'} variant={TypographyVariant.H5}>
              {t(
                'patient_detail:vial_reconciliation_table.table_header_status'
              )}
            </ClinText>
            <ClinText className={'date'} variant={TypographyVariant.H5}>
              {t(
                'patient_detail:vial_reconciliation_table.table_header_date_actioned'
              )}
            </ClinText>
            <div>&nbsp;</div>
          </StyledTableHeader>
        </>
      )}
      {patientVials?.map((vial, index) => (
        <VialReconcileRow
          rowIndex={`${index}`}
          key={`vial-${vial.vialNumber}-${index}`}
          patientVial={vial}
          isSaving={
            isSavingVial && selectedVial?.vialNumber === vial.vialNumber
          }
          isDisabled={
            isSavingVial && selectedVial?.vialNumber === vial.vialNumber
          }
          vialReconciliationType={vialReconciliationType}
          showDebug={showDebug}
          vialRecordState={vialRecordStates.find(
            (v) => v.vialNumber === vial.vialNumber
          )}
          handleVialRecordState={handleVialRecordState}
          handleSaveChanges={handleSaveChanges}
          handleVialDateInputSelected={handleVialDateInputSelected}
        />
      ))}
    </StyledVialReconciliationTable>
  )
}
