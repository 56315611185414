import React from 'react'

import { State } from './BasketContext'
import { ActionType, ActionTypes } from './BasketContext.actions'
import { BasketStatus, IViewBasketItem } from './BasketContext.types'
import { getHoldsInformationFor } from '../../services/Holds/holds'
import { HoldType } from '../../services/Holds/holds.constants'
import { getVariantInformationForCatalogItem } from '../../services/PortfolioJourneys/PortfolioJourney.model'
import {
  BasketItemDto,
  PriceAndAvailabilityDto
} from '../../types/swaggerTypes'
import { compareTwoDates } from '../../utils/dateUtils'

// Helper functions to handle individual actions
const handleUpdateBasket = (state: State, action: ActionTypes): State => {
  if (action.type !== ActionType.UpdateBasket) return state
  const { basket } = action
  return {
    ...state,
    basket: { ...basket, data: basket.data }
  }
}

const handleEmptyBasket = (state: State): State => {
  return {
    ...state,
    basket: { data: undefined, isLoading: false, error: undefined },
    viewBasket: {
      items: [],
      total: 0,
      currencyCode: state.viewBasket?.currencyCode,
      instituteId: '',
      basketId: '',
      created: '',
      lastUpdated: '',
      state: BasketStatus.Default,
      userId: '',
      checkoutStamp: '',
      orders: []
    }
  }
}

const handleAddBasketItem = (state: State, action: ActionTypes): State => {
  if (action.type !== ActionType.AddBasketItem) return state

  const { viewBasket } = state
  const oldBasket = state.basket.data
  const { quantity, pricing, basketItem } = action

  if (
    !basketItem ||
    !viewBasket ||
    !oldBasket ||
    quantity === undefined ||
    !pricing
  ) {
    return state
  }

  // Update basket items and quantity
  const updatedItems = updateBasketItems(oldBasket.items, basketItem, quantity)

  // Update view basket & add in P&A
  const updatedViewItems = updateViewBasketItems(
    viewBasket.items,
    basketItem,
    quantity,
    pricing
  )

  const addTotal = updatedViewItems.reduce(sumOrdersReducer, 0)

  return {
    ...state,
    viewBasket: {
      ...viewBasket,
      currencyCode: pricing.currencyCode,
      items: updatedViewItems,
      total: addTotal
    },
    basket: {
      ...state.basket,
      data: {
        ...oldBasket,
        items: updatedItems
      }
    }
  }
}

const updateBasketItems = (
  items: BasketItemDto[],
  newItem: BasketItemDto,
  quantity: number
): BasketItemDto[] => {
  const itemExists = items.find((item) => item.sku === newItem.sku)

  if (itemExists) {
    itemExists.quantity += quantity
    return [...items]
  }

  return [...items, newItem]
}

const updateViewBasketItems = (
  items: IViewBasketItem[],
  newItem: BasketItemDto,
  quantity: number,
  pricing: PriceAndAvailabilityDto
): IViewBasketItem[] => {
  const existingItem = items.find((item) => item.sku === newItem.sku)

  if (existingItem) {
    const updatedQuantity = existingItem.quantity + quantity
    existingItem.quantity = updatedQuantity
    existingItem.amount =
      updatedQuantity * (pricing ? parseFloat(pricing?.listPrice) : 0)
    return [...items]
  }

  return [
    ...items,
    {
      ...newItem,
      priceAndAvailability: pricing,
      amount: newItem.quantity * (pricing ? parseFloat(pricing?.listPrice) : 0)
    }
  ]
}

const handleUpdateItemQuantity = (state: State, action: ActionTypes): State => {
  if (action.type !== ActionType.UpdateItemQuantity) return state

  const { viewBasket } = state
  const { basketItem, quantity } = action

  if (!basketItem || !viewBasket || !quantity) {
    return state
  }

  const updatedItem = viewBasket.items.find(
    (item) => item.sku === basketItem.sku
  )

  if (!updatedItem || !updatedItem.priceAndAvailability) {
    return state
  }

  updatedItem.quantity = quantity
  updatedItem.amount =
    parseFloat(updatedItem.priceAndAvailability.listPrice) * quantity

  const updateTotal = viewBasket.items.reduce(sumOrdersReducer, 0)

  return {
    ...state,
    viewBasket: {
      ...viewBasket,
      items: [...viewBasket.items],
      total: updateTotal
    }
  }
}

const handleRemoveBasketItem = (state: State, action: ActionTypes): State => {
  if (action.type !== ActionType.RemoveBasketItem) return state

  const existingBasket = state.viewBasket
  const removeItem = action.basketItem
  const existingBasketDto = state.basket.data

  if (!existingBasket || !existingBasketDto) {
    return state
  }

  const removeItems = existingBasket.items.filter(
    (item) => item.sku !== removeItem.sku
  )
  const removeTotal = removeItems.reduce(sumOrdersReducer, 0)

  return {
    ...state,
    viewBasket: {
      ...existingBasket,
      items: removeItems,
      total: removeTotal
    },
    basket: {
      ...state.basket,
      data: {
        ...existingBasketDto,
        items: existingBasketDto.items.filter(
          (item) => item.basketItemId !== removeItem.basketItemId
        )
      }
    }
  }
}

const handleUpdateBasketItemDocuments = (
  state: State,
  action: ActionTypes
): State => {
  if (action.type !== ActionType.UpdateBasketItemDocuments) return state

  const staleViewBasket = state.viewBasket
  const updatedBasketItemDocs = action.basketItemDocuments

  if (!staleViewBasket || !updatedBasketItemDocs) {
    return state
  }

  return {
    ...state,
    viewBasket: {
      ...staleViewBasket,
      items: staleViewBasket.items.map((item) =>
        item.basketItemId !== action.basketItemId
          ? item
          : {
              ...item,
              documents: action.remove ? [] : [...updatedBasketItemDocs]
            }
      )
    }
  }
}

const handleSetExpectedDeliveryDate = (
  state: State,
  action: ActionTypes
): State => {
  if (action.type !== ActionType.SetExpectedDeliveryDate) return state

  if (!action.countryCode || !state.basket?.data?.items) {
    return {
      ...state,
      expectedDeliveryDate: undefined
    }
  }

  const expectedDeliveryDate = findLatestDeliveryDate(
    state.basket.data.items,
    action.countryCode
  )

  return {
    ...state,
    expectedDeliveryDate
  }
}

const findLatestDeliveryDate = (
  basketItems: BasketItemDto[],
  countryCode: string
): string | undefined => {
  let latestDate: string | undefined = undefined

  basketItems.forEach((item) => {
    const estimatedDate = item.skuCatalogItem.expectedDeliveryDates?.find(
      (date) => date.shipToCountry === countryCode
    )

    if (!estimatedDate?.expectedDeliveryDate) return

    if (!latestDate) {
      latestDate = estimatedDate.expectedDeliveryDate
      return
    }

    latestDate = compareTwoDates(latestDate, estimatedDate.expectedDeliveryDate)
  })

  return latestDate
}

const handleUpdateBasketDetails = (
  state: State,
  action: ActionTypes
): State => {
  if (action.type !== ActionType.UpdateBasketDetails) return state

  if (action.basketDetails.isMaOrder) {
    return {
      ...state,
      basketMADetails: action.basketDetails
    }
  }

  return {
    ...state,
    basketDetails: action.basketDetails
  }
}

const handleSetCurrentBasket = (state: State, action: ActionTypes): State => {
  if (action.type !== ActionType.SetCurrentBasket) return state

  const { priceAndAvailability, basket, countryCode } = action

  if (!priceAndAvailability || !basket) {
    return state
  }

  const augBasketItems = createAugmentedBasketItems(
    basket.items,
    countryCode,
    priceAndAvailability
  )
  const basketTotal = augBasketItems.reduce(subTotalReducer, 0)

  return {
    ...state,
    viewBasket: {
      ...basket,
      items: augBasketItems,
      state: BasketStatus[basket.state as BasketStatus],
      total: basketTotal,
      currencyCode: augBasketItems.length
        ? augBasketItems[0].priceAndAvailability?.currencyCode
        : undefined
    }
  }
}

const createAugmentedBasketItems = (
  basketItems: BasketItemDto[],
  countryCode: string,
  priceAndAvailability: PriceAndAvailabilityDto[]
): IViewBasketItem[] => {
  return basketItems.map((item) => {
    const pricingInfo = priceAndAvailability.find(
      (p) => p.itemNumber === item.sku
    )

    // Add in order-ability and pricing
    const basketItemWithPricing = getIBasketItemWithPricingAndOrderability(
      item,
      countryCode,
      pricingInfo
    )

    // Add holds information
    const basketItemWithHoldInformation =
      basketItemWithPricing.priceAndAvailability &&
      getHoldsInformationFor(
        basketItemWithPricing.priceAndAvailability.holds
      ).filter(
        (doc) =>
          doc.requiresDocumentUpload || doc.type === HoldType.SafetyLetter
      )

    return {
      ...basketItemWithPricing,
      holds: basketItemWithHoldInformation
    }
  })
}

const handlePlaceOrder = (state: State, action: ActionTypes): State => {
  if (action.type !== ActionType.PlaceOrder) return state

  if (!action.orders || !state.viewBasket) {
    return state
  }

  return {
    ...state,
    orders: [...action.orders],
    basket: {
      isLoading: false,
      data: {
        ...action.basket,
        state: BasketStatus.Placing
      }
    },
    viewBasket: {
      ...state.viewBasket,
      state: BasketStatus.Placing
    }
  }
}

const handleOrderSubmitted = (state: State, action: ActionTypes): State => {
  if (action.type !== ActionType.OrderSubmitted) return state

  if (!state.viewBasket) {
    return state
  }

  return {
    ...state,
    basket: { data: undefined, isLoading: false, error: undefined },
    viewBasket: {
      ...state.viewBasket,
      state: BasketStatus.Submitted
    },
    completedOrder: action.completedOrder
  }
}

// Main reducer with simplified switch statement
const basketReducer: React.Reducer<State, ActionTypes> = (
  state: State,
  action: ActionTypes
) => {
  switch (action.type) {
    case ActionType.UpdateBasket:
      return handleUpdateBasket(state, action)

    case ActionType.EmptyBasket:
      return handleEmptyBasket(state)

    case ActionType.AddBasketItem:
      return handleAddBasketItem(state, action)

    case ActionType.UpdateItemQuantity:
      return handleUpdateItemQuantity(state, action)

    case ActionType.RemoveBasketItem:
      return handleRemoveBasketItem(state, action)

    case ActionType.UpdateBasketItemDocuments:
      return handleUpdateBasketItemDocuments(state, action)

    case ActionType.SetExpectedDeliveryDate:
      return handleSetExpectedDeliveryDate(state, action)

    case ActionType.UpdateBasketDetails:
      return handleUpdateBasketDetails(state, action)

    case ActionType.SetCurrentBasket:
      return handleSetCurrentBasket(state, action)

    case ActionType.PlaceOrder:
      return handlePlaceOrder(state, action)

    case ActionType.OrderSubmitted:
      return handleOrderSubmitted(state, action)

    case ActionType.ClearCompletedOrder:
      return {
        ...state,
        completedOrder: undefined
      }

    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

/**
 * Reducer to sum up the amounts in a basket item
 * @param sum
 * @param current
 */
export const sumOrdersReducer = (sum: number, current: IViewBasketItem) =>
  sum +
  (current.priceAndAvailability
    ? current.quantity * parseFloat(current.priceAndAvailability.listPrice)
    : 0)

/**
 * Reducer to sum up the amounts in a basket item to a total
 * @param sum
 * @param current
 */
export const subTotalReducer = (sum: number, current: IViewBasketItem) =>
  sum + current.amount

/**
 * Utility to merge pricing into a basketItem
 * @param basketItem
 * @param countryCode
 * @param priceAndAvailability
 */
export const getIBasketItemWithPricingAndOrderability = (
  basketItem: BasketItemDto,
  countryCode: string,
  priceAndAvailability?: PriceAndAvailabilityDto
): IViewBasketItem => {
  const { cannotOrder, uiVariantState } = getVariantInformationForCatalogItem(
    basketItem.skuCatalogItem,
    countryCode
  )
  let amount

  if (cannotOrder) {
    amount = 0
  } else if (priceAndAvailability) {
    amount = parseFloat(priceAndAvailability.listPrice) * basketItem.quantity
  } else {
    amount = 0
  }
  return {
    ...basketItem,
    priceAndAvailability,
    uiVariantState: uiVariantState,
    unavailable: cannotOrder,
    amount: amount
  }
}

export { basketReducer }
