import { AxiosError, AxiosResponse } from 'axios'
import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, StaticContext } from 'react-router'
import { matchPath } from 'react-router-dom'

import { OrderDetail } from './OrderDetail'
import {
  checkCanOrderAgain,
  getUpdatedOrderState,
  getCarrierType,
  scrollToTrackingLinkKey
} from './OrderDetail.model'
import {
  AnnounceMode,
  IClinAnnounceBarProps
} from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { OrderCancellationCodes } from '../../../constants'
import { useAppContext } from '../../../context/app'
import { addBasketItemAsync, useBasket } from '../../../context/basket'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import {
  createConfirmationEvent,
  clearConfirmationEvent
} from '../../../events/ConfirmationEvent'
import { AddedToBasketConfirmation } from '../../../features/AddedToBasketConfirmation'
import { IBasketItemDetails } from '../../../features/AddedToBasketConfirmation/AddedToBasketConfirmation'
import { OrderLineCancellationModal } from '../../../features/OrderLineCancellationModal'
import { useEffectOnlyOnce } from '../../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { AnalyticsEvent } from '../../../services/Analytics'
import { AnalyticsPageEvent } from '../../../services/Analytics/AnalyticsPageEvent'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import {
  AuthError,
  cancelGetOrderById,
  cancelSetDocumentToHold,
  getOrderById,
  getPriceAndAvailabilityForSku,
  removeOrderLine,
  requestOrderInvoice,
  setDocumentToHold,
  upsertSkuItemToBasket
} from '../../../services/ApiService'
import {
  getHoldDocumentForHold,
  useOrderLevelHolds
} from '../../../services/Holds/holds'
import { getVariantInformationForCatalogItem } from '../../../services/PortfolioJourneys/PortfolioJourney.model'
import { IAugmentedCatalogItemDto } from '../../../services/PortfolioJourneys/PortfolioJourney.types'
import { IError } from '../../../types'
import { findOrderCancellationLookupCode } from '../../../types/IOrder'
import { IRestEndpointState } from '../../../types/IRestEndpointState'
import { OrderStatus } from '../../../types/OrderStatus'
import {
  BasketDto,
  DeleteOrderDto,
  HoldDto,
  InstituteDto,
  OrderDto,
  OrderLineDto,
  UploadedDocumentDto
} from '../../../types/swaggerTypes'
import { resetBasket } from '../../../utils/basketUtils'
import { useFeatureFlags } from '../../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../../context/featureFlags/FeatureFlagKeys'

type LocationState = {
  from: string
  orderDto?: OrderDto
}

export interface IOrderDetailProps
  extends RouteComponentProps<{}, StaticContext, LocationState> {}

export const OrderDetailContainer: FunctionComponent<IOrderDetailProps> = ({
  history,
  location
}) => {
  const { t } = useTranslation()
  const { useFeatureFlag } = useFeatureFlags()
  const isNewCheckoutPage = useFeatureFlag(
    FeatureFlagKeys.NewOneStepCheckoutPage
  )
  const { orderDto } = location.state
  const matchPatientDetails =
    history.location.state &&
    matchPath(history.location.state.from, {
      path: `/programs/my-physicians/:physicianId/:patientId`
    })

  const backButtonTitle = matchPatientDetails
    ? t('order_detail:back_to_patient_details_btn')
    : t('order_detail:back_to_my_dashboard_btn')
  const {
    dispatch,
    defaultShippingAddress,
    portfolioCountryCode,
    supportContact,
    institute,
    user,
    userDetails
  } = useAppContext()
  const [{ viewBasket }, basketDispatch] = useBasket()
  const [order, setOrder] = React.useState<OrderDto | undefined>(orderDto)
  const orderLevelHolds = useOrderLevelHolds(undefined, order, supportContact)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isAddingToBasket, setAddingToBasket] = React.useState<boolean>(false)
  const [canAllItemsBeOrdered, setCanAllItemsBeOrdered] =
    React.useState<boolean>(true)
  const [orderAnnounce, setOrderAnnounce] = React.useState<
    IClinAnnounceBarProps | undefined
  >()
  const [orderLine, setOrderLine] = React.useState<OrderLineDto>()
  const [quantity, setQuantity] = React.useState<number>(0)
  const [unitPrice, setUnitPrice] = React.useState<number>(0)

  const [isCancelOrderlineModalOpen, setIsCancelOrderlineModalOpen] =
    React.useState<boolean>(false)
  const [selectedOrderLine, setSelectedOrderLine] = React.useState<
    OrderLineDto | undefined
  >()
  const [deleteOrderDescription, setDeleteOrderDescription] = React.useState<
    DeleteOrderDto | undefined
  >()
  const [cancellationSubmitting, setCancellationSubmitting] =
    React.useState<boolean>(false)

  const cancellationOptions =
    OrderCancellationCodes && Object.values(OrderCancellationCodes)
  const [cancellationHasError, setCancellationHasError] =
    React.useState<IError>()

  const handleGetOrder = useCallback(() => {
    if (order) {
      setIsLoading(true)
      getOrderById(order.orderNumber)
        .then((response) => {
          setOrder(response.data)
          setCanAllItemsBeOrdered(
            checkCanOrderAgain(response.data, portfolioCountryCode)
          )
        })
        .catch((error) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the order. ${error}`
            )
          )
          analyticsServiceSingleton.trackError(
            error,
            JSON.parse(localStorage.getItem('current_user') || '{}')
          )
        })
        .finally(() => setIsLoading(false))
    }
  }, [dispatch, order, portfolioCountryCode])

  const handleBack = () => {
    if (
      (history.location.state && history.location.state.from === '/orders') ||
      matchPatientDetails
    ) {
      history.goBack()
    } else {
      history.push('/orders')
    }
  }
  const handleTrackingLinkClicked = (
    orderLine: OrderLineDto,
    currentLanguage: string
  ) => {
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.DeliveryTracking, {
      carrierName: orderLine.trackingInfo?.freightCarrier,
      instituteId: user?.institute?.instituteId,
      language: currentLanguage,
      location: history.location.pathname
    })
  }

  const handleCancelOrderLine = (orderLine: OrderLineDto) => {
    const updateOrderLineDto: DeleteOrderDto = {
      cancelReason: '',
      cancelComments: ''
    }
    setDeleteOrderDescription(updateOrderLineDto)
    setIsCancelOrderlineModalOpen(true)
    setSelectedOrderLine(orderLine)
  }

  const handleCancellationOptionChange = (id: string) => {
    if (deleteOrderDescription) {
      const updateDeleteOrderDesc: DeleteOrderDto = {
        ...deleteOrderDescription,
        cancelReason: id ? findOrderCancellationLookupCode[id] : ''
      }
      setDeleteOrderDescription(updateDeleteOrderDesc)
    }
  }

  const handleCancellationTextChange = (comment: string) => {
    if (deleteOrderDescription) {
      const updateOrderLineDto: DeleteOrderDto = {
        ...deleteOrderDescription,
        cancelComments: comment
      }
      setDeleteOrderDescription(updateOrderLineDto)
    }
  }

  const handleSubmitCancellation = () => {
    if (
      order &&
      deleteOrderDescription &&
      selectedOrderLine &&
      selectedOrderLine?.lineId
    ) {
      setCancellationSubmitting(true)
      removeOrderLine(
        order.orderNumber,
        selectedOrderLine?.lineId.toString(),
        deleteOrderDescription
      )
        .then((response) => {
          if (response.status === 200) {
            handleGetOrder()
            setOrderAnnounce({
              title: ' ',
              message: t('order_detail:successful_cancellation'),
              mode: AnnounceMode.Success,
              isDismissible: true
            })
            setIsCancelOrderlineModalOpen(false)
            selectedOrderLine.lineId &&
              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.CancelOrderedItem,
                {
                  orderId: order.orderNumber,
                  itemId: selectedOrderLine.lineId.toString()
                }
              )
            if (response.data.lines.length === 0) {
              history.push('/orders')
            }
            if (
              response.data.lines.filter(
                (item) => item.status === OrderStatus.Cancelled
              ).length === response.data.lines.length
            ) {
              history.push('/orders')
            }
          }
        })
        .catch((error) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }

          setCancellationHasError({
            hasError: true,
            errorMessage: `${t('order_detail:error_cancelling')} ${error}`
          })
          analyticsServiceSingleton.trackError(
            error,
            JSON.parse(localStorage.getItem('current_user') || '{}')
          )
        })
        .finally(() => {
          setCancellationSubmitting(false)
          setIsLoading(false)
        })
    }
  }

  const handleCancellationClose = () => {
    setIsCancelOrderlineModalOpen(false)
    setDeleteOrderDescription(undefined)
  }

  const handleEmailOrderDetails = () => {
    if (
      !order?.orderStatus ||
      (order.orderStatus !== OrderStatus.Shipped.toString() &&
        order.orderStatus !== OrderStatus.Delivered.toString())
    ) {
      setOrderAnnounce({
        message: t('order_detail:error_sending_invoice'),
        mode: AnnounceMode.Warning,
        isDismissible: true
      })
      return
    }

    requestOrderInvoice(order.orderNumber)
      .then((response) => {
        if (response.status === 200) {
          setOrderAnnounce({
            title: ' ',
            message: t('order_detail:invoice_sent'),
            mode: AnnounceMode.Information,
            isDismissible: true
          })
        }
      })
      .catch((error: AxiosError) => {
        setOrderAnnounce({
          message: `${t('order_detail:error_sending_invoice')} ${error}`,
          mode: AnnounceMode.Error,
          isDismissible: true
        })
      })
  }

  const handleAddToBasket = async (
    orderLine: OrderLineDto,
    quantity: number
  ) => {
    setQuantity(quantity)
    setUnitPrice(orderLine.unitSellingPrice)
    setOrderLine(orderLine)
    const sku = orderLine?.skuCatalogItem?.sku
    // Reset the basket if its checking out
    await resetBasket(dispatch, basketDispatch)
    if (sku && defaultShippingAddress) {
      setAddingToBasket(true)
      getPriceAndAvailabilityForSku(sku, defaultShippingAddress.addressId)
        .then((response) => {
          addBasketItemAsync(basketDispatch, sku, quantity, response.data)
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `${t('order_detail:error_adding_item_to_basket')} ${error}`
            )
          )
          window.scrollTo(0, 0)
        })
        .finally(() => {
          setAddingToBasket(false)
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
        })
    }
  }

  const handleRequestAgain = async () => {
    if (order && defaultShippingAddress) {
      // Reset the basket if its checking out
      await resetBasket(dispatch, basketDispatch)

      const skus: string[] = []
      order.lines.forEach((o) => {
        const variantInformation: IAugmentedCatalogItemDto | null =
          o.skuCatalogItem &&
          getVariantInformationForCatalogItem(
            o.skuCatalogItem,
            portfolioCountryCode
          )
        const cannotReOrder = variantInformation?.cannotOrder
        if (
          o.skuCatalogItem?.sku &&
          o.medicationFlag === 'Y' &&
          !cannotReOrder
        ) {
          skus.push(o.skuCatalogItem.sku)
        }
      })
      if (skus.length > 0) {
        const updateBasketCalls: Promise<AxiosResponse<BasketDto>>[] = skus.map(
          (sku) => upsertSkuItemToBasket(sku, 1)
        )
        setAddingToBasket(true)
        Promise.all(updateBasketCalls)
          .then(() => {
            // Prices etc will be updated in basket load
            setTimeout(() => {
              history.push('/basket')
            }, 500)
          })
          .catch((error) => {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `${t('order_detail:error_reordering')} ${error}`
              )
            )
            window.scrollTo(0, 0)
          })
          .finally(() => setAddingToBasket(false))
      }
      analyticsServiceSingleton.trackEvent(AnalyticsEvent.OpenRepeatOrderModal)
    }
  }

  const handleGoToBasket = () => {
    history.push('/basket')
  }

  const handleProceedToCheckout = () => {
    history.push('/checkout')
  }

  const handleProceedToCheckoutOld = () => {
    history.push('/basket')
  }

  const handleFileUploadedForOrderAndHold = (
    orderId: string,
    orderLine: OrderLineDto,
    hold: HoldDto,
    document: UploadedDocumentDto
  ) => {
    const holdDocumentType: string | undefined = getHoldDocumentForHold(hold)
    if (hold.holdId && holdDocumentType) {
      setDocumentToHold(
        orderId,
        document.uploadedDocumentId,
        hold,
        holdDocumentType
      )
        .then((response) => {
          if (response.status > 200) {
            // Update order with document details
            if (order) {
              setOrder(
                getUpdatedOrderState(
                  order,
                  orderLine,
                  hold,
                  holdDocumentType,
                  document
                )
              )
            }
          }
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `${t('order_detail:error_uploading_document')} ${error}`
            )
          )
          // Trigger refresh to remove document
          window.scrollTo(0, 0)
        })
    } else {
      console.warn(
        'No holdId or holdDocumentType supplied to setDocumentToHold'
      )
    }
  }

  const removeLocalStorageInformation = () => {
    if (
      userDetails?.contactCard.name &&
      localStorage.getItem(userDetails?.contactCard.name)
    ) {
      localStorage.removeItem(userDetails?.contactCard.name)
    }
  }

  useEffect(
    () => {
      if (orderLine?.skuCatalogItem) {
        const basketItemDetails: IBasketItemDetails = {
          title: orderLine.skuCatalogItem.item.genericConcatenation,
          sku: orderLine.skuCatalogItem.sku,
          quantity: quantity,
          amount: quantity * unitPrice,
          currencyCode: viewBasket?.currencyCode
            ? viewBasket?.currencyCode
            : 'GBP',
          requiresDocumentation: false // TODO: Work out whether this needs to be true or false
        }
        dispatch(
          createConfirmationEvent({
            children: (
              <AddedToBasketConfirmation
                basketItemDetails={basketItemDetails}
                handleGoToBasket={handleGoToBasket}
                handleProceedToCheckout={
                  isNewCheckoutPage
                    ? handleProceedToCheckout
                    : handleProceedToCheckoutOld
                }
                wasItemRemoved={false}
                handleClose={() => dispatch(clearConfirmationEvent())}
              />
            ),
            autoHideAfterMs: 10 * 1000,
            boxWidth: 360,
            position: 'absolute',
            topPosition: -75,
            removePadding: true
          })
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewBasket]
  )

  // cancel api calls on unmount
  useEffect(() => {
    window.addEventListener('beforeunload', removeLocalStorageInformation)
    return () => {
      //this is for removing data from local storage that are preventing fast track new users to see new feature on order details page
      removeLocalStorageInformation()
      window.removeEventListener('beforeunload', removeLocalStorageInformation)
      cancelGetOrderById()
      cancelSetDocumentToHold()
    }
  }, [])

  useEffect(() => {
    // Scroll to the middle of the page (or any target element with the corresponding ID)
    const shouldScrollToTrackingLink = localStorage.getItem(
      scrollToTrackingLinkKey
    )

    const trackingLink = document.getElementById('tracking-link')

    if (shouldScrollToTrackingLink === 'True' && trackingLink) {
      trackingLink.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
      localStorage.removeItem(scrollToTrackingLinkKey)
    }
  }, [])

  type Deps = [OrderDto, IRestEndpointState<InstituteDto>, string]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      analyticsServiceSingleton.trackPageView(AnalyticsPageEvent.ViewOrder, {
        'Order number': dependencies[0].orderNumber,
        Institution: dependencies[1].data?.instituteName
      })
    },
    [order, institute],
    (dependencies: Deps) => dependencies[0] && dependencies[1]
  )

  const listOfNewFeatures = undefined //expired feature

  return (
    <>
      <OrderLineCancellationModal
        orderLine={selectedOrderLine}
        isOpen={isCancelOrderlineModalOpen}
        selectedOption={
          deleteOrderDescription &&
          findOrderCancellationLookupCode[deleteOrderDescription?.cancelReason]
        }
        cancellationOptions={cancellationOptions}
        isSubmitting={cancellationSubmitting}
        handleCommentChange={handleCancellationTextChange}
        handleOptionChange={handleCancellationOptionChange}
        handleSubmit={handleSubmitCancellation}
        handleClose={handleCancellationClose}
        hasError={cancellationHasError && cancellationHasError.hasError}
        errorMessage={cancellationHasError && cancellationHasError.errorMessage}
      />
      <OrderDetail
        order={order}
        newFeaturesList={listOfNewFeatures}
        orderLevelHolds={orderLevelHolds}
        isLoading={isLoading}
        countryCode={portfolioCountryCode}
        isAddingToBasket={isAddingToBasket}
        orderAnnounce={orderAnnounce}
        supportContact={supportContact}
        backButtonTitle={backButtonTitle}
        handleDismissAnnounce={() => setOrderAnnounce(undefined)}
        onPrint={() => window.print()}
        canAllItemsBeOrdered={canAllItemsBeOrdered}
        onRequestAgain={handleRequestAgain}
        onBack={handleBack}
        onCancelOrderLine={handleCancelOrderLine}
        onAddToBasket={handleAddToBasket}
        onEmailOrderDetails={handleEmailOrderDetails}
        handleFileUploadedForOrderAndHold={handleFileUploadedForOrderAndHold}
        handleTrackingLinkClicked={handleTrackingLinkClicked}
        getCarrierType={getCarrierType}
      />
    </>
  )
}
